import {Directive, ElementRef, EventEmitter, OnDestroy, Output} from '@angular/core';
import {fromEvent, merge, Subscription, timer} from 'rxjs';
import {map, switchMap, takeUntil, tap} from 'rxjs/operators';

@Directive({
  selector: '[appLongPress]',
})
export class LongPressDirective implements OnDestroy {
  public duration = 500;
  @Output() mouseLongPress = new EventEmitter<any>();

  sub!: Subscription;
  constructor(private el: ElementRef) {
    const mouseDown$ = fromEvent<MouseEvent>(this.el.nativeElement, 'mousedown').pipe(tap((event) => event.preventDefault()));
    const touchstart$ = fromEvent<TouchEvent>(this.el.nativeElement, 'touchstart').pipe(tap((event) => event.preventDefault()));
    const startEvent$ = merge(mouseDown$, touchstart$);

    const mouseUp$ = fromEvent<MouseEvent>(this.el.nativeElement, 'mouseup').pipe(
      tap((event) => {
        console.log(event);
        event.preventDefault();
      }),
    );
    const touchEnd$ = fromEvent<TouchEvent>(this.el.nativeElement, 'touchend').pipe(tap((event) => event.preventDefault()));
    const endEvent$ = merge(mouseUp$, touchEnd$);

    this.sub = startEvent$
      .pipe(
        switchMap((event) =>
          timer(this.duration).pipe(
            takeUntil(endEvent$),
            map(() => event),
          ),
        ),
      )
      .subscribe((event) => {
        console.log(event);
        this.mouseLongPress.emit(event as Event);
      });
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
