/* PRO LIGHT */
import {faHome} from '@fortawesome/pro-light-svg-icons/faHome';
import {faMusic} from '@fortawesome/pro-light-svg-icons/faMusic';
import {faClock} from '@fortawesome/pro-light-svg-icons/faClock';
import {faHeart} from '@fortawesome/pro-light-svg-icons/faHeart';
import {faMusicAlt} from '@fortawesome/pro-light-svg-icons/faMusicAlt';
import {faAlbum} from '@fortawesome/pro-light-svg-icons/faAlbum';
import {faUserMusic} from '@fortawesome/pro-light-svg-icons/faUserMusic';
import {faAlbumCollection} from '@fortawesome/pro-light-svg-icons/faAlbumCollection';
import {faPlusCircle} from '@fortawesome/pro-light-svg-icons/faPlusCircle';
import {faBell} from '@fortawesome/pro-light-svg-icons/faBell';
import {faSearch} from '@fortawesome/pro-light-svg-icons/faSearch';
import {faHeadphones} from '@fortawesome/pro-light-svg-icons/faHeadphones';
import {faUser} from '@fortawesome/pro-light-svg-icons/faUser';
import {faUsers} from '@fortawesome/pro-light-svg-icons/faUsers';
import {faShareAlt} from '@fortawesome/pro-light-svg-icons/faShareAlt';
import {faListMusic} from '@fortawesome/pro-light-svg-icons/faListMusic';
import {faPlay} from '@fortawesome/pro-light-svg-icons/faPlay';
import {faExpandAlt} from '@fortawesome/pro-light-svg-icons/faExpandAlt';
import {faEllipsisH} from '@fortawesome/pro-light-svg-icons/faEllipsisH';
import {faStepBackward} from '@fortawesome/pro-light-svg-icons/faStepBackward';
import {faStepForward} from '@fortawesome/pro-light-svg-icons/faStepForward';
import {faRepeat} from '@fortawesome/pro-light-svg-icons/faRepeat';
import {faRandom} from '@fortawesome/pro-light-svg-icons/faRandom';
import {faVolume} from '@fortawesome/pro-light-svg-icons/faVolume';
import {faChevronDown} from '@fortawesome/pro-light-svg-icons/faChevronDown';
import {faPaperPlane} from '@fortawesome/pro-light-svg-icons/faPaperPlane';

/* PRO REGULAR (font-weight: 500) */
import {faMusic as farMusic} from '@fortawesome/pro-regular-svg-icons/faMusic';
import {
  faArrowsAlt,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCog,
  faDollarSign,
  faHandshakeAlt,
  faRedo,
  faSignOut,
  faTimes,
  faWifiSlash,
  faTrashAlt,
  faMoneyBillWave,
  faMoonStars,
  faUserTimes,
  faPen,
  faExclamationTriangle,
  faUnlockAlt,
  faEyeSlash,
  faEye,
  faCalendar,
  faFileUser,
  faPause,
  faVolumeMute,
  faSadTear,
  faPlayCircle,
  faFileSearch,
  faRepeat1,
  faCoins,
  faPercent,
  faMicrophone,
  faSignOutAlt,
  faMinusCircle,
  faHourglassStart,
  faUserPlus,
  faCloudUpload,
  faStar,
  faFileInvoiceDollar,
  faTimesCircle,
  faCommentAltDollar,
  faAnalytics,
  faCreditCard,
  faMicrophoneStand,
  faSpinner,
  faImages,
  IconDefinition,
  faMoneyCheckAlt,
  faUserClock,
  faEnvelopeOpen,
  faThumbtack,
  faInfoCircle,
  faShareSquare,
  faCalendarStar,
  faFileAlt,
  faBars,
  faChevronUp,
  faFileMusic,
  faFileCheck,
  faUserUnlock,
} from '@fortawesome/pro-light-svg-icons';

/* PRO SOLID */
import {faBadgeCheck, faLongArrowAltDown, faLongArrowAltUp, faThumbtack as faThumbtackSolid} from '@fortawesome/pro-solid-svg-icons';

/* FREE SOLID */
import {faHeart as fasHeart, faCheck} from '@fortawesome/free-solid-svg-icons';
import {faFacebook, faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons';

export const customQueue: IconDefinition = ({
  prefix: 'fa-queue',
  iconName: 'queue',
  icon: [
    18, // SVG view box width
    16, // SVG view box height
    [],
    'U+E003',
    // path from addToQueue.svg icon
    'M 4.0645195,5.8477938 A 0.55629592,0.58053227 0 0 0 3.5343529,6.428268 v 8.126638 a 0.55629592,0.58053227 0 0 0 0.8539158,0.489776 l 5.5624032,-3.693721 a 0.55629592,0.58053227 0 0 0 0.03912,-0.95234 L 4.4273794,5.9679706 A 0.55629592,0.58053227 0 0 0 4.0645195,5.8477938 Z m 0.582314,1.7527605 4.0370878,3.2175497 -4.0370878,2.680158 z M 18.552841,2.3649484 c 0.307156,0 0.55624,-0.2598899 0.55624,-0.5804742 C 19.109081,1.4638899 18.859997,1.204 18.552841,1.204 Z M 4.0905933,1.204 c -0.3072004,0 -0.5562404,0.2598899 -0.5562404,0.5804742 0,0.3205843 0.24904,0.5804742 0.5562404,0.5804742 z m 14.4622477,0 H 4.0905933 V 2.3649484 H 18.552841 Z M 9.6529962,5.8477938 a 0.55629592,0.58053227 0 1 0 0,1.1609484 h 8.8998448 a 0.55629592,0.58053227 0 1 0 0,-1.1609484 z m 8.8998448,5.8047422 c 0.307156,0 0.55624,-0.259937 0.55624,-0.580475 0,-0.320537 -0.249084,-0.580474 -0.55624,-0.580474 z m -4.449922,-1.160949 c -0.307156,0 -0.55624,0.259937 -0.55624,0.580474 0,0.320538 0.249084,0.580475 0.55624,0.580475 z m 4.449922,0 h -4.449922 v 1.160949 h 4.449922 z',
  ],
} as unknown) as IconDefinition;

export const FaIcons = {
  home: faHome,
  music: faMusic,
  clock: faClock,
  heart: faHeart,
  musicAlt: faMusicAlt,
  album: faAlbum,
  userMusic: faUserMusic,
  albumCollection: faAlbumCollection,
  plusCircle: faPlusCircle,
  bell: faBell,
  search: faSearch,
  headphones: faHeadphones,
  user: faUser,
  users: faUsers,
  userPlus: faUserPlus,
  paperPlane: faPaperPlane,
  checkCircle: faCheckCircle,
  shareAlt: faShareAlt,
  listMusic: faListMusic,
  play: faPlay,
  pause: faPause,
  dollarSign: faDollarSign,
  cog: faCog,
  handShakeAlt: faHandshakeAlt,
  signOut: faSignOut,
  signOutAlt: faSignOutAlt,
  expandAlt: faExpandAlt,
  ellipsisH: faEllipsisH,
  stepBackward: faStepBackward,
  stepForward: faStepForward,
  repeat: faRepeat,
  random: faRandom,
  volume: faVolume,
  volumeMute: faVolumeMute,
  chevronUp: faChevronUp,
  chevronDown: faChevronDown,
  chevronLeft: faChevronLeft,
  chevronRight: faChevronRight,
  times: faTimes,
  arrowsAlt: faArrowsAlt,
  redo: faRedo,
  wifiSlash: faWifiSlash,
  trashAlt: faTrashAlt,
  moneyBillWave: faMoneyBillWave,
  moonStars: faMoonStars,
  userTimes: faUserTimes,
  pen: faPen,
  exclamationTriangle: faExclamationTriangle,
  unlockAlt: faUnlockAlt,
  eyeSlash: faEyeSlash,
  eye: faEye,
  calendar: faCalendar,
  calendarStar: faCalendarStar,
  fileUser: faFileUser,
  instagram: faInstagram,
  facebook: faFacebook,
  twitter: faTwitter,
  sadTear: faSadTear,
  playCircle: faPlayCircle,
  fileSearch: faFileSearch,
  repeat1: faRepeat1,
  coins: faCoins,
  percent: faPercent,
  microphone: faMicrophone,
  minus: faMinusCircle,
  hourglassStart: faHourglassStart,
  spinner: faSpinner,
  images: faImages,
  longArrowAltUp: faLongArrowAltUp,
  longArrowAltDown: faLongArrowAltDown,
  userClock: faUserClock,
  cloudUpload: faCloudUpload,
  star: faStar,
  fileInvoiceDollar: faFileInvoiceDollar,
  timesCircle: faTimesCircle,
  commentsAltDollar: faCommentAltDollar,
  analytics: faAnalytics,
  creditCard: faCreditCard,
  microphoneStand: faMicrophoneStand,
  moneyCheckAlt: faMoneyCheckAlt,
  envelopeOpen: faEnvelopeOpen,
  thumbtack: faThumbtack,
  infoCircle: faInfoCircle,
  fileAlt: faFileAlt,
  addToQueue: customQueue,
  shareSquare: faShareSquare,
  menuBars: faBars,
  fileMusic: faFileMusic,
  fileCheck: faFileCheck,
  userUnlock: faUserUnlock,
};

export const FaIconsRegular = {
  music: farMusic,
};

export const FaIconsSolidPro = {
  badgeCheck: faBadgeCheck,
  thumbtack: faThumbtackSolid,
};

export const FaIconsSolid = {
  heart: fasHeart,
  check: faCheck,
};

export const applicationIcon: IconDefinition = ({
  prefix: 'xxx',
  iconName: 'yyy',
  icon: [
    26, // SVG view box width
    20, // SVG view box height
    [],
    'U+E002', // probably not important for SVG and JS approach
    'M 13 0.6640625 C 12.078001 0.6640625 11.330078 1.4119053 11.330078 2.3339844 L 11.330078 18.472656 C 11.330078 19.394655 12.078001 20.142578 13 20.142578 C 13.922099 20.142578 14.669922 19.394655 14.669922 18.472656 L 14.669922 15.691406 C 14.669922 15.076707 15.168504 14.578125 15.783203 14.578125 C 16.398003 14.578125 16.896484 15.076707 16.896484 15.691406 L 16.896484 15.96875 C 16.896484 16.737149 17.51871 17.359375 18.287109 17.359375 L 18.564453 17.359375 C 19.486452 17.359375 20.234375 16.613505 20.234375 15.691406 L 20.234375 7.8984375 C 20.234375 6.9763584 19.486452 6.2304688 18.564453 6.2304688 C 17.642354 6.2304688 16.896484 6.9763584 16.896484 7.8984375 L 16.896484 9.0117188 C 16.896484 9.6264381 16.398003 10.125 15.783203 10.125 C 15.168504 10.125 14.669922 9.6264381 14.669922 9.0117188 L 14.669922 2.3339844 C 14.669922 1.4119053 13.922099 0.6640625 13 0.6640625 z M 7.4355469 4.5605469 C 6.5106088 4.5605469 5.765625 5.3055279 5.765625 6.2304688 L 5.765625 14.578125 C 5.765625 15.503066 6.5106088 16.246094 7.4355469 16.246094 C 8.360485 16.246094 9.1054688 15.503066 9.1054688 14.578125 L 9.1054688 6.2304688 C 9.1054688 5.3055279 8.360485 4.5605469 7.4355469 4.5605469 z M 1.8691406 7.9003906 C 0.94420252 7.9003906 0.19921875 8.6434185 0.19921875 9.5683594 L 0.19921875 11.794922 C 0.19921875 12.719863 0.94420252 13.464844 1.8691406 13.464844 C 2.7940787 13.464844 3.5390625 12.719863 3.5390625 11.794922 L 3.5390625 9.5683594 C 3.5390625 8.6434185 2.7940787 7.9003906 1.8691406 7.9003906 z M 24.130859 7.9003906 C 23.205921 7.9003906 22.460938 8.6434185 22.460938 9.5683594 L 22.460938 11.794922 C 22.460937 12.719863 23.205921 13.464844 24.130859 13.464844 C 25.055797 13.464844 25.800781 12.719863 25.800781 11.794922 L 25.800781 9.5683594 C 25.800781 8.6434185 25.055797 7.9003906 24.130859 7.9003906 z',
  ],
} as unknown) as IconDefinition;

export const blarecastIcon: IconDefinition = ({
  prefix: 'blarecast',
  iconName: 'blarecastIcon',
  icon: [
    58, // SVG view box width
    44, // SVG view box height
    [],
    'U+E002', // probably not important for SVG and JS approach
    'm 49.8415,20.1618 c 0,-2.0303 1.6502,-3.6763 3.6857,-3.6763 2.0356,0 3.6857,1.646 3.6857,3.6763 v 4.9018 c 0,2.0304 -1.6501,3.6764 -3.6857,3.6764 -2.0355,0 -3.6857,-1.646 -3.6857,-3.6764 z M 25.2704,4.23103 V 39.769 c 0,2.0304 1.6501,3.6763 3.6857,3.6763 2.0355,0 3.6856,-1.6459 3.6856,-3.6763 v -6.1273 c 0,-1.3535 1.1001,-2.4509 2.4572,-2.4509 1.357,0 2.4571,1.0974 2.4571,2.4509 v 0.6128 c 0,1.692 1.3751,3.0636 3.0714,3.0636 h 0.6142 c 2.0356,0 3.6857,-1.646 3.6857,-3.6764 V 16.4855 c 0,-2.0304 -1.6501,-3.6763 -3.6857,-3.6763 -2.0355,0 -3.6856,1.6459 -3.6856,3.6763 v 2.4509 c 0,1.3536 -1.1001,2.4509 -2.4571,2.4509 -1.3571,0 -2.4572,-1.0973 -2.4572,-2.4509 V 4.23103 c 0,-2.03039 -1.6501,-3.676342 -3.6856,-3.676342 -2.0356,0 -3.6857,1.645962 -3.6857,3.676342 z M 0.699219,20.1618 c 0,-2.0303 1.9504453,-3.676301 3.685681,-3.6763 1.7352357,1e-6 3.68567,1.646 3.68567,3.6763 v 4.9018 c 0,2.0304 -1.65013,3.6764 -3.68567,3.6764 -2.03555,0 -3.685681,-1.646 -3.685681,-3.6764 z M 12.9848,12.8092 c 0,-2.0304 1.6501,-3.67637 3.6857,-3.67637 2.0355,0 3.6857,1.64597 3.6857,3.67637 v 18.3817 c 0,2.0304 -1.6502,3.6763 -3.6857,3.6763 -2.0356,0 -3.6857,-1.6459 -3.6857,-3.6763 z',
  ],
} as unknown) as IconDefinition;

export const explicitIcon: IconDefinition = ({
  prefix: 'explicit',
  iconName: 'explicitIcon',
  icon: [
    17, // SVG view box width
    16, // SVG view box height
    [],
    'U+E002', // probably not important for SVG and JS approach
    'M 4.59375 0 C 2.37775 2.0724163e-015 0.59375 1.784 0.59375 4 L 0.59375 12 C 0.59375 14.216 2.37775 16 4.59375 16 L 12.59375 16 C 14.80975 16 16.59375 14.216 16.59375 12 L 16.59375 4 C 16.59375 1.784 14.80975 0 12.59375 0 L 4.59375 0 z M 6.46875 3.4628906 L 10.660156 3.4628906 C 10.849196 3.4628906 11.014577 3.532149 11.154297 3.671875 C 11.294027 3.811601 11.363281 3.9769756 11.363281 4.1660156 C 11.363281 4.3550556 11.294027 4.5184731 11.154297 4.6582031 C 11.014577 4.7897131 10.849196 4.8554688 10.660156 4.8554688 L 7.6035156 4.8554688 C 7.5295456 4.8554688 7.4921875 4.8928269 7.4921875 4.9667969 L 7.4921875 6.9511719 C 7.4921875 7.0251419 7.5295456 7.0625 7.6035156 7.0625 L 10.451172 7.0625 C 10.640222 7.0625 10.796632 7.1282556 10.919922 7.2597656 C 11.051422 7.3912756 11.117188 7.5476956 11.117188 7.7285156 C 11.117188 7.9093456 11.051422 8.0657556 10.919922 8.1972656 C 10.796632 8.3205456 10.640222 8.3808594 10.451172 8.3808594 L 7.6035156 8.3808594 C 7.5295456 8.3808594 7.4921875 8.4182075 7.4921875 8.4921875 L 7.4921875 10.958984 C 7.4921875 11.032954 7.5295456 11.068359 7.6035156 11.068359 L 10.660156 11.068359 C 10.849196 11.068359 11.014577 11.139567 11.154297 11.279297 C 11.294027 11.410807 11.363281 11.570726 11.363281 11.759766 C 11.363281 11.948806 11.294027 12.112233 11.154297 12.251953 C 11.014577 12.391683 10.849196 12.462891 10.660156 12.462891 L 6.46875 12.462891 C 6.27971 12.462891 6.1162925 12.391683 5.9765625 12.251953 C 5.8368375 12.112233 5.765625 11.948806 5.765625 11.759766 L 5.765625 4.1660156 C 5.765625 3.9769756 5.8368375 3.811601 5.9765625 3.671875 C 6.1162925 3.532149 6.27971 3.4628906 6.46875 3.4628906 z',
  ],
} as unknown) as IconDefinition;
