import {Pipe, PipeTransform} from '@angular/core';
import {Maybe} from 'src/generated/graphql';

@Pipe({
  name: 'songTime',
})
export class SongTimePipe implements PipeTransform {
  public transform(seconds: number | Maybe<number> | undefined, backup: number | Maybe<number> | undefined = undefined): string {
    if (seconds) {
      return Math.floor(seconds / 60) + (Math.floor(seconds % 60) > 9 ? ':' : ':0') + Math.floor(seconds % 60);
    }

    if (backup) {
      return Math.floor(backup / 60) + (Math.floor(backup % 60) > 9 ? ':' : ':0') + Math.floor(backup % 60);
    }

    return '0:00';
  }
}
