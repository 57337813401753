export type ModalPosition = 'center' | 'top';

export class ModalData<T> {
  public content!: unknown;
  public padding?: string;
  public width?: string;
  public position?: ModalPosition;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public data?: T;
}
