import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import {SwiperComponent} from 'swiper/angular';
import {SwiperOptions} from 'swiper';
import {FaIcons} from '../../constants/fa-icons.enum';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements AfterViewInit {
  @ViewChild('swiper') public swiper: SwiperComponent | undefined;

  private _listItems: QueryList<any> | undefined;
  public get listItems(): QueryList<any> | undefined {
    return this._listItems;
  }

  @ContentChildren('carouselSlide') public set listItems(value: QueryList<any> | undefined) {
    console.log(value);
    this.breakPointChange();
    this._listItems = value;
  }

  @Input() public breakpoints: {[width: number]: SwiperOptions; [ratio: string]: SwiperOptions} | undefined = {
    '1680': {slidesPerView: 3},
    '600': {slidesPerView: 2},
  };

  @Output() public slideChange = new EventEmitter<number>();

  public FaIcons = FaIcons;

  public currentSlide = 0;
  public numberOfDots = 0;
  public allSlidesAreInView = false;
  public disabled = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    this.breakPointChange();
  }

  public localSlideChange(): void {
    this.currentSlide = this.swiper?.swiperRef.activeIndex || 0;
    console.log(this.currentSlide);
    this.slideChange.emit(this.currentSlide);
    this.changeDetectorRef.detectChanges();
  }

  public breakPointChange(): void {
    if (this.swiper?.swiperRef?.params?.slidesPerView && this.listItems?.length) {
      this.numberOfDots = Math.round(this.listItems?.length / +this.swiper?.swiperRef?.params?.slidesPerView);
      console.log(this.swiper?.swiperRef?.params?.slidesPerView, this.listItems?.length, this.numberOfDots);
    }

    if (this.swiper?.swiperRef?.params?.slidesPerView === this.listItems?.length) {
      this.allSlidesAreInView = true;
    } else {
      this.allSlidesAreInView = false;
    }
    this.changeDetectorRef.detectChanges();
  }
}
