import {Directive, HostBinding, Optional} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[appDisableForm]',
})
export class DisableFormDirective {
  @HostBinding('class') public class = '';

  constructor(@Optional() public formGroupDirective: FormGroupDirective) {
    setTimeout(() => {
      // wait for next tick
      if (formGroupDirective.form) {
        formGroupDirective.form.statusChanges.pipe(untilDestroyed(this)).subscribe((status) => {
          if (status === 'DISABLED') {
            this.class = 'disabled-form';
          } else {
            this.class = '';
          }
        });
      }
    }, 0);
  }
}
