<h5 ngClass.lt-md="text-center">Edit playlist</h5>
<div fxLayout="row" class="mt-3" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center">
  <app-cover-image-upload
    coverImageType="playlist"
    [disableFilePicking]="editPlayListForm.disabled"
    [imagePreview]="imagePreview"
  ></app-cover-image-upload>
  <form [formGroup]="editPlayListForm" appDisableForm class="w-100">
    <div fxLayout="column" ngClass.gt-sm="ml-3">
      <div class="mt-3 input-field-wrapper">
        <label>Playlist name</label>
        <input formControlName="playlistName" type="text" placeholder="Best playlist" />
      </div>

      <div class="mt-3 input-field-wrapper radio-inputs-wrapper">
        <label>Privacy</label>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayoutAlign="space-between center">
            <label class="radio" for="private">Private</label>
            <input type="radio" id="private" name="type" [value]="playlistType.PRIVATE" formControlName="type" />

            <label class="radio" for="public">Public</label>
            <input type="radio" id="public" name="type" [value]="playlistType.PUBLIC" formControlName="type" />
          </div>
          <button
            class="ml-3 big"
            [disabled]="editPlayListForm.invalid"
            [class.loader]="editPlayListForm.disabled"
            (click)="editPlaylist()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
