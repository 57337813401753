import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./blare-app/blare-app.module').then((m) => m.BlareAppModule),
  },
  {
    path: '',
    loadChildren: () => import('./static-app/static-app.module').then((m) => m.StaticAppModule),
  },
  {
    path: 'account',
    loadChildren: () => import('./auth-app/auth-app.module').then((m) => m.AuthAppModule),
  },
  {
    path: '**',
    redirectTo: '/' /* TODO: not found page? */,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
