<div fxLayout="column" fxLayoutAlign="center end" class="profile-menu-popover-wrapper p-3">
  <a fxLayoutAlign="end center" class="link" (click)="editPhotoClick.emit()">
    <span>Choose photo </span>
    <fa-icon fxFlex="20px" [icon]="imagesIcon"></fa-icon>
  </a>
  <!-- <a fxFlex fxLayoutAlign="end center" class="link" (click)="removePhotoClick.emit()">
    <span>Remove current photo</span>
    <fa-icon fxFlex="20px" [icon]="trashIcon"></fa-icon>
  </a> -->
</div>
