import {Component, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FetchResult} from '@apollo/client/core';
import {ModalComponent} from 'src/app/shared/components/modal/modal.component';
import {FaIcons} from 'src/app/shared/constants/fa-icons.enum';
import {PlaylistType} from 'src/app/shared/enums/playlist-privacy.enum';
import {ModalData} from 'src/app/shared/components/modal/modal-config';
import {CacheHelperService} from 'src/app/shared/services/cache-helper.service';
import {
  AddToPlaylistWebGQL,
  AllPlaylistsQueryGQL,
  AllPlaylistsQueryQuery,
  CreatePlaylistWebGQL,
  CreatePlaylistWebMutation,
} from 'src/generated/graphql';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {CoverImageUploadComponent} from '../cover-image-upload/cover-image-upload.component';

@UntilDestroy()
@Component({
  selector: 'app-modal-new-playlist',
  templateUrl: './modal-new-playlist.component.html',
  styleUrls: ['./modal-new-playlist.component.scss'],
})
export class ModalNewPlaylistComponent {
  @ViewChild(CoverImageUploadComponent) private coverImageUploadComponent: CoverImageUploadComponent | undefined;

  public faIcons = FaIcons;
  public playlistType = PlaylistType;
  public newPlayListForm: FormGroup;

  public get type(): FormControl {
    return this.newPlayListForm.get('type') as FormControl;
  }

  public get playlistName(): FormControl {
    return this.newPlayListForm.get('playlistName') as FormControl;
  }

  constructor(
    private parentComponent: ModalComponent,
    private formBuilder: FormBuilder,
    private createPlaylistWebGQL: CreatePlaylistWebGQL,
    private allPlaylistsQueryGQL: AllPlaylistsQueryGQL,
    private cacheHelperService: CacheHelperService,
    private modalData: ModalData<string>,
    private addToPlaylistWebGQL: AddToPlaylistWebGQL,
  ) {
    this.newPlayListForm = this.formBuilder.group({
      playlistName: ['', Validators.required],
      type: [PlaylistType.PUBLIC, Validators.required],
    });
  }

  public createNewPlaylist(): void {
    if (!this.newPlayListForm.disabled) {
      this.newPlayListForm.disable();
      const typePrivate = this.type.value === PlaylistType.PRIVATE;

      this.createPlaylistWebGQL
        .mutate(
          {
            title: this.playlistName.value,
            private: typePrivate,
          },
          {
            update: (store, mutationResult: FetchResult<CreatePlaylistWebMutation>) => {
              store.updateQuery(
                {query: this.allPlaylistsQueryGQL.document, variables: {userId: this.cacheHelperService.getCurrentUserCache().id}},
                (data: AllPlaylistsQueryQuery | null) => {
                  if (mutationResult.data?.createPlaylist?.playlist !== undefined && data && data.allPlaylists) {
                    const updatedCache = {
                      ...data,
                      allPlaylists: {
                        ...data.allPlaylists,
                        edges: [
                          ...data.allPlaylists.edges,
                          {
                            __typename: 'PlaylistsEdge',
                            node: mutationResult.data.createPlaylist.playlist,
                          },
                        ],
                      },
                    };
                    return updatedCache as AllPlaylistsQueryQuery;
                  }
                  return data;
                },
              );
            },
          },
        )
        .pipe(untilDestroyed(this))
        .subscribe(
          (response) => {
            if (response.data?.createPlaylist?.playlist?.id && this.coverImageUploadComponent) {
              this.coverImageUploadComponent
                .uploadImage(response.data.createPlaylist.playlist.id)
                .then(() => {
                  this.parentComponent.closeModal();
                })
                .catch(() => {
                  this.newPlayListForm.enable();
                });

              // track id is passed, so after creation of playlist, add track to playlist
              if (this.modalData.data) {
                this.addToPlaylistWebGQL
                  .mutate({playlist: response.data.createPlaylist.playlist.id, track: this.modalData.data})
                  .pipe(untilDestroyed(this))
                  .subscribe();
              }
            } else {
              this.parentComponent.closeModal();
            }
          },
          () => {
            this.newPlayListForm.enable();
          },
        );
    }
  }
}
