import {Pipe, PipeTransform} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BehaviorSubject, interval, Observable} from 'rxjs';

@UntilDestroy()
@Pipe({
  name: 'agoTime',
})
export class AgoTimePipe implements PipeTransform {
  private agoTime$ = new BehaviorSubject<string>('');

  public transform(value: string): Observable<string> {
    const d = new Date(value);
    let seconds = Math.round(Math.abs((new Date().getTime() - d.getTime()) / 1000));
    const timeToUpdate = Number.isNaN(seconds) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;

    interval(timeToUpdate)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        seconds = Math.round(Math.abs((new Date().getTime() - d.getTime()) / 1000));
        const agoTimeString = this.getReadableSting(seconds);
        if (this.agoTime$.getValue() !== agoTimeString) {
          this.agoTime$.next(this.getReadableSting(seconds));
        }
      });

    this.agoTime$.next(this.getReadableSting(seconds));

    return this.agoTime$;
  }

  public getReadableSting(seconds: number): string {
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));

    if (seconds <= 45) {
      return 'a few seconds ago';
    }
    if (seconds <= 90) {
      return 'a minute ago';
    }
    if (minutes <= 45) {
      return `${minutes} minutes ago`;
    }
    if (minutes <= 90) {
      return 'an hour ago';
    }
    if (hours <= 22) {
      return `${hours} hours ago`;
    }
    if (hours <= 36) {
      return 'a day ago';
    }
    if (days <= 25) {
      return `${days} days ago`;
    }
    if (days <= 45) {
      return 'a month ago';
    }
    if (days <= 345) {
      return `${months} months ago`;
    }
    if (days <= 545) {
      return 'a year ago';
    }
    return `${years} years ago`;
  }

  private getSecondsUntilUpdate(seconds: number) {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;
    if (seconds < min) {
      // less than 1 min, update every 2 secs
      return 2;
    }
    if (seconds < hr) {
      // less than an hour, update every 30 secs
      return 30;
    }
    if (seconds < day) {
      // less then a day, update every 5 mins
      return 300;
    }
    // update every hour
    return 3600;
  }
}
