import {animate, style, transition, trigger} from '@angular/animations';
import {Component, ElementRef, HostBinding, HostListener, Input, OnChanges} from '@angular/core';

@Component({
  selector: 'app-smooth-height',
  templateUrl: './smooth-height.component.html',
  styleUrls: ['./smooth-height.component.scss'],
  animations: [
    trigger('grow', [
      transition('void <=> *', []),
      transition('* <=> *', [style({height: '{{startHeight}}px', opacity: 0}), animate('0.5s ease')], {
        params: {startHeight: 0},
      }),
    ]),
  ],
})
export class SmoothHeightComponent implements OnChanges {
  @HostBinding('@grow') public grow: unknown;

  @HostBinding('style.overflow') public overflow = 'visible';

  @Input() public trigger: unknown | undefined;

  public startHeight: number | undefined;

  constructor(private element: ElementRef) {}

  @HostListener('@grow.done', ['$event'])
  public animationDone(): void {
    setTimeout(() => {
      this.overflow = 'visible';
    });
  }

  public ngOnChanges(): void {
    this.overflow = 'hidden';
    this.startHeight = this.element.nativeElement.clientHeight;

    this.grow = {value: this.trigger, params: {startHeight: this.startHeight}};
  }
}
