/* eslint-disable @typescript-eslint/no-explicit-any */
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {appConfig} from 'src/app/app.config';
import {AuthService} from '../services/auth.service';
// https://medium.com/@this.is.samy/angular-auto-session-recovery-interceptor-42e8233cfa23
// https://stackoverflow.com/questions/45202208/angular-4-interceptor-retry-requests-after-token-refresh
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private _refreshSubject: Subject<any> = new Subject<any>();

  constructor(private authService: AuthService) {}

  public ifTokenExpired(): Subject<any> {
    this._refreshSubject.subscribe({
      complete: () => {
        this._refreshSubject = new Subject<any>();
      },
    });
    if (this._refreshSubject.observers.length === 1 && this.authService.refreshToken) {
      // Hit refresh-token API passing the refresh token stored into the request
      // to get new access token and refresh token pair
      this.authService.tryRefreshingToken().subscribe(this._refreshSubject);
    }
    return this._refreshSubject;
  }

  public checkTokenExpiryErr(error: HttpErrorResponse): boolean {
    return error.status && error.status === 401 && error.error;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const updatedReq = this.updateHeader(req);
    console.log(updatedReq);

    return next.handle(updatedReq).pipe(
      catchError((error, caught) => {
        if (error instanceof HttpErrorResponse) {
          console.log(error);
          if (req.url.endsWith('refresh-token') && (error.status === 401 || error.status === 400)) {
            this.authService.logoutActions();
          }
          if (req.url.endsWith('login') && error.status === 401) {
            return throwError(error);
          }
          if (this.checkTokenExpiryErr(error)) {
            return this.ifTokenExpired().pipe(switchMap(() => next.handle(this.updateHeader(updatedReq))));
          }
          return throwError(error);
        }
        return caught;
      }),
    );
  }

  public updateHeader(req: HttpRequest<any>): HttpRequest<any> {
    let headers = req.headers;

    if (!req.url.includes('upload')) {
      // upload should not have Content-Type header present
      headers = req.headers.get('Content-Type') ? req.headers : req.headers.set('Content-Type', 'application/json');
    }

    if (
      req.url.endsWith('graphql') ||
      req.url.endsWith('logout') ||
      req.url.endsWith('upload') ||
      req.url.startsWith('payment') ||
      req.url.includes('search') ||
      req.url.includes('stripe') ||
      req.url.includes('onfido') ||
      req.url.includes('recommendations')
    ) {
      headers = headers.set('Authorization', this.authService.token || '');
    }

    let url = req.url;
    if (!req.url.startsWith('http')) {
      // else queued requests get double http://blarecast...
      url = `${appConfig.backendURL}/${req.url}`;
    }

    return req.clone({
      url,
      headers,
    });
  }
}
