import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ModalData} from 'src/app/shared/components/modal/modal-config';
import {ModalComponent} from 'src/app/shared/components/modal/modal.component';
import {FaIcons} from 'src/app/shared/constants/fa-icons.enum';
import {CacheHelperService} from 'src/app/shared/services/cache-helper.service';
import {AllPlaylistsQueryGQL, AllPlaylistsQueryQuery, Playlist, RemovePlaylistGQL} from 'src/generated/graphql';

export interface DeletePlaylistData {
  playlist: Playlist;
  redirect?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-modal-remove-playlist',
  templateUrl: './modal-remove-playlist.component.html',
  styleUrls: ['./modal-remove-playlist.component.scss'],
})
export class ModalRemovePlaylistComponent {
  public FaIcons = FaIcons;
  public isButtonLoading = false;

  constructor(
    private parentComponent: ModalComponent,
    private allPlaylistsQueryGQL: AllPlaylistsQueryGQL,
    private modalData: ModalData<DeletePlaylistData>,
    private removePlaylistByIdGQL: RemovePlaylistGQL,
    private router: Router,
    private cacheHelperService: CacheHelperService,
  ) {}

  public close(): void {
    this.parentComponent.closeModal();
  }

  public removePlaylist(): void {
    if (!this.isButtonLoading) {
      this.isButtonLoading = true;
      this.removePlaylistByIdGQL
        .mutate(
          {
            id: this.modalData.data?.playlist.id,
          },
          {
            update: (store) => {
              store.updateQuery(
                {query: this.allPlaylistsQueryGQL.document, variables: {userId: this.cacheHelperService.getCurrentUserCache().id}},
                (data: AllPlaylistsQueryQuery | null) => {
                  if (data && data.allPlaylists) {
                    const updatedCache = {
                      ...data,
                      allPlaylists: {
                        ...data.allPlaylists,
                        edges: data?.allPlaylists?.edges.filter((obj) => obj.node?.id !== this.modalData?.data?.playlist.id),
                      },
                    };
                    return updatedCache as AllPlaylistsQueryQuery;
                  }
                  return data;
                },
              );
            },
          },
        )
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.parentComponent.closeModal();
            if (this.modalData.data?.redirect) {
              this.router.navigate(['/']);
            }
          },
          () => {
            this.isButtonLoading = false;
          },
        );
    }
  }
}
