import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {init, SdkHandle} from 'onfido-sdk-ui';
import {FaIcons} from 'src/app/shared/constants/fa-icons.enum';
import {KycService} from '../../services/kyc.service';

enum VerificationFlow {
  FULLNAME = 0,
  ONFIDO = 1,
}
@UntilDestroy()
@Component({
  selector: 'app-onfido',
  templateUrl: './onfido.component.html',
  styleUrls: ['./onfido.component.scss'],
})
export class OnfidoComponent {
  @Output() public backButtonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public fidoBackButtonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onfidoStepClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public completed: EventEmitter<void> = new EventEmitter<void>();

  public loadingOnFidoElement = true;

  @ViewChild('onfidoMountDiv') public set onfidoMountDiv(element: ElementRef | undefined) {
    // When elements render -> init onfido SDK
    if (element) {
      this.kycService
        .getSdkToken(this.additionalInfoForm.get('firstName')?.value, this.additionalInfoForm.get('lastName')?.value)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (response) => {
            if (element) {
              this.loadingOnFidoElement = false;
              this.onfido = init({
                token: response.sdkToken,
                smsNumberCountryCode: 'CA',
                language: {
                  locale: 'en_US',
                  phrases: {
                    'DOCUMENT_SELECTOR.IDENTITY.NATIONAL_IDENTITY_CARD_HINT': 'Hello',
                  },
                  mobilePhrases: {
                    national_identity_card: 'My Identity Card Mobile',
                    'capture.driving_licence.instructions': 'Hello',
                  },
                },
                steps: [
                  {
                    type: 'document',
                  },
                  {
                    type: 'face',
                  },
                ],

                onComplete: () => {
                  // callback for when everything is complete
                  this.kycService
                    .checkApplicant()
                    .pipe(untilDestroyed(this))
                    .subscribe({
                      next: () => {
                        this.completed.emit();
                      },
                    });
                },
              });
            }
          },
        });
    }
  }

  public FaIcons = FaIcons;

  public additionalInfoForm: FormGroup;

  public verificationFlow = VerificationFlow;
  public verificationStep = VerificationFlow.FULLNAME;

  public onfido!: SdkHandle;

  constructor(private formBuilder: FormBuilder, private kycService: KycService) {
    this.additionalInfoForm = formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
    });
  }
}
