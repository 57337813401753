<div
  class="popover-wrapper"
  ngClass.lt-md="mobile"
  [@popoverAnimationDesktop]="animationState"
  [@popoverAnimationMobile]="{value: animationState, params: {currentProcentage: currentMobileYPosition}}"
  (@popoverAnimationDesktop.done)="animationEnd($event)"
  (@popoverAnimationMobile.done)="animationEnd($event)"
  [style.transform]="'translateY(' + currentMobileYPosition + '%)'"
>
  <div
    draggable="true"
    class="mobile-close-bar-wrapper"
    fxHide.gt-sm
    (touchmove)="draggingMobileCloseBar($event)"
    (touchend)="endOfDraggingMobileCloseBar()"
  >
    <div class="bar"></div>
  </div>

  <ng-container [ngSwitch]="renderMethod">
    <ng-container *ngSwitchCase="'template'">
      <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'component'">
      <ng-container *ngComponentOutlet="content"></ng-container>
    </ng-container>
  </ng-container>
</div>
