import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {PaymentMethod, StripeCardElementOptions, StripeElementChangeEvent, StripeElementsOptions, StripeError} from '@stripe/stripe-js';
import {
  StripeCardCvcComponent,
  StripeCardExpiryComponent,
  StripeCardNumberComponent,
  StripeFactoryService,
  StripeInstance,
  // eslint-disable-next-line import/no-unresolved
} from 'ngx-stripe';
import {combineLatest, Observable, throwError} from 'rxjs';
import {finalize, switchMap} from 'rxjs/operators';
import {countryData} from '../../constants/country-data';
import {Intent, PaymentService} from '../../services/payment.service';

enum PaymentFlow {
  ADDRESS = 0,
  CARD = 1,
}

enum StripeElementType {
  CARD = 0,
  DATE = 1,
  CVV = 2,
}
@UntilDestroy()
@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss'],
})
export class StripeComponent implements OnInit {
  @Output() public backButtonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public completed: EventEmitter<void> = new EventEmitter<void>();

  @Input() public manuallyCallAddCardToCustomerFunction = false;

  @ViewChild(StripeCardNumberComponent) public cardNumberElement!: StripeCardNumberComponent;
  @ViewChild(StripeCardExpiryComponent) public cardExpiryElement!: StripeCardNumberComponent;
  @ViewChild(StripeCardCvcComponent) public cardCVVElement!: StripeCardNumberComponent;
  public cardNumberElementChange$: Observable<StripeElementChangeEvent> | undefined;
  public cardExpiryElementChange$: Observable<StripeElementChangeEvent> | undefined;
  public cardCVVElementChange$: Observable<StripeElementChangeEvent> | undefined;

  public StripeElementType = StripeElementType;

  public cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: window.getComputedStyle(document.documentElement).getPropertyValue('--black'),
        fontWeight: '100',
        fontFamily: '"Museo Sans", Museo Sans, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: window.getComputedStyle(document.documentElement).getPropertyValue('--grey'),
        },
      },
    },
  };

  public elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  public stripe: StripeInstance | undefined;
  public stripeForm: FormGroup;
  public stripeFormValid = false;

  public billingAddressForm: FormGroup;

  public PaymentFlow = PaymentFlow;
  public currentStep = PaymentFlow.ADDRESS;
  public countryData = countryData;

  public loadingStripeElements = true;
  private loadedElements: number[] = [];

  public get name(): FormControl {
    return this.stripeForm.get('name') as FormControl;
  }

  constructor(
    private fb: FormBuilder,
    private stripeFactory: StripeFactoryService,
    private paymentService: PaymentService,
    private formBuilder: FormBuilder,
  ) {
    this.stripeForm = this.fb.group({
      name: ['', [Validators.required]],
    });

    this.billingAddressForm = this.formBuilder.group({
      street: ['', Validators.required],
      townCity: ['', Validators.required],
      zipCode: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]+$/)]],
      country: [undefined, Validators.required],
    });
  }

  public ngOnInit(): void {
    this.paymentService.getStripeKey().subscribe({
      next: (response) => {
        this.stripe = this.stripeFactory.create(response.publishableKey, {locale: 'en'});
      },
    });
  }

  public createPaymentMethod(): Observable<{
    paymentMethod?: PaymentMethod | undefined;
    error?: StripeError | undefined;
  }> {
    if (this.stripe) {
      this.disableForm();
      return this.stripe
        .createPaymentMethod({
          type: 'card',
          card: this.cardNumberElement.element,
          billing_details: {
            name: this.name.value,
            address: {
              line1: this.billingAddressForm.get('street')?.value,
              city: this.billingAddressForm.get('townCity')?.value,
              postal_code: this.billingAddressForm.get('zipCode')?.value,
              country: this.billingAddressForm.get('country')?.value,
            },
          },
        })
        .pipe(untilDestroyed(this));
    }
    this.enableForm();
    return throwError('Stripe instance is empty!');
  }

  public addCardToCustomer(): Observable<Intent> {
    this.disableForm();
    return this.createPaymentMethod().pipe(
      switchMap((stripeResponse) =>
        stripeResponse.paymentMethod
          ? this.paymentService.addCardToCustomer(stripeResponse.paymentMethod.id)
          : throwError('Payment method is empty!'),
      ),
      finalize(() => {
        this.enableForm();
      }),
    );
  }

  public automaticAddCardToCustomer(): void {
    this.addCardToCustomer()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => this.completed.emit(),
      });
  }

  public elementReady(type: StripeElementType): void {
    this.loadedElements.push(type);

    // check if all elements are ready
    if (
      this.loadedElements.includes(StripeElementType.CARD) &&
      this.loadedElements.includes(StripeElementType.DATE) &&
      this.loadedElements.includes(StripeElementType.CVV)
    ) {
      this.loadingStripeElements = false;

      this.cardNumberElementChange$ = this.cardNumberElement.change;
      this.cardExpiryElementChange$ = this.cardExpiryElement.change;
      this.cardCVVElementChange$ = this.cardCVVElement.change;

      // start with form validation checking
      combineLatest([
        this.cardNumberElementChange$,
        this.cardExpiryElementChange$,
        this.cardCVVElementChange$,
        this.stripeForm.statusChanges,
      ]).subscribe({
        next: (data) => {
          if (
            data[0].complete &&
            !data[0].error &&
            data[1].complete &&
            !data[1].error &&
            data[2].complete &&
            !data[2].error &&
            (data[3] === 'VALID' || data[3] === 'DISABLED')
          ) {
            this.stripeFormValid = true;
          } else {
            this.stripeFormValid = false;
          }
        },
      });
    }
  }

  public disableForm(): void {
    this.stripeForm.disable();
    this.cardOptions = {
      ...this.cardOptions,
      style: {base: {color: window.getComputedStyle(document.documentElement).getPropertyValue('--grey')}},
    };
  }
  public enableForm(): void {
    this.stripeForm.enable();
    this.cardOptions = {
      ...this.cardOptions,
      style: {base: {color: window.getComputedStyle(document.documentElement).getPropertyValue('--black')}},
    };
  }
}
