import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FetchResult} from '@apollo/client/core';
import {ModalData} from 'src/app/shared/components/modal/modal-config';
import {ModalComponent} from 'src/app/shared/components/modal/modal.component';
import {FaIcons} from 'src/app/shared/constants/fa-icons.enum';
import {PlaylistType} from 'src/app/shared/enums/playlist-privacy.enum';
import {PresignedImgPipe} from 'src/app/shared/pipes/presigned-img.pipe';
import {Playlist, UpdatePlaylistByIdGQL, UpdatePlaylistByIdMutation} from 'src/generated/graphql';
import {CoverImageUploadComponent} from '../cover-image-upload/cover-image-upload.component';

@Component({
  selector: 'app-modal-edit-playlist',
  templateUrl: './modal-edit-playlist.component.html',
  styleUrls: ['./modal-edit-playlist.component.scss'],
})
export class ModalEditPlaylistComponent implements OnInit {
  @ViewChild(CoverImageUploadComponent) private coverImageUploadComponent: CoverImageUploadComponent | undefined;

  public faIcons = FaIcons;
  public playlistType = PlaylistType;
  public imagePreview: string | null = null;
  public editPlayListForm: FormGroup;

  public get type(): FormControl {
    return this.editPlayListForm.get('type') as FormControl;
  }

  public get playlistName(): FormControl {
    return this.editPlayListForm.get('playlistName') as FormControl;
  }

  constructor(
    private parentComponent: ModalComponent,
    private formBuilder: FormBuilder,
    private updatePlaylistByIdGQL: UpdatePlaylistByIdGQL,
    private modalData: ModalData<Playlist>,
    private presignedImgPipe: PresignedImgPipe,
  ) {
    this.editPlayListForm = this.formBuilder.group({
      playlistName: ['', Validators.required],
      type: [PlaylistType.PRIVATE, Validators.required],
    });
  }

  public ngOnInit(): void {
    this.editPlayListForm.patchValue({
      playlistName: this.modalData.data?.title,
      type: this.modalData.data?.private === false ? PlaylistType.PUBLIC : PlaylistType.PRIVATE,
    });
    this.imagePreview = this.presignedImgPipe.transform(this.modalData.data?.presignedImgs) as string;
  }

  public editPlaylist(): void {
    if (!this.editPlayListForm.disabled) {
      this.editPlayListForm.disable();
      const typePrivate = this.type.value === PlaylistType.PRIVATE;
      this.updatePlaylistByIdGQL
        .mutate(
          {
            id: this.modalData.data?.id,
            title: this.playlistName.value,
            private: typePrivate,
          },
          {
            update: (store, mutationResult: FetchResult<UpdatePlaylistByIdMutation>) => {
              if (mutationResult.data?.updatePlaylistById?.playlist !== undefined) {
                const normalizedId = store.identify({id: this.modalData.data?.id, __typename: 'Playlist'});
                store.modify({
                  id: normalizedId,
                  fields: {
                    title: () => mutationResult.data?.updatePlaylistById?.playlist?.title,
                    private: () => mutationResult.data?.updatePlaylistById?.playlist?.private,
                  },
                });
              }
            },
          },
        )
        .subscribe({
          next: (response) => {
            if (response.data?.updatePlaylistById?.playlist?.id && this.coverImageUploadComponent) {
              this.coverImageUploadComponent
                .uploadImage(response.data.updatePlaylistById.playlist.id)
                .then(() => {
                  this.parentComponent.closeModal();
                })
                .catch(() => {
                  this.editPlayListForm.enable();
                });
            } else {
              this.parentComponent.closeModal();
            }
          },
          error: () => {
            this.editPlayListForm.enable();
          },
        });
    }
  }
}
