import {Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2} from '@angular/core';

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective implements OnDestroy {
  @Input() public set tooltipTitle(text: string) {
    this.tooltipText = text || '';
    if (this.tooltip && !this.disableTooltip) {
      this.hide();
      this.show();
    }
  }
  @Input() public placement = 'top';
  @Input() public delay = 250;
  @Input() public tooltipTarget?: HTMLElement | null;
  @Input() public disableTooltip = false;
  @Input() public tooltipClass = '';

  private tooltipText!: string;
  private tooltip!: HTMLElement | null;
  private offset = 10;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    if (!this.tooltip && !this.disableTooltip) {
      this.show();
    }
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    if (this.tooltip && !this.disableTooltip) {
      this.hide();
    }
  }

  public ngOnDestroy(): void {
    this.onMouseLeave();
    this.tooltip = null;
  }

  private show(): void {
    this.create();
    this.setPosition();
    this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
  }

  private hide(): void {
    this.renderer.removeClass(this.tooltip, 'ng-tooltip-show');
    this.renderer.removeChild(document.body, this.tooltip);
    this.tooltip = null;
  }

  private create(): void {
    this.tooltip = this.renderer.createElement('span');

    this.renderer.appendChild(this.tooltip, this.renderer.createText(this.tooltipText || ''));

    this.renderer.appendChild(document.body, this.tooltip);

    if (this.tooltipClass) {
      this.renderer.addClass(this.tooltip, `${this.tooltipClass}`);
    }

    this.renderer.addClass(this.tooltip, 'ng-tooltip');
    this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement}`);
    this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this.delay}ms`);
  }

  private setPosition(): void {
    const hostPos = this.tooltipTarget ? this.tooltipTarget?.getBoundingClientRect() : this.el.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltip?.getBoundingClientRect() || new DOMRect(0, 0, 0, 0);

    const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    let top;
    let left;

    if (this.placement === 'top') {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'bottom') {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'left') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }

    if (this.placement === 'right') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }
}
