import {Component, EventEmitter, Output} from '@angular/core';
import {FaIcons} from '../../constants/fa-icons.enum';

@Component({
  selector: 'app-change-photo-options',
  templateUrl: './change-photo-options.component.html',
  styleUrls: ['./change-photo-options.component.scss'],
})
export class ChangePhotoOptionsComponent {
  @Output() public removePhotoClick = new EventEmitter<void>();
  @Output() public editPhotoClick = new EventEmitter<void>();

  public trashIcon = FaIcons.trashAlt;
  public imagesIcon = FaIcons.images;
}
