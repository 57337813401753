<div fxLayout="column" class="profile-menu-popover-wrapper p-3" fxLayoutAlign="center end">
  <!-- FAVOURITE -->
  <ng-container *ngIf="!!popoverData.data?.favouritesButton">
    <a (click)="favourite(); close()" fxFlex fxLayoutAlign="end center" class="link">
      <span [fxHide]="!this.popoverData.data?.isLiked">Remove from favourite</span>
      <span [fxHide]="this.popoverData.data?.isLiked">Add to favourite</span>
      <fa-icon
        class="dark-grey"
        fxFlex="20px"
        fxLayoutAlign="center center"
        [ngClass]="this.popoverData.data?.isLiked ? 'blue' : 'dark-grey'"
        [icon]="heart"
      ></fa-icon>
    </a>
  </ng-container>

  <!-- SHARE -->
  <ng-container *ngIf="!!popoverData.data?.shareButton">
    <a (click)="share(); close()" fxFlex fxLayoutAlign="end center" class="link">
      <span>Share {{ popoverData.data?.type }}</span>
      <fa-icon class="dark-grey" fxFlex="20px" fxLayoutAlign="center center" [icon]="FaIcons.shareAlt"></fa-icon>
    </a>
  </ng-container>

  <!-- VIEW -->
  <ng-container *ngIf="!!popoverData.data?.viewButton">
    <a (click)="view(); close()" fxFlex fxLayoutAlign="end center" class="link">
      <span>View artist</span>
      <fa-icon class="dark-grey" fxFlex="20px" fxLayoutAlign="center center" [icon]="FaIcons.userMusic"></fa-icon>
    </a>
  </ng-container>

  <!-- ADD TO PLAYLIST -->
  <ng-container *ngIf="!!popoverData.data?.addToPlaylistButton">
    <a (click)="playlistAdd(); close()" fxFlex fxLayoutAlign="end center" class="link">
      <span>Add to playlist</span>
      <fa-icon class="dark-grey" fxFlex="20px" fxLayoutAlign="center center" [icon]="FaIcons.listMusic"></fa-icon>
    </a>
  </ng-container>

  <!-- REMOVE FROM PLAYLIST -->
  <ng-container *ngIf="!!popoverData.data?.removeFromPlaylistButton">
    <a (click)="playlistRemove(); close()" fxFlex fxLayoutAlign="end center" class="link">
      <span>Remove from playlist</span>
      <fa-icon class="dark-grey" fxFlex="20px" fxLayoutAlign="center center" [icon]="FaIcons.listMusic"></fa-icon>
    </a>
  </ng-container>

  <!-- EDIT -->
  <ng-container *ngIf="!!popoverData.data?.editButton">
    <a (click)="edit(); close()" fxFlex fxLayoutAlign="end center" class="link">
      <span>Edit {{ popoverData.data?.type }}</span>
      <fa-icon class="dark-grey" fxFlex="20px" fxLayoutAlign="center center" [icon]="FaIcons.pen"></fa-icon>
    </a>
  </ng-container>

  <!-- DELETE -->
  <ng-container *ngIf="!!popoverData.data?.deleteButton">
    <a (click)="this.delete(); close()" fxFlex fxLayoutAlign="end center" class="link">
      <span>Delete {{ popoverData.data?.type }}</span>
      <fa-icon class="dark-grey" fxFlex="20px" fxLayoutAlign="center center" [icon]="FaIcons.trashAlt"></fa-icon>
    </a>
  </ng-container>

  <!-- ADD TO QUEUE -->
  <ng-container *ngIf="!!popoverData.data?.addToQueueButton">
    <a (click)="queueAdd(); close()" fxFlex fxLayoutAlign="end center" class="link">
      <span>Add to queue</span>
      <fa-icon class="dark-grey" fxFlex="20px" fxLayoutAlign="center center" [icon]="FaIcons.addToQueue"></fa-icon>
    </a>
  </ng-container>

  <!-- REMOVE FROM QUEUE -->
  <ng-container *ngIf="!!popoverData.data?.removeFromQueueButton">
    <a (click)="queueRemove(); close()" fxFlex fxLayoutAlign="end center" class="link">
      <span>Remove from queue</span>
      <fa-icon class="dark-grey" fxFlex="20px" fxLayoutAlign="center center" [icon]="FaIcons.addToQueue"></fa-icon>
    </a>
  </ng-container>
</div>
