import {Component, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {PopoverData} from 'src/app/shared/components/popover/popover-config';
import {PopoverComponent} from 'src/app/shared/components/popover/popover.component';
import {FaIcons} from 'src/app/shared/constants/fa-icons.enum';
import {CacheHelperService} from 'src/app/shared/services/cache-helper.service';
import {FollowService} from '../../services/follow.service';

export interface PopoverArtistOptionData {
  showShareButton: boolean;
  artistId: string | undefined;
  userId: string | undefined;
  artistIsFollowing: boolean | undefined;
  userIsFollowing: boolean | undefined;
}

@UntilDestroy()
@Component({
  selector: 'app-popover-artist-options',
  templateUrl: './popover-artist-options.component.html',
  styleUrls: ['./popover-artist-options.component.scss'],
})
export class PopoverArtistOptionsComponent implements OnInit {
  public FaIcons = FaIcons;

  public isUserArtist: boolean | undefined;
  public userName: string | null | undefined;
  public artistName: string | null | undefined;

  public artistIsFollowing: boolean | undefined;
  public userIsFollowing: boolean | undefined;

  constructor(
    private popoverParentComponent: PopoverComponent,
    public popoverData: PopoverData<PopoverArtistOptionData>,
    private followService: FollowService,
    private cacheHelperService: CacheHelperService,
  ) {
    this.isUserArtist = this.cacheHelperService.isUserArtist();
    this.userName = this.cacheHelperService.getCurrentUserCache().profilePublicByUserId?.displayName;
    this.artistName = this.cacheHelperService.getCurrentArtistCache()?.displayName;
  }

  public ngOnInit(): void {
    if (this.popoverData.data?.userIsFollowing === undefined) {
      this.followService
        .isLiked(
          undefined,
          this.cacheHelperService.getCurrentUserCache().id,
          this.popoverData.data?.artistId,
          this.popoverData.data?.userId,
        )
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (isFollowing) => {
            this.userIsFollowing = isFollowing;
          },
        });
    } else {
      this.userIsFollowing = this.popoverData.data.userIsFollowing;
    }

    if (this.popoverData.data?.artistIsFollowing === undefined) {
      if (this.isUserArtist) {
        this.followService
          .isLiked(
            this.cacheHelperService.getCurrentArtistCache().id,
            undefined,
            this.popoverData.data?.artistId,
            this.popoverData.data?.userId,
          )
          .pipe(untilDestroyed(this))
          .subscribe({
            next: (isFollowing) => {
              this.artistIsFollowing = isFollowing;
            },
          });
      }
    } else {
      this.artistIsFollowing = this.popoverData.data.artistIsFollowing;
    }
  }

  public followOrUnfollow(asUser: boolean): void {
    if (asUser) {
      if (this.userIsFollowing) {
        this.followService
          .unfollow(undefined, this.popoverData.data?.artistId, this.popoverData.data?.userId, !this.artistIsFollowing)
          .pipe(untilDestroyed(this))
          .subscribe();
      } else {
        this.followService
          .follow(undefined, this.popoverData.data?.artistId, this.popoverData.data?.userId, !this.userIsFollowing)
          .pipe(untilDestroyed(this))
          .subscribe();
      }
    } else if (this.artistIsFollowing) {
      this.followService
        .unfollow(
          this.cacheHelperService.getCurrentArtistCache().id,
          this.popoverData.data?.artistId,
          this.popoverData.data?.userId,
          !this.userIsFollowing,
        )
        .pipe(untilDestroyed(this))
        .subscribe();
    } else {
      this.followService
        .follow(
          this.cacheHelperService.getCurrentArtistCache().id,
          this.popoverData.data?.artistId,
          this.popoverData.data?.userId,
          !this.artistIsFollowing,
        )
        .pipe(untilDestroyed(this))
        .subscribe();
    }
  }

  public close(): void {
    this.popoverParentComponent.closePopover();
  }
}
