import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {
  PopoverArtistOptionData,
  PopoverArtistOptionsComponent,
} from 'src/app/blare-app/shared/components/popover-artist-options/popover-artist-options.component';
import {FaIcons} from 'src/app/shared/constants/fa-icons.enum';
import {ImgSize} from 'src/app/shared/constants/img-size.enum';
import {Artist} from 'src/generated/graphql';
import {PopoverService} from '../../popover/popover.service';
import {TileBaseComponent} from '../tile-base.component';

@Component({
  selector: 'app-tile-artist',
  templateUrl: './../tile-base.component.html',
  styleUrls: ['./../tile-base.component.scss'],
})
export class TileArtistComponent extends TileBaseComponent {
  @Input() public set item(value: Artist) {
    this.id = value.id ? value.id : '';
    this.title = value.displayName ? value.displayName : '';
    this.img = value.presignedProfileImgs?.find((x) => x?.size === ImgSize.Small)?.url || null;
    this.subtitle = `${value.likesByArtistId?.totalCount ? value.likesByArtistId.totalCount : 0} followers`;
    this.type = value.__typename ? value.__typename : undefined;
  }

  public borderRadius = '50%';
  public icon = FaIcons.userMusic;

  constructor(private router: Router, private popoverService: PopoverService) {
    super();
  }

  public leftClick(): void {
    this.router.navigate(['artist', this.id]);
  }

  public rightClick(event: MouseEvent): void {
    event.preventDefault();

    this.popoverService.show<PopoverArtistOptionData>(
      {
        x: event.clientX + 16,
        y: event.clientY - 4,
      },
      {
        content: PopoverArtistOptionsComponent,
        data: {
          showShareButton: true,
          artistId: this.id,
          userId: undefined,
          userIsFollowing: undefined,
          artistIsFollowing: undefined,
        },
      },
      true,
    );
  }
}
