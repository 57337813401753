import {Pipe, PipeTransform} from '@angular/core';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {NotificationType} from 'src/generated/graphql';
import {FaIcons} from '../constants/fa-icons.enum';

@Pipe({
  name: 'notificationIcon',
})
export class NotificationIconPipe implements PipeTransform {
  public transform(notificationType: NotificationType): IconProp {
    if (notificationType === NotificationType.NewConcert) {
      return FaIcons.calendarStar;
    }
    if (notificationType === NotificationType.NewFollower) {
      return FaIcons.user;
    }
    if (notificationType === NotificationType.NewRecommendations) {
      return FaIcons.headphones;
    }
    if (notificationType === NotificationType.NewSong) {
      return FaIcons.music;
    }
    return FaIcons.bell;
  }
}
