<app-smooth-height [trigger]="verificationStep">
  <div fxLayoutAlign="start center" fxLayout="column" *ngIf="verificationStep === verificationFlow.FULLNAME">
    <h4>Account verification</h4>
    <fa-icon class="mt-5 light" [icon]="FaIcons.fileUser" size="2x"></fa-icon>

    <ng-container *ngIf="verificationStep === verificationFlow.FULLNAME">
      <span class="mt-5 light text-center">
        Please provide your legal first and last name as they appear on the identification document you intend to use for the verification
        process.
      </span>
      <form [formGroup]="additionalInfoForm" fxLayout="column" class="w-100 mt-4">
        <div class="input-field-wrapper">
          <label for="firstName">First name</label>
          <input id="firstName" formControlName="firstName" type="text" placeholder="Saul" />
        </div>

        <div class="input-field-wrapper mt-4">
          <label for="lastName">Last name</label>
          <input id="lastName" formControlName="lastName" type="text" placeholder="Hudson" />
        </div>
      </form>
      <div class="mt-5" fxLayoutAlign="start center" fxLayout="row">
        <button class="big secondary" (click)="backButtonClick.emit()">Back</button>
        <button
          class="ml-3 big"
          (click)="onfidoStepClick.emit(); verificationStep = verificationFlow.ONFIDO"
          [disabled]="additionalInfoForm.invalid"
        >
          Continue
        </button>
      </div>
    </ng-container>
  </div>
  <div class="fido-wrapper" *ngIf="verificationStep === verificationFlow.ONFIDO" fxLayoutAlign="start center" fxLayout="column">
    <div class="dummy-div-for-animation" [class.loader]="loadingOnFidoElement"></div>
    <div #onfidoMountDiv id="onfido-mount"></div>
    <button
      class="big secondary my-2"
      (click)="verificationStep = verificationFlow.FULLNAME; loadingOnFidoElement = true; fidoBackButtonClick.emit()"
    >
      Back
    </button>
  </div>
</app-smooth-height>
