<div ngClass.lt-md="p-3">
  <div
    class="modal-wrapper"
    ngClass.lt-md="mobile"
    ngClass.gt-sm="desktop"
    [@slideInOutAnimation]="animationState"
    (@slideInOutAnimation.done)="animationEnd()"
    [style.padding]="data.padding"
    [style.max-width]="data.width"
    ngClass.lt-md="p-3"
  >
    <ng-container [ngSwitch]="renderMethod">
      <ng-container *ngSwitchCase="'template'">
        <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'component'">
        <ng-container *ngComponentOutlet="content"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
