import {Directive, HostBinding, HostListener, Optional} from '@angular/core';
import {FormControlName} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {startWith} from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[appInputErrorClass]',
})
export class InputErrorClassDirective {
  @HostBinding('class') public class = '';

  public touched = false;

  constructor(@Optional() public formControlName: FormControlName) {
    setTimeout(() => {
      this.formControlName.statusChanges?.pipe(untilDestroyed(this), startWith(this.formControlName.status)).subscribe(() => {
        this.checkIfValidAndTouched();
      });
    }, 0);
  }

  @HostListener('blur', ['$event'])
  public onFocus(): void {
    this.touched = true;
    this.checkIfValidAndTouched();
  }

  @HostListener('input', ['$event'])
  public onChange(): void {
    this.touched = true;
    this.checkIfValidAndTouched();
  }

  public checkIfValidAndTouched(): void {
    if (this.formControlName.touched || this.touched) {
      if (this.formControlName.status === 'INVALID') {
        this.class = 'error';
      } else {
        this.class = '';
      }
    }
  }
}
