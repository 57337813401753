import {Injectable} from '@angular/core';
import {GetAllGenresGQL, Genre} from 'src/generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class GenresService {
  public genresAreLoaded: Promise<void>;

  public parentGenres: Genre[] = [];
  public childGenres: {[parentId: string]: Genre[]} = {};

  constructor(private getAllGenresGQL: GetAllGenresGQL) {
    this.genresAreLoaded = new Promise((resolve, reject) => {
      this.getAllGenresGQL.fetch().subscribe((response) => {
        response.data.allGenres?.edges.forEach((genre) => {
          if (genre.node?.parentCategory) {
            if (this.childGenres[genre.node.parentCategory]) {
              this.childGenres[genre.node.parentCategory].push(genre.node as Genre);
            } else {
              this.childGenres[genre.node.parentCategory] = [genre.node as Genre];
            }
          } else {
            this.parentGenres.push(genre.node as Genre);
          }
        });
        resolve();
      }, reject);
    });
  }
}
