import {Injectable} from '@angular/core';
import {Observable, Subscription, throwError} from 'rxjs';
import {mergeMap, tap} from 'rxjs/operators';
import {ToastService} from 'src/app/shared/components/toast/toast.service';
import {CacheHelperService} from 'src/app/shared/services/cache-helper.service';
import {
  AddToAutoGeneratedQueueGQL,
  AddToUsersQueueGQL,
  AllAutoGeneratedQueueGQL,
  AllUsersQueueGQL,
  AutoGeneratedQueue,
  AutoGeneratedQueuesOrderBy,
  DeleteFromQueueGQL,
  EmptyAutoGeneratedQueueGQL,
  Track,
  TrackDetailsGQL,
  UpdateAutoGeneratedQueueByIdGQL,
  UpdateNextUpGQL,
  UpdateNextUpMutationVariables,
} from 'src/generated/graphql';
import {PlayerStore} from './player.store';

@Injectable({providedIn: 'root'})
export class PlayerService {
  public trackDetailsSubscription: Subscription | undefined;

  constructor(
    private playerStore: PlayerStore,
    private trackDetailsGQL: TrackDetailsGQL,
    private emptyAutoGeneratedQueueGQL: EmptyAutoGeneratedQueueGQL,
    private addToAutoGeneratedQueueGQL: AddToAutoGeneratedQueueGQL,
    private allAutoGeneratedQueueGQL: AllAutoGeneratedQueueGQL,
    private cacheHelperService: CacheHelperService,
    private addToUsersQueueGQL: AddToUsersQueueGQL,
    private allUsersQueueQuery: AllUsersQueueGQL,
    private updateNextUp: UpdateNextUpGQL,
    private updateAutoGeneratedQueueByIdGQL: UpdateAutoGeneratedQueueByIdGQL,
    private toastService: ToastService,
    private deleteUsersQueue: DeleteFromQueueGQL,
  ) {}

  public changeTrack(track: string | Track, play = false): void {
    if (this.trackDetailsSubscription) {
      this.trackDetailsSubscription.unsubscribe();
      this.trackDetailsSubscription = undefined;
    }

    if (typeof track === 'string') {
      this.trackDetailsSubscription = this.trackDetailsGQL.fetch({id: track}).subscribe((response) => {
        if (response.data.trackById) {
          this.playerStore.update({currentPlayingTrack: {...response.data.trackById} as Track});
          if (play) {
            this.playerStore.play();
          }
        }
      });
    } else {
      this.playerStore.update({currentPlayingTrack: {...track}});
      if (play) {
        this.playerStore.play();
      }
    }
  }

  public playPreviousTrack(currentlyPlayingQueueItem: AutoGeneratedQueue, newPlayingQueueItem: AutoGeneratedQueue): Observable<unknown> {
    this.changeTrack(newPlayingQueueItem.trackByTrackId as Track);
    return this.updateAutoGeneratedQueueByIdGQL.mutate(
      {id: currentlyPlayingQueueItem.id, listened: false},
      {
        update: (store) => {
          const normalizedId = store.identify({id: currentlyPlayingQueueItem.id, __typename: 'AutoGeneratedQueue'});
          store.modify({
            id: normalizedId,
            fields: {
              listened: () => false,
            },
          });
        },
      },
    );
  }

  public addTracksToNextUp(tracksToAdd: Track[]): Observable<unknown> {
    // 1. Empty nextUp on BE
    // 2. Add tracks to nextUp on BE
    // 3. Refetch nextUp
    // 4. Add first track to player & Pop from Backend

    const newQueueTracks = tracksToAdd.map((x) => ({
      trackId: x.id,
      userId: this.cacheHelperService.getCurrentUserCache().id,
    }));

    return this.emptyAutoGeneratedQueueGQL.mutate().pipe(
      mergeMap(() =>
        this.addToAutoGeneratedQueueGQL.mutate(
          {queueObjectList: newQueueTracks},
          {
            refetchQueries: [
              {
                query: this.allAutoGeneratedQueueGQL.document,
                variables: {orderBy: AutoGeneratedQueuesOrderBy.TrackNumberAsc},
              },
            ],
          },
        ),
      ),
      mergeMap((response: any) => {
        const nextTrackQueueId = response.data.mnCreateAutoGeneratedQueue.autoGeneratedQueueEdge.node.id;
        this.changeTrack(tracksToAdd[0]);
        return this.updateAutoGeneratedQueueById(nextTrackQueueId);
      }),
      tap({
        next: () => this.playerStore.play(),
      }),
    );
  }

  public addTracksToQueue(tracksToAdd: Track[]): Observable<unknown> {
    const newQueueTracks = tracksToAdd.map((x) => ({
      trackId: x.id,
      userId: this.cacheHelperService.getCurrentUserCache().id,
    }));

    return this.addToUsersQueueGQL
      .mutate(
        {queueObjectList: newQueueTracks},
        {
          refetchQueries: [
            {
              query: this.allUsersQueueQuery.document,
            },
          ],
        },
      )
      .pipe(
        tap({
          next: () => {
            this.toastService.show({
              text: 'The songs have been added to your queue',
              type: 'success',
            });
          },
        }),
      );
  }

  // Update players Next Up via album/playlist id
  public updateNextUpById(variables: UpdateNextUpMutationVariables): Observable<unknown> {
    return this.updateNextUp
      .mutate(variables, {
        fetchPolicy: 'no-cache',
        refetchQueries: [
          {
            query: this.allAutoGeneratedQueueGQL.document,
            variables: {orderBy: AutoGeneratedQueuesOrderBy.TrackNumberAsc},
          },
        ],
      })
      .pipe(
        mergeMap((response) => {
          // NOTE: id is queueID & not the trackID
          const tmpQueue = response.data?.updateNextUp?.autoGeneratedQueues as Array<AutoGeneratedQueue>;
          const firstNode = tmpQueue[0];
          console.log(tmpQueue);

          if (tmpQueue.length) {
            this.changeTrack(firstNode?.trackByTrackId as Track);
            return this.updateAutoGeneratedQueueById(firstNode?.id);
          }

          // Should block this function from even running if no tracks are loaded
          // This is just a backup, to stop error spamming
          // Recommendation kicks in automatically from here on out
          return throwError('There are no tracks to load');
        }),
        tap({
          next: () => this.playerStore.play(),
        }),
      );
  }

  // Pop the new selected song out of Next Up
  public updateAutoGeneratedQueueById(trackQueueId: string): Observable<unknown> {
    return this.updateAutoGeneratedQueueByIdGQL.mutate(
      {id: trackQueueId, listened: true},
      {
        update: (store) => {
          setTimeout(() => {
            const normalizedId = store.identify({id: trackQueueId, __typename: 'AutoGeneratedQueue'});
            store.modify({
              id: normalizedId,
              fields: {
                listened: () => true,
              },
            });
          }, 200);
        },
      },
    );
  }

  public deleteFromUsersQueue(queueItemId: string): Observable<unknown> {
    return this.deleteUsersQueue.mutate(
      {trackId: queueItemId},
      {
        update: (store) => {
          store.modify({
            fields: {
              allUsersQueues: (userQueue = {}) => ({
                ...userQueue,
                edges: userQueue.edges.slice(1),
              }),
            },
          });
        },
      },
    );
  }

  public generateRandomSequenceIndexes(autoQueueOriginalLength: number | undefined): number[] {
    return Array(autoQueueOriginalLength)
      .fill(-1)
      .map((_, i) => i + 1)
      .sort(() => 0.5 - Math.random());
  }
}
