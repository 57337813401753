import {ComponentRef, Injectable, Injector} from '@angular/core';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';

import {MediaObserver} from '@angular/flex-layout';
import {ModalComponent} from './modal.component';
import {ModalData, ModalPosition} from './modal-config';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private overlay: Overlay, private parentInjector: Injector, private mediaObserver: MediaObserver) {}

  public show<T>(data: ModalData<T>): ComponentRef<ModalComponent> {
    const overlayRef = this.overlay.create(this.getOverlayConfig(data.position));

    const injector = this.getInjector(data, overlayRef, this.parentInjector);
    const modalPortal = new ComponentPortal(ModalComponent, null, injector);

    const componentRef = overlayRef.attach(modalPortal);

    return componentRef;
  }

  private getOverlayConfig(position: ModalPosition | undefined): OverlayConfig {
    let positionStrategy = this.overlay.position().global().centerHorizontally();

    const isOnMobile = this.mediaObserver.isActive('lt-md');

    if (position === 'center' || isOnMobile) {
      positionStrategy = positionStrategy.centerVertically();
    } else if (position === 'top' || position === undefined) {
      positionStrategy = positionStrategy.top('4%');
    }

    return new OverlayConfig({
      hasBackdrop: true,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });
  }

  private getInjector(data: ModalData<unknown>, overlayRef: OverlayRef, parentInjector: Injector): Injector {
    return Injector.create({
      parent: parentInjector,
      providers: [
        {provide: ModalData, useValue: data},
        {provide: OverlayRef, useValue: overlayRef},
        {provide: ModalComponent, useValue: this},
      ],
    });
  }
}
