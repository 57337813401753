<div class="input-field-wrapper" [class.disabled]="disabled" appPrefixInput>
  <label for="blarecastUrl">{{ labelText }}</label>
  <input
    #blarecastUrl
    id="blarecastUrl"
    type="text"
    placeholder="composer36"
    [(ngModel)]="value"
    (change)="valueChange(blarecastUrl.value, $event)"
    (keyup)="valueChange(blarecastUrl.value, $event)"
    (paste)="valueChange(blarecastUrl.value, $event)"
    (blur)="setTouched()"
    [disabled]="disabled"
    appInputErrorClass
  />
  <div class="prefix">
    blarecast.com/<ng-container *ngIf="isUserArtist">artist</ng-container><ng-container *ngIf="!isUserArtist">listener</ng-container>/
  </div>
  <div class="note" [class.error]="ngControl && ngControl.errors && ngControl.errors['urlIsTaken']" *ngIf="showUniqueNote" [@fadeInOut]>
    Blarecast URL must be unique
  </div>
  <ng-container *ngIf="ngControl && ngControl.errors">
    <ng-container *ngIf="!ngControl?.pristine">
      <div class="note error" [@fadeInOut] *ngIf="ngControl.errors['minlenght']">
        Blarecast URL must be unique and longer than 1 character
      </div>
      <div class="note error" [@fadeInOut] *ngIf="ngControl.errors['maxlenght']">
        Blarecast URL must be unique and shorter than 50 characters
      </div>
    </ng-container>
    <div
      class="note error"
      [@fadeInOut]
      *ngIf="!ngControl.errors['maxlenght'] && !ngControl.errors['minlenght'] && ngControl.errors['specialChars']"
    >
      Blarecast URL can only have letters, numbers, dashes, periods and underscores
    </div>
  </ng-container>
</div>
