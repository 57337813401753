import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {DatePipe} from '@angular/common';
import {ChartsModule} from 'ng2-charts';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig} from '@abacritt/angularx-social-login';
import {environment} from 'src/environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {GraphQLModule} from './graphql.module';

const googleLoginOptions = {
  scope: 'profile email',
  plugin_name: 'login', // you need this, otherwise it crashes (why? no clue.)
};

const fbLoginOptions = {
  scope: 'email',
};

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, CoreModule, BrowserAnimationsModule, ChartsModule, SharedModule, GraphQLModule],
  providers: [
    DatePipe,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleLoginProvider, googleLoginOptions),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookLoginProvider, fbLoginOptions),
          },
        ],
        onError: (error) => console.error('Social Login Error (module)', error),
      } as SocialAuthServiceConfig,
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
