import {Component, Input, EventEmitter, Output, NgZone} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Apollo} from 'apollo-angular';
import {fadeInOut} from 'src/app/shared/animations/animations';
import {ModalService} from 'src/app/shared/components/modal/modal.service';
import {ToastService} from 'src/app/shared/components/toast/toast.service';
import {FaIcons} from 'src/app/shared/constants/fa-icons.enum';
import {ImgSize} from 'src/app/shared/constants/img-size.enum';
import {UploadProgress} from '../../interfaces/upload.interface';
import {UploadService, UploadURLParameters} from '../../services/upload.service';
import {ModalImgCropperComponent, ImgCropperModalData} from '../modal-img-cropper/modal-img-cropper.component';

export type CoverImageType = 'album' | 'playlist' | 'song';
@UntilDestroy()
@Component({
  selector: 'app-cover-image-upload',
  templateUrl: './cover-image-upload.component.html',
  styleUrls: ['./cover-image-upload.component.scss'],
  animations: [fadeInOut],
})
export class CoverImageUploadComponent {
  @Input() public entityId = '';
  @Input() public imagePreview: string | null = null;
  @Output() public imagePreviewChange = new EventEmitter<string | null>();

  @Input() public disableFilePicking = false;

  public iconType = FaIcons.music;
  public imageLoading = false;
  public error = false;
  public mouseOverImagePreview = false;
  public uploadProgress: UploadProgress = {progress: 0, state: 'PENDING'};
  public fileToUpload: File | undefined;

  public readonly blueColorHex = getComputedStyle(document.documentElement).getPropertyValue('--blue');
  public readonly greyColorHex = getComputedStyle(document.documentElement).getPropertyValue('--grey');

  private _coverImageType: CoverImageType = 'song';
  public get coverImageType(): CoverImageType {
    return this._coverImageType;
  }
  @Input() public set coverImageType(value: CoverImageType) {
    if (value === 'album') {
      this.iconType = FaIcons.albumCollection;
    }
    if (value === 'playlist') {
      this.iconType = FaIcons.listMusic;
    }
    this._coverImageType = value;
  }

  constructor(
    private toastService: ToastService,
    private uploadService: UploadService,
    private modalService: ModalService,
    private ngZone: NgZone,
    private apollo: Apollo,
  ) {}

  public openImageCropper(): void {
    this.modalService
      .show<ImgCropperModalData>({
        content: ModalImgCropperComponent,
        padding: '16px',
        width: '864px',
        data: {aspectRatio: 1},
      })
      .instance.closeData.subscribe((file: File) => {
        this.error = false;

        // 10MB in bytes
        if (file.size > 10485760) {
          this.toastService.show({
            text: 'Cover image size exceeds 10MB limit. Please choose a smaller image.',
            type: 'error',
            timeout: null,
          });
          this.error = true;
        } else {
          this.fileToUpload = file;
          if (this.entityId) {
            this.uploadImage(this.entityId);
          }

          this.imagePreview = URL.createObjectURL(file);
          this.imagePreviewChange.emit(this.imagePreview);
        }
      });
  }

  public uploadImage(entityId: string): Promise<string | null> {
    return new Promise((resolve, reject) => {
      let uploadURLParameters: UploadURLParameters = {trackId: entityId, isCoverImage: true};

      if (this.coverImageType === 'album') {
        uploadURLParameters = {albumId: entityId};
      }

      if (this.coverImageType === 'playlist') {
        uploadURLParameters = {playlistId: entityId};
      }
      if (this.fileToUpload) {
        this.imageLoading = true;
        this.disableFilePicking = true;
        this.uploadService
          .uploadFile(uploadURLParameters, this.fileToUpload)
          .pipe(untilDestroyed(this))
          .subscribe(
            (progress) => {
              this.uploadProgress = progress;
              if (progress.state === 'DONE') {
                this.ngZone.run(() => {
                  // I don't know why, been pulling my hair trying to figure out why this must be in ngZone
                  this.imageLoading = false;
                  this.disableFilePicking = false;
                  this.mouseOverImagePreview = false;
                  this.toastService.show({text: 'Cover image uploaded successfully.', type: 'success'});
                });

                let typename = 'Track';
                if (this.coverImageType === 'album') {
                  typename = 'Album';
                }
                if (this.coverImageType === 'playlist') {
                  typename = 'Playlist';
                }
                const normalizedId = this.apollo.client.cache.identify({id: this.entityId || entityId, __typename: typename});

                this.apollo.client.cache.modify({
                  id: normalizedId,
                  fields: {
                    presignedImgs: () =>
                      Object.values(ImgSize).map((x) => ({
                        size: x,
                        url: this.imagePreview,
                        __typename: 'PresignedImage',
                      })),
                  },
                });

                resolve(this.imagePreview);
              }
            },
            () => {
              this.imageLoading = false;
              this.disableFilePicking = false;
              this.imagePreview = null;
              reject();
            },
          );
      } else {
        resolve(this.imagePreview);
      }
    });
  }
}
