import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import Cropper from 'cropperjs';
import {ModalData} from 'src/app/shared/components/modal/modal-config';
import {ModalComponent} from 'src/app/shared/components/modal/modal.component';
import {FilePickerDirective} from 'src/app/shared/directives/file-picker.directive';

export interface ImgCropperModalData {
  aspectRatio: number;
}

@Component({
  selector: 'app-modal-img-cropper',
  templateUrl: './modal-img-cropper.component.html',
  styleUrls: ['./modal-img-cropper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalImgCropperComponent implements OnInit {
  @ViewChild(FilePickerDirective) private filePickerDirective: FilePickerDirective | undefined;

  public cropper: Cropper | undefined;

  private cropperSelectionInitWidth: number | undefined;
  private cropperSelectionInitHeight: number | undefined;

  constructor(public parentComponent: ModalComponent, public modalData: ModalData<ImgCropperModalData>) {}

  public ngOnInit(): void {
    const template = `
    <cropper-canvas background>
      <cropper-image src="/assets/images/auth-background.png" alt="Picture"></cropper-image>
      <cropper-shade hidden></cropper-shade>
      <cropper-handle action="select" plain></cropper-handle>
      <cropper-selection aspect-ratio="${this.modalData.data?.aspectRatio}" initial-coverage="1" movable resizable>
        <cropper-grid role="grid" bordered covered></cropper-grid>
        <cropper-handle action="move" theme-color="rgba(255, 255, 255, 0.35)"></cropper-handle>
        <cropper-handle action="n-resize"></cropper-handle>
        <cropper-handle action="e-resize"></cropper-handle>
        <cropper-handle action="s-resize"></cropper-handle>
        <cropper-handle action="w-resize"></cropper-handle>
        <cropper-handle action="ne-resize"></cropper-handle>
        <cropper-handle action="nw-resize"></cropper-handle>
        <cropper-handle action="se-resize"></cropper-handle>
        <cropper-handle action="sw-resize"></cropper-handle>
      </cropper-selection>
    </cropper-canvas>`;

    this.cropper = new Cropper('#image', {template});

    this.cropperSelectionInitWidth = this.cropper?.getCropperSelection()?.width;
    this.cropperSelectionInitHeight = this.cropper?.getCropperSelection()?.height;

    this.cropper
      .getCropperImage()
      ?.$ready()
      .then(() => {
        if (this.filePickerDirective) {
          this.filePickerDirective.onClickOpenInput();
        }
      });
  }

  public fileChange(files: FileList | null): void {
    if (files) {
      const cropperImage = this.cropper?.getCropperImage();
      if (cropperImage) {
        cropperImage.$image.src = URL.createObjectURL(files[0]);
        cropperImage.$ready().then(() => {
          cropperImage.$center('contain');
          this.cropper?.getCropperSelection()?.$change(0, 0, this.cropperSelectionInitWidth, this.cropperSelectionInitHeight);
          this.cropper?.getCropperSelection()?.$center();
        });
      }
    }
  }

  public confirmSelection(): void {
    this.cropper
      ?.getCropperSelection()
      ?.$toCanvas()
      .then((canvas) => {
        canvas.toBlob(
          (blob) => {
            if (blob) {
              const imgFile = new File([blob], 'croppedImg.jpg', {type: 'image/jpeg'});
              this.parentComponent.closeData.next(imgFile);
            }
          },
          'image/jpeg',
          1,
        );
        this.parentComponent.closeModal();
      });
  }
}
