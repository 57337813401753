import {OverlayRef} from '@angular/cdk/overlay';
import {Component, HostListener, OnInit, TemplateRef} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Subject} from 'rxjs';
import {slideFromTop} from '../../animations/animations';
import {ModalData} from './modal-config';

@UntilDestroy()
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['modal.component.scss'],
  animations: [slideFromTop],
})
export class ModalComponent implements OnInit {
  public renderMethod: 'template' | 'component' = 'component';
  public animationState = 'enter';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public context: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public content: any;

  public closeType = '';
  public closeEvent = new Subject<string>();
  public closeData = new Subject<any>();

  private alreadyCalled = false; // for some reason when leave animation is done, callback is called twice

  @HostListener('document:keydown.escape', ['$event'])
  private onKeydownHandler() {
    this.closeType = 'backdropClick';
    this.closeModal();
  }

  constructor(public data: ModalData<unknown>, public overlayRef: OverlayRef) {}

  public ngOnInit(): void {
    this.content = this.data.content;

    if (this.content instanceof TemplateRef) {
      this.renderMethod = 'template';
      this.context = {
        close: this.closeModal.bind(this),
        data: this.data.data,
      };
    }

    this.overlayRef
      .backdropClick()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.closeType = 'backdropClick';
        this.closeModal();
      });
  }

  public closeModal(closeType?: string): void {
    this.animationState = 'leave';
    this.closeType = closeType || this.closeType;
  }

  public animationEnd(): void {
    if (this.animationState === 'leave' && !this.alreadyCalled) {
      this.overlayRef.dispose();
      this.alreadyCalled = true;
      this.closeEvent.next(this.closeType);
    }
  }
}
