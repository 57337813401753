import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {explicitIcon} from '../../constants/fa-icons.enum';

@Component({
  selector: 'app-title-subtitle',
  templateUrl: './title-subtitle.component.html',
  styleUrls: ['./title-subtitle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleSubtitleComponent {
  @Input() public title = '';
  @Input() public subtitle: string | number = '';
  @Input() public centerAlign = true;
  @Input() public disabled = false;
  @Input() public explicit = false;

  public readonly explicitIcon = explicitIcon;
}
