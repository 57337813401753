<div
  class="choose-image-wrapper"
  [ngClass]="coverImageType"
  [class.error]="error"
  [style.cursor]="disableFilePicking ? 'unset' : 'pointer'"
>
  <img *ngIf="imagePreview" (error)="imagePreview = null" [src]="imagePreview | imageFromObject" />

  <div
    *ngIf="!disableFilePicking"
    (mouseenter)="mouseOverImagePreview = true"
    (mouseleave)="mouseOverImagePreview = false"
    (click)="openImageCropper()"
    class="file-picker"
  ></div>

  <div class="choose-image-inner-wrapper" fxFlex [class.blur]="(mouseOverImagePreview && imagePreview) || imageLoading">
    <ng-container *ngIf="!imageLoading">
      <fa-icon
        *ngIf="!imagePreview || (imagePreview && mouseOverImagePreview)"
        [class.center-icon]="disableFilePicking"
        [icon]="iconType"
        class="grey icon"
        size="2x"
      ></fa-icon>
      <div class="text text-center w-100">
        <span class="grey" *ngIf="!imagePreview && !disableFilePicking" [@fadeInOut]>Choose image</span>
        <span class="grey" *ngIf="imagePreview && mouseOverImagePreview">Change image</span>
      </div>
    </ng-container>
    <round-progress
      class="center"
      *ngIf="imageLoading"
      [radius]="24"
      [stroke]="4"
      [color]="blueColorHex"
      [background]="greyColorHex"
      [current]="uploadProgress.progress"
      [max]="100"
    ></round-progress>
  </div>
</div>
