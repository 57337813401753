import {Component, Input} from '@angular/core';
import {PackageFeature} from '../../interfaces/package-feature.interface';

export interface PackageSubscription {
  id: string;
  key: string;
  name: string;
  price1: string;
  price2: string;
  priceClass: string;
  text: string;
  features: PackageFeature[];
}

@Component({
  selector: 'app-package-subscription',
  templateUrl: './package-subscription.component.html',
  styleUrls: ['./package-subscription.component.scss'],
})
export class PackageSubscriptionComponent {
  @Input() public selected = false;
  @Input() public togglePrice = false;
  @Input() public package: PackageSubscription | undefined;
}
