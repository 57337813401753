export type ToastType = 'error' | 'success' | 'info';

export class ToastData {
  public text = '';
  public type: ToastType;
  // timeout null will never close down toast, user will must click on it, to close it
  public timeout?: number | undefined | null;

  constructor({text, type, timeout}: {text: string; type: ToastType; timeout?: number | null}) {
    this.text = text;
    this.type = type;

    if (timeout === undefined) {
      // Defualt timeout value
      this.timeout = 3500;
    } else {
      this.timeout = timeout;
    }
  }
}
