import {environment} from 'src/environments/environment';

export const TRACK_TO_PLAY_ID = 'trackToPlayId';

export const appConfig = {
  backendURL: environment.backendURL,
  baseAssetsURL: environment.baseAssetsURL,
  emailRegex: '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$',
  minPasswordLength: 8,
  minBlareCastUrlLength: 2,
  maxBlareCastUrlLength: 50,
  blareCastUrlRegex: new RegExp(/[^a-z0-9+-_.]*?$/), // ^[a-z0-9+-_.]*?$ - doesn't work
  minDisplayNameLength: 2,
  maxDisplayNameLength: 50,
  priceDivider: 10000,
};

export const appSharedRoutes = {
  settings: 'settings',
  upload: 'upload',
  myUploads: 'my-music',
  about: 'about',
  handcashSuccess: 'handcash-success',
  handcashFail: 'handcash-fail',
};
