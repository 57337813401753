import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DailyMix} from 'src/app/blare-app/shared/services/discover.service';
import {FaIcons} from 'src/app/shared/constants/fa-icons.enum';
import {DrawThumbnailFromTracksService} from 'src/app/shared/services/draw-thumbnail-from-tracks.service';
import {TileBaseComponent} from '../tile-base.component';

@UntilDestroy()
@Component({
  selector: 'app-tile-daily-mix',
  templateUrl: './../tile-base.component.html',
  styleUrls: ['./../tile-base.component.scss'],
})
export class TileDailyMixComponent extends TileBaseComponent {
  @Input() public set item(value: DailyMix) {
    this.type = 'dailyMix';
    this.title = value.title ? `Daily mix ${value.title}` : '';
    this.dailyMix = value;

    this.thumbnailService
      .drawThumbnail(this.dailyMix?.tracks)
      .pipe(untilDestroyed(this))
      .subscribe((res: string) => {
        this.img = res;
      });
  }

  public borderRadius = '4px';
  public icon = FaIcons.listMusic;
  public dailyMix: DailyMix | undefined;

  constructor(private router: Router, private thumbnailService: DrawThumbnailFromTracksService) {
    super();
  }

  public leftClick(): void {
    const mixTitle = this.dailyMix?.title?.toLocaleLowerCase().replace(/\s/g, '');
    if (mixTitle) {
      this.router.navigate(['daily-mix', mixTitle]);
    }
  }

  public rightClick(event: Event): void {
    event.preventDefault();
    console.log('right');
  }
}

// linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
