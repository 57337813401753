<h5 ngClass.lt-md="text-center">Add to playlist</h5>

<form [formGroup]="playlistForm" appDisableForm>
  <div class="mt-3 input-ng-select input-field-wrapper">
    <label for="genre">Playlist</label>
    <ng-select labelForId="genre" class="custom" [items]="playlists" formControlName="playlist" bindLabel="title" bindValue="id">
    </ng-select>
  </div>
</form>

<div fxLayout="row" class="mt-3" fxLayoutAlign="end center">
  <button class="ml-3 big secondary" (click)="createNewPlaylist()">Create new</button>
  <button class="ml-3 big" [disabled]="playlistForm.invalid" [class.loader]="playlistForm.disabled" (click)="addTrackToPlaylist()">
    Add
  </button>
</div>
