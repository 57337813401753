import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CacheHelperService} from 'src/app/shared/services/cache-helper.service';
import {AllLikedArtistsGQL, CreateLikeGQL, DeleteLikeGQL, IsLikedGQL} from 'src/generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class FollowService {
  constructor(
    private deleteLikeGQL: DeleteLikeGQL,
    private createLikeGQL: CreateLikeGQL,
    private isLikedGQL: IsLikedGQL,
    private allLikedArtistsGQL: AllLikedArtistsGQL,
    private cacheHelperService: CacheHelperService,
  ) {}

  public unfollow(
    actingArtistId: string | undefined,
    artistId: string | undefined,
    likedUserId: string | undefined,
    updateCache: boolean = true,
  ): Observable<boolean> {
    return this.deleteLikeGQL
      .mutate(
        {userId: likedUserId, artistId, actingArtistId},
        {
          update: (store) => {
            if (updateCache && (artistId || likedUserId)) {
              const normalizedId = artistId
                ? store.identify({id: artistId, __typename: 'Artist'})
                : store.identify({userId: likedUserId, __typename: 'ProfilePublic'});

              store.modify({
                id: normalizedId,
                fields: {
                  likedAsListener: () => false,
                },
              });

              if (artistId) {
                store.modify({
                  fields: {
                    allLikes: (tracks, {readField}) => ({
                      ...tracks,
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      edges: tracks.edges.filter((x: any) => readField('id', x?.node?.artistByArtistId) !== artistId),
                    }),
                  },
                });
              } else {
                store.modify({
                  fields: {
                    allLikes: (tracks, {readField}) => ({
                      ...tracks,
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      edges: tracks.edges.filter((x: any) => readField('userId', x?.node?.userByLikedUserId) !== likedUserId),
                    }),
                  },
                });
              }
            }
          },
        },
      )
      .pipe(map((response) => !!response.data?.deleteLikes?.likes?.length));
  }

  public follow(
    actingArtistId: string | undefined,
    artistId: string | undefined,
    likedUserId: string | undefined,
    updateCache: boolean = true,
  ): Observable<boolean> {
    return this.createLikeGQL
      .mutate(
        {likedUserId, artistId, actingArtistId},
        {
          update: (store) => {
            if (updateCache && (artistId || likedUserId)) {
              const normalizedId = artistId
                ? store.identify({id: artistId, __typename: 'Artist'})
                : store.identify({userId: likedUserId, __typename: 'ProfilePublic'});

              store.modify({
                id: normalizedId,
                fields: {
                  likedAsListener: () => true,
                },
              });
            }
          },
          refetchQueries: [
            {query: this.allLikedArtistsGQL.document, variables: {userId: this.cacheHelperService.getCurrentUserCache().id}},
          ],
        },
      )
      .pipe(map((response) => !!response.data?.createLike?.like));
  }

  public isLiked(
    actingArtistId: string | undefined,
    userId: string | undefined,
    artistId: string | undefined,
    likedUserId: string | undefined,
  ): Observable<boolean> {
    return this.isLikedGQL
      .fetch(
        {
          userId,
          actingArtistId,
          artistId,
          likedUserId,
        },
        {fetchPolicy: 'no-cache'},
      )
      .pipe(
        map((response) => {
          if (response.data.allLikes && response.data.allLikes.edges.length > 0) {
            return true;
          }
          return false;
        }),
      );
  }
}
