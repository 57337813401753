import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ToastService} from 'src/app/shared/components/toast/toast.service';
import {
  Album,
  AssetType,
  CreateLikeGQL,
  DeleteFromQueueGQL,
  DeleteLikeGQL,
  GetShareLinkGQL,
  Playlist,
  RemoveFromPlaylistWebGQL,
  Track,
} from 'src/generated/graphql';
import {Clipboard} from '@angular/cdk/clipboard';
import {Reference, StoreObject} from '@apollo/client/cache';
import {PlayerStore} from '../state/player.store';

export type LikesEdgeOptionType = 'Track' | 'Playlist' | 'Album';

export interface LikeOptions {
  item: Album | Track | Playlist;
  idFieldKey: string; // field name for fetch (eg. trackId: item.id)
  __typename: LikesEdgeOptionType; // for normalizedId when turning like on/off
  nodeFieldType: string; // used in delete readField (eg. albumByAlbumId, trackByTrackId)
}

@Injectable({
  providedIn: 'root',
})
export class AdditionalTrackOptionsService {
  constructor(
    private createLikeGQL: CreateLikeGQL,
    private deleteLikeGQL: DeleteLikeGQL,
    private getShareLinkGQL: GetShareLinkGQL,
    private apollo: Apollo,
    private toastService: ToastService,
    private clipboard: Clipboard,
    private removeFromPlaylistGQL: RemoveFromPlaylistWebGQL,
    private deleteFromQueueGQL: DeleteFromQueueGQL,
    private playerStore: PlayerStore,
  ) {}

  public likeContent(options: LikeOptions): Observable<unknown> {
    const request: Observable<unknown> = !options.item.likedAsListener ? this.createLike(options) : this.deleteLike(options);

    return request.pipe(
      tap({
        next: () => this.updateCurrentTrack(options), // Handle Current track like/unlike
      }),
      tap({
        next: () => {
          const normalizedId = this.apollo.client.cache.identify({id: options.item.id, __typename: options.__typename});
          this.apollo.client.cache.modify({
            id: normalizedId,
            fields: {
              likedAsListener: () => !options.item.likedAsListener,
            },
          });
        },
      }),
    );
  }

  public shareContent(id: string, itemType: AssetType): Observable<unknown> {
    return this.getShareLinkGQL.mutate({id, itemType}).pipe(
      tap({
        next: (response) => {
          this.clipboard.copy(response.data?.shareContent?.link as string);
          this.toastService.show({
            text: `The ${itemType.toLowerCase()} link has been copied to your clipboard`,
            type: 'success',
          });
        },
      }),
    );
  }

  public removeFromPlaylist(playlistId: string, trackId: string): Observable<unknown> {
    return this.removeFromPlaylistGQL.mutate(
      {playlist: playlistId, track: trackId},
      {
        update: (store) => {
          const normalizedId = store.identify({id: playlistId, __typename: 'Playlist'});
          store.modify({
            id: normalizedId,
            fields: {
              playlistsTracksByPlaylistId: (connection = {}, {readField}) => ({
                ...connection,
                edges: connection.edges.filter(
                  (x: {node: {trackByTrackId: StoreObject | Reference | undefined}}) => readField('id', x.node.trackByTrackId) !== trackId,
                ),
              }),
            },
          });
        },
      },
    );
  }

  public removeFromQueue(trackQueueId: string): Observable<unknown> {
    return this.deleteFromQueueGQL.mutate(
      {trackId: trackQueueId},
      {
        update: (store) => {
          store.modify({
            fields: {
              allUsersQueues: (userQueue = {}, {readField}) => ({
                ...userQueue,
                edges: userQueue.edges.filter((x: {node: {id: string}}) => readField('id', x.node) !== trackQueueId),
              }),
            },
          });
        },
      },
    );
  }

  private createLike(options: LikeOptions): Observable<unknown> {
    return this.createLikeGQL.mutate(
      {[options.idFieldKey]: options.item.id},
      {
        update: (store) => {
          store.modify({
            fields: {
              allLikes: (allLikes = {}) => ({
                ...allLikes,
                edges: [
                  {
                    __typename: 'LikesEdge',
                    node: {
                      [options.idFieldKey]: {
                        ...options.item,
                        likedAsListener: !options.item.likedAsListener,
                      },
                    },
                  },
                  ...allLikes.edges,
                ],
              }),
            },
          });
        },
      },
    );
  }

  private deleteLike(options: LikeOptions): Observable<unknown> {
    return this.deleteLikeGQL.mutate(
      {[options.idFieldKey]: options.item.id},
      {
        update: (store) => {
          store.modify({
            fields: {
              allLikes: (items, {readField}) => ({
                ...items,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                edges: items.edges.filter((x: any) => readField('id', x?.node ? x?.node[options.nodeFieldType] : '') !== options.item.id),
              }),
            },
          });
        },
      },
    );
  }

  private updateCurrentTrack(options: LikeOptions): void {
    if (options.__typename === 'Track') {
      if (options.item.id === this.playerStore.getValue().currentPlayingTrack?.id) {
        this.playerStore.like(!options.item.likedAsListener);
      }
    }
  }
}
