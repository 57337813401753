import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {Artist, ArtistSubscription, CurrentUserQueryGQL, CurrentUserQueryQuery, User, VerificationStatus} from 'src/generated/graphql';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CacheHelperService {
  constructor(private apollo: Apollo, private currentUserQueryGQL: CurrentUserQueryGQL) {}

  public getCurrentUserCache(): User {
    const userCache = this.apollo.client.readQuery({
      query: this.currentUserQueryGQL.document,
    }) as CurrentUserQueryQuery;
    return userCache?.currentUser as User;
  }

  public getCurrentArtistCache(): Artist {
    return this.getCurrentUserCache().artistsByUserId?.edges[0]?.node as Artist;
  }

  public getCurrentSubscription(): ArtistSubscription {
    return this.getCurrentUserCache().artistsByUserId?.edges[0]?.node?.artistSubscriptionsByArtistId?.nodes[0] as ArtistSubscription;
  }

  public getCurrentSubscription$(): Observable<ArtistSubscription> {
    return this.currentUserQueryGQL
      .watch()
      .valueChanges.pipe(
        map(
          (response) =>
            response.data.currentUser?.artistsByUserId?.edges[0]?.node?.artistSubscriptionsByArtistId.nodes[0] as ArtistSubscription,
        ),
      );
  }

  public isUserArtist(): boolean {
    return (
      !!this.getCurrentUserCache().artistsByUserId.edges.length &&
      this.getCurrentUserCache().userVerificationByUserId?.status === VerificationStatus.Verified
    );
  }

  public isUserArtist$(): Observable<boolean> {
    return this.currentUserQueryGQL
      .watch()
      .valueChanges.pipe(
        map(
          (response) =>
            !!response.data?.currentUser?.artistsByUserId.edges.length &&
            response.data?.currentUser?.userVerificationByUserId?.status === VerificationStatus.Verified,
        ),
      );
  }

  public userVerificationStatus$(): Observable<VerificationStatus> {
    return this.currentUserQueryGQL
      .watch()
      .valueChanges.pipe(map((response) => response.data?.currentUser?.userVerificationByUserId?.status || VerificationStatus.None));
  }

  public doesArtistHaveSubscription(): boolean {
    return !!this.getCurrentUserCache().artistsByUserId.edges[0].node?.artistSubscriptionsByArtistId.nodes.length;
  }
}
