<div class="youllget py-3 text-center" *ngIf="togglePrice">
  You'll get <b>{{ package?.price2 }}</b>
</div>
<div class="wrapper" [class.active]="selected" [class.topup]="togglePrice">
  <div class="p-5">
    <div class="mb-3 text-center" fxLayoutAlign="center center" fxLayout="row">
      <img src="assets/icons/wave.svg" />
      <h4 class="ml-2">{{ package?.name }}</h4>
    </div>
    <div class="mb-4 text-center">
      <h4 [ngClass]="package?.priceClass || ''">
        {{ togglePrice ? package?.price2 : package?.price1 }}
      </h4>
    </div>
    <div
      *ngFor="let feature of package?.features; let last = last"
      [class.grey]="feature.disabled"
      fxLayoutAlign="start center"
      [class.mb-3]="!last"
    >
      <fa-icon class="mr-3" [ngClass]="feature.disabled ? 'light' : 'blue'" [icon]="feature.icon"></fa-icon>
      <p [class.grey]="feature.disabled">{{ feature.label }}</p>
    </div>
    <!-- <p class="caption text-center">{{ package?.text }}</p> -->
  </div>
</div>
