import {Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
})
export class SkeletonLoaderComponent {
  @HostBinding('style.width') @Input() public width = '100%';
  @HostBinding('style.height') @Input() public height = '100%';
  @Input() public circle = false;
  @Input() public borderRadius = '4px';
}
