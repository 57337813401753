import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {persistState} from '@datorama/akita';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

// define which Akkita stores and properties of said stores are saved to localStorage
const storage = persistState({
  include: ['blare-state', 'player'],
  preStorageUpdate: (storeName, state) => {
    if (storeName === 'blare-state') {
      return {
        activeTheme: state.activeTheme,
        systemTheme: state.systemTheme,
        defaultProfile: state.defaultProfile,
      };
    }

    if (storeName === 'player') {
      return {
        volumeLevel: state.volumeLevel,
        randomSequance: state.randomSequance,
        currentPlayingTrack: state.currentPlayingTrack,
        repeatType: state.repeatType,
        title: state.title,
      };
    }

    return state;
  },
  preStoreUpdate: (storeName, state) => state,
});

const providers = [{provide: 'persistStorage', useValue: storage}];

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
