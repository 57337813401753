import {Directive, HostListener, Output, Input, OnChanges, SimpleChanges, OnDestroy, EventEmitter, Optional, Inject} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Directive({
  selector: '[appFilePicker]',
  exportAs: 'appFilePicker',
})
// Usage: https://stackblitz.com/edit/angular-file-picker-directive
export class FilePickerDirective implements OnDestroy, OnChanges {
  /*
   * File types list emitted on change.
   * */
  @Input() public fileTypes = 'all';

  @Input() public openFilePickerOnClick = true;
  /*
   * File list emitted on change.
   * */
  @Output()
  public filesChanged = new EventEmitter<FileList | null>();

  /*
   * File list emitted on change.
   * */
  @Output()
  public filesReset = new EventEmitter<void>();

  private _form!: HTMLFormElement;
  /*
   * Allow multiple file selection. Defaults to `false`.
   * */
  private _multiple = false;
  @Input()
  public set multiple(val: boolean) {
    this._multiple = coerceBooleanProperty(val);
  }
  public get multiple(): boolean {
    return this._multiple;
  }

  private _nativeFileElement!: HTMLInputElement;
  public get nativeFileElement(): HTMLInputElement {
    return this._nativeFileElement;
  }

  constructor(@Optional() @Inject(DOCUMENT) private _document: Document) {
    if (this._document) {
      this._form = this._document.createElement('form');
      this._nativeFileElement = this._document.createElement('input');
      this._nativeFileElement.type = 'file';
      this._nativeFileElement.accept = this.fileTypes;
      this._nativeFileElement.multiple = this.multiple;

      this._nativeFileElement.addEventListener('change', this._onFilesChanged);
      this._form.appendChild(this.nativeFileElement);
    }
  }
  /*
   * Prevent dragover event so drop events register.
   */
  @HostListener('dragover', ['$event'])
  public _onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  /*
   * Set files on drop.
   * Emit selected files.
   */
  @HostListener('drop', ['$event'])
  public _drop(event: DragEvent): void {
    event.preventDefault();
    const files = event.dataTransfer ? event.dataTransfer.files : null;
    if (files) {
      const acceptedFileExtensions = this._nativeFileElement.accept.split(',');

      const areFilesValidFileTypes = Array.from(files).every((file) => {
        const droppedFileExtensions = `.${file.name.split('.').pop()}` || '';
        return acceptedFileExtensions.some((x) => x === droppedFileExtensions);
      });

      if (areFilesValidFileTypes) {
        this._nativeFileElement.files = files;
        this._onFilesChanged();
      }
    }
  }

  /*
   * Invoke file browse on click.
   */

  @HostListener('click', ['$event'])
  public _onClick(event: Event): void {
    if (this.openFilePickerOnClick) {
      event.preventDefault();
      this._nativeFileElement.click();
    }
  }

  public onClickOpenInput(): void {
    this._nativeFileElement.click();
  }

  /*
   * Selected Files
   */
  public get files(): FileList | null {
    return this._nativeFileElement.files;
  }

  /*
   * Native input[type=file] element.
   */

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.multiple) {
      this._nativeFileElement.multiple = this.multiple;
    }
    if (changes.fileTypes) {
      this._nativeFileElement.accept = this.fileTypes;
    }
  }

  public ngOnDestroy(): void {
    this._nativeFileElement.removeEventListener('change', this._onFilesChanged);
    this._nativeFileElement.remove();
    this._form.remove();
  }

  /*
   * Reset file list.
   */
  public reset(): void {
    this._form.reset();
    this.filesReset.emit();
  }

  private _onFilesChanged = () => {
    this.filesChanged.emit(this._nativeFileElement.files);
    this._nativeFileElement.files = null;
  };
}
