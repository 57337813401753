import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpContextToken, HttpContext} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ToastService} from '../components/toast/toast.service';

export const SKIP_INTERCEPTOR_ERROR_HANDLING = new HttpContextToken<boolean>(() => false);
// USE as:
// return this.http
// .post<UploadProgress>(`upload?${params.toString()}`, formData, {
//   reportProgress: true,
//   observe: 'events',
//   context: new HttpContext().set(SKIP_INTERCEPTOR_ERROR_HANDLING, true),
// })
export const SKIP_INTERCEPTOR_ERROR_HANDLING_GQL = {context: {headers: {skipError: 'true'}}};
// USE as:
// this.getAllGenresGQL.fetch(undefined, SKIP_INTERCEPTOR_ERROR_HANDLING_GQL).subscribe((response) => {
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let updatedReq = request;
    // this is workaround for GQL requests, that you can't access HttpRequest object to set context (for skipError)
    if (request.headers.get('skiperror')) {
      updatedReq = updatedReq.clone({
        headers: updatedReq.headers.delete('skiperror'),
        context: new HttpContext().set(SKIP_INTERCEPTOR_ERROR_HANDLING, true),
      });
    }

    return next.handle(updatedReq).pipe(
      // TODO retry(1), if fail try to create request again?
      catchError((error) => {
        if (!updatedReq.context.get(SKIP_INTERCEPTOR_ERROR_HANDLING)) {
          this.toastService.show({text: 'Something went wrong, please try again later', type: 'error'});
        }
        return throwError(error);
      }),
    );
  }
}
