import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
// eslint-disable-next-line import/no-unresolved
import {NgxStripeModule} from 'ngx-stripe';

import {TitleSubtitleComponent} from './components/title-subtitle/title-subtitle.component';
import {TrackCardComponent} from './components/track-card/track-card.component';
import {CoreModule} from '../core/core.module';
import {SmoothHeightComponent} from './components/smooth-height/smooth-height.component';
import {PopoverComponent} from './components/popover/popover.component';
import {ToastComponent} from './components/toast/toast.component';
import {ModalComponent} from './components/modal/modal.component';
import {FilePickerDirective} from './directives/file-picker.directive';
import {RangeSliderBackgroundDirective} from './directives/range-slider-background.directive';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {MediaIfDirective} from './directives/mediaif.directive';
import {SongTimePipe} from './pipes/song-time.pipe';
import {GenreSelectWrapperComponent} from './components/genre-select-wrapper/genre-select-wrapper.component';
import {ErrorInterceptor} from './interceptors/error.interceptor';
import {DisableFormDirective} from './directives/disable-form.directive';
import {SkeletonLoaderComponent} from './components/skeleton-loader/skeleton-loader.component';
import {TilePlaylistComponent} from './components/tile-base/tile-playlist/tile-playlist.component';
import {TileArtistComponent} from './components/tile-base/tile-artist/tile-artist.component';
import {TileTrackComponent} from './components/tile-base/tile-track/tile-track.component';
import {TileAlbumComponent} from './components/tile-base/tile-album/tile-album.component';
import {AgoTimePipe} from './pipes/ago-time.pipe';
import {TileDailyMixComponent} from './components/tile-base/tile-daily-mix/tile-daily-mix.component';
import {TrackPricePipe} from './pipes/track-price.pipe';
import {PrefixInputDirective} from './directives/prefix-input.directive';
import {InputErrorClassDirective} from './directives/input-error-class.directive';
import {StripeComponent} from './components/stripe/stripe.component';
import {OnfidoComponent} from './components/onfido/onfido.component';
import {TooltipDirective} from './directives/tooltip.directive';
import {NumbersFormatPipe} from './pipes/numbers-format.pipe';
import {ImageFromObjectPipe} from './pipes/image-from-object.pipe';
import {ChangePhotoOptionsComponent} from './components/change-photo-options/change-photo-options.component';
import {PackageTopupComponent} from './components/package-topup/package-topup.component';
import {PackageSubscriptionComponent} from './components/package-subscription/package-subscription.component';
import {TileListenerComponent} from './components/tile-base/tile-listener/tile-listener.component';
import {NotificationIconPipe} from './pipes/notification-icon.pipe';
import {PresignedImgPipe} from './pipes/presigned-img.pipe';
import {InputBlarecastUrlComponent} from './components/input-blarecast-url/input-blarecast-url.component';
import {LongPressDirective} from './directives/long-press.directive';
import {CarouselComponent} from './components/carousel/carousel.component';

const sharedComponents = [
  TitleSubtitleComponent,
  TrackCardComponent,
  SmoothHeightComponent,
  PopoverComponent,
  ToastComponent,
  ModalComponent,
  FilePickerDirective,
  RangeSliderBackgroundDirective,
  MediaIfDirective,
  DisableFormDirective,
  GenreSelectWrapperComponent,
  SkeletonLoaderComponent,
  TilePlaylistComponent,
  TileArtistComponent,
  TileTrackComponent,
  TileAlbumComponent,
  TileDailyMixComponent,
  PrefixInputDirective,
  StripeComponent,
  OnfidoComponent,
  InputErrorClassDirective,
  TooltipDirective,
  ChangePhotoOptionsComponent,
  PackageTopupComponent,
  PackageSubscriptionComponent,
  TileListenerComponent,
  InputBlarecastUrlComponent,
  LongPressDirective,
  CarouselComponent,
];

const sharedPipes = [
  SongTimePipe,
  AgoTimePipe,
  TrackPricePipe,
  NumbersFormatPipe,
  ImageFromObjectPipe,
  NotificationIconPipe,
  PresignedImgPipe,
];

@NgModule({
  declarations: [...sharedComponents, ...sharedPipes],
  imports: [CommonModule, CoreModule, NgxStripeModule.forRoot()],
  exports: [...sharedComponents, ...sharedPipes],
  entryComponents: [PopoverComponent, ToastComponent, ModalComponent],
  providers: [
    PresignedImgPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {}
