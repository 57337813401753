import {Injectable} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {ModalService} from 'src/app/shared/components/modal/modal.service';
import {PopoverService} from 'src/app/shared/components/popover/popover.service';
import {CacheHelperService} from 'src/app/shared/services/cache-helper.service';
import {AssetType, Playlist, PlaylistDetailsWebGQL, Track} from 'src/generated/graphql';
import {ModalEditPlaylistComponent} from '../components/modal-edit-playlist/modal-edit-playlist.component';
import {ModalRemovePlaylistComponent} from '../components/modal-remove-playlist/modal-remove-playlist.component';
import {PopoverTileOptionData, PopoverTileOptionsComponent} from '../components/popover-tile-options/popover-tile-options.component';
import {PlayerService} from '../state/player.service';
import {AdditionalTrackOptionsService, LikeOptions} from './additional-track-options.service';

export interface PlaylistOptionsResponse {
  popOverData: Partial<PopoverTileOptionData> | null;
  playlist: Playlist;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class PlaylistOptionsService {
  constructor(
    private modalService: ModalService,
    private playerService: PlayerService,
    private popoverService: PopoverService,
    private cacheHelperService: CacheHelperService,
    private playlistDetailsWebGQL: PlaylistDetailsWebGQL,
    private additionalTrackOptionsService: AdditionalTrackOptionsService,
  ) {}

  public openPlaylistOption(playlist: Playlist, posX: number, posY: number): Observable<PlaylistOptionsResponse> {
    return this.playlistDetailsWebGQL.fetch({id: playlist.id}).pipe(
      map(
        (response) =>
          ({
            popOverData: !response.data.playlistById ? null : this.setPopOverData(response.data.playlistById as Playlist),
            playlist: response.data.playlistById as Playlist,
          } as PlaylistOptionsResponse),
      ),
      tap({
        next: (options: PlaylistOptionsResponse) => {
          if (!options.popOverData) {
            return;
          }

          this.popoverService.show(
            {
              x: posX + 16,
              y: posY - 4,
            },
            {
              content: PopoverTileOptionsComponent,
              data: options.popOverData,
            },
            true,
          );
        },
      }),
    );
  }

  // ------------ WHAT OPTIONS TO DISPLAY ------------

  private setPopOverData(playlist: Playlist | undefined): Partial<PopoverTileOptionData> {
    // Define popup options available to everyone
    const baseData: Partial<PopoverTileOptionData> = {
      type: 'playlist',
      shareButton: this.sharePlaylist(playlist),
      addToQueueButton: this.addPlaylistToQueue(playlist),
      isLiked: playlist?.likedAsListener || false,
    };

    const isAuthor = playlist?.userByAuthor?.id === this.cacheHelperService.getCurrentUserCache().id;
    // Add options for authors or non-authors of playlist
    if (isAuthor) {
      Object.assign(baseData, {
        editButton: this.editPlaylist(playlist),
        deleteButton: this.deletePlaylist(playlist),
      });
    } else {
      Object.assign(baseData, {
        favouritesButton: this.favouritePlaylist(playlist),
      });
    }

    return baseData;
  }

  // ------------ PLAYLIST AVAILABLE OPTIONS ------------

  private sharePlaylist = (playlist: Playlist | undefined): (() => void) => {
    const share = () => {
      if (playlist?.id) {
        this.additionalTrackOptionsService.shareContent(playlist?.id, AssetType.Playlist).pipe(untilDestroyed(this)).subscribe();
      }
    };
    return share;
  };

  private editPlaylist = (playlist: Playlist | undefined): (() => void) => {
    const edit = () => {
      if (playlist) {
        this.modalService.show({content: ModalEditPlaylistComponent, position: 'center', padding: '16px', data: playlist});
      }
    };
    return edit;
  };

  private deletePlaylist = (playlist: Playlist | undefined): (() => void) => {
    const _delete = () => {
      if (playlist) {
        this.modalService.show({content: ModalRemovePlaylistComponent, position: 'top', data: {playlist}});
      }
    };
    return _delete;
  };

  private addPlaylistToQueue = (playlist: Playlist | undefined): (() => void) => {
    const addToQueue = () => {
      const tracks = playlist?.playlistsTracksByPlaylistId.edges.map((e) => e.node?.trackByTrackId as Track) || [];
      this.playerService.addTracksToQueue(tracks).pipe(untilDestroyed(this)).subscribe();
    };
    return addToQueue;
  };

  private favouritePlaylist = (playlist: Playlist | undefined): (() => void) => {
    const favourite = () => {
      if (playlist?.likedAsListener === undefined) {
        return;
      }
      const options: LikeOptions = {
        item: playlist as Playlist,
        idFieldKey: 'playlistId',
        __typename: 'Playlist',
        nodeFieldType: 'playlistByPlaylistId',
      };
      this.additionalTrackOptionsService.likeContent(options).pipe(untilDestroyed(this)).subscribe();
    };
    return favourite;
  };
}
