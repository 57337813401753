import {HttpClient, HttpContext} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CardBrands} from 'src/app/blare-app/shared/components/tile-payment-processor/tile-payment-processor.component';
import {SKIP_INTERCEPTOR_ERROR_HANDLING} from '../interceptors/error.interceptor';

interface StripeKey {
  publishableKey: string;
}

interface CustomerId {
  customerId: string;
}

export interface Intent {
  setupIntent: {
    customerId: string;
    clientSecret: string;
    id: string;
    status: string;
    nextAction: {
      type: string;
      use_stripe_sdk: object;
      redirect_to_url: {
        return_url: string;
        url: string;
      };
    };
  };
}

interface StripeMetaData {
  id: string;
  card: {
    brand: string;
    exp_month: number;
    exp_year: number;
    last4: string;
    fingerprint: string;
    funding: string;
  };
  bankAccount: {
    bankAccount: {
      last4: string;
    };
  };
  billingDetails: {
    city: string;
    country: string;
    line1: string;
    line2: string;
    postal_code: string;
    state: string;
    email: string;
    name: string;
    phone: string;
  };
}

interface HandcashMetaData {
  handle: string;
}

interface SubscriptionResponse {
  success: string;
  status: boolean;
  paymentIntent: {
    id: string;
    status: boolean;
  };
  nextAction: string;
}

interface GetConnectedAccount {
  accountId: string;
  features: {
    payoutsEnabled: boolean;
    chargesEnabled: boolean;
  };
  externalAccount: {
    id: string;
    address_city: string;
    address_country: string;
    address_line1: string;
    address_line1_check: string;
    address_line2: string;
    address_state: string;
    address_zip: string;
    address_zip_check: string;
    cvc_check: string;
    funding: string;
    brand: CardBrands;
    exp_month: number;
    exp_year: number;
    last4: string;
    fingerprint: string;
  };
}

interface AddConnectedAccount {
  accountLink: {
    object: unknown;
    created: number;
    expires_at: number;
    url: string;
  };
}

export enum PaymentProcessorType {
  handcash = 'HANDCASH',
  stripe = 'STRIPE',
}

export interface PaymentProcessor {
  processorType: PaymentProcessorType;
  id: string;
  metadata: StripeMetaData | HandcashMetaData;
}

interface PaymentMethods {
  collection: PaymentProcessor[];
  selected: PaymentProcessor | null;
}

interface GetWithdrawalMethods {
  id: string;
  userId: string;
  amount: number;
  paymentProcessor: string;
  paymentProcessorId: string;
  customerId: string;
  withdrawalLimit: number;
  balance: number;
  currency: string;
  applicationFee: number;

  processors: {
    collection: PaymentProcessor[];
    selected: PaymentProcessor;
  };
}

interface CreateWithdrawal {
  id: string;
  amount: number;
  fee: number;
  payout: number;
  processorId: string;
  processorPayoutId: string;
  status: string;
  applicationFee: number;
  withdrawalLimit: number;
  currency: string;
}

interface ConnectedAccountLink {
  url: string;
}
interface HandCashLoginUrl {
  loginLink: string;
}

interface ConnectHandcashAuthToken {
  test: string;
}

interface CancelSubscription {
  completed: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private http: HttpClient) {}

  // *** STRIPE ***
  public getStripeKey(): Observable<StripeKey> {
    return this.http.get<StripeKey>('payment/stripe/keys');
  }

  public getCustomerId(): Observable<CustomerId> {
    return this.http.get<CustomerId>('payment/stripe/customers');
  }

  public getWithdrawalMethods(): Observable<GetWithdrawalMethods> {
    return this.http.get<GetWithdrawalMethods>('payment/withdrawals/me');
  }

  public setDefaultPaymentProcessor(paymentProcessorId: string): Observable<AddConnectedAccount> {
    return this.http.patch<AddConnectedAccount>('payment/withdrawals/payment-method', {
      paymentProcessorId,
    });
  }

  // connected accounts are used for withdrawals
  public getConnectedAccount(): Observable<GetConnectedAccount> {
    return this.http.get<GetConnectedAccount>('payment/stripe/accounts', {
      context: new HttpContext().set(SKIP_INTERCEPTOR_ERROR_HANDLING, true),
    });
  }

  public addConnectedAccount(): Observable<AddConnectedAccount> {
    return this.http.post<AddConnectedAccount>('payment/stripe/accounts', {
      externalAccountId: '',
      options: {
        returnUrl: `${window.location.origin}/my-music?connectedAccount=return`,
        refreshUrl: `${window.location.origin}/my-music?connectedAccount=refresh`,
      },
    });
  }

  public createWithdrawal(preview: boolean): Observable<CreateWithdrawal> {
    return this.http.post<CreateWithdrawal>(
      'payment/withdrawals',
      {
        preview,
      },
      {
        context: new HttpContext().set(SKIP_INTERCEPTOR_ERROR_HANDLING, true),
      },
    );
  }

  public getConnectedAccountLink(): Observable<ConnectedAccountLink> {
    return this.http.get<ConnectedAccountLink>('payment/stripe/accounts/link');
  }

  public addCardToCustomer(paymentMethodId: string, intentId?: string, confirm?: boolean): Observable<Intent> {
    return this.http.post<Intent>('payment/stripe/setup-intents', {paymentMethodId, intentId, confirm});
  }

  public getPaymentMethod(): Observable<PaymentMethods> {
    return this.http.get<PaymentMethods>('payment/payment-processors', {
      context: new HttpContext().set(SKIP_INTERCEPTOR_ERROR_HANDLING, true),
      params: {
        operationType: 'PAYMENT',
      },
    });
  }

  public deletePaymentMethod(paymentMethodId: string): Observable<string> {
    return this.http.delete<string>(`payment/payment-processors/${paymentMethodId}`);
  }

  public editSubscription(subscriptionTypeId: string, yearlyInterval: boolean): Observable<SubscriptionResponse> {
    return this.http.post<SubscriptionResponse>(
      'payment/subscription',
      {subscriptionId: subscriptionTypeId, interval: yearlyInterval ? 'YEAR' : 'MONTH'},
      {context: new HttpContext().set(SKIP_INTERCEPTOR_ERROR_HANDLING, true)}, // TODO: REMOVE THIS IN PRODUCTION!!! mocking data
    );
  }

  public cancelSubscription(): Observable<CancelSubscription> {
    return this.http.delete<CancelSubscription>('payment/subscription/cancel');
  }

  public getHandCashLoginUrl(): Observable<HandCashLoginUrl> {
    return this.http.get<HandCashLoginUrl>('payment/handcash/account/login');
  }

  public connectHandcashAuthToken(authToken: string): Observable<ConnectHandcashAuthToken> {
    return this.http.post<ConnectHandcashAuthToken>('payment/handcash/account', {authToken});
  }
}
