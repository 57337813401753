<div class="card-wrapper">
  <div class="img-wrapper mb-2">
    <ng-container [ngTemplateOutlet]="image" [ngTemplateOutletContext]="{showIcon: true, class: 'moved-img'}"> </ng-container>
    <ng-container [ngTemplateOutlet]="image" [ngTemplateOutletContext]="{showIcon: false, class: 'back-img'}"> </ng-container>
  </div>
  <app-title-subtitle [title]="item?.name || ''" [subtitle]="item?.author || ''"></app-title-subtitle>
</div>

<ng-template #image let-showIcon="showIcon" let-class="class">
  <div class="img-bg" [ngClass]="class" [style.background-image]="'url(' + item?.img + ')'" [style.border-radius]="'16px'"></div>
</ng-template>
