import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Maybe} from 'graphql/jsutils/Maybe';

@Pipe({
  name: 'imageFromObject',
})
export class ImageFromObjectPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  public transform(image: Maybe<string> | undefined, path?: string): string {
    if (image) {
      if (image?.startsWith('http')) {
        return image as string;
      }
      if (image?.startsWith('blob')) {
        return this.sanitizer.bypassSecurityTrustUrl(image) as string;
      }
      if (image?.startsWith('data:image/jpeg;base64')) {
        return image as string;
      }
      if (image?.startsWith('/assets/')) {
        return image;
      }

      if (path) {
        return path + image;
      }
    }
    return '';
  }
}
