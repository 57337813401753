import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {finalize, first, tap} from 'rxjs/operators';
import {PopoverTileOptionsComponent} from 'src/app/blare-app/shared/components/popover-tile-options/popover-tile-options.component';
import {FaIcons} from 'src/app/shared/constants/fa-icons.enum';
import {Album, AlbumDetailsGQL, AssetType, Maybe, Track} from 'src/generated/graphql';
import {AdditionalTrackOptionsService, LikeOptions} from 'src/app/blare-app/shared/services/additional-track-options.service';
import {PlayerService} from 'src/app/blare-app/shared/state/player.service';
import {ImgSize} from 'src/app/shared/constants/img-size.enum';
import {PopoverService} from '../../popover/popover.service';
import {TileBaseComponent} from '../tile-base.component';

@UntilDestroy()
@Component({
  selector: 'app-tile-album',
  templateUrl: './../tile-base.component.html',
  styleUrls: ['./../tile-base.component.scss'],
})
export class TileAlbumComponent extends TileBaseComponent {
  @Input() public set item(value: Album) {
    this.album = value;
    this.id = value?.id ? value?.id : '';
    this.title = value?.title ? value?.title : '';
    this.img = value.presignedImgs?.find((x) => x?.size === ImgSize.Small)?.url || null;
    this.subtitle = value?.artistByArtist?.displayName ? value?.artistByArtist?.displayName : '';
    this.explicit = value.explicit || false;
    this.type = value?.__typename ? value?.__typename : undefined;
  }
  @ViewChild('tile') public tile: ElementRef | undefined;

  public borderRadius = '4px';
  public icon = FaIcons.album;

  private album: Album | undefined;
  private albumTracks: Track[] = [];

  // @ViewChild('uploadTypePopover') private uploadTypePopover: TemplateRef<PopoverComponent> | undefined;

  constructor(
    private router: Router,
    private popoverService: PopoverService,
    private additionalTrackOptionsService: AdditionalTrackOptionsService,
    private getAlbumGQL: AlbumDetailsGQL,
    private playerService: PlayerService,
  ) {
    super();
  }

  public leftClick(): void {
    console.log(this.id);
    this.router.navigate(['albums', this.id]);
  }

  public rightClick(event: MouseEvent): void {
    event.preventDefault();
    this.setCursor('wait', 'wait');

    this.getAlbumGQL
      .fetch({id: this.album?.id})
      .pipe(
        first(),
        tap({
          next: (response) => {
            const fullAlbum = response?.data.albumById as Maybe<Album> | undefined;
            if (fullAlbum) {
              this.album = fullAlbum;
              this.albumTracks = fullAlbum?.tracksByAlbum.edges.map((e) => e.node as Track) || [];
            }
          },
        }),
        finalize(() => {
          this.setCursor();
        }),
      )
      .subscribe({
        next: () => {
          this.popoverService.show(
            {
              x: event.clientX + 16,
              y: event.clientY - 4,
            },
            {
              content: PopoverTileOptionsComponent,
              data: {
                type: 'album',
                favouritesButton: this.favouriteAlbum,
                shareButton: this.shareAlbum,
                viewButton: this.viewAlbumArtist,
                addToQueueButton: this.addAlbumToQueue,
                isLiked: this.album?.likedAsListener,
              },
            },
            true,
          );
        },
      });
  }

  public shareAlbum = (): void => {
    if (this.album?.id) {
      this.additionalTrackOptionsService.shareContent(this.album.id, AssetType.Album).pipe(untilDestroyed(this)).subscribe();
    }
  };

  public viewAlbumArtist = (): void => {
    if (this.album?.artistByArtist?.id) {
      this.router.navigate(['artist', this.album?.artistByArtist?.id]);
    }
  };

  public addAlbumToQueue = (): void => {
    this.playerService.addTracksToQueue(this.albumTracks).pipe(untilDestroyed(this)).subscribe();
  };

  public favouriteAlbum = (): void => {
    if (this.album?.likedAsListener === undefined) {
      return;
    }
    const options: LikeOptions = {
      item: this.album,
      idFieldKey: 'albumId',
      __typename: 'Album',
      nodeFieldType: 'albumByAlbumId',
    };
    this.additionalTrackOptionsService
      .likeContent(options)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.setAlbumLike(this.album?.likedAsListener || false);
        },
      });
  };

  private setAlbumLike(likedAsListener = false): void {
    this.album = {
      ...this.album,
      likedAsListener,
    } as Album;
  }

  private setCursor(cursorBody = 'auto', cursorTile = 'pointer') {
    document.body.style.cursor = cursorBody;
    (this.tile as ElementRef).nativeElement.style.cursor = cursorTile;
  }
}
