import {Pipe, PipeTransform} from '@angular/core';
import {Maybe, PresignedImage} from 'src/generated/graphql';
import {ImgSize} from '../constants/img-size.enum';

@Pipe({
  name: 'presignedImg',
})
export class PresignedImgPipe implements PipeTransform {
  public transform(
    presignedImg: Maybe<Maybe<PresignedImage>[]> | undefined,
    size: number | undefined = ImgSize.Small,
  ): string | undefined | null {
    if (presignedImg === undefined) return undefined;

    return presignedImg?.find((x) => x?.size === size)?.url || null;
  }
}
