import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {finalize, first, tap} from 'rxjs/operators';
import {ModalAddToPlaylistComponent} from 'src/app/blare-app/shared/components/modal-add-to-playlist/modal-add-to-playlist.component';
import {PopoverTileOptionsComponent} from 'src/app/blare-app/shared/components/popover-tile-options/popover-tile-options.component';
import {AdditionalTrackOptionsService, LikeOptions} from 'src/app/blare-app/shared/services/additional-track-options.service';
import {PlayerService} from 'src/app/blare-app/shared/state/player.service';
import {FaIcons} from 'src/app/shared/constants/fa-icons.enum';
import {ImgSize} from 'src/app/shared/constants/img-size.enum';
import {AssetType, Track, TrackTileOptionsGQL} from 'src/generated/graphql';
import {ModalService} from '../../modal/modal.service';
import {PopoverService} from '../../popover/popover.service';
import {TileBaseComponent} from '../tile-base.component';

@UntilDestroy()
@Component({
  selector: 'app-tile-track',
  templateUrl: './../tile-base.component.html',
  styleUrls: ['./../tile-base.component.scss'],
})
export class TileTrackComponent extends TileBaseComponent {
  @Input() public set item(value: Track) {
    this.track = value;
    this.id = value.id ? value.id : '';
    this.title = value.title ? value.title : '';
    this.img = value.presignedImgs?.find((x) => x?.size === ImgSize.Small)?.url || null;
    this.subtitle = value.artistByArtist?.displayName ? value.artistByArtist?.displayName : '';
    this.disabled = !(value?.isAvailable === undefined || value?.isAvailable === null ? true : value?.isAvailable);
    this.explicit = value.explicit || false;
    this.type = value.__typename ? value.__typename : undefined;
  }
  @ViewChild('tile') public tile: ElementRef | undefined;

  public borderRadius = '16px';
  public icon = FaIcons.music;

  private track: Track | undefined;

  constructor(
    private playerService: PlayerService,
    private popoverService: PopoverService,
    private additionalTrackOptionsService: AdditionalTrackOptionsService,
    private modalService: ModalService,
    private trackTileOptionsGQL: TrackTileOptionsGQL,
    private router: Router,
  ) {
    super();
  }

  public leftClick(): void {
    this.playerService.changeTrack(this.id, true);
  }

  public rightClick(event: MouseEvent): void {
    event.preventDefault();
    this.setCursor('wait', 'wait');

    this.trackTileOptionsGQL
      .fetch({id: this.track?.id})
      .pipe(
        untilDestroyed(this),
        tap({
          next: (response) => this.setPlaylistLike(response.data.trackById?.likedAsListener || false),
        }),
        finalize(() => {
          this.setCursor();
        }),
      )
      .subscribe({
        next: (response) => {
          if (!response.data.trackById) {
            return;
          }

          this.popoverService.show(
            {
              x: event.clientX + 16,
              y: event.clientY - 4,
            },
            {
              content: PopoverTileOptionsComponent,
              data: {
                type: 'song',
                favouritesButton: this.favouriteTrack,
                shareButton: this.shareTrack,
                viewButton: this.viewTrackArtist,
                addToPlaylistButton: this.addTrackToPlaylist,
                addToQueueButton: this.addTrackToQueue,
                isLiked: this.track?.likedAsListener,
              },
            },
            true,
          );
        },
      });
  }

  public shareTrack = (): void => {
    if (this.track?.id) {
      this.additionalTrackOptionsService.shareContent(this.track.id, AssetType.Track).pipe(untilDestroyed(this)).subscribe();
    }
  };

  public viewTrackArtist = (): void => {
    console.log(this.track);
    if (this.track?.artistByArtist?.id) {
      this.router.navigate(['artist', this.track?.artistByArtist?.id]);
    }
  };

  public addTrackToPlaylist = (): void => {
    if (this.track?.id) {
      this.modalService.show({
        content: ModalAddToPlaylistComponent,
        position: 'center',
        padding: '16px',
        data: this.track?.id,
      });
    }
  };

  public addTrackToQueue = (): void => {
    this.playerService
      .addTracksToQueue([this.track as Track])
      .pipe(first())
      .subscribe();
  };

  public favouriteTrack = (): void => {
    if (this.track?.likedAsListener === undefined) {
      return;
    }

    const options: LikeOptions = {
      item: this.track,
      idFieldKey: 'trackId',
      __typename: 'Track',
      nodeFieldType: 'trackByTrackId',
    };

    this.additionalTrackOptionsService
      .likeContent(options)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => this.setPlaylistLike(!this.track?.likedAsListener),
      });
  };

  private setPlaylistLike(likedAsListener = false): void {
    this.track = {
      ...this.track,
      likedAsListener,
    } as Track;
  }

  private setCursor(cursorBody = 'auto', cursorTile = 'pointer') {
    document.body.style.cursor = cursorBody;
    (this.tile as ElementRef).nativeElement.style.cursor = cursorTile;
  }
}
