import {Component, OnInit, TemplateRef} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {fromEvent, Subscription} from 'rxjs';
import {take, filter, skip} from 'rxjs/operators';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {MediaObserver} from '@angular/flex-layout';
import {AnimationEvent} from '@angular/animations';
import {popoverAnimation} from '../../animations/animations';
import {PopoverData} from './popover-config';

@UntilDestroy()
@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['popover.component.scss'],
  animations: [popoverAnimation],
})
export class PopoverComponent implements OnInit {
  public showing = true;
  public renderMethod: 'template' | 'component' = 'component';
  public animationState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public context: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public content: any;

  public currentMobileYPosition = 0;
  public initYPosition = 0;

  private alreadyCalled = false; // for some reason when leave animation is done, callback is called twice
  private sub!: Subscription;
  private isOnMobile;

  constructor(
    private data: PopoverData<unknown>,
    private overlayRef: OverlayRef,
    private mediaObserver: MediaObserver,
    private overlay: Overlay,
  ) {
    this.isOnMobile = this.mediaObserver.isActive('lt-md');
    if (this.isOnMobile) {
      this.animationState = 'currentMobile';
    } else {
      this.animationState = 'enterDesktop';
    }
    // if you clicked on another popover open, the current one was closed AFTER the first one opened, that broke scroll block
    // What happened: new one opened(ckecked if you you already scroll blocking => yes), old one animation end -> closing (remove scroll block)
    // I needed to remove close anuimation (probably not that bad)
    overlayRef.outsidePointerEvents().subscribe(() => {
      this.closePopover();
    });
  }

  public ngOnInit(): void {
    this.content = this.data.content;

    if (this.content instanceof TemplateRef) {
      this.renderMethod = 'template';
      this.context = {
        close: this.closePopover.bind(this),
      };
    }

    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        skip(1), // TODO: edge case: if you popover is not opened with click event, this will skip first one
        filter((event) => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.closePopover();
      });
  }

  public closePopover(): void {
    if (this.isOnMobile) {
      this.animationState = 'leaveMobile';
    } else {
      // this.animationState = 'leaveDesktop';
      this.showing = false;
      this.overlayRef.dispose();
      this.sub.unsubscribe();
      this.alreadyCalled = true;
    }
  }

  public animationEnd(event: AnimationEvent): void {
    if (
      // (this.animationState === 'leaveDesktop' && event.triggerName === 'popoverAnimationDesktop') ||
      this.animationState === 'leaveMobile' &&
      event.triggerName === 'popoverAnimationMobile' &&
      !this.alreadyCalled
    ) {
      this.showing = false;
      this.overlayRef.dispose();
      this.sub.unsubscribe();
      this.alreadyCalled = true;
    }

    if (this.animationState === 'enterMobile') {
      this.animationState = 'currentMobile';
    }
  }

  public draggingMobileCloseBar(event: TouchEvent): void {
    const yPosition = window.innerHeight - event.touches[0].clientY;

    if (!this.initYPosition) {
      this.initYPosition = yPosition;
    }

    const procentageOfShowingPopover = ((yPosition / this.initYPosition) * 100 - 100) * -1;
    if (procentageOfShowingPopover > 0 && procentageOfShowingPopover < 100) {
      this.currentMobileYPosition = Math.abs((yPosition / this.initYPosition) * 100 - 100);
    }
  }

  public endOfDraggingMobileCloseBar(): void {
    if (this.currentMobileYPosition > 30) {
      this.closePopover();
    } else {
      this.animationState = 'enterMobile';
      this.currentMobileYPosition = 0;
    }
  }
}
