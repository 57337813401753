import {AfterContentInit, Component, ContentChild, forwardRef, SimpleChange, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NgSelectComponent} from '@ng-select/ng-select';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Genre} from 'src/generated/graphql';
import {GenresService} from '../../services/genres.service';

@UntilDestroy()
@Component({
  selector: 'app-genre-select-wrapper',
  templateUrl: './genre-select-wrapper.component.html',
  styleUrls: ['./genre-select-wrapper.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => GenreSelectWrapperComponent),
      multi: true,
    },
  ],
})
export class GenreSelectWrapperComponent implements AfterContentInit, ControlValueAccessor {
  @ContentChild('subgenre') public subgenreComponent: NgSelectComponent | undefined;

  @ContentChild('genre') public genreComponent: NgSelectComponent | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onTouched!: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onChanged!: any;

  constructor(private genresService: GenresService) {}

  public ngAfterContentInit(): void {
    this.genresService.genresAreLoaded.then(() => {
      if (this.genreComponent && this.subgenreComponent) {
        this.genreComponent.bindLabel = 'categoryName';
        this.genreComponent.bindValue = 'id';

        this.subgenreComponent.bindLabel = 'categoryName';
        this.subgenreComponent.bindValue = 'id';
        this.subgenreComponent.setDisabledState(true);

        // ngOnChanges was not called when dynamicly changing component @Inputs
        // so we call it manually ngOnChanges
        const changes: SimpleChanges = {};
        changes.items = new SimpleChange([], this.genresService.parentGenres, false);

        // eslint-disable-next-line @angular-eslint/no-lifecycle-call
        this.genreComponent.ngOnChanges(changes);

        this.genreComponent.changeEvent.pipe(untilDestroyed(this)).subscribe((parentSelectedGenre: Genre) => {
          this.onChanged(parentSelectedGenre?.id);

          this.subgenreComponent?.clearModel();
          this.subgenreComponent?.setDisabledState(true);

          if (parentSelectedGenre && this.subgenreComponent) {
            if (this.genresService.childGenres[parentSelectedGenre.id]?.length > 0) {
              this.subgenreComponent.setDisabledState(false);
              changes.items = new SimpleChange([], this.genresService.childGenres[parentSelectedGenre.id], false);
              // eslint-disable-next-line @angular-eslint/no-lifecycle-call
              this.subgenreComponent.ngOnChanges(changes);
            } else {
              this.subgenreComponent.setDisabledState(true);
            }
          }
        });

        this.subgenreComponent.changeEvent.pipe(untilDestroyed(this)).subscribe((childSelectedGenre: Genre) => {
          if (this.genreComponent?.hasValue && this.subgenreComponent?.hasValue) {
            this.onChanged(childSelectedGenre?.id);
          }
          if (this.genreComponent?.hasValue && !this.subgenreComponent?.hasValue) {
            this.onChanged((this.genreComponent.selectedValues[0] as Genre)?.id);
          }
        });
      }
    });
  }

  public writeValue(value: string): void {
    this.genresService.genresAreLoaded.then(() => {
      if (value) {
        const isParentGenre = this.genresService.parentGenres.some((x) => x.id === value);
        if (isParentGenre) {
          this.genreComponent?.writeValue(value);
        } else {
          Object.entries(this.genresService.childGenres).forEach(([parentId, childGenres]) => {
            const isChildGenre = childGenres.some((x) => x.id === value);
            if (isChildGenre) {
              this.genreComponent?.writeValue(parentId);
              setTimeout(() => {
                const changes: SimpleChanges = {};
                this.subgenreComponent?.setDisabledState(false);
                changes.items = new SimpleChange([], this.genresService.childGenres[parentId], false);
                // eslint-disable-next-line @angular-eslint/no-lifecycle-call
                this.subgenreComponent?.ngOnChanges(changes);

                this.subgenreComponent?.writeValue(value);
              });
            }
          });
        }
      } else {
        // TODO if you set valid value inside form constructor, then reset value to undefined immediately (in same tick), timeout inside
        // if(isChildGenre) has not yet executed, so subgenre value still stays seleced
        this.genreComponent?.clearModel();
        this.subgenreComponent?.clearModel();
        this.subgenreComponent?.setDisabledState(true);
      }
    });
  }

  public registerOnChange(fn: void): void {
    this.onChanged = fn;
  }
  public registerOnTouched(fn: void): void {
    // TODO: implement error checking
    this.onTouched = fn;
  }
}
