import {Pipe, PipeTransform} from '@angular/core';
import {appConfig} from 'src/app/app.config';

@Pipe({
  name: 'trackPrice',
})
export class TrackPricePipe implements PipeTransform {
  public transform(price: number | undefined | null): number {
    return (price || 0) / appConfig.priceDivider;
  }
}
