import {trigger, state, style, transition, animate, query, group} from '@angular/animations';

export const slideIn = trigger('slideInOutLeft', [
  transition(':enter', [
    style({transform: 'translateX(100%)', height: 0, width: 0, opacity: 0, overflow: 'hidden'}),
    animate('5s ease-out', style({transform: 'translateX(0%)', height: '*', width: '*', opacity: 1, overflow: 'hidden'})),
  ]),
  transition(':leave', [
    style({transform: 'translateX(0%)', height: '*', width: '*', opacity: 1, overflow: 'hidden'}),
    animate('5s ease-in', style({transform: 'translateX(100%)', height: '*', width: '*', opacity: 0, overflow: 'hidden'})),
  ]),
]);

export const fadeInOutLeaveOnState = trigger('fadeInOutLeaveOnState', [
  transition(':enter', [style({opacity: 0}), animate('0.3s ease-out', style({opacity: 1}))]),
  state(
    'leave',
    style({
      opacity: 0,
    }),
  ),
  transition('* => leave', animate('0.3s ease-out')),
]);

export const popoverAnimation = [
  trigger('popoverAnimationDesktop', [
    state(
      'void',
      style({
        opacity: 0,
      }),
    ),
    state(
      'enterDesktop',
      style({
        opacity: 1,
      }),
    ),
    state(
      'leaveDesktop',
      style({
        opacity: 0,
      }),
    ),
    transition('void => enterDesktop', animate('0.3s ease-in')),
    transition('enterDesktop => leaveDesktop', animate('0.3s ease-out')),
  ]),
  trigger('popoverAnimationMobile', [
    state(
      'void',
      style({
        transform: 'translateY(100%)',
      }),
    ),
    state(
      'currentMobile',
      style({
        transform: 'translateY({{currentProcentage}}%)',
      }),
      {params: {currentProcentage: 0}},
    ),
    state(
      'enterMobile',
      style({
        transform: 'translateY(0%)',
      }),
    ),
    state(
      'leaveMobile',
      style({
        transform: 'translateY(100%)',
      }),
    ),
    transition('void => currentMobile', animate('0.3s ease-in')),
    transition('currentMobile <=> leaveMobile', animate('0.3s ease-out')),
    transition('enterMobile <=> currentMobile', animate('0.3s ease-out')),
  ]),
];

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [style({opacity: 0}), animate('0.3s ease-out', style({opacity: 1}))]),
  transition(':leave', [style({opacity: 1}), animate('0.3s ease-out', style({opacity: 0}))]),
]);

const slideTo = (direction: any) => {
  const optional = {optional: true};
  return [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          [direction]: 0,
          width: '100%',
        }),
      ],
      optional,
    ),
    query(':enter', [style({[direction]: '-100%'})], optional),
    group([
      query(':leave', [animate('600ms ease', style({[direction]: '100%'}))], optional),
      query(':enter', [animate('600ms ease', style({[direction]: '0%'}))], optional),
    ]),
  ];
};

export const slider = trigger('routeAnimations', [
  transition('* => isLeft', slideTo('left')),
  transition('* => isRight', slideTo('right')),
  transition('isRight => *', slideTo('left')),
  transition('isLeft => *', slideTo('right')),
]);

export const expand = trigger('expandAnimation', [
  transition(':enter', [
    style({height: 0, opacity: 0, overflow: 'hidden'}),
    animate('0.3s ease-out', style({height: '*', opacity: 1, overflow: 'hidden'})),
  ]),
  transition(':leave', [
    style({height: '*', opacity: 1, overflow: 'hidden'}),
    animate('0.3s ease-in', style({height: 0, opacity: 0, overflow: 'hidden'})),
  ]),
]);

export const rotate = trigger('rotatedState', [
  state('default', style({transform: 'rotate(0)'})),
  state('rotated', style({transform: 'rotate(90deg)'})),
  transition('rotated => default', animate('240ms ease-out')),
  transition('default => rotated', animate('240ms ease-in')),
]);

export const slideFromTop = trigger('slideInOutAnimation', [
  transition(':enter', [
    style({
      transform: 'translateY(-100%)',
      opacity: 0,
    }),
    animate(
      '240ms ease-in',
      style({
        transform: 'translateY(0)',
        opacity: 1,
      }),
    ),
  ]),
  state(
    'leave',
    style({
      transform: 'translateY(-100%)',
      opacity: 0,
    }),
  ),
  transition('* => leave', animate('240ms ease-out')),
]);

export const slideFromBottom = trigger('slideFromBottom', [
  transition(':enter', [
    style({
      transform: 'translateY(100%)',
    }),
    animate(
      '500ms ease-in',
      style({
        transform: 'translateY(0)',
      }),
    ),
  ]),
  transition(':leave', [
    style({
      transform: 'translateY(0%)',
    }),
    animate(
      '500ms ease-out',
      style({
        transform: 'translateY(100%)',
      }),
    ),
  ]),
]);

export const rotate180 = trigger('rotate180', [
  state(
    'down',
    style({
      transform: 'rotate(0)',
    }),
  ),
  transition('* => down', animate('240ms ease-out')),
  state(
    'up',
    style({
      transform: 'rotate(-180deg)',
    }),
  ),
  transition('* => up', animate('240ms ease-out')),
]);

export const stepper = [
  trigger('stepTransition', [
    state('previous', style({transform: 'translate3d(-100%, 0, 0)', opacity: 0})),
    state('current', style({transform: 'translate3d(0, 0, 0)', opacity: 1})),
    state('next', style({transform: 'translate3d(100%, 0, 0)', opacity: 0})),
    transition('* => *', animate('500ms cubic-bezier(0.35, 0, 0.25, 1)')),
  ]),
];
// export const slideFromTop = trigger('slideInOutAnimation', [
//   state(':enter', style({transform: 'translateY(0%)', opacity: 1})),
//   state('leave', style({transform: 'translateY(-100%)', opacity: 0})),
//   transition('enter <=> leave', animate('1000ms linear')),
// ]);
