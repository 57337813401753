<h5 ngClass.lt-md="text-center">Create a playlist</h5>
<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center" class="pt-3 w-100">
  <app-cover-image-upload coverImageType="playlist" [disableFilePicking]="newPlayListForm.disabled"></app-cover-image-upload>
  <form [formGroup]="newPlayListForm" appDisableForm class="w-100">
    <div fxLayout="column" ngClass.gt-sm="ml-3">
      <div class="mt-3 input-field-wrapper">
        <label for="playlistName">Playlist name</label>
        <input id="playlistName" formControlName="playlistName" type="text" placeholder="Best playlist" />
      </div>

      <div class="mt-3 input-field-wrapper radio-inputs-wrapper">
        <label>Privacy</label>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayoutAlign="start center">
            <label class="radio" for="private">Private</label>
            <input type="radio" id="private" name="type" [value]="playlistType.PRIVATE" formControlName="type" />
            <label class="radio" for="public">Public</label>
            <input type="radio" id="public" name="type" [value]="playlistType.PUBLIC" formControlName="type" />
          </div>
          <button
            class="ml-3 big"
            [disabled]="newPlayListForm.invalid"
            [class.loader]="newPlayListForm.disabled"
            (click)="createNewPlaylist()"
          >
            Create
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
