<a
  #tile
  class="card-wrapper"
  (click)="disabled || leftClick()"
  (contextmenu)="disabled || rightClick($event)"
  appLongPress
  (mouseLongPress)="rightClick($event)"
  [class.disabled]="disabled"
>
  <div class="img-wrapper mb-2">
    <div *ngIf="img" class="moved-img">
      <img [src]="img" [style.border-radius]="borderRadius" (error)="img = null" />
      <fa-icon *ngIf="icon && type !== 'Artist' && type !== 'Track' && type !== 'ProfilePublic'" [icon]="icon"></fa-icon>
    </div>
    <div *ngIf="img" class="back-img img-bg" [style.background-image]="'url(' + img + ')'" [style.border-radius]="borderRadius"></div>

    <div *ngIf="!img" class="no-img drop-shadow" [style.border-radius]="borderRadius">
      <fa-icon [icon]="icon" class="fa-2x"></fa-icon>
    </div>
  </div>
  <app-title-subtitle [title]="title" [subtitle]="subtitle" [disabled]="disabled" [explicit]="explicit"></app-title-subtitle>
</a>
