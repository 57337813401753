import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Track} from 'src/app/blare-app/shared/interfaces/track.interface';

@Component({
  selector: 'app-track-card',
  templateUrl: './track-card.component.html',
  styleUrls: ['./track-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackCardComponent implements OnInit {
  @Input() public item: Track | undefined;
  @Input() public index: number | undefined;

  private tracks: Track[] = [
    {
      id: 0,
      name: 'Kissing in the Disco',
      author: 'Young Emily',
      img: 'assets/images/Cover-art.png',
    },
    {
      id: 1,
      name: 'Ultimate',
      author: 'JakeioG',
      img: 'assets/images/Cover-art-1.png',
    },
    {
      id: 2,
      name: 'Stop Sending Flo,..',
      author: 'Flight Woods',
      img: 'assets/images/Cover-art-2.png',
    },
    {
      id: 3,
      name: 'Feelings',
      author: 'Odd Giraffes',
      img: 'assets/images/Cover-art-3.png',
    },
    {
      id: 4,
      name: 'Be Proud',
      author: 'Foxesica',
      img: 'assets/images/Cover-art-4.png',
    },
    {
      id: 5,
      name: 'Let’s go on a ride',
      author: 'Mavis Talent',
      img: 'assets/images/Cover-art-5.png',
    },
    {
      id: 6,
      name: 'Do You Like It?',
      author: 'Fivedust',
      img: 'assets/images/Cover-art-6.png',
    },
    {
      id: 7,
      name: 'Love Again',
      author: 'JakeioG ft. Your Sugar',
      img: 'assets/images/Cover-art-7.png',
    },
    {
      id: 8,
      name: 'Ryhme with me',
      author: 'JakeioG',
      img: 'assets/images/Cover-art-8.png',
    },
    {
      id: 9,
      name: 'Fresh!!!',
      author: 'Kumari Rogers',
      img: 'assets/images/Cover-art-9.png',
    },
    {
      id: 10,
      name: 'You Do You',
      author: 'Ollie Isidore',
      img: 'assets/images/Cover-art-10.png',
    },
    {
      id: 11,
      name: 'Midnight Mind',
      author: 'Theodore HH',
      img: 'assets/images/Cover-art-11.png',
    },
    {
      id: 12,
      name: 'This is Us',
      author: 'Dario K ft. Youngza Z',
      img: 'assets/images/Cover-art-12.png',
    },
    {
      id: 13,
      name: 'Sandy',
      author: 'FreeWilly',
      img: 'assets/images/Cover-art-13.png',
    },
    {
      id: 14,
      name: 'Leave me alone',
      author: 'Artsy',
      img: 'assets/images/Cover-art-14.png',
    },
    {
      id: 15,
      name: 'Best time of my life',
      author: 'PoolCrew',
      img: 'assets/images/Cover-art-15.png',
    },
    {
      id: 16,
      name: 'Don’t leave me ',
      author: 'Juicy Allan',
      img: 'assets/images/Cover-art-16.png',
    },
    {
      id: 17,
      name: 'Vibezz',
      author: 'Seaz',
      img: 'assets/images/Cover-art-17.png',
    },
    {
      id: 18,
      name: 'Helium',
      author: 'BoyBand',
      img: 'assets/images/Cover-art-18.png',
    },
    {
      id: 19,
      name: 'Black and white',
      author: 'Christi',
      img: 'assets/images/Cover-art-19.png',
    },
    {
      id: 20,
      name: 'Wishes are good',
      author: 'SergyB',
      img: 'assets/images/Cover-art-20.png',
    },
    {
      id: 21,
      name: 'Be Free',
      author: 'FreeSoulz',
      img: 'assets/images/Cover-art-21.png',
    },
    {
      id: 22,
      name: 'Nice places',
      author: 'Travelers',
      img: 'assets/images/Cover-art-22.png',
    },
  ];

  public ngOnInit(): void {
    if (!this.item) {
      this.item = this.tracks.find((item) => item.id === this.index)
        ? this.tracks.find((item) => item.id === this.index)
        : this.getRandomTrack();
    }
  }

  private getRandomTrack(): Track {
    return this.tracks[Math.floor(Math.random() * this.tracks.length)];
  }
}
