<div fxLayoutAlign="space-between center" fxLayout="row" class="w-100" fxLayoutGap="8px" [style.opacity]="disabled ? 0.3 : 1">
  <button *ngIf="!allSlidesAreInView" class="no-style" (click)="swiper?.swiperRef?.slidePrev()">
    <fa-icon [icon]="FaIcons.chevronLeft"></fa-icon>
  </button>
  <swiper
    #swiper
    [slidesPerView]="1"
    [spaceBetween]="24"
    [breakpoints]="breakpoints"
    (slideChange)="localSlideChange()"
    (breakpoint)="breakPointChange()"
    class="p-2"
  >
    <ng-template *ngFor="let slide of listItems" swiperSlide>
      <ng-container [ngTemplateOutlet]="slide"></ng-container>
    </ng-template>
  </swiper>
  <button *ngIf="!allSlidesAreInView" class="no-style" (click)="swiper?.swiperRef?.slideNext()">
    <fa-icon [icon]="FaIcons.chevronRight"></fa-icon>
  </button>
</div>
<div *ngIf="!allSlidesAreInView" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="16px" class="mt-3">
  <div
    class="swiper-dot"
    (click)="swiper?.swiperRef?.slideTo(i)"
    *ngFor="let dummy of [].constructor(numberOfDots); let i = index"
    [class.active]="i === currentSlide"
  ></div>
</div>
