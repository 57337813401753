/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {ApolloClientOptions, FieldFunctionOptions, InMemoryCache, Reference, split, StoreObject} from '@apollo/client/core';
import {HttpLink, HttpBatchLink} from 'apollo-angular/http';

const getNestedObjectProperty = (selectionArray: string[][], obj: any) => {
  selectionArray.forEach((keys: string[]) => {
    keys.forEach((key) => {
      if (obj && key in obj) {
        // eslint-disable-next-line no-param-reassign
        obj = obj[key];
      }
    });
  });
  return obj;
};

export const mergeArrayByField = (field: string, objNestedField: string[][]) => (
  existing: any,
  incoming: any,
  {readField, mergeObjects}: FieldFunctionOptions,
): any => {
  // console.log('ex', existing);
  // console.log('in', incoming);
  const merged: any = existing ? existing.edges.slice(0) : [];

  const indexes: Record<string, number> = Object.create(null);
  if (existing && existing.edges) {
    existing.edges.forEach((item: {node: Reference | StoreObject | undefined}, index: number) => {
      indexes[readField(field.toString(), getNestedObjectProperty(objNestedField, item)) as string] = index;
    });
  }

  if (incoming && incoming.edges) {
    incoming.edges.forEach((item: {node: Reference | StoreObject | undefined}) => {
      const name = readField(field.toString(), getNestedObjectProperty(objNestedField, item)) as string;
      const index = indexes[name];
      if (typeof index === 'number') {
        merged[index] = mergeObjects(merged[index], item);
      } else {
        // i think, here needs to be an extra logic, to handle new notifications, now notifications that are first will be pushed to last
        indexes[name] = merged.length;
        merged.push(item);
      }
    });
  }
  // console.log('merged', merged);
  const pageInfo = incoming ? incoming.pageInfo : existing.pageInfo;
  return {
    pageInfo,
    edges: merged,
  };
};

const uri = 'v1/graphql'; // <-- add the URL of the GraphQL server here
export const createApollo = (httpLink: HttpLink, httpBatchLink: HttpBatchLink): ApolloClientOptions<unknown> => ({
  link: split(
    (operation) => operation.getContext().batch === true,
    httpBatchLink.create({uri, batchInterval: 100}),
    httpLink.create({uri}),
  ),
  cache: new InMemoryCache({
    typePolicies: {
      ProfilePublic: {
        keyFields: ['userId'],
        fields: {
          presignedCoverImgs: {
            merge: (_, incoming) => incoming,
          },
          presignedProfileImgs: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      ProfileSetting: {
        keyFields: ['userId'],
      },
      usersNotification: {
        keyFields: ['userId'],
      },
      User: {
        fields: {
          profilePublicByUserId: {
            merge: true,
          },
          artistsByUserId: {
            merge: true,
          },
        },
      },
      Artist: {
        fields: {
          presignedCoverImgs: {
            merge: (_, incoming) => incoming,
          },
          presignedProfileImgs: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      Query: {
        fields: {
          allNotifications: {
            merge: mergeArrayByField('id', [['node']]),
            read: (existing) => existing,
          },
          allLikes: {
            keyArgs: ['condition'],
            merge: mergeArrayByField('id', [['node'], ['artistByArtistId', 'albumByAlbumId', 'trackByTrackId']]),
          },
          allPlayHistories: {
            keyArgs: false,
            merge: mergeArrayByField('id', [['node']]),
          },
          allEvents: {
            keyArgs: ['filter'],
            merge: mergeArrayByField('id', [['node']]),
          },
          allFollowers: {
            keyArgs: ['filter', 'condition'],
            merge: mergeArrayByField('id', [['node']]),
          },
          allFollowings: {
            keyArgs: ['filter', 'condition'],
            merge: mergeArrayByField('id', [['node']]),
          },
          FavoriteTracks: {
            keyArgs: false,
            merge: mergeArrayByField('id', [['node']]),
          },
          allTracks: {
            keyArgs: ['filter', 'condition'],
            merge: mergeArrayByField('id', [['node']]),
          },
          allAlbums: {
            keyArgs: ['filter', 'condition'],
            merge: mergeArrayByField('id', [['node']]),
          },
          allArtists: {
            keyArgs: ['filter', 'condition'],
            merge: mergeArrayByField('id', [['node']]),
          },
          allProfilePublics: {
            keyArgs: ['filter', 'condition'],
            merge: mergeArrayByField('userId', [['node']]),
          },
          searchBlarecastUrls: {
            keyArgs: ['search'],
            merge: mergeArrayByField('userId', [['node']]),
          },
          autoGeneratedQueues: {
            merge: (_, incoming) => incoming,
          },
        },
      },
    },
  }),
});

@NgModule({
  imports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, HttpBatchLink],
    },
  ],
})
export class GraphQLModule {}
