import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {finalize} from 'rxjs/operators';
import {PlaylistOptionsResponse, PlaylistOptionsService} from 'src/app/blare-app/shared/services/playlist-options.service';
import {FaIcons} from 'src/app/shared/constants/fa-icons.enum';
import {ImgSize} from 'src/app/shared/constants/img-size.enum';
import {Playlist} from 'src/generated/graphql';
import {TileBaseComponent} from '../tile-base.component';

@UntilDestroy()
@Component({
  selector: 'app-tile-playlist',
  templateUrl: './../tile-base.component.html',
  styleUrls: ['./../tile-base.component.scss'],
})
export class TilePlaylistComponent extends TileBaseComponent {
  @Input() public set item(value: Playlist) {
    this.playlist = value;
    this.id = value.id ? value.id : '';
    this.title = value.title ? value.title : '';
    this.img = value.presignedImgs?.find((x) => x?.size === ImgSize.Small)?.url || null;
    this.subtitle = `${value.likesByPlaylistId?.totalCount ? value.likesByPlaylistId.totalCount : 0} likes`;
    this.type = value.__typename ? value.__typename : undefined;
  }
  @ViewChild('tile') public tile: ElementRef | undefined;

  public isLoading = false;
  public borderRadius = '4px';
  public icon = FaIcons.listMusic;

  private playlist: Playlist | undefined;

  constructor(private router: Router, private playlistOptionsService: PlaylistOptionsService) {
    super();
  }

  public leftClick(): void {
    this.router.navigate(['playlist', this.id]);
  }

  public rightClick(event: MouseEvent): void {
    event.preventDefault();
    this.setCursor('wait', 'wait');
    this.playlistOptionsService
      .openPlaylistOption(this.playlist as Playlist, event.clientX, event.clientY)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.setCursor();
        }),
      )
      .subscribe({
        next: (data: PlaylistOptionsResponse) => (this.playlist = data.playlist),
      });
  }

  private setCursor(cursorBody = 'auto', cursorTile = 'pointer') {
    document.body.style.cursor = cursorBody;
    (this.tile as ElementRef).nativeElement.style.cursor = cursorTile;
  }
}
