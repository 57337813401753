import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {FaIcons} from 'src/app/shared/constants/fa-icons.enum';
import {ImgSize} from 'src/app/shared/constants/img-size.enum';
import {ProfilePublic} from 'src/generated/graphql';
import {TileBaseComponent} from '../tile-base.component';

@Component({
  selector: 'app-tile-listener',
  templateUrl: './../tile-base.component.html',
  styleUrls: ['./../tile-base.component.scss'],
})
export class TileListenerComponent extends TileBaseComponent {
  @Input() public set item(value: ProfilePublic) {
    this.id = value.userId ? value.userId : '';
    this.title = value.displayName ? value.displayName : '';
    this.img = value.presignedProfileImgs?.find((x) => x?.size === ImgSize.Small)?.url || null;
    this.subtitle = `${value.followerCount ? value.followerCount : 0} followers`;
    this.type = value.__typename ? value.__typename : undefined;
  }

  public borderRadius = '50%';
  public icon = FaIcons.userMusic;

  constructor(private router: Router) {
    super();
  }

  public leftClick(): void {
    this.router.navigate(['listener', this.id]);
  }

  public rightClick(event: Event): void {
    event.preventDefault();
    console.log('right', this.type);
  }
}
