import {Component, OnInit} from '@angular/core';
import {PopoverData} from 'src/app/shared/components/popover/popover-config';
import {PopoverComponent} from 'src/app/shared/components/popover/popover.component';
import {FaIcons, FaIconsSolid} from 'src/app/shared/constants/fa-icons.enum';

export type TilePopUpTypes = 'album' | 'playlist' | 'song';

export interface PopoverTileOptionData {
  favouritesButton?: () => void; // Add to favourite
  shareButton?: () => void; // Share [type]
  addToPlaylistButton?: () => void; // Add to playlist
  removeFromPlaylistButton?: () => void; // Remove from playlist
  addToQueueButton?: () => void; // Add to queue
  removeFromQueueButton?: () => void; // Remove from queue
  viewButton?: () => void; // View artist
  editButton?: () => void; // Edit [type]
  deleteButton?: () => void; // Delete [type]
  type: TilePopUpTypes;
  isLiked: boolean;
}

@Component({
  selector: 'app-popover-tile-options',
  templateUrl: './popover-tile-options.component.html',
  styleUrls: ['./popover-tile-options.component.scss'],
})
export class PopoverTileOptionsComponent implements OnInit {
  public heart = FaIcons.heart;
  public readonly FaIcons = FaIcons;

  constructor(private popoverParentComponent: PopoverComponent, public popoverData: PopoverData<PopoverTileOptionData>) {}

  public get favourite(): () => void {
    return this.popoverData.data?.favouritesButton as () => void;
  }
  public get share(): () => void {
    return this.popoverData.data?.shareButton as () => void;
  }
  public get playlistAdd(): () => void {
    return this.popoverData.data?.addToPlaylistButton as () => void;
  }
  public get playlistRemove(): () => void {
    return this.popoverData.data?.removeFromPlaylistButton as () => void;
  }
  public get queueAdd(): () => void {
    return this.popoverData.data?.addToQueueButton as () => void;
  }
  public get queueRemove(): () => void {
    return this.popoverData.data?.removeFromQueueButton as () => void;
  }
  public get view(): () => void {
    return this.popoverData.data?.viewButton as () => void;
  }
  public get edit(): () => void {
    return this.popoverData.data?.editButton as () => void;
  }
  public get delete(): () => void {
    return this.popoverData.data?.deleteButton as () => void;
  }
  public ngOnInit(): void {
    this.heart = this.popoverData.data?.isLiked ? FaIconsSolid.heart : FaIcons.heart;
  }
  public close(): void {
    this.popoverParentComponent.closePopover();
  }
}
