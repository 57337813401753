import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {Track} from 'src/generated/graphql';

export type RepeatType = 'single' | 'autoQueue' | 'none';

export interface PlayerState {
  key: string;
  playing: boolean;
  randomSequance: boolean;
  repeatType: RepeatType;
  volumeLevel: number;
  currentPlayingTrack: Track | undefined;
  title: string;
}

export const createInitialState = (): PlayerState => ({
  key: '',
  playing: false,
  randomSequance: false,
  volumeLevel: 1, // [0 - 1] interval
  currentPlayingTrack: undefined,
  repeatType: 'none',
  title: '',
});

// [currentType]: 'next type'
export const toggleRepeatTypeNext = {
  none: 'autoQueue',
  autoQueue: 'single',
  single: 'none',
};

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'player'})
export class PlayerStore extends Store<PlayerState> {
  constructor() {
    super(createInitialState());
  }

  public play(): void {
    this.update({playing: true});
  }

  public like(likedAsListener: boolean): void {
    this.update(({currentPlayingTrack, ...obj}) => ({
      currentPlayingTrack: {
        ...(currentPlayingTrack as Track),
        likedAsListener,
      },
      ...obj,
    }));
  }

  public pause(): void {
    this.update({playing: false});
  }

  public changeVolumeLevel(newVolumeLevel: number): void {
    this.update({volumeLevel: newVolumeLevel});
  }

  public toggleRandomSequance(): void {
    this.update({randomSequance: !this.getValue().randomSequance});
  }

  public toggleRepeatType(currentType = this.getValue().repeatType || 'none'): void {
    this.update({repeatType: toggleRepeatTypeNext[currentType] as RepeatType});
  }

  public updateTitle(title = ''): void {
    this.update({title});
  }
}
