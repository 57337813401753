/* eslint-disable no-empty-function */
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {Album, Artist, Playlist, ProfilePublic, Track} from 'src/generated/graphql';

export abstract class TileBaseComponent {
  public id = '';
  public icon: IconProp | undefined;
  public title = '';
  public img: string | null = null;
  public subtitle = '';
  public disabled = false;
  public explicit = false;
  public borderRadius = '0';
  public type:
    | Artist['__typename']
    | Playlist['__typename']
    | Track['__typename']
    | Album['__typename']
    | ProfilePublic['__typename']
    | 'dailyMix';

  public abstract leftClick(): void;
  public abstract rightClick(event: Event): void;
}
