import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser} from '@abacritt/angularx-social-login';
import {HttpClient, HttpContext} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Apollo} from 'apollo-angular';
import {from, Observable, throwError} from 'rxjs';
import {catchError, switchMap, tap, timeout} from 'rxjs/operators';
import {PlayerStore} from 'src/app/blare-app/shared/state/player.store';
import {AccountType} from 'src/generated/graphql';
import {SKIP_INTERCEPTOR_ERROR_HANDLING} from '../interceptors/error.interceptor';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const AppleID: any;
interface ErrorResponse {
  message: string;
  status: number;
  name: string;
}

interface RegisterResponse {
  userRegistered: string;
  confirmationEmailSend: string;
  error?: ErrorResponse;
}

interface LoginResponse {
  token: string;
  id: string;
  refreshToken: string;
}

interface LogoutResponse {
  message: string;
}

interface UserInfo {
  email: string;
  password: string;
  accountType: string;
}

interface EmailSendResponse {
  emailSent: boolean;
}
interface PasswordChangeResponse {
  passwordChanged: boolean;
}
interface EmailConfirmedResponse extends LoginResponse {
  emailConfirmed: boolean;
  welcomeEmailSent: boolean;
}

export type SocialPlatform = 'facebook' | 'google' | 'apple';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public token: null | string = null;
  public refreshToken: null | string = null;

  private readonly TOKEN_KEY = 'TOKEN_KEY';
  private readonly REFRESH_TOKEN_KEY = 'REFRESH_TOKEN_KEY';

  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private router: Router,
    private socialAuthService: SocialAuthService,
    private playerStore: PlayerStore,
  ) {
    this.loadTokens();
  }

  public registerUser(email: string, password: string, accountType: AccountType): Observable<RegisterResponse> {
    const userInfo: UserInfo = {
      email,
      password,
      accountType,
    };
    return this.http
      .post<RegisterResponse>('register', userInfo, {context: new HttpContext().set(SKIP_INTERCEPTOR_ERROR_HANDLING, true)})
      .pipe(
        tap((response: RegisterResponse) => {
          console.log(response);
        }),
      );
  }

  public authorizeUserSocial(platform: SocialPlatform, accountType?: AccountType): Observable<LoginResponse> {
    const object = {};

    // This is sent only on register
    if (accountType) {
      Object.assign(object, {accountType});
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let socialAuth: Observable<SocialUser | any> | undefined;

    if (platform === 'google' || platform === 'facebook') {
      const providerId = platform === 'google' ? GoogleLoginProvider.PROVIDER_ID : FacebookLoginProvider.PROVIDER_ID;
      socialAuth = from(this.socialAuthService.signIn(providerId));
    }

    if (platform === 'apple') {
      AppleID.auth.init({
        clientId: 'com.blarecast.blareweb',
        scope: 'name email',
        redirectURI: 'https://www.blarecast.io/apple-callback',
        state: 'init',
        nonce: 'test',
        usePopup: true, // or false defaults to false
      });

      socialAuth = from(AppleID.auth.signIn());
    }

    if (socialAuth) {
      return socialAuth.pipe(
        switchMap((user) => {
          if (platform === 'google') {
            Object.assign(object, {access_token: user.idToken});
          } else if (platform === 'facebook') {
            Object.assign(object, {access_token: user.authToken});
          } else if (platform === 'apple') {
            Object.assign(object, {code: user.authorization.code, id_token: user.authorization.id_token});
          }
          return this.http
            .post<LoginResponse>(
              `auth/${platform}/web`,
              {...object},
              {context: new HttpContext().set(SKIP_INTERCEPTOR_ERROR_HANDLING, true)},
            )
            .pipe(
              tap((response: LoginResponse) => {
                this.saveToken(response.token);
                this.saveRefreshToken(response.refreshToken);
              }),
            );
        }),
      );
    }

    return throwError('Social authorization failed');
  }

  public loginWithEmail(email: string, password: string): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>('login', {email, password}, {context: new HttpContext().set(SKIP_INTERCEPTOR_ERROR_HANDLING, true)})
      .pipe(
        tap((response: LoginResponse) => {
          this.saveToken(response.token);
          this.saveRefreshToken(response.refreshToken);
        }),
      );
  }

  public tryRefreshingToken(): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>(
        'refresh-token',
        {refreshToken: this.refreshToken},
        {context: new HttpContext().set(SKIP_INTERCEPTOR_ERROR_HANDLING, true)},
      )
      .pipe(
        tap((response: LoginResponse) => {
          this.saveToken(response.token);
          this.saveRefreshToken(response.refreshToken);
        }),
      );
  }

  public logoutActions() {
    this.router.navigate(['account/login']);
    this.token = null;
    this.refreshToken = null;
    localStorage.removeItem(this.TOKEN_KEY);
    localStorage.removeItem(this.REFRESH_TOKEN_KEY);
    this.playerStore.pause();
    this.apollo.client.clearStore(); // reset global cache
  }

  public logout(): Observable<LogoutResponse> {
    return this.http.post<LogoutResponse>('logout', {}).pipe(
      timeout(10000),
      tap(() => {
        this.logoutActions();
      }),
      catchError((error) => {
        this.logoutActions();
        return throwError(error);
      }),
    );
  }

  public resendEmailConfirmEmail(token: string): Observable<EmailSendResponse> {
    return this.http.post<EmailSendResponse>('registration/confirmation/resend', {token});
  }

  public confirmRegistrationEmail(emailConfirmToken: string): Observable<EmailConfirmedResponse> {
    return this.http
      .post<EmailConfirmedResponse>(
        'registration/confirmation',
        {token: emailConfirmToken},
        {context: new HttpContext().set(SKIP_INTERCEPTOR_ERROR_HANDLING, true)},
      )
      .pipe(
        tap((response: EmailConfirmedResponse) => {
          this.saveToken(response.token);
          this.saveRefreshToken(response.refreshToken);
        }),
      );
  }

  public sendResetPasswordEmail(toEmail: string): Observable<EmailSendResponse> {
    return this.http.post<EmailSendResponse>('reset-password', {email: toEmail});
  }

  public changePassword(token: string, newPassword: string): Observable<PasswordChangeResponse> {
    return this.http.post<PasswordChangeResponse>('new-password', {token, password: newPassword});
  }

  public loadTokens(): void {
    this.token = localStorage.getItem(this.TOKEN_KEY);
    this.refreshToken = localStorage.getItem(this.REFRESH_TOKEN_KEY);
  }

  public tokensExists(): boolean {
    if (this.token === null || this.refreshToken === null) {
      return false;
    }
    return true;
  }

  public saveToken(token: string): void {
    localStorage.setItem(this.TOKEN_KEY, token);
    this.token = token;
  }

  public saveRefreshToken(refreshToken: string): void {
    localStorage.setItem(this.REFRESH_TOKEN_KEY, refreshToken);
    this.refreshToken = refreshToken;
  }
}

// const CLIENT_ID = 'com.blarecast.blareweb';
// const REDIRECT_API_URL = 'https://blarecast.io';

// const width = 600;
// const height = 700;
// const leftPosition = window.screen.width / 2 - (width / 2 + 10);
// const topPosition = window.screen.height / 2 - (height / 2 + 50);
// const features = `left=${leftPosition},top=${topPosition},screenX=${leftPosition},screenY=${topPosition},width=${width},height=${height}`;

// window.open(
//   'https://appleid.apple.com/auth/authorize?' +
//     `client_id=${CLIENT_ID}&` +
//     `redirect_uri=${encodeURIComponent(REDIRECT_API_URL)}&` +
//     'response_type=code id_token&' +
//     'scope=name email&' +
//     'response_mode=form_post',
//   '_blank',
//   features,
// );

// return fromEvent(window, 'message').pipe(
//   switchMap((event) => {
//     console.log(event);

//     // Object.assign(object, {id_token: user.idToken});

//     return this.http
//       .post<LoginResponse>(`auth/${platform}/web`, {...object})
//       .pipe(
//         tap((response: LoginResponse) => {
//           this.saveToken(response.token);
//           this.saveRefreshToken(response.refreshToken);
//         }),
//       );
//   }),
// );

// window.addEventListener('message', async (event) => {
//   console.log(event);
//   // const decodedToken = this.jwtHelper.decodeToken(event.data.id_token);
//   // let requestData = {};
//   // if (event.data.user) {
//   //   const userName = JSON.parse(event.data.user);
//   //   requestData = {
//   //     email: decodedToken.email,
//   //     name: `${userName.name.firstName} ${userName.name.lastName}`,
//   //     socialId: decodedToken.sub,
//   //   };
//   // } else {
//   //   requestData = {
//   //     email: decodedToken.email,
//   //     socialId: decodedToken.sub,
//   //   };
//   // }
//   // console.log(`User Data : ${requestData}`);
//   // do your next stuff here
// });
