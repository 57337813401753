import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator} from '@angular/forms';
import {fadeInOut} from 'src/app/shared/animations/animations';

@Component({
  selector: 'app-input-blarecast-url',
  templateUrl: './input-blarecast-url.component.html',
  styleUrls: ['./input-blarecast-url.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => InputBlarecastUrlComponent),
      multi: true,
    },

    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => InputBlarecastUrlComponent),
      multi: true,
    },
  ],
  animations: [fadeInOut],
})
export class InputBlarecastUrlComponent implements ControlValueAccessor, Validator {
  @Input() public labelText = 'Blarecast url';
  @Input() public id = 'blarecastUrl';
  @Input() public isUserArtist = false;
  @Output() public enterKeyPressed = new EventEmitter<void>();

  public ngControl: AbstractControl | undefined;

  public minLength = 1;
  public maxLength = 50;

  public disabled = false;
  public touched = false;

  public value = '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onTouched!: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onChanged!: any;

  public get showUniqueNote(): boolean {
    return (
      this.ngControl?.pristine ||
      (!this.ngControl?.errors?.maxlenght && !this.ngControl?.errors?.minlenght && !this.ngControl?.errors?.specialChars)
    );
  }

  public writeValue(value: string): void {
    this.filterUrl(value);
  }

  public registerOnChange(fn: void): void {
    this.onChanged = fn;
  }

  public registerOnTouched(fn: void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public setTouched(): void {
    this.touched = true;
    this.onTouched();
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    if (!this.ngControl) {
      this.ngControl = control;
    }

    // https://stackoverflow.com/questions/11704267/in-javascript-how-to-conditionally-add-a-member-to-an-object
    const errors = {
      ...(!control.value.length && {required: true}),
      ...(control.value.length > this.maxLength && {maxlenght: true}),
      ...(control.value.length <= this.minLength && {minlenght: true}),
      ...(control.hasError('urlIsTaken') && {urlIsTaken: true}),
      ...(/[^a-zA-Z0-9+\-_.]/g.test(control.value) && {specialChars: true}),
    };

    return errors;
  }

  public valueChange(value: string, event?: Event | KeyboardEvent): void {
    if (event && 'code' in event && event?.code === 'Enter') {
      this.enterKeyPressed.emit();
    }

    this.filterUrl(value);
  }

  private filterUrl(value: string): void {
    if (this.ngControl?.hasError('urlIsTaken')) {
      this.ngControl?.setErrors({});
    }

    this.value = value.replace(/ /g, '-');

    if (this.onChanged) {
      this.onChanged(this.value);
    }
  }
}
