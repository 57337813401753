import {gql} from 'apollo-angular';
import {Injectable} from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: any;
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: any;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /** The day, does not include a time. */
  Date: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
};

export type AccountBalanceConsumption = {
  __typename?: 'AccountBalanceConsumption';
  consumption?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type AccountBalanceConsumptionPayload = {
  __typename?: 'AccountBalanceConsumptionPayload';
  accountBalance?: Maybe<AccountBalancePayload>;
  rows?: Maybe<Array<Maybe<AccountBalanceConsumption>>>;
};

export type AccountBalancePayload = {
  __typename?: 'AccountBalancePayload';
  avgMonthConsumption?: Maybe<Scalars['Int']>;
  balance?: Maybe<Scalars['Int']>;
};

export enum AccountType {
  Artist = 'ARTIST',
  Band = 'BAND',
  Listener = 'LISTENER',
}

/** All input for the `addTrackToPlaylist` mutation. */
export type AddTrackToPlaylistInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  playlist?: InputMaybe<Scalars['UUID']>;
  track?: InputMaybe<Scalars['UUID']>;
  trackNumber?: InputMaybe<Scalars['Int']>;
};

/** The output of our `addTrackToPlaylist` mutation. */
export type AddTrackToPlaylistPayload = {
  __typename?: 'AddTrackToPlaylistPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  uuid?: Maybe<Scalars['UUID']>;
};

/** All input for the `addTracksToAlbum` mutation. */
export type AddTracksToAlbumInput = {
  albumId?: InputMaybe<Scalars['UUID']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  trackIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
};

/** The output of our `addTracksToAlbum` mutation. */
export type AddTracksToAlbumPayload = {
  __typename?: 'AddTracksToAlbumPayload';
  albumsTracks?: Maybe<Array<Maybe<AlbumsTrack>>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** `Album` table */
export type Album = Node & {
  __typename?: 'Album';
  /** Reads and enables pagination through a set of `AlbumsTrack`. */
  albumsTracksByAlbumId: AlbumsTracksConnection;
  /** All the `Artist`-s part of the `Album` */
  allArtists?: Maybe<Scalars['String']>;
  /** The `Artist` that has created the `Album` */
  artist?: Maybe<Scalars['UUID']>;
  /** Reads a single `Artist` that is related to this `Album`. */
  artistByArtist?: Maybe<Artist>;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByExtraArtistAlbumAndArtist: AlbumArtistsByExtraArtistAlbumAndArtistManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByLikeAlbumIdAndActingArtistId: AlbumArtistsByLikeAlbumIdAndActingArtistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByLikeAlbumIdAndArtistId: AlbumArtistsByLikeAlbumIdAndArtistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByRecentlyPlayedAlbumIdAndArtistId: AlbumArtistsByRecentlyPlayedAlbumIdAndArtistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByTrackAlbumAndArtist: AlbumArtistsByTrackAlbumAndArtistManyToManyConnection;
  /** Defines the datetime with time zone of `Album` was first created */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** The `Album` description */
  description?: Maybe<Scalars['String']>;
  /** The type of digital rights this `Album` has */
  digitalRights?: Maybe<Scalars['String']>;
  /** Does the `Album` contain explicit content */
  explicit?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `ExtraArtist`. */
  extraArtistsByAlbum: ExtraArtistsConnection;
  /** Extra `Artist`-s part of the `Album` */
  extraArtistsText?: Maybe<Scalars['String']>;
  /** The type of `Genre` the `Album` is */
  genre?: Maybe<Scalars['UUID']>;
  /** Reads a single `Genre` that is related to this `Album`. */
  genreByGenre?: Maybe<Genre>;
  /** Reads and enables pagination through a set of `GenresAlbum`. */
  genresAlbumsByAlbumId: GenresAlbumsConnection;
  /** Reads and enables pagination through a set of `Genre`. */
  genresByGenresAlbumAlbumIdAndGenreId: AlbumGenresByGenresAlbumAlbumIdAndGenreIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Genre`. */
  genresByTrackAlbumAndGenre: AlbumGenresByTrackAlbumAndGenreManyToManyConnection;
  id: Scalars['UUID'];
  /** The `Album` image */
  img?: Maybe<Scalars['String']>;
  /** Defines the amount of likes the `Album` has */
  likeCount?: Maybe<Scalars['Int']>;
  likedAsListener?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByAlbumId: LikesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Playlist`. */
  playlistsByLikeAlbumIdAndPlaylistId: AlbumPlaylistsByLikeAlbumIdAndPlaylistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Playlist`. */
  playlistsByRecentlyPlayedAlbumIdAndPlaylistId: AlbumPlaylistsByRecentlyPlayedAlbumIdAndPlaylistIdManyToManyConnection;
  presignedImgs?: Maybe<Array<Maybe<PresignedImage>>>;
  /** The `Publisher` this `Album` is part of */
  publisher?: Maybe<Scalars['UUID']>;
  /** Reads and enables pagination through a set of `RecentlyPlayed`. */
  recentlyPlayedsByAlbumId: RecentlyPlayedsConnection;
  /** Reads a single `RecordLabel` that is related to this `Album`. */
  recordLabelByPublisher?: Maybe<RecordLabel>;
  /** Reads and enables pagination through a set of `RecordLabel`. */
  recordLabelsByTrackAlbumAndPublisher: AlbumRecordLabelsByTrackAlbumAndPublisherManyToManyConnection;
  /** Defines the date when `Album` will be available */
  releaseDate: Scalars['Datetime'];
  streamCount?: Maybe<Scalars['BigFloat']>;
  /** Tags */
  tags?: Maybe<Scalars['String']>;
  /** The text that a particular `Album` will be searched by */
  textSearch?: Maybe<Scalars['String']>;
  /** The `Album` title */
  title?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `TracksAlbum`. */
  tracksAlbumsByAlbumId: TracksAlbumsConnection;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByAlbum: TracksConnection;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByAlbumsTrackAlbumIdAndTrackId: AlbumTracksByAlbumsTrackAlbumIdAndTrackIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByLikeAlbumIdAndTrackId: AlbumTracksByLikeAlbumIdAndTrackIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByTracksAlbumAlbumIdAndTrackId: AlbumTracksByTracksAlbumAlbumIdAndTrackIdManyToManyConnection;
  /** Defines the datetime with time zone `Album` was last updated */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `User`. */
  usersByLikeAlbumIdAndLikedUserId: AlbumUsersByLikeAlbumIdAndLikedUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByLikeAlbumIdAndUserId: AlbumUsersByLikeAlbumIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByRecentlyPlayedAlbumIdAndUserId: AlbumUsersByRecentlyPlayedAlbumIdAndUserIdManyToManyConnection;
};

/** `Album` table */
export type AlbumAlbumsTracksByAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumsTrackCondition>;
  filter?: InputMaybe<AlbumsTrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsTracksOrderBy>>;
};

/** `Album` table */
export type AlbumArtistsByExtraArtistAlbumAndArtistArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `Album` table */
export type AlbumArtistsByLikeAlbumIdAndActingArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `Album` table */
export type AlbumArtistsByLikeAlbumIdAndArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `Album` table */
export type AlbumArtistsByRecentlyPlayedAlbumIdAndArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `Album` table */
export type AlbumArtistsByTrackAlbumAndArtistArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `Album` table */
export type AlbumExtraArtistsByAlbumArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ExtraArtistCondition>;
  filter?: InputMaybe<ExtraArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExtraArtistsOrderBy>>;
};

/** `Album` table */
export type AlbumGenresAlbumsByAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenresAlbumCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenresAlbumsOrderBy>>;
};

/** `Album` table */
export type AlbumGenresByGenresAlbumAlbumIdAndGenreIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenreCondition>;
  filter?: InputMaybe<GenreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenresOrderBy>>;
};

/** `Album` table */
export type AlbumGenresByTrackAlbumAndGenreArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenreCondition>;
  filter?: InputMaybe<GenreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenresOrderBy>>;
};

/** `Album` table */
export type AlbumLikesByAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** `Album` table */
export type AlbumPlaylistsByLikeAlbumIdAndPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PlaylistCondition>;
  filter?: InputMaybe<PlaylistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** `Album` table */
export type AlbumPlaylistsByRecentlyPlayedAlbumIdAndPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PlaylistCondition>;
  filter?: InputMaybe<PlaylistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** `Album` table */
export type AlbumRecentlyPlayedsByAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecentlyPlayedCondition>;
  filter?: InputMaybe<RecentlyPlayedFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecentlyPlayedsOrderBy>>;
};

/** `Album` table */
export type AlbumRecordLabelsByTrackAlbumAndPublisherArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecordLabelCondition>;
  filter?: InputMaybe<RecordLabelFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecordLabelsOrderBy>>;
};

/** `Album` table */
export type AlbumTracksAlbumsByAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TracksAlbumCondition>;
  filter?: InputMaybe<TracksAlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksAlbumsOrderBy>>;
};

/** `Album` table */
export type AlbumTracksByAlbumArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `Album` table */
export type AlbumTracksByAlbumsTrackAlbumIdAndTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `Album` table */
export type AlbumTracksByLikeAlbumIdAndTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `Album` table */
export type AlbumTracksByTracksAlbumAlbumIdAndTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `Album` table */
export type AlbumUsersByLikeAlbumIdAndLikedUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** `Album` table */
export type AlbumUsersByLikeAlbumIdAndUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** `Album` table */
export type AlbumUsersByRecentlyPlayedAlbumIdAndUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `ExtraArtist`. */
export type AlbumArtistsByExtraArtistAlbumAndArtistManyToManyConnection = {
  __typename?: 'AlbumArtistsByExtraArtistAlbumAndArtistManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `ExtraArtist`, and the cursor to aid in pagination. */
  edges: Array<AlbumArtistsByExtraArtistAlbumAndArtistManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `ExtraArtist`. */
export type AlbumArtistsByExtraArtistAlbumAndArtistManyToManyEdge = {
  __typename?: 'AlbumArtistsByExtraArtistAlbumAndArtistManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `ExtraArtist`. */
  extraArtistsByArtist: ExtraArtistsConnection;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** A `Artist` edge in the connection, with data from `ExtraArtist`. */
export type AlbumArtistsByExtraArtistAlbumAndArtistManyToManyEdgeExtraArtistsByArtistArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ExtraArtistCondition>;
  filter?: InputMaybe<ExtraArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExtraArtistsOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `Like`. */
export type AlbumArtistsByLikeAlbumIdAndActingArtistIdManyToManyConnection = {
  __typename?: 'AlbumArtistsByLikeAlbumIdAndActingArtistIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<AlbumArtistsByLikeAlbumIdAndActingArtistIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type AlbumArtistsByLikeAlbumIdAndActingArtistIdManyToManyEdge = {
  __typename?: 'AlbumArtistsByLikeAlbumIdAndActingArtistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByActingArtistId: LikesConnection;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type AlbumArtistsByLikeAlbumIdAndActingArtistIdManyToManyEdgeLikesByActingArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `Like`. */
export type AlbumArtistsByLikeAlbumIdAndArtistIdManyToManyConnection = {
  __typename?: 'AlbumArtistsByLikeAlbumIdAndArtistIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<AlbumArtistsByLikeAlbumIdAndArtistIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type AlbumArtistsByLikeAlbumIdAndArtistIdManyToManyEdge = {
  __typename?: 'AlbumArtistsByLikeAlbumIdAndArtistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByArtistId: LikesConnection;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type AlbumArtistsByLikeAlbumIdAndArtistIdManyToManyEdgeLikesByArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `RecentlyPlayed`. */
export type AlbumArtistsByRecentlyPlayedAlbumIdAndArtistIdManyToManyConnection = {
  __typename?: 'AlbumArtistsByRecentlyPlayedAlbumIdAndArtistIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `RecentlyPlayed`, and the cursor to aid in pagination. */
  edges: Array<AlbumArtistsByRecentlyPlayedAlbumIdAndArtistIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `RecentlyPlayed`. */
export type AlbumArtistsByRecentlyPlayedAlbumIdAndArtistIdManyToManyEdge = {
  __typename?: 'AlbumArtistsByRecentlyPlayedAlbumIdAndArtistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
  /** Reads and enables pagination through a set of `RecentlyPlayed`. */
  recentlyPlayedsByArtistId: RecentlyPlayedsConnection;
};

/** A `Artist` edge in the connection, with data from `RecentlyPlayed`. */
export type AlbumArtistsByRecentlyPlayedAlbumIdAndArtistIdManyToManyEdgeRecentlyPlayedsByArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecentlyPlayedCondition>;
  filter?: InputMaybe<RecentlyPlayedFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecentlyPlayedsOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `Track`. */
export type AlbumArtistsByTrackAlbumAndArtistManyToManyConnection = {
  __typename?: 'AlbumArtistsByTrackAlbumAndArtistManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `Track`, and the cursor to aid in pagination. */
  edges: Array<AlbumArtistsByTrackAlbumAndArtistManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `Track`. */
export type AlbumArtistsByTrackAlbumAndArtistManyToManyEdge = {
  __typename?: 'AlbumArtistsByTrackAlbumAndArtistManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByArtist: TracksConnection;
};

/** A `Artist` edge in the connection, with data from `Track`. */
export type AlbumArtistsByTrackAlbumAndArtistManyToManyEdgeTracksByArtistArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** A condition to be used against `Album` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AlbumCondition = {
  /** Checks for equality with the object’s `allArtists` field. */
  allArtists?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `artist` field. */
  artist?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `digitalRights` field. */
  digitalRights?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `explicit` field. */
  explicit?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `extraArtistsText` field. */
  extraArtistsText?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `genre` field. */
  genre?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `img` field. */
  img?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `likeCount` field. */
  likeCount?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `publisher` field. */
  publisher?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `releaseDate` field. */
  releaseDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `textSearch` field. */
  textSearch?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `Album` object types. All fields are combined with a logical ‘and.’ */
export type AlbumFilter = {
  /** Filter by the object’s `allArtists` field. */
  allArtists?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AlbumFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `digitalRights` field. */
  digitalRights?: InputMaybe<StringFilter>;
  /** Filter by the object’s `extraArtistsText` field. */
  extraArtistsText?: InputMaybe<StringFilter>;
  /** Filter by the object’s `img` field. */
  img?: InputMaybe<StringFilter>;
  /** Filter by the object’s `likeCount` field. */
  likeCount?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AlbumFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AlbumFilter>>;
  /** Filter by the object’s `releaseDate` field. */
  releaseDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: InputMaybe<StringFilter>;
  /** Filter by the object’s `textSearch` field. */
  textSearch?: InputMaybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `Genre` values, with data from `GenresAlbum`. */
export type AlbumGenresByGenresAlbumAlbumIdAndGenreIdManyToManyConnection = {
  __typename?: 'AlbumGenresByGenresAlbumAlbumIdAndGenreIdManyToManyConnection';
  /** A list of edges which contains the `Genre`, info from the `GenresAlbum`, and the cursor to aid in pagination. */
  edges: Array<AlbumGenresByGenresAlbumAlbumIdAndGenreIdManyToManyEdge>;
  /** A list of `Genre` objects. */
  nodes: Array<Maybe<Genre>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Genre` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Genre` edge in the connection, with data from `GenresAlbum`. */
export type AlbumGenresByGenresAlbumAlbumIdAndGenreIdManyToManyEdge = {
  __typename?: 'AlbumGenresByGenresAlbumAlbumIdAndGenreIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `GenresAlbum`. */
  genresAlbumsByGenreId: GenresAlbumsConnection;
  /** The `Genre` at the end of the edge. */
  node?: Maybe<Genre>;
};

/** A `Genre` edge in the connection, with data from `GenresAlbum`. */
export type AlbumGenresByGenresAlbumAlbumIdAndGenreIdManyToManyEdgeGenresAlbumsByGenreIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenresAlbumCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenresAlbumsOrderBy>>;
};

/** A connection to a list of `Genre` values, with data from `Track`. */
export type AlbumGenresByTrackAlbumAndGenreManyToManyConnection = {
  __typename?: 'AlbumGenresByTrackAlbumAndGenreManyToManyConnection';
  /** A list of edges which contains the `Genre`, info from the `Track`, and the cursor to aid in pagination. */
  edges: Array<AlbumGenresByTrackAlbumAndGenreManyToManyEdge>;
  /** A list of `Genre` objects. */
  nodes: Array<Maybe<Genre>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Genre` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Genre` edge in the connection, with data from `Track`. */
export type AlbumGenresByTrackAlbumAndGenreManyToManyEdge = {
  __typename?: 'AlbumGenresByTrackAlbumAndGenreManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Genre` at the end of the edge. */
  node?: Maybe<Genre>;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByGenre: TracksConnection;
};

/** A `Genre` edge in the connection, with data from `Track`. */
export type AlbumGenresByTrackAlbumAndGenreManyToManyEdgeTracksByGenreArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** An input for mutations affecting `Album` */
export type AlbumInput = {
  /** All the `Artist`-s part of the `Album` */
  allArtists?: InputMaybe<Scalars['String']>;
  /** The `Artist` that has created the `Album` */
  artist?: InputMaybe<Scalars['UUID']>;
  /** The `Album` description */
  description?: InputMaybe<Scalars['String']>;
  /** The type of digital rights this `Album` has */
  digitalRights?: InputMaybe<Scalars['String']>;
  /** Does the `Album` contain explicit content */
  explicit?: InputMaybe<Scalars['Boolean']>;
  /** Extra `Artist`-s part of the `Album` */
  extraArtistsText?: InputMaybe<Scalars['String']>;
  /** The type of `Genre` the `Album` is */
  genre?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** The `Publisher` this `Album` is part of */
  publisher?: InputMaybe<Scalars['UUID']>;
  /** Defines the date when `Album` will be available */
  releaseDate: Scalars['Datetime'];
  /** Tags */
  tags?: InputMaybe<Scalars['String']>;
  /** The `Album` title */
  title?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `Album`. Fields that are set will be updated. */
export type AlbumPatch = {
  /** All the `Artist`-s part of the `Album` */
  allArtists?: InputMaybe<Scalars['String']>;
  /** The `Artist` that has created the `Album` */
  artist?: InputMaybe<Scalars['UUID']>;
  /** The `Album` description */
  description?: InputMaybe<Scalars['String']>;
  /** The type of digital rights this `Album` has */
  digitalRights?: InputMaybe<Scalars['String']>;
  /** Does the `Album` contain explicit content */
  explicit?: InputMaybe<Scalars['Boolean']>;
  /** Extra `Artist`-s part of the `Album` */
  extraArtistsText?: InputMaybe<Scalars['String']>;
  /** The type of `Genre` the `Album` is */
  genre?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** The `Publisher` this `Album` is part of */
  publisher?: InputMaybe<Scalars['UUID']>;
  /** Defines the date when `Album` will be available */
  releaseDate?: InputMaybe<Scalars['Datetime']>;
  /** Tags */
  tags?: InputMaybe<Scalars['String']>;
  /** The `Album` title */
  title?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `Playlist` values, with data from `Like`. */
export type AlbumPlaylistsByLikeAlbumIdAndPlaylistIdManyToManyConnection = {
  __typename?: 'AlbumPlaylistsByLikeAlbumIdAndPlaylistIdManyToManyConnection';
  /** A list of edges which contains the `Playlist`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<AlbumPlaylistsByLikeAlbumIdAndPlaylistIdManyToManyEdge>;
  /** A list of `Playlist` objects. */
  nodes: Array<Maybe<Playlist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Playlist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Playlist` edge in the connection, with data from `Like`. */
export type AlbumPlaylistsByLikeAlbumIdAndPlaylistIdManyToManyEdge = {
  __typename?: 'AlbumPlaylistsByLikeAlbumIdAndPlaylistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByPlaylistId: LikesConnection;
  /** The `Playlist` at the end of the edge. */
  node?: Maybe<Playlist>;
};

/** A `Playlist` edge in the connection, with data from `Like`. */
export type AlbumPlaylistsByLikeAlbumIdAndPlaylistIdManyToManyEdgeLikesByPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Playlist` values, with data from `RecentlyPlayed`. */
export type AlbumPlaylistsByRecentlyPlayedAlbumIdAndPlaylistIdManyToManyConnection = {
  __typename?: 'AlbumPlaylistsByRecentlyPlayedAlbumIdAndPlaylistIdManyToManyConnection';
  /** A list of edges which contains the `Playlist`, info from the `RecentlyPlayed`, and the cursor to aid in pagination. */
  edges: Array<AlbumPlaylistsByRecentlyPlayedAlbumIdAndPlaylistIdManyToManyEdge>;
  /** A list of `Playlist` objects. */
  nodes: Array<Maybe<Playlist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Playlist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Playlist` edge in the connection, with data from `RecentlyPlayed`. */
export type AlbumPlaylistsByRecentlyPlayedAlbumIdAndPlaylistIdManyToManyEdge = {
  __typename?: 'AlbumPlaylistsByRecentlyPlayedAlbumIdAndPlaylistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Playlist` at the end of the edge. */
  node?: Maybe<Playlist>;
  /** Reads and enables pagination through a set of `RecentlyPlayed`. */
  recentlyPlayedsByPlaylistId: RecentlyPlayedsConnection;
};

/** A `Playlist` edge in the connection, with data from `RecentlyPlayed`. */
export type AlbumPlaylistsByRecentlyPlayedAlbumIdAndPlaylistIdManyToManyEdgeRecentlyPlayedsByPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecentlyPlayedCondition>;
  filter?: InputMaybe<RecentlyPlayedFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecentlyPlayedsOrderBy>>;
};

/** A connection to a list of `RecordLabel` values, with data from `Track`. */
export type AlbumRecordLabelsByTrackAlbumAndPublisherManyToManyConnection = {
  __typename?: 'AlbumRecordLabelsByTrackAlbumAndPublisherManyToManyConnection';
  /** A list of edges which contains the `RecordLabel`, info from the `Track`, and the cursor to aid in pagination. */
  edges: Array<AlbumRecordLabelsByTrackAlbumAndPublisherManyToManyEdge>;
  /** A list of `RecordLabel` objects. */
  nodes: Array<Maybe<RecordLabel>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `RecordLabel` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `RecordLabel` edge in the connection, with data from `Track`. */
export type AlbumRecordLabelsByTrackAlbumAndPublisherManyToManyEdge = {
  __typename?: 'AlbumRecordLabelsByTrackAlbumAndPublisherManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RecordLabel` at the end of the edge. */
  node?: Maybe<RecordLabel>;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByPublisher: TracksConnection;
};

/** A `RecordLabel` edge in the connection, with data from `Track`. */
export type AlbumRecordLabelsByTrackAlbumAndPublisherManyToManyEdgeTracksByPublisherArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** A connection to a list of `Track` values, with data from `AlbumsTrack`. */
export type AlbumTracksByAlbumsTrackAlbumIdAndTrackIdManyToManyConnection = {
  __typename?: 'AlbumTracksByAlbumsTrackAlbumIdAndTrackIdManyToManyConnection';
  /** A list of edges which contains the `Track`, info from the `AlbumsTrack`, and the cursor to aid in pagination. */
  edges: Array<AlbumTracksByAlbumsTrackAlbumIdAndTrackIdManyToManyEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Track` edge in the connection, with data from `AlbumsTrack`. */
export type AlbumTracksByAlbumsTrackAlbumIdAndTrackIdManyToManyEdge = {
  __typename?: 'AlbumTracksByAlbumsTrackAlbumIdAndTrackIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
  sort?: Maybe<Scalars['Int']>;
  /** Defines the `Track` number inside the `Album` */
  trackNumber: Scalars['Int'];
};

/** A connection to a list of `Track` values, with data from `Like`. */
export type AlbumTracksByLikeAlbumIdAndTrackIdManyToManyConnection = {
  __typename?: 'AlbumTracksByLikeAlbumIdAndTrackIdManyToManyConnection';
  /** A list of edges which contains the `Track`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<AlbumTracksByLikeAlbumIdAndTrackIdManyToManyEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Track` edge in the connection, with data from `Like`. */
export type AlbumTracksByLikeAlbumIdAndTrackIdManyToManyEdge = {
  __typename?: 'AlbumTracksByLikeAlbumIdAndTrackIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByTrackId: LikesConnection;
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
};

/** A `Track` edge in the connection, with data from `Like`. */
export type AlbumTracksByLikeAlbumIdAndTrackIdManyToManyEdgeLikesByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Track` values, with data from `TracksAlbum`. */
export type AlbumTracksByTracksAlbumAlbumIdAndTrackIdManyToManyConnection = {
  __typename?: 'AlbumTracksByTracksAlbumAlbumIdAndTrackIdManyToManyConnection';
  /** A list of edges which contains the `Track`, info from the `TracksAlbum`, and the cursor to aid in pagination. */
  edges: Array<AlbumTracksByTracksAlbumAlbumIdAndTrackIdManyToManyEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Track` edge in the connection, with data from `TracksAlbum`. */
export type AlbumTracksByTracksAlbumAlbumIdAndTrackIdManyToManyEdge = {
  __typename?: 'AlbumTracksByTracksAlbumAlbumIdAndTrackIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
  /** Reads and enables pagination through a set of `TracksAlbum`. */
  tracksAlbumsByTrackId: TracksAlbumsConnection;
};

/** A `Track` edge in the connection, with data from `TracksAlbum`. */
export type AlbumTracksByTracksAlbumAlbumIdAndTrackIdManyToManyEdgeTracksAlbumsByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TracksAlbumCondition>;
  filter?: InputMaybe<TracksAlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksAlbumsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Like`. */
export type AlbumUsersByLikeAlbumIdAndLikedUserIdManyToManyConnection = {
  __typename?: 'AlbumUsersByLikeAlbumIdAndLikedUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<AlbumUsersByLikeAlbumIdAndLikedUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Like`. */
export type AlbumUsersByLikeAlbumIdAndLikedUserIdManyToManyEdge = {
  __typename?: 'AlbumUsersByLikeAlbumIdAndLikedUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByLikedUserId: LikesConnection;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** A `User` edge in the connection, with data from `Like`. */
export type AlbumUsersByLikeAlbumIdAndLikedUserIdManyToManyEdgeLikesByLikedUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Like`. */
export type AlbumUsersByLikeAlbumIdAndUserIdManyToManyConnection = {
  __typename?: 'AlbumUsersByLikeAlbumIdAndUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<AlbumUsersByLikeAlbumIdAndUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Like`. */
export type AlbumUsersByLikeAlbumIdAndUserIdManyToManyEdge = {
  __typename?: 'AlbumUsersByLikeAlbumIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByUserId: LikesConnection;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** A `User` edge in the connection, with data from `Like`. */
export type AlbumUsersByLikeAlbumIdAndUserIdManyToManyEdgeLikesByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `User` values, with data from `RecentlyPlayed`. */
export type AlbumUsersByRecentlyPlayedAlbumIdAndUserIdManyToManyConnection = {
  __typename?: 'AlbumUsersByRecentlyPlayedAlbumIdAndUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `RecentlyPlayed`, and the cursor to aid in pagination. */
  edges: Array<AlbumUsersByRecentlyPlayedAlbumIdAndUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `RecentlyPlayed`. */
export type AlbumUsersByRecentlyPlayedAlbumIdAndUserIdManyToManyEdge = {
  __typename?: 'AlbumUsersByRecentlyPlayedAlbumIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
  /** Reads and enables pagination through a set of `RecentlyPlayed`. */
  recentlyPlayedsByUserId: RecentlyPlayedsConnection;
};

/** A `User` edge in the connection, with data from `RecentlyPlayed`. */
export type AlbumUsersByRecentlyPlayedAlbumIdAndUserIdManyToManyEdgeRecentlyPlayedsByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecentlyPlayedCondition>;
  filter?: InputMaybe<RecentlyPlayedFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecentlyPlayedsOrderBy>>;
};

/** A connection to a list of `Album` values. */
export type AlbumsConnection = {
  __typename?: 'AlbumsConnection';
  /** A list of edges which contains the `Album` and cursor to aid in pagination. */
  edges: Array<AlbumsEdge>;
  /** A list of `Album` objects. */
  nodes: Array<Maybe<Album>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Album` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Album` edge in the connection. */
export type AlbumsEdge = {
  __typename?: 'AlbumsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Album` at the end of the edge. */
  node?: Maybe<Album>;
};

/** Methods to use when ordering `Album`. */
export enum AlbumsOrderBy {
  AllArtistsAsc = 'ALL_ARTISTS_ASC',
  AllArtistsDesc = 'ALL_ARTISTS_DESC',
  ArtistAsc = 'ARTIST_ASC',
  ArtistDesc = 'ARTIST_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  DigitalRightsAsc = 'DIGITAL_RIGHTS_ASC',
  DigitalRightsDesc = 'DIGITAL_RIGHTS_DESC',
  ExplicitAsc = 'EXPLICIT_ASC',
  ExplicitDesc = 'EXPLICIT_DESC',
  ExtraArtistsTextAsc = 'EXTRA_ARTISTS_TEXT_ASC',
  ExtraArtistsTextDesc = 'EXTRA_ARTISTS_TEXT_DESC',
  GenreAsc = 'GENRE_ASC',
  GenreDesc = 'GENRE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImgAsc = 'IMG_ASC',
  ImgDesc = 'IMG_DESC',
  LikeCountAsc = 'LIKE_COUNT_ASC',
  LikeCountDesc = 'LIKE_COUNT_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PublisherAsc = 'PUBLISHER_ASC',
  PublisherDesc = 'PUBLISHER_DESC',
  ReleaseDateAsc = 'RELEASE_DATE_ASC',
  ReleaseDateDesc = 'RELEASE_DATE_DESC',
  TagsAsc = 'TAGS_ASC',
  TagsDesc = 'TAGS_DESC',
  TextSearchAsc = 'TEXT_SEARCH_ASC',
  TextSearchDesc = 'TEXT_SEARCH_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** `AlbumsTrack` table - holds the information regarding albums tracks */
export type AlbumsTrack = Node & {
  __typename?: 'AlbumsTrack';
  /** Reads a single `Album` that is related to this `AlbumsTrack`. */
  albumByAlbumId?: Maybe<Album>;
  /** Defines the `Album` the `Track`-s are bound to */
  albumId: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  sort?: Maybe<Scalars['Int']>;
  /** Reads a single `Track` that is related to this `AlbumsTrack`. */
  trackByTrackId?: Maybe<Track>;
  /** Defines the `Track` unique identifier bound to the `Album` */
  trackId: Scalars['UUID'];
  /** Defines the `Track` number inside the `Album` */
  trackNumber: Scalars['Int'];
};

/**
 * A condition to be used against `AlbumsTrack` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AlbumsTrackCondition = {
  /** Checks for equality with the object’s `albumId` field. */
  albumId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `sort` field. */
  sort?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `trackId` field. */
  trackId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `trackNumber` field. */
  trackNumber?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `AlbumsTrack` object types. All fields are combined with a logical ‘and.’ */
export type AlbumsTrackFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AlbumsTrackFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<AlbumsTrackFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AlbumsTrackFilter>>;
  /** Filter by the object’s `sort` field. */
  sort?: InputMaybe<IntFilter>;
  /** Filter by the object’s `trackNumber` field. */
  trackNumber?: InputMaybe<IntFilter>;
};

/** A connection to a list of `AlbumsTrack` values. */
export type AlbumsTracksConnection = {
  __typename?: 'AlbumsTracksConnection';
  /** A list of edges which contains the `AlbumsTrack` and cursor to aid in pagination. */
  edges: Array<AlbumsTracksEdge>;
  /** A list of `AlbumsTrack` objects. */
  nodes: Array<Maybe<AlbumsTrack>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AlbumsTrack` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AlbumsTrack` edge in the connection. */
export type AlbumsTracksEdge = {
  __typename?: 'AlbumsTracksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AlbumsTrack` at the end of the edge. */
  node?: Maybe<AlbumsTrack>;
};

/** Methods to use when ordering `AlbumsTrack`. */
export enum AlbumsTracksOrderBy {
  AlbumIdAsc = 'ALBUM_ID_ASC',
  AlbumIdDesc = 'ALBUM_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SortAsc = 'SORT_ASC',
  SortDesc = 'SORT_DESC',
  TrackIdAsc = 'TRACK_ID_ASC',
  TrackIdDesc = 'TRACK_ID_DESC',
  TrackNumberAsc = 'TRACK_NUMBER_ASC',
  TrackNumberDesc = 'TRACK_NUMBER_DESC',
}

/** `Artist` table */
export type Artist = Node & {
  __typename?: 'Artist';
  /** Reads and enables pagination through a set of `Album`. */
  albumsByArtist: AlbumsConnection;
  /** Reads and enables pagination through a set of `Album`. */
  albumsByExtraArtistArtistAndAlbum: ArtistAlbumsByExtraArtistArtistAndAlbumManyToManyConnection;
  /** Reads and enables pagination through a set of `Album`. */
  albumsByLikeActingArtistIdAndAlbumId: ArtistAlbumsByLikeActingArtistIdAndAlbumIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Album`. */
  albumsByLikeArtistIdAndAlbumId: ArtistAlbumsByLikeArtistIdAndAlbumIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Album`. */
  albumsByRecentlyPlayedArtistIdAndAlbumId: ArtistAlbumsByRecentlyPlayedArtistIdAndAlbumIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Album`. */
  albumsByTrackArtistAndAlbum: ArtistAlbumsByTrackArtistAndAlbumManyToManyConnection;
  /** Reads and enables pagination through a set of `ArtistMember`. */
  artistMembersByArtistId: ArtistMembersConnection;
  /** Reads and enables pagination through a set of `ArtistSubscriptionType`. */
  artistSubscriptionTypesByArtistSubscriptionArtistIdAndSubscriptionType: ArtistArtistSubscriptionTypesByArtistSubscriptionArtistIdAndSubscriptionTypeManyToManyConnection;
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByArtistId: ArtistSubscriptionsConnection;
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByArtistSubscriptionArtistIdAndDowngradeTo: ArtistArtistSubscriptionsByArtistSubscriptionArtistIdAndDowngradeToManyToManyConnection;
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByArtistSubscriptionArtistIdAndUpgradeTo: ArtistArtistSubscriptionsByArtistSubscriptionArtistIdAndUpgradeToManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByLikeActingArtistIdAndArtistId: ArtistArtistsByLikeActingArtistIdAndArtistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByLikeArtistIdAndActingArtistId: ArtistArtistsByLikeArtistIdAndActingArtistIdManyToManyConnection;
  /** The `Artist` internal url */
  blarecastUrl?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Country`. */
  countriesByPriceCreatorIdAndCountry: ArtistCountriesByPriceCreatorIdAndCountryManyToManyConnection;
  /** The `Artist` profile cover image */
  coverImg?: Maybe<Scalars['String']>;
  /** E@omit create,update,delete\nDefines the datetime with time zone when `Artist` was first created */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** A longer description of the `Artist` */
  description?: Maybe<Scalars['String']>;
  /** The `Artist` public display name */
  displayName?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Event`. */
  eventsByArtistId: EventsConnection;
  /** Reads and enables pagination through a set of `ExtraArtist`. */
  extraArtistsByArtist: ExtraArtistsConnection;
  /** The `Artist` facebook profile */
  facebook?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `FeaturedArtist`. */
  featuredArtistsByArtist: FeaturedArtistsConnection;
  /** The amount of followers this `Artist` has */
  followerCount?: Maybe<Scalars['Int']>;
  /** The amount of followings this `Artist` has */
  followingCount?: Maybe<Scalars['Int']>;
  /** The preferred selected genre of this `Artist` */
  genre?: Maybe<Scalars['UUID']>;
  /** Reads a single `Genre` that is related to this `Artist`. */
  genreByGenre?: Maybe<Genre>;
  /** Reads and enables pagination through a set of `Genre`. */
  genresByAlbumArtistAndGenre: ArtistGenresByAlbumArtistAndGenreManyToManyConnection;
  /** Reads and enables pagination through a set of `Genre`. */
  genresByTrackArtistAndGenre: ArtistGenresByTrackArtistAndGenreManyToManyConnection;
  /** Unique identifier for row */
  id: Scalars['UUID'];
  /** The `Artist` image */
  img?: Maybe<Scalars['String']>;
  /** The `Artist` instagram profile */
  instagram?: Maybe<Scalars['String']>;
  likedAsArtist?: Maybe<Scalars['Boolean']>;
  likedAsListener?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByActingArtistId: LikesConnection;
  /** Reads and enables pagination through a set of `Like`. */
  likesByArtistId: LikesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Track`. */
  pinnedTracks: TracksConnection;
  /** Reads and enables pagination through a set of `Playlist`. */
  playlistsByLikeActingArtistIdAndPlaylistId: ArtistPlaylistsByLikeActingArtistIdAndPlaylistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Playlist`. */
  playlistsByLikeArtistIdAndPlaylistId: ArtistPlaylistsByLikeArtistIdAndPlaylistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Playlist`. */
  playlistsByRecentlyPlayedArtistIdAndPlaylistId: ArtistPlaylistsByRecentlyPlayedArtistIdAndPlaylistIdManyToManyConnection;
  presignedCoverImgs?: Maybe<Array<Maybe<PresignedImage>>>;
  presignedProfileImgs?: Maybe<Array<Maybe<PresignedImage>>>;
  /** Reads and enables pagination through a set of `Price`. */
  pricesByCreatorId: PricesConnection;
  /** Reads and enables pagination through a set of `RecentlyPlayed`. */
  recentlyPlayedsByArtistId: RecentlyPlayedsConnection;
  /** Reads and enables pagination through a set of `RecordLabel`. */
  recordLabelsByAlbumArtistAndPublisher: ArtistRecordLabelsByAlbumArtistAndPublisherManyToManyConnection;
  /** Reads and enables pagination through a set of `RecordLabel`. */
  recordLabelsByTrackArtistAndPublisher: ArtistRecordLabelsByTrackArtistAndPublisherManyToManyConnection;
  /** A shorter description of the `Artist` */
  shortDescription?: Maybe<Scalars['String']>;
  /** If the `Artist` is a solo artist or part of a band, defaults to true */
  solo?: Maybe<Scalars['Boolean']>;
  /** Calculated column based on displayName, description and shortDescription */
  textSearch?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `TracksArtist`. */
  tracksArtistsByArtistId: TracksArtistsConnection;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByArtist: TracksConnection;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByFeaturedArtistArtistAndTrack: ArtistTracksByFeaturedArtistArtistAndTrackManyToManyConnection;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByLikeActingArtistIdAndTrackId: ArtistTracksByLikeActingArtistIdAndTrackIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByLikeArtistIdAndTrackId: ArtistTracksByLikeArtistIdAndTrackIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByPriceCreatorIdAndTrackId: ArtistTracksByPriceCreatorIdAndTrackIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByTracksArtistArtistIdAndTrackId: ArtistTracksByTracksArtistArtistIdAndTrackIdManyToManyConnection;
  /** The `Artist` twitter profile */
  twitter?: Maybe<Scalars['String']>;
  /** E@omit create,update,delete\nDefines the datetime with time zone when `Artist` was last updated */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `Artist`. */
  userByUserId?: Maybe<User>;
  /** Id of user who owns the `Artist` */
  userId?: Maybe<Scalars['UUID']>;
  /** Reads and enables pagination through a set of `User`. */
  usersByArtistMemberArtistIdAndUserId: ArtistUsersByArtistMemberArtistIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByArtistSubscriptionArtistIdAndUserId: ArtistUsersByArtistSubscriptionArtistIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByLikeActingArtistIdAndLikedUserId: ArtistUsersByLikeActingArtistIdAndLikedUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByLikeActingArtistIdAndUserId: ArtistUsersByLikeActingArtistIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByLikeArtistIdAndLikedUserId: ArtistUsersByLikeArtistIdAndLikedUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByLikeArtistIdAndUserId: ArtistUsersByLikeArtistIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByRecentlyPlayedArtistIdAndUserId: ArtistUsersByRecentlyPlayedArtistIdAndUserIdManyToManyConnection;
  /** Defines if the `Artist` is verified or not */
  verified?: Maybe<Scalars['Boolean']>;
};

/** `Artist` table */
export type ArtistAlbumsByArtistArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** `Artist` table */
export type ArtistAlbumsByExtraArtistArtistAndAlbumArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** `Artist` table */
export type ArtistAlbumsByLikeActingArtistIdAndAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** `Artist` table */
export type ArtistAlbumsByLikeArtistIdAndAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** `Artist` table */
export type ArtistAlbumsByRecentlyPlayedArtistIdAndAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** `Artist` table */
export type ArtistAlbumsByTrackArtistAndAlbumArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** `Artist` table */
export type ArtistArtistMembersByArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistMemberCondition>;
  filter?: InputMaybe<ArtistMemberFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistMembersOrderBy>>;
};

/** `Artist` table */
export type ArtistArtistSubscriptionTypesByArtistSubscriptionArtistIdAndSubscriptionTypeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionTypeCondition>;
  filter?: InputMaybe<ArtistSubscriptionTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionTypesOrderBy>>;
};

/** `Artist` table */
export type ArtistArtistSubscriptionsByArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** `Artist` table */
export type ArtistArtistSubscriptionsByArtistSubscriptionArtistIdAndDowngradeToArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** `Artist` table */
export type ArtistArtistSubscriptionsByArtistSubscriptionArtistIdAndUpgradeToArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** `Artist` table */
export type ArtistArtistsByLikeActingArtistIdAndArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `Artist` table */
export type ArtistArtistsByLikeArtistIdAndActingArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `Artist` table */
export type ArtistCountriesByPriceCreatorIdAndCountryArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CountryCondition>;
  filter?: InputMaybe<CountryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
};

/** `Artist` table */
export type ArtistEventsByArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EventCondition>;
  filter?: InputMaybe<EventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
};

/** `Artist` table */
export type ArtistExtraArtistsByArtistArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ExtraArtistCondition>;
  filter?: InputMaybe<ExtraArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExtraArtistsOrderBy>>;
};

/** `Artist` table */
export type ArtistFeaturedArtistsByArtistArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FeaturedArtistCondition>;
  filter?: InputMaybe<FeaturedArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeaturedArtistsOrderBy>>;
};

/** `Artist` table */
export type ArtistGenresByAlbumArtistAndGenreArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenreCondition>;
  filter?: InputMaybe<GenreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenresOrderBy>>;
};

/** `Artist` table */
export type ArtistGenresByTrackArtistAndGenreArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenreCondition>;
  filter?: InputMaybe<GenreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenresOrderBy>>;
};

/** `Artist` table */
export type ArtistLikesByActingArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** `Artist` table */
export type ArtistLikesByArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** `Artist` table */
export type ArtistPinnedTracksArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** `Artist` table */
export type ArtistPlaylistsByLikeActingArtistIdAndPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PlaylistCondition>;
  filter?: InputMaybe<PlaylistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** `Artist` table */
export type ArtistPlaylistsByLikeArtistIdAndPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PlaylistCondition>;
  filter?: InputMaybe<PlaylistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** `Artist` table */
export type ArtistPlaylistsByRecentlyPlayedArtistIdAndPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PlaylistCondition>;
  filter?: InputMaybe<PlaylistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** `Artist` table */
export type ArtistPricesByCreatorIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PriceCondition>;
  filter?: InputMaybe<PriceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricesOrderBy>>;
};

/** `Artist` table */
export type ArtistRecentlyPlayedsByArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecentlyPlayedCondition>;
  filter?: InputMaybe<RecentlyPlayedFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecentlyPlayedsOrderBy>>;
};

/** `Artist` table */
export type ArtistRecordLabelsByAlbumArtistAndPublisherArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecordLabelCondition>;
  filter?: InputMaybe<RecordLabelFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecordLabelsOrderBy>>;
};

/** `Artist` table */
export type ArtistRecordLabelsByTrackArtistAndPublisherArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecordLabelCondition>;
  filter?: InputMaybe<RecordLabelFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecordLabelsOrderBy>>;
};

/** `Artist` table */
export type ArtistTracksArtistsByArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TracksArtistCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksArtistsOrderBy>>;
};

/** `Artist` table */
export type ArtistTracksByArtistArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `Artist` table */
export type ArtistTracksByFeaturedArtistArtistAndTrackArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `Artist` table */
export type ArtistTracksByLikeActingArtistIdAndTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `Artist` table */
export type ArtistTracksByLikeArtistIdAndTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `Artist` table */
export type ArtistTracksByPriceCreatorIdAndTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `Artist` table */
export type ArtistTracksByTracksArtistArtistIdAndTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `Artist` table */
export type ArtistUsersByArtistMemberArtistIdAndUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** `Artist` table */
export type ArtistUsersByArtistSubscriptionArtistIdAndUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** `Artist` table */
export type ArtistUsersByLikeActingArtistIdAndLikedUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** `Artist` table */
export type ArtistUsersByLikeActingArtistIdAndUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** `Artist` table */
export type ArtistUsersByLikeArtistIdAndLikedUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** `Artist` table */
export type ArtistUsersByLikeArtistIdAndUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** `Artist` table */
export type ArtistUsersByRecentlyPlayedArtistIdAndUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `Album` values, with data from `ExtraArtist`. */
export type ArtistAlbumsByExtraArtistArtistAndAlbumManyToManyConnection = {
  __typename?: 'ArtistAlbumsByExtraArtistArtistAndAlbumManyToManyConnection';
  /** A list of edges which contains the `Album`, info from the `ExtraArtist`, and the cursor to aid in pagination. */
  edges: Array<ArtistAlbumsByExtraArtistArtistAndAlbumManyToManyEdge>;
  /** A list of `Album` objects. */
  nodes: Array<Maybe<Album>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Album` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Album` edge in the connection, with data from `ExtraArtist`. */
export type ArtistAlbumsByExtraArtistArtistAndAlbumManyToManyEdge = {
  __typename?: 'ArtistAlbumsByExtraArtistArtistAndAlbumManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `ExtraArtist`. */
  extraArtistsByAlbum: ExtraArtistsConnection;
  /** The `Album` at the end of the edge. */
  node?: Maybe<Album>;
};

/** A `Album` edge in the connection, with data from `ExtraArtist`. */
export type ArtistAlbumsByExtraArtistArtistAndAlbumManyToManyEdgeExtraArtistsByAlbumArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ExtraArtistCondition>;
  filter?: InputMaybe<ExtraArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExtraArtistsOrderBy>>;
};

/** A connection to a list of `Album` values, with data from `Like`. */
export type ArtistAlbumsByLikeActingArtistIdAndAlbumIdManyToManyConnection = {
  __typename?: 'ArtistAlbumsByLikeActingArtistIdAndAlbumIdManyToManyConnection';
  /** A list of edges which contains the `Album`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<ArtistAlbumsByLikeActingArtistIdAndAlbumIdManyToManyEdge>;
  /** A list of `Album` objects. */
  nodes: Array<Maybe<Album>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Album` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Album` edge in the connection, with data from `Like`. */
export type ArtistAlbumsByLikeActingArtistIdAndAlbumIdManyToManyEdge = {
  __typename?: 'ArtistAlbumsByLikeActingArtistIdAndAlbumIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByAlbumId: LikesConnection;
  /** The `Album` at the end of the edge. */
  node?: Maybe<Album>;
};

/** A `Album` edge in the connection, with data from `Like`. */
export type ArtistAlbumsByLikeActingArtistIdAndAlbumIdManyToManyEdgeLikesByAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Album` values, with data from `Like`. */
export type ArtistAlbumsByLikeArtistIdAndAlbumIdManyToManyConnection = {
  __typename?: 'ArtistAlbumsByLikeArtistIdAndAlbumIdManyToManyConnection';
  /** A list of edges which contains the `Album`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<ArtistAlbumsByLikeArtistIdAndAlbumIdManyToManyEdge>;
  /** A list of `Album` objects. */
  nodes: Array<Maybe<Album>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Album` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Album` edge in the connection, with data from `Like`. */
export type ArtistAlbumsByLikeArtistIdAndAlbumIdManyToManyEdge = {
  __typename?: 'ArtistAlbumsByLikeArtistIdAndAlbumIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByAlbumId: LikesConnection;
  /** The `Album` at the end of the edge. */
  node?: Maybe<Album>;
};

/** A `Album` edge in the connection, with data from `Like`. */
export type ArtistAlbumsByLikeArtistIdAndAlbumIdManyToManyEdgeLikesByAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Album` values, with data from `RecentlyPlayed`. */
export type ArtistAlbumsByRecentlyPlayedArtistIdAndAlbumIdManyToManyConnection = {
  __typename?: 'ArtistAlbumsByRecentlyPlayedArtistIdAndAlbumIdManyToManyConnection';
  /** A list of edges which contains the `Album`, info from the `RecentlyPlayed`, and the cursor to aid in pagination. */
  edges: Array<ArtistAlbumsByRecentlyPlayedArtistIdAndAlbumIdManyToManyEdge>;
  /** A list of `Album` objects. */
  nodes: Array<Maybe<Album>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Album` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Album` edge in the connection, with data from `RecentlyPlayed`. */
export type ArtistAlbumsByRecentlyPlayedArtistIdAndAlbumIdManyToManyEdge = {
  __typename?: 'ArtistAlbumsByRecentlyPlayedArtistIdAndAlbumIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Album` at the end of the edge. */
  node?: Maybe<Album>;
  /** Reads and enables pagination through a set of `RecentlyPlayed`. */
  recentlyPlayedsByAlbumId: RecentlyPlayedsConnection;
};

/** A `Album` edge in the connection, with data from `RecentlyPlayed`. */
export type ArtistAlbumsByRecentlyPlayedArtistIdAndAlbumIdManyToManyEdgeRecentlyPlayedsByAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecentlyPlayedCondition>;
  filter?: InputMaybe<RecentlyPlayedFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecentlyPlayedsOrderBy>>;
};

/** A connection to a list of `Album` values, with data from `Track`. */
export type ArtistAlbumsByTrackArtistAndAlbumManyToManyConnection = {
  __typename?: 'ArtistAlbumsByTrackArtistAndAlbumManyToManyConnection';
  /** A list of edges which contains the `Album`, info from the `Track`, and the cursor to aid in pagination. */
  edges: Array<ArtistAlbumsByTrackArtistAndAlbumManyToManyEdge>;
  /** A list of `Album` objects. */
  nodes: Array<Maybe<Album>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Album` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Album` edge in the connection, with data from `Track`. */
export type ArtistAlbumsByTrackArtistAndAlbumManyToManyEdge = {
  __typename?: 'ArtistAlbumsByTrackArtistAndAlbumManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Album` at the end of the edge. */
  node?: Maybe<Album>;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByAlbum: TracksConnection;
};

/** A `Album` edge in the connection, with data from `Track`. */
export type ArtistAlbumsByTrackArtistAndAlbumManyToManyEdgeTracksByAlbumArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** A connection to a list of `ArtistSubscriptionType` values, with data from `ArtistSubscription`. */
export type ArtistArtistSubscriptionTypesByArtistSubscriptionArtistIdAndSubscriptionTypeManyToManyConnection = {
  __typename?: 'ArtistArtistSubscriptionTypesByArtistSubscriptionArtistIdAndSubscriptionTypeManyToManyConnection';
  /** A list of edges which contains the `ArtistSubscriptionType`, info from the `ArtistSubscription`, and the cursor to aid in pagination. */
  edges: Array<ArtistArtistSubscriptionTypesByArtistSubscriptionArtistIdAndSubscriptionTypeManyToManyEdge>;
  /** A list of `ArtistSubscriptionType` objects. */
  nodes: Array<Maybe<ArtistSubscriptionType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ArtistSubscriptionType` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ArtistSubscriptionType` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistArtistSubscriptionTypesByArtistSubscriptionArtistIdAndSubscriptionTypeManyToManyEdge = {
  __typename?: 'ArtistArtistSubscriptionTypesByArtistSubscriptionArtistIdAndSubscriptionTypeManyToManyEdge';
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsBySubscriptionType: ArtistSubscriptionsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ArtistSubscriptionType` at the end of the edge. */
  node?: Maybe<ArtistSubscriptionType>;
};

/** A `ArtistSubscriptionType` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistArtistSubscriptionTypesByArtistSubscriptionArtistIdAndSubscriptionTypeManyToManyEdgeArtistSubscriptionsBySubscriptionTypeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** A connection to a list of `ArtistSubscription` values, with data from `ArtistSubscription`. */
export type ArtistArtistSubscriptionsByArtistSubscriptionArtistIdAndDowngradeToManyToManyConnection = {
  __typename?: 'ArtistArtistSubscriptionsByArtistSubscriptionArtistIdAndDowngradeToManyToManyConnection';
  /** A list of edges which contains the `ArtistSubscription`, info from the `ArtistSubscription`, and the cursor to aid in pagination. */
  edges: Array<ArtistArtistSubscriptionsByArtistSubscriptionArtistIdAndDowngradeToManyToManyEdge>;
  /** A list of `ArtistSubscription` objects. */
  nodes: Array<Maybe<ArtistSubscription>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ArtistSubscription` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ArtistSubscription` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistArtistSubscriptionsByArtistSubscriptionArtistIdAndDowngradeToManyToManyEdge = {
  __typename?: 'ArtistArtistSubscriptionsByArtistSubscriptionArtistIdAndDowngradeToManyToManyEdge';
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByDowngradeTo: ArtistSubscriptionsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ArtistSubscription` at the end of the edge. */
  node?: Maybe<ArtistSubscription>;
};

/** A `ArtistSubscription` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistArtistSubscriptionsByArtistSubscriptionArtistIdAndDowngradeToManyToManyEdgeArtistSubscriptionsByDowngradeToArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** A connection to a list of `ArtistSubscription` values, with data from `ArtistSubscription`. */
export type ArtistArtistSubscriptionsByArtistSubscriptionArtistIdAndUpgradeToManyToManyConnection = {
  __typename?: 'ArtistArtistSubscriptionsByArtistSubscriptionArtistIdAndUpgradeToManyToManyConnection';
  /** A list of edges which contains the `ArtistSubscription`, info from the `ArtistSubscription`, and the cursor to aid in pagination. */
  edges: Array<ArtistArtistSubscriptionsByArtistSubscriptionArtistIdAndUpgradeToManyToManyEdge>;
  /** A list of `ArtistSubscription` objects. */
  nodes: Array<Maybe<ArtistSubscription>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ArtistSubscription` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ArtistSubscription` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistArtistSubscriptionsByArtistSubscriptionArtistIdAndUpgradeToManyToManyEdge = {
  __typename?: 'ArtistArtistSubscriptionsByArtistSubscriptionArtistIdAndUpgradeToManyToManyEdge';
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByUpgradeTo: ArtistSubscriptionsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ArtistSubscription` at the end of the edge. */
  node?: Maybe<ArtistSubscription>;
};

/** A `ArtistSubscription` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistArtistSubscriptionsByArtistSubscriptionArtistIdAndUpgradeToManyToManyEdgeArtistSubscriptionsByUpgradeToArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `Like`. */
export type ArtistArtistsByLikeActingArtistIdAndArtistIdManyToManyConnection = {
  __typename?: 'ArtistArtistsByLikeActingArtistIdAndArtistIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<ArtistArtistsByLikeActingArtistIdAndArtistIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type ArtistArtistsByLikeActingArtistIdAndArtistIdManyToManyEdge = {
  __typename?: 'ArtistArtistsByLikeActingArtistIdAndArtistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByArtistId: LikesConnection;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type ArtistArtistsByLikeActingArtistIdAndArtistIdManyToManyEdgeLikesByArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `Like`. */
export type ArtistArtistsByLikeArtistIdAndActingArtistIdManyToManyConnection = {
  __typename?: 'ArtistArtistsByLikeArtistIdAndActingArtistIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<ArtistArtistsByLikeArtistIdAndActingArtistIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type ArtistArtistsByLikeArtistIdAndActingArtistIdManyToManyEdge = {
  __typename?: 'ArtistArtistsByLikeArtistIdAndActingArtistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByActingArtistId: LikesConnection;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type ArtistArtistsByLikeArtistIdAndActingArtistIdManyToManyEdgeLikesByActingArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A condition to be used against `Artist` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ArtistCondition = {
  /** Checks for equality with the object’s `blarecastUrl` field. */
  blarecastUrl?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `coverImg` field. */
  coverImg?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `facebook` field. */
  facebook?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `followerCount` field. */
  followerCount?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `followingCount` field. */
  followingCount?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `genre` field. */
  genre?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `img` field. */
  img?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `instagram` field. */
  instagram?: InputMaybe<Scalars['String']>;
  /** Filters the list of artists to only those which are verified */
  isVerifiedUser?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `shortDescription` field. */
  shortDescription?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `solo` field. */
  solo?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `textSearch` field. */
  textSearch?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `twitter` field. */
  twitter?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `verified` field. */
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** A connection to a list of `Country` values, with data from `Price`. */
export type ArtistCountriesByPriceCreatorIdAndCountryManyToManyConnection = {
  __typename?: 'ArtistCountriesByPriceCreatorIdAndCountryManyToManyConnection';
  /** A list of edges which contains the `Country`, info from the `Price`, and the cursor to aid in pagination. */
  edges: Array<ArtistCountriesByPriceCreatorIdAndCountryManyToManyEdge>;
  /** A list of `Country` objects. */
  nodes: Array<Maybe<Country>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Country` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Country` edge in the connection, with data from `Price`. */
export type ArtistCountriesByPriceCreatorIdAndCountryManyToManyEdge = {
  __typename?: 'ArtistCountriesByPriceCreatorIdAndCountryManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Country` at the end of the edge. */
  node?: Maybe<Country>;
  /** Reads and enables pagination through a set of `Price`. */
  pricesByCountry: PricesConnection;
};

/** A `Country` edge in the connection, with data from `Price`. */
export type ArtistCountriesByPriceCreatorIdAndCountryManyToManyEdgePricesByCountryArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PriceCondition>;
  filter?: InputMaybe<PriceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricesOrderBy>>;
};

/** A filter to be used against `Artist` object types. All fields are combined with a logical ‘and.’ */
export type ArtistFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ArtistFilter>>;
  /** Filter by the object’s `blarecastUrl` field. */
  blarecastUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `coverImg` field. */
  coverImg?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `facebook` field. */
  facebook?: InputMaybe<StringFilter>;
  /** Filter by the object’s `followerCount` field. */
  followerCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `followingCount` field. */
  followingCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `img` field. */
  img?: InputMaybe<StringFilter>;
  /** Filter by the object’s `instagram` field. */
  instagram?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ArtistFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ArtistFilter>>;
  /** Filter by the object’s `shortDescription` field. */
  shortDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `textSearch` field. */
  textSearch?: InputMaybe<StringFilter>;
  /** Filter by the object’s `twitter` field. */
  twitter?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `Genre` values, with data from `Album`. */
export type ArtistGenresByAlbumArtistAndGenreManyToManyConnection = {
  __typename?: 'ArtistGenresByAlbumArtistAndGenreManyToManyConnection';
  /** A list of edges which contains the `Genre`, info from the `Album`, and the cursor to aid in pagination. */
  edges: Array<ArtistGenresByAlbumArtistAndGenreManyToManyEdge>;
  /** A list of `Genre` objects. */
  nodes: Array<Maybe<Genre>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Genre` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Genre` edge in the connection, with data from `Album`. */
export type ArtistGenresByAlbumArtistAndGenreManyToManyEdge = {
  __typename?: 'ArtistGenresByAlbumArtistAndGenreManyToManyEdge';
  /** Reads and enables pagination through a set of `Album`. */
  albumsByGenre: AlbumsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Genre` at the end of the edge. */
  node?: Maybe<Genre>;
};

/** A `Genre` edge in the connection, with data from `Album`. */
export type ArtistGenresByAlbumArtistAndGenreManyToManyEdgeAlbumsByGenreArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** A connection to a list of `Genre` values, with data from `Track`. */
export type ArtistGenresByTrackArtistAndGenreManyToManyConnection = {
  __typename?: 'ArtistGenresByTrackArtistAndGenreManyToManyConnection';
  /** A list of edges which contains the `Genre`, info from the `Track`, and the cursor to aid in pagination. */
  edges: Array<ArtistGenresByTrackArtistAndGenreManyToManyEdge>;
  /** A list of `Genre` objects. */
  nodes: Array<Maybe<Genre>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Genre` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Genre` edge in the connection, with data from `Track`. */
export type ArtistGenresByTrackArtistAndGenreManyToManyEdge = {
  __typename?: 'ArtistGenresByTrackArtistAndGenreManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Genre` at the end of the edge. */
  node?: Maybe<Genre>;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByGenre: TracksConnection;
};

/** A `Genre` edge in the connection, with data from `Track`. */
export type ArtistGenresByTrackArtistAndGenreManyToManyEdgeTracksByGenreArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** All input for the `artistHasSubscription` mutation. */
export type ArtistHasSubscriptionInput = {
  artist: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `artistHasSubscription` mutation. */
export type ArtistHasSubscriptionPayload = {
  __typename?: 'ArtistHasSubscriptionPayload';
  boolean?: Maybe<Scalars['Boolean']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** An input for mutations affecting `Artist` */
export type ArtistInput = {
  /** The `Artist` internal url */
  blarecastUrl?: InputMaybe<Scalars['String']>;
  /** The `Artist` profile cover image */
  coverImg?: InputMaybe<Scalars['String']>;
  /** E@omit create,update,delete\nDefines the datetime with time zone when `Artist` was first created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** A longer description of the `Artist` */
  description?: InputMaybe<Scalars['String']>;
  /** The `Artist` public display name */
  displayName?: InputMaybe<Scalars['String']>;
  /** The `Artist` facebook profile */
  facebook?: InputMaybe<Scalars['String']>;
  /** The preferred selected genre of this `Artist` */
  genre?: InputMaybe<Scalars['UUID']>;
  /** Unique identifier for row */
  id?: InputMaybe<Scalars['UUID']>;
  /** The `Artist` image */
  img?: InputMaybe<Scalars['String']>;
  /** The `Artist` instagram profile */
  instagram?: InputMaybe<Scalars['String']>;
  /** A shorter description of the `Artist` */
  shortDescription?: InputMaybe<Scalars['String']>;
  /** If the `Artist` is a solo artist or part of a band, defaults to true */
  solo?: InputMaybe<Scalars['Boolean']>;
  /** The `Artist` twitter profile */
  twitter?: InputMaybe<Scalars['String']>;
  /** E@omit create,update,delete\nDefines the datetime with time zone when `Artist` was last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Defines if the `Artist` is verified or not */
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** `ArtistMember` table - A member of a band */
export type ArtistMember = Node & {
  __typename?: 'ArtistMember';
  /** Reads a single `Artist` that is related to this `ArtistMember`. */
  artistByArtistId?: Maybe<Artist>;
  /** The id of the `Artist` this member belongs to */
  artistId?: Maybe<Scalars['UUID']>;
  /** Defines if the `User` has accepted to join `Artist` membership */
  confirmed?: Maybe<Scalars['Boolean']>;
  /** Defines the datetime with time zone when `ArtistMember` was first created */
  createdAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `ProfilePublic`. */
  publicProfile: ProfilePublicsConnection;
  /** Reads a single `User` that is related to this `ArtistMember`. */
  userByUserId?: Maybe<User>;
  /** The actual `User` behind the artist member */
  userId?: Maybe<Scalars['UUID']>;
};

/** `ArtistMember` table - A member of a band */
export type ArtistMemberPublicProfileArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/**
 * A condition to be used against `ArtistMember` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ArtistMemberCondition = {
  /** Checks for equality with the object’s `artistId` field. */
  artistId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `confirmed` field. */
  confirmed?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ArtistMember` object types. All fields are combined with a logical ‘and.’ */
export type ArtistMemberFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ArtistMemberFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ArtistMemberFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ArtistMemberFilter>>;
};

/** An input for mutations affecting `ArtistMember` */
export type ArtistMemberInput = {
  /** The id of the `Artist` this member belongs to */
  artistId?: InputMaybe<Scalars['UUID']>;
  /** Defines if the `User` has accepted to join `Artist` membership */
  confirmed?: InputMaybe<Scalars['Boolean']>;
  /** Defines the datetime with time zone when `ArtistMember` was first created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** The actual `User` behind the artist member */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A connection to a list of `ArtistMember` values. */
export type ArtistMembersConnection = {
  __typename?: 'ArtistMembersConnection';
  /** A list of edges which contains the `ArtistMember` and cursor to aid in pagination. */
  edges: Array<ArtistMembersEdge>;
  /** A list of `ArtistMember` objects. */
  nodes: Array<Maybe<ArtistMember>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ArtistMember` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ArtistMember` edge in the connection. */
export type ArtistMembersEdge = {
  __typename?: 'ArtistMembersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ArtistMember` at the end of the edge. */
  node?: Maybe<ArtistMember>;
};

/** Methods to use when ordering `ArtistMember`. */
export enum ArtistMembersOrderBy {
  ArtistIdAsc = 'ARTIST_ID_ASC',
  ArtistIdDesc = 'ARTIST_ID_DESC',
  ConfirmedAsc = 'CONFIRMED_ASC',
  ConfirmedDesc = 'CONFIRMED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
}

/** Represents an update to a `Artist`. Fields that are set will be updated. */
export type ArtistPatch = {
  /** The `Artist` internal url */
  blarecastUrl?: InputMaybe<Scalars['String']>;
  /** The `Artist` profile cover image */
  coverImg?: InputMaybe<Scalars['String']>;
  /** E@omit create,update,delete\nDefines the datetime with time zone when `Artist` was first created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** A longer description of the `Artist` */
  description?: InputMaybe<Scalars['String']>;
  /** The `Artist` public display name */
  displayName?: InputMaybe<Scalars['String']>;
  /** The `Artist` facebook profile */
  facebook?: InputMaybe<Scalars['String']>;
  /** The preferred selected genre of this `Artist` */
  genre?: InputMaybe<Scalars['UUID']>;
  /** Unique identifier for row */
  id?: InputMaybe<Scalars['UUID']>;
  /** The `Artist` image */
  img?: InputMaybe<Scalars['String']>;
  /** The `Artist` instagram profile */
  instagram?: InputMaybe<Scalars['String']>;
  /** A shorter description of the `Artist` */
  shortDescription?: InputMaybe<Scalars['String']>;
  /** If the `Artist` is a solo artist or part of a band, defaults to true */
  solo?: InputMaybe<Scalars['Boolean']>;
  /** The `Artist` twitter profile */
  twitter?: InputMaybe<Scalars['String']>;
  /** E@omit create,update,delete\nDefines the datetime with time zone when `Artist` was last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Defines if the `Artist` is verified or not */
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** A connection to a list of `Playlist` values, with data from `Like`. */
export type ArtistPlaylistsByLikeActingArtistIdAndPlaylistIdManyToManyConnection = {
  __typename?: 'ArtistPlaylistsByLikeActingArtistIdAndPlaylistIdManyToManyConnection';
  /** A list of edges which contains the `Playlist`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<ArtistPlaylistsByLikeActingArtistIdAndPlaylistIdManyToManyEdge>;
  /** A list of `Playlist` objects. */
  nodes: Array<Maybe<Playlist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Playlist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Playlist` edge in the connection, with data from `Like`. */
export type ArtistPlaylistsByLikeActingArtistIdAndPlaylistIdManyToManyEdge = {
  __typename?: 'ArtistPlaylistsByLikeActingArtistIdAndPlaylistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByPlaylistId: LikesConnection;
  /** The `Playlist` at the end of the edge. */
  node?: Maybe<Playlist>;
};

/** A `Playlist` edge in the connection, with data from `Like`. */
export type ArtistPlaylistsByLikeActingArtistIdAndPlaylistIdManyToManyEdgeLikesByPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Playlist` values, with data from `Like`. */
export type ArtistPlaylistsByLikeArtistIdAndPlaylistIdManyToManyConnection = {
  __typename?: 'ArtistPlaylistsByLikeArtistIdAndPlaylistIdManyToManyConnection';
  /** A list of edges which contains the `Playlist`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<ArtistPlaylistsByLikeArtistIdAndPlaylistIdManyToManyEdge>;
  /** A list of `Playlist` objects. */
  nodes: Array<Maybe<Playlist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Playlist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Playlist` edge in the connection, with data from `Like`. */
export type ArtistPlaylistsByLikeArtistIdAndPlaylistIdManyToManyEdge = {
  __typename?: 'ArtistPlaylistsByLikeArtistIdAndPlaylistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByPlaylistId: LikesConnection;
  /** The `Playlist` at the end of the edge. */
  node?: Maybe<Playlist>;
};

/** A `Playlist` edge in the connection, with data from `Like`. */
export type ArtistPlaylistsByLikeArtistIdAndPlaylistIdManyToManyEdgeLikesByPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Playlist` values, with data from `RecentlyPlayed`. */
export type ArtistPlaylistsByRecentlyPlayedArtistIdAndPlaylistIdManyToManyConnection = {
  __typename?: 'ArtistPlaylistsByRecentlyPlayedArtistIdAndPlaylistIdManyToManyConnection';
  /** A list of edges which contains the `Playlist`, info from the `RecentlyPlayed`, and the cursor to aid in pagination. */
  edges: Array<ArtistPlaylistsByRecentlyPlayedArtistIdAndPlaylistIdManyToManyEdge>;
  /** A list of `Playlist` objects. */
  nodes: Array<Maybe<Playlist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Playlist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Playlist` edge in the connection, with data from `RecentlyPlayed`. */
export type ArtistPlaylistsByRecentlyPlayedArtistIdAndPlaylistIdManyToManyEdge = {
  __typename?: 'ArtistPlaylistsByRecentlyPlayedArtistIdAndPlaylistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Playlist` at the end of the edge. */
  node?: Maybe<Playlist>;
  /** Reads and enables pagination through a set of `RecentlyPlayed`. */
  recentlyPlayedsByPlaylistId: RecentlyPlayedsConnection;
};

/** A `Playlist` edge in the connection, with data from `RecentlyPlayed`. */
export type ArtistPlaylistsByRecentlyPlayedArtistIdAndPlaylistIdManyToManyEdgeRecentlyPlayedsByPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecentlyPlayedCondition>;
  filter?: InputMaybe<RecentlyPlayedFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecentlyPlayedsOrderBy>>;
};

/** A connection to a list of `RecordLabel` values, with data from `Album`. */
export type ArtistRecordLabelsByAlbumArtistAndPublisherManyToManyConnection = {
  __typename?: 'ArtistRecordLabelsByAlbumArtistAndPublisherManyToManyConnection';
  /** A list of edges which contains the `RecordLabel`, info from the `Album`, and the cursor to aid in pagination. */
  edges: Array<ArtistRecordLabelsByAlbumArtistAndPublisherManyToManyEdge>;
  /** A list of `RecordLabel` objects. */
  nodes: Array<Maybe<RecordLabel>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `RecordLabel` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `RecordLabel` edge in the connection, with data from `Album`. */
export type ArtistRecordLabelsByAlbumArtistAndPublisherManyToManyEdge = {
  __typename?: 'ArtistRecordLabelsByAlbumArtistAndPublisherManyToManyEdge';
  /** Reads and enables pagination through a set of `Album`. */
  albumsByPublisher: AlbumsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RecordLabel` at the end of the edge. */
  node?: Maybe<RecordLabel>;
};

/** A `RecordLabel` edge in the connection, with data from `Album`. */
export type ArtistRecordLabelsByAlbumArtistAndPublisherManyToManyEdgeAlbumsByPublisherArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** A connection to a list of `RecordLabel` values, with data from `Track`. */
export type ArtistRecordLabelsByTrackArtistAndPublisherManyToManyConnection = {
  __typename?: 'ArtistRecordLabelsByTrackArtistAndPublisherManyToManyConnection';
  /** A list of edges which contains the `RecordLabel`, info from the `Track`, and the cursor to aid in pagination. */
  edges: Array<ArtistRecordLabelsByTrackArtistAndPublisherManyToManyEdge>;
  /** A list of `RecordLabel` objects. */
  nodes: Array<Maybe<RecordLabel>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `RecordLabel` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `RecordLabel` edge in the connection, with data from `Track`. */
export type ArtistRecordLabelsByTrackArtistAndPublisherManyToManyEdge = {
  __typename?: 'ArtistRecordLabelsByTrackArtistAndPublisherManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RecordLabel` at the end of the edge. */
  node?: Maybe<RecordLabel>;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByPublisher: TracksConnection;
};

/** A `RecordLabel` edge in the connection, with data from `Track`. */
export type ArtistRecordLabelsByTrackArtistAndPublisherManyToManyEdgeTracksByPublisherArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `ArtistSubscription` table */
export type ArtistSubscription = Node & {
  __typename?: 'ArtistSubscription';
  /** Defines if `ArtistSubscription` is active */
  active?: Maybe<Scalars['Boolean']>;
  /** Reads a single `Artist` that is related to this `ArtistSubscription`. */
  artistByArtistId?: Maybe<Artist>;
  /** Artist id that belongs to the `ArtistSubscription` */
  artistId: Scalars['UUID'];
  /** Reads a single `ArtistSubscription` that is related to this `ArtistSubscription`. */
  artistSubscriptionByDowngradeTo?: Maybe<ArtistSubscription>;
  /** Reads a single `ArtistSubscription` that is related to this `ArtistSubscription`. */
  artistSubscriptionByUpgradeTo?: Maybe<ArtistSubscription>;
  /** Reads a single `ArtistSubscriptionType` that is related to this `ArtistSubscription`. */
  artistSubscriptionTypeBySubscriptionType?: Maybe<ArtistSubscriptionType>;
  /** Reads and enables pagination through a set of `ArtistSubscriptionType`. */
  artistSubscriptionTypesByArtistSubscriptionDowngradeToAndSubscriptionType: ArtistSubscriptionArtistSubscriptionTypesByArtistSubscriptionDowngradeToAndSubscriptionTypeManyToManyConnection;
  /** Reads and enables pagination through a set of `ArtistSubscriptionType`. */
  artistSubscriptionTypesByArtistSubscriptionUpgradeToAndSubscriptionType: ArtistSubscriptionArtistSubscriptionTypesByArtistSubscriptionUpgradeToAndSubscriptionTypeManyToManyConnection;
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByArtistSubscriptionDowngradeToAndUpgradeTo: ArtistSubscriptionArtistSubscriptionsByArtistSubscriptionDowngradeToAndUpgradeToManyToManyConnection;
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByArtistSubscriptionUpgradeToAndDowngradeTo: ArtistSubscriptionArtistSubscriptionsByArtistSubscriptionUpgradeToAndDowngradeToManyToManyConnection;
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByDowngradeTo: ArtistSubscriptionsConnection;
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByUpgradeTo: ArtistSubscriptionsConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByArtistSubscriptionDowngradeToAndArtistId: ArtistSubscriptionArtistsByArtistSubscriptionDowngradeToAndArtistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByArtistSubscriptionUpgradeToAndArtistId: ArtistSubscriptionArtistsByArtistSubscriptionUpgradeToAndArtistIdManyToManyConnection;
  /** Monthly or yearly billing cycle */
  billingInterval?: Maybe<BillingInterval>;
  /** `ArtistSubscription` datetime with time zone of cancellation */
  cancelledAt?: Maybe<Scalars['Datetime']>;
  /** Defines the datetime with time zone when `ArtistSubscription` was first created */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** `ArtistSubscription` datetime with time zone to downgrade to on next payment */
  downgradeTo?: Maybe<Scalars['UUID']>;
  /** `ArtistSubscription` datetime with time zone of expiration */
  expiredAt?: Maybe<Scalars['Datetime']>;
  /** Unique identifier for row */
  id: Scalars['UUID'];
  /** Next billing datetime with time zone for the `ArtistSubscription` */
  nextBillingDate?: Maybe<Scalars['Datetime']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The selected payment processor unique identifier for the `ArtistSubscription` and future payments */
  paymentProcessorId?: Maybe<Scalars['String']>;
  /** Processor type selected for this `ArtistSubscription` and future payments */
  processorType?: Maybe<Scalars['String']>;
  refund?: Maybe<ArtistSubscriptionRefund>;
  /** Id of the `ArtistSubscriptionType` currently used */
  subscriptionType: Scalars['UUID'];
  /** Defines the datetime with time zone when `ArtistSubscription` was last updated */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Defines the `ArtistSubscription` to upgrade to when renewing */
  upgradeTo?: Maybe<Scalars['UUID']>;
  /** Reads a single `User` that is related to this `ArtistSubscription`. */
  userByUserId?: Maybe<User>;
  userId?: Maybe<Scalars['UUID']>;
  /** Reads and enables pagination through a set of `User`. */
  usersByArtistSubscriptionDowngradeToAndUserId: ArtistSubscriptionUsersByArtistSubscriptionDowngradeToAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByArtistSubscriptionUpgradeToAndUserId: ArtistSubscriptionUsersByArtistSubscriptionUpgradeToAndUserIdManyToManyConnection;
};

/** `ArtistSubscription` table */
export type ArtistSubscriptionArtistSubscriptionTypesByArtistSubscriptionDowngradeToAndSubscriptionTypeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionTypeCondition>;
  filter?: InputMaybe<ArtistSubscriptionTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionTypesOrderBy>>;
};

/** `ArtistSubscription` table */
export type ArtistSubscriptionArtistSubscriptionTypesByArtistSubscriptionUpgradeToAndSubscriptionTypeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionTypeCondition>;
  filter?: InputMaybe<ArtistSubscriptionTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionTypesOrderBy>>;
};

/** `ArtistSubscription` table */
export type ArtistSubscriptionArtistSubscriptionsByArtistSubscriptionDowngradeToAndUpgradeToArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** `ArtistSubscription` table */
export type ArtistSubscriptionArtistSubscriptionsByArtistSubscriptionUpgradeToAndDowngradeToArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** `ArtistSubscription` table */
export type ArtistSubscriptionArtistSubscriptionsByDowngradeToArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** `ArtistSubscription` table */
export type ArtistSubscriptionArtistSubscriptionsByUpgradeToArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** `ArtistSubscription` table */
export type ArtistSubscriptionArtistsByArtistSubscriptionDowngradeToAndArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `ArtistSubscription` table */
export type ArtistSubscriptionArtistsByArtistSubscriptionUpgradeToAndArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `ArtistSubscription` table */
export type ArtistSubscriptionUsersByArtistSubscriptionDowngradeToAndUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** `ArtistSubscription` table */
export type ArtistSubscriptionUsersByArtistSubscriptionUpgradeToAndUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `ArtistSubscriptionType` values, with data from `ArtistSubscription`. */
export type ArtistSubscriptionArtistSubscriptionTypesByArtistSubscriptionDowngradeToAndSubscriptionTypeManyToManyConnection = {
  __typename?: 'ArtistSubscriptionArtistSubscriptionTypesByArtistSubscriptionDowngradeToAndSubscriptionTypeManyToManyConnection';
  /** A list of edges which contains the `ArtistSubscriptionType`, info from the `ArtistSubscription`, and the cursor to aid in pagination. */
  edges: Array<ArtistSubscriptionArtistSubscriptionTypesByArtistSubscriptionDowngradeToAndSubscriptionTypeManyToManyEdge>;
  /** A list of `ArtistSubscriptionType` objects. */
  nodes: Array<Maybe<ArtistSubscriptionType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ArtistSubscriptionType` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ArtistSubscriptionType` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionArtistSubscriptionTypesByArtistSubscriptionDowngradeToAndSubscriptionTypeManyToManyEdge = {
  __typename?: 'ArtistSubscriptionArtistSubscriptionTypesByArtistSubscriptionDowngradeToAndSubscriptionTypeManyToManyEdge';
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsBySubscriptionType: ArtistSubscriptionsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ArtistSubscriptionType` at the end of the edge. */
  node?: Maybe<ArtistSubscriptionType>;
};

/** A `ArtistSubscriptionType` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionArtistSubscriptionTypesByArtistSubscriptionDowngradeToAndSubscriptionTypeManyToManyEdgeArtistSubscriptionsBySubscriptionTypeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** A connection to a list of `ArtistSubscriptionType` values, with data from `ArtistSubscription`. */
export type ArtistSubscriptionArtistSubscriptionTypesByArtistSubscriptionUpgradeToAndSubscriptionTypeManyToManyConnection = {
  __typename?: 'ArtistSubscriptionArtistSubscriptionTypesByArtistSubscriptionUpgradeToAndSubscriptionTypeManyToManyConnection';
  /** A list of edges which contains the `ArtistSubscriptionType`, info from the `ArtistSubscription`, and the cursor to aid in pagination. */
  edges: Array<ArtistSubscriptionArtistSubscriptionTypesByArtistSubscriptionUpgradeToAndSubscriptionTypeManyToManyEdge>;
  /** A list of `ArtistSubscriptionType` objects. */
  nodes: Array<Maybe<ArtistSubscriptionType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ArtistSubscriptionType` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ArtistSubscriptionType` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionArtistSubscriptionTypesByArtistSubscriptionUpgradeToAndSubscriptionTypeManyToManyEdge = {
  __typename?: 'ArtistSubscriptionArtistSubscriptionTypesByArtistSubscriptionUpgradeToAndSubscriptionTypeManyToManyEdge';
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsBySubscriptionType: ArtistSubscriptionsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ArtistSubscriptionType` at the end of the edge. */
  node?: Maybe<ArtistSubscriptionType>;
};

/** A `ArtistSubscriptionType` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionArtistSubscriptionTypesByArtistSubscriptionUpgradeToAndSubscriptionTypeManyToManyEdgeArtistSubscriptionsBySubscriptionTypeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** A connection to a list of `ArtistSubscription` values, with data from `ArtistSubscription`. */
export type ArtistSubscriptionArtistSubscriptionsByArtistSubscriptionDowngradeToAndUpgradeToManyToManyConnection = {
  __typename?: 'ArtistSubscriptionArtistSubscriptionsByArtistSubscriptionDowngradeToAndUpgradeToManyToManyConnection';
  /** A list of edges which contains the `ArtistSubscription`, info from the `ArtistSubscription`, and the cursor to aid in pagination. */
  edges: Array<ArtistSubscriptionArtistSubscriptionsByArtistSubscriptionDowngradeToAndUpgradeToManyToManyEdge>;
  /** A list of `ArtistSubscription` objects. */
  nodes: Array<Maybe<ArtistSubscription>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ArtistSubscription` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ArtistSubscription` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionArtistSubscriptionsByArtistSubscriptionDowngradeToAndUpgradeToManyToManyEdge = {
  __typename?: 'ArtistSubscriptionArtistSubscriptionsByArtistSubscriptionDowngradeToAndUpgradeToManyToManyEdge';
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByUpgradeTo: ArtistSubscriptionsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ArtistSubscription` at the end of the edge. */
  node?: Maybe<ArtistSubscription>;
};

/** A `ArtistSubscription` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionArtistSubscriptionsByArtistSubscriptionDowngradeToAndUpgradeToManyToManyEdgeArtistSubscriptionsByUpgradeToArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** A connection to a list of `ArtistSubscription` values, with data from `ArtistSubscription`. */
export type ArtistSubscriptionArtistSubscriptionsByArtistSubscriptionUpgradeToAndDowngradeToManyToManyConnection = {
  __typename?: 'ArtistSubscriptionArtistSubscriptionsByArtistSubscriptionUpgradeToAndDowngradeToManyToManyConnection';
  /** A list of edges which contains the `ArtistSubscription`, info from the `ArtistSubscription`, and the cursor to aid in pagination. */
  edges: Array<ArtistSubscriptionArtistSubscriptionsByArtistSubscriptionUpgradeToAndDowngradeToManyToManyEdge>;
  /** A list of `ArtistSubscription` objects. */
  nodes: Array<Maybe<ArtistSubscription>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ArtistSubscription` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ArtistSubscription` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionArtistSubscriptionsByArtistSubscriptionUpgradeToAndDowngradeToManyToManyEdge = {
  __typename?: 'ArtistSubscriptionArtistSubscriptionsByArtistSubscriptionUpgradeToAndDowngradeToManyToManyEdge';
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByDowngradeTo: ArtistSubscriptionsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ArtistSubscription` at the end of the edge. */
  node?: Maybe<ArtistSubscription>;
};

/** A `ArtistSubscription` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionArtistSubscriptionsByArtistSubscriptionUpgradeToAndDowngradeToManyToManyEdgeArtistSubscriptionsByDowngradeToArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `ArtistSubscription`. */
export type ArtistSubscriptionArtistsByArtistSubscriptionDowngradeToAndArtistIdManyToManyConnection = {
  __typename?: 'ArtistSubscriptionArtistsByArtistSubscriptionDowngradeToAndArtistIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `ArtistSubscription`, and the cursor to aid in pagination. */
  edges: Array<ArtistSubscriptionArtistsByArtistSubscriptionDowngradeToAndArtistIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionArtistsByArtistSubscriptionDowngradeToAndArtistIdManyToManyEdge = {
  __typename?: 'ArtistSubscriptionArtistsByArtistSubscriptionDowngradeToAndArtistIdManyToManyEdge';
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByArtistId: ArtistSubscriptionsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** A `Artist` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionArtistsByArtistSubscriptionDowngradeToAndArtistIdManyToManyEdgeArtistSubscriptionsByArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `ArtistSubscription`. */
export type ArtistSubscriptionArtistsByArtistSubscriptionUpgradeToAndArtistIdManyToManyConnection = {
  __typename?: 'ArtistSubscriptionArtistsByArtistSubscriptionUpgradeToAndArtistIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `ArtistSubscription`, and the cursor to aid in pagination. */
  edges: Array<ArtistSubscriptionArtistsByArtistSubscriptionUpgradeToAndArtistIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionArtistsByArtistSubscriptionUpgradeToAndArtistIdManyToManyEdge = {
  __typename?: 'ArtistSubscriptionArtistsByArtistSubscriptionUpgradeToAndArtistIdManyToManyEdge';
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByArtistId: ArtistSubscriptionsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** A `Artist` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionArtistsByArtistSubscriptionUpgradeToAndArtistIdManyToManyEdgeArtistSubscriptionsByArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/**
 * A condition to be used against `ArtistSubscription` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ArtistSubscriptionCondition = {
  /** Checks for equality with the object’s `active` field. */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `artistId` field. */
  artistId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `billingInterval` field. */
  billingInterval?: InputMaybe<BillingInterval>;
  /** Checks for equality with the object’s `cancelledAt` field. */
  cancelledAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `downgradeTo` field. */
  downgradeTo?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `expiredAt` field. */
  expiredAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `nextBillingDate` field. */
  nextBillingDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `paymentProcessorId` field. */
  paymentProcessorId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `processorType` field. */
  processorType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `subscriptionType` field. */
  subscriptionType?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `upgradeTo` field. */
  upgradeTo?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ArtistSubscription` object types. All fields are combined with a logical ‘and.’ */
export type ArtistSubscriptionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ArtistSubscriptionFilter>>;
  /** Filter by the object’s `cancelledAt` field. */
  cancelledAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `expiredAt` field. */
  expiredAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `nextBillingDate` field. */
  nextBillingDate?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ArtistSubscriptionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ArtistSubscriptionFilter>>;
  /** Filter by the object’s `paymentProcessorId` field. */
  paymentProcessorId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `processorType` field. */
  processorType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `ArtistSubscription` */
export type ArtistSubscriptionInput = {
  /** Defines if `ArtistSubscription` is active */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Artist id that belongs to the `ArtistSubscription` */
  artistId: Scalars['UUID'];
  /** Monthly or yearly billing cycle */
  billingInterval?: InputMaybe<BillingInterval>;
  /** `ArtistSubscription` datetime with time zone of cancellation */
  cancelledAt?: InputMaybe<Scalars['Datetime']>;
  /** `ArtistSubscription` datetime with time zone to downgrade to on next payment */
  downgradeTo?: InputMaybe<Scalars['UUID']>;
  /** `ArtistSubscription` datetime with time zone of expiration */
  expiredAt?: InputMaybe<Scalars['Datetime']>;
  /** Next billing datetime with time zone for the `ArtistSubscription` */
  nextBillingDate?: InputMaybe<Scalars['Datetime']>;
  /** The selected payment processor unique identifier for the `ArtistSubscription` and future payments */
  paymentProcessorId?: InputMaybe<Scalars['String']>;
  /** Processor type selected for this `ArtistSubscription` and future payments */
  processorType?: InputMaybe<Scalars['String']>;
  /** Id of the `ArtistSubscriptionType` currently used */
  subscriptionType: Scalars['UUID'];
  /** Defines the `ArtistSubscription` to upgrade to when renewing */
  upgradeTo?: InputMaybe<Scalars['UUID']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** Represents an update to a `ArtistSubscription`. Fields that are set will be updated. */
export type ArtistSubscriptionPatch = {
  /** Defines if `ArtistSubscription` is active */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Artist id that belongs to the `ArtistSubscription` */
  artistId?: InputMaybe<Scalars['UUID']>;
  /** Monthly or yearly billing cycle */
  billingInterval?: InputMaybe<BillingInterval>;
  /** `ArtistSubscription` datetime with time zone of cancellation */
  cancelledAt?: InputMaybe<Scalars['Datetime']>;
  /** `ArtistSubscription` datetime with time zone to downgrade to on next payment */
  downgradeTo?: InputMaybe<Scalars['UUID']>;
  /** `ArtistSubscription` datetime with time zone of expiration */
  expiredAt?: InputMaybe<Scalars['Datetime']>;
  /** Next billing datetime with time zone for the `ArtistSubscription` */
  nextBillingDate?: InputMaybe<Scalars['Datetime']>;
  /** The selected payment processor unique identifier for the `ArtistSubscription` and future payments */
  paymentProcessorId?: InputMaybe<Scalars['String']>;
  /** Processor type selected for this `ArtistSubscription` and future payments */
  processorType?: InputMaybe<Scalars['String']>;
  /** Id of the `ArtistSubscriptionType` currently used */
  subscriptionType?: InputMaybe<Scalars['UUID']>;
  /** Defines the `ArtistSubscription` to upgrade to when renewing */
  upgradeTo?: InputMaybe<Scalars['UUID']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

export type ArtistSubscriptionRefund = {
  __typename?: 'ArtistSubscriptionRefund';
  applicable: Scalars['Boolean'];
  daysValid: Scalars['Int'];
};

/** `ArtistSubscriptionType` table */
export type ArtistSubscriptionType = Node & {
  __typename?: 'ArtistSubscriptionType';
  /** Original annual price of the `ArtistSubscriptionType` */
  _annualPrice?: Maybe<Scalars['Int']>;
  /** Defines the original minimum withdrawal limit */
  _minWithdrawalLimit?: Maybe<Scalars['Int']>;
  /** Original monthly price of the `ArtistSubscriptionType` */
  _monthlyPrice?: Maybe<Scalars['Int']>;
  /** Defines the original setup fee */
  _setupFee?: Maybe<Scalars['Int']>;
  /** Defines the original withdrawal fee */
  _withdrawalFee?: Maybe<Scalars['Int']>;
  annualPrice?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByArtistSubscriptionSubscriptionTypeAndDowngradeTo: ArtistSubscriptionTypeArtistSubscriptionsByArtistSubscriptionSubscriptionTypeAndDowngradeToManyToManyConnection;
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByArtistSubscriptionSubscriptionTypeAndUpgradeTo: ArtistSubscriptionTypeArtistSubscriptionsByArtistSubscriptionSubscriptionTypeAndUpgradeToManyToManyConnection;
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsBySubscriptionType: ArtistSubscriptionsConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByArtistSubscriptionSubscriptionTypeAndArtistId: ArtistSubscriptionTypeArtistsByArtistSubscriptionSubscriptionTypeAndArtistIdManyToManyConnection;
  /** Reads a single `Currency` that is related to this `ArtistSubscriptionType`. */
  currencyByCurrencyId?: Maybe<Currency>;
  currencyCode?: Maybe<Scalars['String']>;
  /** The currency `ArtistSubscriptionType` is bound to */
  currencyId?: Maybe<Scalars['UUID']>;
  /** The limit of all track durations */
  duration?: Maybe<Scalars['Int']>;
  /** Can the artist set dynamic prices */
  dynamicPricing?: Maybe<Scalars['Boolean']>;
  /** All available formats for that `ArtistSubscriptionType` */
  formats?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['UUID'];
  /** Is this the default `ArtistSubscriptionType` */
  isDefault?: Maybe<Scalars['Boolean']>;
  minWithdrawalLimit?: Maybe<Scalars['String']>;
  /** Defines if a `ArtistSubscriptionType` offers the user a money back guarantee */
  moneyBackGuarantee?: Maybe<Scalars['Boolean']>;
  /** Defines how long a `ArtistSubscriptionType` offers the user a money back guarantee in days */
  moneyBackGuaranteeDays?: Maybe<Scalars['Int']>;
  monthlyPrice?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Percentage of earning per track */
  percentage?: Maybe<Scalars['BigFloat']>;
  /** The allowed number of posts */
  postCount?: Maybe<Scalars['Int']>;
  /** Is `ArtistSubscriptionType` recurring */
  recurring?: Maybe<Scalars['Boolean']>;
  /** Refund fee in percentages */
  refundPercentageFee?: Maybe<Scalars['Float']>;
  setupFee?: Maybe<Scalars['String']>;
  /** Subscription type of the `ArtistSubscriptionType`, either BASIC, ARTIST PRO or ARTIST PRO+ */
  subscriptionType: Scalars['String'];
  /** Track limit in duration */
  trackLimit?: Maybe<Scalars['Int']>;
  /** Whether the package enables the use of track pinning */
  trackPin?: Maybe<Scalars['Boolean']>;
  /** Defines the number of track pins available */
  trackPinLimit?: Maybe<Scalars['Int']>;
  /** Track limit in file size */
  trackSize?: Maybe<Scalars['BigInt']>;
  /** Reads and enables pagination through a set of `User`. */
  usersByArtistSubscriptionSubscriptionTypeAndUserId: ArtistSubscriptionTypeUsersByArtistSubscriptionSubscriptionTypeAndUserIdManyToManyConnection;
  withdrawalFee?: Maybe<Scalars['String']>;
};

/** `ArtistSubscriptionType` table */
export type ArtistSubscriptionTypeArtistSubscriptionsByArtistSubscriptionSubscriptionTypeAndDowngradeToArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** `ArtistSubscriptionType` table */
export type ArtistSubscriptionTypeArtistSubscriptionsByArtistSubscriptionSubscriptionTypeAndUpgradeToArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** `ArtistSubscriptionType` table */
export type ArtistSubscriptionTypeArtistSubscriptionsBySubscriptionTypeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** `ArtistSubscriptionType` table */
export type ArtistSubscriptionTypeArtistsByArtistSubscriptionSubscriptionTypeAndArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `ArtistSubscriptionType` table */
export type ArtistSubscriptionTypeUsersByArtistSubscriptionSubscriptionTypeAndUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `ArtistSubscription` values, with data from `ArtistSubscription`. */
export type ArtistSubscriptionTypeArtistSubscriptionsByArtistSubscriptionSubscriptionTypeAndDowngradeToManyToManyConnection = {
  __typename?: 'ArtistSubscriptionTypeArtistSubscriptionsByArtistSubscriptionSubscriptionTypeAndDowngradeToManyToManyConnection';
  /** A list of edges which contains the `ArtistSubscription`, info from the `ArtistSubscription`, and the cursor to aid in pagination. */
  edges: Array<ArtistSubscriptionTypeArtistSubscriptionsByArtistSubscriptionSubscriptionTypeAndDowngradeToManyToManyEdge>;
  /** A list of `ArtistSubscription` objects. */
  nodes: Array<Maybe<ArtistSubscription>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ArtistSubscription` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ArtistSubscription` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionTypeArtistSubscriptionsByArtistSubscriptionSubscriptionTypeAndDowngradeToManyToManyEdge = {
  __typename?: 'ArtistSubscriptionTypeArtistSubscriptionsByArtistSubscriptionSubscriptionTypeAndDowngradeToManyToManyEdge';
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByDowngradeTo: ArtistSubscriptionsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ArtistSubscription` at the end of the edge. */
  node?: Maybe<ArtistSubscription>;
};

/** A `ArtistSubscription` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionTypeArtistSubscriptionsByArtistSubscriptionSubscriptionTypeAndDowngradeToManyToManyEdgeArtistSubscriptionsByDowngradeToArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** A connection to a list of `ArtistSubscription` values, with data from `ArtistSubscription`. */
export type ArtistSubscriptionTypeArtistSubscriptionsByArtistSubscriptionSubscriptionTypeAndUpgradeToManyToManyConnection = {
  __typename?: 'ArtistSubscriptionTypeArtistSubscriptionsByArtistSubscriptionSubscriptionTypeAndUpgradeToManyToManyConnection';
  /** A list of edges which contains the `ArtistSubscription`, info from the `ArtistSubscription`, and the cursor to aid in pagination. */
  edges: Array<ArtistSubscriptionTypeArtistSubscriptionsByArtistSubscriptionSubscriptionTypeAndUpgradeToManyToManyEdge>;
  /** A list of `ArtistSubscription` objects. */
  nodes: Array<Maybe<ArtistSubscription>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ArtistSubscription` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ArtistSubscription` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionTypeArtistSubscriptionsByArtistSubscriptionSubscriptionTypeAndUpgradeToManyToManyEdge = {
  __typename?: 'ArtistSubscriptionTypeArtistSubscriptionsByArtistSubscriptionSubscriptionTypeAndUpgradeToManyToManyEdge';
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByUpgradeTo: ArtistSubscriptionsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ArtistSubscription` at the end of the edge. */
  node?: Maybe<ArtistSubscription>;
};

/** A `ArtistSubscription` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionTypeArtistSubscriptionsByArtistSubscriptionSubscriptionTypeAndUpgradeToManyToManyEdgeArtistSubscriptionsByUpgradeToArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `ArtistSubscription`. */
export type ArtistSubscriptionTypeArtistsByArtistSubscriptionSubscriptionTypeAndArtistIdManyToManyConnection = {
  __typename?: 'ArtistSubscriptionTypeArtistsByArtistSubscriptionSubscriptionTypeAndArtistIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `ArtistSubscription`, and the cursor to aid in pagination. */
  edges: Array<ArtistSubscriptionTypeArtistsByArtistSubscriptionSubscriptionTypeAndArtistIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionTypeArtistsByArtistSubscriptionSubscriptionTypeAndArtistIdManyToManyEdge = {
  __typename?: 'ArtistSubscriptionTypeArtistsByArtistSubscriptionSubscriptionTypeAndArtistIdManyToManyEdge';
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByArtistId: ArtistSubscriptionsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** A `Artist` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionTypeArtistsByArtistSubscriptionSubscriptionTypeAndArtistIdManyToManyEdgeArtistSubscriptionsByArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/**
 * A condition to be used against `ArtistSubscriptionType` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ArtistSubscriptionTypeCondition = {
  /** Checks for equality with the object’s `_annualPrice` field. */
  _annualPrice?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `_minWithdrawalLimit` field. */
  _minWithdrawalLimit?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `_monthlyPrice` field. */
  _monthlyPrice?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `_setupFee` field. */
  _setupFee?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `_withdrawalFee` field. */
  _withdrawalFee?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `currencyId` field. */
  currencyId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `duration` field. */
  duration?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `dynamicPricing` field. */
  dynamicPricing?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `formats` field. */
  formats?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isDefault` field. */
  isDefault?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `moneyBackGuarantee` field. */
  moneyBackGuarantee?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `moneyBackGuaranteeDays` field. */
  moneyBackGuaranteeDays?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `percentage` field. */
  percentage?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `postCount` field. */
  postCount?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `recurring` field. */
  recurring?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `refundPercentageFee` field. */
  refundPercentageFee?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `subscriptionType` field. */
  subscriptionType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `trackLimit` field. */
  trackLimit?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `trackPin` field. */
  trackPin?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `trackPinLimit` field. */
  trackPinLimit?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `trackSize` field. */
  trackSize?: InputMaybe<Scalars['BigInt']>;
};

/** A filter to be used against `ArtistSubscriptionType` object types. All fields are combined with a logical ‘and.’ */
export type ArtistSubscriptionTypeFilter = {
  /** Filter by the object’s `_annualPrice` field. */
  _annualPrice?: InputMaybe<IntFilter>;
  /** Filter by the object’s `_minWithdrawalLimit` field. */
  _minWithdrawalLimit?: InputMaybe<IntFilter>;
  /** Filter by the object’s `_monthlyPrice` field. */
  _monthlyPrice?: InputMaybe<IntFilter>;
  /** Filter by the object’s `_setupFee` field. */
  _setupFee?: InputMaybe<IntFilter>;
  /** Filter by the object’s `_withdrawalFee` field. */
  _withdrawalFee?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ArtistSubscriptionTypeFilter>>;
  /** Filter by the object’s `duration` field. */
  duration?: InputMaybe<IntFilter>;
  /** Filter by the object’s `moneyBackGuaranteeDays` field. */
  moneyBackGuaranteeDays?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ArtistSubscriptionTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ArtistSubscriptionTypeFilter>>;
  /** Filter by the object’s `postCount` field. */
  postCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `subscriptionType` field. */
  subscriptionType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `trackLimit` field. */
  trackLimit?: InputMaybe<IntFilter>;
  /** Filter by the object’s `trackPinLimit` field. */
  trackPinLimit?: InputMaybe<IntFilter>;
};

/** A connection to a list of `User` values, with data from `ArtistSubscription`. */
export type ArtistSubscriptionTypeUsersByArtistSubscriptionSubscriptionTypeAndUserIdManyToManyConnection = {
  __typename?: 'ArtistSubscriptionTypeUsersByArtistSubscriptionSubscriptionTypeAndUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `ArtistSubscription`, and the cursor to aid in pagination. */
  edges: Array<ArtistSubscriptionTypeUsersByArtistSubscriptionSubscriptionTypeAndUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionTypeUsersByArtistSubscriptionSubscriptionTypeAndUserIdManyToManyEdge = {
  __typename?: 'ArtistSubscriptionTypeUsersByArtistSubscriptionSubscriptionTypeAndUserIdManyToManyEdge';
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByUserId: ArtistSubscriptionsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** A `User` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionTypeUsersByArtistSubscriptionSubscriptionTypeAndUserIdManyToManyEdgeArtistSubscriptionsByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** A connection to a list of `ArtistSubscriptionType` values. */
export type ArtistSubscriptionTypesConnection = {
  __typename?: 'ArtistSubscriptionTypesConnection';
  /** A list of edges which contains the `ArtistSubscriptionType` and cursor to aid in pagination. */
  edges: Array<ArtistSubscriptionTypesEdge>;
  /** A list of `ArtistSubscriptionType` objects. */
  nodes: Array<Maybe<ArtistSubscriptionType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ArtistSubscriptionType` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ArtistSubscriptionType` edge in the connection. */
export type ArtistSubscriptionTypesEdge = {
  __typename?: 'ArtistSubscriptionTypesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ArtistSubscriptionType` at the end of the edge. */
  node?: Maybe<ArtistSubscriptionType>;
};

/** Methods to use when ordering `ArtistSubscriptionType`. */
export enum ArtistSubscriptionTypesOrderBy {
  CurrencyIdAsc = 'CURRENCY_ID_ASC',
  CurrencyIdDesc = 'CURRENCY_ID_DESC',
  DurationAsc = 'DURATION_ASC',
  DurationDesc = 'DURATION_DESC',
  DynamicPricingAsc = 'DYNAMIC_PRICING_ASC',
  DynamicPricingDesc = 'DYNAMIC_PRICING_DESC',
  FormatsAsc = 'FORMATS_ASC',
  FormatsDesc = 'FORMATS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsDefaultAsc = 'IS_DEFAULT_ASC',
  IsDefaultDesc = 'IS_DEFAULT_DESC',
  MoneyBackGuaranteeAsc = 'MONEY_BACK_GUARANTEE_ASC',
  MoneyBackGuaranteeDaysAsc = 'MONEY_BACK_GUARANTEE_DAYS_ASC',
  MoneyBackGuaranteeDaysDesc = 'MONEY_BACK_GUARANTEE_DAYS_DESC',
  MoneyBackGuaranteeDesc = 'MONEY_BACK_GUARANTEE_DESC',
  Natural = 'NATURAL',
  PercentageAsc = 'PERCENTAGE_ASC',
  PercentageDesc = 'PERCENTAGE_DESC',
  PostCountAsc = 'POST_COUNT_ASC',
  PostCountDesc = 'POST_COUNT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RecurringAsc = 'RECURRING_ASC',
  RecurringDesc = 'RECURRING_DESC',
  RefundPercentageFeeAsc = 'REFUND_PERCENTAGE_FEE_ASC',
  RefundPercentageFeeDesc = 'REFUND_PERCENTAGE_FEE_DESC',
  SubscriptionTypeAsc = 'SUBSCRIPTION_TYPE_ASC',
  SubscriptionTypeDesc = 'SUBSCRIPTION_TYPE_DESC',
  TrackLimitAsc = 'TRACK_LIMIT_ASC',
  TrackLimitDesc = 'TRACK_LIMIT_DESC',
  TrackPinAsc = 'TRACK_PIN_ASC',
  TrackPinDesc = 'TRACK_PIN_DESC',
  TrackPinLimitAsc = 'TRACK_PIN_LIMIT_ASC',
  TrackPinLimitDesc = 'TRACK_PIN_LIMIT_DESC',
  TrackSizeAsc = 'TRACK_SIZE_ASC',
  TrackSizeDesc = 'TRACK_SIZE_DESC',
  AnnualPriceAsc = '_ANNUAL_PRICE_ASC',
  AnnualPriceDesc = '_ANNUAL_PRICE_DESC',
  MinWithdrawalLimitAsc = '_MIN_WITHDRAWAL_LIMIT_ASC',
  MinWithdrawalLimitDesc = '_MIN_WITHDRAWAL_LIMIT_DESC',
  MonthlyPriceAsc = '_MONTHLY_PRICE_ASC',
  MonthlyPriceDesc = '_MONTHLY_PRICE_DESC',
  SetupFeeAsc = '_SETUP_FEE_ASC',
  SetupFeeDesc = '_SETUP_FEE_DESC',
  WithdrawalFeeAsc = '_WITHDRAWAL_FEE_ASC',
  WithdrawalFeeDesc = '_WITHDRAWAL_FEE_DESC',
}

/** A connection to a list of `User` values, with data from `ArtistSubscription`. */
export type ArtistSubscriptionUsersByArtistSubscriptionDowngradeToAndUserIdManyToManyConnection = {
  __typename?: 'ArtistSubscriptionUsersByArtistSubscriptionDowngradeToAndUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `ArtistSubscription`, and the cursor to aid in pagination. */
  edges: Array<ArtistSubscriptionUsersByArtistSubscriptionDowngradeToAndUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionUsersByArtistSubscriptionDowngradeToAndUserIdManyToManyEdge = {
  __typename?: 'ArtistSubscriptionUsersByArtistSubscriptionDowngradeToAndUserIdManyToManyEdge';
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByUserId: ArtistSubscriptionsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** A `User` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionUsersByArtistSubscriptionDowngradeToAndUserIdManyToManyEdgeArtistSubscriptionsByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ArtistSubscription`. */
export type ArtistSubscriptionUsersByArtistSubscriptionUpgradeToAndUserIdManyToManyConnection = {
  __typename?: 'ArtistSubscriptionUsersByArtistSubscriptionUpgradeToAndUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `ArtistSubscription`, and the cursor to aid in pagination. */
  edges: Array<ArtistSubscriptionUsersByArtistSubscriptionUpgradeToAndUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionUsersByArtistSubscriptionUpgradeToAndUserIdManyToManyEdge = {
  __typename?: 'ArtistSubscriptionUsersByArtistSubscriptionUpgradeToAndUserIdManyToManyEdge';
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByUserId: ArtistSubscriptionsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** A `User` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistSubscriptionUsersByArtistSubscriptionUpgradeToAndUserIdManyToManyEdgeArtistSubscriptionsByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** A connection to a list of `ArtistSubscription` values. */
export type ArtistSubscriptionsConnection = {
  __typename?: 'ArtistSubscriptionsConnection';
  /** A list of edges which contains the `ArtistSubscription` and cursor to aid in pagination. */
  edges: Array<ArtistSubscriptionsEdge>;
  /** A list of `ArtistSubscription` objects. */
  nodes: Array<Maybe<ArtistSubscription>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ArtistSubscription` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ArtistSubscription` edge in the connection. */
export type ArtistSubscriptionsEdge = {
  __typename?: 'ArtistSubscriptionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ArtistSubscription` at the end of the edge. */
  node?: Maybe<ArtistSubscription>;
};

/** Methods to use when ordering `ArtistSubscription`. */
export enum ArtistSubscriptionsOrderBy {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  ArtistIdAsc = 'ARTIST_ID_ASC',
  ArtistIdDesc = 'ARTIST_ID_DESC',
  BillingIntervalAsc = 'BILLING_INTERVAL_ASC',
  BillingIntervalDesc = 'BILLING_INTERVAL_DESC',
  CancelledAtAsc = 'CANCELLED_AT_ASC',
  CancelledAtDesc = 'CANCELLED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DowngradeToAsc = 'DOWNGRADE_TO_ASC',
  DowngradeToDesc = 'DOWNGRADE_TO_DESC',
  ExpiredAtAsc = 'EXPIRED_AT_ASC',
  ExpiredAtDesc = 'EXPIRED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  NextBillingDateAsc = 'NEXT_BILLING_DATE_ASC',
  NextBillingDateDesc = 'NEXT_BILLING_DATE_DESC',
  PaymentProcessorIdAsc = 'PAYMENT_PROCESSOR_ID_ASC',
  PaymentProcessorIdDesc = 'PAYMENT_PROCESSOR_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProcessorTypeAsc = 'PROCESSOR_TYPE_ASC',
  ProcessorTypeDesc = 'PROCESSOR_TYPE_DESC',
  SubscriptionTypeAsc = 'SUBSCRIPTION_TYPE_ASC',
  SubscriptionTypeDesc = 'SUBSCRIPTION_TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpgradeToAsc = 'UPGRADE_TO_ASC',
  UpgradeToDesc = 'UPGRADE_TO_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
}

/** A connection to a list of `Track` values, with data from `FeaturedArtist`. */
export type ArtistTracksByFeaturedArtistArtistAndTrackManyToManyConnection = {
  __typename?: 'ArtistTracksByFeaturedArtistArtistAndTrackManyToManyConnection';
  /** A list of edges which contains the `Track`, info from the `FeaturedArtist`, and the cursor to aid in pagination. */
  edges: Array<ArtistTracksByFeaturedArtistArtistAndTrackManyToManyEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Track` edge in the connection, with data from `FeaturedArtist`. */
export type ArtistTracksByFeaturedArtistArtistAndTrackManyToManyEdge = {
  __typename?: 'ArtistTracksByFeaturedArtistArtistAndTrackManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `FeaturedArtist`. */
  featuredArtistsByTrack: FeaturedArtistsConnection;
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
};

/** A `Track` edge in the connection, with data from `FeaturedArtist`. */
export type ArtistTracksByFeaturedArtistArtistAndTrackManyToManyEdgeFeaturedArtistsByTrackArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FeaturedArtistCondition>;
  filter?: InputMaybe<FeaturedArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeaturedArtistsOrderBy>>;
};

/** A connection to a list of `Track` values, with data from `Like`. */
export type ArtistTracksByLikeActingArtistIdAndTrackIdManyToManyConnection = {
  __typename?: 'ArtistTracksByLikeActingArtistIdAndTrackIdManyToManyConnection';
  /** A list of edges which contains the `Track`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<ArtistTracksByLikeActingArtistIdAndTrackIdManyToManyEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Track` edge in the connection, with data from `Like`. */
export type ArtistTracksByLikeActingArtistIdAndTrackIdManyToManyEdge = {
  __typename?: 'ArtistTracksByLikeActingArtistIdAndTrackIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByTrackId: LikesConnection;
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
};

/** A `Track` edge in the connection, with data from `Like`. */
export type ArtistTracksByLikeActingArtistIdAndTrackIdManyToManyEdgeLikesByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Track` values, with data from `Like`. */
export type ArtistTracksByLikeArtistIdAndTrackIdManyToManyConnection = {
  __typename?: 'ArtistTracksByLikeArtistIdAndTrackIdManyToManyConnection';
  /** A list of edges which contains the `Track`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<ArtistTracksByLikeArtistIdAndTrackIdManyToManyEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Track` edge in the connection, with data from `Like`. */
export type ArtistTracksByLikeArtistIdAndTrackIdManyToManyEdge = {
  __typename?: 'ArtistTracksByLikeArtistIdAndTrackIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByTrackId: LikesConnection;
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
};

/** A `Track` edge in the connection, with data from `Like`. */
export type ArtistTracksByLikeArtistIdAndTrackIdManyToManyEdgeLikesByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Track` values, with data from `Price`. */
export type ArtistTracksByPriceCreatorIdAndTrackIdManyToManyConnection = {
  __typename?: 'ArtistTracksByPriceCreatorIdAndTrackIdManyToManyConnection';
  /** A list of edges which contains the `Track`, info from the `Price`, and the cursor to aid in pagination. */
  edges: Array<ArtistTracksByPriceCreatorIdAndTrackIdManyToManyEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Track` edge in the connection, with data from `Price`. */
export type ArtistTracksByPriceCreatorIdAndTrackIdManyToManyEdge = {
  __typename?: 'ArtistTracksByPriceCreatorIdAndTrackIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
  /** Reads and enables pagination through a set of `Price`. */
  pricesByTrackId: PricesConnection;
};

/** A `Track` edge in the connection, with data from `Price`. */
export type ArtistTracksByPriceCreatorIdAndTrackIdManyToManyEdgePricesByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PriceCondition>;
  filter?: InputMaybe<PriceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricesOrderBy>>;
};

/** A connection to a list of `Track` values, with data from `TracksArtist`. */
export type ArtistTracksByTracksArtistArtistIdAndTrackIdManyToManyConnection = {
  __typename?: 'ArtistTracksByTracksArtistArtistIdAndTrackIdManyToManyConnection';
  /** A list of edges which contains the `Track`, info from the `TracksArtist`, and the cursor to aid in pagination. */
  edges: Array<ArtistTracksByTracksArtistArtistIdAndTrackIdManyToManyEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Track` edge in the connection, with data from `TracksArtist`. */
export type ArtistTracksByTracksArtistArtistIdAndTrackIdManyToManyEdge = {
  __typename?: 'ArtistTracksByTracksArtistArtistIdAndTrackIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
  /** Reads and enables pagination through a set of `TracksArtist`. */
  tracksArtistsByTrackId: TracksArtistsConnection;
};

/** A `Track` edge in the connection, with data from `TracksArtist`. */
export type ArtistTracksByTracksArtistArtistIdAndTrackIdManyToManyEdgeTracksArtistsByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TracksArtistCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksArtistsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ArtistMember`. */
export type ArtistUsersByArtistMemberArtistIdAndUserIdManyToManyConnection = {
  __typename?: 'ArtistUsersByArtistMemberArtistIdAndUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `ArtistMember`, and the cursor to aid in pagination. */
  edges: Array<ArtistUsersByArtistMemberArtistIdAndUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ArtistMember`. */
export type ArtistUsersByArtistMemberArtistIdAndUserIdManyToManyEdge = {
  __typename?: 'ArtistUsersByArtistMemberArtistIdAndUserIdManyToManyEdge';
  /** Defines if the `User` has accepted to join `Artist` membership */
  confirmed?: Maybe<Scalars['Boolean']>;
  /** Defines the datetime with time zone when `ArtistMember` was first created */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  id: Scalars['UUID'];
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** A connection to a list of `User` values, with data from `ArtistSubscription`. */
export type ArtistUsersByArtistSubscriptionArtistIdAndUserIdManyToManyConnection = {
  __typename?: 'ArtistUsersByArtistSubscriptionArtistIdAndUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `ArtistSubscription`, and the cursor to aid in pagination. */
  edges: Array<ArtistUsersByArtistSubscriptionArtistIdAndUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistUsersByArtistSubscriptionArtistIdAndUserIdManyToManyEdge = {
  __typename?: 'ArtistUsersByArtistSubscriptionArtistIdAndUserIdManyToManyEdge';
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByUserId: ArtistSubscriptionsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** A `User` edge in the connection, with data from `ArtistSubscription`. */
export type ArtistUsersByArtistSubscriptionArtistIdAndUserIdManyToManyEdgeArtistSubscriptionsByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Like`. */
export type ArtistUsersByLikeActingArtistIdAndLikedUserIdManyToManyConnection = {
  __typename?: 'ArtistUsersByLikeActingArtistIdAndLikedUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<ArtistUsersByLikeActingArtistIdAndLikedUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Like`. */
export type ArtistUsersByLikeActingArtistIdAndLikedUserIdManyToManyEdge = {
  __typename?: 'ArtistUsersByLikeActingArtistIdAndLikedUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByLikedUserId: LikesConnection;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** A `User` edge in the connection, with data from `Like`. */
export type ArtistUsersByLikeActingArtistIdAndLikedUserIdManyToManyEdgeLikesByLikedUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Like`. */
export type ArtistUsersByLikeActingArtistIdAndUserIdManyToManyConnection = {
  __typename?: 'ArtistUsersByLikeActingArtistIdAndUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<ArtistUsersByLikeActingArtistIdAndUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Like`. */
export type ArtistUsersByLikeActingArtistIdAndUserIdManyToManyEdge = {
  __typename?: 'ArtistUsersByLikeActingArtistIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByUserId: LikesConnection;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** A `User` edge in the connection, with data from `Like`. */
export type ArtistUsersByLikeActingArtistIdAndUserIdManyToManyEdgeLikesByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Like`. */
export type ArtistUsersByLikeArtistIdAndLikedUserIdManyToManyConnection = {
  __typename?: 'ArtistUsersByLikeArtistIdAndLikedUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<ArtistUsersByLikeArtistIdAndLikedUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Like`. */
export type ArtistUsersByLikeArtistIdAndLikedUserIdManyToManyEdge = {
  __typename?: 'ArtistUsersByLikeArtistIdAndLikedUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByLikedUserId: LikesConnection;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** A `User` edge in the connection, with data from `Like`. */
export type ArtistUsersByLikeArtistIdAndLikedUserIdManyToManyEdgeLikesByLikedUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Like`. */
export type ArtistUsersByLikeArtistIdAndUserIdManyToManyConnection = {
  __typename?: 'ArtistUsersByLikeArtistIdAndUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<ArtistUsersByLikeArtistIdAndUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Like`. */
export type ArtistUsersByLikeArtistIdAndUserIdManyToManyEdge = {
  __typename?: 'ArtistUsersByLikeArtistIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByUserId: LikesConnection;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** A `User` edge in the connection, with data from `Like`. */
export type ArtistUsersByLikeArtistIdAndUserIdManyToManyEdgeLikesByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `User` values, with data from `RecentlyPlayed`. */
export type ArtistUsersByRecentlyPlayedArtistIdAndUserIdManyToManyConnection = {
  __typename?: 'ArtistUsersByRecentlyPlayedArtistIdAndUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `RecentlyPlayed`, and the cursor to aid in pagination. */
  edges: Array<ArtistUsersByRecentlyPlayedArtistIdAndUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `RecentlyPlayed`. */
export type ArtistUsersByRecentlyPlayedArtistIdAndUserIdManyToManyEdge = {
  __typename?: 'ArtistUsersByRecentlyPlayedArtistIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
  /** Reads and enables pagination through a set of `RecentlyPlayed`. */
  recentlyPlayedsByUserId: RecentlyPlayedsConnection;
};

/** A `User` edge in the connection, with data from `RecentlyPlayed`. */
export type ArtistUsersByRecentlyPlayedArtistIdAndUserIdManyToManyEdgeRecentlyPlayedsByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecentlyPlayedCondition>;
  filter?: InputMaybe<RecentlyPlayedFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecentlyPlayedsOrderBy>>;
};

/** A connection to a list of `Artist` values. */
export type ArtistsConnection = {
  __typename?: 'ArtistsConnection';
  /** A list of edges which contains the `Artist` and cursor to aid in pagination. */
  edges: Array<ArtistsEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection. */
export type ArtistsEdge = {
  __typename?: 'ArtistsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** Methods to use when ordering `Artist`. */
export enum ArtistsOrderBy {
  BlarecastUrlAsc = 'BLARECAST_URL_ASC',
  BlarecastUrlDesc = 'BLARECAST_URL_DESC',
  CoverImgAsc = 'COVER_IMG_ASC',
  CoverImgDesc = 'COVER_IMG_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  FacebookAsc = 'FACEBOOK_ASC',
  FacebookDesc = 'FACEBOOK_DESC',
  FollowerCountAsc = 'FOLLOWER_COUNT_ASC',
  FollowerCountDesc = 'FOLLOWER_COUNT_DESC',
  FollowingCountAsc = 'FOLLOWING_COUNT_ASC',
  FollowingCountDesc = 'FOLLOWING_COUNT_DESC',
  GenreAsc = 'GENRE_ASC',
  GenreDesc = 'GENRE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImgAsc = 'IMG_ASC',
  ImgDesc = 'IMG_DESC',
  InstagramAsc = 'INSTAGRAM_ASC',
  InstagramDesc = 'INSTAGRAM_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ShortDescriptionAsc = 'SHORT_DESCRIPTION_ASC',
  ShortDescriptionDesc = 'SHORT_DESCRIPTION_DESC',
  SoloAsc = 'SOLO_ASC',
  SoloDesc = 'SOLO_DESC',
  TextSearchAsc = 'TEXT_SEARCH_ASC',
  TextSearchDesc = 'TEXT_SEARCH_DESC',
  TwitterAsc = 'TWITTER_ASC',
  TwitterDesc = 'TWITTER_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  VerifiedAsc = 'VERIFIED_ASC',
  VerifiedDesc = 'VERIFIED_DESC',
}

export enum AssetType {
  Album = 'ALBUM',
  Artist = 'ARTIST',
  Playlist = 'PLAYLIST',
  Track = 'TRACK',
}

/** All input for the `authenticate` mutation. */
export type AuthenticateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};

/** The output of our `authenticate` mutation. */
export type AuthenticatePayload = {
  __typename?: 'AuthenticatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  jwtToken?: Maybe<JwtToken>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/**  Users auto generated queue table */
export type AutoGeneratedQueue = Node & {
  __typename?: 'AutoGeneratedQueue';
  /** When this queue row was created */
  createdAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['UUID'];
  listened?: Maybe<Scalars['Boolean']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Track` that is related to this `AutoGeneratedQueue`. */
  trackByTrackId?: Maybe<Track>;
  /** The primary unique identifier for the track */
  trackId?: Maybe<Scalars['UUID']>;
  /** Sort numbers for users auto generated queue */
  trackNumber: Scalars['Int'];
  /** Reads a single `User` that is related to this `AutoGeneratedQueue`. */
  userByUserId?: Maybe<User>;
  /** The primary unique identifier for the user */
  userId?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `AutoGeneratedQueue` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AutoGeneratedQueueCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `listened` field. */
  listened?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `trackId` field. */
  trackId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `trackNumber` field. */
  trackNumber?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `AutoGeneratedQueue` object types. All fields are combined with a logical ‘and.’ */
export type AutoGeneratedQueueFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AutoGeneratedQueueFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AutoGeneratedQueueFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AutoGeneratedQueueFilter>>;
  /** Filter by the object’s `trackNumber` field. */
  trackNumber?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `AutoGeneratedQueue` */
export type AutoGeneratedQueueInput = {
  /** When this queue row was created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  listened?: InputMaybe<Scalars['Boolean']>;
  /** The primary unique identifier for the track */
  trackId?: InputMaybe<Scalars['UUID']>;
  /** Sort numbers for users auto generated queue */
  trackNumber?: InputMaybe<Scalars['Int']>;
  /** The primary unique identifier for the user */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** Represents an update to a `AutoGeneratedQueue`. Fields that are set will be updated. */
export type AutoGeneratedQueuePatch = {
  /** When this queue row was created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  listened?: InputMaybe<Scalars['Boolean']>;
  /** The primary unique identifier for the track */
  trackId?: InputMaybe<Scalars['UUID']>;
  /** Sort numbers for users auto generated queue */
  trackNumber?: InputMaybe<Scalars['Int']>;
  /** The primary unique identifier for the user */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A connection to a list of `AutoGeneratedQueue` values. */
export type AutoGeneratedQueuesConnection = {
  __typename?: 'AutoGeneratedQueuesConnection';
  /** A list of edges which contains the `AutoGeneratedQueue` and cursor to aid in pagination. */
  edges: Array<AutoGeneratedQueuesEdge>;
  /** A list of `AutoGeneratedQueue` objects. */
  nodes: Array<Maybe<AutoGeneratedQueue>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AutoGeneratedQueue` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AutoGeneratedQueue` edge in the connection. */
export type AutoGeneratedQueuesEdge = {
  __typename?: 'AutoGeneratedQueuesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AutoGeneratedQueue` at the end of the edge. */
  node?: Maybe<AutoGeneratedQueue>;
};

/** Methods to use when ordering `AutoGeneratedQueue`. */
export enum AutoGeneratedQueuesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ListenedAsc = 'LISTENED_ASC',
  ListenedDesc = 'LISTENED_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TrackIdAsc = 'TRACK_ID_ASC',
  TrackIdDesc = 'TRACK_ID_DESC',
  TrackNumberAsc = 'TRACK_NUMBER_ASC',
  TrackNumberDesc = 'TRACK_NUMBER_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
}

export enum BillingInterval {
  Month = 'MONTH',
  Year = 'YEAR',
}

export type BlarecastUrl = {
  __typename?: 'BlarecastUrl';
  blarecastUrl: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  img?: Maybe<Scalars['String']>;
  presignedImgs?: Maybe<Array<Maybe<PresignedImage>>>;
  type: Scalars['String'];
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `BlarecastUrl` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BlarecastUrlCondition = {
  /** Checks for equality with the object’s `blarecastUrl` field. */
  blarecastUrl?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `img` field. */
  img?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `BlarecastUrl` object types. All fields are combined with a logical ‘and.’ */
export type BlarecastUrlFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BlarecastUrlFilter>>;
  /** Filter by the object’s `blarecastUrl` field. */
  blarecastUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `img` field. */
  img?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BlarecastUrlFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BlarecastUrlFilter>>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
};

export enum BlarecastUrlSearchType {
  Artist = 'ARTIST',
  Listener = 'LISTENER',
}

/** A connection to a list of `BlarecastUrl` values. */
export type BlarecastUrlsConnection = {
  __typename?: 'BlarecastUrlsConnection';
  /** A list of edges which contains the `BlarecastUrl` and cursor to aid in pagination. */
  edges: Array<BlarecastUrlsEdge>;
  /** A list of `BlarecastUrl` objects. */
  nodes: Array<Maybe<BlarecastUrl>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BlarecastUrl` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `BlarecastUrl` edge in the connection. */
export type BlarecastUrlsEdge = {
  __typename?: 'BlarecastUrlsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `BlarecastUrl` at the end of the edge. */
  node?: Maybe<BlarecastUrl>;
};

/** Methods to use when ordering `BlarecastUrl`. */
export enum BlarecastUrlsOrderBy {
  BlarecastUrlAsc = 'BLARECAST_URL_ASC',
  BlarecastUrlDesc = 'BLARECAST_URL_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  ImgAsc = 'IMG_ASC',
  ImgDesc = 'IMG_DESC',
  Natural = 'NATURAL',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
}

/** All input for the `checkAccountType` mutation. */
export type CheckAccountTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  usersId: Scalars['UUID'];
};

/** The output of our `checkAccountType` mutation. */
export type CheckAccountTypePayload = {
  __typename?: 'CheckAccountTypePayload';
  boolean?: Maybe<Scalars['Boolean']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `checkRecentlyPlayedConstraint` mutation. */
export type CheckRecentlyPlayedConstraintInput = {
  albumId?: InputMaybe<Scalars['UUID']>;
  artistId?: InputMaybe<Scalars['UUID']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  playlistId?: InputMaybe<Scalars['UUID']>;
};

/** The output of our `checkRecentlyPlayedConstraint` mutation. */
export type CheckRecentlyPlayedConstraintPayload = {
  __typename?: 'CheckRecentlyPlayedConstraintPayload';
  boolean?: Maybe<Scalars['Boolean']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** A connection to a list of `Country` values. */
export type CountriesConnection = {
  __typename?: 'CountriesConnection';
  /** A list of edges which contains the `Country` and cursor to aid in pagination. */
  edges: Array<CountriesEdge>;
  /** A list of `Country` objects. */
  nodes: Array<Maybe<Country>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Country` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Country` edge in the connection. */
export type CountriesEdge = {
  __typename?: 'CountriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Country` at the end of the edge. */
  node?: Maybe<Country>;
};

/** Methods to use when ordering `Country`. */
export enum CountriesOrderBy {
  BasePriceAsc = 'BASE_PRICE_ASC',
  BasePriceDesc = 'BASE_PRICE_DESC',
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  CodeIso3166Asc = 'CODE_ISO3166_ASC',
  CodeIso3166Desc = 'CODE_ISO3166_DESC',
  DefaultAsc = 'DEFAULT_ASC',
  DefaultDesc = 'DEFAULT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsDefaultAsc = 'IS_DEFAULT_ASC',
  IsDefaultDesc = 'IS_DEFAULT_DESC',
  MaxPriceAsc = 'MAX_PRICE_ASC',
  MaxPriceDesc = 'MAX_PRICE_DESC',
  MinPriceAsc = 'MIN_PRICE_ASC',
  MinPriceDesc = 'MIN_PRICE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SupportedAsc = 'SUPPORTED_ASC',
  SupportedDesc = 'SUPPORTED_DESC',
}

/** A country tracks many to many table */
export type CountriesTrack = {
  __typename?: 'CountriesTrack';
  /** Reads a single `Country` that is related to this `CountriesTrack`. */
  countryByCountryId?: Maybe<Country>;
  /** The primary unique identifier for the country */
  countryId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Track` that is related to this `CountriesTrack`. */
  trackByTrackId?: Maybe<Track>;
  /** The primary unique identifier for the track */
  trackId?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `CountriesTrack` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CountriesTrackCondition = {
  /** Checks for equality with the object’s `countryId` field. */
  countryId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `trackId` field. */
  trackId?: InputMaybe<Scalars['UUID']>;
};

/** An input for mutations affecting `CountriesTrack` */
export type CountriesTrackInput = {
  /** The primary unique identifier for the country */
  countryId?: InputMaybe<Scalars['UUID']>;
  /** The primary unique identifier for the track */
  trackId?: InputMaybe<Scalars['UUID']>;
};

/** A connection to a list of `CountriesTrack` values. */
export type CountriesTracksConnection = {
  __typename?: 'CountriesTracksConnection';
  /** A list of edges which contains the `CountriesTrack` and cursor to aid in pagination. */
  edges: Array<CountriesTracksEdge>;
  /** A list of `CountriesTrack` objects. */
  nodes: Array<Maybe<CountriesTrack>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CountriesTrack` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CountriesTrack` edge in the connection. */
export type CountriesTracksEdge = {
  __typename?: 'CountriesTracksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CountriesTrack` at the end of the edge. */
  node?: Maybe<CountriesTrack>;
};

/** Methods to use when ordering `CountriesTrack`. */
export enum CountriesTracksOrderBy {
  CountryIdAsc = 'COUNTRY_ID_ASC',
  CountryIdDesc = 'COUNTRY_ID_DESC',
  Natural = 'NATURAL',
  TrackIdAsc = 'TRACK_ID_ASC',
  TrackIdDesc = 'TRACK_ID_DESC',
}

/** A country list */
export type Country = Node & {
  __typename?: 'Country';
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByPriceCountryAndCreatorId: CountryArtistsByPriceCountryAndCreatorIdManyToManyConnection;
  /** Base recommended price for country */
  basePrice?: Maybe<Scalars['Int']>;
  /** The country’s code */
  code?: Maybe<Scalars['String']>;
  codeIso3166?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `CountriesTrack`. */
  countriesTracksByCountryId: CountriesTracksConnection;
  /** Reads and enables pagination through a set of `Currency`. */
  currenciesByCountry: CurrenciesConnection;
  default?: Maybe<Scalars['Boolean']>;
  /** The primary unique identifier for the country */
  id: Scalars['UUID'];
  isDefault?: Maybe<Scalars['Boolean']>;
  /** Maximum price for country */
  maxPrice?: Maybe<Scalars['Int']>;
  /** Minimum price for country */
  minPrice?: Maybe<Scalars['Int']>;
  /** The country’s name */
  name?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Price`. */
  pricesByCountry: PricesConnection;
  /** Reads and enables pagination through a set of `ProfileSetting`. */
  profileSettingsByGeolocation: ProfileSettingsConnection;
  /** Reads and enables pagination through a set of `RecordLabel`. */
  recordLabelsByProfileSettingGeolocationAndRecordLabelId: CountryRecordLabelsByProfileSettingGeolocationAndRecordLabelIdManyToManyConnection;
  supported?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByCountriesTrackCountryIdAndTrackId: CountryTracksByCountriesTrackCountryIdAndTrackIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByPriceCountryAndTrackId: CountryTracksByPriceCountryAndTrackIdManyToManyConnection;
};

/** A country list */
export type CountryArtistsByPriceCountryAndCreatorIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** A country list */
export type CountryCountriesTracksByCountryIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CountriesTrackCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountriesTracksOrderBy>>;
};

/** A country list */
export type CountryCurrenciesByCountryArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CurrencyCondition>;
  filter?: InputMaybe<CurrencyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrenciesOrderBy>>;
};

/** A country list */
export type CountryPricesByCountryArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PriceCondition>;
  filter?: InputMaybe<PriceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricesOrderBy>>;
};

/** A country list */
export type CountryProfileSettingsByGeolocationArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProfileSettingCondition>;
  filter?: InputMaybe<ProfileSettingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProfileSettingsOrderBy>>;
};

/** A country list */
export type CountryRecordLabelsByProfileSettingGeolocationAndRecordLabelIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecordLabelCondition>;
  filter?: InputMaybe<RecordLabelFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecordLabelsOrderBy>>;
};

/** A country list */
export type CountryTracksByCountriesTrackCountryIdAndTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** A country list */
export type CountryTracksByPriceCountryAndTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `Price`. */
export type CountryArtistsByPriceCountryAndCreatorIdManyToManyConnection = {
  __typename?: 'CountryArtistsByPriceCountryAndCreatorIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `Price`, and the cursor to aid in pagination. */
  edges: Array<CountryArtistsByPriceCountryAndCreatorIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `Price`. */
export type CountryArtistsByPriceCountryAndCreatorIdManyToManyEdge = {
  __typename?: 'CountryArtistsByPriceCountryAndCreatorIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
  /** Reads and enables pagination through a set of `Price`. */
  pricesByCreatorId: PricesConnection;
};

/** A `Artist` edge in the connection, with data from `Price`. */
export type CountryArtistsByPriceCountryAndCreatorIdManyToManyEdgePricesByCreatorIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PriceCondition>;
  filter?: InputMaybe<PriceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricesOrderBy>>;
};

/** A condition to be used against `Country` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CountryCondition = {
  /** Checks for equality with the object’s `basePrice` field. */
  basePrice?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `code` field. */
  code?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `codeIso3166` field. */
  codeIso3166?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `default` field. */
  default?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isDefault` field. */
  isDefault?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `maxPrice` field. */
  maxPrice?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `minPrice` field. */
  minPrice?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `supported` field. */
  supported?: InputMaybe<Scalars['Boolean']>;
};

/** A filter to be used against `Country` object types. All fields are combined with a logical ‘and.’ */
export type CountryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CountryFilter>>;
  /** Filter by the object’s `basePrice` field. */
  basePrice?: InputMaybe<IntFilter>;
  /** Filter by the object’s `code` field. */
  code?: InputMaybe<StringFilter>;
  /** Filter by the object’s `codeIso3166` field. */
  codeIso3166?: InputMaybe<StringFilter>;
  /** Filter by the object’s `maxPrice` field. */
  maxPrice?: InputMaybe<IntFilter>;
  /** Filter by the object’s `minPrice` field. */
  minPrice?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CountryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CountryFilter>>;
};

/** A connection to a list of `RecordLabel` values, with data from `ProfileSetting`. */
export type CountryRecordLabelsByProfileSettingGeolocationAndRecordLabelIdManyToManyConnection = {
  __typename?: 'CountryRecordLabelsByProfileSettingGeolocationAndRecordLabelIdManyToManyConnection';
  /** A list of edges which contains the `RecordLabel`, info from the `ProfileSetting`, and the cursor to aid in pagination. */
  edges: Array<CountryRecordLabelsByProfileSettingGeolocationAndRecordLabelIdManyToManyEdge>;
  /** A list of `RecordLabel` objects. */
  nodes: Array<Maybe<RecordLabel>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `RecordLabel` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `RecordLabel` edge in the connection, with data from `ProfileSetting`. */
export type CountryRecordLabelsByProfileSettingGeolocationAndRecordLabelIdManyToManyEdge = {
  __typename?: 'CountryRecordLabelsByProfileSettingGeolocationAndRecordLabelIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RecordLabel` at the end of the edge. */
  node?: Maybe<RecordLabel>;
  /** Reads and enables pagination through a set of `ProfileSetting`. */
  profileSettingsByRecordLabelId: ProfileSettingsConnection;
};

/** A `RecordLabel` edge in the connection, with data from `ProfileSetting`. */
export type CountryRecordLabelsByProfileSettingGeolocationAndRecordLabelIdManyToManyEdgeProfileSettingsByRecordLabelIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProfileSettingCondition>;
  filter?: InputMaybe<ProfileSettingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProfileSettingsOrderBy>>;
};

/** A connection to a list of `Track` values, with data from `CountriesTrack`. */
export type CountryTracksByCountriesTrackCountryIdAndTrackIdManyToManyConnection = {
  __typename?: 'CountryTracksByCountriesTrackCountryIdAndTrackIdManyToManyConnection';
  /** A list of edges which contains the `Track`, info from the `CountriesTrack`, and the cursor to aid in pagination. */
  edges: Array<CountryTracksByCountriesTrackCountryIdAndTrackIdManyToManyEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Track` edge in the connection, with data from `CountriesTrack`. */
export type CountryTracksByCountriesTrackCountryIdAndTrackIdManyToManyEdge = {
  __typename?: 'CountryTracksByCountriesTrackCountryIdAndTrackIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
};

/** A connection to a list of `Track` values, with data from `Price`. */
export type CountryTracksByPriceCountryAndTrackIdManyToManyConnection = {
  __typename?: 'CountryTracksByPriceCountryAndTrackIdManyToManyConnection';
  /** A list of edges which contains the `Track`, info from the `Price`, and the cursor to aid in pagination. */
  edges: Array<CountryTracksByPriceCountryAndTrackIdManyToManyEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Track` edge in the connection, with data from `Price`. */
export type CountryTracksByPriceCountryAndTrackIdManyToManyEdge = {
  __typename?: 'CountryTracksByPriceCountryAndTrackIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
  /** Reads and enables pagination through a set of `Price`. */
  pricesByTrackId: PricesConnection;
};

/** A `Track` edge in the connection, with data from `Price`. */
export type CountryTracksByPriceCountryAndTrackIdManyToManyEdgePricesByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PriceCondition>;
  filter?: InputMaybe<PriceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricesOrderBy>>;
};

/** All input for the create `Album` mutation. */
export type CreateAlbumInput = {
  /** The `Album` to be created by this mutation. */
  album: AlbumInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `Album` mutation. */
export type CreateAlbumPayload = {
  __typename?: 'CreateAlbumPayload';
  /** The `Album` that was created by this mutation. */
  album?: Maybe<Album>;
  /** An edge for our `Album`. May be used by Relay 1. */
  albumEdge?: Maybe<AlbumsEdge>;
  /** Reads a single `Artist` that is related to this `Album`. */
  artistByArtist?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Genre` that is related to this `Album`. */
  genreByGenre?: Maybe<Genre>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `RecordLabel` that is related to this `Album`. */
  recordLabelByPublisher?: Maybe<RecordLabel>;
};

/** The output of our create `Album` mutation. */
export type CreateAlbumPayloadAlbumEdgeArgs = {
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** All input for the create `Artist` mutation. */
export type CreateArtistInput = {
  /** The `Artist` to be created by this mutation. */
  artist: ArtistInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** All input for the `createArtistMemberByEmail` mutation. */
export type CreateArtistMemberByEmailInput = {
  artistId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailAddress: Scalars['String'];
};

/** The output of our `createArtistMemberByEmail` mutation. */
export type CreateArtistMemberByEmailPayload = {
  __typename?: 'CreateArtistMemberByEmailPayload';
  /** Reads a single `Artist` that is related to this `ArtistMember`. */
  artistByArtistId?: Maybe<Artist>;
  artistMember?: Maybe<ArtistMember>;
  /** An edge for our `ArtistMember`. May be used by Relay 1. */
  artistMemberEdge?: Maybe<ArtistMembersEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ArtistMember`. */
  userByUserId?: Maybe<User>;
};

/** The output of our `createArtistMemberByEmail` mutation. */
export type CreateArtistMemberByEmailPayloadArtistMemberEdgeArgs = {
  orderBy?: InputMaybe<Array<ArtistMembersOrderBy>>;
};

/** All input for the create `ArtistMember` mutation. */
export type CreateArtistMemberInput = {
  /** The `ArtistMember` to be created by this mutation. */
  artistMember: ArtistMemberInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `ArtistMember` mutation. */
export type CreateArtistMemberPayload = {
  __typename?: 'CreateArtistMemberPayload';
  /** Reads a single `Artist` that is related to this `ArtistMember`. */
  artistByArtistId?: Maybe<Artist>;
  /** The `ArtistMember` that was created by this mutation. */
  artistMember?: Maybe<ArtistMember>;
  /** An edge for our `ArtistMember`. May be used by Relay 1. */
  artistMemberEdge?: Maybe<ArtistMembersEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ArtistMember`. */
  userByUserId?: Maybe<User>;
};

/** The output of our create `ArtistMember` mutation. */
export type CreateArtistMemberPayloadArtistMemberEdgeArgs = {
  orderBy?: InputMaybe<Array<ArtistMembersOrderBy>>;
};

/** The output of our create `Artist` mutation. */
export type CreateArtistPayload = {
  __typename?: 'CreateArtistPayload';
  /** The `Artist` that was created by this mutation. */
  artist?: Maybe<Artist>;
  /** An edge for our `Artist`. May be used by Relay 1. */
  artistEdge?: Maybe<ArtistsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Genre` that is related to this `Artist`. */
  genreByGenre?: Maybe<Genre>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Artist`. */
  userByUserId?: Maybe<User>;
};

/** The output of our create `Artist` mutation. */
export type CreateArtistPayloadArtistEdgeArgs = {
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** All input for the create `ArtistSubscription` mutation. */
export type CreateArtistSubscriptionInput = {
  /** The `ArtistSubscription` to be created by this mutation. */
  artistSubscription: ArtistSubscriptionInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `ArtistSubscription` mutation. */
export type CreateArtistSubscriptionPayload = {
  __typename?: 'CreateArtistSubscriptionPayload';
  /** Reads a single `Artist` that is related to this `ArtistSubscription`. */
  artistByArtistId?: Maybe<Artist>;
  /** The `ArtistSubscription` that was created by this mutation. */
  artistSubscription?: Maybe<ArtistSubscription>;
  /** Reads a single `ArtistSubscription` that is related to this `ArtistSubscription`. */
  artistSubscriptionByDowngradeTo?: Maybe<ArtistSubscription>;
  /** Reads a single `ArtistSubscription` that is related to this `ArtistSubscription`. */
  artistSubscriptionByUpgradeTo?: Maybe<ArtistSubscription>;
  /** An edge for our `ArtistSubscription`. May be used by Relay 1. */
  artistSubscriptionEdge?: Maybe<ArtistSubscriptionsEdge>;
  /** Reads a single `ArtistSubscriptionType` that is related to this `ArtistSubscription`. */
  artistSubscriptionTypeBySubscriptionType?: Maybe<ArtistSubscriptionType>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ArtistSubscription`. */
  userByUserId?: Maybe<User>;
};

/** The output of our create `ArtistSubscription` mutation. */
export type CreateArtistSubscriptionPayloadArtistSubscriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** All input for the create `AutoGeneratedQueue` mutation. */
export type CreateAutoGeneratedQueueInput = {
  /** The `AutoGeneratedQueue` to be created by this mutation. */
  autoGeneratedQueue: AutoGeneratedQueueInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `AutoGeneratedQueue` mutation. */
export type CreateAutoGeneratedQueuePayload = {
  __typename?: 'CreateAutoGeneratedQueuePayload';
  /** The `AutoGeneratedQueue` that was created by this mutation. */
  autoGeneratedQueue?: Maybe<AutoGeneratedQueue>;
  /** An edge for our `AutoGeneratedQueue`. May be used by Relay 1. */
  autoGeneratedQueueEdge?: Maybe<AutoGeneratedQueuesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `AutoGeneratedQueue`. */
  trackByTrackId?: Maybe<Track>;
  /** Reads a single `User` that is related to this `AutoGeneratedQueue`. */
  userByUserId?: Maybe<User>;
};

/** The output of our create `AutoGeneratedQueue` mutation. */
export type CreateAutoGeneratedQueuePayloadAutoGeneratedQueueEdgeArgs = {
  orderBy?: InputMaybe<Array<AutoGeneratedQueuesOrderBy>>;
};

/** All input for the create `CountriesTrack` mutation. */
export type CreateCountriesTrackInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `CountriesTrack` to be created by this mutation. */
  countriesTrack: CountriesTrackInput;
};

/** The output of our create `CountriesTrack` mutation. */
export type CreateCountriesTrackPayload = {
  __typename?: 'CreateCountriesTrackPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CountriesTrack` that was created by this mutation. */
  countriesTrack?: Maybe<CountriesTrack>;
  /** An edge for our `CountriesTrack`. May be used by Relay 1. */
  countriesTrackEdge?: Maybe<CountriesTracksEdge>;
  /** Reads a single `Country` that is related to this `CountriesTrack`. */
  countryByCountryId?: Maybe<Country>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `CountriesTrack`. */
  trackByTrackId?: Maybe<Track>;
};

/** The output of our create `CountriesTrack` mutation. */
export type CreateCountriesTrackPayloadCountriesTrackEdgeArgs = {
  orderBy?: InputMaybe<Array<CountriesTracksOrderBy>>;
};

/** All input for the `createEarningsHistory` mutation. */
export type CreateEarningsHistoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  rows: Array<InputMaybe<EarningHistoryInputRecordInput>>;
};

/** The output of our `createEarningsHistory` mutation. */
export type CreateEarningsHistoryPayload = {
  __typename?: 'CreateEarningsHistoryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Event` mutation. */
export type CreateEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Event` to be created by this mutation. */
  event: EventInput;
};

/** The output of our create `Event` mutation. */
export type CreateEventPayload = {
  __typename?: 'CreateEventPayload';
  /** Reads a single `Artist` that is related to this `Event`. */
  artistByArtistId?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Event` that was created by this mutation. */
  event?: Maybe<Event>;
  /** An edge for our `Event`. May be used by Relay 1. */
  eventEdge?: Maybe<EventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `Event` mutation. */
export type CreateEventPayloadEventEdgeArgs = {
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
};

/** All input for the create `ExtraArtist` mutation. */
export type CreateExtraArtistInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ExtraArtist` to be created by this mutation. */
  extraArtist: ExtraArtistInput;
};

/** The output of our create `ExtraArtist` mutation. */
export type CreateExtraArtistPayload = {
  __typename?: 'CreateExtraArtistPayload';
  /** Reads a single `Album` that is related to this `ExtraArtist`. */
  albumByAlbum?: Maybe<Album>;
  /** Reads a single `Artist` that is related to this `ExtraArtist`. */
  artistByArtist?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ExtraArtist` that was created by this mutation. */
  extraArtist?: Maybe<ExtraArtist>;
  /** An edge for our `ExtraArtist`. May be used by Relay 1. */
  extraArtistEdge?: Maybe<ExtraArtistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `ExtraArtist` mutation. */
export type CreateExtraArtistPayloadExtraArtistEdgeArgs = {
  orderBy?: InputMaybe<Array<ExtraArtistsOrderBy>>;
};

/** All input for the create `FeaturedArtist` mutation. */
export type CreateFeaturedArtistInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `FeaturedArtist` to be created by this mutation. */
  featuredArtist: FeaturedArtistInput;
};

/** The output of our create `FeaturedArtist` mutation. */
export type CreateFeaturedArtistPayload = {
  __typename?: 'CreateFeaturedArtistPayload';
  /** Reads a single `Artist` that is related to this `FeaturedArtist`. */
  artistByArtist?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FeaturedArtist` that was created by this mutation. */
  featuredArtist?: Maybe<FeaturedArtist>;
  /** An edge for our `FeaturedArtist`. May be used by Relay 1. */
  featuredArtistEdge?: Maybe<FeaturedArtistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `FeaturedArtist`. */
  trackByTrack?: Maybe<Track>;
};

/** The output of our create `FeaturedArtist` mutation. */
export type CreateFeaturedArtistPayloadFeaturedArtistEdgeArgs = {
  orderBy?: InputMaybe<Array<FeaturedArtistsOrderBy>>;
};

/** All input for the create `FirebaseToken` mutation. */
export type CreateFirebaseTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `FirebaseToken` to be created by this mutation. */
  firebaseToken: FirebaseTokenInput;
};

/** The output of our create `FirebaseToken` mutation. */
export type CreateFirebaseTokenPayload = {
  __typename?: 'CreateFirebaseTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FirebaseToken` that was created by this mutation. */
  firebaseToken?: Maybe<FirebaseToken>;
  /** An edge for our `FirebaseToken`. May be used by Relay 1. */
  firebaseTokenEdge?: Maybe<FirebaseTokensEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `FirebaseToken`. */
  userByUserId?: Maybe<User>;
};

/** The output of our create `FirebaseToken` mutation. */
export type CreateFirebaseTokenPayloadFirebaseTokenEdgeArgs = {
  orderBy?: InputMaybe<Array<FirebaseTokensOrderBy>>;
};

/** All input for the create `FlywaySchemaHistory` mutation. */
export type CreateFlywaySchemaHistoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `FlywaySchemaHistory` to be created by this mutation. */
  flywaySchemaHistory: FlywaySchemaHistoryInput;
};

/** The output of our create `FlywaySchemaHistory` mutation. */
export type CreateFlywaySchemaHistoryPayload = {
  __typename?: 'CreateFlywaySchemaHistoryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FlywaySchemaHistory` that was created by this mutation. */
  flywaySchemaHistory?: Maybe<FlywaySchemaHistory>;
  /** An edge for our `FlywaySchemaHistory`. May be used by Relay 1. */
  flywaySchemaHistoryEdge?: Maybe<FlywaySchemaHistoriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `FlywaySchemaHistory` mutation. */
export type CreateFlywaySchemaHistoryPayloadFlywaySchemaHistoryEdgeArgs = {
  orderBy?: InputMaybe<Array<FlywaySchemaHistoriesOrderBy>>;
};

/** All input for the create `GenresAlbum` mutation. */
export type CreateGenresAlbumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `GenresAlbum` to be created by this mutation. */
  genresAlbum: GenresAlbumInput;
};

/** The output of our create `GenresAlbum` mutation. */
export type CreateGenresAlbumPayload = {
  __typename?: 'CreateGenresAlbumPayload';
  /** Reads a single `Album` that is related to this `GenresAlbum`. */
  albumByAlbumId?: Maybe<Album>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Genre` that is related to this `GenresAlbum`. */
  genreByGenreId?: Maybe<Genre>;
  /** The `GenresAlbum` that was created by this mutation. */
  genresAlbum?: Maybe<GenresAlbum>;
  /** An edge for our `GenresAlbum`. May be used by Relay 1. */
  genresAlbumEdge?: Maybe<GenresAlbumsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `GenresAlbum` mutation. */
export type CreateGenresAlbumPayloadGenresAlbumEdgeArgs = {
  orderBy?: InputMaybe<Array<GenresAlbumsOrderBy>>;
};

/** All input for the create `LastPlayedTrack` mutation. */
export type CreateLastPlayedTrackInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `LastPlayedTrack` to be created by this mutation. */
  lastPlayedTrack: LastPlayedTrackInput;
};

/** The output of our create `LastPlayedTrack` mutation. */
export type CreateLastPlayedTrackPayload = {
  __typename?: 'CreateLastPlayedTrackPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LastPlayedTrack` that was created by this mutation. */
  lastPlayedTrack?: Maybe<LastPlayedTrack>;
  /** An edge for our `LastPlayedTrack`. May be used by Relay 1. */
  lastPlayedTrackEdge?: Maybe<LastPlayedTracksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `LastPlayedTrack`. */
  trackByTrackId?: Maybe<Track>;
};

/** The output of our create `LastPlayedTrack` mutation. */
export type CreateLastPlayedTrackPayloadLastPlayedTrackEdgeArgs = {
  orderBy?: InputMaybe<Array<LastPlayedTracksOrderBy>>;
};

/** All input for the `createLike` mutation. */
export type CreateLikeInput = {
  actingArtistId?: InputMaybe<Scalars['UUID']>;
  albumId?: InputMaybe<Scalars['UUID']>;
  artistId?: InputMaybe<Scalars['UUID']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  likedUserId?: InputMaybe<Scalars['UUID']>;
  playlistId?: InputMaybe<Scalars['UUID']>;
  trackId?: InputMaybe<Scalars['UUID']>;
};

/** The output of our `createLike` mutation. */
export type CreateLikePayload = {
  __typename?: 'CreateLikePayload';
  /** Reads a single `Album` that is related to this `Like`. */
  albumByAlbumId?: Maybe<Album>;
  /** Reads a single `Artist` that is related to this `Like`. */
  artistByActingArtistId?: Maybe<Artist>;
  /** Reads a single `Artist` that is related to this `Like`. */
  artistByArtistId?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  like?: Maybe<Like>;
  /** An edge for our `Like`. May be used by Relay 1. */
  likeEdge?: Maybe<LikesEdge>;
  /** Reads a single `Playlist` that is related to this `Like`. */
  playlistByPlaylistId?: Maybe<Playlist>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `Like`. */
  trackByTrackId?: Maybe<Track>;
  /** Reads a single `User` that is related to this `Like`. */
  userByLikedUserId?: Maybe<User>;
  /** Reads a single `User` that is related to this `Like`. */
  userByUserId?: Maybe<User>;
};

/** The output of our `createLike` mutation. */
export type CreateLikePayloadLikeEdgeArgs = {
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** All input for the create `ListenerPackage` mutation. */
export type CreateListenerPackageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ListenerPackage` to be created by this mutation. */
  listenerPackage: ListenerPackageInput;
};

/** The output of our create `ListenerPackage` mutation. */
export type CreateListenerPackagePayload = {
  __typename?: 'CreateListenerPackagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ListenerPackage` that was created by this mutation. */
  listenerPackage?: Maybe<ListenerPackage>;
  /** An edge for our `ListenerPackage`. May be used by Relay 1. */
  listenerPackageEdge?: Maybe<ListenerPackagesEdge>;
  /** Reads a single `ListenerPackageType` that is related to this `ListenerPackage`. */
  listenerPackageTypeByPackageType?: Maybe<ListenerPackageType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ListenerPackage`. */
  userByUserId?: Maybe<User>;
};

/** The output of our create `ListenerPackage` mutation. */
export type CreateListenerPackagePayloadListenerPackageEdgeArgs = {
  orderBy?: InputMaybe<Array<ListenerPackagesOrderBy>>;
};

/** All input for the `createPlaylist` mutation. */
export type CreatePlaylistInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  img?: InputMaybe<Scalars['String']>;
  private?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

/** The output of our `createPlaylist` mutation. */
export type CreatePlaylistPayload = {
  __typename?: 'CreatePlaylistPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  playlist?: Maybe<Playlist>;
  /** An edge for our `Playlist`. May be used by Relay 1. */
  playlistEdge?: Maybe<PlaylistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByAuthor?: Maybe<User>;
};

/** The output of our `createPlaylist` mutation. */
export type CreatePlaylistPayloadPlaylistEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** All input for the create `PlaylistsTrack` mutation. */
export type CreatePlaylistsTrackInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `PlaylistsTrack` to be created by this mutation. */
  playlistsTrack: PlaylistsTrackInput;
};

/** The output of our create `PlaylistsTrack` mutation. */
export type CreatePlaylistsTrackPayload = {
  __typename?: 'CreatePlaylistsTrackPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Playlist` that is related to this `PlaylistsTrack`. */
  playlistByPlaylistId?: Maybe<Playlist>;
  /** The `PlaylistsTrack` that was created by this mutation. */
  playlistsTrack?: Maybe<PlaylistsTrack>;
  /** An edge for our `PlaylistsTrack`. May be used by Relay 1. */
  playlistsTrackEdge?: Maybe<PlaylistsTracksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `PlaylistsTrack`. */
  trackByTrackId?: Maybe<Track>;
};

/** The output of our create `PlaylistsTrack` mutation. */
export type CreatePlaylistsTrackPayloadPlaylistsTrackEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsTracksOrderBy>>;
};

/** All input for the create `Price` mutation. */
export type CreatePriceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Price` to be created by this mutation. */
  price: PriceInput;
};

/** The output of our create `Price` mutation. */
export type CreatePricePayload = {
  __typename?: 'CreatePricePayload';
  /** Reads a single `Artist` that is related to this `Price`. */
  artistByCreatorId?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Country` that is related to this `Price`. */
  countryByCountry?: Maybe<Country>;
  /** The `Price` that was created by this mutation. */
  price?: Maybe<Price>;
  /** An edge for our `Price`. May be used by Relay 1. */
  priceEdge?: Maybe<PricesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `Price`. */
  trackByTrackId?: Maybe<Track>;
};

/** The output of our create `Price` mutation. */
export type CreatePricePayloadPriceEdgeArgs = {
  orderBy?: InputMaybe<Array<PricesOrderBy>>;
};

/** All input for the create `ProfilePublic` mutation. */
export type CreateProfilePublicInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ProfilePublic` to be created by this mutation. */
  profilePublic: ProfilePublicInput;
};

/** The output of our create `ProfilePublic` mutation. */
export type CreateProfilePublicPayload = {
  __typename?: 'CreateProfilePublicPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProfilePublic` that was created by this mutation. */
  profilePublic?: Maybe<ProfilePublic>;
  /** An edge for our `ProfilePublic`. May be used by Relay 1. */
  profilePublicEdge?: Maybe<ProfilePublicsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ProfilePublic`. */
  userByUserId?: Maybe<User>;
};

/** The output of our create `ProfilePublic` mutation. */
export type CreateProfilePublicPayloadProfilePublicEdgeArgs = {
  orderBy?: InputMaybe<Array<ProfilePublicsOrderBy>>;
};

/** All input for the create `RecentlyPlayed` mutation. */
export type CreateRecentlyPlayedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `RecentlyPlayed` to be created by this mutation. */
  recentlyPlayed: RecentlyPlayedInput;
};

/** The output of our create `RecentlyPlayed` mutation. */
export type CreateRecentlyPlayedPayload = {
  __typename?: 'CreateRecentlyPlayedPayload';
  /** Reads a single `Album` that is related to this `RecentlyPlayed`. */
  albumByAlbumId?: Maybe<Album>;
  /** Reads a single `Artist` that is related to this `RecentlyPlayed`. */
  artistByArtistId?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Playlist` that is related to this `RecentlyPlayed`. */
  playlistByPlaylistId?: Maybe<Playlist>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RecentlyPlayed` that was created by this mutation. */
  recentlyPlayed?: Maybe<RecentlyPlayed>;
  /** An edge for our `RecentlyPlayed`. May be used by Relay 1. */
  recentlyPlayedEdge?: Maybe<RecentlyPlayedsEdge>;
  /** Reads a single `User` that is related to this `RecentlyPlayed`. */
  userByUserId?: Maybe<User>;
};

/** The output of our create `RecentlyPlayed` mutation. */
export type CreateRecentlyPlayedPayloadRecentlyPlayedEdgeArgs = {
  orderBy?: InputMaybe<Array<RecentlyPlayedsOrderBy>>;
};

/** All input for the `createRoyalties` mutation. */
export type CreateRoyaltiesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  track: Scalars['UUID'];
  userPercentages: Array<InputMaybe<UserPercentageInput>>;
};

/** The output of our `createRoyalties` mutation. */
export type CreateRoyaltiesPayload = {
  __typename?: 'CreateRoyaltiesPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `createStreamsHistory` mutation. */
export type CreateStreamsHistoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  rows: Array<InputMaybe<StreamHistoryInputRecordInput>>;
};

/** The output of our `createStreamsHistory` mutation. */
export type CreateStreamsHistoryPayload = {
  __typename?: 'CreateStreamsHistoryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Track` mutation. */
export type CreateTrackInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Track` to be created by this mutation. */
  track: TrackInput;
};

/** The output of our create `Track` mutation. */
export type CreateTrackPayload = {
  __typename?: 'CreateTrackPayload';
  /** Reads a single `Album` that is related to this `Track`. */
  albumByAlbum?: Maybe<Album>;
  /** Reads a single `Artist` that is related to this `Track`. */
  artistByArtist?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Genre` that is related to this `Track`. */
  genreByGenre?: Maybe<Genre>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `RecordLabel` that is related to this `Track`. */
  recordLabelByPublisher?: Maybe<RecordLabel>;
  /** The `Track` that was created by this mutation. */
  track?: Maybe<Track>;
  /** An edge for our `Track`. May be used by Relay 1. */
  trackEdge?: Maybe<TracksEdge>;
};

/** The output of our create `Track` mutation. */
export type CreateTrackPayloadTrackEdgeArgs = {
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** All input for the create `TracksGenre` mutation. */
export type CreateTracksGenreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TracksGenre` to be created by this mutation. */
  tracksGenre: TracksGenreInput;
};

/** The output of our create `TracksGenre` mutation. */
export type CreateTracksGenrePayload = {
  __typename?: 'CreateTracksGenrePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Genre` that is related to this `TracksGenre`. */
  genreByGenreId?: Maybe<Genre>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `TracksGenre`. */
  trackByTrackId?: Maybe<Track>;
  /** The `TracksGenre` that was created by this mutation. */
  tracksGenre?: Maybe<TracksGenre>;
  /** An edge for our `TracksGenre`. May be used by Relay 1. */
  tracksGenreEdge?: Maybe<TracksGenresEdge>;
};

/** The output of our create `TracksGenre` mutation. */
export type CreateTracksGenrePayloadTracksGenreEdgeArgs = {
  orderBy?: InputMaybe<Array<TracksGenresOrderBy>>;
};

/** All input for the create `UsersQueue` mutation. */
export type CreateUsersQueueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `UsersQueue` to be created by this mutation. */
  usersQueue: UsersQueueInput;
};

/** The output of our create `UsersQueue` mutation. */
export type CreateUsersQueuePayload = {
  __typename?: 'CreateUsersQueuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `UsersQueue`. */
  trackByTrackId?: Maybe<Track>;
  /** Reads a single `User` that is related to this `UsersQueue`. */
  userByUserId?: Maybe<User>;
  /** The `UsersQueue` that was created by this mutation. */
  usersQueue?: Maybe<UsersQueue>;
  /** An edge for our `UsersQueue`. May be used by Relay 1. */
  usersQueueEdge?: Maybe<UsersQueuesEdge>;
};

/** The output of our create `UsersQueue` mutation. */
export type CreateUsersQueuePayloadUsersQueueEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersQueuesOrderBy>>;
};

/** A connection to a list of `Currency` values. */
export type CurrenciesConnection = {
  __typename?: 'CurrenciesConnection';
  /** A list of edges which contains the `Currency` and cursor to aid in pagination. */
  edges: Array<CurrenciesEdge>;
  /** A list of `Currency` objects. */
  nodes: Array<Maybe<Currency>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Currency` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Currency` edge in the connection. */
export type CurrenciesEdge = {
  __typename?: 'CurrenciesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Currency` at the end of the edge. */
  node?: Maybe<Currency>;
};

/** Methods to use when ordering `Currency`. */
export enum CurrenciesOrderBy {
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SymbolAsc = 'SYMBOL_ASC',
  SymbolDesc = 'SYMBOL_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** `Currency` table */
export type Currency = Node & {
  __typename?: 'Currency';
  /** Reads and enables pagination through a set of `ArtistSubscriptionType`. */
  artistSubscriptionTypesByCurrencyId: ArtistSubscriptionTypesConnection;
  /** A three-letter uppercase (eg: EUR) for the `Currency` */
  code: Scalars['String'];
  /** The currency country */
  country: Scalars['UUID'];
  /** Reads a single `Country` that is related to this `Currency`. */
  countryByCountry?: Maybe<Country>;
  /** Defines the datetime with time zone when when `Currency` was first created */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Unique identifier for row */
  id: Scalars['UUID'];
  /** Reads and enables pagination through a set of `ListenerPackageType`. */
  listenerPackageTypesByCurrencyId: ListenerPackageTypesConnection;
  /** The primary unique identifier for the currency */
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Symbol for the `Currency` (eg: €) */
  symbol?: Maybe<Scalars['String']>;
  /** Defines the datetime with time zone when when `Currency` was last updated */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** `Currency` table */
export type CurrencyArtistSubscriptionTypesByCurrencyIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionTypeCondition>;
  filter?: InputMaybe<ArtistSubscriptionTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionTypesOrderBy>>;
};

/** `Currency` table */
export type CurrencyListenerPackageTypesByCurrencyIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ListenerPackageTypeCondition>;
  filter?: InputMaybe<ListenerPackageTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ListenerPackageTypesOrderBy>>;
};

/**
 * A condition to be used against `Currency` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CurrencyCondition = {
  /** Checks for equality with the object’s `code` field. */
  code?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `country` field. */
  country?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `symbol` field. */
  symbol?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `Currency` object types. All fields are combined with a logical ‘and.’ */
export type CurrencyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CurrencyFilter>>;
  /** Filter by the object’s `code` field. */
  code?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CurrencyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CurrencyFilter>>;
  /** Filter by the object’s `symbol` field. */
  symbol?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Datetime']>;
};

export type DeepLinkPayload = {
  __typename?: 'DeepLinkPayload';
  link?: Maybe<Scalars['String']>;
};

/** All input for the `deleteAlbumById` mutation. */
export type DeleteAlbumByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteAlbumImage` mutation. */
export type DeleteAlbumImageInput = {
  album?: InputMaybe<Scalars['UUID']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `deleteAlbumImage` mutation. */
export type DeleteAlbumImagePayload = {
  __typename?: 'DeleteAlbumImagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteAlbum` mutation. */
export type DeleteAlbumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Album` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Album` mutation. */
export type DeleteAlbumPayload = {
  __typename?: 'DeleteAlbumPayload';
  /** The `Album` that was deleted by this mutation. */
  album?: Maybe<Album>;
  /** An edge for our `Album`. May be used by Relay 1. */
  albumEdge?: Maybe<AlbumsEdge>;
  /** Reads a single `Artist` that is related to this `Album`. */
  artistByArtist?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedAlbumId?: Maybe<Scalars['ID']>;
  /** Reads a single `Genre` that is related to this `Album`. */
  genreByGenre?: Maybe<Genre>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `RecordLabel` that is related to this `Album`. */
  recordLabelByPublisher?: Maybe<RecordLabel>;
};

/** The output of our delete `Album` mutation. */
export type DeleteAlbumPayloadAlbumEdgeArgs = {
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** All input for the `deleteAllNotifications` mutation. */
export type DeleteAllNotificationsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `deleteAllNotifications` mutation. */
export type DeleteAllNotificationsPayload = {
  __typename?: 'DeleteAllNotificationsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteArtistByBlarecastUrl` mutation. */
export type DeleteArtistByBlarecastUrlInput = {
  /** The `Artist` internal url */
  blarecastUrl: Scalars['String'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** All input for the `deleteArtistById` mutation. */
export type DeleteArtistByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique identifier for row */
  id: Scalars['UUID'];
};

/** All input for the `deleteArtist` mutation. */
export type DeleteArtistInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Artist` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteArtistMemberByArtistIdAndUserId` mutation. */
export type DeleteArtistMemberByArtistIdAndUserIdInput = {
  /** The id of the `Artist` this member belongs to */
  artistId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The actual `User` behind the artist member */
  userId: Scalars['UUID'];
};

/** All input for the `deleteArtistMemberById` mutation. */
export type DeleteArtistMemberByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteArtistMember` mutation. */
export type DeleteArtistMemberInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ArtistMember` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `ArtistMember` mutation. */
export type DeleteArtistMemberPayload = {
  __typename?: 'DeleteArtistMemberPayload';
  /** Reads a single `Artist` that is related to this `ArtistMember`. */
  artistByArtistId?: Maybe<Artist>;
  /** The `ArtistMember` that was deleted by this mutation. */
  artistMember?: Maybe<ArtistMember>;
  /** An edge for our `ArtistMember`. May be used by Relay 1. */
  artistMemberEdge?: Maybe<ArtistMembersEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedArtistMemberId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ArtistMember`. */
  userByUserId?: Maybe<User>;
};

/** The output of our delete `ArtistMember` mutation. */
export type DeleteArtistMemberPayloadArtistMemberEdgeArgs = {
  orderBy?: InputMaybe<Array<ArtistMembersOrderBy>>;
};

/** The output of our delete `Artist` mutation. */
export type DeleteArtistPayload = {
  __typename?: 'DeleteArtistPayload';
  /** The `Artist` that was deleted by this mutation. */
  artist?: Maybe<Artist>;
  /** An edge for our `Artist`. May be used by Relay 1. */
  artistEdge?: Maybe<ArtistsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedArtistId?: Maybe<Scalars['ID']>;
  /** Reads a single `Genre` that is related to this `Artist`. */
  genreByGenre?: Maybe<Genre>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Artist`. */
  userByUserId?: Maybe<User>;
};

/** The output of our delete `Artist` mutation. */
export type DeleteArtistPayloadArtistEdgeArgs = {
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** All input for the `deleteAutoGeneratedQueueById` mutation. */
export type DeleteAutoGeneratedQueueByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteAutoGeneratedQueue` mutation. */
export type DeleteAutoGeneratedQueueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AutoGeneratedQueue` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `AutoGeneratedQueue` mutation. */
export type DeleteAutoGeneratedQueuePayload = {
  __typename?: 'DeleteAutoGeneratedQueuePayload';
  /** The `AutoGeneratedQueue` that was deleted by this mutation. */
  autoGeneratedQueue?: Maybe<AutoGeneratedQueue>;
  /** An edge for our `AutoGeneratedQueue`. May be used by Relay 1. */
  autoGeneratedQueueEdge?: Maybe<AutoGeneratedQueuesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedAutoGeneratedQueueId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `AutoGeneratedQueue`. */
  trackByTrackId?: Maybe<Track>;
  /** Reads a single `User` that is related to this `AutoGeneratedQueue`. */
  userByUserId?: Maybe<User>;
};

/** The output of our delete `AutoGeneratedQueue` mutation. */
export type DeleteAutoGeneratedQueuePayloadAutoGeneratedQueueEdgeArgs = {
  orderBy?: InputMaybe<Array<AutoGeneratedQueuesOrderBy>>;
};

/** All input for the `deleteCountriesTrackByTrackIdAndCountryId` mutation. */
export type DeleteCountriesTrackByTrackIdAndCountryIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The primary unique identifier for the country */
  countryId: Scalars['UUID'];
  /** The primary unique identifier for the track */
  trackId: Scalars['UUID'];
};

/** The output of our delete `CountriesTrack` mutation. */
export type DeleteCountriesTrackPayload = {
  __typename?: 'DeleteCountriesTrackPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CountriesTrack` that was deleted by this mutation. */
  countriesTrack?: Maybe<CountriesTrack>;
  /** An edge for our `CountriesTrack`. May be used by Relay 1. */
  countriesTrackEdge?: Maybe<CountriesTracksEdge>;
  /** Reads a single `Country` that is related to this `CountriesTrack`. */
  countryByCountryId?: Maybe<Country>;
  deletedCountriesTrackId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `CountriesTrack`. */
  trackByTrackId?: Maybe<Track>;
};

/** The output of our delete `CountriesTrack` mutation. */
export type DeleteCountriesTrackPayloadCountriesTrackEdgeArgs = {
  orderBy?: InputMaybe<Array<CountriesTracksOrderBy>>;
};

/** All input for the `deleteCoverImage` mutation. */
export type DeleteCoverImageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `deleteCoverImage` mutation. */
export type DeleteCoverImagePayload = {
  __typename?: 'DeleteCoverImagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteEarningsHistoryByDate` mutation. */
export type DeleteEarningsHistoryByDateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  date: Scalars['Date'];
};

/** The output of our `deleteEarningsHistoryByDate` mutation. */
export type DeleteEarningsHistoryByDatePayload = {
  __typename?: 'DeleteEarningsHistoryByDatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteEventById` mutation. */
export type DeleteEventByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteEvent` mutation. */
export type DeleteEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Event` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Event` mutation. */
export type DeleteEventPayload = {
  __typename?: 'DeleteEventPayload';
  /** Reads a single `Artist` that is related to this `Event`. */
  artistByArtistId?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedEventId?: Maybe<Scalars['ID']>;
  /** The `Event` that was deleted by this mutation. */
  event?: Maybe<Event>;
  /** An edge for our `Event`. May be used by Relay 1. */
  eventEdge?: Maybe<EventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `Event` mutation. */
export type DeleteEventPayloadEventEdgeArgs = {
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
};

/** All input for the `deleteExtraArtistById` mutation. */
export type DeleteExtraArtistByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteExtraArtist` mutation. */
export type DeleteExtraArtistInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ExtraArtist` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `ExtraArtist` mutation. */
export type DeleteExtraArtistPayload = {
  __typename?: 'DeleteExtraArtistPayload';
  /** Reads a single `Album` that is related to this `ExtraArtist`. */
  albumByAlbum?: Maybe<Album>;
  /** Reads a single `Artist` that is related to this `ExtraArtist`. */
  artistByArtist?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedExtraArtistId?: Maybe<Scalars['ID']>;
  /** The `ExtraArtist` that was deleted by this mutation. */
  extraArtist?: Maybe<ExtraArtist>;
  /** An edge for our `ExtraArtist`. May be used by Relay 1. */
  extraArtistEdge?: Maybe<ExtraArtistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `ExtraArtist` mutation. */
export type DeleteExtraArtistPayloadExtraArtistEdgeArgs = {
  orderBy?: InputMaybe<Array<ExtraArtistsOrderBy>>;
};

/** All input for the `deleteFeaturedArtistById` mutation. */
export type DeleteFeaturedArtistByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteFeaturedArtist` mutation. */
export type DeleteFeaturedArtistInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FeaturedArtist` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `FeaturedArtist` mutation. */
export type DeleteFeaturedArtistPayload = {
  __typename?: 'DeleteFeaturedArtistPayload';
  /** Reads a single `Artist` that is related to this `FeaturedArtist`. */
  artistByArtist?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedFeaturedArtistId?: Maybe<Scalars['ID']>;
  /** The `FeaturedArtist` that was deleted by this mutation. */
  featuredArtist?: Maybe<FeaturedArtist>;
  /** An edge for our `FeaturedArtist`. May be used by Relay 1. */
  featuredArtistEdge?: Maybe<FeaturedArtistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `FeaturedArtist`. */
  trackByTrack?: Maybe<Track>;
};

/** The output of our delete `FeaturedArtist` mutation. */
export type DeleteFeaturedArtistPayloadFeaturedArtistEdgeArgs = {
  orderBy?: InputMaybe<Array<FeaturedArtistsOrderBy>>;
};

/** All input for the `deleteFirebaseTokenById` mutation. */
export type DeleteFirebaseTokenByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteFirebaseTokenByToken` mutation. */
export type DeleteFirebaseTokenByTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Firebase token value */
  token: Scalars['String'];
};

/** All input for the `deleteFirebaseTokenByUserIdAndDevice` mutation. */
export type DeleteFirebaseTokenByUserIdAndDeviceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The device type this `FirebaseToken` is bound to */
  device: DeviceType;
  /** The primary unique identifier for the user */
  userId: Scalars['UUID'];
};

/** All input for the `deleteFirebaseToken` mutation. */
export type DeleteFirebaseTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FirebaseToken` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `FirebaseToken` mutation. */
export type DeleteFirebaseTokenPayload = {
  __typename?: 'DeleteFirebaseTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedFirebaseTokenId?: Maybe<Scalars['ID']>;
  /** The `FirebaseToken` that was deleted by this mutation. */
  firebaseToken?: Maybe<FirebaseToken>;
  /** An edge for our `FirebaseToken`. May be used by Relay 1. */
  firebaseTokenEdge?: Maybe<FirebaseTokensEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `FirebaseToken`. */
  userByUserId?: Maybe<User>;
};

/** The output of our delete `FirebaseToken` mutation. */
export type DeleteFirebaseTokenPayloadFirebaseTokenEdgeArgs = {
  orderBy?: InputMaybe<Array<FirebaseTokensOrderBy>>;
};

/** All input for the `deleteFlywaySchemaHistoryByInstalledRank` mutation. */
export type DeleteFlywaySchemaHistoryByInstalledRankInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  installedRank: Scalars['Int'];
};

/** All input for the `deleteFlywaySchemaHistory` mutation. */
export type DeleteFlywaySchemaHistoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FlywaySchemaHistory` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `FlywaySchemaHistory` mutation. */
export type DeleteFlywaySchemaHistoryPayload = {
  __typename?: 'DeleteFlywaySchemaHistoryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedFlywaySchemaHistoryId?: Maybe<Scalars['ID']>;
  /** The `FlywaySchemaHistory` that was deleted by this mutation. */
  flywaySchemaHistory?: Maybe<FlywaySchemaHistory>;
  /** An edge for our `FlywaySchemaHistory`. May be used by Relay 1. */
  flywaySchemaHistoryEdge?: Maybe<FlywaySchemaHistoriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `FlywaySchemaHistory` mutation. */
export type DeleteFlywaySchemaHistoryPayloadFlywaySchemaHistoryEdgeArgs = {
  orderBy?: InputMaybe<Array<FlywaySchemaHistoriesOrderBy>>;
};

/** All input for the `deleteLastPlayedTrackById` mutation. */
export type DeleteLastPlayedTrackByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** A `LastPlayedTrack` unique identifier */
  id: Scalars['UUID'];
};

/** All input for the `deleteLastPlayedTrackByTrackIdAndUserId` mutation. */
export type DeleteLastPlayedTrackByTrackIdAndUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Track` that was played` */
  trackId: Scalars['UUID'];
  /** The `User` that played the `Track` */
  userId: Scalars['UUID'];
};

/** All input for the `deleteLastPlayedTrack` mutation. */
export type DeleteLastPlayedTrackInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LastPlayedTrack` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `LastPlayedTrack` mutation. */
export type DeleteLastPlayedTrackPayload = {
  __typename?: 'DeleteLastPlayedTrackPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedLastPlayedTrackId?: Maybe<Scalars['ID']>;
  /** The `LastPlayedTrack` that was deleted by this mutation. */
  lastPlayedTrack?: Maybe<LastPlayedTrack>;
  /** An edge for our `LastPlayedTrack`. May be used by Relay 1. */
  lastPlayedTrackEdge?: Maybe<LastPlayedTracksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `LastPlayedTrack`. */
  trackByTrackId?: Maybe<Track>;
};

/** The output of our delete `LastPlayedTrack` mutation. */
export type DeleteLastPlayedTrackPayloadLastPlayedTrackEdgeArgs = {
  orderBy?: InputMaybe<Array<LastPlayedTracksOrderBy>>;
};

/** All input for the `deleteLikeById` mutation. */
export type DeleteLikeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteLike` mutation. */
export type DeleteLikeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Like` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Like` mutation. */
export type DeleteLikePayload = {
  __typename?: 'DeleteLikePayload';
  /** Reads a single `Album` that is related to this `Like`. */
  albumByAlbumId?: Maybe<Album>;
  /** Reads a single `Artist` that is related to this `Like`. */
  artistByActingArtistId?: Maybe<Artist>;
  /** Reads a single `Artist` that is related to this `Like`. */
  artistByArtistId?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedLikeId?: Maybe<Scalars['ID']>;
  /** The `Like` that was deleted by this mutation. */
  like?: Maybe<Like>;
  /** An edge for our `Like`. May be used by Relay 1. */
  likeEdge?: Maybe<LikesEdge>;
  /** Reads a single `Playlist` that is related to this `Like`. */
  playlistByPlaylistId?: Maybe<Playlist>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `Like`. */
  trackByTrackId?: Maybe<Track>;
  /** Reads a single `User` that is related to this `Like`. */
  userByLikedUserId?: Maybe<User>;
  /** Reads a single `User` that is related to this `Like`. */
  userByUserId?: Maybe<User>;
};

/** The output of our delete `Like` mutation. */
export type DeleteLikePayloadLikeEdgeArgs = {
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** All input for the `deleteLikes` mutation. */
export type DeleteLikesInput = {
  actingArtist?: InputMaybe<Scalars['UUID']>;
  albumLike?: InputMaybe<Scalars['UUID']>;
  artistLike?: InputMaybe<Scalars['UUID']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  playlistLike?: InputMaybe<Scalars['UUID']>;
  trackLike?: InputMaybe<Scalars['UUID']>;
  userLike?: InputMaybe<Scalars['UUID']>;
};

/** The output of our `deleteLikes` mutation. */
export type DeleteLikesPayload = {
  __typename?: 'DeleteLikesPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  likes?: Maybe<Array<Maybe<Like>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteNotificationById` mutation. */
export type DeleteNotificationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteNotification` mutation. */
export type DeleteNotificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Notification` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Notification` mutation. */
export type DeleteNotificationPayload = {
  __typename?: 'DeleteNotificationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedNotificationId?: Maybe<Scalars['ID']>;
  /** The `Notification` that was deleted by this mutation. */
  notification?: Maybe<Notification>;
  /** An edge for our `Notification`. May be used by Relay 1. */
  notificationEdge?: Maybe<NotificationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Notification`. */
  userByUserId?: Maybe<User>;
};

/** The output of our delete `Notification` mutation. */
export type DeleteNotificationPayloadNotificationEdgeArgs = {
  orderBy?: InputMaybe<Array<NotificationsOrderBy>>;
};

/** All input for the `deletePlayHistoryById` mutation. */
export type DeletePlayHistoryByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deletePlayHistory` mutation. */
export type DeletePlayHistoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PlayHistory` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `PlayHistory` mutation. */
export type DeletePlayHistoryPayload = {
  __typename?: 'DeletePlayHistoryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedPlayHistoryId?: Maybe<Scalars['ID']>;
  /** The `PlayHistory` that was deleted by this mutation. */
  playHistory?: Maybe<PlayHistory>;
  /** An edge for our `PlayHistory`. May be used by Relay 1. */
  playHistoryEdge?: Maybe<PlayHistoriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `PlayHistory`. */
  trackByTrackId?: Maybe<Track>;
  /** Reads a single `User` that is related to this `PlayHistory`. */
  userByUserId?: Maybe<User>;
};

/** The output of our delete `PlayHistory` mutation. */
export type DeletePlayHistoryPayloadPlayHistoryEdgeArgs = {
  orderBy?: InputMaybe<Array<PlayHistoriesOrderBy>>;
};

/** All input for the `deletePlaylistById` mutation. */
export type DeletePlaylistByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deletePlaylistImage` mutation. */
export type DeletePlaylistImageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  playlist?: InputMaybe<Scalars['UUID']>;
};

/** The output of our `deletePlaylistImage` mutation. */
export type DeletePlaylistImagePayload = {
  __typename?: 'DeletePlaylistImagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deletePlaylist` mutation. */
export type DeletePlaylistInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Playlist` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Playlist` mutation. */
export type DeletePlaylistPayload = {
  __typename?: 'DeletePlaylistPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedPlaylistId?: Maybe<Scalars['ID']>;
  /** The `Playlist` that was deleted by this mutation. */
  playlist?: Maybe<Playlist>;
  /** An edge for our `Playlist`. May be used by Relay 1. */
  playlistEdge?: Maybe<PlaylistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByAuthor?: Maybe<User>;
};

/** The output of our delete `Playlist` mutation. */
export type DeletePlaylistPayloadPlaylistEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** All input for the `deletePlaylistsTrackById` mutation. */
export type DeletePlaylistsTrackByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deletePlaylistsTrackByTrackIdAndPlaylistId` mutation. */
export type DeletePlaylistsTrackByTrackIdAndPlaylistIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The id of the `Playlist` that the collection is part of */
  playlistId: Scalars['UUID'];
  /** The id of the `Track` that is part of `Playlist` */
  trackId: Scalars['UUID'];
};

/** All input for the `deletePlaylistsTrack` mutation. */
export type DeletePlaylistsTrackInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PlaylistsTrack` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `PlaylistsTrack` mutation. */
export type DeletePlaylistsTrackPayload = {
  __typename?: 'DeletePlaylistsTrackPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedPlaylistsTrackId?: Maybe<Scalars['ID']>;
  /** Reads a single `Playlist` that is related to this `PlaylistsTrack`. */
  playlistByPlaylistId?: Maybe<Playlist>;
  /** The `PlaylistsTrack` that was deleted by this mutation. */
  playlistsTrack?: Maybe<PlaylistsTrack>;
  /** An edge for our `PlaylistsTrack`. May be used by Relay 1. */
  playlistsTrackEdge?: Maybe<PlaylistsTracksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `PlaylistsTrack`. */
  trackByTrackId?: Maybe<Track>;
};

/** The output of our delete `PlaylistsTrack` mutation. */
export type DeletePlaylistsTrackPayloadPlaylistsTrackEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsTracksOrderBy>>;
};

/** All input for the `deletePriceById` mutation. */
export type DeletePriceByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deletePrice` mutation. */
export type DeletePriceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Price` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Price` mutation. */
export type DeletePricePayload = {
  __typename?: 'DeletePricePayload';
  /** Reads a single `Artist` that is related to this `Price`. */
  artistByCreatorId?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Country` that is related to this `Price`. */
  countryByCountry?: Maybe<Country>;
  deletedPriceId?: Maybe<Scalars['ID']>;
  /** The `Price` that was deleted by this mutation. */
  price?: Maybe<Price>;
  /** An edge for our `Price`. May be used by Relay 1. */
  priceEdge?: Maybe<PricesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `Price`. */
  trackByTrackId?: Maybe<Track>;
};

/** The output of our delete `Price` mutation. */
export type DeletePricePayloadPriceEdgeArgs = {
  orderBy?: InputMaybe<Array<PricesOrderBy>>;
};

/** All input for the `deleteProfileImage` mutation. */
export type DeleteProfileImageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `deleteProfileImage` mutation. */
export type DeleteProfileImagePayload = {
  __typename?: 'DeleteProfileImagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteProfilePublicByBlarecastUrl` mutation. */
export type DeleteProfilePublicByBlarecastUrlInput = {
  /** A unique url that identifies a `User` */
  blarecastUrl: Scalars['String'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** All input for the `deleteProfilePublicByUserId` mutation. */
export type DeleteProfilePublicByUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `User` unique identifier */
  userId: Scalars['UUID'];
};

/** All input for the `deleteProfilePublic` mutation. */
export type DeleteProfilePublicInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProfilePublic` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `ProfilePublic` mutation. */
export type DeleteProfilePublicPayload = {
  __typename?: 'DeleteProfilePublicPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedProfilePublicId?: Maybe<Scalars['ID']>;
  /** The `ProfilePublic` that was deleted by this mutation. */
  profilePublic?: Maybe<ProfilePublic>;
  /** An edge for our `ProfilePublic`. May be used by Relay 1. */
  profilePublicEdge?: Maybe<ProfilePublicsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ProfilePublic`. */
  userByUserId?: Maybe<User>;
};

/** The output of our delete `ProfilePublic` mutation. */
export type DeleteProfilePublicPayloadProfilePublicEdgeArgs = {
  orderBy?: InputMaybe<Array<ProfilePublicsOrderBy>>;
};

/** All input for the `deleteSearchHistoryById` mutation. */
export type DeleteSearchHistoryByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteSearchHistory` mutation. */
export type DeleteSearchHistoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SearchHistory` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `SearchHistory` mutation. */
export type DeleteSearchHistoryPayload = {
  __typename?: 'DeleteSearchHistoryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedSearchHistoryId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SearchHistory` that was deleted by this mutation. */
  searchHistory?: Maybe<SearchHistory>;
  /** An edge for our `SearchHistory`. May be used by Relay 1. */
  searchHistoryEdge?: Maybe<SearchHistoriesEdge>;
  /** Reads a single `User` that is related to this `SearchHistory`. */
  userByUserId?: Maybe<User>;
};

/** The output of our delete `SearchHistory` mutation. */
export type DeleteSearchHistoryPayloadSearchHistoryEdgeArgs = {
  orderBy?: InputMaybe<Array<SearchHistoriesOrderBy>>;
};

/** All input for the `deleteStreamsHistoryByDate` mutation. */
export type DeleteStreamsHistoryByDateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  date: Scalars['Date'];
};

/** The output of our `deleteStreamsHistoryByDate` mutation. */
export type DeleteStreamsHistoryByDatePayload = {
  __typename?: 'DeleteStreamsHistoryByDatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteTrackById` mutation. */
export type DeleteTrackByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteTrackImage` mutation. */
export type DeleteTrackImageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  track?: InputMaybe<Scalars['UUID']>;
};

/** The output of our `deleteTrackImage` mutation. */
export type DeleteTrackImagePayload = {
  __typename?: 'DeleteTrackImagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteTrack` mutation. */
export type DeleteTrackInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Track` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Track` mutation. */
export type DeleteTrackPayload = {
  __typename?: 'DeleteTrackPayload';
  /** Reads a single `Album` that is related to this `Track`. */
  albumByAlbum?: Maybe<Album>;
  /** Reads a single `Artist` that is related to this `Track`. */
  artistByArtist?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTrackId?: Maybe<Scalars['ID']>;
  /** Reads a single `Genre` that is related to this `Track`. */
  genreByGenre?: Maybe<Genre>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `RecordLabel` that is related to this `Track`. */
  recordLabelByPublisher?: Maybe<RecordLabel>;
  /** The `Track` that was deleted by this mutation. */
  track?: Maybe<Track>;
  /** An edge for our `Track`. May be used by Relay 1. */
  trackEdge?: Maybe<TracksEdge>;
};

/** The output of our delete `Track` mutation. */
export type DeleteTrackPayloadTrackEdgeArgs = {
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** All input for the `deleteUser` mutation. */
export type DeleteUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `deleteUser` mutation. */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteUsersQueueById` mutation. */
export type DeleteUsersQueueByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteUsersQueue` mutation. */
export type DeleteUsersQueueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UsersQueue` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `UsersQueue` mutation. */
export type DeleteUsersQueuePayload = {
  __typename?: 'DeleteUsersQueuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUsersQueueId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `UsersQueue`. */
  trackByTrackId?: Maybe<Track>;
  /** Reads a single `User` that is related to this `UsersQueue`. */
  userByUserId?: Maybe<User>;
  /** The `UsersQueue` that was deleted by this mutation. */
  usersQueue?: Maybe<UsersQueue>;
  /** An edge for our `UsersQueue`. May be used by Relay 1. */
  usersQueueEdge?: Maybe<UsersQueuesEdge>;
};

/** The output of our delete `UsersQueue` mutation. */
export type DeleteUsersQueuePayloadUsersQueueEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersQueuesOrderBy>>;
};

export enum DeviceType {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB',
}

/** `Document` table */
export type Document = Node & {
  __typename?: 'Document';
  /** `Document` content as text or html */
  content: Scalars['String'];
  /** Defines the datetime with time zone when `Document` was first created */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** The `Document` unique identifier */
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** `Document` title as url */
  slug?: Maybe<Scalars['String']>;
  /** `Document` title */
  title: Scalars['String'];
  /** Defines the datetime with time zone when `Document` was last updated */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `Document` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type DocumentCondition = {
  /** Checks for equality with the object’s `content` field. */
  content?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `Document` object types. All fields are combined with a logical ‘and.’ */
export type DocumentFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DocumentFilter>>;
  /** Filter by the object’s `content` field. */
  content?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DocumentFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DocumentFilter>>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `Document` values. */
export type DocumentsConnection = {
  __typename?: 'DocumentsConnection';
  /** A list of edges which contains the `Document` and cursor to aid in pagination. */
  edges: Array<DocumentsEdge>;
  /** A list of `Document` objects. */
  nodes: Array<Maybe<Document>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Document` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Document` edge in the connection. */
export type DocumentsEdge = {
  __typename?: 'DocumentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Document` at the end of the edge. */
  node?: Maybe<Document>;
};

/** Methods to use when ordering `Document`. */
export enum DocumentsOrderBy {
  ContentAsc = 'CONTENT_ASC',
  ContentDesc = 'CONTENT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** An input for mutations affecting `EarningHistoryInputRecord` */
export type EarningHistoryInputRecordInput = {
  amount?: InputMaybe<Scalars['BigInt']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  royalty?: InputMaybe<Scalars['Float']>;
  trackId?: InputMaybe<Scalars['UUID']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A connection to a list of `EarningsHistory` values. */
export type EarningsHistoriesConnection = {
  __typename?: 'EarningsHistoriesConnection';
  /** A list of edges which contains the `EarningsHistory` and cursor to aid in pagination. */
  edges: Array<EarningsHistoriesEdge>;
  /** A list of `EarningsHistory` objects. */
  nodes: Array<Maybe<EarningsHistory>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EarningsHistory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `EarningsHistory` edge in the connection. */
export type EarningsHistoriesEdge = {
  __typename?: 'EarningsHistoriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `EarningsHistory` at the end of the edge. */
  node?: Maybe<EarningsHistory>;
};

/** Methods to use when ordering `EarningsHistory`. */
export enum EarningsHistoriesOrderBy {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoyaltyAsc = 'ROYALTY_ASC',
  RoyaltyDesc = 'ROYALTY_DESC',
  TrackIdAsc = 'TRACK_ID_ASC',
  TrackIdDesc = 'TRACK_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
}

/** `EarningsHistory` table */
export type EarningsHistory = Node & {
  __typename?: 'EarningsHistory';
  /** Defines the amount a `User` has earned */
  amount: Scalars['BigInt'];
  /** Defines the datetime with time zone when `EarningsHistory` was first created */
  createdAt: Scalars['Datetime'];
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Defines the `Royalty` defined at the time of earning */
  royalty: Scalars['BigFloat'];
  /** Reads a single `Track` that is related to this `EarningsHistory`. */
  trackByTrackId?: Maybe<Track>;
  /** Defines the `Track` that this earning is bound to */
  trackId?: Maybe<Scalars['UUID']>;
  /** Reads a single `User` that is related to this `EarningsHistory`. */
  userByUserId?: Maybe<User>;
  /** Defines the `User` that received the earning */
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `EarningsHistory` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EarningsHistoryCondition = {
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `royalty` field. */
  royalty?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `trackId` field. */
  trackId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `EarningsHistory` object types. All fields are combined with a logical ‘and.’ */
export type EarningsHistoryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EarningsHistoryFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EarningsHistoryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EarningsHistoryFilter>>;
};

export type EarningsHistoryRow = {
  __typename?: 'EarningsHistoryRow';
  amount?: Maybe<Scalars['BigInt']>;
  createdAt?: Maybe<Scalars['Date']>;
};

/** All input for the `emptyAutoGeneratedQueue` mutation. */
export type EmptyAutoGeneratedQueueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `emptyAutoGeneratedQueue` mutation. */
export type EmptyAutoGeneratedQueuePayload = {
  __typename?: 'EmptyAutoGeneratedQueuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `emptyUsersQueue` mutation. */
export type EmptyUsersQueueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `emptyUsersQueue` mutation. */
export type EmptyUsersQueuePayload = {
  __typename?: 'EmptyUsersQueuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** Event dates table */
export type Event = Node & {
  __typename?: 'Event';
  /** Reads a single `Artist` that is related to this `Event`. */
  artistByArtistId?: Maybe<Artist>;
  /** The primary unique identifier for the artist */
  artistId: Scalars['UUID'];
  /** When this event row was created */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Date and time when event starts */
  eventDate: Scalars['Datetime'];
  id: Scalars['UUID'];
  /** Name of the event */
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Place of the event */
  place: Scalars['String'];
};

/** A condition to be used against `Event` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type EventCondition = {
  /** Checks for equality with the object’s `artistId` field. */
  artistId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `eventDate` field. */
  eventDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `place` field. */
  place?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `Event` object types. All fields are combined with a logical ‘and.’ */
export type EventFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EventFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `eventDate` field. */
  eventDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EventFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EventFilter>>;
  /** Filter by the object’s `place` field. */
  place?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `Event` */
export type EventInput = {
  /** The primary unique identifier for the artist */
  artistId: Scalars['UUID'];
  /** When this event row was created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Date and time when event starts */
  eventDate: Scalars['Datetime'];
  id?: InputMaybe<Scalars['UUID']>;
  /** Name of the event */
  name: Scalars['String'];
  /** Place of the event */
  place: Scalars['String'];
};

/** Represents an update to a `Event`. Fields that are set will be updated. */
export type EventPatch = {
  /** The primary unique identifier for the artist */
  artistId?: InputMaybe<Scalars['UUID']>;
  /** When this event row was created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Date and time when event starts */
  eventDate?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** Name of the event */
  name?: InputMaybe<Scalars['String']>;
  /** Place of the event */
  place?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `Event` values. */
export type EventsConnection = {
  __typename?: 'EventsConnection';
  /** A list of edges which contains the `Event` and cursor to aid in pagination. */
  edges: Array<EventsEdge>;
  /** A list of `Event` objects. */
  nodes: Array<Maybe<Event>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Event` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Event` edge in the connection. */
export type EventsEdge = {
  __typename?: 'EventsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Event` at the end of the edge. */
  node?: Maybe<Event>;
};

/** Methods to use when ordering `Event`. */
export enum EventsOrderBy {
  ArtistIdAsc = 'ARTIST_ID_ASC',
  ArtistIdDesc = 'ARTIST_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EventDateAsc = 'EVENT_DATE_ASC',
  EventDateDesc = 'EVENT_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PlaceAsc = 'PLACE_ASC',
  PlaceDesc = 'PLACE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export type ExtraArtist = Node & {
  __typename?: 'ExtraArtist';
  album?: Maybe<Scalars['UUID']>;
  /** Reads a single `Album` that is related to this `ExtraArtist`. */
  albumByAlbum?: Maybe<Album>;
  artist?: Maybe<Scalars['UUID']>;
  /** Reads a single `Artist` that is related to this `ExtraArtist`. */
  artistByArtist?: Maybe<Artist>;
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  sort: Scalars['Int'];
};

/**
 * A condition to be used against `ExtraArtist` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ExtraArtistCondition = {
  /** Checks for equality with the object’s `album` field. */
  album?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `artist` field. */
  artist?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `sort` field. */
  sort?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `ExtraArtist` object types. All fields are combined with a logical ‘and.’ */
export type ExtraArtistFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ExtraArtistFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<ExtraArtistFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ExtraArtistFilter>>;
  /** Filter by the object’s `sort` field. */
  sort?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ExtraArtist` */
export type ExtraArtistInput = {
  album?: InputMaybe<Scalars['UUID']>;
  artist?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `ExtraArtist`. Fields that are set will be updated. */
export type ExtraArtistPatch = {
  album?: InputMaybe<Scalars['UUID']>;
  artist?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `ExtraArtist` values. */
export type ExtraArtistsConnection = {
  __typename?: 'ExtraArtistsConnection';
  /** A list of edges which contains the `ExtraArtist` and cursor to aid in pagination. */
  edges: Array<ExtraArtistsEdge>;
  /** A list of `ExtraArtist` objects. */
  nodes: Array<Maybe<ExtraArtist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ExtraArtist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ExtraArtist` edge in the connection. */
export type ExtraArtistsEdge = {
  __typename?: 'ExtraArtistsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ExtraArtist` at the end of the edge. */
  node?: Maybe<ExtraArtist>;
};

/** Methods to use when ordering `ExtraArtist`. */
export enum ExtraArtistsOrderBy {
  AlbumAsc = 'ALBUM_ASC',
  AlbumDesc = 'ALBUM_DESC',
  ArtistAsc = 'ARTIST_ASC',
  ArtistDesc = 'ARTIST_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SortAsc = 'SORT_ASC',
  SortDesc = 'SORT_DESC',
}

export type FeaturedArtist = Node & {
  __typename?: 'FeaturedArtist';
  artist?: Maybe<Scalars['UUID']>;
  /** Reads a single `Artist` that is related to this `FeaturedArtist`. */
  artistByArtist?: Maybe<Artist>;
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  sort: Scalars['Int'];
  track?: Maybe<Scalars['UUID']>;
  /** Reads a single `Track` that is related to this `FeaturedArtist`. */
  trackByTrack?: Maybe<Track>;
};

/**
 * A condition to be used against `FeaturedArtist` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FeaturedArtistCondition = {
  /** Checks for equality with the object’s `artist` field. */
  artist?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `sort` field. */
  sort?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `track` field. */
  track?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `FeaturedArtist` object types. All fields are combined with a logical ‘and.’ */
export type FeaturedArtistFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeaturedArtistFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<FeaturedArtistFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeaturedArtistFilter>>;
  /** Filter by the object’s `sort` field. */
  sort?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `FeaturedArtist` */
export type FeaturedArtistInput = {
  artist?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  sort?: InputMaybe<Scalars['Int']>;
  track?: InputMaybe<Scalars['UUID']>;
};

/** Represents an update to a `FeaturedArtist`. Fields that are set will be updated. */
export type FeaturedArtistPatch = {
  artist?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  sort?: InputMaybe<Scalars['Int']>;
  track?: InputMaybe<Scalars['UUID']>;
};

/** A connection to a list of `FeaturedArtist` values. */
export type FeaturedArtistsConnection = {
  __typename?: 'FeaturedArtistsConnection';
  /** A list of edges which contains the `FeaturedArtist` and cursor to aid in pagination. */
  edges: Array<FeaturedArtistsEdge>;
  /** A list of `FeaturedArtist` objects. */
  nodes: Array<Maybe<FeaturedArtist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeaturedArtist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `FeaturedArtist` edge in the connection. */
export type FeaturedArtistsEdge = {
  __typename?: 'FeaturedArtistsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FeaturedArtist` at the end of the edge. */
  node?: Maybe<FeaturedArtist>;
};

/** Methods to use when ordering `FeaturedArtist`. */
export enum FeaturedArtistsOrderBy {
  ArtistAsc = 'ARTIST_ASC',
  ArtistDesc = 'ARTIST_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SortAsc = 'SORT_ASC',
  SortDesc = 'SORT_DESC',
  TrackAsc = 'TRACK_ASC',
  TrackDesc = 'TRACK_DESC',
}

/** `FirebaseToken` table */
export type FirebaseToken = Node & {
  __typename?: 'FirebaseToken';
  /** When this users token was created */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** The device type this `FirebaseToken` is bound to */
  device: DeviceType;
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Firebase token value */
  token: Scalars['String'];
  /** Reads a single `User` that is related to this `FirebaseToken`. */
  userByUserId?: Maybe<User>;
  /** The primary unique identifier for the user */
  userId?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `FirebaseToken` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FirebaseTokenCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `device` field. */
  device?: InputMaybe<DeviceType>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `token` field. */
  token?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `FirebaseToken` object types. All fields are combined with a logical ‘and.’ */
export type FirebaseTokenFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FirebaseTokenFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FirebaseTokenFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FirebaseTokenFilter>>;
  /** Filter by the object’s `token` field. */
  token?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `FirebaseToken` */
export type FirebaseTokenInput = {
  /** When this users token was created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** The device type this `FirebaseToken` is bound to */
  device: DeviceType;
  id?: InputMaybe<Scalars['UUID']>;
  /** Firebase token value */
  token: Scalars['String'];
  /** The primary unique identifier for the user */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** Represents an update to a `FirebaseToken`. Fields that are set will be updated. */
export type FirebaseTokenPatch = {
  /** When this users token was created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** The device type this `FirebaseToken` is bound to */
  device?: InputMaybe<DeviceType>;
  id?: InputMaybe<Scalars['UUID']>;
  /** Firebase token value */
  token?: InputMaybe<Scalars['String']>;
  /** The primary unique identifier for the user */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A connection to a list of `FirebaseToken` values. */
export type FirebaseTokensConnection = {
  __typename?: 'FirebaseTokensConnection';
  /** A list of edges which contains the `FirebaseToken` and cursor to aid in pagination. */
  edges: Array<FirebaseTokensEdge>;
  /** A list of `FirebaseToken` objects. */
  nodes: Array<Maybe<FirebaseToken>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FirebaseToken` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `FirebaseToken` edge in the connection. */
export type FirebaseTokensEdge = {
  __typename?: 'FirebaseTokensEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FirebaseToken` at the end of the edge. */
  node?: Maybe<FirebaseToken>;
};

/** Methods to use when ordering `FirebaseToken`. */
export enum FirebaseTokensOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeviceAsc = 'DEVICE_ASC',
  DeviceDesc = 'DEVICE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TokenAsc = 'TOKEN_ASC',
  TokenDesc = 'TOKEN_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
}

/** A connection to a list of `FlywaySchemaHistory` values. */
export type FlywaySchemaHistoriesConnection = {
  __typename?: 'FlywaySchemaHistoriesConnection';
  /** A list of edges which contains the `FlywaySchemaHistory` and cursor to aid in pagination. */
  edges: Array<FlywaySchemaHistoriesEdge>;
  /** A list of `FlywaySchemaHistory` objects. */
  nodes: Array<Maybe<FlywaySchemaHistory>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FlywaySchemaHistory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `FlywaySchemaHistory` edge in the connection. */
export type FlywaySchemaHistoriesEdge = {
  __typename?: 'FlywaySchemaHistoriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FlywaySchemaHistory` at the end of the edge. */
  node?: Maybe<FlywaySchemaHistory>;
};

/** Methods to use when ordering `FlywaySchemaHistory`. */
export enum FlywaySchemaHistoriesOrderBy {
  ChecksumAsc = 'CHECKSUM_ASC',
  ChecksumDesc = 'CHECKSUM_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  ExecutionTimeAsc = 'EXECUTION_TIME_ASC',
  ExecutionTimeDesc = 'EXECUTION_TIME_DESC',
  InstalledByAsc = 'INSTALLED_BY_ASC',
  InstalledByDesc = 'INSTALLED_BY_DESC',
  InstalledOnAsc = 'INSTALLED_ON_ASC',
  InstalledOnDesc = 'INSTALLED_ON_DESC',
  InstalledRankAsc = 'INSTALLED_RANK_ASC',
  InstalledRankDesc = 'INSTALLED_RANK_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ScriptAsc = 'SCRIPT_ASC',
  ScriptDesc = 'SCRIPT_DESC',
  SuccessAsc = 'SUCCESS_ASC',
  SuccessDesc = 'SUCCESS_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  VersionAsc = 'VERSION_ASC',
  VersionDesc = 'VERSION_DESC',
}

export type FlywaySchemaHistory = Node & {
  __typename?: 'FlywaySchemaHistory';
  checksum?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  executionTime: Scalars['Int'];
  installedBy: Scalars['String'];
  installedOn: Scalars['Datetime'];
  installedRank: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  script: Scalars['String'];
  success: Scalars['Boolean'];
  type: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `FlywaySchemaHistory` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type FlywaySchemaHistoryCondition = {
  /** Checks for equality with the object’s `checksum` field. */
  checksum?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `executionTime` field. */
  executionTime?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `installedBy` field. */
  installedBy?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `installedOn` field. */
  installedOn?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `installedRank` field. */
  installedRank?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `script` field. */
  script?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `success` field. */
  success?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `version` field. */
  version?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `FlywaySchemaHistory` object types. All fields are combined with a logical ‘and.’ */
export type FlywaySchemaHistoryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FlywaySchemaHistoryFilter>>;
  /** Filter by the object’s `checksum` field. */
  checksum?: InputMaybe<IntFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `executionTime` field. */
  executionTime?: InputMaybe<IntFilter>;
  /** Filter by the object’s `installedBy` field. */
  installedBy?: InputMaybe<StringFilter>;
  /** Filter by the object’s `installedOn` field. */
  installedOn?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `installedRank` field. */
  installedRank?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FlywaySchemaHistoryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FlywaySchemaHistoryFilter>>;
  /** Filter by the object’s `script` field. */
  script?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `version` field. */
  version?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `FlywaySchemaHistory` */
export type FlywaySchemaHistoryInput = {
  checksum?: InputMaybe<Scalars['Int']>;
  description: Scalars['String'];
  executionTime: Scalars['Int'];
  installedBy: Scalars['String'];
  installedOn?: InputMaybe<Scalars['Datetime']>;
  installedRank: Scalars['Int'];
  script: Scalars['String'];
  success: Scalars['Boolean'];
  type: Scalars['String'];
  version?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `FlywaySchemaHistory`. Fields that are set will be updated. */
export type FlywaySchemaHistoryPatch = {
  checksum?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  executionTime?: InputMaybe<Scalars['Int']>;
  installedBy?: InputMaybe<Scalars['String']>;
  installedOn?: InputMaybe<Scalars['Datetime']>;
  installedRank?: InputMaybe<Scalars['Int']>;
  script?: InputMaybe<Scalars['String']>;
  success?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

export type Follower = {
  __typename?: 'Follower';
  artist?: Maybe<Scalars['UUID']>;
  blarecastUrl?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  img?: Maybe<Scalars['String']>;
  likeId?: Maybe<Scalars['UUID']>;
  presignedImgs?: Maybe<Array<Maybe<PresignedImage>>>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `Follower` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FollowerCondition = {
  /** Checks for equality with the object’s `artist` field. */
  artist?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `blarecastUrl` field. */
  blarecastUrl?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `img` field. */
  img?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `likeId` field. */
  likeId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `user` field. */
  user?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `Follower` object types. All fields are combined with a logical ‘and.’ */
export type FollowerFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FollowerFilter>>;
  /** Filter by the object’s `blarecastUrl` field. */
  blarecastUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `img` field. */
  img?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FollowerFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FollowerFilter>>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
};

/** A connection to a list of `Follower` values. */
export type FollowersConnection = {
  __typename?: 'FollowersConnection';
  /** A list of edges which contains the `Follower` and cursor to aid in pagination. */
  edges: Array<FollowersEdge>;
  /** A list of `Follower` objects. */
  nodes: Array<Maybe<Follower>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Follower` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Follower` edge in the connection. */
export type FollowersEdge = {
  __typename?: 'FollowersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Follower` at the end of the edge. */
  node?: Maybe<Follower>;
};

/** Methods to use when ordering `Follower`. */
export enum FollowersOrderBy {
  ArtistAsc = 'ARTIST_ASC',
  ArtistDesc = 'ARTIST_DESC',
  BlarecastUrlAsc = 'BLARECAST_URL_ASC',
  BlarecastUrlDesc = 'BLARECAST_URL_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImgAsc = 'IMG_ASC',
  ImgDesc = 'IMG_DESC',
  LikeIdAsc = 'LIKE_ID_ASC',
  LikeIdDesc = 'LIKE_ID_DESC',
  Natural = 'NATURAL',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UserAsc = 'USER_ASC',
  UserDesc = 'USER_DESC',
}

export type Following = {
  __typename?: 'Following';
  artist?: Maybe<Scalars['UUID']>;
  blarecastUrl?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  img?: Maybe<Scalars['String']>;
  likeId?: Maybe<Scalars['UUID']>;
  presignedImgs?: Maybe<Array<Maybe<PresignedImage>>>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `Following` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FollowingCondition = {
  /** Checks for equality with the object’s `artist` field. */
  artist?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `blarecastUrl` field. */
  blarecastUrl?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `img` field. */
  img?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `likeId` field. */
  likeId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `user` field. */
  user?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `Following` object types. All fields are combined with a logical ‘and.’ */
export type FollowingFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FollowingFilter>>;
  /** Filter by the object’s `blarecastUrl` field. */
  blarecastUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `img` field. */
  img?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FollowingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FollowingFilter>>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
};

/** A connection to a list of `Following` values. */
export type FollowingsConnection = {
  __typename?: 'FollowingsConnection';
  /** A list of edges which contains the `Following` and cursor to aid in pagination. */
  edges: Array<FollowingsEdge>;
  /** A list of `Following` objects. */
  nodes: Array<Maybe<Following>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Following` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Following` edge in the connection. */
export type FollowingsEdge = {
  __typename?: 'FollowingsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Following` at the end of the edge. */
  node?: Maybe<Following>;
};

/** Methods to use when ordering `Following`. */
export enum FollowingsOrderBy {
  ArtistAsc = 'ARTIST_ASC',
  ArtistDesc = 'ARTIST_DESC',
  BlarecastUrlAsc = 'BLARECAST_URL_ASC',
  BlarecastUrlDesc = 'BLARECAST_URL_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImgAsc = 'IMG_ASC',
  ImgDesc = 'IMG_DESC',
  LikeIdAsc = 'LIKE_ID_ASC',
  LikeIdDesc = 'LIKE_ID_DESC',
  Natural = 'NATURAL',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UserAsc = 'USER_ASC',
  UserDesc = 'USER_DESC',
}

/** `Genre` table - A list of genres that can be bound to a `Track`, `Album`, etc ... */
export type Genre = Node & {
  __typename?: 'Genre';
  /** Reads and enables pagination through a set of `Album`. */
  albumsByGenre: AlbumsConnection;
  /** Reads and enables pagination through a set of `Album`. */
  albumsByGenresAlbumGenreIdAndAlbumId: GenreAlbumsByGenresAlbumGenreIdAndAlbumIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Album`. */
  albumsByTrackGenreAndAlbum: GenreAlbumsByTrackGenreAndAlbumManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByAlbumGenreAndArtist: GenreArtistsByAlbumGenreAndArtistManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByGenre: ArtistsConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByTrackGenreAndArtist: GenreArtistsByTrackGenreAndArtistManyToManyConnection;
  /** The name of the `Genre` */
  categoryName: Scalars['String'];
  /** Defines the datetime with time zone when `Genre` was first created */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Genre` that is related to this `Genre`. */
  genreByParentCategory?: Maybe<Genre>;
  /** Reads and enables pagination through a set of `GenresAlbum`. */
  genresAlbumsByGenreId: GenresAlbumsConnection;
  /** Reads and enables pagination through a set of `Genre`. */
  genresByParentCategory: GenresConnection;
  /** The `Genre` unique identifier */
  id: Scalars['UUID'];
  /** The image of the `Genre` */
  img?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The name of the parent `Genre` this `Genre` belongs to. If empty, it is a main `Genre` */
  parentCategory?: Maybe<Scalars['UUID']>;
  presignedImgs?: Maybe<Array<Maybe<PresignedImage>>>;
  /** Reads and enables pagination through a set of `RecordLabel`. */
  recordLabelsByAlbumGenreAndPublisher: GenreRecordLabelsByAlbumGenreAndPublisherManyToManyConnection;
  /** Reads and enables pagination through a set of `RecordLabel`. */
  recordLabelsByTrackGenreAndPublisher: GenreRecordLabelsByTrackGenreAndPublisherManyToManyConnection;
  /** The text that a particular `Genre` will be searched by */
  textSearch?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByGenre: TracksConnection;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByTracksGenreGenreIdAndTrackId: GenreTracksByTracksGenreGenreIdAndTrackIdManyToManyConnection;
  /** Reads and enables pagination through a set of `TracksGenre`. */
  tracksGenresByGenreId: TracksGenresConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByArtistGenreAndUserId: GenreUsersByArtistGenreAndUserIdManyToManyConnection;
};

/** `Genre` table - A list of genres that can be bound to a `Track`, `Album`, etc ... */
export type GenreAlbumsByGenreArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** `Genre` table - A list of genres that can be bound to a `Track`, `Album`, etc ... */
export type GenreAlbumsByGenresAlbumGenreIdAndAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** `Genre` table - A list of genres that can be bound to a `Track`, `Album`, etc ... */
export type GenreAlbumsByTrackGenreAndAlbumArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** `Genre` table - A list of genres that can be bound to a `Track`, `Album`, etc ... */
export type GenreArtistsByAlbumGenreAndArtistArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `Genre` table - A list of genres that can be bound to a `Track`, `Album`, etc ... */
export type GenreArtistsByGenreArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `Genre` table - A list of genres that can be bound to a `Track`, `Album`, etc ... */
export type GenreArtistsByTrackGenreAndArtistArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `Genre` table - A list of genres that can be bound to a `Track`, `Album`, etc ... */
export type GenreGenresAlbumsByGenreIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenresAlbumCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenresAlbumsOrderBy>>;
};

/** `Genre` table - A list of genres that can be bound to a `Track`, `Album`, etc ... */
export type GenreGenresByParentCategoryArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenreCondition>;
  filter?: InputMaybe<GenreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenresOrderBy>>;
};

/** `Genre` table - A list of genres that can be bound to a `Track`, `Album`, etc ... */
export type GenreRecordLabelsByAlbumGenreAndPublisherArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecordLabelCondition>;
  filter?: InputMaybe<RecordLabelFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecordLabelsOrderBy>>;
};

/** `Genre` table - A list of genres that can be bound to a `Track`, `Album`, etc ... */
export type GenreRecordLabelsByTrackGenreAndPublisherArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecordLabelCondition>;
  filter?: InputMaybe<RecordLabelFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecordLabelsOrderBy>>;
};

/** `Genre` table - A list of genres that can be bound to a `Track`, `Album`, etc ... */
export type GenreTracksByGenreArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `Genre` table - A list of genres that can be bound to a `Track`, `Album`, etc ... */
export type GenreTracksByTracksGenreGenreIdAndTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `Genre` table - A list of genres that can be bound to a `Track`, `Album`, etc ... */
export type GenreTracksGenresByGenreIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TracksGenreCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksGenresOrderBy>>;
};

/** `Genre` table - A list of genres that can be bound to a `Track`, `Album`, etc ... */
export type GenreUsersByArtistGenreAndUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `Album` values, with data from `GenresAlbum`. */
export type GenreAlbumsByGenresAlbumGenreIdAndAlbumIdManyToManyConnection = {
  __typename?: 'GenreAlbumsByGenresAlbumGenreIdAndAlbumIdManyToManyConnection';
  /** A list of edges which contains the `Album`, info from the `GenresAlbum`, and the cursor to aid in pagination. */
  edges: Array<GenreAlbumsByGenresAlbumGenreIdAndAlbumIdManyToManyEdge>;
  /** A list of `Album` objects. */
  nodes: Array<Maybe<Album>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Album` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Album` edge in the connection, with data from `GenresAlbum`. */
export type GenreAlbumsByGenresAlbumGenreIdAndAlbumIdManyToManyEdge = {
  __typename?: 'GenreAlbumsByGenresAlbumGenreIdAndAlbumIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `GenresAlbum`. */
  genresAlbumsByAlbumId: GenresAlbumsConnection;
  /** The `Album` at the end of the edge. */
  node?: Maybe<Album>;
};

/** A `Album` edge in the connection, with data from `GenresAlbum`. */
export type GenreAlbumsByGenresAlbumGenreIdAndAlbumIdManyToManyEdgeGenresAlbumsByAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenresAlbumCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenresAlbumsOrderBy>>;
};

/** A connection to a list of `Album` values, with data from `Track`. */
export type GenreAlbumsByTrackGenreAndAlbumManyToManyConnection = {
  __typename?: 'GenreAlbumsByTrackGenreAndAlbumManyToManyConnection';
  /** A list of edges which contains the `Album`, info from the `Track`, and the cursor to aid in pagination. */
  edges: Array<GenreAlbumsByTrackGenreAndAlbumManyToManyEdge>;
  /** A list of `Album` objects. */
  nodes: Array<Maybe<Album>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Album` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Album` edge in the connection, with data from `Track`. */
export type GenreAlbumsByTrackGenreAndAlbumManyToManyEdge = {
  __typename?: 'GenreAlbumsByTrackGenreAndAlbumManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Album` at the end of the edge. */
  node?: Maybe<Album>;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByAlbum: TracksConnection;
};

/** A `Album` edge in the connection, with data from `Track`. */
export type GenreAlbumsByTrackGenreAndAlbumManyToManyEdgeTracksByAlbumArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `Album`. */
export type GenreArtistsByAlbumGenreAndArtistManyToManyConnection = {
  __typename?: 'GenreArtistsByAlbumGenreAndArtistManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `Album`, and the cursor to aid in pagination. */
  edges: Array<GenreArtistsByAlbumGenreAndArtistManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `Album`. */
export type GenreArtistsByAlbumGenreAndArtistManyToManyEdge = {
  __typename?: 'GenreArtistsByAlbumGenreAndArtistManyToManyEdge';
  /** Reads and enables pagination through a set of `Album`. */
  albumsByArtist: AlbumsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** A `Artist` edge in the connection, with data from `Album`. */
export type GenreArtistsByAlbumGenreAndArtistManyToManyEdgeAlbumsByArtistArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `Track`. */
export type GenreArtistsByTrackGenreAndArtistManyToManyConnection = {
  __typename?: 'GenreArtistsByTrackGenreAndArtistManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `Track`, and the cursor to aid in pagination. */
  edges: Array<GenreArtistsByTrackGenreAndArtistManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `Track`. */
export type GenreArtistsByTrackGenreAndArtistManyToManyEdge = {
  __typename?: 'GenreArtistsByTrackGenreAndArtistManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByArtist: TracksConnection;
};

/** A `Artist` edge in the connection, with data from `Track`. */
export type GenreArtistsByTrackGenreAndArtistManyToManyEdgeTracksByArtistArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** A condition to be used against `Genre` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type GenreCondition = {
  /** Checks for equality with the object’s `categoryName` field. */
  categoryName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `img` field. */
  img?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `parentCategory` field. */
  parentCategory?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `textSearch` field. */
  textSearch?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `Genre` object types. All fields are combined with a logical ‘and.’ */
export type GenreFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GenreFilter>>;
  /** Filter by the object’s `categoryName` field. */
  categoryName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `img` field. */
  img?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GenreFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GenreFilter>>;
  /** Filter by the object’s `textSearch` field. */
  textSearch?: InputMaybe<StringFilter>;
};

/** A connection to a list of `RecordLabel` values, with data from `Album`. */
export type GenreRecordLabelsByAlbumGenreAndPublisherManyToManyConnection = {
  __typename?: 'GenreRecordLabelsByAlbumGenreAndPublisherManyToManyConnection';
  /** A list of edges which contains the `RecordLabel`, info from the `Album`, and the cursor to aid in pagination. */
  edges: Array<GenreRecordLabelsByAlbumGenreAndPublisherManyToManyEdge>;
  /** A list of `RecordLabel` objects. */
  nodes: Array<Maybe<RecordLabel>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `RecordLabel` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `RecordLabel` edge in the connection, with data from `Album`. */
export type GenreRecordLabelsByAlbumGenreAndPublisherManyToManyEdge = {
  __typename?: 'GenreRecordLabelsByAlbumGenreAndPublisherManyToManyEdge';
  /** Reads and enables pagination through a set of `Album`. */
  albumsByPublisher: AlbumsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RecordLabel` at the end of the edge. */
  node?: Maybe<RecordLabel>;
};

/** A `RecordLabel` edge in the connection, with data from `Album`. */
export type GenreRecordLabelsByAlbumGenreAndPublisherManyToManyEdgeAlbumsByPublisherArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** A connection to a list of `RecordLabel` values, with data from `Track`. */
export type GenreRecordLabelsByTrackGenreAndPublisherManyToManyConnection = {
  __typename?: 'GenreRecordLabelsByTrackGenreAndPublisherManyToManyConnection';
  /** A list of edges which contains the `RecordLabel`, info from the `Track`, and the cursor to aid in pagination. */
  edges: Array<GenreRecordLabelsByTrackGenreAndPublisherManyToManyEdge>;
  /** A list of `RecordLabel` objects. */
  nodes: Array<Maybe<RecordLabel>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `RecordLabel` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `RecordLabel` edge in the connection, with data from `Track`. */
export type GenreRecordLabelsByTrackGenreAndPublisherManyToManyEdge = {
  __typename?: 'GenreRecordLabelsByTrackGenreAndPublisherManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RecordLabel` at the end of the edge. */
  node?: Maybe<RecordLabel>;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByPublisher: TracksConnection;
};

/** A `RecordLabel` edge in the connection, with data from `Track`. */
export type GenreRecordLabelsByTrackGenreAndPublisherManyToManyEdgeTracksByPublisherArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** A connection to a list of `Track` values, with data from `TracksGenre`. */
export type GenreTracksByTracksGenreGenreIdAndTrackIdManyToManyConnection = {
  __typename?: 'GenreTracksByTracksGenreGenreIdAndTrackIdManyToManyConnection';
  /** A list of edges which contains the `Track`, info from the `TracksGenre`, and the cursor to aid in pagination. */
  edges: Array<GenreTracksByTracksGenreGenreIdAndTrackIdManyToManyEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Track` edge in the connection, with data from `TracksGenre`. */
export type GenreTracksByTracksGenreGenreIdAndTrackIdManyToManyEdge = {
  __typename?: 'GenreTracksByTracksGenreGenreIdAndTrackIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
  /** Reads and enables pagination through a set of `TracksGenre`. */
  tracksGenresByTrackId: TracksGenresConnection;
};

/** A `Track` edge in the connection, with data from `TracksGenre`. */
export type GenreTracksByTracksGenreGenreIdAndTrackIdManyToManyEdgeTracksGenresByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TracksGenreCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksGenresOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Artist`. */
export type GenreUsersByArtistGenreAndUserIdManyToManyConnection = {
  __typename?: 'GenreUsersByArtistGenreAndUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `Artist`, and the cursor to aid in pagination. */
  edges: Array<GenreUsersByArtistGenreAndUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Artist`. */
export type GenreUsersByArtistGenreAndUserIdManyToManyEdge = {
  __typename?: 'GenreUsersByArtistGenreAndUserIdManyToManyEdge';
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByUserId: ArtistsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** A `User` edge in the connection, with data from `Artist`. */
export type GenreUsersByArtistGenreAndUserIdManyToManyEdgeArtistsByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `GenresAlbum` table - Holds all the `Genre`-s bound to a particular `Album` */
export type GenresAlbum = {
  __typename?: 'GenresAlbum';
  /** Reads a single `Album` that is related to this `GenresAlbum`. */
  albumByAlbumId?: Maybe<Album>;
  /** The `Album` a particular `Genre` can be bound to */
  albumId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Genre` that is related to this `GenresAlbum`. */
  genreByGenreId?: Maybe<Genre>;
  /** The `Genre` that is selected to be in the collection */
  genreId?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `GenresAlbum` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type GenresAlbumCondition = {
  /** Checks for equality with the object’s `albumId` field. */
  albumId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `genreId` field. */
  genreId?: InputMaybe<Scalars['UUID']>;
};

/** An input for mutations affecting `GenresAlbum` */
export type GenresAlbumInput = {
  /** The `Album` a particular `Genre` can be bound to */
  albumId?: InputMaybe<Scalars['UUID']>;
  /** The `Genre` that is selected to be in the collection */
  genreId?: InputMaybe<Scalars['UUID']>;
};

/** A connection to a list of `GenresAlbum` values. */
export type GenresAlbumsConnection = {
  __typename?: 'GenresAlbumsConnection';
  /** A list of edges which contains the `GenresAlbum` and cursor to aid in pagination. */
  edges: Array<GenresAlbumsEdge>;
  /** A list of `GenresAlbum` objects. */
  nodes: Array<Maybe<GenresAlbum>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GenresAlbum` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GenresAlbum` edge in the connection. */
export type GenresAlbumsEdge = {
  __typename?: 'GenresAlbumsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GenresAlbum` at the end of the edge. */
  node?: Maybe<GenresAlbum>;
};

/** Methods to use when ordering `GenresAlbum`. */
export enum GenresAlbumsOrderBy {
  AlbumIdAsc = 'ALBUM_ID_ASC',
  AlbumIdDesc = 'ALBUM_ID_DESC',
  GenreIdAsc = 'GENRE_ID_ASC',
  GenreIdDesc = 'GENRE_ID_DESC',
  Natural = 'NATURAL',
}

/** A connection to a list of `Genre` values. */
export type GenresConnection = {
  __typename?: 'GenresConnection';
  /** A list of edges which contains the `Genre` and cursor to aid in pagination. */
  edges: Array<GenresEdge>;
  /** A list of `Genre` objects. */
  nodes: Array<Maybe<Genre>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Genre` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Genre` edge in the connection. */
export type GenresEdge = {
  __typename?: 'GenresEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Genre` at the end of the edge. */
  node?: Maybe<Genre>;
};

/** Methods to use when ordering `Genre`. */
export enum GenresOrderBy {
  CategoryNameAsc = 'CATEGORY_NAME_ASC',
  CategoryNameDesc = 'CATEGORY_NAME_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImgAsc = 'IMG_ASC',
  ImgDesc = 'IMG_DESC',
  Natural = 'NATURAL',
  ParentCategoryAsc = 'PARENT_CATEGORY_ASC',
  ParentCategoryDesc = 'PARENT_CATEGORY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TextSearchAsc = 'TEXT_SEARCH_ASC',
  TextSearchDesc = 'TEXT_SEARCH_DESC',
}

export type GetDeepLinkInput = {
  id: Scalars['String'];
  type: AssetType;
};

/** All input for the `getMostStreamedArtists` mutation. */
export type GetMostStreamedArtistsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `getMostStreamedArtists` mutation. */
export type GetMostStreamedArtistsPayload = {
  __typename?: 'GetMostStreamedArtistsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  string?: Maybe<Scalars['String']>;
};

/** All input for the `getMostStreamedTracks` mutation. */
export type GetMostStreamedTracksInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `getMostStreamedTracks` mutation. */
export type GetMostStreamedTracksPayload = {
  __typename?: 'GetMostStreamedTracksPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  string?: Maybe<Scalars['String']>;
};

/** All input for the `hasLabel` mutation. */
export type HasLabelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `hasLabel` mutation. */
export type HasLabelPayload = {
  __typename?: 'HasLabelPayload';
  boolean?: Maybe<Scalars['Boolean']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertTrackEarnings` mutation. */
export type InsertTrackEarningsInput = {
  amount?: InputMaybe<Scalars['BigInt']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  trackUid?: InputMaybe<Scalars['UUID']>;
  userUid?: InputMaybe<Scalars['UUID']>;
};

/** The output of our `insertTrackEarnings` mutation. */
export type InsertTrackEarningsPayload = {
  __typename?: 'InsertTrackEarningsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `TracksEarning`. */
  trackByTrackId?: Maybe<Track>;
  tracksEarning?: Maybe<TracksEarning>;
  /** An edge for our `TracksEarning`. May be used by Relay 1. */
  tracksEarningEdge?: Maybe<TracksEarningsEdge>;
  /** Reads a single `User` that is related to this `TracksEarning`. */
  userByUserId?: Maybe<User>;
};

/** The output of our `insertTrackEarnings` mutation. */
export type InsertTrackEarningsPayloadTracksEarningEdgeArgs = {
  orderBy?: InputMaybe<Array<TracksEarningsOrderBy>>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Int']>;
};

/** All input for the `isArtistMember` mutation. */
export type IsArtistMemberInput = {
  artistId?: InputMaybe<Scalars['UUID']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `isArtistMember` mutation. */
export type IsArtistMemberPayload = {
  __typename?: 'IsArtistMemberPayload';
  boolean?: Maybe<Scalars['Boolean']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `isArtistOwner` mutation. */
export type IsArtistOwnerInput = {
  artistId?: InputMaybe<Scalars['UUID']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `isArtistOwner` mutation. */
export type IsArtistOwnerPayload = {
  __typename?: 'IsArtistOwnerPayload';
  boolean?: Maybe<Scalars['Boolean']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `isPlaylistOwner` mutation. */
export type IsPlaylistOwnerInput = {
  authorId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  playlistId: Scalars['UUID'];
};

/** The output of our `isPlaylistOwner` mutation. */
export type IsPlaylistOwnerPayload = {
  __typename?: 'IsPlaylistOwnerPayload';
  boolean?: Maybe<Scalars['Boolean']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `isTrackMember` mutation. */
export type IsTrackMemberInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  trackId: Scalars['UUID'];
};

/** The output of our `isTrackMember` mutation. */
export type IsTrackMemberPayload = {
  __typename?: 'IsTrackMemberPayload';
  boolean?: Maybe<Scalars['Boolean']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `isTrackOwner` mutation. */
export type IsTrackOwnerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  trackId: Scalars['UUID'];
};

/** The output of our `isTrackOwner` mutation. */
export type IsTrackOwnerPayload = {
  __typename?: 'IsTrackOwnerPayload';
  boolean?: Maybe<Scalars['Boolean']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `isValidArtist` mutation. */
export type IsValidArtistInput = {
  artistId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `isValidArtist` mutation. */
export type IsValidArtistPayload = {
  __typename?: 'IsValidArtistPayload';
  boolean?: Maybe<Scalars['Boolean']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type JwtToken = {
  __typename?: 'JwtToken';
  artistId?: Maybe<Scalars['UUID']>;
  country?: Maybe<Scalars['UUID']>;
  exp?: Maybe<Scalars['BigInt']>;
  role?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
};

/** `LastPlayedTrack` table */
export type LastPlayedTrack = Node & {
  __typename?: 'LastPlayedTrack';
  /** Defines the datetime with time zone when `LastPlayedTrack` was first created */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** A `LastPlayedTrack` unique identifier */
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Track` that is related to this `LastPlayedTrack`. */
  trackByTrackId?: Maybe<Track>;
  /** The `Track` that was played` */
  trackId?: Maybe<Scalars['UUID']>;
  /** The `Track` order number that it was played at` */
  trackNumber: Scalars['Int'];
  /** The `User` that played the `Track` */
  userId?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `LastPlayedTrack` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LastPlayedTrackCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `trackId` field. */
  trackId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `trackNumber` field. */
  trackNumber?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `LastPlayedTrack` object types. All fields are combined with a logical ‘and.’ */
export type LastPlayedTrackFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LastPlayedTrackFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LastPlayedTrackFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LastPlayedTrackFilter>>;
  /** Filter by the object’s `trackNumber` field. */
  trackNumber?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `LastPlayedTrack` */
export type LastPlayedTrackInput = {
  /** Defines the datetime with time zone when `LastPlayedTrack` was first created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** A `LastPlayedTrack` unique identifier */
  id?: InputMaybe<Scalars['UUID']>;
  /** The `Track` that was played` */
  trackId?: InputMaybe<Scalars['UUID']>;
  /** The `Track` order number that it was played at` */
  trackNumber?: InputMaybe<Scalars['Int']>;
  /** The `User` that played the `Track` */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** Represents an update to a `LastPlayedTrack`. Fields that are set will be updated. */
export type LastPlayedTrackPatch = {
  /** Defines the datetime with time zone when `LastPlayedTrack` was first created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** A `LastPlayedTrack` unique identifier */
  id?: InputMaybe<Scalars['UUID']>;
  /** The `Track` that was played` */
  trackId?: InputMaybe<Scalars['UUID']>;
  /** The `Track` order number that it was played at` */
  trackNumber?: InputMaybe<Scalars['Int']>;
  /** The `User` that played the `Track` */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A connection to a list of `LastPlayedTrack` values. */
export type LastPlayedTracksConnection = {
  __typename?: 'LastPlayedTracksConnection';
  /** A list of edges which contains the `LastPlayedTrack` and cursor to aid in pagination. */
  edges: Array<LastPlayedTracksEdge>;
  /** A list of `LastPlayedTrack` objects. */
  nodes: Array<Maybe<LastPlayedTrack>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LastPlayedTrack` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LastPlayedTrack` edge in the connection. */
export type LastPlayedTracksEdge = {
  __typename?: 'LastPlayedTracksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LastPlayedTrack` at the end of the edge. */
  node?: Maybe<LastPlayedTrack>;
};

/** Methods to use when ordering `LastPlayedTrack`. */
export enum LastPlayedTracksOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TrackIdAsc = 'TRACK_ID_ASC',
  TrackIdDesc = 'TRACK_ID_DESC',
  TrackNumberAsc = 'TRACK_NUMBER_ASC',
  TrackNumberDesc = 'TRACK_NUMBER_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
}

/** `Like` table */
export type Like = Node & {
  __typename?: 'Like';
  /** The id of `Artist` the user liked as */
  actingArtistId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Album` that is related to this `Like`. */
  albumByAlbumId?: Maybe<Album>;
  /** The id of the `Album` associated with this id. */
  albumId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Artist` that is related to this `Like`. */
  artistByActingArtistId?: Maybe<Artist>;
  /** Reads a single `Artist` that is related to this `Like`. */
  artistByArtistId?: Maybe<Artist>;
  /** The id of the `Artist` associated with this id. */
  artistId?: Maybe<Scalars['UUID']>;
  /** Defines the datetime with time zone when `Like` was first created */
  createdAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['UUID'];
  /** The id of `User` that was liked */
  likedUserId?: Maybe<Scalars['UUID']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Playlist` that is related to this `Like`. */
  playlistByPlaylistId?: Maybe<Playlist>;
  /** The id of the `Playlist` associated with this id. */
  playlistId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Track` that is related to this `Like`. */
  trackByTrackId?: Maybe<Track>;
  /** The id of the `Track` associated with this id. */
  trackId?: Maybe<Scalars['UUID']>;
  /** Reads a single `User` that is related to this `Like`. */
  userByLikedUserId?: Maybe<User>;
  /** Reads a single `User` that is related to this `Like`. */
  userByUserId?: Maybe<User>;
  /** The id of the `User` associated with this account. */
  userId?: Maybe<Scalars['UUID']>;
};

/** A condition to be used against `Like` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type LikeCondition = {
  /** Checks for equality with the object’s `actingArtistId` field. */
  actingArtistId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `albumId` field. */
  albumId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `artistId` field. */
  artistId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `likedUserId` field. */
  likedUserId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `playlistId` field. */
  playlistId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `trackId` field. */
  trackId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `Like` object types. All fields are combined with a logical ‘and.’ */
export type LikeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LikeFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LikeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LikeFilter>>;
};

/** A connection to a list of `Like` values. */
export type LikesConnection = {
  __typename?: 'LikesConnection';
  /** A list of edges which contains the `Like` and cursor to aid in pagination. */
  edges: Array<LikesEdge>;
  /** A list of `Like` objects. */
  nodes: Array<Maybe<Like>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Like` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Like` edge in the connection. */
export type LikesEdge = {
  __typename?: 'LikesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Like` at the end of the edge. */
  node?: Maybe<Like>;
};

/** Methods to use when ordering `Like`. */
export enum LikesOrderBy {
  ActingArtistIdAsc = 'ACTING_ARTIST_ID_ASC',
  ActingArtistIdDesc = 'ACTING_ARTIST_ID_DESC',
  AlbumIdAsc = 'ALBUM_ID_ASC',
  AlbumIdDesc = 'ALBUM_ID_DESC',
  ArtistIdAsc = 'ARTIST_ID_ASC',
  ArtistIdDesc = 'ARTIST_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LikedUserIdAsc = 'LIKED_USER_ID_ASC',
  LikedUserIdDesc = 'LIKED_USER_ID_DESC',
  Natural = 'NATURAL',
  PlaylistIdAsc = 'PLAYLIST_ID_ASC',
  PlaylistIdDesc = 'PLAYLIST_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TrackIdAsc = 'TRACK_ID_ASC',
  TrackIdDesc = 'TRACK_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
}

/** `ListenerPackage` table */
export type ListenerPackage = Node & {
  __typename?: 'ListenerPackage';
  /** Defines whether the `ListenerPackage` is activated and can be used */
  active?: Maybe<Scalars['Boolean']>;
  /** Defines the datetime with time zone the `ListenerPackage` was canceled */
  canceledAt?: Maybe<Scalars['Datetime']>;
  /** Defines the datetime with time zone when `ListenerPackage` was first created */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Defines if a free trial period is active */
  hasFreeTrial?: Maybe<Scalars['Boolean']>;
  /**  Defines the `ListenerPackage` unique identifier */
  id: Scalars['UUID'];
  /** Reads a single `ListenerPackageType` that is related to this `ListenerPackage`. */
  listenerPackageTypeByPackageType?: Maybe<ListenerPackageType>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Defines the `ListenerPackageType` as uuid selected */
  packageType: Scalars['UUID'];
  /** Defines the datetime with time zone when `ListenerPackage` was last updated */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `ListenerPackage`. */
  userByUserId?: Maybe<User>;
  /** Defines the `User` this `ListenerPackage` is linked to */
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `ListenerPackage` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ListenerPackageCondition = {
  /** Checks for equality with the object’s `active` field. */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `canceledAt` field. */
  canceledAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `hasFreeTrial` field. */
  hasFreeTrial?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `packageType` field. */
  packageType?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ListenerPackage` object types. All fields are combined with a logical ‘and.’ */
export type ListenerPackageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ListenerPackageFilter>>;
  /** Filter by the object’s `canceledAt` field. */
  canceledAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ListenerPackageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ListenerPackageFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `ListenerPackage` */
export type ListenerPackageInput = {
  /** Defines whether the `ListenerPackage` is activated and can be used */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Defines the datetime with time zone the `ListenerPackage` was canceled */
  canceledAt?: InputMaybe<Scalars['Datetime']>;
  /** Defines the datetime with time zone when `ListenerPackage` was first created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Defines if a free trial period is active */
  hasFreeTrial?: InputMaybe<Scalars['Boolean']>;
  /** Defines the `ListenerPackageType` as uuid selected */
  packageType: Scalars['UUID'];
  /** Defines the datetime with time zone when `ListenerPackage` was last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Defines the `User` this `ListenerPackage` is linked to */
  userId: Scalars['UUID'];
};

/** Represents an update to a `ListenerPackage`. Fields that are set will be updated. */
export type ListenerPackagePatch = {
  /** Defines whether the `ListenerPackage` is activated and can be used */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Defines the datetime with time zone the `ListenerPackage` was canceled */
  canceledAt?: InputMaybe<Scalars['Datetime']>;
  /** Defines the datetime with time zone when `ListenerPackage` was first created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Defines if a free trial period is active */
  hasFreeTrial?: InputMaybe<Scalars['Boolean']>;
  /**  Defines the `ListenerPackage` unique identifier */
  id?: InputMaybe<Scalars['UUID']>;
  /** Defines the `ListenerPackageType` as uuid selected */
  packageType?: InputMaybe<Scalars['UUID']>;
  /** Defines the datetime with time zone when `ListenerPackage` was last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Defines the `User` this `ListenerPackage` is linked to */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** `ListenerPackageType` table */
export type ListenerPackageType = Node & {
  __typename?: 'ListenerPackageType';
  /** Defines the `ListenerPackageType` price the `User` must be billed */
  _packagePrice: Scalars['Int'];
  /** Reads a single `Currency` that is related to this `ListenerPackageType`. */
  currencyByCurrencyId?: Maybe<Currency>;
  currencyCode?: Maybe<Scalars['String']>;
  /** Defines `Currency` the `ListenerPackageType` is bound to */
  currencyId?: Maybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  /** Reads and enables pagination through a set of `ListenerPackage`. */
  listenerPackagesByPackageType: ListenerPackagesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  packagePrice?: Maybe<Scalars['String']>;
  /** `ListenerPackageType` title */
  packageType: Scalars['String'];
  /** Defines the platform fee in percentage */
  platformPercentageFee?: Maybe<Scalars['Float']>;
  /** Defines the stream uplift for blarecast, how much will we receive from it */
  streamCharge: Scalars['BigFloat'];
  /** Defines the number of days before a package trial expires */
  trialDays?: Maybe<Scalars['Int']>;
  /** Defines the number of `Track-s` a `User` can listen before the package trial expires */
  trialTracks?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `User`. */
  usersByListenerPackagePackageTypeAndUserId: ListenerPackageTypeUsersByListenerPackagePackageTypeAndUserIdManyToManyConnection;
  /** Defines the VAT deduction in percentage */
  vatDeduction: Scalars['BigFloat'];
};

/** `ListenerPackageType` table */
export type ListenerPackageTypeListenerPackagesByPackageTypeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ListenerPackageCondition>;
  filter?: InputMaybe<ListenerPackageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ListenerPackagesOrderBy>>;
};

/** `ListenerPackageType` table */
export type ListenerPackageTypeUsersByListenerPackagePackageTypeAndUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/**
 * A condition to be used against `ListenerPackageType` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ListenerPackageTypeCondition = {
  /** Checks for equality with the object’s `_packagePrice` field. */
  _packagePrice?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `currencyId` field. */
  currencyId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `packageType` field. */
  packageType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `platformPercentageFee` field. */
  platformPercentageFee?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `streamCharge` field. */
  streamCharge?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `trialDays` field. */
  trialDays?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `trialTracks` field. */
  trialTracks?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `vatDeduction` field. */
  vatDeduction?: InputMaybe<Scalars['BigFloat']>;
};

/** A filter to be used against `ListenerPackageType` object types. All fields are combined with a logical ‘and.’ */
export type ListenerPackageTypeFilter = {
  /** Filter by the object’s `_packagePrice` field. */
  _packagePrice?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ListenerPackageTypeFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<ListenerPackageTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ListenerPackageTypeFilter>>;
  /** Filter by the object’s `packageType` field. */
  packageType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `trialDays` field. */
  trialDays?: InputMaybe<IntFilter>;
  /** Filter by the object’s `trialTracks` field. */
  trialTracks?: InputMaybe<IntFilter>;
};

/** A connection to a list of `User` values, with data from `ListenerPackage`. */
export type ListenerPackageTypeUsersByListenerPackagePackageTypeAndUserIdManyToManyConnection = {
  __typename?: 'ListenerPackageTypeUsersByListenerPackagePackageTypeAndUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `ListenerPackage`, and the cursor to aid in pagination. */
  edges: Array<ListenerPackageTypeUsersByListenerPackagePackageTypeAndUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ListenerPackage`. */
export type ListenerPackageTypeUsersByListenerPackagePackageTypeAndUserIdManyToManyEdge = {
  __typename?: 'ListenerPackageTypeUsersByListenerPackagePackageTypeAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `ListenerPackage`. */
  listenerPackagesByUserId: ListenerPackagesConnection;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** A `User` edge in the connection, with data from `ListenerPackage`. */
export type ListenerPackageTypeUsersByListenerPackagePackageTypeAndUserIdManyToManyEdgeListenerPackagesByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ListenerPackageCondition>;
  filter?: InputMaybe<ListenerPackageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ListenerPackagesOrderBy>>;
};

/** A connection to a list of `ListenerPackageType` values. */
export type ListenerPackageTypesConnection = {
  __typename?: 'ListenerPackageTypesConnection';
  /** A list of edges which contains the `ListenerPackageType` and cursor to aid in pagination. */
  edges: Array<ListenerPackageTypesEdge>;
  /** A list of `ListenerPackageType` objects. */
  nodes: Array<Maybe<ListenerPackageType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ListenerPackageType` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ListenerPackageType` edge in the connection. */
export type ListenerPackageTypesEdge = {
  __typename?: 'ListenerPackageTypesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ListenerPackageType` at the end of the edge. */
  node?: Maybe<ListenerPackageType>;
};

/** Methods to use when ordering `ListenerPackageType`. */
export enum ListenerPackageTypesOrderBy {
  CurrencyIdAsc = 'CURRENCY_ID_ASC',
  CurrencyIdDesc = 'CURRENCY_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PackageTypeAsc = 'PACKAGE_TYPE_ASC',
  PackageTypeDesc = 'PACKAGE_TYPE_DESC',
  PlatformPercentageFeeAsc = 'PLATFORM_PERCENTAGE_FEE_ASC',
  PlatformPercentageFeeDesc = 'PLATFORM_PERCENTAGE_FEE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StreamChargeAsc = 'STREAM_CHARGE_ASC',
  StreamChargeDesc = 'STREAM_CHARGE_DESC',
  TrialDaysAsc = 'TRIAL_DAYS_ASC',
  TrialDaysDesc = 'TRIAL_DAYS_DESC',
  TrialTracksAsc = 'TRIAL_TRACKS_ASC',
  TrialTracksDesc = 'TRIAL_TRACKS_DESC',
  VatDeductionAsc = 'VAT_DEDUCTION_ASC',
  VatDeductionDesc = 'VAT_DEDUCTION_DESC',
  PackagePriceAsc = '_PACKAGE_PRICE_ASC',
  PackagePriceDesc = '_PACKAGE_PRICE_DESC',
}

/** A connection to a list of `ListenerPackage` values. */
export type ListenerPackagesConnection = {
  __typename?: 'ListenerPackagesConnection';
  /** A list of edges which contains the `ListenerPackage` and cursor to aid in pagination. */
  edges: Array<ListenerPackagesEdge>;
  /** A list of `ListenerPackage` objects. */
  nodes: Array<Maybe<ListenerPackage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ListenerPackage` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ListenerPackage` edge in the connection. */
export type ListenerPackagesEdge = {
  __typename?: 'ListenerPackagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ListenerPackage` at the end of the edge. */
  node?: Maybe<ListenerPackage>;
};

/** Methods to use when ordering `ListenerPackage`. */
export enum ListenerPackagesOrderBy {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  CanceledAtAsc = 'CANCELED_AT_ASC',
  CanceledAtDesc = 'CANCELED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  HasFreeTrialAsc = 'HAS_FREE_TRIAL_ASC',
  HasFreeTrialDesc = 'HAS_FREE_TRIAL_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PackageTypeAsc = 'PACKAGE_TYPE_ASC',
  PackageTypeDesc = 'PACKAGE_TYPE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
}

/** All input for the `mnCreateAlbumsTracksByAlbumIdAndTracksId` mutation. */
export type MnCreateAlbumsTracksByAlbumIdAndTracksIdInput = {
  albumId?: InputMaybe<Scalars['UUID']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  tracks?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
};

/** The output of our `mnCreateAlbumsTracksByAlbumIdAndTracksId` mutation. */
export type MnCreateAlbumsTracksByAlbumIdAndTracksIdPayload = {
  __typename?: 'MnCreateAlbumsTracksByAlbumIdAndTracksIdPayload';
  albumsTracks?: Maybe<Array<Maybe<AlbumsTrack>>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  addTrackToPlaylist?: Maybe<AddTrackToPlaylistPayload>;
  addTracksToAlbum?: Maybe<AddTracksToAlbumPayload>;
  artistHasSubscription?: Maybe<ArtistHasSubscriptionPayload>;
  /** Creates a JWT token that will securely identify a person and give them certain permissions. This token expires in 2 days. */
  authenticate?: Maybe<AuthenticatePayload>;
  checkAccountType?: Maybe<CheckAccountTypePayload>;
  checkRecentlyPlayedConstraint?: Maybe<CheckRecentlyPlayedConstraintPayload>;
  /** Creates a single `Album`. */
  createAlbum?: Maybe<CreateAlbumPayload>;
  /** Creates a single `Artist`. */
  createArtist?: Maybe<CreateArtistPayload>;
  /** Creates a single `ArtistMember`. */
  createArtistMember?: Maybe<CreateArtistMemberPayload>;
  /** Creates a user and binds to Artist */
  createArtistMemberByEmail?: Maybe<CreateArtistMemberByEmailPayload>;
  /** Creates a single `ArtistSubscription`. */
  createArtistSubscription?: Maybe<CreateArtistSubscriptionPayload>;
  /** Creates a single `AutoGeneratedQueue`. */
  createAutoGeneratedQueue?: Maybe<CreateAutoGeneratedQueuePayload>;
  /** Creates a single `CountriesTrack`. */
  createCountriesTrack?: Maybe<CreateCountriesTrackPayload>;
  createEarningsHistory?: Maybe<CreateEarningsHistoryPayload>;
  /** Creates a single `Event`. */
  createEvent?: Maybe<CreateEventPayload>;
  /** Creates a single `ExtraArtist`. */
  createExtraArtist?: Maybe<CreateExtraArtistPayload>;
  /** Creates a single `FeaturedArtist`. */
  createFeaturedArtist?: Maybe<CreateFeaturedArtistPayload>;
  /** Creates a single `FirebaseToken`. */
  createFirebaseToken?: Maybe<CreateFirebaseTokenPayload>;
  /** Creates a single `FlywaySchemaHistory`. */
  createFlywaySchemaHistory?: Maybe<CreateFlywaySchemaHistoryPayload>;
  /** Creates a single `GenresAlbum`. */
  createGenresAlbum?: Maybe<CreateGenresAlbumPayload>;
  /** Creates a single `LastPlayedTrack`. */
  createLastPlayedTrack?: Maybe<CreateLastPlayedTrackPayload>;
  createLike?: Maybe<CreateLikePayload>;
  /** Creates a single `ListenerPackage`. */
  createListenerPackage?: Maybe<CreateListenerPackagePayload>;
  createPlaylist?: Maybe<CreatePlaylistPayload>;
  /** Creates a single `PlaylistsTrack`. */
  createPlaylistsTrack?: Maybe<CreatePlaylistsTrackPayload>;
  /** Creates a single `Price`. */
  createPrice?: Maybe<CreatePricePayload>;
  /** Creates a single `ProfilePublic`. */
  createProfilePublic?: Maybe<CreateProfilePublicPayload>;
  /** Creates a single `RecentlyPlayed`. */
  createRecentlyPlayed?: Maybe<CreateRecentlyPlayedPayload>;
  createRoyalties?: Maybe<CreateRoyaltiesPayload>;
  createStreamsHistory?: Maybe<CreateStreamsHistoryPayload>;
  /** Creates a single `Track`. */
  createTrack?: Maybe<CreateTrackPayload>;
  /** Creates a single `TracksGenre`. */
  createTracksGenre?: Maybe<CreateTracksGenrePayload>;
  /** Creates a single `UsersQueue`. */
  createUsersQueue?: Maybe<CreateUsersQueuePayload>;
  /** Deletes a single `Album` using its globally unique id. */
  deleteAlbum?: Maybe<DeleteAlbumPayload>;
  /** Deletes a single `Album` using a unique key. */
  deleteAlbumById?: Maybe<DeleteAlbumPayload>;
  deleteAlbumImage?: Maybe<DeleteAlbumImagePayload>;
  deleteAllNotifications?: Maybe<DeleteAllNotificationsPayload>;
  /** Deletes a single `Artist` using its globally unique id. */
  deleteArtist?: Maybe<DeleteArtistPayload>;
  /** Deletes a single `Artist` using a unique key. */
  deleteArtistByBlarecastUrl?: Maybe<DeleteArtistPayload>;
  /** Deletes a single `Artist` using a unique key. */
  deleteArtistById?: Maybe<DeleteArtistPayload>;
  /** Deletes a single `ArtistMember` using its globally unique id. */
  deleteArtistMember?: Maybe<DeleteArtistMemberPayload>;
  /** Deletes a single `ArtistMember` using a unique key. */
  deleteArtistMemberByArtistIdAndUserId?: Maybe<DeleteArtistMemberPayload>;
  /** Deletes a single `ArtistMember` using a unique key. */
  deleteArtistMemberById?: Maybe<DeleteArtistMemberPayload>;
  /** Deletes a single `AutoGeneratedQueue` using its globally unique id. */
  deleteAutoGeneratedQueue?: Maybe<DeleteAutoGeneratedQueuePayload>;
  /** Deletes a single `AutoGeneratedQueue` using a unique key. */
  deleteAutoGeneratedQueueById?: Maybe<DeleteAutoGeneratedQueuePayload>;
  /** Deletes a single `CountriesTrack` using a unique key. */
  deleteCountriesTrackByTrackIdAndCountryId?: Maybe<DeleteCountriesTrackPayload>;
  deleteCoverImage?: Maybe<DeleteCoverImagePayload>;
  /** Specify a date to remove all rows created on that date */
  deleteEarningsHistoryByDate?: Maybe<DeleteEarningsHistoryByDatePayload>;
  /** Deletes a single `Event` using its globally unique id. */
  deleteEvent?: Maybe<DeleteEventPayload>;
  /** Deletes a single `Event` using a unique key. */
  deleteEventById?: Maybe<DeleteEventPayload>;
  /** Deletes a single `ExtraArtist` using its globally unique id. */
  deleteExtraArtist?: Maybe<DeleteExtraArtistPayload>;
  /** Deletes a single `ExtraArtist` using a unique key. */
  deleteExtraArtistById?: Maybe<DeleteExtraArtistPayload>;
  /** Deletes a single `FeaturedArtist` using its globally unique id. */
  deleteFeaturedArtist?: Maybe<DeleteFeaturedArtistPayload>;
  /** Deletes a single `FeaturedArtist` using a unique key. */
  deleteFeaturedArtistById?: Maybe<DeleteFeaturedArtistPayload>;
  /** Deletes a single `FirebaseToken` using its globally unique id. */
  deleteFirebaseToken?: Maybe<DeleteFirebaseTokenPayload>;
  /** Deletes a single `FirebaseToken` using a unique key. */
  deleteFirebaseTokenById?: Maybe<DeleteFirebaseTokenPayload>;
  /** Deletes a single `FirebaseToken` using a unique key. */
  deleteFirebaseTokenByToken?: Maybe<DeleteFirebaseTokenPayload>;
  /** Deletes a single `FirebaseToken` using a unique key. */
  deleteFirebaseTokenByUserIdAndDevice?: Maybe<DeleteFirebaseTokenPayload>;
  /** Deletes a single `FlywaySchemaHistory` using its globally unique id. */
  deleteFlywaySchemaHistory?: Maybe<DeleteFlywaySchemaHistoryPayload>;
  /** Deletes a single `FlywaySchemaHistory` using a unique key. */
  deleteFlywaySchemaHistoryByInstalledRank?: Maybe<DeleteFlywaySchemaHistoryPayload>;
  /** Deletes a single `LastPlayedTrack` using its globally unique id. */
  deleteLastPlayedTrack?: Maybe<DeleteLastPlayedTrackPayload>;
  /** Deletes a single `LastPlayedTrack` using a unique key. */
  deleteLastPlayedTrackById?: Maybe<DeleteLastPlayedTrackPayload>;
  /** Deletes a single `LastPlayedTrack` using a unique key. */
  deleteLastPlayedTrackByTrackIdAndUserId?: Maybe<DeleteLastPlayedTrackPayload>;
  /** Deletes a single `Like` using its globally unique id. */
  deleteLike?: Maybe<DeleteLikePayload>;
  /** Deletes a single `Like` using a unique key. */
  deleteLikeById?: Maybe<DeleteLikePayload>;
  deleteLikes?: Maybe<DeleteLikesPayload>;
  /** Deletes a single `Notification` using its globally unique id. */
  deleteNotification?: Maybe<DeleteNotificationPayload>;
  /** Deletes a single `Notification` using a unique key. */
  deleteNotificationById?: Maybe<DeleteNotificationPayload>;
  /** Deletes a single `PlayHistory` using its globally unique id. */
  deletePlayHistory?: Maybe<DeletePlayHistoryPayload>;
  /** Deletes a single `PlayHistory` using a unique key. */
  deletePlayHistoryById?: Maybe<DeletePlayHistoryPayload>;
  /** Deletes a single `Playlist` using its globally unique id. */
  deletePlaylist?: Maybe<DeletePlaylistPayload>;
  /** Deletes a single `Playlist` using a unique key. */
  deletePlaylistById?: Maybe<DeletePlaylistPayload>;
  deletePlaylistImage?: Maybe<DeletePlaylistImagePayload>;
  /** Deletes a single `PlaylistsTrack` using its globally unique id. */
  deletePlaylistsTrack?: Maybe<DeletePlaylistsTrackPayload>;
  /** Deletes a single `PlaylistsTrack` using a unique key. */
  deletePlaylistsTrackById?: Maybe<DeletePlaylistsTrackPayload>;
  /** Deletes a single `PlaylistsTrack` using a unique key. */
  deletePlaylistsTrackByTrackIdAndPlaylistId?: Maybe<DeletePlaylistsTrackPayload>;
  /** Deletes a single `Price` using its globally unique id. */
  deletePrice?: Maybe<DeletePricePayload>;
  /** Deletes a single `Price` using a unique key. */
  deletePriceById?: Maybe<DeletePricePayload>;
  deleteProfileImage?: Maybe<DeleteProfileImagePayload>;
  /** Deletes a single `ProfilePublic` using its globally unique id. */
  deleteProfilePublic?: Maybe<DeleteProfilePublicPayload>;
  /** Deletes a single `ProfilePublic` using a unique key. */
  deleteProfilePublicByBlarecastUrl?: Maybe<DeleteProfilePublicPayload>;
  /** Deletes a single `ProfilePublic` using a unique key. */
  deleteProfilePublicByUserId?: Maybe<DeleteProfilePublicPayload>;
  /** Deletes a single `SearchHistory` using its globally unique id. */
  deleteSearchHistory?: Maybe<DeleteSearchHistoryPayload>;
  /** Deletes a single `SearchHistory` using a unique key. */
  deleteSearchHistoryById?: Maybe<DeleteSearchHistoryPayload>;
  /** Specify a date to remove all rows created on that date */
  deleteStreamsHistoryByDate?: Maybe<DeleteStreamsHistoryByDatePayload>;
  /** Deletes a single `Track` using its globally unique id. */
  deleteTrack?: Maybe<DeleteTrackPayload>;
  /** Deletes a single `Track` using a unique key. */
  deleteTrackById?: Maybe<DeleteTrackPayload>;
  deleteTrackImage?: Maybe<DeleteTrackImagePayload>;
  /** Delete a user and all their data. This action is irreversible. */
  deleteUser?: Maybe<DeleteUserPayload>;
  /** Deletes a single `UsersQueue` using its globally unique id. */
  deleteUsersQueue?: Maybe<DeleteUsersQueuePayload>;
  /** Deletes a single `UsersQueue` using a unique key. */
  deleteUsersQueueById?: Maybe<DeleteUsersQueuePayload>;
  emptyAutoGeneratedQueue?: Maybe<EmptyAutoGeneratedQueuePayload>;
  emptyUsersQueue?: Maybe<EmptyUsersQueuePayload>;
  getMostStreamedArtists?: Maybe<GetMostStreamedArtistsPayload>;
  getMostStreamedTracks?: Maybe<GetMostStreamedTracksPayload>;
  hasLabel?: Maybe<HasLabelPayload>;
  insertTrackEarnings?: Maybe<InsertTrackEarningsPayload>;
  isArtistMember?: Maybe<IsArtistMemberPayload>;
  isArtistOwner?: Maybe<IsArtistOwnerPayload>;
  isPlaylistOwner?: Maybe<IsPlaylistOwnerPayload>;
  isTrackMember?: Maybe<IsTrackMemberPayload>;
  isTrackOwner?: Maybe<IsTrackOwnerPayload>;
  isValidArtist?: Maybe<IsValidArtistPayload>;
  mnCreateAlbumsTracksByAlbumIdAndTracksId?: Maybe<MnCreateAlbumsTracksByAlbumIdAndTracksIdPayload>;
  /** Creates one or many `AutoGeneratedQueue`. */
  mnCreateAutoGeneratedQueue?: Maybe<MnCreateAutoGeneratedQueuePayload>;
  /** Creates one or many `UsersQueue`. */
  mnCreateUsersQueue?: Maybe<MnCreateUsersQueuePayload>;
  /** Deletes one or many `AutoGeneratedQueue` a unique key via a patch. */
  mnDeleteAutoGeneratedQueueById?: Maybe<MnDeleteAutoGeneratedQueuePayload>;
  /** Deletes one or many `UsersQueue` a unique key via a patch. */
  mnDeleteUsersQueueById?: Maybe<MnDeleteUsersQueuePayload>;
  /** Updates one or many `AutoGeneratedQueue` using a unique key and a patch. */
  mnUpdateAutoGeneratedQueueById?: Maybe<MnUpdateAutoGeneratedQueuePayload>;
  /** Updates one or many `UsersQueue` using a unique key and a patch. */
  mnUpdateUsersQueueById?: Maybe<MnUpdateUsersQueuePayload>;
  processListenerPackagesFreeTrialExpired?: Maybe<ProcessListenerPackagesFreeTrialExpiredPayload>;
  removeTrackFromPlaylist?: Maybe<RemoveTrackFromPlaylistPayload>;
  resendArtistMemberInvitation?: Maybe<Scalars['Boolean']>;
  setAbortedRoyalties?: Maybe<SetAbortedRoyaltiesPayload>;
  /** Update both listener and artist blarecast urls at the same time. */
  setBlarecastData?: Maybe<SetBlarecastDataPayload>;
  /** Set display name and email after social media registration */
  setEmailAfterSocialMediaRegistration?: Maybe<SetEmailAfterSocialMediaRegistrationPayload>;
  /** Setting profile settings for specific user */
  setProfileSettingsTextColumns?: Maybe<SetProfileSettingsTextColumnsPayload>;
  shareContent?: Maybe<DeepLinkPayload>;
  toggleTrackPin?: Maybe<ToggleTrackPinPayload>;
  /** Updates a single `Album` using its globally unique id and a patch. */
  updateAlbum?: Maybe<UpdateAlbumPayload>;
  /** Updates a single `Album` using a unique key and a patch. */
  updateAlbumById?: Maybe<UpdateAlbumPayload>;
  updateAlbumsTracksByAlbumIdAndTrackId?: Maybe<UpdateAlbumsTracksByAlbumIdAndTrackIdPayload>;
  /** Updates a single `Artist` using its globally unique id and a patch. */
  updateArtist?: Maybe<UpdateArtistPayload>;
  /** Updates a single `Artist` using a unique key and a patch. */
  updateArtistByBlarecastUrl?: Maybe<UpdateArtistPayload>;
  /** Updates a single `Artist` using a unique key and a patch. */
  updateArtistById?: Maybe<UpdateArtistPayload>;
  /** Updates a single `ArtistSubscription` using its globally unique id and a patch. */
  updateArtistSubscription?: Maybe<UpdateArtistSubscriptionPayload>;
  /** Updates a single `ArtistSubscription` using a unique key and a patch. */
  updateArtistSubscriptionById?: Maybe<UpdateArtistSubscriptionPayload>;
  /** Updates a single `AutoGeneratedQueue` using its globally unique id and a patch. */
  updateAutoGeneratedQueue?: Maybe<UpdateAutoGeneratedQueuePayload>;
  /** Updates a single `AutoGeneratedQueue` using a unique key and a patch. */
  updateAutoGeneratedQueueById?: Maybe<UpdateAutoGeneratedQueuePayload>;
  /** Updates a single `Event` using its globally unique id and a patch. */
  updateEvent?: Maybe<UpdateEventPayload>;
  /** Updates a single `Event` using a unique key and a patch. */
  updateEventById?: Maybe<UpdateEventPayload>;
  /** Updates a single `ExtraArtist` using its globally unique id and a patch. */
  updateExtraArtist?: Maybe<UpdateExtraArtistPayload>;
  /** Updates a single `ExtraArtist` using a unique key and a patch. */
  updateExtraArtistById?: Maybe<UpdateExtraArtistPayload>;
  /** Updates a single `FeaturedArtist` using its globally unique id and a patch. */
  updateFeaturedArtist?: Maybe<UpdateFeaturedArtistPayload>;
  /** Updates a single `FeaturedArtist` using a unique key and a patch. */
  updateFeaturedArtistById?: Maybe<UpdateFeaturedArtistPayload>;
  /** Updates a single `FirebaseToken` using its globally unique id and a patch. */
  updateFirebaseToken?: Maybe<UpdateFirebaseTokenPayload>;
  /** Updates a single `FirebaseToken` using a unique key and a patch. */
  updateFirebaseTokenById?: Maybe<UpdateFirebaseTokenPayload>;
  /** Updates a single `FirebaseToken` using a unique key and a patch. */
  updateFirebaseTokenByToken?: Maybe<UpdateFirebaseTokenPayload>;
  /** Updates a single `FirebaseToken` using a unique key and a patch. */
  updateFirebaseTokenByUserIdAndDevice?: Maybe<UpdateFirebaseTokenPayload>;
  /** Updates a single `FlywaySchemaHistory` using its globally unique id and a patch. */
  updateFlywaySchemaHistory?: Maybe<UpdateFlywaySchemaHistoryPayload>;
  /** Updates a single `FlywaySchemaHistory` using a unique key and a patch. */
  updateFlywaySchemaHistoryByInstalledRank?: Maybe<UpdateFlywaySchemaHistoryPayload>;
  /** Updates a single `LastPlayedTrack` using its globally unique id and a patch. */
  updateLastPlayedTrack?: Maybe<UpdateLastPlayedTrackPayload>;
  /** Updates a single `LastPlayedTrack` using a unique key and a patch. */
  updateLastPlayedTrackById?: Maybe<UpdateLastPlayedTrackPayload>;
  /** Updates a single `LastPlayedTrack` using a unique key and a patch. */
  updateLastPlayedTrackByTrackIdAndUserId?: Maybe<UpdateLastPlayedTrackPayload>;
  /** Updates a single `ListenerPackage` using its globally unique id and a patch. */
  updateListenerPackage?: Maybe<UpdateListenerPackagePayload>;
  /** Updates a single `ListenerPackage` using a unique key and a patch. */
  updateListenerPackageById?: Maybe<UpdateListenerPackagePayload>;
  updateNextUp?: Maybe<UpdateNextUpPayload>;
  /** Updates a single `Notification` using its globally unique id and a patch. */
  updateNotification?: Maybe<UpdateNotificationPayload>;
  /** Updates a single `Notification` using a unique key and a patch. */
  updateNotificationById?: Maybe<UpdateNotificationPayload>;
  updateNotificationsAllRead?: Maybe<UpdateNotificationsAllReadPayload>;
  /** Updates a single `PlayHistory` using its globally unique id and a patch. */
  updatePlayHistory?: Maybe<UpdatePlayHistoryPayload>;
  /** Updates a single `PlayHistory` using a unique key and a patch. */
  updatePlayHistoryById?: Maybe<UpdatePlayHistoryPayload>;
  /** Updates a single `Playlist` using its globally unique id and a patch. */
  updatePlaylist?: Maybe<UpdatePlaylistPayload>;
  /** Updates a single `Playlist` using a unique key and a patch. */
  updatePlaylistById?: Maybe<UpdatePlaylistPayload>;
  updatePlaylistTrackOrder?: Maybe<UpdatePlaylistTrackOrderPayload>;
  /** Updates a single `PlaylistsTrack` using its globally unique id and a patch. */
  updatePlaylistsTrack?: Maybe<UpdatePlaylistsTrackPayload>;
  /** Updates a single `PlaylistsTrack` using a unique key and a patch. */
  updatePlaylistsTrackById?: Maybe<UpdatePlaylistsTrackPayload>;
  /** Updates a single `PlaylistsTrack` using a unique key and a patch. */
  updatePlaylistsTrackByTrackIdAndPlaylistId?: Maybe<UpdatePlaylistsTrackPayload>;
  /** Updates a single `ProfilePublic` using its globally unique id and a patch. */
  updateProfilePublic?: Maybe<UpdateProfilePublicPayload>;
  /** Updates a single `ProfilePublic` using a unique key and a patch. */
  updateProfilePublicByBlarecastUrl?: Maybe<UpdateProfilePublicPayload>;
  /** Updates a single `ProfilePublic` using a unique key and a patch. */
  updateProfilePublicByUserId?: Maybe<UpdateProfilePublicPayload>;
  /** Updates a single `ProfileSetting` using its globally unique id and a patch. */
  updateProfileSetting?: Maybe<UpdateProfileSettingPayload>;
  /** Updates a single `ProfileSetting` using a unique key and a patch. */
  updateProfileSettingByUserId?: Maybe<UpdateProfileSettingPayload>;
  updateSocialMedia?: Maybe<UpdateSocialMediaPayload>;
  /** Updates a single `Track` using its globally unique id and a patch. */
  updateTrack?: Maybe<UpdateTrackPayload>;
  /** Updates a single `Track` using a unique key and a patch. */
  updateTrackById?: Maybe<UpdateTrackPayload>;
  updateTrackOrder?: Maybe<UpdateTrackOrderPayload>;
  updateUserRegistration?: Maybe<UpdateUserRegistrationPayload>;
  /** Updates a single `UsersNotification` using its globally unique id and a patch. */
  updateUsersNotification?: Maybe<UpdateUsersNotificationPayload>;
  /** Updates a single `UsersNotification` using a unique key and a patch. */
  updateUsersNotificationByUserId?: Maybe<UpdateUsersNotificationPayload>;
  /** Updates a single `UsersQueue` using its globally unique id and a patch. */
  updateUsersQueue?: Maybe<UpdateUsersQueuePayload>;
  /** Updates a single `UsersQueue` using a unique key and a patch. */
  updateUsersQueueById?: Maybe<UpdateUsersQueuePayload>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddTrackToPlaylistArgs = {
  input: AddTrackToPlaylistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddTracksToAlbumArgs = {
  input: AddTracksToAlbumInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationArtistHasSubscriptionArgs = {
  input: ArtistHasSubscriptionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAuthenticateArgs = {
  input: AuthenticateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCheckAccountTypeArgs = {
  input: CheckAccountTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCheckRecentlyPlayedConstraintArgs = {
  input: CheckRecentlyPlayedConstraintInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAlbumArgs = {
  input: CreateAlbumInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateArtistArgs = {
  input: CreateArtistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateArtistMemberArgs = {
  input: CreateArtistMemberInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateArtistMemberByEmailArgs = {
  input: CreateArtistMemberByEmailInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateArtistSubscriptionArgs = {
  input: CreateArtistSubscriptionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAutoGeneratedQueueArgs = {
  input: CreateAutoGeneratedQueueInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCountriesTrackArgs = {
  input: CreateCountriesTrackInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEarningsHistoryArgs = {
  input: CreateEarningsHistoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEventArgs = {
  input: CreateEventInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateExtraArtistArgs = {
  input: CreateExtraArtistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFeaturedArtistArgs = {
  input: CreateFeaturedArtistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFirebaseTokenArgs = {
  input: CreateFirebaseTokenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFlywaySchemaHistoryArgs = {
  input: CreateFlywaySchemaHistoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGenresAlbumArgs = {
  input: CreateGenresAlbumInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLastPlayedTrackArgs = {
  input: CreateLastPlayedTrackInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLikeArgs = {
  input: CreateLikeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateListenerPackageArgs = {
  input: CreateListenerPackageInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePlaylistArgs = {
  input: CreatePlaylistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePlaylistsTrackArgs = {
  input: CreatePlaylistsTrackInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePriceArgs = {
  input: CreatePriceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProfilePublicArgs = {
  input: CreateProfilePublicInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRecentlyPlayedArgs = {
  input: CreateRecentlyPlayedInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRoyaltiesArgs = {
  input: CreateRoyaltiesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateStreamsHistoryArgs = {
  input: CreateStreamsHistoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTrackArgs = {
  input: CreateTrackInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTracksGenreArgs = {
  input: CreateTracksGenreInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUsersQueueArgs = {
  input: CreateUsersQueueInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAlbumArgs = {
  input: DeleteAlbumInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAlbumByIdArgs = {
  input: DeleteAlbumByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAlbumImageArgs = {
  input: DeleteAlbumImageInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAllNotificationsArgs = {
  input: DeleteAllNotificationsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteArtistArgs = {
  input: DeleteArtistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteArtistByBlarecastUrlArgs = {
  input: DeleteArtistByBlarecastUrlInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteArtistByIdArgs = {
  input: DeleteArtistByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteArtistMemberArgs = {
  input: DeleteArtistMemberInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteArtistMemberByArtistIdAndUserIdArgs = {
  input: DeleteArtistMemberByArtistIdAndUserIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteArtistMemberByIdArgs = {
  input: DeleteArtistMemberByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAutoGeneratedQueueArgs = {
  input: DeleteAutoGeneratedQueueInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAutoGeneratedQueueByIdArgs = {
  input: DeleteAutoGeneratedQueueByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCountriesTrackByTrackIdAndCountryIdArgs = {
  input: DeleteCountriesTrackByTrackIdAndCountryIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCoverImageArgs = {
  input: DeleteCoverImageInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEarningsHistoryByDateArgs = {
  input: DeleteEarningsHistoryByDateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEventArgs = {
  input: DeleteEventInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEventByIdArgs = {
  input: DeleteEventByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExtraArtistArgs = {
  input: DeleteExtraArtistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExtraArtistByIdArgs = {
  input: DeleteExtraArtistByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeaturedArtistArgs = {
  input: DeleteFeaturedArtistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeaturedArtistByIdArgs = {
  input: DeleteFeaturedArtistByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFirebaseTokenArgs = {
  input: DeleteFirebaseTokenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFirebaseTokenByIdArgs = {
  input: DeleteFirebaseTokenByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFirebaseTokenByTokenArgs = {
  input: DeleteFirebaseTokenByTokenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFirebaseTokenByUserIdAndDeviceArgs = {
  input: DeleteFirebaseTokenByUserIdAndDeviceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFlywaySchemaHistoryArgs = {
  input: DeleteFlywaySchemaHistoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFlywaySchemaHistoryByInstalledRankArgs = {
  input: DeleteFlywaySchemaHistoryByInstalledRankInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLastPlayedTrackArgs = {
  input: DeleteLastPlayedTrackInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLastPlayedTrackByIdArgs = {
  input: DeleteLastPlayedTrackByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLastPlayedTrackByTrackIdAndUserIdArgs = {
  input: DeleteLastPlayedTrackByTrackIdAndUserIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLikeArgs = {
  input: DeleteLikeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLikeByIdArgs = {
  input: DeleteLikeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLikesArgs = {
  input: DeleteLikesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationArgs = {
  input: DeleteNotificationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationByIdArgs = {
  input: DeleteNotificationByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePlayHistoryArgs = {
  input: DeletePlayHistoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePlayHistoryByIdArgs = {
  input: DeletePlayHistoryByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePlaylistArgs = {
  input: DeletePlaylistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePlaylistByIdArgs = {
  input: DeletePlaylistByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePlaylistImageArgs = {
  input: DeletePlaylistImageInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePlaylistsTrackArgs = {
  input: DeletePlaylistsTrackInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePlaylistsTrackByIdArgs = {
  input: DeletePlaylistsTrackByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePlaylistsTrackByTrackIdAndPlaylistIdArgs = {
  input: DeletePlaylistsTrackByTrackIdAndPlaylistIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePriceArgs = {
  input: DeletePriceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePriceByIdArgs = {
  input: DeletePriceByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProfileImageArgs = {
  input: DeleteProfileImageInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProfilePublicArgs = {
  input: DeleteProfilePublicInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProfilePublicByBlarecastUrlArgs = {
  input: DeleteProfilePublicByBlarecastUrlInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProfilePublicByUserIdArgs = {
  input: DeleteProfilePublicByUserIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSearchHistoryArgs = {
  input: DeleteSearchHistoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSearchHistoryByIdArgs = {
  input: DeleteSearchHistoryByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteStreamsHistoryByDateArgs = {
  input: DeleteStreamsHistoryByDateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTrackArgs = {
  input: DeleteTrackInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTrackByIdArgs = {
  input: DeleteTrackByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTrackImageArgs = {
  input: DeleteTrackImageInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUsersQueueArgs = {
  input: DeleteUsersQueueInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUsersQueueByIdArgs = {
  input: DeleteUsersQueueByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationEmptyAutoGeneratedQueueArgs = {
  input: EmptyAutoGeneratedQueueInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationEmptyUsersQueueArgs = {
  input: EmptyUsersQueueInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationGetMostStreamedArtistsArgs = {
  input: GetMostStreamedArtistsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationGetMostStreamedTracksArgs = {
  input: GetMostStreamedTracksInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationHasLabelArgs = {
  input: HasLabelInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertTrackEarningsArgs = {
  input: InsertTrackEarningsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationIsArtistMemberArgs = {
  input: IsArtistMemberInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationIsArtistOwnerArgs = {
  input: IsArtistOwnerInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationIsPlaylistOwnerArgs = {
  input: IsPlaylistOwnerInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationIsTrackMemberArgs = {
  input: IsTrackMemberInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationIsTrackOwnerArgs = {
  input: IsTrackOwnerInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationIsValidArtistArgs = {
  input: IsValidArtistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationMnCreateAlbumsTracksByAlbumIdAndTracksIdArgs = {
  input: MnCreateAlbumsTracksByAlbumIdAndTracksIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationMnCreateAutoGeneratedQueueArgs = {
  input: MnCreateAutoGeneratedQueueInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationMnCreateUsersQueueArgs = {
  input: MnCreateUsersQueueInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationMnDeleteAutoGeneratedQueueByIdArgs = {
  input: MnDeleteAutoGeneratedQueueByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationMnDeleteUsersQueueByIdArgs = {
  input: MnDeleteUsersQueueByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationMnUpdateAutoGeneratedQueueByIdArgs = {
  input: MnUpdateAutoGeneratedQueueByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationMnUpdateUsersQueueByIdArgs = {
  input: MnUpdateUsersQueueByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationProcessListenerPackagesFreeTrialExpiredArgs = {
  input: ProcessListenerPackagesFreeTrialExpiredInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveTrackFromPlaylistArgs = {
  input: RemoveTrackFromPlaylistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationResendArtistMemberInvitationArgs = {
  artistMemberId?: InputMaybe<Scalars['ID']>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetAbortedRoyaltiesArgs = {
  input: SetAbortedRoyaltiesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetBlarecastDataArgs = {
  input: SetBlarecastDataInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetEmailAfterSocialMediaRegistrationArgs = {
  input: SetEmailAfterSocialMediaRegistrationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetProfileSettingsTextColumnsArgs = {
  input: SetProfileSettingsTextColumnsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationShareContentArgs = {
  input: GetDeepLinkInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationToggleTrackPinArgs = {
  input: ToggleTrackPinInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAlbumArgs = {
  input: UpdateAlbumInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAlbumByIdArgs = {
  input: UpdateAlbumByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAlbumsTracksByAlbumIdAndTrackIdArgs = {
  input: UpdateAlbumsTracksByAlbumIdAndTrackIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateArtistArgs = {
  input: UpdateArtistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateArtistByBlarecastUrlArgs = {
  input: UpdateArtistByBlarecastUrlInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateArtistByIdArgs = {
  input: UpdateArtistByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateArtistSubscriptionArgs = {
  input: UpdateArtistSubscriptionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateArtistSubscriptionByIdArgs = {
  input: UpdateArtistSubscriptionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAutoGeneratedQueueArgs = {
  input: UpdateAutoGeneratedQueueInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAutoGeneratedQueueByIdArgs = {
  input: UpdateAutoGeneratedQueueByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEventByIdArgs = {
  input: UpdateEventByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExtraArtistArgs = {
  input: UpdateExtraArtistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExtraArtistByIdArgs = {
  input: UpdateExtraArtistByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeaturedArtistArgs = {
  input: UpdateFeaturedArtistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeaturedArtistByIdArgs = {
  input: UpdateFeaturedArtistByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFirebaseTokenArgs = {
  input: UpdateFirebaseTokenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFirebaseTokenByIdArgs = {
  input: UpdateFirebaseTokenByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFirebaseTokenByTokenArgs = {
  input: UpdateFirebaseTokenByTokenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFirebaseTokenByUserIdAndDeviceArgs = {
  input: UpdateFirebaseTokenByUserIdAndDeviceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFlywaySchemaHistoryArgs = {
  input: UpdateFlywaySchemaHistoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFlywaySchemaHistoryByInstalledRankArgs = {
  input: UpdateFlywaySchemaHistoryByInstalledRankInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLastPlayedTrackArgs = {
  input: UpdateLastPlayedTrackInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLastPlayedTrackByIdArgs = {
  input: UpdateLastPlayedTrackByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLastPlayedTrackByTrackIdAndUserIdArgs = {
  input: UpdateLastPlayedTrackByTrackIdAndUserIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateListenerPackageArgs = {
  input: UpdateListenerPackageInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateListenerPackageByIdArgs = {
  input: UpdateListenerPackageByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNextUpArgs = {
  input: UpdateNextUpInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationArgs = {
  input: UpdateNotificationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationByIdArgs = {
  input: UpdateNotificationByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationsAllReadArgs = {
  input: UpdateNotificationsAllReadInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePlayHistoryArgs = {
  input: UpdatePlayHistoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePlayHistoryByIdArgs = {
  input: UpdatePlayHistoryByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePlaylistArgs = {
  input: UpdatePlaylistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePlaylistByIdArgs = {
  input: UpdatePlaylistByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePlaylistTrackOrderArgs = {
  input: UpdatePlaylistTrackOrderInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePlaylistsTrackArgs = {
  input: UpdatePlaylistsTrackInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePlaylistsTrackByIdArgs = {
  input: UpdatePlaylistsTrackByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePlaylistsTrackByTrackIdAndPlaylistIdArgs = {
  input: UpdatePlaylistsTrackByTrackIdAndPlaylistIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProfilePublicArgs = {
  input: UpdateProfilePublicInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProfilePublicByBlarecastUrlArgs = {
  input: UpdateProfilePublicByBlarecastUrlInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProfilePublicByUserIdArgs = {
  input: UpdateProfilePublicByUserIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProfileSettingArgs = {
  input: UpdateProfileSettingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProfileSettingByUserIdArgs = {
  input: UpdateProfileSettingByUserIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSocialMediaArgs = {
  input: UpdateSocialMediaInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTrackArgs = {
  input: UpdateTrackInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTrackByIdArgs = {
  input: UpdateTrackByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTrackOrderArgs = {
  input: UpdateTrackOrderInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserRegistrationArgs = {
  input: UpdateUserRegistrationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUsersNotificationArgs = {
  input: UpdateUsersNotificationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUsersNotificationByUserIdArgs = {
  input: UpdateUsersNotificationByUserIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUsersQueueArgs = {
  input: UpdateUsersQueueInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUsersQueueByIdArgs = {
  input: UpdateUsersQueueByIdInput;
};

export type MyEarnings = {
  __typename?: 'MyEarnings';
  earnings?: Maybe<Scalars['BigInt']>;
  rows?: Maybe<Array<Maybe<EarningsHistoryRow>>>;
  totalEarnings?: Maybe<Scalars['BigInt']>;
  totalWithdrawn?: Maybe<Scalars['BigInt']>;
};

export enum MyEarningsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
}

export type MyStreams = {
  __typename?: 'MyStreams';
  availableUploadTime?: Maybe<Scalars['Int']>;
  avgListeners?: Maybe<Scalars['BigInt']>;
  maxUploadTime?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<StreamsHistoryRow>>>;
  streams?: Maybe<Scalars['BigInt']>;
};

export enum MyStreamsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
}

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** `Notification` table */
export type Notification = Node & {
  __typename?: 'Notification';
  /** When this push `Notification` was created */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Status if email has already been sent */
  emailSent?: Maybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** `Notification` type */
  notificationType: NotificationType;
  /** Status if push `Notification` has already been sent */
  pushSent?: Maybe<Scalars['Boolean']>;
  /** Status if users `Notification` has already been read trough FE */
  read?: Maybe<Scalars['Boolean']>;
  /** `Notification` subtitle */
  subtitle: Scalars['String'];
  /** The primary unique identifier for onclick `Notification` target */
  targetId?: Maybe<Scalars['UUID']>;
  /** Type of account this `Notification` is set for */
  targetType?: Maybe<AccountType>;
  /** `Notification` title */
  title: Scalars['String'];
  /** Reads a single `User` that is related to this `Notification`. */
  userByUserId?: Maybe<User>;
  /** The primary unique identifier for the user */
  userId?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `Notification` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type NotificationCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `emailSent` field. */
  emailSent?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `notificationType` field. */
  notificationType?: InputMaybe<NotificationType>;
  /** Checks for equality with the object’s `pushSent` field. */
  pushSent?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `read` field. */
  read?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `subtitle` field. */
  subtitle?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `targetId` field. */
  targetId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `targetType` field. */
  targetType?: InputMaybe<AccountType>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `Notification` object types. All fields are combined with a logical ‘and.’ */
export type NotificationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NotificationFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NotificationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NotificationFilter>>;
  /** Filter by the object’s `subtitle` field. */
  subtitle?: InputMaybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
};

/** Represents an update to a `Notification`. Fields that are set will be updated. */
export type NotificationPatch = {
  id?: InputMaybe<Scalars['UUID']>;
  /** Status if users `Notification` has already been read trough FE */
  read?: InputMaybe<Scalars['Boolean']>;
};

export enum NotificationType {
  NewConcert = 'NEW_CONCERT',
  NewFollower = 'NEW_FOLLOWER',
  NewRecommendations = 'NEW_RECOMMENDATIONS',
  NewSong = 'NEW_SONG',
}

/** A connection to a list of `Notification` values. */
export type NotificationsConnection = {
  __typename?: 'NotificationsConnection';
  /** A list of edges which contains the `Notification` and cursor to aid in pagination. */
  edges: Array<NotificationsEdge>;
  /** A list of `Notification` objects. */
  nodes: Array<Maybe<Notification>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Notification` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Notification` edge in the connection. */
export type NotificationsEdge = {
  __typename?: 'NotificationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Notification` at the end of the edge. */
  node?: Maybe<Notification>;
};

/** Methods to use when ordering `Notification`. */
export enum NotificationsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EmailSentAsc = 'EMAIL_SENT_ASC',
  EmailSentDesc = 'EMAIL_SENT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  NotificationTypeAsc = 'NOTIFICATION_TYPE_ASC',
  NotificationTypeDesc = 'NOTIFICATION_TYPE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PushSentAsc = 'PUSH_SENT_ASC',
  PushSentDesc = 'PUSH_SENT_DESC',
  ReadAsc = 'READ_ASC',
  ReadDesc = 'READ_DESC',
  SubtitleAsc = 'SUBTITLE_ASC',
  SubtitleDesc = 'SUBTITLE_DESC',
  TargetIdAsc = 'TARGET_ID_ASC',
  TargetIdDesc = 'TARGET_ID_DESC',
  TargetTypeAsc = 'TARGET_TYPE_ASC',
  TargetTypeDesc = 'TARGET_TYPE_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
};

export type PaymentHistory = {
  __typename?: 'PaymentHistory';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type?: Maybe<PaymentHistoryType>;
};

export enum PaymentHistoryType {
  Subscription = 'SUBSCRIPTION',
  TopUp = 'TOP_UP',
}

/** A connection to a list of `PlayHistory` values. */
export type PlayHistoriesConnection = {
  __typename?: 'PlayHistoriesConnection';
  /** A list of edges which contains the `PlayHistory` and cursor to aid in pagination. */
  edges: Array<PlayHistoriesEdge>;
  /** A list of `PlayHistory` objects. */
  nodes: Array<Maybe<PlayHistory>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PlayHistory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PlayHistory` edge in the connection. */
export type PlayHistoriesEdge = {
  __typename?: 'PlayHistoriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PlayHistory` at the end of the edge. */
  node?: Maybe<PlayHistory>;
};

/** Methods to use when ordering `PlayHistory`. */
export enum PlayHistoriesOrderBy {
  BilledPriceAsc = 'BILLED_PRICE_ASC',
  BilledPriceDesc = 'BILLED_PRICE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PriceIdAsc = 'PRICE_ID_ASC',
  PriceIdDesc = 'PRICE_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TrackIdAsc = 'TRACK_ID_ASC',
  TrackIdDesc = 'TRACK_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
}

/**  `PlayHistory` table */
export type PlayHistory = Node & {
  __typename?: 'PlayHistory';
  /** The amount `User` was billed at */
  billedPrice: Scalars['BigFloat'];
  /** Defines the datetime with time zone when `PlayHistory` was first created */
  createdAt: Scalars['Datetime'];
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The primary unique identifier of `Price` */
  priceId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Track` that is related to this `PlayHistory`. */
  trackByTrackId?: Maybe<Track>;
  /** The primary unique identifier for the `Track` */
  trackId?: Maybe<Scalars['UUID']>;
  /** Reads a single `User` that is related to this `PlayHistory`. */
  userByUserId?: Maybe<User>;
  /** The primary unique identifier for the `User` */
  userId?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `PlayHistory` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PlayHistoryCondition = {
  /** Checks for equality with the object’s `billedPrice` field. */
  billedPrice?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `priceId` field. */
  priceId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `trackId` field. */
  trackId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `PlayHistory` object types. All fields are combined with a logical ‘and.’ */
export type PlayHistoryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PlayHistoryFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PlayHistoryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PlayHistoryFilter>>;
};

/** Represents an update to a `PlayHistory`. Fields that are set will be updated. */
export type PlayHistoryPatch = {
  /** The amount `User` was billed at */
  billedPrice?: InputMaybe<Scalars['BigFloat']>;
  /** Defines the datetime with time zone when `PlayHistory` was first created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** The primary unique identifier of `Price` */
  priceId?: InputMaybe<Scalars['UUID']>;
  /** The primary unique identifier for the `Track` */
  trackId?: InputMaybe<Scalars['UUID']>;
  /** The primary unique identifier for the `User` */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** `Playlist` table */
export type Playlist = Node & {
  __typename?: 'Playlist';
  /** Reads and enables pagination through a set of `Album`. */
  albumsByLikePlaylistIdAndAlbumId: PlaylistAlbumsByLikePlaylistIdAndAlbumIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Album`. */
  albumsByRecentlyPlayedPlaylistIdAndAlbumId: PlaylistAlbumsByRecentlyPlayedPlaylistIdAndAlbumIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByLikePlaylistIdAndActingArtistId: PlaylistArtistsByLikePlaylistIdAndActingArtistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByLikePlaylistIdAndArtistId: PlaylistArtistsByLikePlaylistIdAndArtistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByRecentlyPlayedPlaylistIdAndArtistId: PlaylistArtistsByRecentlyPlayedPlaylistIdAndArtistIdManyToManyConnection;
  /** The unique identifier of a `User` that created the `Playlist` */
  author?: Maybe<Scalars['UUID']>;
  /** Defines the datetime with time zone of `Playlist` was first created */
  createdAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['UUID'];
  /** Link to the `Playlist` image */
  img?: Maybe<Scalars['String']>;
  /** The number of `Likes` the `Playlist``has */
  likeCount?: Maybe<Scalars['Int']>;
  likedAsListener?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByPlaylistId: LikesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `PlaylistsTrack`. */
  playlistsTracksByPlaylistId: PlaylistsTracksConnection;
  presignedImgs?: Maybe<Array<Maybe<PresignedImage>>>;
  /** Defines if the `Playlist` is only available to the `User` that created it */
  private?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `RecentlyPlayed`. */
  recentlyPlayedsByPlaylistId: RecentlyPlayedsConnection;
  /** Defines if the `Playlist` is only available to the `User` that created it */
  textSearch?: Maybe<Scalars['String']>;
  /** `Playlist` title */
  title?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByLikePlaylistIdAndTrackId: PlaylistTracksByLikePlaylistIdAndTrackIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByPlaylistsTrackPlaylistIdAndTrackId: PlaylistTracksByPlaylistsTrackPlaylistIdAndTrackIdManyToManyConnection;
  /** Defines datetime with time zone when the `Playlist` was last updated */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByAuthor?: Maybe<User>;
  /** Reads and enables pagination through a set of `User`. */
  usersByLikePlaylistIdAndLikedUserId: PlaylistUsersByLikePlaylistIdAndLikedUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByLikePlaylistIdAndUserId: PlaylistUsersByLikePlaylistIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByRecentlyPlayedPlaylistIdAndUserId: PlaylistUsersByRecentlyPlayedPlaylistIdAndUserIdManyToManyConnection;
};

/** `Playlist` table */
export type PlaylistAlbumsByLikePlaylistIdAndAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** `Playlist` table */
export type PlaylistAlbumsByRecentlyPlayedPlaylistIdAndAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** `Playlist` table */
export type PlaylistArtistsByLikePlaylistIdAndActingArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `Playlist` table */
export type PlaylistArtistsByLikePlaylistIdAndArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `Playlist` table */
export type PlaylistArtistsByRecentlyPlayedPlaylistIdAndArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `Playlist` table */
export type PlaylistLikesByPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** `Playlist` table */
export type PlaylistPlaylistsTracksByPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PlaylistsTrackCondition>;
  filter?: InputMaybe<PlaylistsTrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlaylistsTracksOrderBy>>;
};

/** `Playlist` table */
export type PlaylistRecentlyPlayedsByPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecentlyPlayedCondition>;
  filter?: InputMaybe<RecentlyPlayedFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecentlyPlayedsOrderBy>>;
};

/** `Playlist` table */
export type PlaylistTracksByLikePlaylistIdAndTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `Playlist` table */
export type PlaylistTracksByPlaylistsTrackPlaylistIdAndTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `Playlist` table */
export type PlaylistUsersByLikePlaylistIdAndLikedUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** `Playlist` table */
export type PlaylistUsersByLikePlaylistIdAndUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** `Playlist` table */
export type PlaylistUsersByRecentlyPlayedPlaylistIdAndUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `Album` values, with data from `Like`. */
export type PlaylistAlbumsByLikePlaylistIdAndAlbumIdManyToManyConnection = {
  __typename?: 'PlaylistAlbumsByLikePlaylistIdAndAlbumIdManyToManyConnection';
  /** A list of edges which contains the `Album`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<PlaylistAlbumsByLikePlaylistIdAndAlbumIdManyToManyEdge>;
  /** A list of `Album` objects. */
  nodes: Array<Maybe<Album>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Album` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Album` edge in the connection, with data from `Like`. */
export type PlaylistAlbumsByLikePlaylistIdAndAlbumIdManyToManyEdge = {
  __typename?: 'PlaylistAlbumsByLikePlaylistIdAndAlbumIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByAlbumId: LikesConnection;
  /** The `Album` at the end of the edge. */
  node?: Maybe<Album>;
};

/** A `Album` edge in the connection, with data from `Like`. */
export type PlaylistAlbumsByLikePlaylistIdAndAlbumIdManyToManyEdgeLikesByAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Album` values, with data from `RecentlyPlayed`. */
export type PlaylistAlbumsByRecentlyPlayedPlaylistIdAndAlbumIdManyToManyConnection = {
  __typename?: 'PlaylistAlbumsByRecentlyPlayedPlaylistIdAndAlbumIdManyToManyConnection';
  /** A list of edges which contains the `Album`, info from the `RecentlyPlayed`, and the cursor to aid in pagination. */
  edges: Array<PlaylistAlbumsByRecentlyPlayedPlaylistIdAndAlbumIdManyToManyEdge>;
  /** A list of `Album` objects. */
  nodes: Array<Maybe<Album>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Album` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Album` edge in the connection, with data from `RecentlyPlayed`. */
export type PlaylistAlbumsByRecentlyPlayedPlaylistIdAndAlbumIdManyToManyEdge = {
  __typename?: 'PlaylistAlbumsByRecentlyPlayedPlaylistIdAndAlbumIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Album` at the end of the edge. */
  node?: Maybe<Album>;
  /** Reads and enables pagination through a set of `RecentlyPlayed`. */
  recentlyPlayedsByAlbumId: RecentlyPlayedsConnection;
};

/** A `Album` edge in the connection, with data from `RecentlyPlayed`. */
export type PlaylistAlbumsByRecentlyPlayedPlaylistIdAndAlbumIdManyToManyEdgeRecentlyPlayedsByAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecentlyPlayedCondition>;
  filter?: InputMaybe<RecentlyPlayedFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecentlyPlayedsOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `Like`. */
export type PlaylistArtistsByLikePlaylistIdAndActingArtistIdManyToManyConnection = {
  __typename?: 'PlaylistArtistsByLikePlaylistIdAndActingArtistIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<PlaylistArtistsByLikePlaylistIdAndActingArtistIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type PlaylistArtistsByLikePlaylistIdAndActingArtistIdManyToManyEdge = {
  __typename?: 'PlaylistArtistsByLikePlaylistIdAndActingArtistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByActingArtistId: LikesConnection;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type PlaylistArtistsByLikePlaylistIdAndActingArtistIdManyToManyEdgeLikesByActingArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `Like`. */
export type PlaylistArtistsByLikePlaylistIdAndArtistIdManyToManyConnection = {
  __typename?: 'PlaylistArtistsByLikePlaylistIdAndArtistIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<PlaylistArtistsByLikePlaylistIdAndArtistIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type PlaylistArtistsByLikePlaylistIdAndArtistIdManyToManyEdge = {
  __typename?: 'PlaylistArtistsByLikePlaylistIdAndArtistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByArtistId: LikesConnection;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type PlaylistArtistsByLikePlaylistIdAndArtistIdManyToManyEdgeLikesByArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `RecentlyPlayed`. */
export type PlaylistArtistsByRecentlyPlayedPlaylistIdAndArtistIdManyToManyConnection = {
  __typename?: 'PlaylistArtistsByRecentlyPlayedPlaylistIdAndArtistIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `RecentlyPlayed`, and the cursor to aid in pagination. */
  edges: Array<PlaylistArtistsByRecentlyPlayedPlaylistIdAndArtistIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `RecentlyPlayed`. */
export type PlaylistArtistsByRecentlyPlayedPlaylistIdAndArtistIdManyToManyEdge = {
  __typename?: 'PlaylistArtistsByRecentlyPlayedPlaylistIdAndArtistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
  /** Reads and enables pagination through a set of `RecentlyPlayed`. */
  recentlyPlayedsByArtistId: RecentlyPlayedsConnection;
};

/** A `Artist` edge in the connection, with data from `RecentlyPlayed`. */
export type PlaylistArtistsByRecentlyPlayedPlaylistIdAndArtistIdManyToManyEdgeRecentlyPlayedsByArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecentlyPlayedCondition>;
  filter?: InputMaybe<RecentlyPlayedFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecentlyPlayedsOrderBy>>;
};

/**
 * A condition to be used against `Playlist` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PlaylistCondition = {
  /** Checks for equality with the object’s `author` field. */
  author?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `img` field. */
  img?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `likeCount` field. */
  likeCount?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `private` field. */
  private?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `textSearch` field. */
  textSearch?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `Playlist` object types. All fields are combined with a logical ‘and.’ */
export type PlaylistFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PlaylistFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `img` field. */
  img?: InputMaybe<StringFilter>;
  /** Filter by the object’s `likeCount` field. */
  likeCount?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PlaylistFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PlaylistFilter>>;
  /** Filter by the object’s `textSearch` field. */
  textSearch?: InputMaybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Represents an update to a `Playlist`. Fields that are set will be updated. */
export type PlaylistPatch = {
  /** The unique identifier of a `User` that created the `Playlist` */
  author?: InputMaybe<Scalars['UUID']>;
  /** Defines the datetime with time zone of `Playlist` was first created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** Link to the `Playlist` image */
  img?: InputMaybe<Scalars['String']>;
  /** Defines if the `Playlist` is only available to the `User` that created it */
  private?: InputMaybe<Scalars['Boolean']>;
  /** `Playlist` title */
  title?: InputMaybe<Scalars['String']>;
  /** Defines datetime with time zone when the `Playlist` was last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `Track` values, with data from `Like`. */
export type PlaylistTracksByLikePlaylistIdAndTrackIdManyToManyConnection = {
  __typename?: 'PlaylistTracksByLikePlaylistIdAndTrackIdManyToManyConnection';
  /** A list of edges which contains the `Track`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<PlaylistTracksByLikePlaylistIdAndTrackIdManyToManyEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Track` edge in the connection, with data from `Like`. */
export type PlaylistTracksByLikePlaylistIdAndTrackIdManyToManyEdge = {
  __typename?: 'PlaylistTracksByLikePlaylistIdAndTrackIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByTrackId: LikesConnection;
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
};

/** A `Track` edge in the connection, with data from `Like`. */
export type PlaylistTracksByLikePlaylistIdAndTrackIdManyToManyEdgeLikesByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Track` values, with data from `PlaylistsTrack`. */
export type PlaylistTracksByPlaylistsTrackPlaylistIdAndTrackIdManyToManyConnection = {
  __typename?: 'PlaylistTracksByPlaylistsTrackPlaylistIdAndTrackIdManyToManyConnection';
  /** A list of edges which contains the `Track`, info from the `PlaylistsTrack`, and the cursor to aid in pagination. */
  edges: Array<PlaylistTracksByPlaylistsTrackPlaylistIdAndTrackIdManyToManyEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Track` edge in the connection, with data from `PlaylistsTrack`. */
export type PlaylistTracksByPlaylistsTrackPlaylistIdAndTrackIdManyToManyEdge = {
  __typename?: 'PlaylistTracksByPlaylistsTrackPlaylistIdAndTrackIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  id: Scalars['UUID'];
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
  /** The order of the `Track` in the `Playlist` collection */
  trackNumber: Scalars['Int'];
};

/** A connection to a list of `User` values, with data from `Like`. */
export type PlaylistUsersByLikePlaylistIdAndLikedUserIdManyToManyConnection = {
  __typename?: 'PlaylistUsersByLikePlaylistIdAndLikedUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<PlaylistUsersByLikePlaylistIdAndLikedUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Like`. */
export type PlaylistUsersByLikePlaylistIdAndLikedUserIdManyToManyEdge = {
  __typename?: 'PlaylistUsersByLikePlaylistIdAndLikedUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByLikedUserId: LikesConnection;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** A `User` edge in the connection, with data from `Like`. */
export type PlaylistUsersByLikePlaylistIdAndLikedUserIdManyToManyEdgeLikesByLikedUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Like`. */
export type PlaylistUsersByLikePlaylistIdAndUserIdManyToManyConnection = {
  __typename?: 'PlaylistUsersByLikePlaylistIdAndUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<PlaylistUsersByLikePlaylistIdAndUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Like`. */
export type PlaylistUsersByLikePlaylistIdAndUserIdManyToManyEdge = {
  __typename?: 'PlaylistUsersByLikePlaylistIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByUserId: LikesConnection;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** A `User` edge in the connection, with data from `Like`. */
export type PlaylistUsersByLikePlaylistIdAndUserIdManyToManyEdgeLikesByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `User` values, with data from `RecentlyPlayed`. */
export type PlaylistUsersByRecentlyPlayedPlaylistIdAndUserIdManyToManyConnection = {
  __typename?: 'PlaylistUsersByRecentlyPlayedPlaylistIdAndUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `RecentlyPlayed`, and the cursor to aid in pagination. */
  edges: Array<PlaylistUsersByRecentlyPlayedPlaylistIdAndUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `RecentlyPlayed`. */
export type PlaylistUsersByRecentlyPlayedPlaylistIdAndUserIdManyToManyEdge = {
  __typename?: 'PlaylistUsersByRecentlyPlayedPlaylistIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
  /** Reads and enables pagination through a set of `RecentlyPlayed`. */
  recentlyPlayedsByUserId: RecentlyPlayedsConnection;
};

/** A `User` edge in the connection, with data from `RecentlyPlayed`. */
export type PlaylistUsersByRecentlyPlayedPlaylistIdAndUserIdManyToManyEdgeRecentlyPlayedsByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecentlyPlayedCondition>;
  filter?: InputMaybe<RecentlyPlayedFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecentlyPlayedsOrderBy>>;
};

/** A connection to a list of `Playlist` values. */
export type PlaylistsConnection = {
  __typename?: 'PlaylistsConnection';
  /** A list of edges which contains the `Playlist` and cursor to aid in pagination. */
  edges: Array<PlaylistsEdge>;
  /** A list of `Playlist` objects. */
  nodes: Array<Maybe<Playlist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Playlist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Playlist` edge in the connection. */
export type PlaylistsEdge = {
  __typename?: 'PlaylistsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Playlist` at the end of the edge. */
  node?: Maybe<Playlist>;
};

/** Methods to use when ordering `Playlist`. */
export enum PlaylistsOrderBy {
  AuthorAsc = 'AUTHOR_ASC',
  AuthorDesc = 'AUTHOR_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImgAsc = 'IMG_ASC',
  ImgDesc = 'IMG_DESC',
  LikeCountAsc = 'LIKE_COUNT_ASC',
  LikeCountDesc = 'LIKE_COUNT_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PrivateAsc = 'PRIVATE_ASC',
  PrivateDesc = 'PRIVATE_DESC',
  TextSearchAsc = 'TEXT_SEARCH_ASC',
  TextSearchDesc = 'TEXT_SEARCH_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** `PlaylistsTrack` table - Holds all the `Track`-s bound to a particular `Playlist` */
export type PlaylistsTrack = Node & {
  __typename?: 'PlaylistsTrack';
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Playlist` that is related to this `PlaylistsTrack`. */
  playlistByPlaylistId?: Maybe<Playlist>;
  /** The id of the `Playlist` that the collection is part of */
  playlistId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Track` that is related to this `PlaylistsTrack`. */
  trackByTrackId?: Maybe<Track>;
  /** The id of the `Track` that is part of `Playlist` */
  trackId?: Maybe<Scalars['UUID']>;
  /** The order of the `Track` in the `Playlist` collection */
  trackNumber: Scalars['Int'];
};

/**
 * A condition to be used against `PlaylistsTrack` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PlaylistsTrackCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `playlistId` field. */
  playlistId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `trackId` field. */
  trackId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `trackNumber` field. */
  trackNumber?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `PlaylistsTrack` object types. All fields are combined with a logical ‘and.’ */
export type PlaylistsTrackFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PlaylistsTrackFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<PlaylistsTrackFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PlaylistsTrackFilter>>;
  /** Filter by the object’s `trackNumber` field. */
  trackNumber?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `PlaylistsTrack` */
export type PlaylistsTrackInput = {
  id?: InputMaybe<Scalars['UUID']>;
  /** The id of the `Playlist` that the collection is part of */
  playlistId?: InputMaybe<Scalars['UUID']>;
  /** The id of the `Track` that is part of `Playlist` */
  trackId?: InputMaybe<Scalars['UUID']>;
  /** The order of the `Track` in the `Playlist` collection */
  trackNumber?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `PlaylistsTrack`. Fields that are set will be updated. */
export type PlaylistsTrackPatch = {
  id?: InputMaybe<Scalars['UUID']>;
  /** The id of the `Playlist` that the collection is part of */
  playlistId?: InputMaybe<Scalars['UUID']>;
  /** The id of the `Track` that is part of `Playlist` */
  trackId?: InputMaybe<Scalars['UUID']>;
  /** The order of the `Track` in the `Playlist` collection */
  trackNumber?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `PlaylistsTrack` values. */
export type PlaylistsTracksConnection = {
  __typename?: 'PlaylistsTracksConnection';
  /** A list of edges which contains the `PlaylistsTrack` and cursor to aid in pagination. */
  edges: Array<PlaylistsTracksEdge>;
  /** A list of `PlaylistsTrack` objects. */
  nodes: Array<Maybe<PlaylistsTrack>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PlaylistsTrack` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PlaylistsTrack` edge in the connection. */
export type PlaylistsTracksEdge = {
  __typename?: 'PlaylistsTracksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PlaylistsTrack` at the end of the edge. */
  node?: Maybe<PlaylistsTrack>;
};

/** Methods to use when ordering `PlaylistsTrack`. */
export enum PlaylistsTracksOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PlaylistIdAsc = 'PLAYLIST_ID_ASC',
  PlaylistIdDesc = 'PLAYLIST_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TrackIdAsc = 'TRACK_ID_ASC',
  TrackIdDesc = 'TRACK_ID_DESC',
  TrackNumberAsc = 'TRACK_NUMBER_ASC',
  TrackNumberDesc = 'TRACK_NUMBER_DESC',
}

/** Presigned image with url and size */
export type PresignedImage = {
  __typename?: 'PresignedImage';
  /** One of the graphic image sizes (1920, 144 ...) */
  size?: Maybe<Scalars['Int']>;
  /** Presigned url for the image, e.g. https://media.blarecast.io/media/144x144/bb3b3e15-024f-4ff7-8b9d-71ce915725c0.jpg */
  url?: Maybe<Scalars['String']>;
};

/** `Price` table */
export type Price = Node & {
  __typename?: 'Price';
  /** An amount in cents. Eg: 0.0125 would represent 125 cents. Calc: 0.0125 * (10^4) */
  amount?: Maybe<Scalars['Int']>;
  /** Reads a single `Artist` that is related to this `Price`. */
  artistByCreatorId?: Maybe<Artist>;
  /** Defines the `Country` that the `Price` is bound to */
  country?: Maybe<Scalars['UUID']>;
  /** Reads a single `Country` that is related to this `Price`. */
  countryByCountry?: Maybe<Country>;
  /** Defines the datetime with time zone of `Price` was first created */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Defines the `Artist` that created the `Price` */
  creatorId?: Maybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Defines the amount in percentage offset from base price in `Country` */
  percentage?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `Royalty`. */
  royaltiesByPriceId: RoyaltiesConnection;
  /** Reads a single `Track` that is related to this `Price`. */
  trackByTrackId?: Maybe<Track>;
  /** The `Track this `Price` is set for */
  trackId: Scalars['UUID'];
  /** Reads and enables pagination through a set of `Track`. */
  tracksByRoyaltyPriceIdAndTrackId: PriceTracksByRoyaltyPriceIdAndTrackIdManyToManyConnection;
  /** Defines the `Price` is valid from */
  validFrom?: Maybe<Scalars['Datetime']>;
  /** Defines the `Price` is valid to */
  validUntil?: Maybe<Scalars['Datetime']>;
};

/** `Price` table */
export type PriceRoyaltiesByPriceIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RoyaltyCondition>;
  filter?: InputMaybe<RoyaltyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RoyaltiesOrderBy>>;
};

/** `Price` table */
export type PriceTracksByRoyaltyPriceIdAndTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** A condition to be used against `Price` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type PriceCondition = {
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `country` field. */
  country?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `creatorId` field. */
  creatorId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `percentage` field. */
  percentage?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `trackId` field. */
  trackId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `validFrom` field. */
  validFrom?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `validUntil` field. */
  validUntil?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `Price` object types. All fields are combined with a logical ‘and.’ */
export type PriceFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PriceFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PriceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PriceFilter>>;
  /** Filter by the object’s `percentage` field. */
  percentage?: InputMaybe<IntFilter>;
  /** Filter by the object’s `validFrom` field. */
  validFrom?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `validUntil` field. */
  validUntil?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `Price` */
export type PriceInput = {
  /** An amount in cents. Eg: 0.0125 would represent 125 cents. Calc: 0.0125 * (10^4) */
  amount?: InputMaybe<Scalars['Int']>;
  /** Defines the `Country` that the `Price` is bound to */
  country?: InputMaybe<Scalars['UUID']>;
  /** Defines the `Artist` that created the `Price` */
  creatorId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** Defines the amount in percentage offset from base price in `Country` */
  percentage?: InputMaybe<Scalars['Int']>;
  /** The `Track this `Price` is set for */
  trackId: Scalars['UUID'];
};

/** A connection to a list of `Track` values, with data from `Royalty`. */
export type PriceTracksByRoyaltyPriceIdAndTrackIdManyToManyConnection = {
  __typename?: 'PriceTracksByRoyaltyPriceIdAndTrackIdManyToManyConnection';
  /** A list of edges which contains the `Track`, info from the `Royalty`, and the cursor to aid in pagination. */
  edges: Array<PriceTracksByRoyaltyPriceIdAndTrackIdManyToManyEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Track` edge in the connection, with data from `Royalty`. */
export type PriceTracksByRoyaltyPriceIdAndTrackIdManyToManyEdge = {
  __typename?: 'PriceTracksByRoyaltyPriceIdAndTrackIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
  /** Reads and enables pagination through a set of `Royalty`. */
  royaltiesByTrackId: RoyaltiesConnection;
};

/** A `Track` edge in the connection, with data from `Royalty`. */
export type PriceTracksByRoyaltyPriceIdAndTrackIdManyToManyEdgeRoyaltiesByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RoyaltyCondition>;
  filter?: InputMaybe<RoyaltyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RoyaltiesOrderBy>>;
};

/** A connection to a list of `Price` values. */
export type PricesConnection = {
  __typename?: 'PricesConnection';
  /** A list of edges which contains the `Price` and cursor to aid in pagination. */
  edges: Array<PricesEdge>;
  /** A list of `Price` objects. */
  nodes: Array<Maybe<Price>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Price` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Price` edge in the connection. */
export type PricesEdge = {
  __typename?: 'PricesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Price` at the end of the edge. */
  node?: Maybe<Price>;
};

/** Methods to use when ordering `Price`. */
export enum PricesOrderBy {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatorIdAsc = 'CREATOR_ID_ASC',
  CreatorIdDesc = 'CREATOR_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PercentageAsc = 'PERCENTAGE_ASC',
  PercentageDesc = 'PERCENTAGE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TrackIdAsc = 'TRACK_ID_ASC',
  TrackIdDesc = 'TRACK_ID_DESC',
  ValidFromAsc = 'VALID_FROM_ASC',
  ValidFromDesc = 'VALID_FROM_DESC',
  ValidUntilAsc = 'VALID_UNTIL_ASC',
  ValidUntilDesc = 'VALID_UNTIL_DESC',
}

/** All input for the `processListenerPackagesFreeTrialExpired` mutation. */
export type ProcessListenerPackagesFreeTrialExpiredInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `processListenerPackagesFreeTrialExpired` mutation. */
export type ProcessListenerPackagesFreeTrialExpiredPayload = {
  __typename?: 'ProcessListenerPackagesFreeTrialExpiredPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  listenerPackages?: Maybe<Array<Maybe<ListenerPackage>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** `ProfilePublic` table */
export type ProfilePublic = Node & {
  __typename?: 'ProfilePublic';
  /** A unique url that identifies a `User` */
  blarecastUrl?: Maybe<Scalars['String']>;
  /**  Is updated when uploading new picture */
  coverImg?: Maybe<Scalars['String']>;
  /** A unique display name for `User` */
  displayName?: Maybe<Scalars['String']>;
  /** facebook url, should begin with https://www.facebook.com/ */
  facebook?: Maybe<Scalars['String']>;
  /** The amount of followers this `User` has */
  followerCount?: Maybe<Scalars['Int']>;
  /** The amount of followings this `User` has */
  followingCount?: Maybe<Scalars['Int']>;
  /** instagram url, should begin with https://www.instagram.com/ */
  instagram?: Maybe<Scalars['String']>;
  likedAsArtist?: Maybe<Scalars['Boolean']>;
  likedAsListener?: Maybe<Scalars['Boolean']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** A descriptive information regarding the `User` */
  personalBio?: Maybe<Scalars['String']>;
  presignedCoverImgs?: Maybe<Array<Maybe<PresignedImage>>>;
  presignedProfileImgs?: Maybe<Array<Maybe<PresignedImage>>>;
  /**  Is updated when uploading new picture */
  profileImg?: Maybe<Scalars['String']>;
  /** The text that a particular `UserProfile` will be searched by */
  textSearch?: Maybe<Scalars['String']>;
  /** twitter url, should begin with https://www.twitter.com/ */
  twitter?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `ProfilePublic`. */
  userByUserId?: Maybe<User>;
  /** The `User` unique identifier */
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `ProfilePublic` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProfilePublicCondition = {
  /** Checks for equality with the object’s `blarecastUrl` field. */
  blarecastUrl?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `coverImg` field. */
  coverImg?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `facebook` field. */
  facebook?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `followerCount` field. */
  followerCount?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `followingCount` field. */
  followingCount?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `instagram` field. */
  instagram?: InputMaybe<Scalars['String']>;
  /** Filters the list of profilePublic to only those which are verified */
  isVerifiedUser?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `personalBio` field. */
  personalBio?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `profileImg` field. */
  profileImg?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `textSearch` field. */
  textSearch?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `twitter` field. */
  twitter?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ProfilePublic` object types. All fields are combined with a logical ‘and.’ */
export type ProfilePublicFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProfilePublicFilter>>;
  /** Filter by the object’s `blarecastUrl` field. */
  blarecastUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `coverImg` field. */
  coverImg?: InputMaybe<StringFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `facebook` field. */
  facebook?: InputMaybe<StringFilter>;
  /** Filter by the object’s `followerCount` field. */
  followerCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `followingCount` field. */
  followingCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `instagram` field. */
  instagram?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProfilePublicFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProfilePublicFilter>>;
  /** Filter by the object’s `personalBio` field. */
  personalBio?: InputMaybe<StringFilter>;
  /** Filter by the object’s `profileImg` field. */
  profileImg?: InputMaybe<StringFilter>;
  /** Filter by the object’s `textSearch` field. */
  textSearch?: InputMaybe<StringFilter>;
  /** Filter by the object’s `twitter` field. */
  twitter?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `ProfilePublic` */
export type ProfilePublicInput = {
  /** A unique url that identifies a `User` */
  blarecastUrl?: InputMaybe<Scalars['String']>;
  /** A unique display name for `User` */
  displayName?: InputMaybe<Scalars['String']>;
  /** facebook url, should begin with https://www.facebook.com/ */
  facebook?: InputMaybe<Scalars['String']>;
  /** instagram url, should begin with https://www.instagram.com/ */
  instagram?: InputMaybe<Scalars['String']>;
  /** A descriptive information regarding the `User` */
  personalBio?: InputMaybe<Scalars['String']>;
  /** twitter url, should begin with https://www.twitter.com/ */
  twitter?: InputMaybe<Scalars['String']>;
  /** The `User` unique identifier */
  userId: Scalars['UUID'];
};

/** Represents an update to a `ProfilePublic`. Fields that are set will be updated. */
export type ProfilePublicPatch = {
  /** A unique url that identifies a `User` */
  blarecastUrl?: InputMaybe<Scalars['String']>;
  /** A unique display name for `User` */
  displayName?: InputMaybe<Scalars['String']>;
  /** facebook url, should begin with https://www.facebook.com/ */
  facebook?: InputMaybe<Scalars['String']>;
  /** instagram url, should begin with https://www.instagram.com/ */
  instagram?: InputMaybe<Scalars['String']>;
  /** A descriptive information regarding the `User` */
  personalBio?: InputMaybe<Scalars['String']>;
  /** twitter url, should begin with https://www.twitter.com/ */
  twitter?: InputMaybe<Scalars['String']>;
  /** The `User` unique identifier */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A connection to a list of `ProfilePublic` values. */
export type ProfilePublicsConnection = {
  __typename?: 'ProfilePublicsConnection';
  /** A list of edges which contains the `ProfilePublic` and cursor to aid in pagination. */
  edges: Array<ProfilePublicsEdge>;
  /** A list of `ProfilePublic` objects. */
  nodes: Array<Maybe<ProfilePublic>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProfilePublic` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProfilePublic` edge in the connection. */
export type ProfilePublicsEdge = {
  __typename?: 'ProfilePublicsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ProfilePublic` at the end of the edge. */
  node?: Maybe<ProfilePublic>;
};

/** Methods to use when ordering `ProfilePublic`. */
export enum ProfilePublicsOrderBy {
  BlarecastUrlAsc = 'BLARECAST_URL_ASC',
  BlarecastUrlDesc = 'BLARECAST_URL_DESC',
  CoverImgAsc = 'COVER_IMG_ASC',
  CoverImgDesc = 'COVER_IMG_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  FacebookAsc = 'FACEBOOK_ASC',
  FacebookDesc = 'FACEBOOK_DESC',
  FollowerCountAsc = 'FOLLOWER_COUNT_ASC',
  FollowerCountDesc = 'FOLLOWER_COUNT_DESC',
  FollowingCountAsc = 'FOLLOWING_COUNT_ASC',
  FollowingCountDesc = 'FOLLOWING_COUNT_DESC',
  InstagramAsc = 'INSTAGRAM_ASC',
  InstagramDesc = 'INSTAGRAM_DESC',
  Natural = 'NATURAL',
  PersonalBioAsc = 'PERSONAL_BIO_ASC',
  PersonalBioDesc = 'PERSONAL_BIO_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProfileImgAsc = 'PROFILE_IMG_ASC',
  ProfileImgDesc = 'PROFILE_IMG_DESC',
  TextSearchAsc = 'TEXT_SEARCH_ASC',
  TextSearchDesc = 'TEXT_SEARCH_DESC',
  TwitterAsc = 'TWITTER_ASC',
  TwitterDesc = 'TWITTER_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
}

/** `ProfileSettings` table - belongs to each `User` account and it stores profile settings */
export type ProfileSetting = Node & {
  __typename?: 'ProfileSetting';
  /** `User` account type */
  accountType?: Maybe<AccountType>;
  /** Reads a single `Country` that is related to this `ProfileSetting`. */
  countryByGeolocation?: Maybe<Country>;
  /** Defines the default profile eg: `Artist` | `User` is selected */
  defaultProfile?: Maybe<ProfileSettingsDefaultProfile>;
  email?: Maybe<Scalars['String']>;
  /** `User` first name */
  firstName?: Maybe<Scalars['String']>;
  /** Defines where the `User` is from so we know which resources he has access to. Must be one of supported `Countries` */
  geolocation?: Maybe<Scalars['UUID']>;
  /** `User` last name */
  lastName?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `RecordLabel` that is related to this `ProfileSetting`. */
  recordLabelByRecordLabelId?: Maybe<RecordLabel>;
  /** Defines the `RecordLabel` if one is set */
  recordLabelId?: Maybe<Scalars['UUID']>;
  /** Defines if the `User` has completed registration flow by adding a payment method to the platform. */
  registered?: Maybe<Scalars['Boolean']>;
  /** Defines if user wants to be billed automatically */
  topUp?: Maybe<Scalars['Boolean']>;
  /** Reads a single `User` that is related to this `ProfileSetting`. */
  userByUserId?: Maybe<User>;
  /** The id of the `User` associated with this account */
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `ProfileSetting` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProfileSettingCondition = {
  /** Checks for equality with the object’s `accountType` field. */
  accountType?: InputMaybe<AccountType>;
  /** Checks for equality with the object’s `defaultProfile` field. */
  defaultProfile?: InputMaybe<ProfileSettingsDefaultProfile>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `geolocation` field. */
  geolocation?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `recordLabelId` field. */
  recordLabelId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `registered` field. */
  registered?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `topUp` field. */
  topUp?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ProfileSetting` object types. All fields are combined with a logical ‘and.’ */
export type ProfileSettingFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProfileSettingFilter>>;
  /** Filter by the object’s `firstName` field. */
  firstName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProfileSettingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProfileSettingFilter>>;
};

/** Represents an update to a `ProfileSetting`. Fields that are set will be updated. */
export type ProfileSettingPatch = {
  /** `User` account type */
  accountType?: InputMaybe<AccountType>;
  /** Defines the default profile eg: `Artist` | `User` is selected */
  defaultProfile?: InputMaybe<ProfileSettingsDefaultProfile>;
  /** `User` first name */
  firstName?: InputMaybe<Scalars['String']>;
  /** `User` last name */
  lastName?: InputMaybe<Scalars['String']>;
  /** Defines if user wants to be billed automatically */
  topUp?: InputMaybe<Scalars['Boolean']>;
  /** The id of the `User` associated with this account */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A connection to a list of `ProfileSetting` values. */
export type ProfileSettingsConnection = {
  __typename?: 'ProfileSettingsConnection';
  /** A list of edges which contains the `ProfileSetting` and cursor to aid in pagination. */
  edges: Array<ProfileSettingsEdge>;
  /** A list of `ProfileSetting` objects. */
  nodes: Array<Maybe<ProfileSetting>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProfileSetting` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export enum ProfileSettingsDefaultProfile {
  Artist = 'ARTIST',
  Listener = 'LISTENER',
}

/** A `ProfileSetting` edge in the connection. */
export type ProfileSettingsEdge = {
  __typename?: 'ProfileSettingsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ProfileSetting` at the end of the edge. */
  node?: Maybe<ProfileSetting>;
};

/** Methods to use when ordering `ProfileSetting`. */
export enum ProfileSettingsOrderBy {
  AccountTypeAsc = 'ACCOUNT_TYPE_ASC',
  AccountTypeDesc = 'ACCOUNT_TYPE_DESC',
  DefaultProfileAsc = 'DEFAULT_PROFILE_ASC',
  DefaultProfileDesc = 'DEFAULT_PROFILE_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  GeolocationAsc = 'GEOLOCATION_ASC',
  GeolocationDesc = 'GEOLOCATION_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RecordLabelIdAsc = 'RECORD_LABEL_ID_ASC',
  RecordLabelIdDesc = 'RECORD_LABEL_ID_DESC',
  RegisteredAsc = 'REGISTERED_ASC',
  RegisteredDesc = 'REGISTERED_DESC',
  TopUpAsc = 'TOP_UP_ASC',
  TopUpDesc = 'TOP_UP_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
}

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  accountBalanceConsumption?: Maybe<AccountBalanceConsumptionPayload>;
  /** Reads a single `Album` using its globally unique `ID`. */
  album?: Maybe<Album>;
  albumById?: Maybe<Album>;
  albumTotalEarnings?: Maybe<Scalars['BigInt']>;
  /** Reads a single `AlbumsTrack` using its globally unique `ID`. */
  albumsTrack?: Maybe<AlbumsTrack>;
  albumsTrackByAlbumIdAndTrackId?: Maybe<AlbumsTrack>;
  /** Reads and enables pagination through a set of `Album`. */
  allAlbums?: Maybe<AlbumsConnection>;
  /** Reads and enables pagination through a set of `AlbumsTrack`. */
  allAlbumsTracks?: Maybe<AlbumsTracksConnection>;
  /** Reads and enables pagination through a set of `ArtistMember`. */
  allArtistMembers?: Maybe<ArtistMembersConnection>;
  /** Reads and enables pagination through a set of `ArtistSubscriptionType`. */
  allArtistSubscriptionTypes?: Maybe<ArtistSubscriptionTypesConnection>;
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  allArtistSubscriptions?: Maybe<ArtistSubscriptionsConnection>;
  /** Reads and enables pagination through a set of `Artist`. */
  allArtists?: Maybe<ArtistsConnection>;
  /** Reads and enables pagination through a set of `AutoGeneratedQueue`. */
  allAutoGeneratedQueues?: Maybe<AutoGeneratedQueuesConnection>;
  /** Reads and enables pagination through a set of `BlarecastUrl`. */
  allBlarecastUrls?: Maybe<BlarecastUrlsConnection>;
  /** Reads and enables pagination through a set of `Country`. */
  allCountries?: Maybe<CountriesConnection>;
  /** Reads and enables pagination through a set of `CountriesTrack`. */
  allCountriesTracks?: Maybe<CountriesTracksConnection>;
  /** Reads and enables pagination through a set of `Currency`. */
  allCurrencies?: Maybe<CurrenciesConnection>;
  /** Reads and enables pagination through a set of `Document`. */
  allDocuments?: Maybe<DocumentsConnection>;
  /** Reads and enables pagination through a set of `EarningsHistory`. */
  allEarningsHistories?: Maybe<EarningsHistoriesConnection>;
  /** Reads and enables pagination through a set of `Event`. */
  allEvents?: Maybe<EventsConnection>;
  /** Reads and enables pagination through a set of `ExtraArtist`. */
  allExtraArtists?: Maybe<ExtraArtistsConnection>;
  /** Reads and enables pagination through a set of `FeaturedArtist`. */
  allFeaturedArtists?: Maybe<FeaturedArtistsConnection>;
  /** Reads and enables pagination through a set of `FirebaseToken`. */
  allFirebaseTokens?: Maybe<FirebaseTokensConnection>;
  /** Reads and enables pagination through a set of `FlywaySchemaHistory`. */
  allFlywaySchemaHistories?: Maybe<FlywaySchemaHistoriesConnection>;
  /** Reads and enables pagination through a set of `Follower`. */
  allFollowers?: Maybe<FollowersConnection>;
  /** Reads and enables pagination through a set of `Following`. */
  allFollowings?: Maybe<FollowingsConnection>;
  /** Reads and enables pagination through a set of `Genre`. */
  allGenres?: Maybe<GenresConnection>;
  /** Reads and enables pagination through a set of `GenresAlbum`. */
  allGenresAlbums?: Maybe<GenresAlbumsConnection>;
  /** Reads and enables pagination through a set of `LastPlayedTrack`. */
  allLastPlayedTracks?: Maybe<LastPlayedTracksConnection>;
  /** Reads and enables pagination through a set of `Like`. */
  allLikes?: Maybe<LikesConnection>;
  /** Reads and enables pagination through a set of `ListenerPackageType`. */
  allListenerPackageTypes?: Maybe<ListenerPackageTypesConnection>;
  /** Reads and enables pagination through a set of `ListenerPackage`. */
  allListenerPackages?: Maybe<ListenerPackagesConnection>;
  /** Reads and enables pagination through a set of `Notification`. */
  allNotifications?: Maybe<NotificationsConnection>;
  /** Reads and enables pagination through a set of `PlayHistory`. */
  allPlayHistories?: Maybe<PlayHistoriesConnection>;
  /** Reads and enables pagination through a set of `Playlist`. */
  allPlaylists?: Maybe<PlaylistsConnection>;
  /** Reads and enables pagination through a set of `PlaylistsTrack`. */
  allPlaylistsTracks?: Maybe<PlaylistsTracksConnection>;
  /** Reads and enables pagination through a set of `Price`. */
  allPrices?: Maybe<PricesConnection>;
  /** Reads and enables pagination through a set of `ProfilePublic`. */
  allProfilePublics?: Maybe<ProfilePublicsConnection>;
  /** Reads and enables pagination through a set of `ProfileSetting`. */
  allProfileSettings?: Maybe<ProfileSettingsConnection>;
  /** Reads and enables pagination through a set of `RecentlyPlayed`. */
  allRecentlyPlayeds?: Maybe<RecentlyPlayedsConnection>;
  /** Reads and enables pagination through a set of `RecordLabel`. */
  allRecordLabels?: Maybe<RecordLabelsConnection>;
  /** Reads and enables pagination through a set of `Royalty`. */
  allRoyalties?: Maybe<RoyaltiesConnection>;
  /** Reads and enables pagination through a set of `SearchHistory`. */
  allSearchHistories?: Maybe<SearchHistoriesConnection>;
  /** Reads and enables pagination through a set of `SearchItem`. */
  allSearchItems?: Maybe<SearchItemsConnection>;
  /** Reads and enables pagination through a set of `StreamsHistory`. */
  allStreamsHistories?: Maybe<StreamsHistoriesConnection>;
  /** Reads and enables pagination through a set of `Track`. */
  allTracks?: Maybe<TracksConnection>;
  /** Reads and enables pagination through a set of `TracksAlbum`. */
  allTracksAlbums?: Maybe<TracksAlbumsConnection>;
  /** Reads and enables pagination through a set of `TracksArtist`. */
  allTracksArtists?: Maybe<TracksArtistsConnection>;
  /** Reads and enables pagination through a set of `TracksEarning`. */
  allTracksEarnings?: Maybe<TracksEarningsConnection>;
  /** Reads and enables pagination through a set of `TracksGenre`. */
  allTracksGenres?: Maybe<TracksGenresConnection>;
  /** Reads and enables pagination through a set of `UserVerification`. */
  allUserVerifications?: Maybe<UserVerificationsConnection>;
  /** Reads and enables pagination through a set of `User`. */
  allUsers?: Maybe<UsersConnection>;
  /** Reads and enables pagination through a set of `UsersAccountBalance`. */
  allUsersAccountBalances?: Maybe<UsersAccountBalancesConnection>;
  /** Reads and enables pagination through a set of `UsersBalanceConsumption`. */
  allUsersBalanceConsumptions?: Maybe<UsersBalanceConsumptionsConnection>;
  /** Reads and enables pagination through a set of `UsersNotification`. */
  allUsersNotifications?: Maybe<UsersNotificationsConnection>;
  /** Reads and enables pagination through a set of `UsersQueue`. */
  allUsersQueues?: Maybe<UsersQueuesConnection>;
  /** Reads a single `Artist` using its globally unique `ID`. */
  artist?: Maybe<Artist>;
  artistByBlarecastUrl?: Maybe<Artist>;
  artistById?: Maybe<Artist>;
  /** Reads a single `ArtistMember` using its globally unique `ID`. */
  artistMember?: Maybe<ArtistMember>;
  artistMemberByArtistIdAndUserId?: Maybe<ArtistMember>;
  artistMemberById?: Maybe<ArtistMember>;
  /** Reads a single `ArtistSubscription` using its globally unique `ID`. */
  artistSubscription?: Maybe<ArtistSubscription>;
  artistSubscriptionById?: Maybe<ArtistSubscription>;
  /** Reads a single `ArtistSubscriptionType` using its globally unique `ID`. */
  artistSubscriptionType?: Maybe<ArtistSubscriptionType>;
  artistSubscriptionTypeById?: Maybe<ArtistSubscriptionType>;
  /** Reads a single `AutoGeneratedQueue` using its globally unique `ID`. */
  autoGeneratedQueue?: Maybe<AutoGeneratedQueue>;
  autoGeneratedQueueById?: Maybe<AutoGeneratedQueue>;
  canDeleteArtistMember?: Maybe<Scalars['Boolean']>;
  countriesTrackByTrackIdAndCountryId?: Maybe<CountriesTrack>;
  /** Reads a single `Country` using its globally unique `ID`. */
  country?: Maybe<Country>;
  countryById?: Maybe<Country>;
  /** Reads a single `Currency` using its globally unique `ID`. */
  currency?: Maybe<Currency>;
  currencyById?: Maybe<Currency>;
  currentEmail?: Maybe<Scalars['String']>;
  currentListenerPackage?: Maybe<ListenerPackage>;
  currentSubscription?: Maybe<ArtistSubscription>;
  /** Gets the person who was identified by our JWT. */
  currentUser?: Maybe<User>;
  /** Reads a single `Document` using its globally unique `ID`. */
  document?: Maybe<Document>;
  documentById?: Maybe<Document>;
  documentBySlug?: Maybe<Document>;
  /** Reads a single `EarningsHistory` using its globally unique `ID`. */
  earningsHistory?: Maybe<EarningsHistory>;
  earningsHistoryById?: Maybe<EarningsHistory>;
  /** Reads a single `Event` using its globally unique `ID`. */
  event?: Maybe<Event>;
  eventById?: Maybe<Event>;
  /** Reads a single `ExtraArtist` using its globally unique `ID`. */
  extraArtist?: Maybe<ExtraArtist>;
  extraArtistById?: Maybe<ExtraArtist>;
  /** Reads a single `FeaturedArtist` using its globally unique `ID`. */
  featuredArtist?: Maybe<FeaturedArtist>;
  featuredArtistById?: Maybe<FeaturedArtist>;
  /** Reads and enables pagination through a set of `Price`. */
  fetchActiveTrackPrices?: Maybe<PricesConnection>;
  /** Reads a single `FirebaseToken` using its globally unique `ID`. */
  firebaseToken?: Maybe<FirebaseToken>;
  firebaseTokenById?: Maybe<FirebaseToken>;
  firebaseTokenByToken?: Maybe<FirebaseToken>;
  firebaseTokenByUserIdAndDevice?: Maybe<FirebaseToken>;
  /** Reads a single `FlywaySchemaHistory` using its globally unique `ID`. */
  flywaySchemaHistory?: Maybe<FlywaySchemaHistory>;
  flywaySchemaHistoryByInstalledRank?: Maybe<FlywaySchemaHistory>;
  /** Reads a single `Genre` using its globally unique `ID`. */
  genre?: Maybe<Genre>;
  genreById?: Maybe<Genre>;
  getCountryData?: Maybe<Country>;
  getCurrentUserId?: Maybe<Scalars['UUID']>;
  getJsonGenres?: Maybe<Scalars['JSON']>;
  /** Reads and enables pagination through a set of `RoyaltyRow`. */
  getRoyalties?: Maybe<RoyaltyRowsConnection>;
  getSubscriptionProperties?: Maybe<TrackSub>;
  getTrackPrice?: Maybe<TrackPrice>;
  hasSocialMediaEmail?: Maybe<Scalars['Boolean']>;
  isAlbumTrackOwner?: Maybe<Scalars['Boolean']>;
  isArtistUser?: Maybe<Scalars['Boolean']>;
  isRegisteredWithSocialMedia?: Maybe<Scalars['Boolean']>;
  isUserVerified?: Maybe<Scalars['Boolean']>;
  /** Reads a single `LastPlayedTrack` using its globally unique `ID`. */
  lastPlayedTrack?: Maybe<LastPlayedTrack>;
  lastPlayedTrackById?: Maybe<LastPlayedTrack>;
  lastPlayedTrackByTrackIdAndUserId?: Maybe<LastPlayedTrack>;
  /** Reads a single `Like` using its globally unique `ID`. */
  like?: Maybe<Like>;
  likeById?: Maybe<Like>;
  /** Reads a single `ListenerPackage` using its globally unique `ID`. */
  listenerPackage?: Maybe<ListenerPackage>;
  listenerPackageById?: Maybe<ListenerPackage>;
  /** Reads a single `ListenerPackageType` using its globally unique `ID`. */
  listenerPackageType?: Maybe<ListenerPackageType>;
  listenerPackageTypeById?: Maybe<ListenerPackageType>;
  myEarnings?: Maybe<MyEarnings>;
  myStreams?: Maybe<MyStreams>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  /** Reads a single `Notification` using its globally unique `ID`. */
  notification?: Maybe<Notification>;
  notificationById?: Maybe<Notification>;
  /** Reads and enables pagination through a set of `Royalty`. */
  ownerships?: Maybe<RoyaltiesConnection>;
  paymentHistory?: Maybe<Array<Maybe<PaymentHistory>>>;
  /** Reads a single `PlayHistory` using its globally unique `ID`. */
  playHistory?: Maybe<PlayHistory>;
  playHistoryById?: Maybe<PlayHistory>;
  /** Reads a single `Playlist` using its globally unique `ID`. */
  playlist?: Maybe<Playlist>;
  playlistById?: Maybe<Playlist>;
  /** Reads a single `PlaylistsTrack` using its globally unique `ID`. */
  playlistsTrack?: Maybe<PlaylistsTrack>;
  playlistsTrackById?: Maybe<PlaylistsTrack>;
  playlistsTrackByTrackIdAndPlaylistId?: Maybe<PlaylistsTrack>;
  /** Reads a single `Price` using its globally unique `ID`. */
  price?: Maybe<Price>;
  priceById?: Maybe<Price>;
  /** Reads a single `ProfilePublic` using its globally unique `ID`. */
  profilePublic?: Maybe<ProfilePublic>;
  profilePublicByBlarecastUrl?: Maybe<ProfilePublic>;
  profilePublicByUserId?: Maybe<ProfilePublic>;
  /** Reads a single `ProfileSetting` using its globally unique `ID`. */
  profileSetting?: Maybe<ProfileSetting>;
  profileSettingByUserId?: Maybe<ProfileSetting>;
  /** Reads and enables pagination through a set of `ProfileSetting`. */
  profileSettingsByEmail?: Maybe<ProfileSettingsConnection>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** Reads a single `RecentlyPlayed` using its globally unique `ID`. */
  recentlyPlayed?: Maybe<RecentlyPlayed>;
  recentlyPlayedById?: Maybe<RecentlyPlayed>;
  /** Reads a single `RecordLabel` using its globally unique `ID`. */
  recordLabel?: Maybe<RecordLabel>;
  recordLabelById?: Maybe<RecordLabel>;
  /** Reads a single `Royalty` using its globally unique `ID`. */
  royalty?: Maybe<Royalty>;
  royaltyById?: Maybe<Royalty>;
  /** Returns albums containing the keyword in the name or description */
  searchAlbums?: Maybe<AlbumsConnection>;
  /** Returns artists containing a given search term in name, first name or last_name */
  searchArtists?: Maybe<ArtistsConnection>;
  /** Reads and enables pagination through a set of `BlarecastUrl`. */
  searchBlarecastUrls?: Maybe<BlarecastUrlsConnection>;
  /** Returns genres containing a given search term. */
  searchGenres?: Maybe<GenresConnection>;
  /** Reads a single `SearchHistory` using its globally unique `ID`. */
  searchHistory?: Maybe<SearchHistory>;
  searchHistoryById?: Maybe<SearchHistory>;
  /** Returns playlists with the keyword in the title and playlists that have track with this title. */
  searchPlaylists?: Maybe<PlaylistsConnection>;
  /** Returns tracks containing the keyword in the title. */
  searchTracks?: Maybe<TracksConnection>;
  /** Reads a single `StreamsHistory` using its globally unique `ID`. */
  streamsHistory?: Maybe<StreamsHistory>;
  streamsHistoryById?: Maybe<StreamsHistory>;
  /** Reads a single `Track` using its globally unique `ID`. */
  track?: Maybe<Track>;
  trackById?: Maybe<Track>;
  trackTotalEarnings?: Maybe<Scalars['BigInt']>;
  tracksPrices?: Maybe<Scalars['BigInt']>;
  /** Reads a single `User` using its globally unique `ID`. */
  user?: Maybe<User>;
  userById?: Maybe<User>;
  /** Reads a single `UserVerification` using its globally unique `ID`. */
  userVerification?: Maybe<UserVerification>;
  userVerificationByUserId?: Maybe<UserVerification>;
  /** Reads a single `UsersAccountBalance` using its globally unique `ID`. */
  usersAccountBalance?: Maybe<UsersAccountBalance>;
  usersAccountBalanceByUserId?: Maybe<UsersAccountBalance>;
  /** Reads a single `UsersBalanceConsumption` using its globally unique `ID`. */
  usersBalanceConsumption?: Maybe<UsersBalanceConsumption>;
  usersBalanceConsumptionById?: Maybe<UsersBalanceConsumption>;
  /** Reads a single `UsersNotification` using its globally unique `ID`. */
  usersNotification?: Maybe<UsersNotification>;
  usersNotificationByUserId?: Maybe<UsersNotification>;
  /** Reads a single `UsersQueue` using its globally unique `ID`. */
  usersQueue?: Maybe<UsersQueue>;
  usersQueueById?: Maybe<UsersQueue>;
  /** Verify if record exists */
  verifyRecord?: Maybe<Scalars['Boolean']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountBalanceConsumptionArgs = {
  from?: InputMaybe<Scalars['Date']>;
  to?: InputMaybe<Scalars['Date']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAlbumArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAlbumByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAlbumTotalEarningsArgs = {
  albumId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAlbumsTrackArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAlbumsTrackByAlbumIdAndTrackIdArgs = {
  albumId: Scalars['UUID'];
  trackId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAlbumsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAlbumsTracksArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumsTrackCondition>;
  filter?: InputMaybe<AlbumsTrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsTracksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllArtistMembersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistMemberCondition>;
  filter?: InputMaybe<ArtistMemberFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistMembersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllArtistSubscriptionTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionTypeCondition>;
  filter?: InputMaybe<ArtistSubscriptionTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionTypesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllArtistSubscriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllArtistsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAutoGeneratedQueuesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AutoGeneratedQueueCondition>;
  filter?: InputMaybe<AutoGeneratedQueueFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AutoGeneratedQueuesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBlarecastUrlsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BlarecastUrlCondition>;
  filter?: InputMaybe<BlarecastUrlFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BlarecastUrlsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllCountriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CountryCondition>;
  filter?: InputMaybe<CountryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllCountriesTracksArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CountriesTrackCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountriesTracksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllCurrenciesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CurrencyCondition>;
  filter?: InputMaybe<CurrencyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrenciesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllDocumentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<DocumentCondition>;
  filter?: InputMaybe<DocumentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllEarningsHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EarningsHistoryCondition>;
  filter?: InputMaybe<EarningsHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EarningsHistoriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EventCondition>;
  filter?: InputMaybe<EventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllExtraArtistsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ExtraArtistCondition>;
  filter?: InputMaybe<ExtraArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExtraArtistsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFeaturedArtistsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FeaturedArtistCondition>;
  filter?: InputMaybe<FeaturedArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeaturedArtistsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFirebaseTokensArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FirebaseTokenCondition>;
  filter?: InputMaybe<FirebaseTokenFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FirebaseTokensOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFlywaySchemaHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FlywaySchemaHistoryCondition>;
  filter?: InputMaybe<FlywaySchemaHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FlywaySchemaHistoriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFollowersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FollowerCondition>;
  filter?: InputMaybe<FollowerFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FollowersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFollowingsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FollowingCondition>;
  filter?: InputMaybe<FollowingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FollowingsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllGenresArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenreCondition>;
  filter?: InputMaybe<GenreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenresOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllGenresAlbumsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenresAlbumCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenresAlbumsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllLastPlayedTracksArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LastPlayedTrackCondition>;
  filter?: InputMaybe<LastPlayedTrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LastPlayedTracksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllLikesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllListenerPackageTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ListenerPackageTypeCondition>;
  filter?: InputMaybe<ListenerPackageTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ListenerPackageTypesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllListenerPackagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ListenerPackageCondition>;
  filter?: InputMaybe<ListenerPackageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ListenerPackagesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllNotificationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NotificationCondition>;
  filter?: InputMaybe<NotificationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NotificationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllPlayHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PlayHistoryCondition>;
  filter?: InputMaybe<PlayHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlayHistoriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllPlaylistsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PlaylistCondition>;
  filter?: InputMaybe<PlaylistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllPlaylistsTracksArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PlaylistsTrackCondition>;
  filter?: InputMaybe<PlaylistsTrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlaylistsTracksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllPricesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PriceCondition>;
  filter?: InputMaybe<PriceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllProfilePublicsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProfilePublicCondition>;
  filter?: InputMaybe<ProfilePublicFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProfilePublicsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllProfileSettingsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProfileSettingCondition>;
  filter?: InputMaybe<ProfileSettingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProfileSettingsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllRecentlyPlayedsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecentlyPlayedCondition>;
  filter?: InputMaybe<RecentlyPlayedFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecentlyPlayedsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllRecordLabelsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecordLabelCondition>;
  filter?: InputMaybe<RecordLabelFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecordLabelsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllRoyaltiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RoyaltyCondition>;
  filter?: InputMaybe<RoyaltyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RoyaltiesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllSearchHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SearchHistoryCondition>;
  filter?: InputMaybe<SearchHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SearchHistoriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllSearchItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SearchItemCondition>;
  filter?: InputMaybe<SearchItemFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SearchItemsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllStreamsHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<StreamsHistoryCondition>;
  filter?: InputMaybe<StreamsHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StreamsHistoriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTracksArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTracksAlbumsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TracksAlbumCondition>;
  filter?: InputMaybe<TracksAlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksAlbumsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTracksArtistsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TracksArtistCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksArtistsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTracksEarningsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TracksEarningCondition>;
  filter?: InputMaybe<TracksEarningFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksEarningsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTracksGenresArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TracksGenreCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksGenresOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllUserVerificationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserVerificationCondition>;
  filter?: InputMaybe<UserVerificationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserVerificationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllUsersAccountBalancesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UsersAccountBalanceCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersAccountBalancesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllUsersBalanceConsumptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UsersBalanceConsumptionCondition>;
  filter?: InputMaybe<UsersBalanceConsumptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersBalanceConsumptionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllUsersNotificationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UsersNotificationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersNotificationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllUsersQueuesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UsersQueueCondition>;
  filter?: InputMaybe<UsersQueueFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersQueuesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryArtistArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryArtistByBlarecastUrlArgs = {
  blarecastUrl: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryArtistByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryArtistMemberArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryArtistMemberByArtistIdAndUserIdArgs = {
  artistId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryArtistMemberByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryArtistSubscriptionArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryArtistSubscriptionByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryArtistSubscriptionTypeArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryArtistSubscriptionTypeByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAutoGeneratedQueueArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAutoGeneratedQueueByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCanDeleteArtistMemberArgs = {
  artistUuid?: InputMaybe<Scalars['UUID']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryCountriesTrackByTrackIdAndCountryIdArgs = {
  countryId: Scalars['UUID'];
  trackId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCountryArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCountryByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCurrencyArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCurrencyByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDocumentArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDocumentByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDocumentBySlugArgs = {
  slug: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEarningsHistoryArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEarningsHistoryByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEventArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEventByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryExtraArtistArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryExtraArtistByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeaturedArtistArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeaturedArtistByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFetchActiveTrackPricesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['UUID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFirebaseTokenArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFirebaseTokenByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFirebaseTokenByTokenArgs = {
  token: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFirebaseTokenByUserIdAndDeviceArgs = {
  device: DeviceType;
  userId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFlywaySchemaHistoryArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFlywaySchemaHistoryByInstalledRankArgs = {
  installedRank: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryGenreArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryGenreByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryGetCountryDataArgs = {
  country?: InputMaybe<Scalars['UUID']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetRoyaltiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  priceId?: InputMaybe<Scalars['UUID']>;
  trackId?: InputMaybe<Scalars['UUID']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetSubscriptionPropertiesArgs = {
  trackId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryGetTrackPriceArgs = {
  track?: InputMaybe<Scalars['UUID']>;
  user?: InputMaybe<Scalars['UUID']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryIsAlbumTrackOwnerArgs = {
  albumId?: InputMaybe<Scalars['UUID']>;
  trackId?: InputMaybe<Scalars['UUID']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryIsArtistUserArgs = {
  artistId?: InputMaybe<Scalars['UUID']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLastPlayedTrackArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryLastPlayedTrackByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryLastPlayedTrackByTrackIdAndUserIdArgs = {
  trackId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryLikeArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryLikeByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryListenerPackageArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryListenerPackageByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryListenerPackageTypeArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryListenerPackageTypeByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMyEarningsArgs = {
  from: Scalars['Date'];
  orderBy?: InputMaybe<Array<MyEarningsOrderBy>>;
  to: Scalars['Date'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMyStreamsArgs = {
  from: Scalars['Date'];
  orderBy?: InputMaybe<Array<MyStreamsOrderBy>>;
  to: Scalars['Date'];
};

/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryNotificationArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryNotificationByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryOwnershipsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  isArtist?: InputMaybe<Scalars['Boolean']>;
  isArtistMember?: InputMaybe<Scalars['Boolean']>;
  isCeltzer?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPlayHistoryArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPlayHistoryByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPlaylistArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPlaylistByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPlaylistsTrackArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPlaylistsTrackByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPlaylistsTrackByTrackIdAndPlaylistIdArgs = {
  playlistId: Scalars['UUID'];
  trackId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPriceArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPriceByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProfilePublicArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProfilePublicByBlarecastUrlArgs = {
  blarecastUrl: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProfilePublicByUserIdArgs = {
  userId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProfileSettingArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProfileSettingByUserIdArgs = {
  userId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProfileSettingsByEmailArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  email?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryRecentlyPlayedArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryRecentlyPlayedByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryRecordLabelArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryRecordLabelByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryRoyaltyArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryRoyaltyByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchAlbumsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchArtistsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchBlarecastUrlsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  artist?: InputMaybe<Scalars['UUID']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  searchFilter?: InputMaybe<BlarecastUrlSearchType>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchGenresArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchHistoryArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchHistoryByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchPlaylistsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchTracksArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryStreamsHistoryArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryStreamsHistoryByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTrackArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTrackByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTrackTotalEarningsArgs = {
  trackId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTracksPricesArgs = {
  track: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserVerificationArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserVerificationByUserIdArgs = {
  userId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUsersAccountBalanceArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUsersAccountBalanceByUserIdArgs = {
  userId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUsersBalanceConsumptionArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUsersBalanceConsumptionByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUsersNotificationArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUsersNotificationByUserIdArgs = {
  userId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUsersQueueArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUsersQueueByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryVerifyRecordArgs = {
  assetId: Scalars['UUID'];
  type: RecordType;
  userId: Scalars['UUID'];
};

/** `RecentlyPlayed` table */
export type RecentlyPlayed = Node & {
  __typename?: 'RecentlyPlayed';
  /** Reads a single `Album` that is related to this `RecentlyPlayed`. */
  albumByAlbumId?: Maybe<Album>;
  /** The id of the `Album` associated with this id. */
  albumId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Artist` that is related to this `RecentlyPlayed`. */
  artistByArtistId?: Maybe<Artist>;
  /** The id of the `Artist` associated with this id. */
  artistId?: Maybe<Scalars['UUID']>;
  /** Defines the date of `RecentlyPlayed` was first created */
  createdAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Playlist` that is related to this `RecentlyPlayed`. */
  playlistByPlaylistId?: Maybe<Playlist>;
  /** The id of the `Playlist` associated with this id. */
  playlistId?: Maybe<Scalars['UUID']>;
  /** Reads a single `User` that is related to this `RecentlyPlayed`. */
  userByUserId?: Maybe<User>;
  /** The id of the `User` associated with this account. */
  userId?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `RecentlyPlayed` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type RecentlyPlayedCondition = {
  /** Checks for equality with the object’s `albumId` field. */
  albumId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `artistId` field. */
  artistId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `playlistId` field. */
  playlistId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `RecentlyPlayed` object types. All fields are combined with a logical ‘and.’ */
export type RecentlyPlayedFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RecentlyPlayedFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<RecentlyPlayedFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RecentlyPlayedFilter>>;
};

/** An input for mutations affecting `RecentlyPlayed` */
export type RecentlyPlayedInput = {
  /** The id of the `Album` associated with this id. */
  albumId?: InputMaybe<Scalars['UUID']>;
  /** The id of the `Artist` associated with this id. */
  artistId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** The id of the `Playlist` associated with this id. */
  playlistId?: InputMaybe<Scalars['UUID']>;
  /** The id of the `User` associated with this account. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A connection to a list of `RecentlyPlayed` values. */
export type RecentlyPlayedsConnection = {
  __typename?: 'RecentlyPlayedsConnection';
  /** A list of edges which contains the `RecentlyPlayed` and cursor to aid in pagination. */
  edges: Array<RecentlyPlayedsEdge>;
  /** A list of `RecentlyPlayed` objects. */
  nodes: Array<Maybe<RecentlyPlayed>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `RecentlyPlayed` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `RecentlyPlayed` edge in the connection. */
export type RecentlyPlayedsEdge = {
  __typename?: 'RecentlyPlayedsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RecentlyPlayed` at the end of the edge. */
  node?: Maybe<RecentlyPlayed>;
};

/** Methods to use when ordering `RecentlyPlayed`. */
export enum RecentlyPlayedsOrderBy {
  AlbumIdAsc = 'ALBUM_ID_ASC',
  AlbumIdDesc = 'ALBUM_ID_DESC',
  ArtistIdAsc = 'ARTIST_ID_ASC',
  ArtistIdDesc = 'ARTIST_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PlaylistIdAsc = 'PLAYLIST_ID_ASC',
  PlaylistIdDesc = 'PLAYLIST_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
}

/** `RecordLabel` table */
export type RecordLabel = Node & {
  __typename?: 'RecordLabel';
  /** Reads and enables pagination through a set of `Album`. */
  albumsByPublisher: AlbumsConnection;
  /** Reads and enables pagination through a set of `Album`. */
  albumsByTrackPublisherAndAlbum: RecordLabelAlbumsByTrackPublisherAndAlbumManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByAlbumPublisherAndArtist: RecordLabelArtistsByAlbumPublisherAndArtistManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByTrackPublisherAndArtist: RecordLabelArtistsByTrackPublisherAndArtistManyToManyConnection;
  /** Is this `RecordLabel` actually confirmed for business */
  confirmed?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `Country`. */
  countriesByProfileSettingRecordLabelIdAndGeolocation: RecordLabelCountriesByProfileSettingRecordLabelIdAndGeolocationManyToManyConnection;
  /** Reads and enables pagination through a set of `Genre`. */
  genresByAlbumPublisherAndGenre: RecordLabelGenresByAlbumPublisherAndGenreManyToManyConnection;
  /** Reads and enables pagination through a set of `Genre`. */
  genresByTrackPublisherAndGenre: RecordLabelGenresByTrackPublisherAndGenreManyToManyConnection;
  id: Scalars['UUID'];
  /** `RecordLabel` name */
  name?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `ProfileSetting`. */
  profileSettingsByRecordLabelId: ProfileSettingsConnection;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByPublisher: TracksConnection;
};

/** `RecordLabel` table */
export type RecordLabelAlbumsByPublisherArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** `RecordLabel` table */
export type RecordLabelAlbumsByTrackPublisherAndAlbumArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** `RecordLabel` table */
export type RecordLabelArtistsByAlbumPublisherAndArtistArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `RecordLabel` table */
export type RecordLabelArtistsByTrackPublisherAndArtistArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `RecordLabel` table */
export type RecordLabelCountriesByProfileSettingRecordLabelIdAndGeolocationArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CountryCondition>;
  filter?: InputMaybe<CountryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
};

/** `RecordLabel` table */
export type RecordLabelGenresByAlbumPublisherAndGenreArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenreCondition>;
  filter?: InputMaybe<GenreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenresOrderBy>>;
};

/** `RecordLabel` table */
export type RecordLabelGenresByTrackPublisherAndGenreArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenreCondition>;
  filter?: InputMaybe<GenreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenresOrderBy>>;
};

/** `RecordLabel` table */
export type RecordLabelProfileSettingsByRecordLabelIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProfileSettingCondition>;
  filter?: InputMaybe<ProfileSettingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProfileSettingsOrderBy>>;
};

/** `RecordLabel` table */
export type RecordLabelTracksByPublisherArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** A connection to a list of `Album` values, with data from `Track`. */
export type RecordLabelAlbumsByTrackPublisherAndAlbumManyToManyConnection = {
  __typename?: 'RecordLabelAlbumsByTrackPublisherAndAlbumManyToManyConnection';
  /** A list of edges which contains the `Album`, info from the `Track`, and the cursor to aid in pagination. */
  edges: Array<RecordLabelAlbumsByTrackPublisherAndAlbumManyToManyEdge>;
  /** A list of `Album` objects. */
  nodes: Array<Maybe<Album>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Album` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Album` edge in the connection, with data from `Track`. */
export type RecordLabelAlbumsByTrackPublisherAndAlbumManyToManyEdge = {
  __typename?: 'RecordLabelAlbumsByTrackPublisherAndAlbumManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Album` at the end of the edge. */
  node?: Maybe<Album>;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByAlbum: TracksConnection;
};

/** A `Album` edge in the connection, with data from `Track`. */
export type RecordLabelAlbumsByTrackPublisherAndAlbumManyToManyEdgeTracksByAlbumArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `Album`. */
export type RecordLabelArtistsByAlbumPublisherAndArtistManyToManyConnection = {
  __typename?: 'RecordLabelArtistsByAlbumPublisherAndArtistManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `Album`, and the cursor to aid in pagination. */
  edges: Array<RecordLabelArtistsByAlbumPublisherAndArtistManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `Album`. */
export type RecordLabelArtistsByAlbumPublisherAndArtistManyToManyEdge = {
  __typename?: 'RecordLabelArtistsByAlbumPublisherAndArtistManyToManyEdge';
  /** Reads and enables pagination through a set of `Album`. */
  albumsByArtist: AlbumsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** A `Artist` edge in the connection, with data from `Album`. */
export type RecordLabelArtistsByAlbumPublisherAndArtistManyToManyEdgeAlbumsByArtistArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `Track`. */
export type RecordLabelArtistsByTrackPublisherAndArtistManyToManyConnection = {
  __typename?: 'RecordLabelArtistsByTrackPublisherAndArtistManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `Track`, and the cursor to aid in pagination. */
  edges: Array<RecordLabelArtistsByTrackPublisherAndArtistManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `Track`. */
export type RecordLabelArtistsByTrackPublisherAndArtistManyToManyEdge = {
  __typename?: 'RecordLabelArtistsByTrackPublisherAndArtistManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByArtist: TracksConnection;
};

/** A `Artist` edge in the connection, with data from `Track`. */
export type RecordLabelArtistsByTrackPublisherAndArtistManyToManyEdgeTracksByArtistArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/**
 * A condition to be used against `RecordLabel` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type RecordLabelCondition = {
  /** Checks for equality with the object’s `confirmed` field. */
  confirmed?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `Country` values, with data from `ProfileSetting`. */
export type RecordLabelCountriesByProfileSettingRecordLabelIdAndGeolocationManyToManyConnection = {
  __typename?: 'RecordLabelCountriesByProfileSettingRecordLabelIdAndGeolocationManyToManyConnection';
  /** A list of edges which contains the `Country`, info from the `ProfileSetting`, and the cursor to aid in pagination. */
  edges: Array<RecordLabelCountriesByProfileSettingRecordLabelIdAndGeolocationManyToManyEdge>;
  /** A list of `Country` objects. */
  nodes: Array<Maybe<Country>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Country` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Country` edge in the connection, with data from `ProfileSetting`. */
export type RecordLabelCountriesByProfileSettingRecordLabelIdAndGeolocationManyToManyEdge = {
  __typename?: 'RecordLabelCountriesByProfileSettingRecordLabelIdAndGeolocationManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Country` at the end of the edge. */
  node?: Maybe<Country>;
  /** Reads and enables pagination through a set of `ProfileSetting`. */
  profileSettingsByGeolocation: ProfileSettingsConnection;
};

/** A `Country` edge in the connection, with data from `ProfileSetting`. */
export type RecordLabelCountriesByProfileSettingRecordLabelIdAndGeolocationManyToManyEdgeProfileSettingsByGeolocationArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProfileSettingCondition>;
  filter?: InputMaybe<ProfileSettingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProfileSettingsOrderBy>>;
};

/** A filter to be used against `RecordLabel` object types. All fields are combined with a logical ‘and.’ */
export type RecordLabelFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RecordLabelFilter>>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<RecordLabelFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RecordLabelFilter>>;
};

/** A connection to a list of `Genre` values, with data from `Album`. */
export type RecordLabelGenresByAlbumPublisherAndGenreManyToManyConnection = {
  __typename?: 'RecordLabelGenresByAlbumPublisherAndGenreManyToManyConnection';
  /** A list of edges which contains the `Genre`, info from the `Album`, and the cursor to aid in pagination. */
  edges: Array<RecordLabelGenresByAlbumPublisherAndGenreManyToManyEdge>;
  /** A list of `Genre` objects. */
  nodes: Array<Maybe<Genre>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Genre` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Genre` edge in the connection, with data from `Album`. */
export type RecordLabelGenresByAlbumPublisherAndGenreManyToManyEdge = {
  __typename?: 'RecordLabelGenresByAlbumPublisherAndGenreManyToManyEdge';
  /** Reads and enables pagination through a set of `Album`. */
  albumsByGenre: AlbumsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Genre` at the end of the edge. */
  node?: Maybe<Genre>;
};

/** A `Genre` edge in the connection, with data from `Album`. */
export type RecordLabelGenresByAlbumPublisherAndGenreManyToManyEdgeAlbumsByGenreArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** A connection to a list of `Genre` values, with data from `Track`. */
export type RecordLabelGenresByTrackPublisherAndGenreManyToManyConnection = {
  __typename?: 'RecordLabelGenresByTrackPublisherAndGenreManyToManyConnection';
  /** A list of edges which contains the `Genre`, info from the `Track`, and the cursor to aid in pagination. */
  edges: Array<RecordLabelGenresByTrackPublisherAndGenreManyToManyEdge>;
  /** A list of `Genre` objects. */
  nodes: Array<Maybe<Genre>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Genre` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Genre` edge in the connection, with data from `Track`. */
export type RecordLabelGenresByTrackPublisherAndGenreManyToManyEdge = {
  __typename?: 'RecordLabelGenresByTrackPublisherAndGenreManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Genre` at the end of the edge. */
  node?: Maybe<Genre>;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByGenre: TracksConnection;
};

/** A `Genre` edge in the connection, with data from `Track`. */
export type RecordLabelGenresByTrackPublisherAndGenreManyToManyEdgeTracksByGenreArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** A connection to a list of `RecordLabel` values. */
export type RecordLabelsConnection = {
  __typename?: 'RecordLabelsConnection';
  /** A list of edges which contains the `RecordLabel` and cursor to aid in pagination. */
  edges: Array<RecordLabelsEdge>;
  /** A list of `RecordLabel` objects. */
  nodes: Array<Maybe<RecordLabel>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `RecordLabel` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `RecordLabel` edge in the connection. */
export type RecordLabelsEdge = {
  __typename?: 'RecordLabelsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RecordLabel` at the end of the edge. */
  node?: Maybe<RecordLabel>;
};

/** Methods to use when ordering `RecordLabel`. */
export enum RecordLabelsOrderBy {
  ConfirmedAsc = 'CONFIRMED_ASC',
  ConfirmedDesc = 'CONFIRMED_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export enum RecordType {
  Album = 'ALBUM',
  Artist = 'ARTIST',
  Playlist = 'PLAYLIST',
  Track = 'TRACK',
}

/** All input for the `removeTrackFromPlaylist` mutation. */
export type RemoveTrackFromPlaylistInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  playlist: Scalars['UUID'];
  track: Scalars['UUID'];
};

/** The output of our `removeTrackFromPlaylist` mutation. */
export type RemoveTrackFromPlaylistPayload = {
  __typename?: 'RemoveTrackFromPlaylistPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Playlist` that is related to this `PlaylistsTrack`. */
  playlistByPlaylistId?: Maybe<Playlist>;
  playlistsTrack?: Maybe<PlaylistsTrack>;
  /** An edge for our `PlaylistsTrack`. May be used by Relay 1. */
  playlistsTrackEdge?: Maybe<PlaylistsTracksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `PlaylistsTrack`. */
  trackByTrackId?: Maybe<Track>;
};

/** The output of our `removeTrackFromPlaylist` mutation. */
export type RemoveTrackFromPlaylistPayloadPlaylistsTrackEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsTracksOrderBy>>;
};

/** A connection to a list of `Royalty` values. */
export type RoyaltiesConnection = {
  __typename?: 'RoyaltiesConnection';
  /** A list of edges which contains the `Royalty` and cursor to aid in pagination. */
  edges: Array<RoyaltiesEdge>;
  /** A list of `Royalty` objects. */
  nodes: Array<Maybe<Royalty>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Royalty` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Royalty` edge in the connection. */
export type RoyaltiesEdge = {
  __typename?: 'RoyaltiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Royalty` at the end of the edge. */
  node?: Maybe<Royalty>;
};

/** Methods to use when ordering `Royalty`. */
export enum RoyaltiesOrderBy {
  ArtistMemberIdAsc = 'ARTIST_MEMBER_ID_ASC',
  ArtistMemberIdDesc = 'ARTIST_MEMBER_ID_DESC',
  CeltzerAsc = 'CELTZER_ASC',
  CeltzerDesc = 'CELTZER_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PercentageAsc = 'PERCENTAGE_ASC',
  PercentageDesc = 'PERCENTAGE_DESC',
  PriceIdAsc = 'PRICE_ID_ASC',
  PriceIdDesc = 'PRICE_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TrackIdAsc = 'TRACK_ID_ASC',
  TrackIdDesc = 'TRACK_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
}

/** `Royalty` table - stores percentages for one track, divided between multiple `ArtistMember` */
export type Royalty = Node & {
  __typename?: 'Royalty';
  /** Unique identified of the collaborating `ArtistMember` */
  artistMemberId?: Maybe<Scalars['UUID']>;
  /** Defines if we have a celtzer contract for this */
  celtzer?: Maybe<Scalars['Boolean']>;
  /** Defines the datetime with time zone when `Royalty` was first created */
  createdAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Tracks royalty percentage for specific artist member */
  percentage: Scalars['BigFloat'];
  /** Reads a single `Price` that is related to this `Royalty`. */
  priceByPriceId?: Maybe<Price>;
  priceId?: Maybe<Scalars['UUID']>;
  /** `Royalty` status */
  status?: Maybe<RoyaltyStatus>;
  /** Reads a single `Track` that is related to this `Royalty`. */
  trackByTrackId?: Maybe<Track>;
  /** `Track` unique identifier */
  trackId?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** `User` unique identifier */
  userId?: Maybe<Scalars['UUID']>;
};

/** A condition to be used against `Royalty` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type RoyaltyCondition = {
  /** Checks for equality with the object’s `artistMemberId` field. */
  artistMemberId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `celtzer` field. */
  celtzer?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `percentage` field. */
  percentage?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceId` field. */
  priceId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<RoyaltyStatus>;
  /** Checks for equality with the object’s `trackId` field. */
  trackId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `Royalty` object types. All fields are combined with a logical ‘and.’ */
export type RoyaltyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RoyaltyFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<RoyaltyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RoyaltyFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

export type RoyaltyRow = {
  __typename?: 'RoyaltyRow';
  artistId?: Maybe<Scalars['UUID']>;
  artistPercentage?: Maybe<Scalars['BigFloat']>;
  percentage?: Maybe<Scalars['BigFloat']>;
  priceId?: Maybe<Scalars['UUID']>;
  royaltyId?: Maybe<Scalars['UUID']>;
  trackId?: Maybe<Scalars['UUID']>;
  userId?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `RoyaltyRow` values. */
export type RoyaltyRowsConnection = {
  __typename?: 'RoyaltyRowsConnection';
  /** A list of edges which contains the `RoyaltyRow` and cursor to aid in pagination. */
  edges: Array<RoyaltyRowsEdge>;
  /** A list of `RoyaltyRow` objects. */
  nodes: Array<Maybe<RoyaltyRow>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `RoyaltyRow` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `RoyaltyRow` edge in the connection. */
export type RoyaltyRowsEdge = {
  __typename?: 'RoyaltyRowsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RoyaltyRow` at the end of the edge. */
  node?: Maybe<RoyaltyRow>;
};

export enum RoyaltyStatus {
  Aborted = 'ABORTED',
  Current = 'CURRENT',
}

/** A connection to a list of `SearchHistory` values. */
export type SearchHistoriesConnection = {
  __typename?: 'SearchHistoriesConnection';
  /** A list of edges which contains the `SearchHistory` and cursor to aid in pagination. */
  edges: Array<SearchHistoriesEdge>;
  /** A list of `SearchHistory` objects. */
  nodes: Array<Maybe<SearchHistory>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SearchHistory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SearchHistory` edge in the connection. */
export type SearchHistoriesEdge = {
  __typename?: 'SearchHistoriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SearchHistory` at the end of the edge. */
  node?: Maybe<SearchHistory>;
};

/** Methods to use when ordering `SearchHistory`. */
export enum SearchHistoriesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SearchValueAsc = 'SEARCH_VALUE_ASC',
  SearchValueDesc = 'SEARCH_VALUE_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
}

/** `SearchHistory` table */
export type SearchHistory = Node & {
  __typename?: 'SearchHistory';
  /** Defines the datetime with time zone when `SearchHistory` was first created. */
  createdAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Defines the value that was searched. */
  searchValue?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `SearchHistory`. */
  userByUserId?: Maybe<User>;
  /** The primary unique identifier for the `User` */
  userId?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `SearchHistory` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SearchHistoryCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `searchValue` field. */
  searchValue?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `SearchHistory` object types. All fields are combined with a logical ‘and.’ */
export type SearchHistoryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SearchHistoryFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SearchHistoryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SearchHistoryFilter>>;
  /** Filter by the object’s `searchValue` field. */
  searchValue?: InputMaybe<StringFilter>;
};

export type SearchItem = {
  __typename?: 'SearchItem';
  artist?: Maybe<Scalars['String']>;
  explicit?: Maybe<Scalars['Boolean']>;
  followers?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  img?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['Int']>;
  presignedImgs?: Maybe<Array<Maybe<PresignedImage>>>;
  searchToken?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `SearchItem` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SearchItemCondition = {
  /** Checks for equality with the object’s `artist` field. */
  artist?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `explicit` field. */
  explicit?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `followers` field. */
  followers?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `img` field. */
  img?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `likes` field. */
  likes?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `searchToken` field. */
  searchToken?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `SearchItem` object types. All fields are combined with a logical ‘and.’ */
export type SearchItemFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SearchItemFilter>>;
  /** Filter by the object’s `artist` field. */
  artist?: InputMaybe<StringFilter>;
  /** Filter by the object’s `followers` field. */
  followers?: InputMaybe<IntFilter>;
  /** Filter by the object’s `img` field. */
  img?: InputMaybe<StringFilter>;
  /** Filter by the object’s `likes` field. */
  likes?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SearchItemFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SearchItemFilter>>;
  /** Filter by the object’s `searchToken` field. */
  searchToken?: InputMaybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
};

/** A connection to a list of `SearchItem` values. */
export type SearchItemsConnection = {
  __typename?: 'SearchItemsConnection';
  /** A list of edges which contains the `SearchItem` and cursor to aid in pagination. */
  edges: Array<SearchItemsEdge>;
  /** A list of `SearchItem` objects. */
  nodes: Array<Maybe<SearchItem>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SearchItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SearchItem` edge in the connection. */
export type SearchItemsEdge = {
  __typename?: 'SearchItemsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SearchItem` at the end of the edge. */
  node?: Maybe<SearchItem>;
};

/** Methods to use when ordering `SearchItem`. */
export enum SearchItemsOrderBy {
  ArtistAsc = 'ARTIST_ASC',
  ArtistDesc = 'ARTIST_DESC',
  ExplicitAsc = 'EXPLICIT_ASC',
  ExplicitDesc = 'EXPLICIT_DESC',
  FollowersAsc = 'FOLLOWERS_ASC',
  FollowersDesc = 'FOLLOWERS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImgAsc = 'IMG_ASC',
  ImgDesc = 'IMG_DESC',
  LikesAsc = 'LIKES_ASC',
  LikesDesc = 'LIKES_DESC',
  Natural = 'NATURAL',
  SearchTokenAsc = 'SEARCH_TOKEN_ASC',
  SearchTokenDesc = 'SEARCH_TOKEN_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
}

/** All input for the `setAbortedRoyalties` mutation. */
export type SetAbortedRoyaltiesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  track: Scalars['UUID'];
};

/** The output of our `setAbortedRoyalties` mutation. */
export type SetAbortedRoyaltiesPayload = {
  __typename?: 'SetAbortedRoyaltiesPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setBlarecastData` mutation. */
export type SetBlarecastDataInput = {
  artist?: InputMaybe<Scalars['UUID']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** The output of our `setBlarecastData` mutation. */
export type SetBlarecastDataPayload = {
  __typename?: 'SetBlarecastDataPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  string?: Maybe<Scalars['String']>;
};

/** All input for the `setEmailAfterSocialMediaRegistration` mutation. */
export type SetEmailAfterSocialMediaRegistrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  newEmail: Scalars['String'];
};

/** The output of our `setEmailAfterSocialMediaRegistration` mutation. */
export type SetEmailAfterSocialMediaRegistrationPayload = {
  __typename?: 'SetEmailAfterSocialMediaRegistrationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setProfileSettingsTextColumns` mutation. */
export type SetProfileSettingsTextColumnsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  newDisplayName: Scalars['String'];
  newPersonalBio: Scalars['String'];
};

/** The output of our `setProfileSettingsTextColumns` mutation. */
export type SetProfileSettingsTextColumnsPayload = {
  __typename?: 'SetProfileSettingsTextColumnsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Country` that is related to this `ProfileSetting`. */
  countryByGeolocation?: Maybe<Country>;
  profileSetting?: Maybe<ProfileSetting>;
  /** An edge for our `ProfileSetting`. May be used by Relay 1. */
  profileSettingEdge?: Maybe<ProfileSettingsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `RecordLabel` that is related to this `ProfileSetting`. */
  recordLabelByRecordLabelId?: Maybe<RecordLabel>;
  /** Reads a single `User` that is related to this `ProfileSetting`. */
  userByUserId?: Maybe<User>;
};

/** The output of our `setProfileSettingsTextColumns` mutation. */
export type SetProfileSettingsTextColumnsPayloadProfileSettingEdgeArgs = {
  orderBy?: InputMaybe<Array<ProfileSettingsOrderBy>>;
};

/** An input for mutations affecting `StreamHistoryInputRecord` */
export type StreamHistoryInputRecordInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  streams?: InputMaybe<Scalars['BigInt']>;
  trackId?: InputMaybe<Scalars['UUID']>;
};

/** A connection to a list of `StreamsHistory` values. */
export type StreamsHistoriesConnection = {
  __typename?: 'StreamsHistoriesConnection';
  /** A list of edges which contains the `StreamsHistory` and cursor to aid in pagination. */
  edges: Array<StreamsHistoriesEdge>;
  /** A list of `StreamsHistory` objects. */
  nodes: Array<Maybe<StreamsHistory>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `StreamsHistory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `StreamsHistory` edge in the connection. */
export type StreamsHistoriesEdge = {
  __typename?: 'StreamsHistoriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `StreamsHistory` at the end of the edge. */
  node?: Maybe<StreamsHistory>;
};

/** Methods to use when ordering `StreamsHistory`. */
export enum StreamsHistoriesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StreamsAsc = 'STREAMS_ASC',
  StreamsDesc = 'STREAMS_DESC',
  TrackIdAsc = 'TRACK_ID_ASC',
  TrackIdDesc = 'TRACK_ID_DESC',
}

/**  `StreamHistory` table - contains the `Users` stream history */
export type StreamsHistory = Node & {
  __typename?: 'StreamsHistory';
  /**  Defines the datetime with time zone this `StreamHistory` applies to */
  createdAt: Scalars['Datetime'];
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Defines the amount of streams a `User` got for the specific created_at date */
  streams: Scalars['BigInt'];
  /** Reads a single `Track` that is related to this `StreamsHistory`. */
  trackByTrackId?: Maybe<Track>;
  /** Defines the `Track` that the `StreamHistory` is bound to */
  trackId: Scalars['UUID'];
};

/**
 * A condition to be used against `StreamsHistory` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type StreamsHistoryCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `streams` field. */
  streams?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `trackId` field. */
  trackId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `StreamsHistory` object types. All fields are combined with a logical ‘and.’ */
export type StreamsHistoryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<StreamsHistoryFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<StreamsHistoryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<StreamsHistoryFilter>>;
};

export type StreamsHistoryRow = {
  __typename?: 'StreamsHistoryRow';
  createdAt?: Maybe<Scalars['Date']>;
  streams?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['String']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['String']>;
};

/** All input for the `toggleTrackPin` mutation. */
export type ToggleTrackPinInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  trackId?: InputMaybe<Scalars['UUID']>;
};

/** The output of our `toggleTrackPin` mutation. */
export type ToggleTrackPinPayload = {
  __typename?: 'ToggleTrackPinPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** `Track` table - contains the tracks information */
export type Track = Node & {
  __typename?: 'Track';
  /** Defines the `Album` this `Track` is bound to */
  album?: Maybe<Scalars['UUID']>;
  /** Reads a single `Album` that is related to this `Track`. */
  albumByAlbum?: Maybe<Album>;
  /** Reads and enables pagination through a set of `Album`. */
  albumsByAlbumsTrackTrackIdAndAlbumId: TrackAlbumsByAlbumsTrackTrackIdAndAlbumIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Album`. */
  albumsByLikeTrackIdAndAlbumId: TrackAlbumsByLikeTrackIdAndAlbumIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Album`. */
  albumsByTracksAlbumTrackIdAndAlbumId: TrackAlbumsByTracksAlbumTrackIdAndAlbumIdManyToManyConnection;
  /** Reads and enables pagination through a set of `AlbumsTrack`. */
  albumsTracksByTrackId: AlbumsTracksConnection;
  /** The `Artist` of the track */
  artist: Scalars['UUID'];
  /** Reads a single `Artist` that is related to this `Track`. */
  artistByArtist?: Maybe<Artist>;
  /** `Track` metadata for `Artist` */
  artistMeta?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByFeaturedArtistTrackAndArtist: TrackArtistsByFeaturedArtistTrackAndArtistManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByLikeTrackIdAndActingArtistId: TrackArtistsByLikeTrackIdAndActingArtistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByLikeTrackIdAndArtistId: TrackArtistsByLikeTrackIdAndArtistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByPriceTrackIdAndCreatorId: TrackArtistsByPriceTrackIdAndCreatorIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByTracksArtistTrackIdAndArtistId: TrackArtistsByTracksArtistTrackIdAndArtistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `AutoGeneratedQueue`. */
  autoGeneratedQueuesByTrackId: AutoGeneratedQueuesConnection;
  /** Defines if the `Track` is available for worldwide and is not region locked */
  availableWorldwide?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `Country`. */
  countriesByCountriesTrackTrackIdAndCountryId: TrackCountriesByCountriesTrackTrackIdAndCountryIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Country`. */
  countriesByPriceTrackIdAndCountry: TrackCountriesByPriceTrackIdAndCountryManyToManyConnection;
  /** Reads and enables pagination through a set of `CountriesTrack`. */
  countriesTracksByTrackId: CountriesTracksConnection;
  /** Defines the datetime with time zone `Track` was first created */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Duration of the `Track` in seconds */
  duration?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `EarningsHistory`. */
  earningsHistoriesByTrackId: EarningsHistoriesConnection;
  /** Defines if the `Track` is explicit */
  explicit?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `FeaturedArtist`. */
  featuredArtistsByTrack: FeaturedArtistsConnection;
  /** Defines the `Genre` the `Track` is bound to */
  genre?: Maybe<Scalars['UUID']>;
  /** Reads a single `Genre` that is related to this `Track`. */
  genreByGenre?: Maybe<Genre>;
  /** Reads and enables pagination through a set of `Genre`. */
  genresByTracksGenreTrackIdAndGenreId: TrackGenresByTracksGenreTrackIdAndGenreIdManyToManyConnection;
  id: Scalars['UUID'];
  /** Link to the `Track` image */
  img?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  /** International Standard Recording Code. 12 alphanumeric characters that represent a unique identification of the song that eg: AA-6Q7-20-00047 */
  isrc?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `LastPlayedTrack`. */
  lastPlayedTracksByTrackId: LastPlayedTracksConnection;
  /** Defines the `Track` number of likes */
  likeCount?: Maybe<Scalars['Int']>;
  likedAsListener?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByTrackId: LikesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Defines when the `Track` was set to be pinned to the `Artist` profile */
  pinnedAt?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `PlayHistory`. */
  playHistoriesByTrackId: PlayHistoriesConnection;
  /** Reads and enables pagination through a set of `Playlist`. */
  playlistsByLikeTrackIdAndPlaylistId: TrackPlaylistsByLikeTrackIdAndPlaylistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Playlist`. */
  playlistsByPlaylistsTrackTrackIdAndPlaylistId: TrackPlaylistsByPlaylistsTrackTrackIdAndPlaylistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PlaylistsTrack`. */
  playlistsTracksByTrackId: PlaylistsTracksConnection;
  presignedImgs?: Maybe<Array<Maybe<PresignedImage>>>;
  price?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `Price`. */
  pricesByRoyaltyTrackIdAndPriceId: TrackPricesByRoyaltyTrackIdAndPriceIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Price`. */
  pricesByTrackId: PricesConnection;
  /** Defines if the `Track` is available for public */
  private?: Maybe<Scalars['Boolean']>;
  /** Defines the `RecordLabel` this `Track` is bound to */
  publisher?: Maybe<Scalars['UUID']>;
  /** Reads a single `RecordLabel` that is related to this `Track`. */
  recordLabelByPublisher?: Maybe<RecordLabel>;
  /** Defines the `Track` release */
  releaseDate?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `Royalty`. */
  royaltiesByTrackId: RoyaltiesConnection;
  /** Defines the `Track` duration */
  size?: Maybe<Scalars['Int']>;
  /** The status of the `Track` that defines if it is processed from transcoder service */
  status?: Maybe<TrackStatus>;
  /** Defines the `Track` number of streams */
  streamCount?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `StreamsHistory`. */
  streamsHistoriesByTrackId: StreamsHistoriesConnection;
  /** `Track` sub title */
  subTitle?: Maybe<Scalars['String']>;
  /** Defines the `Track` tags */
  tags?: Maybe<Scalars['String']>;
  /** The text that a particular `Track` will be searched by */
  textSearch?: Maybe<Scalars['String']>;
  /** `Track` title */
  title?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `TracksAlbum`. */
  tracksAlbumsByTrackId: TracksAlbumsConnection;
  /** Reads and enables pagination through a set of `TracksArtist`. */
  tracksArtistsByTrackId: TracksArtistsConnection;
  /** Reads and enables pagination through a set of `TracksEarning`. */
  tracksEarningsByTrackId: TracksEarningsConnection;
  /** Reads and enables pagination through a set of `TracksGenre`. */
  tracksGenresByTrackId: TracksGenresConnection;
  /** Defines the datetime with time zone `Track` last updated */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `User`. */
  usersByAutoGeneratedQueueTrackIdAndUserId: TrackUsersByAutoGeneratedQueueTrackIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByEarningsHistoryTrackIdAndUserId: TrackUsersByEarningsHistoryTrackIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByLikeTrackIdAndLikedUserId: TrackUsersByLikeTrackIdAndLikedUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByLikeTrackIdAndUserId: TrackUsersByLikeTrackIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPlayHistoryTrackIdAndUserId: TrackUsersByPlayHistoryTrackIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByTracksEarningTrackIdAndUserId: TrackUsersByTracksEarningTrackIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByUsersQueueTrackIdAndUserId: TrackUsersByUsersQueueTrackIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `UsersQueue`. */
  usersQueuesByTrackId: UsersQueuesConnection;
};

/** `Track` table - contains the tracks information */
export type TrackAlbumsByAlbumsTrackTrackIdAndAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackAlbumsByLikeTrackIdAndAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackAlbumsByTracksAlbumTrackIdAndAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackAlbumsTracksByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumsTrackCondition>;
  filter?: InputMaybe<AlbumsTrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsTracksOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackArtistsByFeaturedArtistTrackAndArtistArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackArtistsByLikeTrackIdAndActingArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackArtistsByLikeTrackIdAndArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackArtistsByPriceTrackIdAndCreatorIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackArtistsByTracksArtistTrackIdAndArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackAutoGeneratedQueuesByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AutoGeneratedQueueCondition>;
  filter?: InputMaybe<AutoGeneratedQueueFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AutoGeneratedQueuesOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackCountriesByCountriesTrackTrackIdAndCountryIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CountryCondition>;
  filter?: InputMaybe<CountryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackCountriesByPriceTrackIdAndCountryArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CountryCondition>;
  filter?: InputMaybe<CountryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackCountriesTracksByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CountriesTrackCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountriesTracksOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackEarningsHistoriesByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EarningsHistoryCondition>;
  filter?: InputMaybe<EarningsHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EarningsHistoriesOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackFeaturedArtistsByTrackArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FeaturedArtistCondition>;
  filter?: InputMaybe<FeaturedArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeaturedArtistsOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackGenresByTracksGenreTrackIdAndGenreIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenreCondition>;
  filter?: InputMaybe<GenreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenresOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackLastPlayedTracksByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LastPlayedTrackCondition>;
  filter?: InputMaybe<LastPlayedTrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LastPlayedTracksOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackLikesByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackPlayHistoriesByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PlayHistoryCondition>;
  filter?: InputMaybe<PlayHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlayHistoriesOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackPlaylistsByLikeTrackIdAndPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PlaylistCondition>;
  filter?: InputMaybe<PlaylistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackPlaylistsByPlaylistsTrackTrackIdAndPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PlaylistCondition>;
  filter?: InputMaybe<PlaylistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackPlaylistsTracksByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PlaylistsTrackCondition>;
  filter?: InputMaybe<PlaylistsTrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlaylistsTracksOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackPricesByRoyaltyTrackIdAndPriceIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PriceCondition>;
  filter?: InputMaybe<PriceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricesOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackPricesByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PriceCondition>;
  filter?: InputMaybe<PriceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricesOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackRoyaltiesByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RoyaltyCondition>;
  filter?: InputMaybe<RoyaltyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RoyaltiesOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackStreamsHistoriesByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<StreamsHistoryCondition>;
  filter?: InputMaybe<StreamsHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StreamsHistoriesOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackTracksAlbumsByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TracksAlbumCondition>;
  filter?: InputMaybe<TracksAlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksAlbumsOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackTracksArtistsByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TracksArtistCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksArtistsOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackTracksEarningsByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TracksEarningCondition>;
  filter?: InputMaybe<TracksEarningFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksEarningsOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackTracksGenresByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TracksGenreCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksGenresOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackUsersByAutoGeneratedQueueTrackIdAndUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackUsersByEarningsHistoryTrackIdAndUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackUsersByLikeTrackIdAndLikedUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackUsersByLikeTrackIdAndUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackUsersByPlayHistoryTrackIdAndUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackUsersByTracksEarningTrackIdAndUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackUsersByUsersQueueTrackIdAndUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** `Track` table - contains the tracks information */
export type TrackUsersQueuesByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UsersQueueCondition>;
  filter?: InputMaybe<UsersQueueFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersQueuesOrderBy>>;
};

/** A connection to a list of `Album` values, with data from `AlbumsTrack`. */
export type TrackAlbumsByAlbumsTrackTrackIdAndAlbumIdManyToManyConnection = {
  __typename?: 'TrackAlbumsByAlbumsTrackTrackIdAndAlbumIdManyToManyConnection';
  /** A list of edges which contains the `Album`, info from the `AlbumsTrack`, and the cursor to aid in pagination. */
  edges: Array<TrackAlbumsByAlbumsTrackTrackIdAndAlbumIdManyToManyEdge>;
  /** A list of `Album` objects. */
  nodes: Array<Maybe<Album>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Album` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Album` edge in the connection, with data from `AlbumsTrack`. */
export type TrackAlbumsByAlbumsTrackTrackIdAndAlbumIdManyToManyEdge = {
  __typename?: 'TrackAlbumsByAlbumsTrackTrackIdAndAlbumIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Album` at the end of the edge. */
  node?: Maybe<Album>;
  sort?: Maybe<Scalars['Int']>;
  /** Defines the `Track` number inside the `Album` */
  trackNumber: Scalars['Int'];
};

/** A connection to a list of `Album` values, with data from `Like`. */
export type TrackAlbumsByLikeTrackIdAndAlbumIdManyToManyConnection = {
  __typename?: 'TrackAlbumsByLikeTrackIdAndAlbumIdManyToManyConnection';
  /** A list of edges which contains the `Album`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<TrackAlbumsByLikeTrackIdAndAlbumIdManyToManyEdge>;
  /** A list of `Album` objects. */
  nodes: Array<Maybe<Album>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Album` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Album` edge in the connection, with data from `Like`. */
export type TrackAlbumsByLikeTrackIdAndAlbumIdManyToManyEdge = {
  __typename?: 'TrackAlbumsByLikeTrackIdAndAlbumIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByAlbumId: LikesConnection;
  /** The `Album` at the end of the edge. */
  node?: Maybe<Album>;
};

/** A `Album` edge in the connection, with data from `Like`. */
export type TrackAlbumsByLikeTrackIdAndAlbumIdManyToManyEdgeLikesByAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Album` values, with data from `TracksAlbum`. */
export type TrackAlbumsByTracksAlbumTrackIdAndAlbumIdManyToManyConnection = {
  __typename?: 'TrackAlbumsByTracksAlbumTrackIdAndAlbumIdManyToManyConnection';
  /** A list of edges which contains the `Album`, info from the `TracksAlbum`, and the cursor to aid in pagination. */
  edges: Array<TrackAlbumsByTracksAlbumTrackIdAndAlbumIdManyToManyEdge>;
  /** A list of `Album` objects. */
  nodes: Array<Maybe<Album>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Album` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Album` edge in the connection, with data from `TracksAlbum`. */
export type TrackAlbumsByTracksAlbumTrackIdAndAlbumIdManyToManyEdge = {
  __typename?: 'TrackAlbumsByTracksAlbumTrackIdAndAlbumIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Album` at the end of the edge. */
  node?: Maybe<Album>;
  /** Reads and enables pagination through a set of `TracksAlbum`. */
  tracksAlbumsByAlbumId: TracksAlbumsConnection;
};

/** A `Album` edge in the connection, with data from `TracksAlbum`. */
export type TrackAlbumsByTracksAlbumTrackIdAndAlbumIdManyToManyEdgeTracksAlbumsByAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TracksAlbumCondition>;
  filter?: InputMaybe<TracksAlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksAlbumsOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `FeaturedArtist`. */
export type TrackArtistsByFeaturedArtistTrackAndArtistManyToManyConnection = {
  __typename?: 'TrackArtistsByFeaturedArtistTrackAndArtistManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `FeaturedArtist`, and the cursor to aid in pagination. */
  edges: Array<TrackArtistsByFeaturedArtistTrackAndArtistManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `FeaturedArtist`. */
export type TrackArtistsByFeaturedArtistTrackAndArtistManyToManyEdge = {
  __typename?: 'TrackArtistsByFeaturedArtistTrackAndArtistManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `FeaturedArtist`. */
  featuredArtistsByArtist: FeaturedArtistsConnection;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** A `Artist` edge in the connection, with data from `FeaturedArtist`. */
export type TrackArtistsByFeaturedArtistTrackAndArtistManyToManyEdgeFeaturedArtistsByArtistArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FeaturedArtistCondition>;
  filter?: InputMaybe<FeaturedArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeaturedArtistsOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `Like`. */
export type TrackArtistsByLikeTrackIdAndActingArtistIdManyToManyConnection = {
  __typename?: 'TrackArtistsByLikeTrackIdAndActingArtistIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<TrackArtistsByLikeTrackIdAndActingArtistIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type TrackArtistsByLikeTrackIdAndActingArtistIdManyToManyEdge = {
  __typename?: 'TrackArtistsByLikeTrackIdAndActingArtistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByActingArtistId: LikesConnection;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type TrackArtistsByLikeTrackIdAndActingArtistIdManyToManyEdgeLikesByActingArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `Like`. */
export type TrackArtistsByLikeTrackIdAndArtistIdManyToManyConnection = {
  __typename?: 'TrackArtistsByLikeTrackIdAndArtistIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<TrackArtistsByLikeTrackIdAndArtistIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type TrackArtistsByLikeTrackIdAndArtistIdManyToManyEdge = {
  __typename?: 'TrackArtistsByLikeTrackIdAndArtistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByArtistId: LikesConnection;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type TrackArtistsByLikeTrackIdAndArtistIdManyToManyEdgeLikesByArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `Price`. */
export type TrackArtistsByPriceTrackIdAndCreatorIdManyToManyConnection = {
  __typename?: 'TrackArtistsByPriceTrackIdAndCreatorIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `Price`, and the cursor to aid in pagination. */
  edges: Array<TrackArtistsByPriceTrackIdAndCreatorIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `Price`. */
export type TrackArtistsByPriceTrackIdAndCreatorIdManyToManyEdge = {
  __typename?: 'TrackArtistsByPriceTrackIdAndCreatorIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
  /** Reads and enables pagination through a set of `Price`. */
  pricesByCreatorId: PricesConnection;
};

/** A `Artist` edge in the connection, with data from `Price`. */
export type TrackArtistsByPriceTrackIdAndCreatorIdManyToManyEdgePricesByCreatorIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PriceCondition>;
  filter?: InputMaybe<PriceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricesOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `TracksArtist`. */
export type TrackArtistsByTracksArtistTrackIdAndArtistIdManyToManyConnection = {
  __typename?: 'TrackArtistsByTracksArtistTrackIdAndArtistIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `TracksArtist`, and the cursor to aid in pagination. */
  edges: Array<TrackArtistsByTracksArtistTrackIdAndArtistIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `TracksArtist`. */
export type TrackArtistsByTracksArtistTrackIdAndArtistIdManyToManyEdge = {
  __typename?: 'TrackArtistsByTracksArtistTrackIdAndArtistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
  /** Reads and enables pagination through a set of `TracksArtist`. */
  tracksArtistsByArtistId: TracksArtistsConnection;
};

/** A `Artist` edge in the connection, with data from `TracksArtist`. */
export type TrackArtistsByTracksArtistTrackIdAndArtistIdManyToManyEdgeTracksArtistsByArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TracksArtistCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksArtistsOrderBy>>;
};

/** A condition to be used against `Track` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TrackCondition = {
  /** Checks for equality with the object’s `album` field. */
  album?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `artist` field. */
  artist?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `artistMeta` field. */
  artistMeta?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `availableWorldwide` field. */
  availableWorldwide?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `duration` field. */
  duration?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `explicit` field. */
  explicit?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `genre` field. */
  genre?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `img` field. */
  img?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `isrc` field. */
  isrc?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `likeCount` field. */
  likeCount?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `pinnedAt` field. */
  pinnedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `private` field. */
  private?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `publisher` field. */
  publisher?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `releaseDate` field. */
  releaseDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `size` field. */
  size?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<TrackStatus>;
  /** Checks for equality with the object’s `streamCount` field. */
  streamCount?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `subTitle` field. */
  subTitle?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `textSearch` field. */
  textSearch?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `Country` values, with data from `CountriesTrack`. */
export type TrackCountriesByCountriesTrackTrackIdAndCountryIdManyToManyConnection = {
  __typename?: 'TrackCountriesByCountriesTrackTrackIdAndCountryIdManyToManyConnection';
  /** A list of edges which contains the `Country`, info from the `CountriesTrack`, and the cursor to aid in pagination. */
  edges: Array<TrackCountriesByCountriesTrackTrackIdAndCountryIdManyToManyEdge>;
  /** A list of `Country` objects. */
  nodes: Array<Maybe<Country>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Country` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Country` edge in the connection, with data from `CountriesTrack`. */
export type TrackCountriesByCountriesTrackTrackIdAndCountryIdManyToManyEdge = {
  __typename?: 'TrackCountriesByCountriesTrackTrackIdAndCountryIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Country` at the end of the edge. */
  node?: Maybe<Country>;
};

/** A connection to a list of `Country` values, with data from `Price`. */
export type TrackCountriesByPriceTrackIdAndCountryManyToManyConnection = {
  __typename?: 'TrackCountriesByPriceTrackIdAndCountryManyToManyConnection';
  /** A list of edges which contains the `Country`, info from the `Price`, and the cursor to aid in pagination. */
  edges: Array<TrackCountriesByPriceTrackIdAndCountryManyToManyEdge>;
  /** A list of `Country` objects. */
  nodes: Array<Maybe<Country>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Country` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Country` edge in the connection, with data from `Price`. */
export type TrackCountriesByPriceTrackIdAndCountryManyToManyEdge = {
  __typename?: 'TrackCountriesByPriceTrackIdAndCountryManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Country` at the end of the edge. */
  node?: Maybe<Country>;
  /** Reads and enables pagination through a set of `Price`. */
  pricesByCountry: PricesConnection;
};

/** A `Country` edge in the connection, with data from `Price`. */
export type TrackCountriesByPriceTrackIdAndCountryManyToManyEdgePricesByCountryArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PriceCondition>;
  filter?: InputMaybe<PriceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricesOrderBy>>;
};

/** A filter to be used against `Track` object types. All fields are combined with a logical ‘and.’ */
export type TrackFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TrackFilter>>;
  /** Filter by the object’s `artistMeta` field. */
  artistMeta?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `duration` field. */
  duration?: InputMaybe<IntFilter>;
  /** Filter by the object’s `img` field. */
  img?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isrc` field. */
  isrc?: InputMaybe<StringFilter>;
  /** Filter by the object’s `likeCount` field. */
  likeCount?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TrackFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TrackFilter>>;
  /** Filter by the object’s `pinnedAt` field. */
  pinnedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `releaseDate` field. */
  releaseDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `size` field. */
  size?: InputMaybe<IntFilter>;
  /** Filter by the object’s `streamCount` field. */
  streamCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `subTitle` field. */
  subTitle?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: InputMaybe<StringFilter>;
  /** Filter by the object’s `textSearch` field. */
  textSearch?: InputMaybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `Genre` values, with data from `TracksGenre`. */
export type TrackGenresByTracksGenreTrackIdAndGenreIdManyToManyConnection = {
  __typename?: 'TrackGenresByTracksGenreTrackIdAndGenreIdManyToManyConnection';
  /** A list of edges which contains the `Genre`, info from the `TracksGenre`, and the cursor to aid in pagination. */
  edges: Array<TrackGenresByTracksGenreTrackIdAndGenreIdManyToManyEdge>;
  /** A list of `Genre` objects. */
  nodes: Array<Maybe<Genre>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Genre` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Genre` edge in the connection, with data from `TracksGenre`. */
export type TrackGenresByTracksGenreTrackIdAndGenreIdManyToManyEdge = {
  __typename?: 'TrackGenresByTracksGenreTrackIdAndGenreIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Genre` at the end of the edge. */
  node?: Maybe<Genre>;
  /** Reads and enables pagination through a set of `TracksGenre`. */
  tracksGenresByGenreId: TracksGenresConnection;
};

/** A `Genre` edge in the connection, with data from `TracksGenre`. */
export type TrackGenresByTracksGenreTrackIdAndGenreIdManyToManyEdgeTracksGenresByGenreIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TracksGenreCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksGenresOrderBy>>;
};

/** An input for mutations affecting `Track` */
export type TrackInput = {
  /** Defines the `Album` this `Track` is bound to */
  album?: InputMaybe<Scalars['UUID']>;
  /** The `Artist` of the track */
  artist: Scalars['UUID'];
  /** `Track` metadata for `Artist` */
  artistMeta?: InputMaybe<Scalars['String']>;
  /** Duration of the `Track` in seconds */
  duration?: InputMaybe<Scalars['Int']>;
  /** Defines if the `Track` is explicit */
  explicit?: InputMaybe<Scalars['Boolean']>;
  /** Defines the `Genre` the `Track` is bound to */
  genre?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** Link to the `Track` image */
  img?: InputMaybe<Scalars['String']>;
  /** International Standard Recording Code. 12 alphanumeric characters that represent a unique identification of the song that eg: AA-6Q7-20-00047 */
  isrc?: InputMaybe<Scalars['String']>;
  /** Defines if the `Track` is available for public */
  private?: InputMaybe<Scalars['Boolean']>;
  /** Defines the `RecordLabel` this `Track` is bound to */
  publisher?: InputMaybe<Scalars['UUID']>;
  /** Defines the `Track` release */
  releaseDate?: InputMaybe<Scalars['Datetime']>;
  /** `Track` sub title */
  subTitle?: InputMaybe<Scalars['String']>;
  /** Defines the `Track` tags */
  tags?: InputMaybe<Scalars['String']>;
  /** `Track` title */
  title?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `Track`. Fields that are set will be updated. */
export type TrackPatch = {
  /** Defines the `Album` this `Track` is bound to */
  album?: InputMaybe<Scalars['UUID']>;
  /** The `Artist` of the track */
  artist?: InputMaybe<Scalars['UUID']>;
  /** `Track` metadata for `Artist` */
  artistMeta?: InputMaybe<Scalars['String']>;
  /** Duration of the `Track` in seconds */
  duration?: InputMaybe<Scalars['Int']>;
  /** Defines if the `Track` is explicit */
  explicit?: InputMaybe<Scalars['Boolean']>;
  /** Defines the `Genre` the `Track` is bound to */
  genre?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** Link to the `Track` image */
  img?: InputMaybe<Scalars['String']>;
  /** International Standard Recording Code. 12 alphanumeric characters that represent a unique identification of the song that eg: AA-6Q7-20-00047 */
  isrc?: InputMaybe<Scalars['String']>;
  /** Defines if the `Track` is available for public */
  private?: InputMaybe<Scalars['Boolean']>;
  /** Defines the `RecordLabel` this `Track` is bound to */
  publisher?: InputMaybe<Scalars['UUID']>;
  /** Defines the `Track` release */
  releaseDate?: InputMaybe<Scalars['Datetime']>;
  /** `Track` sub title */
  subTitle?: InputMaybe<Scalars['String']>;
  /** Defines the `Track` tags */
  tags?: InputMaybe<Scalars['String']>;
  /** `Track` title */
  title?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `Playlist` values, with data from `Like`. */
export type TrackPlaylistsByLikeTrackIdAndPlaylistIdManyToManyConnection = {
  __typename?: 'TrackPlaylistsByLikeTrackIdAndPlaylistIdManyToManyConnection';
  /** A list of edges which contains the `Playlist`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<TrackPlaylistsByLikeTrackIdAndPlaylistIdManyToManyEdge>;
  /** A list of `Playlist` objects. */
  nodes: Array<Maybe<Playlist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Playlist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Playlist` edge in the connection, with data from `Like`. */
export type TrackPlaylistsByLikeTrackIdAndPlaylistIdManyToManyEdge = {
  __typename?: 'TrackPlaylistsByLikeTrackIdAndPlaylistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByPlaylistId: LikesConnection;
  /** The `Playlist` at the end of the edge. */
  node?: Maybe<Playlist>;
};

/** A `Playlist` edge in the connection, with data from `Like`. */
export type TrackPlaylistsByLikeTrackIdAndPlaylistIdManyToManyEdgeLikesByPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Playlist` values, with data from `PlaylistsTrack`. */
export type TrackPlaylistsByPlaylistsTrackTrackIdAndPlaylistIdManyToManyConnection = {
  __typename?: 'TrackPlaylistsByPlaylistsTrackTrackIdAndPlaylistIdManyToManyConnection';
  /** A list of edges which contains the `Playlist`, info from the `PlaylistsTrack`, and the cursor to aid in pagination. */
  edges: Array<TrackPlaylistsByPlaylistsTrackTrackIdAndPlaylistIdManyToManyEdge>;
  /** A list of `Playlist` objects. */
  nodes: Array<Maybe<Playlist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Playlist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Playlist` edge in the connection, with data from `PlaylistsTrack`. */
export type TrackPlaylistsByPlaylistsTrackTrackIdAndPlaylistIdManyToManyEdge = {
  __typename?: 'TrackPlaylistsByPlaylistsTrackTrackIdAndPlaylistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  id: Scalars['UUID'];
  /** The `Playlist` at the end of the edge. */
  node?: Maybe<Playlist>;
  /** The order of the `Track` in the `Playlist` collection */
  trackNumber: Scalars['Int'];
};

export type TrackPrice = {
  __typename?: 'TrackPrice';
  amount?: Maybe<Scalars['Int']>;
  basePrice?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  maxPrice?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  percentage?: Maybe<Scalars['Int']>;
  priceId?: Maybe<Scalars['UUID']>;
  validFrom?: Maybe<Scalars['Date']>;
  validUntil?: Maybe<Scalars['Date']>;
};

/** A connection to a list of `Price` values, with data from `Royalty`. */
export type TrackPricesByRoyaltyTrackIdAndPriceIdManyToManyConnection = {
  __typename?: 'TrackPricesByRoyaltyTrackIdAndPriceIdManyToManyConnection';
  /** A list of edges which contains the `Price`, info from the `Royalty`, and the cursor to aid in pagination. */
  edges: Array<TrackPricesByRoyaltyTrackIdAndPriceIdManyToManyEdge>;
  /** A list of `Price` objects. */
  nodes: Array<Maybe<Price>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Price` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Price` edge in the connection, with data from `Royalty`. */
export type TrackPricesByRoyaltyTrackIdAndPriceIdManyToManyEdge = {
  __typename?: 'TrackPricesByRoyaltyTrackIdAndPriceIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Price` at the end of the edge. */
  node?: Maybe<Price>;
  /** Reads and enables pagination through a set of `Royalty`. */
  royaltiesByPriceId: RoyaltiesConnection;
};

/** A `Price` edge in the connection, with data from `Royalty`. */
export type TrackPricesByRoyaltyTrackIdAndPriceIdManyToManyEdgeRoyaltiesByPriceIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RoyaltyCondition>;
  filter?: InputMaybe<RoyaltyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RoyaltiesOrderBy>>;
};

export enum TrackStatus {
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Suspended = 'SUSPENDED',
}

export type TrackSub = {
  __typename?: 'TrackSub';
  allowedFormats?: Maybe<Array<Maybe<Scalars['String']>>>;
  maxDuration?: Maybe<Scalars['Int']>;
  totalDuration?: Maybe<Scalars['Int']>;
  trackLimit?: Maybe<Scalars['Int']>;
  trackSize?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `User` values, with data from `AutoGeneratedQueue`. */
export type TrackUsersByAutoGeneratedQueueTrackIdAndUserIdManyToManyConnection = {
  __typename?: 'TrackUsersByAutoGeneratedQueueTrackIdAndUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `AutoGeneratedQueue`, and the cursor to aid in pagination. */
  edges: Array<TrackUsersByAutoGeneratedQueueTrackIdAndUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `AutoGeneratedQueue`. */
export type TrackUsersByAutoGeneratedQueueTrackIdAndUserIdManyToManyEdge = {
  __typename?: 'TrackUsersByAutoGeneratedQueueTrackIdAndUserIdManyToManyEdge';
  /** Reads and enables pagination through a set of `AutoGeneratedQueue`. */
  autoGeneratedQueuesByUserId: AutoGeneratedQueuesConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** A `User` edge in the connection, with data from `AutoGeneratedQueue`. */
export type TrackUsersByAutoGeneratedQueueTrackIdAndUserIdManyToManyEdgeAutoGeneratedQueuesByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AutoGeneratedQueueCondition>;
  filter?: InputMaybe<AutoGeneratedQueueFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AutoGeneratedQueuesOrderBy>>;
};

/** A connection to a list of `User` values, with data from `EarningsHistory`. */
export type TrackUsersByEarningsHistoryTrackIdAndUserIdManyToManyConnection = {
  __typename?: 'TrackUsersByEarningsHistoryTrackIdAndUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `EarningsHistory`, and the cursor to aid in pagination. */
  edges: Array<TrackUsersByEarningsHistoryTrackIdAndUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `EarningsHistory`. */
export type TrackUsersByEarningsHistoryTrackIdAndUserIdManyToManyEdge = {
  __typename?: 'TrackUsersByEarningsHistoryTrackIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `EarningsHistory`. */
  earningsHistoriesByUserId: EarningsHistoriesConnection;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** A `User` edge in the connection, with data from `EarningsHistory`. */
export type TrackUsersByEarningsHistoryTrackIdAndUserIdManyToManyEdgeEarningsHistoriesByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EarningsHistoryCondition>;
  filter?: InputMaybe<EarningsHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EarningsHistoriesOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Like`. */
export type TrackUsersByLikeTrackIdAndLikedUserIdManyToManyConnection = {
  __typename?: 'TrackUsersByLikeTrackIdAndLikedUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<TrackUsersByLikeTrackIdAndLikedUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Like`. */
export type TrackUsersByLikeTrackIdAndLikedUserIdManyToManyEdge = {
  __typename?: 'TrackUsersByLikeTrackIdAndLikedUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByLikedUserId: LikesConnection;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** A `User` edge in the connection, with data from `Like`. */
export type TrackUsersByLikeTrackIdAndLikedUserIdManyToManyEdgeLikesByLikedUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Like`. */
export type TrackUsersByLikeTrackIdAndUserIdManyToManyConnection = {
  __typename?: 'TrackUsersByLikeTrackIdAndUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<TrackUsersByLikeTrackIdAndUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Like`. */
export type TrackUsersByLikeTrackIdAndUserIdManyToManyEdge = {
  __typename?: 'TrackUsersByLikeTrackIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByUserId: LikesConnection;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** A `User` edge in the connection, with data from `Like`. */
export type TrackUsersByLikeTrackIdAndUserIdManyToManyEdgeLikesByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `User` values, with data from `PlayHistory`. */
export type TrackUsersByPlayHistoryTrackIdAndUserIdManyToManyConnection = {
  __typename?: 'TrackUsersByPlayHistoryTrackIdAndUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `PlayHistory`, and the cursor to aid in pagination. */
  edges: Array<TrackUsersByPlayHistoryTrackIdAndUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PlayHistory`. */
export type TrackUsersByPlayHistoryTrackIdAndUserIdManyToManyEdge = {
  __typename?: 'TrackUsersByPlayHistoryTrackIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
  /** Reads and enables pagination through a set of `PlayHistory`. */
  playHistoriesByUserId: PlayHistoriesConnection;
};

/** A `User` edge in the connection, with data from `PlayHistory`. */
export type TrackUsersByPlayHistoryTrackIdAndUserIdManyToManyEdgePlayHistoriesByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PlayHistoryCondition>;
  filter?: InputMaybe<PlayHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlayHistoriesOrderBy>>;
};

/** A connection to a list of `User` values, with data from `TracksEarning`. */
export type TrackUsersByTracksEarningTrackIdAndUserIdManyToManyConnection = {
  __typename?: 'TrackUsersByTracksEarningTrackIdAndUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `TracksEarning`, and the cursor to aid in pagination. */
  edges: Array<TrackUsersByTracksEarningTrackIdAndUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `TracksEarning`. */
export type TrackUsersByTracksEarningTrackIdAndUserIdManyToManyEdge = {
  __typename?: 'TrackUsersByTracksEarningTrackIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
  /** Reads and enables pagination through a set of `TracksEarning`. */
  tracksEarningsByUserId: TracksEarningsConnection;
};

/** A `User` edge in the connection, with data from `TracksEarning`. */
export type TrackUsersByTracksEarningTrackIdAndUserIdManyToManyEdgeTracksEarningsByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TracksEarningCondition>;
  filter?: InputMaybe<TracksEarningFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksEarningsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `UsersQueue`. */
export type TrackUsersByUsersQueueTrackIdAndUserIdManyToManyConnection = {
  __typename?: 'TrackUsersByUsersQueueTrackIdAndUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `UsersQueue`, and the cursor to aid in pagination. */
  edges: Array<TrackUsersByUsersQueueTrackIdAndUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `UsersQueue`. */
export type TrackUsersByUsersQueueTrackIdAndUserIdManyToManyEdge = {
  __typename?: 'TrackUsersByUsersQueueTrackIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
  /** Reads and enables pagination through a set of `UsersQueue`. */
  usersQueuesByUserId: UsersQueuesConnection;
};

/** A `User` edge in the connection, with data from `UsersQueue`. */
export type TrackUsersByUsersQueueTrackIdAndUserIdManyToManyEdgeUsersQueuesByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UsersQueueCondition>;
  filter?: InputMaybe<UsersQueueFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersQueuesOrderBy>>;
};

/** A track albume table */
export type TracksAlbum = {
  __typename?: 'TracksAlbum';
  /** Reads a single `Album` that is related to this `TracksAlbum`. */
  albumByAlbumId?: Maybe<Album>;
  albumId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Track` that is related to this `TracksAlbum`. */
  trackByTrackId?: Maybe<Track>;
  trackId?: Maybe<Scalars['UUID']>;
  trackNumber?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `TracksAlbum` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TracksAlbumCondition = {
  /** Checks for equality with the object’s `albumId` field. */
  albumId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `trackId` field. */
  trackId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `trackNumber` field. */
  trackNumber?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `TracksAlbum` object types. All fields are combined with a logical ‘and.’ */
export type TracksAlbumFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TracksAlbumFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<TracksAlbumFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TracksAlbumFilter>>;
  /** Filter by the object’s `trackNumber` field. */
  trackNumber?: InputMaybe<IntFilter>;
};

/** A connection to a list of `TracksAlbum` values. */
export type TracksAlbumsConnection = {
  __typename?: 'TracksAlbumsConnection';
  /** A list of edges which contains the `TracksAlbum` and cursor to aid in pagination. */
  edges: Array<TracksAlbumsEdge>;
  /** A list of `TracksAlbum` objects. */
  nodes: Array<Maybe<TracksAlbum>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TracksAlbum` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TracksAlbum` edge in the connection. */
export type TracksAlbumsEdge = {
  __typename?: 'TracksAlbumsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TracksAlbum` at the end of the edge. */
  node?: Maybe<TracksAlbum>;
};

/** Methods to use when ordering `TracksAlbum`. */
export enum TracksAlbumsOrderBy {
  AlbumIdAsc = 'ALBUM_ID_ASC',
  AlbumIdDesc = 'ALBUM_ID_DESC',
  Natural = 'NATURAL',
  TrackIdAsc = 'TRACK_ID_ASC',
  TrackIdDesc = 'TRACK_ID_DESC',
  TrackNumberAsc = 'TRACK_NUMBER_ASC',
  TrackNumberDesc = 'TRACK_NUMBER_DESC',
}

/** `Track`-`Album` relational table */
export type TracksArtist = {
  __typename?: 'TracksArtist';
  /** Reads a single `Artist` that is related to this `TracksArtist`. */
  artistByArtistId?: Maybe<Artist>;
  /** `Artist` unique identifier */
  artistId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Track` that is related to this `TracksArtist`. */
  trackByTrackId?: Maybe<Track>;
  /** `Track` unique identifier */
  trackId?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `TracksArtist` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TracksArtistCondition = {
  /** Checks for equality with the object’s `artistId` field. */
  artistId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `trackId` field. */
  trackId?: InputMaybe<Scalars['UUID']>;
};

/** A connection to a list of `TracksArtist` values. */
export type TracksArtistsConnection = {
  __typename?: 'TracksArtistsConnection';
  /** A list of edges which contains the `TracksArtist` and cursor to aid in pagination. */
  edges: Array<TracksArtistsEdge>;
  /** A list of `TracksArtist` objects. */
  nodes: Array<Maybe<TracksArtist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TracksArtist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TracksArtist` edge in the connection. */
export type TracksArtistsEdge = {
  __typename?: 'TracksArtistsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TracksArtist` at the end of the edge. */
  node?: Maybe<TracksArtist>;
};

/** Methods to use when ordering `TracksArtist`. */
export enum TracksArtistsOrderBy {
  ArtistIdAsc = 'ARTIST_ID_ASC',
  ArtistIdDesc = 'ARTIST_ID_DESC',
  Natural = 'NATURAL',
  TrackIdAsc = 'TRACK_ID_ASC',
  TrackIdDesc = 'TRACK_ID_DESC',
}

/** A connection to a list of `Track` values. */
export type TracksConnection = {
  __typename?: 'TracksConnection';
  /** A list of edges which contains the `Track` and cursor to aid in pagination. */
  edges: Array<TracksEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** `TrackEarning` table */
export type TracksEarning = {
  __typename?: 'TracksEarning';
  /** Defines the datetime with time zone `TrackEarning` was created */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** The amount earned for the `Track` */
  earnings?: Maybe<Scalars['BigInt']>;
  /** Reads a single `Track` that is related to this `TracksEarning`. */
  trackByTrackId?: Maybe<Track>;
  /** `Track` identifier */
  trackId: Scalars['UUID'];
  /** Reads a single `User` that is related to this `TracksEarning`. */
  userByUserId?: Maybe<User>;
  /** `User` identifier */
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `TracksEarning` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TracksEarningCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `earnings` field. */
  earnings?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `trackId` field. */
  trackId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `TracksEarning` object types. All fields are combined with a logical ‘and.’ */
export type TracksEarningFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TracksEarningFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TracksEarningFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TracksEarningFilter>>;
};

/** A connection to a list of `TracksEarning` values. */
export type TracksEarningsConnection = {
  __typename?: 'TracksEarningsConnection';
  /** A list of edges which contains the `TracksEarning` and cursor to aid in pagination. */
  edges: Array<TracksEarningsEdge>;
  /** A list of `TracksEarning` objects. */
  nodes: Array<Maybe<TracksEarning>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TracksEarning` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TracksEarning` edge in the connection. */
export type TracksEarningsEdge = {
  __typename?: 'TracksEarningsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TracksEarning` at the end of the edge. */
  node?: Maybe<TracksEarning>;
};

/** Methods to use when ordering `TracksEarning`. */
export enum TracksEarningsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EarningsAsc = 'EARNINGS_ASC',
  EarningsDesc = 'EARNINGS_DESC',
  Natural = 'NATURAL',
  TrackIdAsc = 'TRACK_ID_ASC',
  TrackIdDesc = 'TRACK_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
}

/** A `Track` edge in the connection. */
export type TracksEdge = {
  __typename?: 'TracksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
};

/** `Track`-`Genre` relational table */
export type TracksGenre = {
  __typename?: 'TracksGenre';
  /** Reads a single `Genre` that is related to this `TracksGenre`. */
  genreByGenreId?: Maybe<Genre>;
  /** `Genre` unique identifier */
  genreId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Track` that is related to this `TracksGenre`. */
  trackByTrackId?: Maybe<Track>;
  /** `Track` unique identifier */
  trackId?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `TracksGenre` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TracksGenreCondition = {
  /** Checks for equality with the object’s `genreId` field. */
  genreId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `trackId` field. */
  trackId?: InputMaybe<Scalars['UUID']>;
};

/** An input for mutations affecting `TracksGenre` */
export type TracksGenreInput = {
  /** `Genre` unique identifier */
  genreId?: InputMaybe<Scalars['UUID']>;
  /** `Track` unique identifier */
  trackId?: InputMaybe<Scalars['UUID']>;
};

/** A connection to a list of `TracksGenre` values. */
export type TracksGenresConnection = {
  __typename?: 'TracksGenresConnection';
  /** A list of edges which contains the `TracksGenre` and cursor to aid in pagination. */
  edges: Array<TracksGenresEdge>;
  /** A list of `TracksGenre` objects. */
  nodes: Array<Maybe<TracksGenre>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TracksGenre` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TracksGenre` edge in the connection. */
export type TracksGenresEdge = {
  __typename?: 'TracksGenresEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TracksGenre` at the end of the edge. */
  node?: Maybe<TracksGenre>;
};

/** Methods to use when ordering `TracksGenre`. */
export enum TracksGenresOrderBy {
  GenreIdAsc = 'GENRE_ID_ASC',
  GenreIdDesc = 'GENRE_ID_DESC',
  Natural = 'NATURAL',
  TrackIdAsc = 'TRACK_ID_ASC',
  TrackIdDesc = 'TRACK_ID_DESC',
}

/** Methods to use when ordering `Track`. */
export enum TracksOrderBy {
  AlbumAsc = 'ALBUM_ASC',
  AlbumDesc = 'ALBUM_DESC',
  ArtistAsc = 'ARTIST_ASC',
  ArtistDesc = 'ARTIST_DESC',
  ArtistMetaAsc = 'ARTIST_META_ASC',
  ArtistMetaDesc = 'ARTIST_META_DESC',
  AvailableWorldwideAsc = 'AVAILABLE_WORLDWIDE_ASC',
  AvailableWorldwideDesc = 'AVAILABLE_WORLDWIDE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DurationAsc = 'DURATION_ASC',
  DurationDesc = 'DURATION_DESC',
  ExplicitAsc = 'EXPLICIT_ASC',
  ExplicitDesc = 'EXPLICIT_DESC',
  GenreAsc = 'GENRE_ASC',
  GenreDesc = 'GENRE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImgAsc = 'IMG_ASC',
  ImgDesc = 'IMG_DESC',
  IsrcAsc = 'ISRC_ASC',
  IsrcDesc = 'ISRC_DESC',
  LikeCountAsc = 'LIKE_COUNT_ASC',
  LikeCountDesc = 'LIKE_COUNT_DESC',
  Natural = 'NATURAL',
  PinnedAtAsc = 'PINNED_AT_ASC',
  PinnedAtDesc = 'PINNED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PrivateAsc = 'PRIVATE_ASC',
  PrivateDesc = 'PRIVATE_DESC',
  PublisherAsc = 'PUBLISHER_ASC',
  PublisherDesc = 'PUBLISHER_DESC',
  ReleaseDateAsc = 'RELEASE_DATE_ASC',
  ReleaseDateDesc = 'RELEASE_DATE_DESC',
  SizeAsc = 'SIZE_ASC',
  SizeDesc = 'SIZE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  StreamCountAsc = 'STREAM_COUNT_ASC',
  StreamCountDesc = 'STREAM_COUNT_DESC',
  SubTitleAsc = 'SUB_TITLE_ASC',
  SubTitleDesc = 'SUB_TITLE_DESC',
  TagsAsc = 'TAGS_ASC',
  TagsDesc = 'TAGS_DESC',
  TextSearchAsc = 'TEXT_SEARCH_ASC',
  TextSearchDesc = 'TEXT_SEARCH_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  TrackNumberOfLikesAsc = 'TRACK_NUMBER_OF_LIKES_ASC',
  TrackNumberOfLikesDesc = 'TRACK_NUMBER_OF_LIKES_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** All input for the `updateAlbumById` mutation. */
export type UpdateAlbumByIdInput = {
  /** An object where the defined keys will be set on the `Album` being updated. */
  albumPatch: AlbumPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `updateAlbum` mutation. */
export type UpdateAlbumInput = {
  /** An object where the defined keys will be set on the `Album` being updated. */
  albumPatch: AlbumPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Album` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `Album` mutation. */
export type UpdateAlbumPayload = {
  __typename?: 'UpdateAlbumPayload';
  /** The `Album` that was updated by this mutation. */
  album?: Maybe<Album>;
  /** An edge for our `Album`. May be used by Relay 1. */
  albumEdge?: Maybe<AlbumsEdge>;
  /** Reads a single `Artist` that is related to this `Album`. */
  artistByArtist?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Genre` that is related to this `Album`. */
  genreByGenre?: Maybe<Genre>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `RecordLabel` that is related to this `Album`. */
  recordLabelByPublisher?: Maybe<RecordLabel>;
};

/** The output of our update `Album` mutation. */
export type UpdateAlbumPayloadAlbumEdgeArgs = {
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** All input for the `updateAlbumsTracksByAlbumIdAndTrackId` mutation. */
export type UpdateAlbumsTracksByAlbumIdAndTrackIdInput = {
  albumId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  trackId: Scalars['UUID'];
  trackNumber: Scalars['Int'];
};

/** The output of our `updateAlbumsTracksByAlbumIdAndTrackId` mutation. */
export type UpdateAlbumsTracksByAlbumIdAndTrackIdPayload = {
  __typename?: 'UpdateAlbumsTracksByAlbumIdAndTrackIdPayload';
  albumsTracks?: Maybe<Array<Maybe<AlbumsTrack>>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateArtistByBlarecastUrl` mutation. */
export type UpdateArtistByBlarecastUrlInput = {
  /** An object where the defined keys will be set on the `Artist` being updated. */
  artistPatch: ArtistPatch;
  /** The `Artist` internal url */
  blarecastUrl: Scalars['String'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** All input for the `updateArtistById` mutation. */
export type UpdateArtistByIdInput = {
  /** An object where the defined keys will be set on the `Artist` being updated. */
  artistPatch: ArtistPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique identifier for row */
  id: Scalars['UUID'];
};

/** All input for the `updateArtist` mutation. */
export type UpdateArtistInput = {
  /** An object where the defined keys will be set on the `Artist` being updated. */
  artistPatch: ArtistPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Artist` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `Artist` mutation. */
export type UpdateArtistPayload = {
  __typename?: 'UpdateArtistPayload';
  /** The `Artist` that was updated by this mutation. */
  artist?: Maybe<Artist>;
  /** An edge for our `Artist`. May be used by Relay 1. */
  artistEdge?: Maybe<ArtistsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Genre` that is related to this `Artist`. */
  genreByGenre?: Maybe<Genre>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Artist`. */
  userByUserId?: Maybe<User>;
};

/** The output of our update `Artist` mutation. */
export type UpdateArtistPayloadArtistEdgeArgs = {
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** All input for the `updateArtistSubscriptionById` mutation. */
export type UpdateArtistSubscriptionByIdInput = {
  /** An object where the defined keys will be set on the `ArtistSubscription` being updated. */
  artistSubscriptionPatch: ArtistSubscriptionPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique identifier for row */
  id: Scalars['UUID'];
};

/** All input for the `updateArtistSubscription` mutation. */
export type UpdateArtistSubscriptionInput = {
  /** An object where the defined keys will be set on the `ArtistSubscription` being updated. */
  artistSubscriptionPatch: ArtistSubscriptionPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ArtistSubscription` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `ArtistSubscription` mutation. */
export type UpdateArtistSubscriptionPayload = {
  __typename?: 'UpdateArtistSubscriptionPayload';
  /** Reads a single `Artist` that is related to this `ArtistSubscription`. */
  artistByArtistId?: Maybe<Artist>;
  /** The `ArtistSubscription` that was updated by this mutation. */
  artistSubscription?: Maybe<ArtistSubscription>;
  /** Reads a single `ArtistSubscription` that is related to this `ArtistSubscription`. */
  artistSubscriptionByDowngradeTo?: Maybe<ArtistSubscription>;
  /** Reads a single `ArtistSubscription` that is related to this `ArtistSubscription`. */
  artistSubscriptionByUpgradeTo?: Maybe<ArtistSubscription>;
  /** An edge for our `ArtistSubscription`. May be used by Relay 1. */
  artistSubscriptionEdge?: Maybe<ArtistSubscriptionsEdge>;
  /** Reads a single `ArtistSubscriptionType` that is related to this `ArtistSubscription`. */
  artistSubscriptionTypeBySubscriptionType?: Maybe<ArtistSubscriptionType>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ArtistSubscription`. */
  userByUserId?: Maybe<User>;
};

/** The output of our update `ArtistSubscription` mutation. */
export type UpdateArtistSubscriptionPayloadArtistSubscriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** All input for the `updateAutoGeneratedQueueById` mutation. */
export type UpdateAutoGeneratedQueueByIdInput = {
  /** An object where the defined keys will be set on the `AutoGeneratedQueue` being updated. */
  autoGeneratedQueuePatch: AutoGeneratedQueuePatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `updateAutoGeneratedQueue` mutation. */
export type UpdateAutoGeneratedQueueInput = {
  /** An object where the defined keys will be set on the `AutoGeneratedQueue` being updated. */
  autoGeneratedQueuePatch: AutoGeneratedQueuePatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AutoGeneratedQueue` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `AutoGeneratedQueue` mutation. */
export type UpdateAutoGeneratedQueuePayload = {
  __typename?: 'UpdateAutoGeneratedQueuePayload';
  /** The `AutoGeneratedQueue` that was updated by this mutation. */
  autoGeneratedQueue?: Maybe<AutoGeneratedQueue>;
  /** An edge for our `AutoGeneratedQueue`. May be used by Relay 1. */
  autoGeneratedQueueEdge?: Maybe<AutoGeneratedQueuesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `AutoGeneratedQueue`. */
  trackByTrackId?: Maybe<Track>;
  /** Reads a single `User` that is related to this `AutoGeneratedQueue`. */
  userByUserId?: Maybe<User>;
};

/** The output of our update `AutoGeneratedQueue` mutation. */
export type UpdateAutoGeneratedQueuePayloadAutoGeneratedQueueEdgeArgs = {
  orderBy?: InputMaybe<Array<AutoGeneratedQueuesOrderBy>>;
};

/** All input for the `updateEventById` mutation. */
export type UpdateEventByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Event` being updated. */
  eventPatch: EventPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateEvent` mutation. */
export type UpdateEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Event` being updated. */
  eventPatch: EventPatch;
  /** The globally unique `ID` which will identify a single `Event` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `Event` mutation. */
export type UpdateEventPayload = {
  __typename?: 'UpdateEventPayload';
  /** Reads a single `Artist` that is related to this `Event`. */
  artistByArtistId?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Event` that was updated by this mutation. */
  event?: Maybe<Event>;
  /** An edge for our `Event`. May be used by Relay 1. */
  eventEdge?: Maybe<EventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `Event` mutation. */
export type UpdateEventPayloadEventEdgeArgs = {
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
};

/** All input for the `updateExtraArtistById` mutation. */
export type UpdateExtraArtistByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ExtraArtist` being updated. */
  extraArtistPatch: ExtraArtistPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateExtraArtist` mutation. */
export type UpdateExtraArtistInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ExtraArtist` being updated. */
  extraArtistPatch: ExtraArtistPatch;
  /** The globally unique `ID` which will identify a single `ExtraArtist` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `ExtraArtist` mutation. */
export type UpdateExtraArtistPayload = {
  __typename?: 'UpdateExtraArtistPayload';
  /** Reads a single `Album` that is related to this `ExtraArtist`. */
  albumByAlbum?: Maybe<Album>;
  /** Reads a single `Artist` that is related to this `ExtraArtist`. */
  artistByArtist?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ExtraArtist` that was updated by this mutation. */
  extraArtist?: Maybe<ExtraArtist>;
  /** An edge for our `ExtraArtist`. May be used by Relay 1. */
  extraArtistEdge?: Maybe<ExtraArtistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `ExtraArtist` mutation. */
export type UpdateExtraArtistPayloadExtraArtistEdgeArgs = {
  orderBy?: InputMaybe<Array<ExtraArtistsOrderBy>>;
};

/** All input for the `updateFeaturedArtistById` mutation. */
export type UpdateFeaturedArtistByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `FeaturedArtist` being updated. */
  featuredArtistPatch: FeaturedArtistPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateFeaturedArtist` mutation. */
export type UpdateFeaturedArtistInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `FeaturedArtist` being updated. */
  featuredArtistPatch: FeaturedArtistPatch;
  /** The globally unique `ID` which will identify a single `FeaturedArtist` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `FeaturedArtist` mutation. */
export type UpdateFeaturedArtistPayload = {
  __typename?: 'UpdateFeaturedArtistPayload';
  /** Reads a single `Artist` that is related to this `FeaturedArtist`. */
  artistByArtist?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FeaturedArtist` that was updated by this mutation. */
  featuredArtist?: Maybe<FeaturedArtist>;
  /** An edge for our `FeaturedArtist`. May be used by Relay 1. */
  featuredArtistEdge?: Maybe<FeaturedArtistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `FeaturedArtist`. */
  trackByTrack?: Maybe<Track>;
};

/** The output of our update `FeaturedArtist` mutation. */
export type UpdateFeaturedArtistPayloadFeaturedArtistEdgeArgs = {
  orderBy?: InputMaybe<Array<FeaturedArtistsOrderBy>>;
};

/** All input for the `updateFirebaseTokenById` mutation. */
export type UpdateFirebaseTokenByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `FirebaseToken` being updated. */
  firebaseTokenPatch: FirebaseTokenPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateFirebaseTokenByToken` mutation. */
export type UpdateFirebaseTokenByTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `FirebaseToken` being updated. */
  firebaseTokenPatch: FirebaseTokenPatch;
  /** Firebase token value */
  token: Scalars['String'];
};

/** All input for the `updateFirebaseTokenByUserIdAndDevice` mutation. */
export type UpdateFirebaseTokenByUserIdAndDeviceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The device type this `FirebaseToken` is bound to */
  device: DeviceType;
  /** An object where the defined keys will be set on the `FirebaseToken` being updated. */
  firebaseTokenPatch: FirebaseTokenPatch;
  /** The primary unique identifier for the user */
  userId: Scalars['UUID'];
};

/** All input for the `updateFirebaseToken` mutation. */
export type UpdateFirebaseTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `FirebaseToken` being updated. */
  firebaseTokenPatch: FirebaseTokenPatch;
  /** The globally unique `ID` which will identify a single `FirebaseToken` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `FirebaseToken` mutation. */
export type UpdateFirebaseTokenPayload = {
  __typename?: 'UpdateFirebaseTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FirebaseToken` that was updated by this mutation. */
  firebaseToken?: Maybe<FirebaseToken>;
  /** An edge for our `FirebaseToken`. May be used by Relay 1. */
  firebaseTokenEdge?: Maybe<FirebaseTokensEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `FirebaseToken`. */
  userByUserId?: Maybe<User>;
};

/** The output of our update `FirebaseToken` mutation. */
export type UpdateFirebaseTokenPayloadFirebaseTokenEdgeArgs = {
  orderBy?: InputMaybe<Array<FirebaseTokensOrderBy>>;
};

/** All input for the `updateFlywaySchemaHistoryByInstalledRank` mutation. */
export type UpdateFlywaySchemaHistoryByInstalledRankInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `FlywaySchemaHistory` being updated. */
  flywaySchemaHistoryPatch: FlywaySchemaHistoryPatch;
  installedRank: Scalars['Int'];
};

/** All input for the `updateFlywaySchemaHistory` mutation. */
export type UpdateFlywaySchemaHistoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `FlywaySchemaHistory` being updated. */
  flywaySchemaHistoryPatch: FlywaySchemaHistoryPatch;
  /** The globally unique `ID` which will identify a single `FlywaySchemaHistory` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `FlywaySchemaHistory` mutation. */
export type UpdateFlywaySchemaHistoryPayload = {
  __typename?: 'UpdateFlywaySchemaHistoryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FlywaySchemaHistory` that was updated by this mutation. */
  flywaySchemaHistory?: Maybe<FlywaySchemaHistory>;
  /** An edge for our `FlywaySchemaHistory`. May be used by Relay 1. */
  flywaySchemaHistoryEdge?: Maybe<FlywaySchemaHistoriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `FlywaySchemaHistory` mutation. */
export type UpdateFlywaySchemaHistoryPayloadFlywaySchemaHistoryEdgeArgs = {
  orderBy?: InputMaybe<Array<FlywaySchemaHistoriesOrderBy>>;
};

/** All input for the `updateLastPlayedTrackById` mutation. */
export type UpdateLastPlayedTrackByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** A `LastPlayedTrack` unique identifier */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `LastPlayedTrack` being updated. */
  lastPlayedTrackPatch: LastPlayedTrackPatch;
};

/** All input for the `updateLastPlayedTrackByTrackIdAndUserId` mutation. */
export type UpdateLastPlayedTrackByTrackIdAndUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LastPlayedTrack` being updated. */
  lastPlayedTrackPatch: LastPlayedTrackPatch;
  /** The `Track` that was played` */
  trackId: Scalars['UUID'];
  /** The `User` that played the `Track` */
  userId: Scalars['UUID'];
};

/** All input for the `updateLastPlayedTrack` mutation. */
export type UpdateLastPlayedTrackInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LastPlayedTrack` being updated. */
  lastPlayedTrackPatch: LastPlayedTrackPatch;
  /** The globally unique `ID` which will identify a single `LastPlayedTrack` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `LastPlayedTrack` mutation. */
export type UpdateLastPlayedTrackPayload = {
  __typename?: 'UpdateLastPlayedTrackPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LastPlayedTrack` that was updated by this mutation. */
  lastPlayedTrack?: Maybe<LastPlayedTrack>;
  /** An edge for our `LastPlayedTrack`. May be used by Relay 1. */
  lastPlayedTrackEdge?: Maybe<LastPlayedTracksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `LastPlayedTrack`. */
  trackByTrackId?: Maybe<Track>;
};

/** The output of our update `LastPlayedTrack` mutation. */
export type UpdateLastPlayedTrackPayloadLastPlayedTrackEdgeArgs = {
  orderBy?: InputMaybe<Array<LastPlayedTracksOrderBy>>;
};

/** All input for the `updateListenerPackageById` mutation. */
export type UpdateListenerPackageByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /**  Defines the `ListenerPackage` unique identifier */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `ListenerPackage` being updated. */
  listenerPackagePatch: ListenerPackagePatch;
};

/** All input for the `updateListenerPackage` mutation. */
export type UpdateListenerPackageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ListenerPackage` being updated. */
  listenerPackagePatch: ListenerPackagePatch;
  /** The globally unique `ID` which will identify a single `ListenerPackage` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `ListenerPackage` mutation. */
export type UpdateListenerPackagePayload = {
  __typename?: 'UpdateListenerPackagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ListenerPackage` that was updated by this mutation. */
  listenerPackage?: Maybe<ListenerPackage>;
  /** An edge for our `ListenerPackage`. May be used by Relay 1. */
  listenerPackageEdge?: Maybe<ListenerPackagesEdge>;
  /** Reads a single `ListenerPackageType` that is related to this `ListenerPackage`. */
  listenerPackageTypeByPackageType?: Maybe<ListenerPackageType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ListenerPackage`. */
  userByUserId?: Maybe<User>;
};

/** The output of our update `ListenerPackage` mutation. */
export type UpdateListenerPackagePayloadListenerPackageEdgeArgs = {
  orderBy?: InputMaybe<Array<ListenerPackagesOrderBy>>;
};

/** All input for the `updateNextUp` mutation. */
export type UpdateNextUpInput = {
  album?: InputMaybe<Scalars['UUID']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  favourites?: InputMaybe<Scalars['Boolean']>;
  playlist?: InputMaybe<Scalars['UUID']>;
  startAt?: InputMaybe<Scalars['Int']>;
};

/** The output of our `updateNextUp` mutation. */
export type UpdateNextUpPayload = {
  __typename?: 'UpdateNextUpPayload';
  autoGeneratedQueues?: Maybe<Array<Maybe<AutoGeneratedQueue>>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateNotificationById` mutation. */
export type UpdateNotificationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Notification` being updated. */
  notificationPatch: NotificationPatch;
};

/** All input for the `updateNotification` mutation. */
export type UpdateNotificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Notification` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Notification` being updated. */
  notificationPatch: NotificationPatch;
};

/** The output of our update `Notification` mutation. */
export type UpdateNotificationPayload = {
  __typename?: 'UpdateNotificationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Notification` that was updated by this mutation. */
  notification?: Maybe<Notification>;
  /** An edge for our `Notification`. May be used by Relay 1. */
  notificationEdge?: Maybe<NotificationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Notification`. */
  userByUserId?: Maybe<User>;
};

/** The output of our update `Notification` mutation. */
export type UpdateNotificationPayloadNotificationEdgeArgs = {
  orderBy?: InputMaybe<Array<NotificationsOrderBy>>;
};

/** All input for the `updateNotificationsAllRead` mutation. */
export type UpdateNotificationsAllReadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `updateNotificationsAllRead` mutation. */
export type UpdateNotificationsAllReadPayload = {
  __typename?: 'UpdateNotificationsAllReadPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updatePlayHistoryById` mutation. */
export type UpdatePlayHistoryByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `PlayHistory` being updated. */
  playHistoryPatch: PlayHistoryPatch;
};

/** All input for the `updatePlayHistory` mutation. */
export type UpdatePlayHistoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PlayHistory` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `PlayHistory` being updated. */
  playHistoryPatch: PlayHistoryPatch;
};

/** The output of our update `PlayHistory` mutation. */
export type UpdatePlayHistoryPayload = {
  __typename?: 'UpdatePlayHistoryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PlayHistory` that was updated by this mutation. */
  playHistory?: Maybe<PlayHistory>;
  /** An edge for our `PlayHistory`. May be used by Relay 1. */
  playHistoryEdge?: Maybe<PlayHistoriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `PlayHistory`. */
  trackByTrackId?: Maybe<Track>;
  /** Reads a single `User` that is related to this `PlayHistory`. */
  userByUserId?: Maybe<User>;
};

/** The output of our update `PlayHistory` mutation. */
export type UpdatePlayHistoryPayloadPlayHistoryEdgeArgs = {
  orderBy?: InputMaybe<Array<PlayHistoriesOrderBy>>;
};

/** All input for the `updatePlaylistById` mutation. */
export type UpdatePlaylistByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Playlist` being updated. */
  playlistPatch: PlaylistPatch;
};

/** All input for the `updatePlaylist` mutation. */
export type UpdatePlaylistInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Playlist` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Playlist` being updated. */
  playlistPatch: PlaylistPatch;
};

/** The output of our update `Playlist` mutation. */
export type UpdatePlaylistPayload = {
  __typename?: 'UpdatePlaylistPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Playlist` that was updated by this mutation. */
  playlist?: Maybe<Playlist>;
  /** An edge for our `Playlist`. May be used by Relay 1. */
  playlistEdge?: Maybe<PlaylistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByAuthor?: Maybe<User>;
};

/** The output of our update `Playlist` mutation. */
export type UpdatePlaylistPayloadPlaylistEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** All input for the `updatePlaylistTrackOrder` mutation. */
export type UpdatePlaylistTrackOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  playlist: Scalars['UUID'];
  track: Scalars['UUID'];
  trackNumber: Scalars['Int'];
};

/** The output of our `updatePlaylistTrackOrder` mutation. */
export type UpdatePlaylistTrackOrderPayload = {
  __typename?: 'UpdatePlaylistTrackOrderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Playlist` that is related to this `PlaylistsTrack`. */
  playlistByPlaylistId?: Maybe<Playlist>;
  playlistsTrack?: Maybe<PlaylistsTrack>;
  /** An edge for our `PlaylistsTrack`. May be used by Relay 1. */
  playlistsTrackEdge?: Maybe<PlaylistsTracksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `PlaylistsTrack`. */
  trackByTrackId?: Maybe<Track>;
};

/** The output of our `updatePlaylistTrackOrder` mutation. */
export type UpdatePlaylistTrackOrderPayloadPlaylistsTrackEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsTracksOrderBy>>;
};

/** All input for the `updatePlaylistsTrackById` mutation. */
export type UpdatePlaylistsTrackByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `PlaylistsTrack` being updated. */
  playlistsTrackPatch: PlaylistsTrackPatch;
};

/** All input for the `updatePlaylistsTrackByTrackIdAndPlaylistId` mutation. */
export type UpdatePlaylistsTrackByTrackIdAndPlaylistIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The id of the `Playlist` that the collection is part of */
  playlistId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `PlaylistsTrack` being updated. */
  playlistsTrackPatch: PlaylistsTrackPatch;
  /** The id of the `Track` that is part of `Playlist` */
  trackId: Scalars['UUID'];
};

/** All input for the `updatePlaylistsTrack` mutation. */
export type UpdatePlaylistsTrackInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PlaylistsTrack` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `PlaylistsTrack` being updated. */
  playlistsTrackPatch: PlaylistsTrackPatch;
};

/** The output of our update `PlaylistsTrack` mutation. */
export type UpdatePlaylistsTrackPayload = {
  __typename?: 'UpdatePlaylistsTrackPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Playlist` that is related to this `PlaylistsTrack`. */
  playlistByPlaylistId?: Maybe<Playlist>;
  /** The `PlaylistsTrack` that was updated by this mutation. */
  playlistsTrack?: Maybe<PlaylistsTrack>;
  /** An edge for our `PlaylistsTrack`. May be used by Relay 1. */
  playlistsTrackEdge?: Maybe<PlaylistsTracksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `PlaylistsTrack`. */
  trackByTrackId?: Maybe<Track>;
};

/** The output of our update `PlaylistsTrack` mutation. */
export type UpdatePlaylistsTrackPayloadPlaylistsTrackEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsTracksOrderBy>>;
};

/** All input for the `updateProfilePublicByBlarecastUrl` mutation. */
export type UpdateProfilePublicByBlarecastUrlInput = {
  /** A unique url that identifies a `User` */
  blarecastUrl: Scalars['String'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ProfilePublic` being updated. */
  profilePublicPatch: ProfilePublicPatch;
};

/** All input for the `updateProfilePublicByUserId` mutation. */
export type UpdateProfilePublicByUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ProfilePublic` being updated. */
  profilePublicPatch: ProfilePublicPatch;
  /** The `User` unique identifier */
  userId: Scalars['UUID'];
};

/** All input for the `updateProfilePublic` mutation. */
export type UpdateProfilePublicInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProfilePublic` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProfilePublic` being updated. */
  profilePublicPatch: ProfilePublicPatch;
};

/** The output of our update `ProfilePublic` mutation. */
export type UpdateProfilePublicPayload = {
  __typename?: 'UpdateProfilePublicPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProfilePublic` that was updated by this mutation. */
  profilePublic?: Maybe<ProfilePublic>;
  /** An edge for our `ProfilePublic`. May be used by Relay 1. */
  profilePublicEdge?: Maybe<ProfilePublicsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ProfilePublic`. */
  userByUserId?: Maybe<User>;
};

/** The output of our update `ProfilePublic` mutation. */
export type UpdateProfilePublicPayloadProfilePublicEdgeArgs = {
  orderBy?: InputMaybe<Array<ProfilePublicsOrderBy>>;
};

/** All input for the `updateProfileSettingByUserId` mutation. */
export type UpdateProfileSettingByUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ProfileSetting` being updated. */
  profileSettingPatch: ProfileSettingPatch;
  /** The id of the `User` associated with this account */
  userId: Scalars['UUID'];
};

/** All input for the `updateProfileSetting` mutation. */
export type UpdateProfileSettingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProfileSetting` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProfileSetting` being updated. */
  profileSettingPatch: ProfileSettingPatch;
};

/** The output of our update `ProfileSetting` mutation. */
export type UpdateProfileSettingPayload = {
  __typename?: 'UpdateProfileSettingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Country` that is related to this `ProfileSetting`. */
  countryByGeolocation?: Maybe<Country>;
  /** The `ProfileSetting` that was updated by this mutation. */
  profileSetting?: Maybe<ProfileSetting>;
  /** An edge for our `ProfileSetting`. May be used by Relay 1. */
  profileSettingEdge?: Maybe<ProfileSettingsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `RecordLabel` that is related to this `ProfileSetting`. */
  recordLabelByRecordLabelId?: Maybe<RecordLabel>;
  /** Reads a single `User` that is related to this `ProfileSetting`. */
  userByUserId?: Maybe<User>;
};

/** The output of our update `ProfileSetting` mutation. */
export type UpdateProfileSettingPayloadProfileSettingEdgeArgs = {
  orderBy?: InputMaybe<Array<ProfileSettingsOrderBy>>;
};

/** All input for the `updateSocialMedia` mutation. */
export type UpdateSocialMediaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  facebookUrl?: InputMaybe<Scalars['String']>;
  instagramUrl?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
};

/** The output of our `updateSocialMedia` mutation. */
export type UpdateSocialMediaPayload = {
  __typename?: 'UpdateSocialMediaPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Country` that is related to this `ProfileSetting`. */
  countryByGeolocation?: Maybe<Country>;
  profileSetting?: Maybe<ProfileSetting>;
  /** An edge for our `ProfileSetting`. May be used by Relay 1. */
  profileSettingEdge?: Maybe<ProfileSettingsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `RecordLabel` that is related to this `ProfileSetting`. */
  recordLabelByRecordLabelId?: Maybe<RecordLabel>;
  /** Reads a single `User` that is related to this `ProfileSetting`. */
  userByUserId?: Maybe<User>;
};

/** The output of our `updateSocialMedia` mutation. */
export type UpdateSocialMediaPayloadProfileSettingEdgeArgs = {
  orderBy?: InputMaybe<Array<ProfileSettingsOrderBy>>;
};

/** All input for the `updateTrackById` mutation. */
export type UpdateTrackByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Track` being updated. */
  trackPatch: TrackPatch;
};

/** All input for the `updateTrack` mutation. */
export type UpdateTrackInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Track` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Track` being updated. */
  trackPatch: TrackPatch;
};

/** All input for the `updateTrackOrder` mutation. */
export type UpdateTrackOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  playlist: Scalars['UUID'];
  track: Scalars['UUID'];
  trackNumber: Scalars['Int'];
};

/** The output of our `updateTrackOrder` mutation. */
export type UpdateTrackOrderPayload = {
  __typename?: 'UpdateTrackOrderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Playlist` that is related to this `PlaylistsTrack`. */
  playlistByPlaylistId?: Maybe<Playlist>;
  playlistsTrack?: Maybe<PlaylistsTrack>;
  /** An edge for our `PlaylistsTrack`. May be used by Relay 1. */
  playlistsTrackEdge?: Maybe<PlaylistsTracksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `PlaylistsTrack`. */
  trackByTrackId?: Maybe<Track>;
};

/** The output of our `updateTrackOrder` mutation. */
export type UpdateTrackOrderPayloadPlaylistsTrackEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsTracksOrderBy>>;
};

/** The output of our update `Track` mutation. */
export type UpdateTrackPayload = {
  __typename?: 'UpdateTrackPayload';
  /** Reads a single `Album` that is related to this `Track`. */
  albumByAlbum?: Maybe<Album>;
  /** Reads a single `Artist` that is related to this `Track`. */
  artistByArtist?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Genre` that is related to this `Track`. */
  genreByGenre?: Maybe<Genre>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `RecordLabel` that is related to this `Track`. */
  recordLabelByPublisher?: Maybe<RecordLabel>;
  /** The `Track` that was updated by this mutation. */
  track?: Maybe<Track>;
  /** An edge for our `Track`. May be used by Relay 1. */
  trackEdge?: Maybe<TracksEdge>;
};

/** The output of our update `Track` mutation. */
export type UpdateTrackPayloadTrackEdgeArgs = {
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** All input for the `updateUserRegistration` mutation. */
export type UpdateUserRegistrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** The output of our `updateUserRegistration` mutation. */
export type UpdateUserRegistrationPayload = {
  __typename?: 'UpdateUserRegistrationPayload';
  boolean?: Maybe<Scalars['Boolean']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateUsersNotificationByUserId` mutation. */
export type UpdateUsersNotificationByUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The id of the `User` associated with this account */
  userId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `UsersNotification` being updated. */
  usersNotificationPatch: UsersNotificationPatch;
};

/** All input for the `updateUsersNotification` mutation. */
export type UpdateUsersNotificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UsersNotification` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UsersNotification` being updated. */
  usersNotificationPatch: UsersNotificationPatch;
};

/** The output of our update `UsersNotification` mutation. */
export type UpdateUsersNotificationPayload = {
  __typename?: 'UpdateUsersNotificationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UsersNotification`. */
  userByUserId?: Maybe<User>;
  /** The `UsersNotification` that was updated by this mutation. */
  usersNotification?: Maybe<UsersNotification>;
  /** An edge for our `UsersNotification`. May be used by Relay 1. */
  usersNotificationEdge?: Maybe<UsersNotificationsEdge>;
};

/** The output of our update `UsersNotification` mutation. */
export type UpdateUsersNotificationPayloadUsersNotificationEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersNotificationsOrderBy>>;
};

/** All input for the `updateUsersQueueById` mutation. */
export type UpdateUsersQueueByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `UsersQueue` being updated. */
  usersQueuePatch: UsersQueuePatch;
};

/** All input for the `updateUsersQueue` mutation. */
export type UpdateUsersQueueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UsersQueue` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UsersQueue` being updated. */
  usersQueuePatch: UsersQueuePatch;
};

/** The output of our update `UsersQueue` mutation. */
export type UpdateUsersQueuePayload = {
  __typename?: 'UpdateUsersQueuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `UsersQueue`. */
  trackByTrackId?: Maybe<Track>;
  /** Reads a single `User` that is related to this `UsersQueue`. */
  userByUserId?: Maybe<User>;
  /** The `UsersQueue` that was updated by this mutation. */
  usersQueue?: Maybe<UsersQueue>;
  /** An edge for our `UsersQueue`. May be used by Relay 1. */
  usersQueueEdge?: Maybe<UsersQueuesEdge>;
};

/** The output of our update `UsersQueue` mutation. */
export type UpdateUsersQueuePayloadUsersQueueEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersQueuesOrderBy>>;
};

/** `User` table */
export type User = Node & {
  __typename?: 'User';
  accountBalance?: Maybe<AccountBalancePayload>;
  /** Reads and enables pagination through a set of `Album`. */
  albumsByLikeLikedUserIdAndAlbumId: UserAlbumsByLikeLikedUserIdAndAlbumIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Album`. */
  albumsByLikeUserIdAndAlbumId: UserAlbumsByLikeUserIdAndAlbumIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Album`. */
  albumsByRecentlyPlayedUserIdAndAlbumId: UserAlbumsByRecentlyPlayedUserIdAndAlbumIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ArtistMember`. */
  artistMembersByUserId: ArtistMembersConnection;
  /** Reads and enables pagination through a set of `ArtistSubscriptionType`. */
  artistSubscriptionTypesByArtistSubscriptionUserIdAndSubscriptionType: UserArtistSubscriptionTypesByArtistSubscriptionUserIdAndSubscriptionTypeManyToManyConnection;
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByArtistSubscriptionUserIdAndDowngradeTo: UserArtistSubscriptionsByArtistSubscriptionUserIdAndDowngradeToManyToManyConnection;
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByArtistSubscriptionUserIdAndUpgradeTo: UserArtistSubscriptionsByArtistSubscriptionUserIdAndUpgradeToManyToManyConnection;
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByUserId: ArtistSubscriptionsConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByArtistMemberUserIdAndArtistId: UserArtistsByArtistMemberUserIdAndArtistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByArtistSubscriptionUserIdAndArtistId: UserArtistsByArtistSubscriptionUserIdAndArtistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByLikeLikedUserIdAndActingArtistId: UserArtistsByLikeLikedUserIdAndActingArtistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByLikeLikedUserIdAndArtistId: UserArtistsByLikeLikedUserIdAndArtistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByLikeUserIdAndActingArtistId: UserArtistsByLikeUserIdAndActingArtistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByLikeUserIdAndArtistId: UserArtistsByLikeUserIdAndArtistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByRecentlyPlayedUserIdAndArtistId: UserArtistsByRecentlyPlayedUserIdAndArtistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByUserId: ArtistsConnection;
  /** Reads and enables pagination through a set of `AutoGeneratedQueue`. */
  autoGeneratedQueuesByUserId: AutoGeneratedQueuesConnection;
  /** Defines the datetime with time zone `User` was first created */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `EarningsHistory`. */
  earningsHistoriesByUserId: EarningsHistoriesConnection;
  /** Reads and enables pagination through a set of `FirebaseToken`. */
  firebaseTokensByUserId: FirebaseTokensConnection;
  /** Reads and enables pagination through a set of `Genre`. */
  genresByArtistUserIdAndGenre: UserGenresByArtistUserIdAndGenreManyToManyConnection;
  /** The primary unique identifier for the `User` */
  id: Scalars['UUID'];
  /** Reads and enables pagination through a set of `Like`. */
  likesByLikedUserId: LikesConnection;
  /** Reads and enables pagination through a set of `Like`. */
  likesByUserId: LikesConnection;
  /** Reads and enables pagination through a set of `ListenerPackageType`. */
  listenerPackageTypesByListenerPackageUserIdAndPackageType: UserListenerPackageTypesByListenerPackageUserIdAndPackageTypeManyToManyConnection;
  /** Reads and enables pagination through a set of `ListenerPackage`. */
  listenerPackagesByUserId: ListenerPackagesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByUserId: NotificationsConnection;
  /** Reads and enables pagination through a set of `PlayHistory`. */
  playHistoriesByUserId: PlayHistoriesConnection;
  /** Reads and enables pagination through a set of `Playlist`. */
  playlistsByAuthor: PlaylistsConnection;
  /** Reads and enables pagination through a set of `Playlist`. */
  playlistsByLikeLikedUserIdAndPlaylistId: UserPlaylistsByLikeLikedUserIdAndPlaylistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Playlist`. */
  playlistsByLikeUserIdAndPlaylistId: UserPlaylistsByLikeUserIdAndPlaylistIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Playlist`. */
  playlistsByRecentlyPlayedUserIdAndPlaylistId: UserPlaylistsByRecentlyPlayedUserIdAndPlaylistIdManyToManyConnection;
  /** Reads a single `ProfilePublic` that is related to this `User`. */
  profilePublicByUserId?: Maybe<ProfilePublic>;
  /** Reads a single `ProfileSetting` that is related to this `User`. */
  profileSettingByUserId?: Maybe<ProfileSetting>;
  /** Reads and enables pagination through a set of `RecentlyPlayed`. */
  recentlyPlayedsByUserId: RecentlyPlayedsConnection;
  registration?: Maybe<UserRegistration>;
  registrationCompleted: Scalars['Boolean'];
  /** Reads and enables pagination through a set of `SearchHistory`. */
  searchHistoriesByUserId: SearchHistoriesConnection;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByAutoGeneratedQueueUserIdAndTrackId: UserTracksByAutoGeneratedQueueUserIdAndTrackIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByEarningsHistoryUserIdAndTrackId: UserTracksByEarningsHistoryUserIdAndTrackIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByLikeLikedUserIdAndTrackId: UserTracksByLikeLikedUserIdAndTrackIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByLikeUserIdAndTrackId: UserTracksByLikeUserIdAndTrackIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByPlayHistoryUserIdAndTrackId: UserTracksByPlayHistoryUserIdAndTrackIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByTracksEarningUserIdAndTrackId: UserTracksByTracksEarningUserIdAndTrackIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Track`. */
  tracksByUsersQueueUserIdAndTrackId: UserTracksByUsersQueueUserIdAndTrackIdManyToManyConnection;
  /** Reads and enables pagination through a set of `TracksEarning`. */
  tracksEarningsByUserId: TracksEarningsConnection;
  /** Defines the datetime with time zone `User` was last updated */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `UserVerification` that is related to this `User`. */
  userVerificationByUserId?: Maybe<UserVerification>;
  /** Reads a single `UsersAccountBalance` that is related to this `User`. */
  usersAccountBalanceByUserId?: Maybe<UsersAccountBalance>;
  /** Reads and enables pagination through a set of `UsersBalanceConsumption`. */
  usersBalanceConsumptionsByUserId: UsersBalanceConsumptionsConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByLikeLikedUserIdAndUserId: UserUsersByLikeLikedUserIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByLikeUserIdAndLikedUserId: UserUsersByLikeUserIdAndLikedUserIdManyToManyConnection;
  /** Reads a single `UsersNotification` that is related to this `User`. */
  usersNotificationByUserId?: Maybe<UsersNotification>;
  /** Reads and enables pagination through a set of `UsersQueue`. */
  usersQueuesByUserId: UsersQueuesConnection;
};

/** `User` table */
export type UserAlbumsByLikeLikedUserIdAndAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** `User` table */
export type UserAlbumsByLikeUserIdAndAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** `User` table */
export type UserAlbumsByRecentlyPlayedUserIdAndAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AlbumCondition>;
  filter?: InputMaybe<AlbumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy>>;
};

/** `User` table */
export type UserArtistMembersByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistMemberCondition>;
  filter?: InputMaybe<ArtistMemberFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistMembersOrderBy>>;
};

/** `User` table */
export type UserArtistSubscriptionTypesByArtistSubscriptionUserIdAndSubscriptionTypeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionTypeCondition>;
  filter?: InputMaybe<ArtistSubscriptionTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionTypesOrderBy>>;
};

/** `User` table */
export type UserArtistSubscriptionsByArtistSubscriptionUserIdAndDowngradeToArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** `User` table */
export type UserArtistSubscriptionsByArtistSubscriptionUserIdAndUpgradeToArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** `User` table */
export type UserArtistSubscriptionsByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** `User` table */
export type UserArtistsByArtistMemberUserIdAndArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `User` table */
export type UserArtistsByArtistSubscriptionUserIdAndArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `User` table */
export type UserArtistsByLikeLikedUserIdAndActingArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `User` table */
export type UserArtistsByLikeLikedUserIdAndArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `User` table */
export type UserArtistsByLikeUserIdAndActingArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `User` table */
export type UserArtistsByLikeUserIdAndArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `User` table */
export type UserArtistsByRecentlyPlayedUserIdAndArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `User` table */
export type UserArtistsByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** `User` table */
export type UserAutoGeneratedQueuesByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AutoGeneratedQueueCondition>;
  filter?: InputMaybe<AutoGeneratedQueueFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AutoGeneratedQueuesOrderBy>>;
};

/** `User` table */
export type UserEarningsHistoriesByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EarningsHistoryCondition>;
  filter?: InputMaybe<EarningsHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EarningsHistoriesOrderBy>>;
};

/** `User` table */
export type UserFirebaseTokensByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FirebaseTokenCondition>;
  filter?: InputMaybe<FirebaseTokenFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FirebaseTokensOrderBy>>;
};

/** `User` table */
export type UserGenresByArtistUserIdAndGenreArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenreCondition>;
  filter?: InputMaybe<GenreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenresOrderBy>>;
};

/** `User` table */
export type UserLikesByLikedUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** `User` table */
export type UserLikesByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** `User` table */
export type UserListenerPackageTypesByListenerPackageUserIdAndPackageTypeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ListenerPackageTypeCondition>;
  filter?: InputMaybe<ListenerPackageTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ListenerPackageTypesOrderBy>>;
};

/** `User` table */
export type UserListenerPackagesByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ListenerPackageCondition>;
  filter?: InputMaybe<ListenerPackageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ListenerPackagesOrderBy>>;
};

/** `User` table */
export type UserNotificationsByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NotificationCondition>;
  filter?: InputMaybe<NotificationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NotificationsOrderBy>>;
};

/** `User` table */
export type UserPlayHistoriesByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PlayHistoryCondition>;
  filter?: InputMaybe<PlayHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlayHistoriesOrderBy>>;
};

/** `User` table */
export type UserPlaylistsByAuthorArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PlaylistCondition>;
  filter?: InputMaybe<PlaylistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** `User` table */
export type UserPlaylistsByLikeLikedUserIdAndPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PlaylistCondition>;
  filter?: InputMaybe<PlaylistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** `User` table */
export type UserPlaylistsByLikeUserIdAndPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PlaylistCondition>;
  filter?: InputMaybe<PlaylistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** `User` table */
export type UserPlaylistsByRecentlyPlayedUserIdAndPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PlaylistCondition>;
  filter?: InputMaybe<PlaylistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** `User` table */
export type UserRecentlyPlayedsByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecentlyPlayedCondition>;
  filter?: InputMaybe<RecentlyPlayedFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecentlyPlayedsOrderBy>>;
};

/** `User` table */
export type UserSearchHistoriesByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SearchHistoryCondition>;
  filter?: InputMaybe<SearchHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SearchHistoriesOrderBy>>;
};

/** `User` table */
export type UserTracksByAutoGeneratedQueueUserIdAndTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `User` table */
export type UserTracksByEarningsHistoryUserIdAndTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `User` table */
export type UserTracksByLikeLikedUserIdAndTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `User` table */
export type UserTracksByLikeUserIdAndTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `User` table */
export type UserTracksByPlayHistoryUserIdAndTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `User` table */
export type UserTracksByTracksEarningUserIdAndTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `User` table */
export type UserTracksByUsersQueueUserIdAndTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrackCondition>;
  filter?: InputMaybe<TrackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy>>;
};

/** `User` table */
export type UserTracksEarningsByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TracksEarningCondition>;
  filter?: InputMaybe<TracksEarningFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksEarningsOrderBy>>;
};

/** `User` table */
export type UserUsersBalanceConsumptionsByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UsersBalanceConsumptionCondition>;
  filter?: InputMaybe<UsersBalanceConsumptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersBalanceConsumptionsOrderBy>>;
};

/** `User` table */
export type UserUsersByLikeLikedUserIdAndUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** `User` table */
export type UserUsersByLikeUserIdAndLikedUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** `User` table */
export type UserUsersQueuesByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UsersQueueCondition>;
  filter?: InputMaybe<UsersQueueFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersQueuesOrderBy>>;
};

/** A connection to a list of `Album` values, with data from `Like`. */
export type UserAlbumsByLikeLikedUserIdAndAlbumIdManyToManyConnection = {
  __typename?: 'UserAlbumsByLikeLikedUserIdAndAlbumIdManyToManyConnection';
  /** A list of edges which contains the `Album`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<UserAlbumsByLikeLikedUserIdAndAlbumIdManyToManyEdge>;
  /** A list of `Album` objects. */
  nodes: Array<Maybe<Album>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Album` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Album` edge in the connection, with data from `Like`. */
export type UserAlbumsByLikeLikedUserIdAndAlbumIdManyToManyEdge = {
  __typename?: 'UserAlbumsByLikeLikedUserIdAndAlbumIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByAlbumId: LikesConnection;
  /** The `Album` at the end of the edge. */
  node?: Maybe<Album>;
};

/** A `Album` edge in the connection, with data from `Like`. */
export type UserAlbumsByLikeLikedUserIdAndAlbumIdManyToManyEdgeLikesByAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Album` values, with data from `Like`. */
export type UserAlbumsByLikeUserIdAndAlbumIdManyToManyConnection = {
  __typename?: 'UserAlbumsByLikeUserIdAndAlbumIdManyToManyConnection';
  /** A list of edges which contains the `Album`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<UserAlbumsByLikeUserIdAndAlbumIdManyToManyEdge>;
  /** A list of `Album` objects. */
  nodes: Array<Maybe<Album>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Album` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Album` edge in the connection, with data from `Like`. */
export type UserAlbumsByLikeUserIdAndAlbumIdManyToManyEdge = {
  __typename?: 'UserAlbumsByLikeUserIdAndAlbumIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByAlbumId: LikesConnection;
  /** The `Album` at the end of the edge. */
  node?: Maybe<Album>;
};

/** A `Album` edge in the connection, with data from `Like`. */
export type UserAlbumsByLikeUserIdAndAlbumIdManyToManyEdgeLikesByAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Album` values, with data from `RecentlyPlayed`. */
export type UserAlbumsByRecentlyPlayedUserIdAndAlbumIdManyToManyConnection = {
  __typename?: 'UserAlbumsByRecentlyPlayedUserIdAndAlbumIdManyToManyConnection';
  /** A list of edges which contains the `Album`, info from the `RecentlyPlayed`, and the cursor to aid in pagination. */
  edges: Array<UserAlbumsByRecentlyPlayedUserIdAndAlbumIdManyToManyEdge>;
  /** A list of `Album` objects. */
  nodes: Array<Maybe<Album>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Album` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Album` edge in the connection, with data from `RecentlyPlayed`. */
export type UserAlbumsByRecentlyPlayedUserIdAndAlbumIdManyToManyEdge = {
  __typename?: 'UserAlbumsByRecentlyPlayedUserIdAndAlbumIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Album` at the end of the edge. */
  node?: Maybe<Album>;
  /** Reads and enables pagination through a set of `RecentlyPlayed`. */
  recentlyPlayedsByAlbumId: RecentlyPlayedsConnection;
};

/** A `Album` edge in the connection, with data from `RecentlyPlayed`. */
export type UserAlbumsByRecentlyPlayedUserIdAndAlbumIdManyToManyEdgeRecentlyPlayedsByAlbumIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecentlyPlayedCondition>;
  filter?: InputMaybe<RecentlyPlayedFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecentlyPlayedsOrderBy>>;
};

/** A connection to a list of `ArtistSubscriptionType` values, with data from `ArtistSubscription`. */
export type UserArtistSubscriptionTypesByArtistSubscriptionUserIdAndSubscriptionTypeManyToManyConnection = {
  __typename?: 'UserArtistSubscriptionTypesByArtistSubscriptionUserIdAndSubscriptionTypeManyToManyConnection';
  /** A list of edges which contains the `ArtistSubscriptionType`, info from the `ArtistSubscription`, and the cursor to aid in pagination. */
  edges: Array<UserArtistSubscriptionTypesByArtistSubscriptionUserIdAndSubscriptionTypeManyToManyEdge>;
  /** A list of `ArtistSubscriptionType` objects. */
  nodes: Array<Maybe<ArtistSubscriptionType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ArtistSubscriptionType` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ArtistSubscriptionType` edge in the connection, with data from `ArtistSubscription`. */
export type UserArtistSubscriptionTypesByArtistSubscriptionUserIdAndSubscriptionTypeManyToManyEdge = {
  __typename?: 'UserArtistSubscriptionTypesByArtistSubscriptionUserIdAndSubscriptionTypeManyToManyEdge';
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsBySubscriptionType: ArtistSubscriptionsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ArtistSubscriptionType` at the end of the edge. */
  node?: Maybe<ArtistSubscriptionType>;
};

/** A `ArtistSubscriptionType` edge in the connection, with data from `ArtistSubscription`. */
export type UserArtistSubscriptionTypesByArtistSubscriptionUserIdAndSubscriptionTypeManyToManyEdgeArtistSubscriptionsBySubscriptionTypeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** A connection to a list of `ArtistSubscription` values, with data from `ArtistSubscription`. */
export type UserArtistSubscriptionsByArtistSubscriptionUserIdAndDowngradeToManyToManyConnection = {
  __typename?: 'UserArtistSubscriptionsByArtistSubscriptionUserIdAndDowngradeToManyToManyConnection';
  /** A list of edges which contains the `ArtistSubscription`, info from the `ArtistSubscription`, and the cursor to aid in pagination. */
  edges: Array<UserArtistSubscriptionsByArtistSubscriptionUserIdAndDowngradeToManyToManyEdge>;
  /** A list of `ArtistSubscription` objects. */
  nodes: Array<Maybe<ArtistSubscription>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ArtistSubscription` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ArtistSubscription` edge in the connection, with data from `ArtistSubscription`. */
export type UserArtistSubscriptionsByArtistSubscriptionUserIdAndDowngradeToManyToManyEdge = {
  __typename?: 'UserArtistSubscriptionsByArtistSubscriptionUserIdAndDowngradeToManyToManyEdge';
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByDowngradeTo: ArtistSubscriptionsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ArtistSubscription` at the end of the edge. */
  node?: Maybe<ArtistSubscription>;
};

/** A `ArtistSubscription` edge in the connection, with data from `ArtistSubscription`. */
export type UserArtistSubscriptionsByArtistSubscriptionUserIdAndDowngradeToManyToManyEdgeArtistSubscriptionsByDowngradeToArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** A connection to a list of `ArtistSubscription` values, with data from `ArtistSubscription`. */
export type UserArtistSubscriptionsByArtistSubscriptionUserIdAndUpgradeToManyToManyConnection = {
  __typename?: 'UserArtistSubscriptionsByArtistSubscriptionUserIdAndUpgradeToManyToManyConnection';
  /** A list of edges which contains the `ArtistSubscription`, info from the `ArtistSubscription`, and the cursor to aid in pagination. */
  edges: Array<UserArtistSubscriptionsByArtistSubscriptionUserIdAndUpgradeToManyToManyEdge>;
  /** A list of `ArtistSubscription` objects. */
  nodes: Array<Maybe<ArtistSubscription>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ArtistSubscription` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ArtistSubscription` edge in the connection, with data from `ArtistSubscription`. */
export type UserArtistSubscriptionsByArtistSubscriptionUserIdAndUpgradeToManyToManyEdge = {
  __typename?: 'UserArtistSubscriptionsByArtistSubscriptionUserIdAndUpgradeToManyToManyEdge';
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByUpgradeTo: ArtistSubscriptionsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ArtistSubscription` at the end of the edge. */
  node?: Maybe<ArtistSubscription>;
};

/** A `ArtistSubscription` edge in the connection, with data from `ArtistSubscription`. */
export type UserArtistSubscriptionsByArtistSubscriptionUserIdAndUpgradeToManyToManyEdgeArtistSubscriptionsByUpgradeToArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `ArtistMember`. */
export type UserArtistsByArtistMemberUserIdAndArtistIdManyToManyConnection = {
  __typename?: 'UserArtistsByArtistMemberUserIdAndArtistIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `ArtistMember`, and the cursor to aid in pagination. */
  edges: Array<UserArtistsByArtistMemberUserIdAndArtistIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `ArtistMember`. */
export type UserArtistsByArtistMemberUserIdAndArtistIdManyToManyEdge = {
  __typename?: 'UserArtistsByArtistMemberUserIdAndArtistIdManyToManyEdge';
  /** Defines if the `User` has accepted to join `Artist` membership */
  confirmed?: Maybe<Scalars['Boolean']>;
  /** Defines the datetime with time zone when `ArtistMember` was first created */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  id: Scalars['UUID'];
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** A connection to a list of `Artist` values, with data from `ArtistSubscription`. */
export type UserArtistsByArtistSubscriptionUserIdAndArtistIdManyToManyConnection = {
  __typename?: 'UserArtistsByArtistSubscriptionUserIdAndArtistIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `ArtistSubscription`, and the cursor to aid in pagination. */
  edges: Array<UserArtistsByArtistSubscriptionUserIdAndArtistIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `ArtistSubscription`. */
export type UserArtistsByArtistSubscriptionUserIdAndArtistIdManyToManyEdge = {
  __typename?: 'UserArtistsByArtistSubscriptionUserIdAndArtistIdManyToManyEdge';
  /** Reads and enables pagination through a set of `ArtistSubscription`. */
  artistSubscriptionsByArtistId: ArtistSubscriptionsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** A `Artist` edge in the connection, with data from `ArtistSubscription`. */
export type UserArtistsByArtistSubscriptionUserIdAndArtistIdManyToManyEdgeArtistSubscriptionsByArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistSubscriptionCondition>;
  filter?: InputMaybe<ArtistSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistSubscriptionsOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `Like`. */
export type UserArtistsByLikeLikedUserIdAndActingArtistIdManyToManyConnection = {
  __typename?: 'UserArtistsByLikeLikedUserIdAndActingArtistIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<UserArtistsByLikeLikedUserIdAndActingArtistIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type UserArtistsByLikeLikedUserIdAndActingArtistIdManyToManyEdge = {
  __typename?: 'UserArtistsByLikeLikedUserIdAndActingArtistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByActingArtistId: LikesConnection;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type UserArtistsByLikeLikedUserIdAndActingArtistIdManyToManyEdgeLikesByActingArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `Like`. */
export type UserArtistsByLikeLikedUserIdAndArtistIdManyToManyConnection = {
  __typename?: 'UserArtistsByLikeLikedUserIdAndArtistIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<UserArtistsByLikeLikedUserIdAndArtistIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type UserArtistsByLikeLikedUserIdAndArtistIdManyToManyEdge = {
  __typename?: 'UserArtistsByLikeLikedUserIdAndArtistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByArtistId: LikesConnection;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type UserArtistsByLikeLikedUserIdAndArtistIdManyToManyEdgeLikesByArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `Like`. */
export type UserArtistsByLikeUserIdAndActingArtistIdManyToManyConnection = {
  __typename?: 'UserArtistsByLikeUserIdAndActingArtistIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<UserArtistsByLikeUserIdAndActingArtistIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type UserArtistsByLikeUserIdAndActingArtistIdManyToManyEdge = {
  __typename?: 'UserArtistsByLikeUserIdAndActingArtistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByActingArtistId: LikesConnection;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type UserArtistsByLikeUserIdAndActingArtistIdManyToManyEdgeLikesByActingArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `Like`. */
export type UserArtistsByLikeUserIdAndArtistIdManyToManyConnection = {
  __typename?: 'UserArtistsByLikeUserIdAndArtistIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<UserArtistsByLikeUserIdAndArtistIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type UserArtistsByLikeUserIdAndArtistIdManyToManyEdge = {
  __typename?: 'UserArtistsByLikeUserIdAndArtistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByArtistId: LikesConnection;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
};

/** A `Artist` edge in the connection, with data from `Like`. */
export type UserArtistsByLikeUserIdAndArtistIdManyToManyEdgeLikesByArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Artist` values, with data from `RecentlyPlayed`. */
export type UserArtistsByRecentlyPlayedUserIdAndArtistIdManyToManyConnection = {
  __typename?: 'UserArtistsByRecentlyPlayedUserIdAndArtistIdManyToManyConnection';
  /** A list of edges which contains the `Artist`, info from the `RecentlyPlayed`, and the cursor to aid in pagination. */
  edges: Array<UserArtistsByRecentlyPlayedUserIdAndArtistIdManyToManyEdge>;
  /** A list of `Artist` objects. */
  nodes: Array<Maybe<Artist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Artist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Artist` edge in the connection, with data from `RecentlyPlayed`. */
export type UserArtistsByRecentlyPlayedUserIdAndArtistIdManyToManyEdge = {
  __typename?: 'UserArtistsByRecentlyPlayedUserIdAndArtistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Artist` at the end of the edge. */
  node?: Maybe<Artist>;
  /** Reads and enables pagination through a set of `RecentlyPlayed`. */
  recentlyPlayedsByArtistId: RecentlyPlayedsConnection;
};

/** A `Artist` edge in the connection, with data from `RecentlyPlayed`. */
export type UserArtistsByRecentlyPlayedUserIdAndArtistIdManyToManyEdgeRecentlyPlayedsByArtistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecentlyPlayedCondition>;
  filter?: InputMaybe<RecentlyPlayedFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecentlyPlayedsOrderBy>>;
};

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `Genre` values, with data from `Artist`. */
export type UserGenresByArtistUserIdAndGenreManyToManyConnection = {
  __typename?: 'UserGenresByArtistUserIdAndGenreManyToManyConnection';
  /** A list of edges which contains the `Genre`, info from the `Artist`, and the cursor to aid in pagination. */
  edges: Array<UserGenresByArtistUserIdAndGenreManyToManyEdge>;
  /** A list of `Genre` objects. */
  nodes: Array<Maybe<Genre>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Genre` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Genre` edge in the connection, with data from `Artist`. */
export type UserGenresByArtistUserIdAndGenreManyToManyEdge = {
  __typename?: 'UserGenresByArtistUserIdAndGenreManyToManyEdge';
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByGenre: ArtistsConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Genre` at the end of the edge. */
  node?: Maybe<Genre>;
};

/** A `Genre` edge in the connection, with data from `Artist`. */
export type UserGenresByArtistUserIdAndGenreManyToManyEdgeArtistsByGenreArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArtistCondition>;
  filter?: InputMaybe<ArtistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** A connection to a list of `ListenerPackageType` values, with data from `ListenerPackage`. */
export type UserListenerPackageTypesByListenerPackageUserIdAndPackageTypeManyToManyConnection = {
  __typename?: 'UserListenerPackageTypesByListenerPackageUserIdAndPackageTypeManyToManyConnection';
  /** A list of edges which contains the `ListenerPackageType`, info from the `ListenerPackage`, and the cursor to aid in pagination. */
  edges: Array<UserListenerPackageTypesByListenerPackageUserIdAndPackageTypeManyToManyEdge>;
  /** A list of `ListenerPackageType` objects. */
  nodes: Array<Maybe<ListenerPackageType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ListenerPackageType` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ListenerPackageType` edge in the connection, with data from `ListenerPackage`. */
export type UserListenerPackageTypesByListenerPackageUserIdAndPackageTypeManyToManyEdge = {
  __typename?: 'UserListenerPackageTypesByListenerPackageUserIdAndPackageTypeManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `ListenerPackage`. */
  listenerPackagesByPackageType: ListenerPackagesConnection;
  /** The `ListenerPackageType` at the end of the edge. */
  node?: Maybe<ListenerPackageType>;
};

/** A `ListenerPackageType` edge in the connection, with data from `ListenerPackage`. */
export type UserListenerPackageTypesByListenerPackageUserIdAndPackageTypeManyToManyEdgeListenerPackagesByPackageTypeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ListenerPackageCondition>;
  filter?: InputMaybe<ListenerPackageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ListenerPackagesOrderBy>>;
};

/** An input for mutations affecting `UserPercentage` */
export type UserPercentageInput = {
  artistMemberId?: InputMaybe<Scalars['UUID']>;
  percentage?: InputMaybe<Scalars['BigFloat']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A connection to a list of `Playlist` values, with data from `Like`. */
export type UserPlaylistsByLikeLikedUserIdAndPlaylistIdManyToManyConnection = {
  __typename?: 'UserPlaylistsByLikeLikedUserIdAndPlaylistIdManyToManyConnection';
  /** A list of edges which contains the `Playlist`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<UserPlaylistsByLikeLikedUserIdAndPlaylistIdManyToManyEdge>;
  /** A list of `Playlist` objects. */
  nodes: Array<Maybe<Playlist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Playlist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Playlist` edge in the connection, with data from `Like`. */
export type UserPlaylistsByLikeLikedUserIdAndPlaylistIdManyToManyEdge = {
  __typename?: 'UserPlaylistsByLikeLikedUserIdAndPlaylistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByPlaylistId: LikesConnection;
  /** The `Playlist` at the end of the edge. */
  node?: Maybe<Playlist>;
};

/** A `Playlist` edge in the connection, with data from `Like`. */
export type UserPlaylistsByLikeLikedUserIdAndPlaylistIdManyToManyEdgeLikesByPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Playlist` values, with data from `Like`. */
export type UserPlaylistsByLikeUserIdAndPlaylistIdManyToManyConnection = {
  __typename?: 'UserPlaylistsByLikeUserIdAndPlaylistIdManyToManyConnection';
  /** A list of edges which contains the `Playlist`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<UserPlaylistsByLikeUserIdAndPlaylistIdManyToManyEdge>;
  /** A list of `Playlist` objects. */
  nodes: Array<Maybe<Playlist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Playlist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Playlist` edge in the connection, with data from `Like`. */
export type UserPlaylistsByLikeUserIdAndPlaylistIdManyToManyEdge = {
  __typename?: 'UserPlaylistsByLikeUserIdAndPlaylistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByPlaylistId: LikesConnection;
  /** The `Playlist` at the end of the edge. */
  node?: Maybe<Playlist>;
};

/** A `Playlist` edge in the connection, with data from `Like`. */
export type UserPlaylistsByLikeUserIdAndPlaylistIdManyToManyEdgeLikesByPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Playlist` values, with data from `RecentlyPlayed`. */
export type UserPlaylistsByRecentlyPlayedUserIdAndPlaylistIdManyToManyConnection = {
  __typename?: 'UserPlaylistsByRecentlyPlayedUserIdAndPlaylistIdManyToManyConnection';
  /** A list of edges which contains the `Playlist`, info from the `RecentlyPlayed`, and the cursor to aid in pagination. */
  edges: Array<UserPlaylistsByRecentlyPlayedUserIdAndPlaylistIdManyToManyEdge>;
  /** A list of `Playlist` objects. */
  nodes: Array<Maybe<Playlist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Playlist` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Playlist` edge in the connection, with data from `RecentlyPlayed`. */
export type UserPlaylistsByRecentlyPlayedUserIdAndPlaylistIdManyToManyEdge = {
  __typename?: 'UserPlaylistsByRecentlyPlayedUserIdAndPlaylistIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Playlist` at the end of the edge. */
  node?: Maybe<Playlist>;
  /** Reads and enables pagination through a set of `RecentlyPlayed`. */
  recentlyPlayedsByPlaylistId: RecentlyPlayedsConnection;
};

/** A `Playlist` edge in the connection, with data from `RecentlyPlayed`. */
export type UserPlaylistsByRecentlyPlayedUserIdAndPlaylistIdManyToManyEdgeRecentlyPlayedsByPlaylistIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecentlyPlayedCondition>;
  filter?: InputMaybe<RecentlyPlayedFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecentlyPlayedsOrderBy>>;
};

export type UserRegistration = {
  __typename?: 'UserRegistration';
  details: Array<Maybe<UserRegistrationRequirementsEnum>>;
  requirements: UserRegistrationRequirements;
};

export type UserRegistrationRequirements = {
  __typename?: 'UserRegistrationRequirements';
  genre: Scalars['Boolean'];
  information: Scalars['Boolean'];
  kyc: Scalars['Boolean'];
  payment: Scalars['Boolean'];
};

export enum UserRegistrationRequirementsEnum {
  Kyc = 'KYC',
  MusicGenre = 'MUSIC_GENRE',
  PaymentInformation = 'PAYMENT_INFORMATION',
  UserInformation = 'USER_INFORMATION',
}

/** A connection to a list of `Track` values, with data from `AutoGeneratedQueue`. */
export type UserTracksByAutoGeneratedQueueUserIdAndTrackIdManyToManyConnection = {
  __typename?: 'UserTracksByAutoGeneratedQueueUserIdAndTrackIdManyToManyConnection';
  /** A list of edges which contains the `Track`, info from the `AutoGeneratedQueue`, and the cursor to aid in pagination. */
  edges: Array<UserTracksByAutoGeneratedQueueUserIdAndTrackIdManyToManyEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Track` edge in the connection, with data from `AutoGeneratedQueue`. */
export type UserTracksByAutoGeneratedQueueUserIdAndTrackIdManyToManyEdge = {
  __typename?: 'UserTracksByAutoGeneratedQueueUserIdAndTrackIdManyToManyEdge';
  /** Reads and enables pagination through a set of `AutoGeneratedQueue`. */
  autoGeneratedQueuesByTrackId: AutoGeneratedQueuesConnection;
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
};

/** A `Track` edge in the connection, with data from `AutoGeneratedQueue`. */
export type UserTracksByAutoGeneratedQueueUserIdAndTrackIdManyToManyEdgeAutoGeneratedQueuesByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AutoGeneratedQueueCondition>;
  filter?: InputMaybe<AutoGeneratedQueueFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AutoGeneratedQueuesOrderBy>>;
};

/** A connection to a list of `Track` values, with data from `EarningsHistory`. */
export type UserTracksByEarningsHistoryUserIdAndTrackIdManyToManyConnection = {
  __typename?: 'UserTracksByEarningsHistoryUserIdAndTrackIdManyToManyConnection';
  /** A list of edges which contains the `Track`, info from the `EarningsHistory`, and the cursor to aid in pagination. */
  edges: Array<UserTracksByEarningsHistoryUserIdAndTrackIdManyToManyEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Track` edge in the connection, with data from `EarningsHistory`. */
export type UserTracksByEarningsHistoryUserIdAndTrackIdManyToManyEdge = {
  __typename?: 'UserTracksByEarningsHistoryUserIdAndTrackIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `EarningsHistory`. */
  earningsHistoriesByTrackId: EarningsHistoriesConnection;
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
};

/** A `Track` edge in the connection, with data from `EarningsHistory`. */
export type UserTracksByEarningsHistoryUserIdAndTrackIdManyToManyEdgeEarningsHistoriesByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EarningsHistoryCondition>;
  filter?: InputMaybe<EarningsHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EarningsHistoriesOrderBy>>;
};

/** A connection to a list of `Track` values, with data from `Like`. */
export type UserTracksByLikeLikedUserIdAndTrackIdManyToManyConnection = {
  __typename?: 'UserTracksByLikeLikedUserIdAndTrackIdManyToManyConnection';
  /** A list of edges which contains the `Track`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<UserTracksByLikeLikedUserIdAndTrackIdManyToManyEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Track` edge in the connection, with data from `Like`. */
export type UserTracksByLikeLikedUserIdAndTrackIdManyToManyEdge = {
  __typename?: 'UserTracksByLikeLikedUserIdAndTrackIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByTrackId: LikesConnection;
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
};

/** A `Track` edge in the connection, with data from `Like`. */
export type UserTracksByLikeLikedUserIdAndTrackIdManyToManyEdgeLikesByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Track` values, with data from `Like`. */
export type UserTracksByLikeUserIdAndTrackIdManyToManyConnection = {
  __typename?: 'UserTracksByLikeUserIdAndTrackIdManyToManyConnection';
  /** A list of edges which contains the `Track`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<UserTracksByLikeUserIdAndTrackIdManyToManyEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Track` edge in the connection, with data from `Like`. */
export type UserTracksByLikeUserIdAndTrackIdManyToManyEdge = {
  __typename?: 'UserTracksByLikeUserIdAndTrackIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByTrackId: LikesConnection;
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
};

/** A `Track` edge in the connection, with data from `Like`. */
export type UserTracksByLikeUserIdAndTrackIdManyToManyEdgeLikesByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `Track` values, with data from `PlayHistory`. */
export type UserTracksByPlayHistoryUserIdAndTrackIdManyToManyConnection = {
  __typename?: 'UserTracksByPlayHistoryUserIdAndTrackIdManyToManyConnection';
  /** A list of edges which contains the `Track`, info from the `PlayHistory`, and the cursor to aid in pagination. */
  edges: Array<UserTracksByPlayHistoryUserIdAndTrackIdManyToManyEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Track` edge in the connection, with data from `PlayHistory`. */
export type UserTracksByPlayHistoryUserIdAndTrackIdManyToManyEdge = {
  __typename?: 'UserTracksByPlayHistoryUserIdAndTrackIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
  /** Reads and enables pagination through a set of `PlayHistory`. */
  playHistoriesByTrackId: PlayHistoriesConnection;
};

/** A `Track` edge in the connection, with data from `PlayHistory`. */
export type UserTracksByPlayHistoryUserIdAndTrackIdManyToManyEdgePlayHistoriesByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PlayHistoryCondition>;
  filter?: InputMaybe<PlayHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlayHistoriesOrderBy>>;
};

/** A connection to a list of `Track` values, with data from `TracksEarning`. */
export type UserTracksByTracksEarningUserIdAndTrackIdManyToManyConnection = {
  __typename?: 'UserTracksByTracksEarningUserIdAndTrackIdManyToManyConnection';
  /** A list of edges which contains the `Track`, info from the `TracksEarning`, and the cursor to aid in pagination. */
  edges: Array<UserTracksByTracksEarningUserIdAndTrackIdManyToManyEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Track` edge in the connection, with data from `TracksEarning`. */
export type UserTracksByTracksEarningUserIdAndTrackIdManyToManyEdge = {
  __typename?: 'UserTracksByTracksEarningUserIdAndTrackIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
  /** Reads and enables pagination through a set of `TracksEarning`. */
  tracksEarningsByTrackId: TracksEarningsConnection;
};

/** A `Track` edge in the connection, with data from `TracksEarning`. */
export type UserTracksByTracksEarningUserIdAndTrackIdManyToManyEdgeTracksEarningsByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TracksEarningCondition>;
  filter?: InputMaybe<TracksEarningFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksEarningsOrderBy>>;
};

/** A connection to a list of `Track` values, with data from `UsersQueue`. */
export type UserTracksByUsersQueueUserIdAndTrackIdManyToManyConnection = {
  __typename?: 'UserTracksByUsersQueueUserIdAndTrackIdManyToManyConnection';
  /** A list of edges which contains the `Track`, info from the `UsersQueue`, and the cursor to aid in pagination. */
  edges: Array<UserTracksByUsersQueueUserIdAndTrackIdManyToManyEdge>;
  /** A list of `Track` objects. */
  nodes: Array<Maybe<Track>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Track` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Track` edge in the connection, with data from `UsersQueue`. */
export type UserTracksByUsersQueueUserIdAndTrackIdManyToManyEdge = {
  __typename?: 'UserTracksByUsersQueueUserIdAndTrackIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Track` at the end of the edge. */
  node?: Maybe<Track>;
  /** Reads and enables pagination through a set of `UsersQueue`. */
  usersQueuesByTrackId: UsersQueuesConnection;
};

/** A `Track` edge in the connection, with data from `UsersQueue`. */
export type UserTracksByUsersQueueUserIdAndTrackIdManyToManyEdgeUsersQueuesByTrackIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UsersQueueCondition>;
  filter?: InputMaybe<UsersQueueFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersQueuesOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Like`. */
export type UserUsersByLikeLikedUserIdAndUserIdManyToManyConnection = {
  __typename?: 'UserUsersByLikeLikedUserIdAndUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByLikeLikedUserIdAndUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Like`. */
export type UserUsersByLikeLikedUserIdAndUserIdManyToManyEdge = {
  __typename?: 'UserUsersByLikeLikedUserIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByUserId: LikesConnection;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** A `User` edge in the connection, with data from `Like`. */
export type UserUsersByLikeLikedUserIdAndUserIdManyToManyEdgeLikesByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Like`. */
export type UserUsersByLikeUserIdAndLikedUserIdManyToManyConnection = {
  __typename?: 'UserUsersByLikeUserIdAndLikedUserIdManyToManyConnection';
  /** A list of edges which contains the `User`, info from the `Like`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByLikeUserIdAndLikedUserIdManyToManyEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Like`. */
export type UserUsersByLikeUserIdAndLikedUserIdManyToManyEdge = {
  __typename?: 'UserUsersByLikeUserIdAndLikedUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Like`. */
  likesByLikedUserId: LikesConnection;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** A `User` edge in the connection, with data from `Like`. */
export type UserUsersByLikeUserIdAndLikedUserIdManyToManyEdgeLikesByLikedUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** `UserVerification` table */
export type UserVerification = Node & {
  __typename?: 'UserVerification';
  /** Onfido applicant id */
  applicantId?: Maybe<Scalars['String']>;
  /** Onfido check id */
  checkId?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The status of the `UserVerification` for `User` */
  status?: Maybe<VerificationStatus>;
  /** Reads a single `User` that is related to this `UserVerification`. */
  userByUserId?: Maybe<User>;
  /** The id of the `User` associated with this account */
  userId: Scalars['UUID'];
  /** Onfido webhook id */
  webhookId?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `UserVerification` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserVerificationCondition = {
  /** Checks for equality with the object’s `applicantId` field. */
  applicantId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `checkId` field. */
  checkId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<VerificationStatus>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `webhookId` field. */
  webhookId?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `UserVerification` object types. All fields are combined with a logical ‘and.’ */
export type UserVerificationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserVerificationFilter>>;
  /** Filter by the object’s `applicantId` field. */
  applicantId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `checkId` field. */
  checkId?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserVerificationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserVerificationFilter>>;
  /** Filter by the object’s `webhookId` field. */
  webhookId?: InputMaybe<StringFilter>;
};

/** A connection to a list of `UserVerification` values. */
export type UserVerificationsConnection = {
  __typename?: 'UserVerificationsConnection';
  /** A list of edges which contains the `UserVerification` and cursor to aid in pagination. */
  edges: Array<UserVerificationsEdge>;
  /** A list of `UserVerification` objects. */
  nodes: Array<Maybe<UserVerification>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserVerification` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UserVerification` edge in the connection. */
export type UserVerificationsEdge = {
  __typename?: 'UserVerificationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserVerification` at the end of the edge. */
  node?: Maybe<UserVerification>;
};

/** Methods to use when ordering `UserVerification`. */
export enum UserVerificationsOrderBy {
  ApplicantIdAsc = 'APPLICANT_ID_ASC',
  ApplicantIdDesc = 'APPLICANT_ID_DESC',
  CheckIdAsc = 'CHECK_ID_ASC',
  CheckIdDesc = 'CHECK_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  WebhookIdAsc = 'WEBHOOK_ID_ASC',
  WebhookIdDesc = 'WEBHOOK_ID_DESC',
}

/** Users account balance information */
export type UsersAccountBalance = Node & {
  __typename?: 'UsersAccountBalance';
  /** Average consumption over the duration of one month */
  averageMonthConsumption?: Maybe<Scalars['BigFloat']>;
  /** Current balance on the account */
  balance?: Maybe<Scalars['BigFloat']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `User` that is related to this `UsersAccountBalance`. */
  userByUserId?: Maybe<User>;
  /** The id of the user associated with this account */
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `UsersAccountBalance` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type UsersAccountBalanceCondition = {
  /** Checks for equality with the object’s `averageMonthConsumption` field. */
  averageMonthConsumption?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `balance` field. */
  balance?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A connection to a list of `UsersAccountBalance` values. */
export type UsersAccountBalancesConnection = {
  __typename?: 'UsersAccountBalancesConnection';
  /** A list of edges which contains the `UsersAccountBalance` and cursor to aid in pagination. */
  edges: Array<UsersAccountBalancesEdge>;
  /** A list of `UsersAccountBalance` objects. */
  nodes: Array<Maybe<UsersAccountBalance>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UsersAccountBalance` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UsersAccountBalance` edge in the connection. */
export type UsersAccountBalancesEdge = {
  __typename?: 'UsersAccountBalancesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UsersAccountBalance` at the end of the edge. */
  node?: Maybe<UsersAccountBalance>;
};

/** Methods to use when ordering `UsersAccountBalance`. */
export enum UsersAccountBalancesOrderBy {
  AverageMonthConsumptionAsc = 'AVERAGE_MONTH_CONSUMPTION_ASC',
  AverageMonthConsumptionDesc = 'AVERAGE_MONTH_CONSUMPTION_DESC',
  BalanceAsc = 'BALANCE_ASC',
  BalanceDesc = 'BALANCE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
}

/** Users balance consumption information */
export type UsersBalanceConsumption = Node & {
  __typename?: 'UsersBalanceConsumption';
  /** Represents the amount of the consumption */
  consumptionAmount: Scalars['Float'];
  /** Date of the consumption */
  consumptionDate?: Maybe<Scalars['Datetime']>;
  /** The primary unique identifier for the users balance consumption */
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `User` that is related to this `UsersBalanceConsumption`. */
  userByUserId?: Maybe<User>;
  /** The id of the user associated with this account */
  userId?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `UsersBalanceConsumption` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type UsersBalanceConsumptionCondition = {
  /** Checks for equality with the object’s `consumptionAmount` field. */
  consumptionAmount?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `consumptionDate` field. */
  consumptionDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `UsersBalanceConsumption` object types. All fields are combined with a logical ‘and.’ */
export type UsersBalanceConsumptionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UsersBalanceConsumptionFilter>>;
  /** Filter by the object’s `consumptionDate` field. */
  consumptionDate?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UsersBalanceConsumptionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UsersBalanceConsumptionFilter>>;
};

/** A connection to a list of `UsersBalanceConsumption` values. */
export type UsersBalanceConsumptionsConnection = {
  __typename?: 'UsersBalanceConsumptionsConnection';
  /** A list of edges which contains the `UsersBalanceConsumption` and cursor to aid in pagination. */
  edges: Array<UsersBalanceConsumptionsEdge>;
  /** A list of `UsersBalanceConsumption` objects. */
  nodes: Array<Maybe<UsersBalanceConsumption>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UsersBalanceConsumption` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UsersBalanceConsumption` edge in the connection. */
export type UsersBalanceConsumptionsEdge = {
  __typename?: 'UsersBalanceConsumptionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UsersBalanceConsumption` at the end of the edge. */
  node?: Maybe<UsersBalanceConsumption>;
};

/** Methods to use when ordering `UsersBalanceConsumption`. */
export enum UsersBalanceConsumptionsOrderBy {
  ConsumptionAmountAsc = 'CONSUMPTION_AMOUNT_ASC',
  ConsumptionAmountDesc = 'CONSUMPTION_AMOUNT_DESC',
  ConsumptionDateAsc = 'CONSUMPTION_DATE_ASC',
  ConsumptionDateDesc = 'CONSUMPTION_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
}

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** `UsersNotification` table - `User` notification settings */
export type UsersNotification = Node & {
  __typename?: 'UsersNotification';
  /** `UsersNotification` for artist updates via email */
  emailArtistUpdate?: Maybe<Scalars['Boolean']>;
  /** `UsersNotification` for blare news and offers via email */
  emailBlareNewsAndOffers?: Maybe<Scalars['Boolean']>;
  /** `UsersNotification` for concert reminder via email */
  emailConcertReminder?: Maybe<Scalars['Boolean']>;
  /** `UsersNotification` for blare new follower via email */
  emailNewFollower?: Maybe<Scalars['Boolean']>;
  /** `UsersNotification` for new music via email */
  emailNewMusic?: Maybe<Scalars['Boolean']>;
  /** `UsersNotification` for playlist updates via email */
  emailPlaylistUpdates?: Maybe<Scalars['Boolean']>;
  /** `UsersNotification` for recommended music via email */
  emailRecommendedMusic?: Maybe<Scalars['Boolean']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `User` that is related to this `UsersNotification`. */
  userByUserId?: Maybe<User>;
  /** The id of the `User` associated with this account */
  userId: Scalars['UUID'];
  /** `UsersNotification` for artist updates via web */
  webArtistUpdate?: Maybe<Scalars['Boolean']>;
  /** `UsersNotification` for blare news and offers via web */
  webBlareNewsAndOffers?: Maybe<Scalars['Boolean']>;
  /** `UsersNotification` for concert reminder via web */
  webConcertReminder?: Maybe<Scalars['Boolean']>;
  /** `UsersNotification` for blare new follower via email */
  webNewFollower?: Maybe<Scalars['Boolean']>;
  /** `UsersNotification` for new music via web */
  webNewMusic?: Maybe<Scalars['Boolean']>;
  /** `UsersNotification` for playlist updates via web */
  webPlaylistUpdates?: Maybe<Scalars['Boolean']>;
  /** `UsersNotification` for recommended music via web */
  webRecommendedMusic?: Maybe<Scalars['Boolean']>;
};

/**
 * A condition to be used against `UsersNotification` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UsersNotificationCondition = {
  /** Checks for equality with the object’s `emailArtistUpdate` field. */
  emailArtistUpdate?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `emailBlareNewsAndOffers` field. */
  emailBlareNewsAndOffers?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `emailConcertReminder` field. */
  emailConcertReminder?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `emailNewFollower` field. */
  emailNewFollower?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `emailNewMusic` field. */
  emailNewMusic?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `emailPlaylistUpdates` field. */
  emailPlaylistUpdates?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `emailRecommendedMusic` field. */
  emailRecommendedMusic?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `webArtistUpdate` field. */
  webArtistUpdate?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `webBlareNewsAndOffers` field. */
  webBlareNewsAndOffers?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `webConcertReminder` field. */
  webConcertReminder?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `webNewFollower` field. */
  webNewFollower?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `webNewMusic` field. */
  webNewMusic?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `webPlaylistUpdates` field. */
  webPlaylistUpdates?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `webRecommendedMusic` field. */
  webRecommendedMusic?: InputMaybe<Scalars['Boolean']>;
};

/** Represents an update to a `UsersNotification`. Fields that are set will be updated. */
export type UsersNotificationPatch = {
  /** `UsersNotification` for artist updates via email */
  emailArtistUpdate?: InputMaybe<Scalars['Boolean']>;
  /** `UsersNotification` for blare news and offers via email */
  emailBlareNewsAndOffers?: InputMaybe<Scalars['Boolean']>;
  /** `UsersNotification` for concert reminder via email */
  emailConcertReminder?: InputMaybe<Scalars['Boolean']>;
  /** `UsersNotification` for blare new follower via email */
  emailNewFollower?: InputMaybe<Scalars['Boolean']>;
  /** `UsersNotification` for new music via email */
  emailNewMusic?: InputMaybe<Scalars['Boolean']>;
  /** `UsersNotification` for playlist updates via email */
  emailPlaylistUpdates?: InputMaybe<Scalars['Boolean']>;
  /** `UsersNotification` for recommended music via email */
  emailRecommendedMusic?: InputMaybe<Scalars['Boolean']>;
  /** The id of the `User` associated with this account */
  userId?: InputMaybe<Scalars['UUID']>;
  /** `UsersNotification` for artist updates via web */
  webArtistUpdate?: InputMaybe<Scalars['Boolean']>;
  /** `UsersNotification` for blare news and offers via web */
  webBlareNewsAndOffers?: InputMaybe<Scalars['Boolean']>;
  /** `UsersNotification` for concert reminder via web */
  webConcertReminder?: InputMaybe<Scalars['Boolean']>;
  /** `UsersNotification` for blare new follower via email */
  webNewFollower?: InputMaybe<Scalars['Boolean']>;
  /** `UsersNotification` for new music via web */
  webNewMusic?: InputMaybe<Scalars['Boolean']>;
  /** `UsersNotification` for playlist updates via web */
  webPlaylistUpdates?: InputMaybe<Scalars['Boolean']>;
  /** `UsersNotification` for recommended music via web */
  webRecommendedMusic?: InputMaybe<Scalars['Boolean']>;
};

/** A connection to a list of `UsersNotification` values. */
export type UsersNotificationsConnection = {
  __typename?: 'UsersNotificationsConnection';
  /** A list of edges which contains the `UsersNotification` and cursor to aid in pagination. */
  edges: Array<UsersNotificationsEdge>;
  /** A list of `UsersNotification` objects. */
  nodes: Array<Maybe<UsersNotification>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UsersNotification` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UsersNotification` edge in the connection. */
export type UsersNotificationsEdge = {
  __typename?: 'UsersNotificationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UsersNotification` at the end of the edge. */
  node?: Maybe<UsersNotification>;
};

/** Methods to use when ordering `UsersNotification`. */
export enum UsersNotificationsOrderBy {
  EmailArtistUpdateAsc = 'EMAIL_ARTIST_UPDATE_ASC',
  EmailArtistUpdateDesc = 'EMAIL_ARTIST_UPDATE_DESC',
  EmailBlareNewsAndOffersAsc = 'EMAIL_BLARE_NEWS_AND_OFFERS_ASC',
  EmailBlareNewsAndOffersDesc = 'EMAIL_BLARE_NEWS_AND_OFFERS_DESC',
  EmailConcertReminderAsc = 'EMAIL_CONCERT_REMINDER_ASC',
  EmailConcertReminderDesc = 'EMAIL_CONCERT_REMINDER_DESC',
  EmailNewFollowerAsc = 'EMAIL_NEW_FOLLOWER_ASC',
  EmailNewFollowerDesc = 'EMAIL_NEW_FOLLOWER_DESC',
  EmailNewMusicAsc = 'EMAIL_NEW_MUSIC_ASC',
  EmailNewMusicDesc = 'EMAIL_NEW_MUSIC_DESC',
  EmailPlaylistUpdatesAsc = 'EMAIL_PLAYLIST_UPDATES_ASC',
  EmailPlaylistUpdatesDesc = 'EMAIL_PLAYLIST_UPDATES_DESC',
  EmailRecommendedMusicAsc = 'EMAIL_RECOMMENDED_MUSIC_ASC',
  EmailRecommendedMusicDesc = 'EMAIL_RECOMMENDED_MUSIC_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  WebArtistUpdateAsc = 'WEB_ARTIST_UPDATE_ASC',
  WebArtistUpdateDesc = 'WEB_ARTIST_UPDATE_DESC',
  WebBlareNewsAndOffersAsc = 'WEB_BLARE_NEWS_AND_OFFERS_ASC',
  WebBlareNewsAndOffersDesc = 'WEB_BLARE_NEWS_AND_OFFERS_DESC',
  WebConcertReminderAsc = 'WEB_CONCERT_REMINDER_ASC',
  WebConcertReminderDesc = 'WEB_CONCERT_REMINDER_DESC',
  WebNewFollowerAsc = 'WEB_NEW_FOLLOWER_ASC',
  WebNewFollowerDesc = 'WEB_NEW_FOLLOWER_DESC',
  WebNewMusicAsc = 'WEB_NEW_MUSIC_ASC',
  WebNewMusicDesc = 'WEB_NEW_MUSIC_DESC',
  WebPlaylistUpdatesAsc = 'WEB_PLAYLIST_UPDATES_ASC',
  WebPlaylistUpdatesDesc = 'WEB_PLAYLIST_UPDATES_DESC',
  WebRecommendedMusicAsc = 'WEB_RECOMMENDED_MUSIC_ASC',
  WebRecommendedMusicDesc = 'WEB_RECOMMENDED_MUSIC_DESC',
}

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/**  `UsersQueue` table */
export type UsersQueue = Node & {
  __typename?: 'UsersQueue';
  /** Defines the datetime with time zone `UsersQueue` was first created */
  createdAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['UUID'];
  /** Defines whether the `Track` was listened */
  listened?: Maybe<Scalars['Boolean']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Track` that is related to this `UsersQueue`. */
  trackByTrackId?: Maybe<Track>;
  /** The primary unique identifier for the `Track` */
  trackId?: Maybe<Scalars['UUID']>;
  /** Defines the `Track` number in the order of the `UsersQueue` collection */
  trackNumber: Scalars['Int'];
  /** Reads a single `User` that is related to this `UsersQueue`. */
  userByUserId?: Maybe<User>;
  /** The primary unique identifier for the `User` */
  userId?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `UsersQueue` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type UsersQueueCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `listened` field. */
  listened?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `trackId` field. */
  trackId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `trackNumber` field. */
  trackNumber?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `UsersQueue` object types. All fields are combined with a logical ‘and.’ */
export type UsersQueueFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UsersQueueFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UsersQueueFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UsersQueueFilter>>;
  /** Filter by the object’s `trackNumber` field. */
  trackNumber?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `UsersQueue` */
export type UsersQueueInput = {
  id?: InputMaybe<Scalars['UUID']>;
  /** Defines whether the `Track` was listened */
  listened?: InputMaybe<Scalars['Boolean']>;
  /** The primary unique identifier for the `Track` */
  trackId?: InputMaybe<Scalars['UUID']>;
  /** Defines the `Track` number in the order of the `UsersQueue` collection */
  trackNumber?: InputMaybe<Scalars['Int']>;
  /** The primary unique identifier for the `User` */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** Represents an update to a `UsersQueue`. Fields that are set will be updated. */
export type UsersQueuePatch = {
  id?: InputMaybe<Scalars['UUID']>;
  /** Defines whether the `Track` was listened */
  listened?: InputMaybe<Scalars['Boolean']>;
  /** The primary unique identifier for the `Track` */
  trackId?: InputMaybe<Scalars['UUID']>;
  /** Defines the `Track` number in the order of the `UsersQueue` collection */
  trackNumber?: InputMaybe<Scalars['Int']>;
  /** The primary unique identifier for the `User` */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A connection to a list of `UsersQueue` values. */
export type UsersQueuesConnection = {
  __typename?: 'UsersQueuesConnection';
  /** A list of edges which contains the `UsersQueue` and cursor to aid in pagination. */
  edges: Array<UsersQueuesEdge>;
  /** A list of `UsersQueue` objects. */
  nodes: Array<Maybe<UsersQueue>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UsersQueue` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UsersQueue` edge in the connection. */
export type UsersQueuesEdge = {
  __typename?: 'UsersQueuesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UsersQueue` at the end of the edge. */
  node?: Maybe<UsersQueue>;
};

/** Methods to use when ordering `UsersQueue`. */
export enum UsersQueuesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ListenedAsc = 'LISTENED_ASC',
  ListenedDesc = 'LISTENED_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TrackIdAsc = 'TRACK_ID_ASC',
  TrackIdDesc = 'TRACK_ID_DESC',
  TrackNumberAsc = 'TRACK_NUMBER_ASC',
  TrackNumberDesc = 'TRACK_NUMBER_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
}

export enum VerificationStatus {
  Failed = 'FAILED',
  None = 'NONE',
  Pending = 'PENDING',
  Verified = 'VERIFIED',
}

/** All input for the create mn`AutoGeneratedQueue` mutation. */
export type MnCreateAutoGeneratedQueueInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The one or many `AutoGeneratedQueue` to be created by this mutation. */
  mnAutoGeneratedQueue?: InputMaybe<Array<AutoGeneratedQueueInput>>;
};

/** The output of our many create `AutoGeneratedQueue` mutation. */
export type MnCreateAutoGeneratedQueuePayload = {
  __typename?: 'mnCreateAutoGeneratedQueuePayload';
  /** The `AutoGeneratedQueue` that was created by this mutation. */
  autoGeneratedQueue?: Maybe<AutoGeneratedQueue>;
  /** An edge for our `AutoGeneratedQueue`. May be used by Relay 1. */
  autoGeneratedQueueEdge?: Maybe<AutoGeneratedQueuesEdge>;
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `AutoGeneratedQueue`. */
  trackByTrackId?: Maybe<Track>;
  /** Reads a single `User` that is related to this `AutoGeneratedQueue`. */
  userByUserId?: Maybe<User>;
};

/** The output of our many create `AutoGeneratedQueue` mutation. */
export type MnCreateAutoGeneratedQueuePayloadAutoGeneratedQueueEdgeArgs = {
  orderBy?: InputMaybe<Array<AutoGeneratedQueuesOrderBy>>;
};

/** All input for the create mn`UsersQueue` mutation. */
export type MnCreateUsersQueueInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The one or many `UsersQueue` to be created by this mutation. */
  mnUsersQueue?: InputMaybe<Array<UsersQueueInput>>;
};

/** The output of our many create `UsersQueue` mutation. */
export type MnCreateUsersQueuePayload = {
  __typename?: 'mnCreateUsersQueuePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `UsersQueue`. */
  trackByTrackId?: Maybe<Track>;
  /** Reads a single `User` that is related to this `UsersQueue`. */
  userByUserId?: Maybe<User>;
  /** The `UsersQueue` that was created by this mutation. */
  usersQueue?: Maybe<UsersQueue>;
  /** An edge for our `UsersQueue`. May be used by Relay 1. */
  usersQueueEdge?: Maybe<UsersQueuesEdge>;
};

/** The output of our many create `UsersQueue` mutation. */
export type MnCreateUsersQueuePayloadUsersQueueEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersQueuesOrderBy>>;
};

/** All input for the delete `mnDeleteAutoGeneratedQueueById` mutation. */
export type MnDeleteAutoGeneratedQueueByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The one or many `AutoGeneratedQueue` to be deleted. You must provide the PK values! */
  mnAutoGeneratedQueuePatch?: InputMaybe<Array<AutoGeneratedQueuePatch>>;
};

/** The output of our delete mn `AutoGeneratedQueue` mutation. */
export type MnDeleteAutoGeneratedQueuePayload = {
  __typename?: 'mnDeleteAutoGeneratedQueuePayload';
  /** The `AutoGeneratedQueue` that was deleted by this mutation. */
  autoGeneratedQueue?: Maybe<AutoGeneratedQueue>;
  /** An edge for our `AutoGeneratedQueue`. May be used by Relay 1. */
  autoGeneratedQueueEdge?: Maybe<AutoGeneratedQueuesEdge>;
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedAutoGeneratedQueueId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `AutoGeneratedQueue`. */
  trackByTrackId?: Maybe<Track>;
  /** Reads a single `User` that is related to this `AutoGeneratedQueue`. */
  userByUserId?: Maybe<User>;
};

/** The output of our delete mn `AutoGeneratedQueue` mutation. */
export type MnDeleteAutoGeneratedQueuePayloadAutoGeneratedQueueEdgeArgs = {
  orderBy?: InputMaybe<Array<AutoGeneratedQueuesOrderBy>>;
};

/** All input for the delete `mnDeleteUsersQueueById` mutation. */
export type MnDeleteUsersQueueByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The one or many `UsersQueue` to be deleted. You must provide the PK values! */
  mnUsersQueuePatch?: InputMaybe<Array<UsersQueuePatch>>;
};

/** The output of our delete mn `UsersQueue` mutation. */
export type MnDeleteUsersQueuePayload = {
  __typename?: 'mnDeleteUsersQueuePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUsersQueueId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `UsersQueue`. */
  trackByTrackId?: Maybe<Track>;
  /** Reads a single `User` that is related to this `UsersQueue`. */
  userByUserId?: Maybe<User>;
  /** The `UsersQueue` that was deleted by this mutation. */
  usersQueue?: Maybe<UsersQueue>;
  /** An edge for our `UsersQueue`. May be used by Relay 1. */
  usersQueueEdge?: Maybe<UsersQueuesEdge>;
};

/** The output of our delete mn `UsersQueue` mutation. */
export type MnDeleteUsersQueuePayloadUsersQueueEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersQueuesOrderBy>>;
};

/** All input for the update `mnUpdateAutoGeneratedQueueById` mutation. */
export type MnUpdateAutoGeneratedQueueByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The one or many `AutoGeneratedQueue` to be updated. */
  mnAutoGeneratedQueuePatch?: InputMaybe<Array<AutoGeneratedQueuePatch>>;
};

/** The output of our update mn `AutoGeneratedQueue` mutation. */
export type MnUpdateAutoGeneratedQueuePayload = {
  __typename?: 'mnUpdateAutoGeneratedQueuePayload';
  /** The `AutoGeneratedQueue` that was updated by this mutation. */
  autoGeneratedQueue?: Maybe<AutoGeneratedQueue>;
  /** An edge for our `AutoGeneratedQueue`. May be used by Relay 1. */
  autoGeneratedQueueEdge?: Maybe<AutoGeneratedQueuesEdge>;
  /** The exact same `clientMutationId` that was provided in the mutation input,                 unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `AutoGeneratedQueue`. */
  trackByTrackId?: Maybe<Track>;
  /** Reads a single `User` that is related to this `AutoGeneratedQueue`. */
  userByUserId?: Maybe<User>;
};

/** The output of our update mn `AutoGeneratedQueue` mutation. */
export type MnUpdateAutoGeneratedQueuePayloadAutoGeneratedQueueEdgeArgs = {
  orderBy?: InputMaybe<Array<AutoGeneratedQueuesOrderBy>>;
};

/** All input for the update `mnUpdateUsersQueueById` mutation. */
export type MnUpdateUsersQueueByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The one or many `UsersQueue` to be updated. */
  mnUsersQueuePatch?: InputMaybe<Array<UsersQueuePatch>>;
};

/** The output of our update mn `UsersQueue` mutation. */
export type MnUpdateUsersQueuePayload = {
  __typename?: 'mnUpdateUsersQueuePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input,                 unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Track` that is related to this `UsersQueue`. */
  trackByTrackId?: Maybe<Track>;
  /** Reads a single `User` that is related to this `UsersQueue`. */
  userByUserId?: Maybe<User>;
  /** The `UsersQueue` that was updated by this mutation. */
  usersQueue?: Maybe<UsersQueue>;
  /** An edge for our `UsersQueue`. May be used by Relay 1. */
  usersQueueEdge?: Maybe<UsersQueuesEdge>;
};

/** The output of our update mn `UsersQueue` mutation. */
export type MnUpdateUsersQueuePayloadUsersQueueEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersQueuesOrderBy>>;
};

export type AllDocumentsQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']>;
}>;

export type AllDocumentsQuery = {
  __typename?: 'Query';
  allDocuments?: {
    __typename?: 'DocumentsConnection';
    edges: Array<{
      __typename?: 'DocumentsEdge';
      node?: {__typename?: 'Document'; id: any; content: string; slug?: string | null; title: string} | null;
    }>;
  } | null;
};

export type AutoTopupQueryVariables = Exact<{[key: string]: never}>;

export type AutoTopupQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    id: any;
    profileSettingByUserId?: {__typename?: 'ProfileSetting'; userId: any; topUp?: boolean | null} | null;
  } | null;
};

export type UpdateAutoTopupMutationVariables = Exact<{
  userId: Scalars['UUID'];
  autoTopup?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateAutoTopupMutation = {
  __typename?: 'Mutation';
  updateProfileSettingByUserId?: {
    __typename?: 'UpdateProfileSettingPayload';
    profileSetting?: {__typename?: 'ProfileSetting'; topUp?: boolean | null; userId: any} | null;
  } | null;
};

export type AllAlbumsByIdQueryVariables = Exact<{
  artistId: Scalars['UUID'];
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy> | AlbumsOrderBy>;
  after?: InputMaybe<Scalars['Cursor']>;
}>;

export type AllAlbumsByIdQuery = {
  __typename?: 'Query';
  allAlbums?: {
    __typename?: 'AlbumsConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'AlbumsEdge';
      node?: {
        __typename?: 'Album';
        id: any;
        title?: string | null;
        description?: string | null;
        explicit?: boolean | null;
        tracksByAlbum: {
          __typename?: 'TracksConnection';
          edges: Array<{__typename?: 'TracksEdge'; node?: {__typename?: 'Track'; id: any; streamCount?: number | null} | null}>;
        };
        presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        artistByArtist?: {
          __typename?: 'Artist';
          id: any;
          displayName?: string | null;
          blarecastUrl?: string | null;
          followerCount?: number | null;
          presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type AlbumTotalEarningsQueryVariables = Exact<{
  albumId: Scalars['UUID'];
}>;

export type AlbumTotalEarningsQuery = {__typename?: 'Query'; albumTotalEarnings?: any | null};

export type CreateEventMutationVariables = Exact<{
  name: Scalars['String'];
  eventDate: Scalars['Datetime'];
  artistId: Scalars['UUID'];
  place: Scalars['String'];
}>;

export type CreateEventMutation = {
  __typename?: 'Mutation';
  createEvent?: {
    __typename?: 'CreateEventPayload';
    event?: {__typename?: 'Event'; id: any; name: string; createdAt?: any | null; artistId: any; eventDate: any; place: string} | null;
  } | null;
};

export type UpdateEventMutationVariables = Exact<{
  name: Scalars['String'];
  eventDate: Scalars['Datetime'];
  eventId: Scalars['UUID'];
  place: Scalars['String'];
}>;

export type UpdateEventMutation = {
  __typename?: 'Mutation';
  updateEventById?: {
    __typename?: 'UpdateEventPayload';
    event?: {__typename?: 'Event'; id: any; name: string; createdAt?: any | null; artistId: any; eventDate: any; place: string} | null;
  } | null;
};

export type DeleteEventMutationVariables = Exact<{
  eventId: Scalars['UUID'];
}>;

export type DeleteEventMutation = {
  __typename?: 'Mutation';
  deleteEventById?: {
    __typename?: 'DeleteEventPayload';
    event?: {__typename?: 'Event'; id: any; name: string; createdAt?: any | null; artistId: any; eventDate: any; place: string} | null;
  } | null;
};

export type FutureEventsByArtistIdQueryVariables = Exact<{
  artistId: Scalars['UUID'];
  fromDate: Scalars['Datetime'];
  after?: InputMaybe<Scalars['Cursor']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type FutureEventsByArtistIdQuery = {
  __typename?: 'Query';
  allEvents?: {
    __typename?: 'EventsConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'EventsEdge';
      node?: {__typename?: 'Event'; id: any; name: string; createdAt?: any | null; artistId: any; eventDate: any; place: string} | null;
    }>;
  } | null;
};

export type PastEventsByArtistIdQueryVariables = Exact<{
  artistId: Scalars['UUID'];
  toDate: Scalars['Datetime'];
  after?: InputMaybe<Scalars['Cursor']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type PastEventsByArtistIdQuery = {
  __typename?: 'Query';
  allEvents?: {
    __typename?: 'EventsConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'EventsEdge';
      node?: {__typename?: 'Event'; id: any; name: string; createdAt?: any | null; artistId: any; eventDate: any; place: string} | null;
    }>;
  } | null;
};

export type EventByIdQueryVariables = Exact<{
  eventId: Scalars['UUID'];
}>;

export type EventByIdQuery = {
  __typename?: 'Query';
  eventById?: {
    __typename?: 'Event';
    id: any;
    name: string;
    createdAt?: any | null;
    artistId: any;
    eventDate: any;
    place: string;
    artistByArtistId?: {__typename?: 'Artist'; displayName?: string | null; blarecastUrl?: string | null; id: any} | null;
  } | null;
};

export type GetAllGenresQueryVariables = Exact<{[key: string]: never}>;

export type GetAllGenresQuery = {
  __typename?: 'Query';
  allGenres?: {
    __typename?: 'GenresConnection';
    edges: Array<{
      __typename?: 'GenresEdge';
      node?: {__typename?: 'Genre'; id: any; parentCategory?: any | null; categoryName: string; img?: string | null} | null;
    }>;
  } | null;
};

export type GetGenreDetailsQueryVariables = Exact<{
  genreID: Scalars['UUID'];
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type GetGenreDetailsQuery = {
  __typename?: 'Query';
  genreById?: {
    __typename?: 'Genre';
    categoryName: string;
    id: any;
    img?: string | null;
    tracksByGenre: {
      __typename?: 'TracksConnection';
      edges: Array<{
        __typename?: 'TracksEdge';
        node?: {
          __typename?: 'Track';
          id: any;
          title?: string | null;
          likedAsListener?: boolean | null;
          duration?: number | null;
          streamCount?: number | null;
          price?: number | null;
          explicit?: boolean | null;
          isAvailable?: boolean | null;
          presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
          artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
        } | null;
      }>;
      pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    };
  } | null;
};

export type AlbumFragmentFragment = {
  __typename?: 'Album';
  id: any;
  title?: string | null;
  description?: string | null;
  explicit?: boolean | null;
  presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
  artistByArtist?: {
    __typename?: 'Artist';
    id: any;
    displayName?: string | null;
    blarecastUrl?: string | null;
    followerCount?: number | null;
    presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
  } | null;
};

export type ArtistDetailsFragmentFragment = {
  __typename?: 'Artist';
  userId?: any | null;
  description?: string | null;
  followingCount?: number | null;
  followerCount?: number | null;
  likedAsListener?: boolean | null;
  likedAsArtist?: boolean | null;
  facebook?: string | null;
  instagram?: string | null;
  twitter?: string | null;
  id: any;
  displayName?: string | null;
  blarecastUrl?: string | null;
  presignedCoverImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
  genreByGenre?: {
    __typename?: 'Genre';
    id: any;
    parentCategory?: any | null;
    categoryName: string;
    genreByParentCategory?: {
      __typename?: 'Genre';
      id: any;
      parentCategory?: any | null;
      categoryName: string;
      presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    } | null;
    presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
  } | null;
  userByUserId?: {
    __typename?: 'User';
    userVerificationByUserId?: {__typename?: 'UserVerification'; status?: VerificationStatus | null} | null;
  } | null;
  tracksByArtist: {
    __typename?: 'TracksConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'TracksEdge';
      node?: {
        __typename?: 'Track';
        id: any;
        title?: string | null;
        likedAsListener?: boolean | null;
        duration?: number | null;
        streamCount?: number | null;
        price?: number | null;
        explicit?: boolean | null;
        isAvailable?: boolean | null;
        presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
        artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
      } | null;
    }>;
  };
  pinnedTracks: {
    __typename?: 'TracksConnection';
    edges: Array<{
      __typename?: 'TracksEdge';
      node?: {
        __typename?: 'Track';
        id: any;
        title?: string | null;
        likedAsListener?: boolean | null;
        duration?: number | null;
        streamCount?: number | null;
        price?: number | null;
        explicit?: boolean | null;
        isAvailable?: boolean | null;
        presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
        artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
      } | null;
    }>;
  };
  albumsByArtist: {
    __typename?: 'AlbumsConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'AlbumsEdge';
      node?: {
        __typename?: 'Album';
        id: any;
        title?: string | null;
        description?: string | null;
        explicit?: boolean | null;
        presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        artistByArtist?: {
          __typename?: 'Artist';
          id: any;
          displayName?: string | null;
          blarecastUrl?: string | null;
          followerCount?: number | null;
          presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        } | null;
      } | null;
    }>;
  };
  artistMembersByArtistId: {
    __typename?: 'ArtistMembersConnection';
    nodes: Array<{
      __typename?: 'ArtistMember';
      id: any;
      artistId?: any | null;
      userId?: any | null;
      confirmed?: boolean | null;
      userByUserId?: {
        __typename?: 'User';
        profilePublicByUserId?: {
          __typename?: 'ProfilePublic';
          userId: any;
          displayName?: string | null;
          blarecastUrl?: string | null;
          presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        } | null;
        userVerificationByUserId?: {__typename?: 'UserVerification'; status?: VerificationStatus | null} | null;
      } | null;
    } | null>;
  };
  eventsByArtistId: {
    __typename?: 'EventsConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'EventsEdge';
      node?: {__typename?: 'Event'; id: any; name: string; createdAt?: any | null; artistId: any; eventDate: any; place: string} | null;
    }>;
  };
  presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
};

export type ArtistFragmentFragment = {
  __typename?: 'Artist';
  id: any;
  displayName?: string | null;
  blarecastUrl?: string | null;
  followerCount?: number | null;
  presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
};

export type ArtistMemberFragmentFragment = {
  __typename?: 'ArtistMember';
  id: any;
  artistId?: any | null;
  userId?: any | null;
  confirmed?: boolean | null;
  userByUserId?: {
    __typename?: 'User';
    profilePublicByUserId?: {
      __typename?: 'ProfilePublic';
      userId: any;
      displayName?: string | null;
      blarecastUrl?: string | null;
      presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    } | null;
    userVerificationByUserId?: {__typename?: 'UserVerification'; status?: VerificationStatus | null} | null;
  } | null;
};

export type AutoGeneratedQueueFragmentFragment = {
  __typename?: 'AutoGeneratedQueue';
  id: any;
  listened?: boolean | null;
  trackByTrackId?: {
    __typename?: 'Track';
    id: any;
    title?: string | null;
    likedAsListener?: boolean | null;
    duration?: number | null;
    streamCount?: number | null;
    price?: number | null;
    explicit?: boolean | null;
    isAvailable?: boolean | null;
    presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
    artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
  } | null;
};

export type EventFragmentFragment = {
  __typename?: 'Event';
  id: any;
  name: string;
  createdAt?: any | null;
  artistId: any;
  eventDate: any;
  place: string;
};

export type GenreFragmentFragment = {
  __typename?: 'Genre';
  id: any;
  parentCategory?: any | null;
  categoryName: string;
  presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
};

export type ImageFragmentFragment = {__typename?: 'PresignedImage'; url?: string | null; size?: number | null};

export type LikeFragmentFragment = {
  __typename?: 'Like';
  id: any;
  artistId?: any | null;
  likedUserId?: any | null;
  albumId?: any | null;
  trackId?: any | null;
  playlistId?: any | null;
  actingArtistId?: any | null;
};

export type PaginationFragmentFragment = {
  __typename?: 'PageInfo';
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: any | null;
  endCursor?: any | null;
};

export type PlaylistFragmentFragment = {
  __typename?: 'Playlist';
  id: any;
  title?: string | null;
  private?: boolean | null;
  likeCount?: number | null;
  presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
};

export type PrivateProfileFragmentFragment = {
  __typename?: 'ProfileSetting';
  userId: any;
  accountType?: AccountType | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
};

export type PublicProfileFragmentFragment = {
  __typename?: 'ProfilePublic';
  userId: any;
  blarecastUrl?: string | null;
  displayName?: string | null;
  personalBio?: string | null;
  followerCount?: number | null;
  followingCount?: number | null;
  facebook?: string | null;
  instagram?: string | null;
  twitter?: string | null;
  likedAsListener?: boolean | null;
  likedAsArtist?: boolean | null;
  presignedCoverImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
  presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
};

export type TrackFragmentFragment = {
  __typename?: 'Track';
  id: any;
  title?: string | null;
  likedAsListener?: boolean | null;
  duration?: number | null;
  streamCount?: number | null;
  price?: number | null;
  explicit?: boolean | null;
  isAvailable?: boolean | null;
  presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
  albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
  artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
};

export type UserFragmentFragment = {
  __typename?: 'User';
  id: any;
  profilePublicByUserId?: {__typename?: 'ProfilePublic'; userId: any; displayName?: string | null} | null;
};

export type UsersQueueFragmentFragment = {
  __typename?: 'UsersQueue';
  id: any;
  trackByTrackId?: {
    __typename?: 'Track';
    id: any;
    title?: string | null;
    likedAsListener?: boolean | null;
    duration?: number | null;
    streamCount?: number | null;
    price?: number | null;
    explicit?: boolean | null;
    isAvailable?: boolean | null;
    presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
    artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
  } | null;
};

export type ActiveArtistSubscriptionQueryVariables = Exact<{
  artistid: Scalars['UUID'];
}>;

export type ActiveArtistSubscriptionQuery = {
  __typename?: 'Query';
  artistById?: {
    __typename?: 'Artist';
    artistSubscriptionsByArtistId: {
      __typename?: 'ArtistSubscriptionsConnection';
      nodes: Array<{
        __typename?: 'ArtistSubscription';
        artistSubscriptionTypeBySubscriptionType?: {__typename?: 'ArtistSubscriptionType'; subscriptionType: string} | null;
      } | null>;
    };
  } | null;
};

export type AddArtistMemberMutationVariables = Exact<{
  artistId: Scalars['UUID'];
  userId: Scalars['UUID'];
}>;

export type AddArtistMemberMutation = {
  __typename?: 'Mutation';
  createArtistMember?: {
    __typename?: 'CreateArtistMemberPayload';
    clientMutationId?: string | null;
    artistByArtistId?: {
      __typename?: 'Artist';
      id: any;
      artistMembersByArtistId: {
        __typename?: 'ArtistMembersConnection';
        nodes: Array<{
          __typename?: 'ArtistMember';
          id: any;
          artistId?: any | null;
          userId?: any | null;
          confirmed?: boolean | null;
          userByUserId?: {
            __typename?: 'User';
            profilePublicByUserId?: {
              __typename?: 'ProfilePublic';
              userId: any;
              displayName?: string | null;
              blarecastUrl?: string | null;
              presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
            } | null;
            userVerificationByUserId?: {__typename?: 'UserVerification'; status?: VerificationStatus | null} | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type ArtistsByUserIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type ArtistsByUserIdQuery = {
  __typename?: 'Query';
  allArtists?: {
    __typename?: 'ArtistsConnection';
    nodes: Array<{
      __typename?: 'Artist';
      id: any;
      displayName?: string | null;
      blarecastUrl?: string | null;
      followerCount?: number | null;
      presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    } | null>;
  } | null;
};

export type AllArtistSubscriptionTypesQueryVariables = Exact<{[key: string]: never}>;

export type AllArtistSubscriptionTypesQuery = {
  __typename?: 'Query';
  allArtistSubscriptionTypes?: {
    __typename?: 'ArtistSubscriptionTypesConnection';
    edges: Array<{
      __typename?: 'ArtistSubscriptionTypesEdge';
      node?: {
        __typename?: 'ArtistSubscriptionType';
        id: any;
        duration?: number | null;
        dynamicPricing?: boolean | null;
        formats?: Array<string | null> | null;
        isDefault?: boolean | null;
        minWithdrawalLimit?: string | null;
        moneyBackGuarantee?: boolean | null;
        percentage?: any | null;
        postCount?: number | null;
        setupFee?: string | null;
        monthlyPrice?: string | null;
        annualPrice?: string | null;
        subscriptionType: string;
        moneyBackGuaranteeDays?: number | null;
      } | null;
    }>;
  } | null;
};

export type CreateArtistMutationVariables = Exact<{
  displayName: Scalars['String'];
  blarecastUrl: Scalars['String'];
  genre: Scalars['UUID'];
}>;

export type CreateArtistMutation = {
  __typename?: 'Mutation';
  createArtist?: {
    __typename?: 'CreateArtistPayload';
    artist?: {
      __typename?: 'Artist';
      id: any;
      displayName?: string | null;
      blarecastUrl?: string | null;
      followerCount?: number | null;
      presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    } | null;
  } | null;
};

export type InviteArtistMemberMutationVariables = Exact<{
  artistId: Scalars['UUID'];
  email: Scalars['String'];
}>;

export type InviteArtistMemberMutation = {
  __typename?: 'Mutation';
  createArtistMemberByEmail?: {
    __typename?: 'CreateArtistMemberByEmailPayload';
    artistByArtistId?: {
      __typename?: 'Artist';
      id: any;
      artistMembersByArtistId: {
        __typename?: 'ArtistMembersConnection';
        nodes: Array<{
          __typename?: 'ArtistMember';
          id: any;
          artistId?: any | null;
          userId?: any | null;
          confirmed?: boolean | null;
          userByUserId?: {
            __typename?: 'User';
            profilePublicByUserId?: {
              __typename?: 'ProfilePublic';
              userId: any;
              displayName?: string | null;
              blarecastUrl?: string | null;
              presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
            } | null;
            userVerificationByUserId?: {__typename?: 'UserVerification'; status?: VerificationStatus | null} | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type RemoveArtistMemberMutationVariables = Exact<{
  artistId: Scalars['UUID'];
  memberUserId: Scalars['UUID'];
}>;

export type RemoveArtistMemberMutation = {
  __typename?: 'Mutation';
  deleteArtistMemberByArtistIdAndUserId?: {__typename?: 'DeleteArtistMemberPayload'; clientMutationId?: string | null} | null;
};

export type ResendArtistMemberInvitationMutationVariables = Exact<{
  memberID: Scalars['ID'];
}>;

export type ResendArtistMemberInvitationMutation = {__typename?: 'Mutation'; resendArtistMemberInvitation?: boolean | null};

export type UpdateArtistMutationVariables = Exact<{
  id: Scalars['UUID'];
  displayName?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  blarecastUrl?: InputMaybe<Scalars['String']>;
  genreId?: InputMaybe<Scalars['UUID']>;
  facebook?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  twitter?: InputMaybe<Scalars['String']>;
}>;

export type UpdateArtistMutation = {
  __typename?: 'Mutation';
  updateArtistById?: {
    __typename?: 'UpdateArtistPayload';
    artist?: {
      __typename?: 'Artist';
      userId?: any | null;
      description?: string | null;
      followingCount?: number | null;
      followerCount?: number | null;
      likedAsListener?: boolean | null;
      likedAsArtist?: boolean | null;
      facebook?: string | null;
      instagram?: string | null;
      twitter?: string | null;
      id: any;
      displayName?: string | null;
      blarecastUrl?: string | null;
      presignedCoverImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
      genreByGenre?: {
        __typename?: 'Genre';
        id: any;
        parentCategory?: any | null;
        categoryName: string;
        genreByParentCategory?: {
          __typename?: 'Genre';
          id: any;
          parentCategory?: any | null;
          categoryName: string;
          presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        } | null;
        presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
      } | null;
      userByUserId?: {
        __typename?: 'User';
        userVerificationByUserId?: {__typename?: 'UserVerification'; status?: VerificationStatus | null} | null;
      } | null;
      tracksByArtist: {
        __typename?: 'TracksConnection';
        pageInfo: {
          __typename?: 'PageInfo';
          hasNextPage: boolean;
          hasPreviousPage: boolean;
          startCursor?: any | null;
          endCursor?: any | null;
        };
        edges: Array<{
          __typename?: 'TracksEdge';
          node?: {
            __typename?: 'Track';
            id: any;
            title?: string | null;
            likedAsListener?: boolean | null;
            duration?: number | null;
            streamCount?: number | null;
            price?: number | null;
            explicit?: boolean | null;
            isAvailable?: boolean | null;
            presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
            albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
            artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
          } | null;
        }>;
      };
      pinnedTracks: {
        __typename?: 'TracksConnection';
        edges: Array<{
          __typename?: 'TracksEdge';
          node?: {
            __typename?: 'Track';
            id: any;
            title?: string | null;
            likedAsListener?: boolean | null;
            duration?: number | null;
            streamCount?: number | null;
            price?: number | null;
            explicit?: boolean | null;
            isAvailable?: boolean | null;
            presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
            albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
            artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
          } | null;
        }>;
      };
      albumsByArtist: {
        __typename?: 'AlbumsConnection';
        pageInfo: {
          __typename?: 'PageInfo';
          hasNextPage: boolean;
          hasPreviousPage: boolean;
          startCursor?: any | null;
          endCursor?: any | null;
        };
        edges: Array<{
          __typename?: 'AlbumsEdge';
          node?: {
            __typename?: 'Album';
            id: any;
            title?: string | null;
            description?: string | null;
            explicit?: boolean | null;
            presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
            artistByArtist?: {
              __typename?: 'Artist';
              id: any;
              displayName?: string | null;
              blarecastUrl?: string | null;
              followerCount?: number | null;
              presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
            } | null;
          } | null;
        }>;
      };
      artistMembersByArtistId: {
        __typename?: 'ArtistMembersConnection';
        nodes: Array<{
          __typename?: 'ArtistMember';
          id: any;
          artistId?: any | null;
          userId?: any | null;
          confirmed?: boolean | null;
          userByUserId?: {
            __typename?: 'User';
            profilePublicByUserId?: {
              __typename?: 'ProfilePublic';
              userId: any;
              displayName?: string | null;
              blarecastUrl?: string | null;
              presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
            } | null;
            userVerificationByUserId?: {__typename?: 'UserVerification'; status?: VerificationStatus | null} | null;
          } | null;
        } | null>;
      };
      eventsByArtistId: {
        __typename?: 'EventsConnection';
        pageInfo: {
          __typename?: 'PageInfo';
          hasNextPage: boolean;
          hasPreviousPage: boolean;
          startCursor?: any | null;
          endCursor?: any | null;
        };
        edges: Array<{
          __typename?: 'EventsEdge';
          node?: {__typename?: 'Event'; id: any; name: string; createdAt?: any | null; artistId: any; eventDate: any; place: string} | null;
        }>;
      };
      presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    } | null;
  } | null;
};

export type AllRecentlyPlayedsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecentlyPlayedsOrderBy> | RecentlyPlayedsOrderBy>;
}>;

export type AllRecentlyPlayedsQuery = {
  __typename?: 'Query';
  allRecentlyPlayeds?: {
    __typename?: 'RecentlyPlayedsConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'RecentlyPlayedsEdge';
      node?: {
        __typename?: 'RecentlyPlayed';
        id: any;
        artistByArtistId?: {
          __typename?: 'Artist';
          id: any;
          displayName?: string | null;
          blarecastUrl?: string | null;
          followerCount?: number | null;
          presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        } | null;
        playlistByPlaylistId?: {
          __typename?: 'Playlist';
          id: any;
          title?: string | null;
          private?: boolean | null;
          likeCount?: number | null;
          presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        } | null;
        albumByAlbumId?: {
          __typename?: 'Album';
          id: any;
          title?: string | null;
          description?: string | null;
          explicit?: boolean | null;
          presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          artistByArtist?: {
            __typename?: 'Artist';
            id: any;
            displayName?: string | null;
            blarecastUrl?: string | null;
            followerCount?: number | null;
            presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type NewTracksQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;

export type NewTracksQuery = {
  __typename?: 'Query';
  allTracks?: {
    __typename?: 'TracksConnection';
    nodes: Array<{
      __typename?: 'Track';
      id: any;
      title?: string | null;
      likedAsListener?: boolean | null;
      duration?: number | null;
      streamCount?: number | null;
      price?: number | null;
      explicit?: boolean | null;
      isAvailable?: boolean | null;
      presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
      albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
      artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
    } | null>;
  } | null;
};

export type TracksByGenreQueryVariables = Exact<{
  genre: Scalars['UUID'];
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type TracksByGenreQuery = {
  __typename?: 'Query';
  genreById?: {
    __typename?: 'Genre';
    id: any;
    parentCategory?: any | null;
    categoryName: string;
    tracksByGenre: {
      __typename?: 'TracksConnection';
      nodes: Array<{
        __typename?: 'Track';
        id: any;
        title?: string | null;
        likedAsListener?: boolean | null;
        duration?: number | null;
        streamCount?: number | null;
        price?: number | null;
        explicit?: boolean | null;
        isAvailable?: boolean | null;
        presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
        artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
      } | null>;
    };
    presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
  } | null;
};

export type AllAlbumsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy> | AlbumsOrderBy>;
}>;

export type AllAlbumsQuery = {
  __typename?: 'Query';
  allAlbums?: {
    __typename?: 'AlbumsConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'AlbumsEdge';
      node?: {
        __typename?: 'Album';
        id: any;
        title?: string | null;
        description?: string | null;
        explicit?: boolean | null;
        presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        artistByArtist?: {
          __typename?: 'Artist';
          id: any;
          displayName?: string | null;
          blarecastUrl?: string | null;
          followerCount?: number | null;
          presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type AllAlbumsByArtistIdQueryVariables = Exact<{
  artistId: Scalars['UUID'];
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AlbumsOrderBy> | AlbumsOrderBy>;
}>;

export type AllAlbumsByArtistIdQuery = {
  __typename?: 'Query';
  allAlbums?: {
    __typename?: 'AlbumsConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'AlbumsEdge';
      node?: {
        __typename?: 'Album';
        id: any;
        title?: string | null;
        description?: string | null;
        explicit?: boolean | null;
        presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        artistByArtist?: {
          __typename?: 'Artist';
          id: any;
          displayName?: string | null;
          blarecastUrl?: string | null;
          followerCount?: number | null;
          presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type AllArtistsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy> | ArtistsOrderBy>;
}>;

export type AllArtistsQuery = {
  __typename?: 'Query';
  allArtists?: {
    __typename?: 'ArtistsConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'ArtistsEdge';
      node?: {
        __typename?: 'Artist';
        id: any;
        displayName?: string | null;
        blarecastUrl?: string | null;
        followerCount?: number | null;
        presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
      } | null;
    }>;
  } | null;
};

export type AllGenresQueryVariables = Exact<{
  parentId?: InputMaybe<Scalars['UUID']>;
  searchString?: InputMaybe<Scalars['String']>;
}>;

export type AllGenresQuery = {
  __typename?: 'Query';
  allGenres?: {
    __typename?: 'GenresConnection';
    edges: Array<{
      __typename?: 'GenresEdge';
      node?: {
        __typename?: 'Genre';
        id: any;
        parentCategory?: any | null;
        categoryName: string;
        presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
      } | null;
    }>;
  } | null;
};

export type AllTracksQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy> | TracksOrderBy>;
}>;

export type AllTracksQuery = {
  __typename?: 'Query';
  allTracks?: {
    __typename?: 'TracksConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'TracksEdge';
      node?: {
        __typename?: 'Track';
        id: any;
        title?: string | null;
        likedAsListener?: boolean | null;
        duration?: number | null;
        streamCount?: number | null;
        price?: number | null;
        explicit?: boolean | null;
        isAvailable?: boolean | null;
        presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
        artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
      } | null;
    }>;
  } | null;
};

export type AlbumDetailsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type AlbumDetailsQuery = {
  __typename?: 'Query';
  albumById?: {
    __typename?: 'Album';
    likedAsListener?: boolean | null;
    description?: string | null;
    releaseDate: any;
    tags?: string | null;
    genre?: any | null;
    id: any;
    title?: string | null;
    explicit?: boolean | null;
    albumsTracksByAlbumId: {
      __typename?: 'AlbumsTracksConnection';
      totalCount: number;
      edges: Array<{
        __typename?: 'AlbumsTracksEdge';
        node?: {
          __typename?: 'AlbumsTrack';
          trackByTrackId?: {
            __typename?: 'Track';
            id: any;
            title?: string | null;
            likedAsListener?: boolean | null;
            duration?: number | null;
            streamCount?: number | null;
            price?: number | null;
            explicit?: boolean | null;
            isAvailable?: boolean | null;
            tracksArtistsByTrackId: {
              __typename?: 'TracksArtistsConnection';
              edges: Array<{
                __typename?: 'TracksArtistsEdge';
                node?: {
                  __typename?: 'TracksArtist';
                  artistByArtistId?: {
                    __typename?: 'Artist';
                    id: any;
                    displayName?: string | null;
                    blarecastUrl?: string | null;
                    followerCount?: number | null;
                    presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
                  } | null;
                } | null;
              }>;
            };
            presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
            albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
            artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
          } | null;
        } | null;
      }>;
    };
    presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    artistByArtist?: {
      __typename?: 'Artist';
      id: any;
      displayName?: string | null;
      blarecastUrl?: string | null;
      followerCount?: number | null;
      presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    } | null;
  } | null;
};

export type AlbumMenuDetailsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type AlbumMenuDetailsQuery = {
  __typename?: 'Query';
  albumById?: {
    __typename?: 'Album';
    likedAsListener?: boolean | null;
    id: any;
    title?: string | null;
    description?: string | null;
    explicit?: boolean | null;
    artistByArtist?: {
      __typename?: 'Artist';
      id: any;
      displayName?: string | null;
      blarecastUrl?: string | null;
      followerCount?: number | null;
      presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    } | null;
    presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
  } | null;
};

export type ArtistDetailsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type ArtistDetailsQuery = {
  __typename?: 'Query';
  artistById?: {
    __typename?: 'Artist';
    userId?: any | null;
    description?: string | null;
    followingCount?: number | null;
    followerCount?: number | null;
    likedAsListener?: boolean | null;
    likedAsArtist?: boolean | null;
    facebook?: string | null;
    instagram?: string | null;
    twitter?: string | null;
    id: any;
    displayName?: string | null;
    blarecastUrl?: string | null;
    artistSubscriptionsByArtistId: {
      __typename?: 'ArtistSubscriptionsConnection';
      nodes: Array<{
        __typename?: 'ArtistSubscription';
        id: any;
        artistSubscriptionTypeBySubscriptionType?: {__typename?: 'ArtistSubscriptionType'; subscriptionType: string} | null;
      } | null>;
    };
    presignedCoverImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    genreByGenre?: {
      __typename?: 'Genre';
      id: any;
      parentCategory?: any | null;
      categoryName: string;
      genreByParentCategory?: {
        __typename?: 'Genre';
        id: any;
        parentCategory?: any | null;
        categoryName: string;
        presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
      } | null;
      presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    } | null;
    userByUserId?: {
      __typename?: 'User';
      userVerificationByUserId?: {__typename?: 'UserVerification'; status?: VerificationStatus | null} | null;
    } | null;
    tracksByArtist: {
      __typename?: 'TracksConnection';
      pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
      edges: Array<{
        __typename?: 'TracksEdge';
        node?: {
          __typename?: 'Track';
          id: any;
          title?: string | null;
          likedAsListener?: boolean | null;
          duration?: number | null;
          streamCount?: number | null;
          price?: number | null;
          explicit?: boolean | null;
          isAvailable?: boolean | null;
          presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
          artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
        } | null;
      }>;
    };
    pinnedTracks: {
      __typename?: 'TracksConnection';
      edges: Array<{
        __typename?: 'TracksEdge';
        node?: {
          __typename?: 'Track';
          id: any;
          title?: string | null;
          likedAsListener?: boolean | null;
          duration?: number | null;
          streamCount?: number | null;
          price?: number | null;
          explicit?: boolean | null;
          isAvailable?: boolean | null;
          presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
          artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
        } | null;
      }>;
    };
    albumsByArtist: {
      __typename?: 'AlbumsConnection';
      pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
      edges: Array<{
        __typename?: 'AlbumsEdge';
        node?: {
          __typename?: 'Album';
          id: any;
          title?: string | null;
          description?: string | null;
          explicit?: boolean | null;
          presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          artistByArtist?: {
            __typename?: 'Artist';
            id: any;
            displayName?: string | null;
            blarecastUrl?: string | null;
            followerCount?: number | null;
            presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          } | null;
        } | null;
      }>;
    };
    artistMembersByArtistId: {
      __typename?: 'ArtistMembersConnection';
      nodes: Array<{
        __typename?: 'ArtistMember';
        id: any;
        artistId?: any | null;
        userId?: any | null;
        confirmed?: boolean | null;
        userByUserId?: {
          __typename?: 'User';
          profilePublicByUserId?: {
            __typename?: 'ProfilePublic';
            userId: any;
            displayName?: string | null;
            blarecastUrl?: string | null;
            presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          } | null;
          userVerificationByUserId?: {__typename?: 'UserVerification'; status?: VerificationStatus | null} | null;
        } | null;
      } | null>;
    };
    eventsByArtistId: {
      __typename?: 'EventsConnection';
      pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
      edges: Array<{
        __typename?: 'EventsEdge';
        node?: {__typename?: 'Event'; id: any; name: string; createdAt?: any | null; artistId: any; eventDate: any; place: string} | null;
      }>;
    };
    presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
  } | null;
};

export type ListenerDetailsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type ListenerDetailsQuery = {
  __typename?: 'Query';
  profilePublicByUserId?: {
    __typename?: 'ProfilePublic';
    userId: any;
    blarecastUrl?: string | null;
    displayName?: string | null;
    personalBio?: string | null;
    followerCount?: number | null;
    followingCount?: number | null;
    facebook?: string | null;
    instagram?: string | null;
    twitter?: string | null;
    likedAsListener?: boolean | null;
    likedAsArtist?: boolean | null;
    userByUserId?: {
      __typename?: 'User';
      playlistsByAuthor: {
        __typename?: 'PlaylistsConnection';
        edges: Array<{
          __typename?: 'PlaylistsEdge';
          node?: {
            __typename?: 'Playlist';
            id: any;
            title?: string | null;
            private?: boolean | null;
            likeCount?: number | null;
            presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          } | null;
        }>;
      };
      userVerificationByUserId?: {__typename?: 'UserVerification'; status?: VerificationStatus | null} | null;
    } | null;
    presignedCoverImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
  } | null;
};

export type PlaylistDetailsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type PlaylistDetailsQuery = {
  __typename?: 'Query';
  playlistById?: {
    __typename?: 'Playlist';
    likedAsListener?: boolean | null;
    id: any;
    title?: string | null;
    private?: boolean | null;
    likeCount?: number | null;
    userByAuthor?: {
      __typename?: 'User';
      id: any;
      profilePublicByUserId?: {__typename?: 'ProfilePublic'; userId: any; displayName?: string | null} | null;
    } | null;
    playlistsTracksByPlaylistId: {
      __typename?: 'PlaylistsTracksConnection';
      pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
      edges: Array<{
        __typename?: 'PlaylistsTracksEdge';
        node?: {
          __typename?: 'PlaylistsTrack';
          trackByTrackId?: {
            __typename?: 'Track';
            id: any;
            title?: string | null;
            likedAsListener?: boolean | null;
            duration?: number | null;
            streamCount?: number | null;
            price?: number | null;
            explicit?: boolean | null;
            isAvailable?: boolean | null;
            artistByArtist?: {
              __typename?: 'Artist';
              id: any;
              displayName?: string | null;
              blarecastUrl?: string | null;
              followerCount?: number | null;
              presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
            } | null;
            presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
            albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
          } | null;
        } | null;
      }>;
    };
    presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
  } | null;
};

export type PlaylistMenuDetailsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type PlaylistMenuDetailsQuery = {
  __typename?: 'Query';
  playlistById?: {
    __typename?: 'Playlist';
    likedAsListener?: boolean | null;
    private?: boolean | null;
    id: any;
    title?: string | null;
    likeCount?: number | null;
    userByAuthor?: {__typename?: 'User'; id: any} | null;
    presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
  } | null;
};

export type TrackDetailsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type TrackDetailsQuery = {
  __typename?: 'Query';
  trackById?: {
    __typename?: 'Track';
    id: any;
    title?: string | null;
    likedAsListener?: boolean | null;
    duration?: number | null;
    streamCount?: number | null;
    price?: number | null;
    explicit?: boolean | null;
    isAvailable?: boolean | null;
    presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
    artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
  } | null;
};

export type TrackDetailsEditQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type TrackDetailsEditQuery = {
  __typename?: 'Query';
  trackById?: {
    __typename?: 'Track';
    id: any;
    img?: string | null;
    title?: string | null;
    price?: number | null;
    genre?: any | null;
    tags?: string | null;
    releaseDate?: any | null;
    private?: boolean | null;
    duration?: number | null;
    artist: any;
    explicit?: boolean | null;
    pinnedAt?: any | null;
    presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    albumByAlbum?: {__typename?: 'Album'; id: any} | null;
  } | null;
};

export type FavoritesQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']>;
}>;

export type FavoritesQuery = {
  __typename?: 'Query';
  allLikes?: {
    __typename?: 'LikesConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'LikesEdge';
      node?: {
        __typename?: 'Like';
        userId?: any | null;
        trackByTrackId?: {
          __typename?: 'Track';
          id: any;
          title?: string | null;
          likedAsListener?: boolean | null;
          duration?: number | null;
          streamCount?: number | null;
          price?: number | null;
          explicit?: boolean | null;
          isAvailable?: boolean | null;
          presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
          artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
        } | null;
        albumByAlbumId?: {
          __typename?: 'Album';
          id: any;
          title?: string | null;
          description?: string | null;
          explicit?: boolean | null;
          presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          artistByArtist?: {
            __typename?: 'Artist';
            id: any;
            displayName?: string | null;
            blarecastUrl?: string | null;
            followerCount?: number | null;
            presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          } | null;
        } | null;
        playlistByPlaylistId?: {
          __typename?: 'Playlist';
          id: any;
          title?: string | null;
          private?: boolean | null;
          likeCount?: number | null;
          presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        } | null;
        artistByArtistId?: {
          __typename?: 'Artist';
          id: any;
          displayName?: string | null;
          blarecastUrl?: string | null;
          followerCount?: number | null;
          presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type LastPlayedTracksQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['Cursor']>;
  after?: InputMaybe<Scalars['Cursor']>;
}>;

export type LastPlayedTracksQuery = {
  __typename?: 'Query';
  allLastPlayedTracks?: {
    __typename?: 'LastPlayedTracksConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'LastPlayedTracksEdge';
      node?: {
        __typename?: 'LastPlayedTrack';
        id: any;
        trackNumber: number;
        trackByTrackId?: {
          __typename?: 'Track';
          id: any;
          title?: string | null;
          likedAsListener?: boolean | null;
          duration?: number | null;
          streamCount?: number | null;
          price?: number | null;
          explicit?: boolean | null;
          isAvailable?: boolean | null;
          presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
          artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type AllLikedAlbumsQueryVariables = Exact<{
  userId: Scalars['UUID'];
  actingArtistId?: InputMaybe<Scalars['UUID']>;
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type AllLikedAlbumsQuery = {
  __typename?: 'Query';
  allLikes?: {
    __typename?: 'LikesConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'LikesEdge';
      node?: {
        __typename?: 'Like';
        albumByAlbumId?: {
          __typename?: 'Album';
          id: any;
          title?: string | null;
          description?: string | null;
          explicit?: boolean | null;
          presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          artistByArtist?: {
            __typename?: 'Artist';
            id: any;
            displayName?: string | null;
            blarecastUrl?: string | null;
            followerCount?: number | null;
            presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type AllLikedArtistsQueryVariables = Exact<{
  userId: Scalars['UUID'];
  actingArtistId?: InputMaybe<Scalars['UUID']>;
  after?: InputMaybe<Scalars['Cursor']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type AllLikedArtistsQuery = {
  __typename?: 'Query';
  allLikes?: {
    __typename?: 'LikesConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'LikesEdge';
      node?: {
        __typename?: 'Like';
        artistByArtistId?: {
          __typename?: 'Artist';
          id: any;
          displayName?: string | null;
          blarecastUrl?: string | null;
          followerCount?: number | null;
          presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type AllLikesQueryVariables = Exact<{
  userId: Scalars['UUID'];
}>;

export type AllLikesQuery = {
  __typename?: 'Query';
  allLikes?: {
    __typename?: 'LikesConnection';
    edges: Array<{
      __typename?: 'LikesEdge';
      node?: {
        __typename?: 'Like';
        id: any;
        artistId?: any | null;
        likedUserId?: any | null;
        albumId?: any | null;
        trackId?: any | null;
        playlistId?: any | null;
        actingArtistId?: any | null;
      } | null;
    }>;
  } | null;
};

export type ArtistFollowersQueryVariables = Exact<{
  artistId?: InputMaybe<Scalars['UUID']>;
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy> | LikesOrderBy>;
}>;

export type ArtistFollowersQuery = {
  __typename?: 'Query';
  allLikes?: {
    __typename?: 'LikesConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{__typename?: 'LikesEdge'; node?: {__typename?: 'Like'; id: any} | null}>;
  } | null;
};

export type ArtistLikesQueryVariables = Exact<{
  userId: Scalars['UUID'];
  artistId: Scalars['UUID'];
}>;

export type ArtistLikesQuery = {
  __typename?: 'Query';
  artistById?: {
    __typename?: 'Artist';
    likesByArtistId: {
      __typename?: 'LikesConnection';
      nodes: Array<{__typename?: 'Like'; userId?: any | null; actingArtistId?: any | null} | null>;
    };
  } | null;
};

export type CreateLikeMutationVariables = Exact<{
  trackId?: InputMaybe<Scalars['UUID']>;
  albumId?: InputMaybe<Scalars['UUID']>;
  playlistId?: InputMaybe<Scalars['UUID']>;
  artistId?: InputMaybe<Scalars['UUID']>;
  likedUserId?: InputMaybe<Scalars['UUID']>;
  actingArtistId?: InputMaybe<Scalars['UUID']>;
}>;

export type CreateLikeMutation = {
  __typename?: 'Mutation';
  createLike?: {
    __typename?: 'CreateLikePayload';
    like?: {
      __typename?: 'Like';
      id: any;
      artistId?: any | null;
      likedUserId?: any | null;
      albumId?: any | null;
      trackId?: any | null;
      playlistId?: any | null;
      actingArtistId?: any | null;
    } | null;
  } | null;
};

export type DeleteLikeMutationVariables = Exact<{
  actingArtistId?: InputMaybe<Scalars['UUID']>;
  trackId?: InputMaybe<Scalars['UUID']>;
  albumId?: InputMaybe<Scalars['UUID']>;
  playlistId?: InputMaybe<Scalars['UUID']>;
  artistId?: InputMaybe<Scalars['UUID']>;
  userId?: InputMaybe<Scalars['UUID']>;
}>;

export type DeleteLikeMutation = {
  __typename?: 'Mutation';
  deleteLikes?: {
    __typename?: 'DeleteLikesPayload';
    likes?: Array<{
      __typename?: 'Like';
      id: any;
      artistId?: any | null;
      likedUserId?: any | null;
      albumId?: any | null;
      trackId?: any | null;
      playlistId?: any | null;
      actingArtistId?: any | null;
    } | null> | null;
  } | null;
};

export type DeleteLikeByIdMutationVariables = Exact<{
  likeId: Scalars['UUID'];
}>;

export type DeleteLikeByIdMutation = {
  __typename?: 'Mutation';
  deleteLikeById?: {
    __typename?: 'DeleteLikePayload';
    like?: {
      __typename?: 'Like';
      id: any;
      artistId?: any | null;
      likedUserId?: any | null;
      albumId?: any | null;
      trackId?: any | null;
      playlistId?: any | null;
      actingArtistId?: any | null;
    } | null;
  } | null;
};

export type ListenerLikesQueryVariables = Exact<{
  currentUserId: Scalars['UUID'];
  listenerId: Scalars['UUID'];
}>;

export type ListenerLikesQuery = {
  __typename?: 'Query';
  userById?: {
    __typename?: 'User';
    likesByLikedUserId: {__typename?: 'LikesConnection'; nodes: Array<{__typename?: 'Like'; actingArtistId?: any | null} | null>};
  } | null;
};

export type AllNotificationsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type AllNotificationsQuery = {
  __typename?: 'Query';
  allNotifications?: {
    __typename?: 'NotificationsConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'NotificationsEdge';
      node?: {
        __typename?: 'Notification';
        id: any;
        createdAt?: any | null;
        title: string;
        subtitle: string;
        notificationType: NotificationType;
        targetId?: any | null;
        targetType?: AccountType | null;
        pushSent?: boolean | null;
        read?: boolean | null;
        emailSent?: boolean | null;
      } | null;
    }>;
  } | null;
};

export type DeleteAllNotificationsMutationVariables = Exact<{[key: string]: never}>;

export type DeleteAllNotificationsMutation = {
  __typename?: 'Mutation';
  deleteAllNotifications?: {__typename?: 'DeleteAllNotificationsPayload'; clientMutationId?: string | null} | null;
};

export type ReadAllNotificationsMutationVariables = Exact<{[key: string]: never}>;

export type ReadAllNotificationsMutation = {
  __typename?: 'Mutation';
  updateNotificationsAllRead?: {__typename?: 'UpdateNotificationsAllReadPayload'; clientMutationId?: string | null} | null;
};

export type ReadNotificationMutationVariables = Exact<{
  notificationId: Scalars['UUID'];
}>;

export type ReadNotificationMutation = {
  __typename?: 'Mutation';
  updateNotificationById?: {__typename?: 'UpdateNotificationPayload'; clientMutationId?: string | null} | null;
};

export type AddToPlaylistMutationVariables = Exact<{
  playlist: Scalars['UUID'];
  track: Scalars['UUID'];
  trackNumber: Scalars['Int'];
}>;

export type AddToPlaylistMutation = {
  __typename?: 'Mutation';
  addTrackToPlaylist?: {
    __typename?: 'AddTrackToPlaylistPayload';
    query?: {
      __typename?: 'Query';
      playlistById?: {
        __typename?: 'Playlist';
        id: any;
        title?: string | null;
        private?: boolean | null;
        likeCount?: number | null;
        presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
      } | null;
    } | null;
  } | null;
};

export type AllPlaylistsQueryVariables = Exact<{
  author: Scalars['UUID'];
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlaylistsOrderBy> | PlaylistsOrderBy>;
}>;

export type AllPlaylistsQuery = {
  __typename?: 'Query';
  allPlaylists?: {
    __typename?: 'PlaylistsConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    nodes: Array<{
      __typename?: 'Playlist';
      id: any;
      title?: string | null;
      private?: boolean | null;
      likeCount?: number | null;
      presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    } | null>;
  } | null;
};

export type CreatePlaylistMutationVariables = Exact<{
  title: Scalars['String'];
  img?: InputMaybe<Scalars['String']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
}>;

export type CreatePlaylistMutation = {
  __typename?: 'Mutation';
  createPlaylist?: {
    __typename?: 'CreatePlaylistPayload';
    playlist?: {
      __typename?: 'Playlist';
      id: any;
      title?: string | null;
      private?: boolean | null;
      likeCount?: number | null;
      presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    } | null;
  } | null;
};

export type DeletePlaylistMutationVariables = Exact<{
  playlistId: Scalars['UUID'];
}>;

export type DeletePlaylistMutation = {
  __typename?: 'Mutation';
  deletePlaylistById?: {
    __typename?: 'DeletePlaylistPayload';
    playlist?: {
      __typename?: 'Playlist';
      id: any;
      title?: string | null;
      private?: boolean | null;
      likeCount?: number | null;
      presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    } | null;
  } | null;
};

export type DeletePlaylistImageMutationVariables = Exact<{
  playlistId: Scalars['UUID'];
}>;

export type DeletePlaylistImageMutation = {
  __typename?: 'Mutation';
  deletePlaylistImage?: {__typename?: 'DeletePlaylistImagePayload'; clientMutationId?: string | null} | null;
};

export type IsTrackOnPlaylistQueryVariables = Exact<{
  trackId: Scalars['UUID'];
  playlistId: Scalars['UUID'];
}>;

export type IsTrackOnPlaylistQuery = {
  __typename?: 'Query';
  allPlaylistsTracks?: {__typename?: 'PlaylistsTracksConnection'; totalCount: number} | null;
};

export type MyPlaylistsQueryVariables = Exact<{
  author: Scalars['UUID'];
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlaylistsOrderBy> | PlaylistsOrderBy>;
}>;

export type MyPlaylistsQuery = {
  __typename?: 'Query';
  allPlaylists?: {
    __typename?: 'PlaylistsConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    nodes: Array<{
      __typename?: 'Playlist';
      id: any;
      title?: string | null;
      private?: boolean | null;
      likeCount?: number | null;
      presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    } | null>;
  } | null;
};

export type RemoveFromPlaylistMutationVariables = Exact<{
  playlist: Scalars['UUID'];
  track: Scalars['UUID'];
}>;

export type RemoveFromPlaylistMutation = {
  __typename?: 'Mutation';
  removeTrackFromPlaylist?: {
    __typename?: 'RemoveTrackFromPlaylistPayload';
    query?: {
      __typename?: 'Query';
      playlistById?: {
        __typename?: 'Playlist';
        id: any;
        title?: string | null;
        private?: boolean | null;
        likeCount?: number | null;
        presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
      } | null;
    } | null;
  } | null;
};

export type UpdatePlaylistMutationVariables = Exact<{
  playlistId: Scalars['UUID'];
  title: Scalars['String'];
  isPrivate?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdatePlaylistMutation = {
  __typename?: 'Mutation';
  updatePlaylistById?: {
    __typename?: 'UpdatePlaylistPayload';
    playlist?: {
      __typename?: 'Playlist';
      id: any;
      title?: string | null;
      private?: boolean | null;
      likeCount?: number | null;
      presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    } | null;
  } | null;
};

export type AddToAutoGeneratedQueueMutationVariables = Exact<{
  queueObjectList?: InputMaybe<Array<AutoGeneratedQueueInput> | AutoGeneratedQueueInput>;
}>;

export type AddToAutoGeneratedQueueMutation = {
  __typename?: 'Mutation';
  mnCreateAutoGeneratedQueue?: {
    __typename?: 'mnCreateAutoGeneratedQueuePayload';
    autoGeneratedQueueEdge?: {
      __typename?: 'AutoGeneratedQueuesEdge';
      node?: {
        __typename?: 'AutoGeneratedQueue';
        id: any;
        listened?: boolean | null;
        trackByTrackId?: {
          __typename?: 'Track';
          id: any;
          title?: string | null;
          likedAsListener?: boolean | null;
          duration?: number | null;
          streamCount?: number | null;
          price?: number | null;
          explicit?: boolean | null;
          isAvailable?: boolean | null;
          presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
          artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type AddToUsersQueueMutationVariables = Exact<{
  queueObjectList?: InputMaybe<Array<UsersQueueInput> | UsersQueueInput>;
}>;

export type AddToUsersQueueMutation = {
  __typename?: 'Mutation';
  mnCreateUsersQueue?: {
    __typename?: 'mnCreateUsersQueuePayload';
    usersQueueEdge?: {
      __typename?: 'UsersQueuesEdge';
      node?: {
        __typename?: 'UsersQueue';
        id: any;
        trackByTrackId?: {
          __typename?: 'Track';
          id: any;
          title?: string | null;
          likedAsListener?: boolean | null;
          duration?: number | null;
          streamCount?: number | null;
          price?: number | null;
          explicit?: boolean | null;
          isAvailable?: boolean | null;
          presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
          artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type AllAutoGeneratedQueueQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AutoGeneratedQueuesOrderBy> | AutoGeneratedQueuesOrderBy>;
}>;

export type AllAutoGeneratedQueueQuery = {
  __typename?: 'Query';
  allAutoGeneratedQueues?: {
    __typename?: 'AutoGeneratedQueuesConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'AutoGeneratedQueuesEdge';
      node?: {
        __typename?: 'AutoGeneratedQueue';
        id: any;
        listened?: boolean | null;
        trackByTrackId?: {
          __typename?: 'Track';
          id: any;
          title?: string | null;
          likedAsListener?: boolean | null;
          duration?: number | null;
          streamCount?: number | null;
          price?: number | null;
          explicit?: boolean | null;
          isAvailable?: boolean | null;
          presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
          artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type AllUsersQueueQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type AllUsersQueueQuery = {
  __typename?: 'Query';
  allUsersQueues?: {
    __typename?: 'UsersQueuesConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'UsersQueuesEdge';
      node?: {
        __typename?: 'UsersQueue';
        id: any;
        trackByTrackId?: {
          __typename?: 'Track';
          id: any;
          title?: string | null;
          likedAsListener?: boolean | null;
          duration?: number | null;
          streamCount?: number | null;
          price?: number | null;
          explicit?: boolean | null;
          isAvailable?: boolean | null;
          presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
          artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type DeleteFromQueueMutationVariables = Exact<{
  trackId: Scalars['UUID'];
}>;

export type DeleteFromQueueMutation = {
  __typename?: 'Mutation';
  deleteUsersQueueById?: {__typename?: 'DeleteUsersQueuePayload'; deletedUsersQueueId?: string | null} | null;
};

export type DeleteFromUpNextMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type DeleteFromUpNextMutation = {
  __typename?: 'Mutation';
  deleteAutoGeneratedQueueById?: {__typename?: 'DeleteAutoGeneratedQueuePayload'; deletedAutoGeneratedQueueId?: string | null} | null;
};

export type DeleteListFromQueueMutationVariables = Exact<{
  queueIdList?: InputMaybe<Array<UsersQueuePatch> | UsersQueuePatch>;
}>;

export type DeleteListFromQueueMutation = {
  __typename?: 'Mutation';
  mnDeleteUsersQueueById?: {__typename?: 'mnDeleteUsersQueuePayload'; deletedUsersQueueId?: string | null} | null;
};

export type EmptyAutoGeneratedQueueMutationVariables = Exact<{[key: string]: never}>;

export type EmptyAutoGeneratedQueueMutation = {
  __typename?: 'Mutation';
  emptyAutoGeneratedQueue?: {__typename?: 'EmptyAutoGeneratedQueuePayload'; clientMutationId?: string | null} | null;
};

export type EmptyUsersQueueMutationVariables = Exact<{[key: string]: never}>;

export type EmptyUsersQueueMutation = {
  __typename?: 'Mutation';
  emptyUsersQueue?: {__typename?: 'EmptyUsersQueuePayload'; clientMutationId?: string | null} | null;
};

export type UpdateAutoGeneratedQueueByIdMutationVariables = Exact<{
  id: Scalars['UUID'];
  listened: Scalars['Boolean'];
}>;

export type UpdateAutoGeneratedQueueByIdMutation = {
  __typename?: 'Mutation';
  updateAutoGeneratedQueueById?: {
    __typename?: 'UpdateAutoGeneratedQueuePayload';
    autoGeneratedQueue?: {
      __typename?: 'AutoGeneratedQueue';
      id: any;
      listened?: boolean | null;
      trackByTrackId?: {
        __typename?: 'Track';
        id: any;
        title?: string | null;
        likedAsListener?: boolean | null;
        duration?: number | null;
        streamCount?: number | null;
        price?: number | null;
        explicit?: boolean | null;
        isAvailable?: boolean | null;
        presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
        artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateAutoGeneratedQueueListMutationVariables = Exact<{
  patches: Array<AutoGeneratedQueuePatch> | AutoGeneratedQueuePatch;
}>;

export type UpdateAutoGeneratedQueueListMutation = {
  __typename?: 'Mutation';
  mnUpdateAutoGeneratedQueueById?: {__typename?: 'mnUpdateAutoGeneratedQueuePayload'; clientMutationId?: string | null} | null;
};

export type UpdateAutogeneratedQueueMutationVariables = Exact<{
  album?: InputMaybe<Scalars['UUID']>;
  playlist?: InputMaybe<Scalars['UUID']>;
  startAt: Scalars['Int'];
}>;

export type UpdateAutogeneratedQueueMutation = {
  __typename?: 'Mutation';
  updateNextUp?: {
    __typename?: 'UpdateNextUpPayload';
    query?: {
      __typename?: 'Query';
      allAutoGeneratedQueues?: {
        __typename?: 'AutoGeneratedQueuesConnection';
        nodes: Array<{
          __typename?: 'AutoGeneratedQueue';
          trackByTrackId?: {
            __typename?: 'Track';
            id: any;
            title?: string | null;
            likedAsListener?: boolean | null;
            duration?: number | null;
            streamCount?: number | null;
            price?: number | null;
            explicit?: boolean | null;
            isAvailable?: boolean | null;
            presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
            albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
            artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type UpdateNextUpMutationVariables = Exact<{
  album?: InputMaybe<Scalars['UUID']>;
  playlist?: InputMaybe<Scalars['UUID']>;
  startAt: Scalars['Int'];
  favourites?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateNextUpMutation = {
  __typename?: 'Mutation';
  updateNextUp?: {
    __typename?: 'UpdateNextUpPayload';
    autoGeneratedQueues?: Array<{
      __typename?: 'AutoGeneratedQueue';
      id: any;
      trackNumber: number;
      trackByTrackId?: {
        __typename?: 'Track';
        id: any;
        title?: string | null;
        likedAsListener?: boolean | null;
        duration?: number | null;
        streamCount?: number | null;
        price?: number | null;
        explicit?: boolean | null;
        isAvailable?: boolean | null;
        presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
        artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SearchHistoryQueryVariables = Exact<{[key: string]: never}>;

export type SearchHistoryQuery = {
  __typename?: 'Query';
  allSearchHistories?: {
    __typename?: 'SearchHistoriesConnection';
    nodes: Array<{__typename?: 'SearchHistory'; searchValue?: string | null} | null>;
  } | null;
};

export type AllFollowersQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']>;
  artistId?: InputMaybe<Scalars['UUID']>;
  searchString?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
}>;

export type AllFollowersQuery = {
  __typename?: 'Query';
  allFollowers?: {
    __typename?: 'FollowersConnection';
    edges: Array<{
      __typename?: 'FollowersEdge';
      node?: {
        __typename?: 'Follower';
        id?: any | null;
        type?: string | null;
        displayName?: string | null;
        presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
      } | null;
    }>;
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
  } | null;
};

export type AllFollowingsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']>;
  artistId?: InputMaybe<Scalars['UUID']>;
  searchString?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
}>;

export type AllFollowingsQuery = {
  __typename?: 'Query';
  allFollowings?: {
    __typename?: 'FollowingsConnection';
    edges: Array<{
      __typename?: 'FollowingsEdge';
      node?: {
        __typename?: 'Following';
        id?: any | null;
        type?: string | null;
        displayName?: string | null;
        presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
      } | null;
    }>;
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
  } | null;
};

export type DeleteAccountMutationVariables = Exact<{[key: string]: never}>;

export type DeleteAccountMutation = {
  __typename?: 'Mutation';
  deleteUser?: {__typename?: 'DeleteUserPayload'; clientMutationId?: string | null} | null;
};

export type GetCurrentEmailQueryVariables = Exact<{[key: string]: never}>;

export type GetCurrentEmailQuery = {__typename?: 'Query'; currentEmail?: string | null};

export type GetShareLinkMutationVariables = Exact<{
  id: Scalars['String'];
  itemType: AssetType;
}>;

export type GetShareLinkMutation = {__typename?: 'Mutation'; shareContent?: {__typename?: 'DeepLinkPayload'; link?: string | null} | null};

export type IsRegisteredWithSocialMediaQueryVariables = Exact<{[key: string]: never}>;

export type IsRegisteredWithSocialMediaQuery = {__typename?: 'Query'; isRegisteredWithSocialMedia?: boolean | null};

export type IsRegistrationCompletedQueryVariables = Exact<{[key: string]: never}>;

export type IsRegistrationCompletedQuery = {
  __typename?: 'Query';
  currentUser?: {__typename?: 'User'; registrationCompleted: boolean} | null;
};

export type IsUserVerifiedQueryVariables = Exact<{[key: string]: never}>;

export type IsUserVerifiedQuery = {__typename?: 'Query'; isUserVerified?: boolean | null};

export type MyProfileQueryVariables = Exact<{[key: string]: never}>;

export type MyProfileQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    artistsByUserId: {
      __typename?: 'ArtistsConnection';
      nodes: Array<{
        __typename?: 'Artist';
        userId?: any | null;
        description?: string | null;
        followingCount?: number | null;
        followerCount?: number | null;
        likedAsListener?: boolean | null;
        likedAsArtist?: boolean | null;
        facebook?: string | null;
        instagram?: string | null;
        twitter?: string | null;
        id: any;
        displayName?: string | null;
        blarecastUrl?: string | null;
        presignedCoverImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        genreByGenre?: {
          __typename?: 'Genre';
          id: any;
          parentCategory?: any | null;
          categoryName: string;
          genreByParentCategory?: {
            __typename?: 'Genre';
            id: any;
            parentCategory?: any | null;
            categoryName: string;
            presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          } | null;
          presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        } | null;
        userByUserId?: {
          __typename?: 'User';
          userVerificationByUserId?: {__typename?: 'UserVerification'; status?: VerificationStatus | null} | null;
        } | null;
        tracksByArtist: {
          __typename?: 'TracksConnection';
          pageInfo: {
            __typename?: 'PageInfo';
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: any | null;
            endCursor?: any | null;
          };
          edges: Array<{
            __typename?: 'TracksEdge';
            node?: {
              __typename?: 'Track';
              id: any;
              title?: string | null;
              likedAsListener?: boolean | null;
              duration?: number | null;
              streamCount?: number | null;
              price?: number | null;
              explicit?: boolean | null;
              isAvailable?: boolean | null;
              presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
              albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
              artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
            } | null;
          }>;
        };
        pinnedTracks: {
          __typename?: 'TracksConnection';
          edges: Array<{
            __typename?: 'TracksEdge';
            node?: {
              __typename?: 'Track';
              id: any;
              title?: string | null;
              likedAsListener?: boolean | null;
              duration?: number | null;
              streamCount?: number | null;
              price?: number | null;
              explicit?: boolean | null;
              isAvailable?: boolean | null;
              presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
              albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
              artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
            } | null;
          }>;
        };
        albumsByArtist: {
          __typename?: 'AlbumsConnection';
          pageInfo: {
            __typename?: 'PageInfo';
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: any | null;
            endCursor?: any | null;
          };
          edges: Array<{
            __typename?: 'AlbumsEdge';
            node?: {
              __typename?: 'Album';
              id: any;
              title?: string | null;
              description?: string | null;
              explicit?: boolean | null;
              presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
              artistByArtist?: {
                __typename?: 'Artist';
                id: any;
                displayName?: string | null;
                blarecastUrl?: string | null;
                followerCount?: number | null;
                presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
              } | null;
            } | null;
          }>;
        };
        artistMembersByArtistId: {
          __typename?: 'ArtistMembersConnection';
          nodes: Array<{
            __typename?: 'ArtistMember';
            id: any;
            artistId?: any | null;
            userId?: any | null;
            confirmed?: boolean | null;
            userByUserId?: {
              __typename?: 'User';
              profilePublicByUserId?: {
                __typename?: 'ProfilePublic';
                userId: any;
                displayName?: string | null;
                blarecastUrl?: string | null;
                presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
              } | null;
              userVerificationByUserId?: {__typename?: 'UserVerification'; status?: VerificationStatus | null} | null;
            } | null;
          } | null>;
        };
        eventsByArtistId: {
          __typename?: 'EventsConnection';
          pageInfo: {
            __typename?: 'PageInfo';
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: any | null;
            endCursor?: any | null;
          };
          edges: Array<{
            __typename?: 'EventsEdge';
            node?: {
              __typename?: 'Event';
              id: any;
              name: string;
              createdAt?: any | null;
              artistId: any;
              eventDate: any;
              place: string;
            } | null;
          }>;
        };
        presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
      } | null>;
    };
    playlistsByAuthor: {
      __typename?: 'PlaylistsConnection';
      nodes: Array<{
        __typename?: 'Playlist';
        id: any;
        title?: string | null;
        private?: boolean | null;
        likeCount?: number | null;
        presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
      } | null>;
    };
    userVerificationByUserId?: {__typename?: 'UserVerification'; status?: VerificationStatus | null} | null;
    profilePublicByUserId?: {
      __typename?: 'ProfilePublic';
      userId: any;
      blarecastUrl?: string | null;
      displayName?: string | null;
      personalBio?: string | null;
      followerCount?: number | null;
      followingCount?: number | null;
      facebook?: string | null;
      instagram?: string | null;
      twitter?: string | null;
      likedAsListener?: boolean | null;
      likedAsArtist?: boolean | null;
      presignedCoverImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
      presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    } | null;
    profileSettingByUserId?: {
      __typename?: 'ProfileSetting';
      userId: any;
      accountType?: AccountType | null;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userByUserId?: {__typename?: 'User'; notificationsByUserId: {__typename?: 'NotificationsConnection'; totalCount: number}} | null;
      countryByGeolocation?: {
        __typename?: 'Country';
        currenciesByCountry: {
          __typename?: 'CurrenciesConnection';
          edges: Array<{__typename?: 'CurrenciesEdge'; node?: {__typename?: 'Currency'; name: string; symbol?: string | null} | null}>;
        };
      } | null;
    } | null;
    artistMembersByUserId: {
      __typename?: 'ArtistMembersConnection';
      nodes: Array<{
        __typename?: 'ArtistMember';
        artistByArtistId?: {
          __typename?: 'Artist';
          id: any;
          displayName?: string | null;
          blarecastUrl?: string | null;
          followerCount?: number | null;
          presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type ProfileQueryVariables = Exact<{
  userId: Scalars['UUID'];
}>;

export type ProfileQuery = {
  __typename?: 'Query';
  profileSettingByUserId?: {
    __typename?: 'ProfileSetting';
    userId: any;
    accountType?: AccountType | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    userByUserId?: {
      __typename?: 'User';
      playlistsByAuthor: {
        __typename?: 'PlaylistsConnection';
        edges: Array<{
          __typename?: 'PlaylistsEdge';
          node?: {
            __typename?: 'Playlist';
            id: any;
            title?: string | null;
            private?: boolean | null;
            likeCount?: number | null;
            presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          } | null;
        }>;
      };
      userVerificationByUserId?: {__typename?: 'UserVerification'; status?: VerificationStatus | null} | null;
    } | null;
  } | null;
  profilePublicByUserId?: {
    __typename?: 'ProfilePublic';
    userId: any;
    blarecastUrl?: string | null;
    displayName?: string | null;
    personalBio?: string | null;
    followerCount?: number | null;
    followingCount?: number | null;
    facebook?: string | null;
    instagram?: string | null;
    twitter?: string | null;
    likedAsListener?: boolean | null;
    likedAsArtist?: boolean | null;
    presignedCoverImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
  } | null;
  allArtistMembers?: {
    __typename?: 'ArtistMembersConnection';
    nodes: Array<{
      __typename?: 'ArtistMember';
      artistByArtistId?: {
        __typename?: 'Artist';
        id: any;
        displayName?: string | null;
        blarecastUrl?: string | null;
        followerCount?: number | null;
        presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type ProfileByUserIdQueryVariables = Exact<{
  userId: Scalars['UUID'];
}>;

export type ProfileByUserIdQuery = {
  __typename?: 'Query';
  userById?: {
    __typename?: 'User';
    id: any;
    artistsByUserId: {
      __typename?: 'ArtistsConnection';
      nodes: Array<{
        __typename?: 'Artist';
        userId?: any | null;
        description?: string | null;
        followingCount?: number | null;
        followerCount?: number | null;
        likedAsListener?: boolean | null;
        likedAsArtist?: boolean | null;
        facebook?: string | null;
        instagram?: string | null;
        twitter?: string | null;
        id: any;
        displayName?: string | null;
        blarecastUrl?: string | null;
        presignedCoverImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        genreByGenre?: {__typename?: 'Genre'; id: any} | null;
        artistMembersByArtistId: {
          __typename?: 'ArtistMembersConnection';
          nodes: Array<{
            __typename?: 'ArtistMember';
            id: any;
            artistId?: any | null;
            userId?: any | null;
            confirmed?: boolean | null;
            userByUserId?: {
              __typename?: 'User';
              profilePublicByUserId?: {
                __typename?: 'ProfilePublic';
                userId: any;
                displayName?: string | null;
                blarecastUrl?: string | null;
                presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
              } | null;
              userVerificationByUserId?: {__typename?: 'UserVerification'; status?: VerificationStatus | null} | null;
            } | null;
          } | null>;
        };
        presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
      } | null>;
    };
    userVerificationByUserId?: {__typename?: 'UserVerification'; status?: VerificationStatus | null} | null;
    profileSettingByUserId?: {
      __typename?: 'ProfileSetting';
      userId: any;
      accountType?: AccountType | null;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    artistMembersByUserId: {
      __typename?: 'ArtistMembersConnection';
      nodes: Array<{
        __typename?: 'ArtistMember';
        artistByArtistId?: {
          __typename?: 'Artist';
          id: any;
          displayName?: string | null;
          blarecastUrl?: string | null;
          followerCount?: number | null;
          presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        } | null;
      } | null>;
    };
  } | null;
  profilePublicByUserId?: {
    __typename?: 'ProfilePublic';
    userId: any;
    blarecastUrl?: string | null;
    displayName?: string | null;
    personalBio?: string | null;
    followerCount?: number | null;
    followingCount?: number | null;
    facebook?: string | null;
    instagram?: string | null;
    twitter?: string | null;
    likedAsListener?: boolean | null;
    likedAsArtist?: boolean | null;
    presignedCoverImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
  } | null;
};

export type NotificationSettingsQueryQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type NotificationSettingsQueryQuery = {
  __typename?: 'Query';
  userById?: {
    __typename?: 'User';
    id: any;
    usersNotificationByUserId?: {
      __typename?: 'UsersNotification';
      userId: any;
      webRecommendedMusic?: boolean | null;
      webPlaylistUpdates?: boolean | null;
      webNewMusic?: boolean | null;
      webConcertReminder?: boolean | null;
      webBlareNewsAndOffers?: boolean | null;
      webArtistUpdate?: boolean | null;
      emailRecommendedMusic?: boolean | null;
      emailPlaylistUpdates?: boolean | null;
      emailNewMusic?: boolean | null;
      emailConcertReminder?: boolean | null;
      emailBlareNewsAndOffers?: boolean | null;
      emailArtistUpdate?: boolean | null;
    } | null;
  } | null;
};

export type UpdateNotificationSettingsMutationMutationVariables = Exact<{
  userId: Scalars['UUID'];
  newEmailRecommendedMusic: Scalars['Boolean'];
  newEmailNewMusic: Scalars['Boolean'];
  newEmailPlaylistUpdates: Scalars['Boolean'];
  newEmailArtistUpdate: Scalars['Boolean'];
  newEmailConcertReminder: Scalars['Boolean'];
  newEmailBlareNewsAndOffers: Scalars['Boolean'];
  newWebRecommendedMusic: Scalars['Boolean'];
  newWebNewMusic: Scalars['Boolean'];
  newWebPlaylistUpdates: Scalars['Boolean'];
  newWebArtistUpdate: Scalars['Boolean'];
  newWebConcertReminder: Scalars['Boolean'];
  newWebBlareNewsAndOffers: Scalars['Boolean'];
}>;

export type UpdateNotificationSettingsMutationMutation = {
  __typename?: 'Mutation';
  updateUsersNotificationByUserId?: {
    __typename?: 'UpdateUsersNotificationPayload';
    usersNotification?: {__typename?: 'UsersNotification'; userId: any} | null;
  } | null;
};

export type RemoveProfileCoverMutationVariables = Exact<{[key: string]: never}>;

export type RemoveProfileCoverMutation = {
  __typename?: 'Mutation';
  deleteCoverImage?: {__typename?: 'DeleteCoverImagePayload'; clientMutationId?: string | null} | null;
};

export type RemoveProfileImageMutationVariables = Exact<{[key: string]: never}>;

export type RemoveProfileImageMutation = {
  __typename?: 'Mutation';
  deleteProfileImage?: {__typename?: 'DeleteProfileImagePayload'; clientMutationId?: string | null} | null;
};

export type StatusQueryVariables = Exact<{[key: string]: never}>;

export type StatusQuery = {
  __typename?: 'Query';
  allNotifications?: {__typename?: 'NotificationsConnection'; totalCount: number} | null;
  currentUser?: {
    __typename?: 'User';
    profilePublicByUserId?: {
      __typename?: 'ProfilePublic';
      presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    } | null;
  } | null;
};

export type TopupTypesQueryVariables = Exact<{[key: string]: never}>;

export type TopupTypesQuery = {
  __typename?: 'Query';
  allListenerPackageTypes?: {
    __typename?: 'ListenerPackageTypesConnection';
    edges: Array<{
      __typename?: 'ListenerPackageTypesEdge';
      node?: {
        __typename?: 'ListenerPackageType';
        id: any;
        _packagePrice: number;
        packagePrice?: string | null;
        packageType: string;
        streamCharge: any;
        trialDays?: number | null;
        trialTracks?: number | null;
      } | null;
    }>;
  } | null;
};

export type UpdatePrivateProfileMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  userId: Scalars['UUID'];
}>;

export type UpdatePrivateProfileMutation = {
  __typename?: 'Mutation';
  updateProfileSettingByUserId?: {
    __typename?: 'UpdateProfileSettingPayload';
    profileSetting?: {
      __typename?: 'ProfileSetting';
      userId: any;
      accountType?: AccountType | null;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  } | null;
};

export type UpdatePublicProfileMutationVariables = Exact<{
  displayName?: InputMaybe<Scalars['String']>;
  personalBio?: InputMaybe<Scalars['String']>;
  facebook?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  twitter?: InputMaybe<Scalars['String']>;
  blarecastUrl?: InputMaybe<Scalars['String']>;
  userId: Scalars['UUID'];
}>;

export type UpdatePublicProfileMutation = {
  __typename?: 'Mutation';
  updateProfilePublicByUserId?: {
    __typename?: 'UpdateProfilePublicPayload';
    profilePublic?: {
      __typename?: 'ProfilePublic';
      userId: any;
      blarecastUrl?: string | null;
      displayName?: string | null;
      personalBio?: string | null;
      followerCount?: number | null;
      followingCount?: number | null;
      facebook?: string | null;
      instagram?: string | null;
      twitter?: string | null;
      likedAsListener?: boolean | null;
      likedAsArtist?: boolean | null;
      presignedCoverImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
      presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
    } | null;
  } | null;
};

export type UserByBlarecastUrlQueryVariables = Exact<{
  url: Scalars['String'];
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type UserByBlarecastUrlQuery = {
  __typename?: 'Query';
  searchBlarecastUrls?: {
    __typename?: 'BlarecastUrlsConnection';
    edges: Array<{
      __typename?: 'BlarecastUrlsEdge';
      node?: {
        __typename?: 'BlarecastUrl';
        userId: any;
        blarecastUrl: string;
        displayName?: string | null;
        presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
      } | null;
    }>;
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
  } | null;
};

export type UserProfilesQueryVariables = Exact<{
  userId: Scalars['UUID'];
}>;

export type UserProfilesQuery = {
  __typename?: 'Query';
  userById?: {
    __typename?: 'User';
    profilePublicByUserId?: {__typename?: 'ProfilePublic'; displayName?: string | null} | null;
    artistsByUserId: {__typename?: 'ArtistsConnection'; nodes: Array<{__typename?: 'Artist'; id: any; displayName?: string | null} | null>};
  } | null;
};

export type IsLikedQueryVariables = Exact<{
  actingArtistId?: InputMaybe<Scalars['UUID']>;
  likedUserId?: InputMaybe<Scalars['UUID']>;
  artistId?: InputMaybe<Scalars['UUID']>;
  trackId?: InputMaybe<Scalars['UUID']>;
  playlistId?: InputMaybe<Scalars['UUID']>;
  albumId?: InputMaybe<Scalars['UUID']>;
  userId?: InputMaybe<Scalars['UUID']>;
}>;

export type IsLikedQuery = {
  __typename?: 'Query';
  allLikes?: {
    __typename?: 'LikesConnection';
    edges: Array<{
      __typename?: 'LikesEdge';
      node?: {
        __typename?: 'Like';
        id: any;
        actingArtistId?: any | null;
        artistId?: any | null;
        albumId?: any | null;
        likedUserId?: any | null;
        playlistId?: any | null;
        trackId?: any | null;
        userId?: any | null;
      } | null;
    }>;
  } | null;
};

export type CreatePlaylistWebMutationVariables = Exact<{
  title?: InputMaybe<Scalars['String']>;
  private?: InputMaybe<Scalars['Boolean']>;
}>;

export type CreatePlaylistWebMutation = {
  __typename?: 'Mutation';
  createPlaylist?: {
    __typename?: 'CreatePlaylistPayload';
    playlist?: {
      __typename?: 'Playlist';
      id: any;
      private?: boolean | null;
      title?: string | null;
      likeCount?: number | null;
      presignedImgs?: Array<{__typename?: 'PresignedImage'; size?: number | null; url?: string | null} | null> | null;
    } | null;
  } | null;
};

export type UpdatePlaylistByIdMutationVariables = Exact<{
  id: Scalars['UUID'];
  title?: InputMaybe<Scalars['String']>;
  private?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdatePlaylistByIdMutation = {
  __typename?: 'Mutation';
  updatePlaylistById?: {
    __typename?: 'UpdatePlaylistPayload';
    playlist?: {__typename?: 'Playlist'; id: any; private?: boolean | null; title?: string | null} | null;
  } | null;
};

export type RemovePlaylistMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type RemovePlaylistMutation = {
  __typename?: 'Mutation';
  deletePlaylistById?: {__typename?: 'DeletePlaylistPayload'; deletedPlaylistId?: string | null} | null;
};

export type AllPlaylistsQueryQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']>;
}>;

export type AllPlaylistsQueryQuery = {
  __typename?: 'Query';
  allPlaylists?: {
    __typename?: 'PlaylistsConnection';
    edges: Array<{
      __typename?: 'PlaylistsEdge';
      node?: {
        __typename?: 'Playlist';
        id: any;
        private?: boolean | null;
        title?: string | null;
        likeCount?: number | null;
        presignedImgs?: Array<{__typename?: 'PresignedImage'; size?: number | null; url?: string | null} | null> | null;
      } | null;
    }>;
  } | null;
};

export type PlaylistByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type PlaylistByIdQuery = {
  __typename?: 'Query';
  playlistById?: {__typename?: 'Playlist'; private?: boolean | null; title?: string | null} | null;
};

export type PlaylistDetailsWebQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type PlaylistDetailsWebQuery = {
  __typename?: 'Query';
  playlistById?: {
    __typename?: 'Playlist';
    id: any;
    title?: string | null;
    img?: string | null;
    private?: boolean | null;
    likedAsListener?: boolean | null;
    presignedImgs?: Array<{__typename?: 'PresignedImage'; size?: number | null; url?: string | null} | null> | null;
    userByAuthor?: {
      __typename?: 'User';
      id: any;
      profilePublicByUserId?: {__typename?: 'ProfilePublic'; userId: any; displayName?: string | null} | null;
    } | null;
    likesByPlaylistId: {__typename?: 'LikesConnection'; totalCount: number};
    playlistsTracksByPlaylistId: {
      __typename?: 'PlaylistsTracksConnection';
      pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
      edges: Array<{
        __typename?: 'PlaylistsTracksEdge';
        node?: {
          __typename?: 'PlaylistsTrack';
          trackByTrackId?: {
            __typename?: 'Track';
            id: any;
            title?: string | null;
            likedAsListener?: boolean | null;
            duration?: number | null;
            streamCount?: number | null;
            price?: number | null;
            explicit?: boolean | null;
            isAvailable?: boolean | null;
            tracksArtistsByTrackId: {
              __typename?: 'TracksArtistsConnection';
              edges: Array<{
                __typename?: 'TracksArtistsEdge';
                node?: {
                  __typename?: 'TracksArtist';
                  artistByArtistId?: {
                    __typename?: 'Artist';
                    id: any;
                    displayName?: string | null;
                    blarecastUrl?: string | null;
                    followerCount?: number | null;
                    presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
                  } | null;
                } | null;
              }>;
            };
            presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
            albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
            artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
          } | null;
        } | null;
      }>;
    };
  } | null;
};

export type AddToPlaylistWebMutationVariables = Exact<{
  playlist: Scalars['UUID'];
  track: Scalars['UUID'];
}>;

export type AddToPlaylistWebMutation = {
  __typename?: 'Mutation';
  addTrackToPlaylist?: {__typename?: 'AddTrackToPlaylistPayload'; clientMutationId?: string | null} | null;
};

export type RemoveFromPlaylistWebMutationVariables = Exact<{
  playlist: Scalars['UUID'];
  track: Scalars['UUID'];
}>;

export type RemoveFromPlaylistWebMutation = {
  __typename?: 'Mutation';
  removeTrackFromPlaylist?: {__typename?: 'RemoveTrackFromPlaylistPayload'; clientMutationId?: string | null} | null;
};

export type AllLikedPlaylistsQueryVariables = Exact<{
  userId: Scalars['UUID'];
}>;

export type AllLikedPlaylistsQuery = {
  __typename?: 'Query';
  allLikes?: {
    __typename?: 'LikesConnection';
    edges: Array<{
      __typename?: 'LikesEdge';
      node?: {
        __typename?: 'Like';
        id: any;
        playlistByPlaylistId?: {
          __typename?: 'Playlist';
          id: any;
          title?: string | null;
          likeCount?: number | null;
          presignedImgs?: Array<{__typename?: 'PresignedImage'; size?: number | null; url?: string | null} | null> | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type MyEarningsQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type MyEarningsQuery = {
  __typename?: 'Query';
  myEarnings?: {
    __typename?: 'MyEarnings';
    earnings?: any | null;
    totalWithdrawn?: any | null;
    totalEarnings?: any | null;
    rows?: Array<{__typename?: 'EarningsHistoryRow'; amount?: any | null; createdAt?: any | null} | null> | null;
  } | null;
};

export type MyStreamingsQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type MyStreamingsQuery = {
  __typename?: 'Query';
  myStreams?: {
    __typename?: 'MyStreams';
    streams?: any | null;
    maxUploadTime?: number | null;
    avgListeners?: any | null;
    availableUploadTime?: number | null;
    rows?: Array<{__typename?: 'StreamsHistoryRow'; createdAt?: any | null; streams?: any | null} | null> | null;
  } | null;
};

export type AccountBalanceConsumptionQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type AccountBalanceConsumptionQuery = {
  __typename?: 'Query';
  accountBalanceConsumption?: {
    __typename?: 'AccountBalanceConsumptionPayload';
    accountBalance?: {__typename?: 'AccountBalancePayload'; avgMonthConsumption?: number | null; balance?: number | null} | null;
    rows?: Array<{__typename?: 'AccountBalanceConsumption'; consumption?: number | null; createdAt?: any | null} | null> | null;
  } | null;
};

export type AllEarningsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']>;
  trackId?: InputMaybe<Scalars['UUID']>;
  from: Scalars['Datetime'];
  to: Scalars['Datetime'];
}>;

export type AllEarningsQuery = {
  __typename?: 'Query';
  allEarningsHistories?: {
    __typename?: 'EarningsHistoriesConnection';
    edges: Array<{
      __typename?: 'EarningsHistoriesEdge';
      node?: {__typename?: 'EarningsHistory'; id: any; createdAt: any; amount: any} | null;
    }>;
  } | null;
};

export type AllStreamsQueryVariables = Exact<{
  trackId?: InputMaybe<Scalars['UUID']>;
  from: Scalars['Datetime'];
  to: Scalars['Datetime'];
}>;

export type AllStreamsQuery = {
  __typename?: 'Query';
  allStreamsHistories?: {
    __typename?: 'StreamsHistoriesConnection';
    edges: Array<{
      __typename?: 'StreamsHistoriesEdge';
      node?: {__typename?: 'StreamsHistory'; id: any; createdAt: any; streams: any} | null;
    }>;
  } | null;
};

export type OwnershipsQueryVariables = Exact<{
  myUserId?: InputMaybe<Scalars['UUID']>;
  isArtistMember?: InputMaybe<Scalars['Boolean']>;
  isCeltzer?: InputMaybe<Scalars['Boolean']>;
  fromMyArtist?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['Cursor']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type OwnershipsQuery = {
  __typename?: 'Query';
  ownerships?: {
    __typename?: 'RoyaltiesConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'RoyaltiesEdge';
      node?: {
        __typename?: 'Royalty';
        id: any;
        trackByTrackId?: {
          __typename?: 'Track';
          id: any;
          title?: string | null;
          img?: string | null;
          presignedImgs?: Array<{__typename?: 'PresignedImage'; size?: number | null; url?: string | null} | null> | null;
          royaltiesByTrackId: {
            __typename?: 'RoyaltiesConnection';
            edges: Array<{
              __typename?: 'RoyaltiesEdge';
              node?: {__typename?: 'Royalty'; id: any; percentage: any; userId?: any | null} | null;
            }>;
          };
          albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type AllTracksByArtistIdQueryVariables = Exact<{
  artistId?: InputMaybe<Scalars['UUID']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TracksOrderBy> | TracksOrderBy>;
  albumId?: InputMaybe<Scalars['UUID']>;
  after?: InputMaybe<Scalars['Cursor']>;
}>;

export type AllTracksByArtistIdQuery = {
  __typename?: 'Query';
  allTracks?: {
    __typename?: 'TracksConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'TracksEdge';
      node?: {
        __typename?: 'Track';
        pinnedAt?: any | null;
        private?: boolean | null;
        id: any;
        title?: string | null;
        likedAsListener?: boolean | null;
        duration?: number | null;
        streamCount?: number | null;
        price?: number | null;
        explicit?: boolean | null;
        isAvailable?: boolean | null;
        albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
        presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
      } | null;
    }>;
  } | null;
};

export type ToggleTrackPinMutationVariables = Exact<{
  trackId: Scalars['UUID'];
}>;

export type ToggleTrackPinMutation = {
  __typename?: 'Mutation';
  toggleTrackPin?: {
    __typename?: 'ToggleTrackPinPayload';
    clientMutationId?: string | null;
    query?: {
      __typename?: 'Query';
      trackById?: {
        __typename?: 'Track';
        pinnedAt?: any | null;
        id: any;
        title?: string | null;
        likedAsListener?: boolean | null;
        duration?: number | null;
        streamCount?: number | null;
        price?: number | null;
        explicit?: boolean | null;
        isAvailable?: boolean | null;
        presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
        albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
        artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
      } | null;
    } | null;
  } | null;
};

export type FavoriteTracksQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']>;
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type FavoriteTracksQuery = {
  __typename?: 'Query';
  allLikes?: {
    __typename?: 'LikesConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'LikesEdge';
      node?: {
        __typename?: 'Like';
        trackByTrackId?: {
          __typename?: 'Track';
          id: any;
          title?: string | null;
          likedAsListener?: boolean | null;
          duration?: number | null;
          streamCount?: number | null;
          price?: number | null;
          explicit?: boolean | null;
          isAvailable?: boolean | null;
          presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
          albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
          artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type TrackTileOptionsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type TrackTileOptionsQuery = {
  __typename?: 'Query';
  trackById?: {__typename?: 'Track'; id: any; likedAsListener?: boolean | null} | null;
};

export type TrackTotalEarningsQueryVariables = Exact<{
  trackId: Scalars['UUID'];
}>;

export type TrackTotalEarningsQuery = {__typename?: 'Query'; trackTotalEarnings?: any | null};

export type GetCurrentArtistQueryVariables = Exact<{
  currentUserID?: InputMaybe<Scalars['UUID']>;
}>;

export type GetCurrentArtistQuery = {
  __typename?: 'Query';
  allArtists?: {
    __typename?: 'ArtistsConnection';
    edges: Array<{__typename?: 'ArtistsEdge'; node?: {__typename?: 'Artist'; id: any} | null}>;
  } | null;
};

export type CreateEmptyTrackMutationVariables = Exact<{
  artistID: Scalars['UUID'];
  title?: InputMaybe<Scalars['String']>;
}>;

export type CreateEmptyTrackMutation = {
  __typename?: 'Mutation';
  createTrack?: {__typename?: 'CreateTrackPayload'; track?: {__typename?: 'Track'; id: any; title?: string | null} | null} | null;
};

export type UpdateTrackMutationVariables = Exact<{
  trackID: Scalars['UUID'];
  title?: InputMaybe<Scalars['String']>;
  albumID?: InputMaybe<Scalars['UUID']>;
  tags?: InputMaybe<Scalars['String']>;
  releaseDate?: InputMaybe<Scalars['Datetime']>;
  private?: InputMaybe<Scalars['Boolean']>;
  genreID?: InputMaybe<Scalars['UUID']>;
  explicit?: InputMaybe<Scalars['Boolean']>;
  artistID?: InputMaybe<Scalars['UUID']>;
}>;

export type UpdateTrackMutation = {
  __typename?: 'Mutation';
  updateTrackById?: {
    __typename?: 'UpdateTrackPayload';
    clientMutationId?: string | null;
    track?: {
      __typename?: 'Track';
      id: any;
      title?: string | null;
      likedAsListener?: boolean | null;
      duration?: number | null;
      streamCount?: number | null;
      price?: number | null;
      explicit?: boolean | null;
      isAvailable?: boolean | null;
      presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
      albumByAlbum?: {__typename?: 'Album'; id: any; title?: string | null} | null;
      artistByArtist?: {__typename?: 'Artist'; id: any; displayName?: string | null} | null;
    } | null;
  } | null;
};

export type CreateTrackPriceMutationVariables = Exact<{
  trackID: Scalars['UUID'];
  amount: Scalars['Int'];
}>;

export type CreateTrackPriceMutation = {
  __typename?: 'Mutation';
  createPrice?: {__typename?: 'CreatePricePayload'; clientMutationId?: string | null} | null;
};

export type SearchCollaboratorsQueryVariables = Exact<{
  searchString?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  afterArtistCursor?: InputMaybe<Scalars['Cursor']>;
  afterUserCursor?: InputMaybe<Scalars['Cursor']>;
}>;

export type SearchCollaboratorsQuery = {
  __typename?: 'Query';
  allArtists?: {
    __typename?: 'ArtistsConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'ArtistsEdge';
      node?: {
        __typename?: 'Artist';
        id: any;
        displayName?: string | null;
        blarecastUrl?: string | null;
        img?: string | null;
        presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; size?: number | null; url?: string | null} | null> | null;
      } | null;
    }>;
  } | null;
  allProfilePublics?: {
    __typename?: 'ProfilePublicsConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: any | null; endCursor?: any | null};
    edges: Array<{
      __typename?: 'ProfilePublicsEdge';
      node?: {
        __typename?: 'ProfilePublic';
        userId: any;
        displayName?: string | null;
        blarecastUrl?: string | null;
        profileImg?: string | null;
        presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; size?: number | null; url?: string | null} | null> | null;
      } | null;
    }>;
  } | null;
};

export type CreateRoyaltiesMutationVariables = Exact<{
  trackId: Scalars['UUID'];
  percentages: Array<InputMaybe<UserPercentageInput>> | InputMaybe<UserPercentageInput>;
}>;

export type CreateRoyaltiesMutation = {
  __typename?: 'Mutation';
  createRoyalties?: {__typename?: 'CreateRoyaltiesPayload'; clientMutationId?: string | null} | null;
};

export type TrackRoyaltiesQueryVariables = Exact<{
  trackId: Scalars['UUID'];
}>;

export type TrackRoyaltiesQuery = {
  __typename?: 'Query';
  allRoyalties?: {
    __typename?: 'RoyaltiesConnection';
    edges: Array<{
      __typename?: 'RoyaltiesEdge';
      node?: {
        __typename?: 'Royalty';
        id: any;
        userId?: any | null;
        artistMemberId?: any | null;
        percentage: any;
        celtzer?: boolean | null;
      } | null;
    }>;
  } | null;
};

export type ArtistMembersQueryVariables = Exact<{
  artistId: Scalars['UUID'];
}>;

export type ArtistMembersQuery = {
  __typename?: 'Query';
  allArtistMembers?: {
    __typename?: 'ArtistMembersConnection';
    edges: Array<{
      __typename?: 'ArtistMembersEdge';
      node?: {
        __typename?: 'ArtistMember';
        id: any;
        userByUserId?: {
          __typename?: 'User';
          id: any;
          profilePublicByUserId?: {
            __typename?: 'ProfilePublic';
            userId: any;
            blarecastUrl?: string | null;
            displayName?: string | null;
            profileImg?: string | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type ArtistMemberByIdQueryVariables = Exact<{
  artistMemberId: Scalars['UUID'];
}>;

export type ArtistMemberByIdQuery = {
  __typename?: 'Query';
  artistMemberById?: {
    __typename?: 'ArtistMember';
    artistByArtistId?: {
      __typename?: 'Artist';
      blarecastUrl?: string | null;
      img?: string | null;
      id: any;
      displayName?: string | null;
      presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; size?: number | null; url?: string | null} | null> | null;
    } | null;
  } | null;
};

export type DeleteTrackMutationVariables = Exact<{
  trackID: Scalars['UUID'];
}>;

export type DeleteTrackMutation = {
  __typename?: 'Mutation';
  deleteTrackById?: {__typename?: 'DeleteTrackPayload'; deletedTrackId?: string | null} | null;
};

export type CreateAlbumMutationVariables = Exact<{
  title?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['String']>;
  releaseDate: Scalars['Datetime'];
  description?: InputMaybe<Scalars['String']>;
  genreID?: InputMaybe<Scalars['UUID']>;
  artistID?: InputMaybe<Scalars['UUID']>;
}>;

export type CreateAlbumMutation = {
  __typename?: 'Mutation';
  createAlbum?: {
    __typename?: 'CreateAlbumPayload';
    clientMutationId?: string | null;
    album?: {__typename?: 'Album'; id: any} | null;
  } | null;
};

export type DeleteAlbumMutationVariables = Exact<{
  albumId: Scalars['UUID'];
}>;

export type DeleteAlbumMutation = {
  __typename?: 'Mutation';
  deleteAlbumById?: {__typename?: 'DeleteAlbumPayload'; clientMutationId?: string | null} | null;
};

export type UpdateAlbumMutationVariables = Exact<{
  albumID: Scalars['UUID'];
  title?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['String']>;
  releaseDate: Scalars['Datetime'];
  description?: InputMaybe<Scalars['String']>;
  genreID?: InputMaybe<Scalars['UUID']>;
}>;

export type UpdateAlbumMutation = {
  __typename?: 'Mutation';
  updateAlbumById?: {
    __typename?: 'UpdateAlbumPayload';
    clientMutationId?: string | null;
    album?: {
      __typename?: 'Album';
      id: any;
      title?: string | null;
      description?: string | null;
      explicit?: boolean | null;
      presignedImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
      artistByArtist?: {
        __typename?: 'Artist';
        id: any;
        displayName?: string | null;
        blarecastUrl?: string | null;
        followerCount?: number | null;
        presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; url?: string | null; size?: number | null} | null> | null;
      } | null;
    } | null;
  } | null;
};

export type CurrentUserQueryQueryVariables = Exact<{[key: string]: never}>;

export type CurrentUserQueryQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    id: any;
    registrationCompleted: boolean;
    registration?: {
      __typename?: 'UserRegistration';
      details: Array<UserRegistrationRequirementsEnum | null>;
      requirements: {__typename?: 'UserRegistrationRequirements'; genre: boolean; information: boolean; kyc: boolean; payment: boolean};
    } | null;
    profilePublicByUserId?: {
      __typename?: 'ProfilePublic';
      userId: any;
      displayName?: string | null;
      blarecastUrl?: string | null;
      profileImg?: string | null;
      presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; size?: number | null; url?: string | null} | null> | null;
    } | null;
    artistsByUserId: {
      __typename?: 'ArtistsConnection';
      edges: Array<{
        __typename?: 'ArtistsEdge';
        node?: {
          __typename?: 'Artist';
          id: any;
          img?: string | null;
          displayName?: string | null;
          blarecastUrl?: string | null;
          genre?: any | null;
          presignedProfileImgs?: Array<{__typename?: 'PresignedImage'; size?: number | null; url?: string | null} | null> | null;
          artistSubscriptionsByArtistId: {
            __typename?: 'ArtistSubscriptionsConnection';
            nodes: Array<{
              __typename?: 'ArtistSubscription';
              id: any;
              subscriptionType: any;
              billingInterval?: BillingInterval | null;
              nextBillingDate?: any | null;
              refund?: {__typename?: 'ArtistSubscriptionRefund'; applicable: boolean} | null;
              artistSubscriptionByDowngradeTo?: {
                __typename?: 'ArtistSubscription';
                id: any;
                billingInterval?: BillingInterval | null;
                nextBillingDate?: any | null;
                subscriptionType: any;
              } | null;
            } | null>;
          };
        } | null;
      }>;
    };
    userVerificationByUserId?: {__typename?: 'UserVerification'; status?: VerificationStatus | null} | null;
    listenerPackagesByUserId: {
      __typename?: 'ListenerPackagesConnection';
      edges: Array<{
        __typename?: 'ListenerPackagesEdge';
        node?: {
          __typename?: 'ListenerPackage';
          id: any;
          active?: boolean | null;
          hasFreeTrial?: boolean | null;
          canceledAt?: any | null;
          createdAt?: any | null;
          packageType: any;
        } | null;
      }>;
    };
  } | null;
};

export type ActiveListenerPackageQueryVariables = Exact<{
  userId: Scalars['UUID'];
}>;

export type ActiveListenerPackageQuery = {
  __typename?: 'Query';
  userById?: {
    __typename?: 'User';
    id: any;
    listenerPackagesByUserId: {
      __typename?: 'ListenerPackagesConnection';
      edges: Array<{
        __typename?: 'ListenerPackagesEdge';
        node?: {
          __typename?: 'ListenerPackage';
          id: any;
          active?: boolean | null;
          hasFreeTrial?: boolean | null;
          canceledAt?: any | null;
          createdAt?: any | null;
          packageType: any;
        } | null;
      }>;
    };
  } | null;
};

export type SetBlarecastUrlAndDisplayNameMutationVariables = Exact<{
  displayName?: InputMaybe<Scalars['String']>;
  blarecastUrl?: InputMaybe<Scalars['String']>;
}>;

export type SetBlarecastUrlAndDisplayNameMutation = {
  __typename?: 'Mutation';
  setBlarecastData?: {__typename?: 'SetBlarecastDataPayload'; clientMutationId?: string | null} | null;
};

export type PaymentHistoryQueryVariables = Exact<{[key: string]: never}>;

export type PaymentHistoryQuery = {
  __typename?: 'Query';
  paymentHistory?: Array<{
    __typename?: 'PaymentHistory';
    amount?: number | null;
    createdAt?: any | null;
    description?: string | null;
    type?: PaymentHistoryType | null;
  } | null> | null;
};

export const ImageFragmentFragmentDoc = gql`
  fragment ImageFragment on PresignedImage {
    url
    size
  }
`;
export const ArtistFragmentFragmentDoc = gql`
  fragment ArtistFragment on Artist {
    id
    displayName
    blarecastUrl
    followerCount
    presignedProfileImgs {
      ...ImageFragment
    }
  }
  ${ImageFragmentFragmentDoc}
`;
export const GenreFragmentFragmentDoc = gql`
  fragment GenreFragment on Genre {
    id
    parentCategory
    categoryName
    presignedImgs {
      ...ImageFragment
    }
  }
  ${ImageFragmentFragmentDoc}
`;
export const PaginationFragmentFragmentDoc = gql`
  fragment PaginationFragment on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;
export const TrackFragmentFragmentDoc = gql`
  fragment TrackFragment on Track {
    id
    title
    likedAsListener
    duration
    streamCount
    price
    explicit
    isAvailable
    presignedImgs {
      ...ImageFragment
    }
    albumByAlbum {
      id
      title
    }
    artistByArtist {
      id
      displayName
    }
  }
  ${ImageFragmentFragmentDoc}
`;
export const AlbumFragmentFragmentDoc = gql`
  fragment AlbumFragment on Album {
    id
    title
    description
    explicit
    presignedImgs {
      ...ImageFragment
    }
    artistByArtist {
      ...ArtistFragment
    }
  }
  ${ImageFragmentFragmentDoc}
  ${ArtistFragmentFragmentDoc}
`;
export const ArtistMemberFragmentFragmentDoc = gql`
  fragment ArtistMemberFragment on ArtistMember {
    id
    artistId
    userId
    confirmed
    userByUserId {
      profilePublicByUserId {
        userId
        displayName
        blarecastUrl
        presignedProfileImgs {
          ...ImageFragment
        }
      }
      userVerificationByUserId {
        status
      }
    }
  }
  ${ImageFragmentFragmentDoc}
`;
export const EventFragmentFragmentDoc = gql`
  fragment EventFragment on Event {
    id
    name
    createdAt
    artistId
    eventDate
    place
  }
`;
export const ArtistDetailsFragmentFragmentDoc = gql`
  fragment ArtistDetailsFragment on Artist {
    ...ArtistFragment
    userId
    description
    followingCount
    followerCount
    likedAsListener
    likedAsArtist
    facebook
    instagram
    twitter
    presignedCoverImgs {
      ...ImageFragment
    }
    genreByGenre {
      ...GenreFragment
      genreByParentCategory {
        ...GenreFragment
      }
    }
    userByUserId {
      userVerificationByUserId {
        status
      }
    }
    tracksByArtist {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          ...TrackFragment
        }
      }
    }
    pinnedTracks(first: 10) {
      edges {
        node {
          ...TrackFragment
        }
      }
    }
    albumsByArtist {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          ...AlbumFragment
        }
      }
    }
    artistMembersByArtistId {
      nodes {
        ...ArtistMemberFragment
      }
    }
    eventsByArtistId {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          ...EventFragment
        }
      }
    }
  }
  ${ArtistFragmentFragmentDoc}
  ${ImageFragmentFragmentDoc}
  ${GenreFragmentFragmentDoc}
  ${PaginationFragmentFragmentDoc}
  ${TrackFragmentFragmentDoc}
  ${AlbumFragmentFragmentDoc}
  ${ArtistMemberFragmentFragmentDoc}
  ${EventFragmentFragmentDoc}
`;
export const AutoGeneratedQueueFragmentFragmentDoc = gql`
  fragment AutoGeneratedQueueFragment on AutoGeneratedQueue {
    id
    listened
    trackByTrackId {
      ...TrackFragment
    }
  }
  ${TrackFragmentFragmentDoc}
`;
export const LikeFragmentFragmentDoc = gql`
  fragment LikeFragment on Like {
    id
    artistId
    likedUserId
    albumId
    trackId
    playlistId
    actingArtistId
  }
`;
export const PlaylistFragmentFragmentDoc = gql`
  fragment PlaylistFragment on Playlist {
    id
    title
    private
    likeCount
    presignedImgs {
      ...ImageFragment
    }
  }
  ${ImageFragmentFragmentDoc}
`;
export const PrivateProfileFragmentFragmentDoc = gql`
  fragment PrivateProfileFragment on ProfileSetting {
    userId
    accountType
    email
    firstName
    lastName
  }
`;
export const PublicProfileFragmentFragmentDoc = gql`
  fragment PublicProfileFragment on ProfilePublic {
    userId
    blarecastUrl
    displayName
    personalBio
    followerCount
    followingCount
    facebook
    instagram
    twitter
    likedAsListener
    likedAsArtist
    presignedCoverImgs {
      ...ImageFragment
    }
    presignedProfileImgs {
      ...ImageFragment
    }
  }
  ${ImageFragmentFragmentDoc}
`;
export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on User {
    id
    profilePublicByUserId {
      userId
      displayName
    }
  }
`;
export const UsersQueueFragmentFragmentDoc = gql`
  fragment UsersQueueFragment on UsersQueue {
    id
    trackByTrackId {
      ...TrackFragment
    }
  }
  ${TrackFragmentFragmentDoc}
`;
export const AllDocumentsDocument = gql`
  query AllDocuments($slug: String) {
    allDocuments(condition: {slug: $slug}) {
      edges {
        node {
          id
          content
          slug
          title
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AllDocumentsGQL extends Apollo.Query<AllDocumentsQuery, AllDocumentsQueryVariables> {
  document = AllDocumentsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AutoTopupDocument = gql`
  query AutoTopup {
    currentUser {
      id
      profileSettingByUserId {
        userId
        topUp
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AutoTopupGQL extends Apollo.Query<AutoTopupQuery, AutoTopupQueryVariables> {
  document = AutoTopupDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateAutoTopupDocument = gql`
  mutation UpdateAutoTopup($userId: UUID!, $autoTopup: Boolean) {
    updateProfileSettingByUserId(input: {profileSettingPatch: {topUp: $autoTopup}, userId: $userId}) {
      profileSetting {
        topUp
        userId
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateAutoTopupGQL extends Apollo.Mutation<UpdateAutoTopupMutation, UpdateAutoTopupMutationVariables> {
  document = UpdateAutoTopupDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllAlbumsByIdDocument = gql`
  query AllAlbumsById($artistId: UUID!, $limit: Int = 20, $orderBy: [AlbumsOrderBy!] = [CREATED_AT_DESC], $after: Cursor = null) {
    allAlbums(first: $limit, orderBy: $orderBy, condition: {artist: $artistId}, after: $after) {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          ...AlbumFragment
          tracksByAlbum {
            edges {
              node {
                id
                streamCount
              }
            }
          }
        }
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
  ${AlbumFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AllAlbumsByIdGQL extends Apollo.Query<AllAlbumsByIdQuery, AllAlbumsByIdQueryVariables> {
  document = AllAlbumsByIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AlbumTotalEarningsDocument = gql`
  query albumTotalEarnings($albumId: UUID!) {
    albumTotalEarnings(albumId: $albumId)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AlbumTotalEarningsGQL extends Apollo.Query<AlbumTotalEarningsQuery, AlbumTotalEarningsQueryVariables> {
  document = AlbumTotalEarningsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateEventDocument = gql`
  mutation CreateEvent($name: String!, $eventDate: Datetime!, $artistId: UUID!, $place: String!) {
    createEvent(input: {event: {artistId: $artistId, place: $place, name: $name, eventDate: $eventDate}}) {
      event {
        ...EventFragment
      }
    }
  }
  ${EventFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CreateEventGQL extends Apollo.Mutation<CreateEventMutation, CreateEventMutationVariables> {
  document = CreateEventDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateEventDocument = gql`
  mutation UpdateEvent($name: String!, $eventDate: Datetime!, $eventId: UUID!, $place: String!) {
    updateEventById(input: {eventPatch: {place: $place, name: $name, eventDate: $eventDate}, id: $eventId}) {
      event {
        ...EventFragment
      }
    }
  }
  ${EventFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateEventGQL extends Apollo.Mutation<UpdateEventMutation, UpdateEventMutationVariables> {
  document = UpdateEventDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteEventDocument = gql`
  mutation DeleteEvent($eventId: UUID!) {
    deleteEventById(input: {id: $eventId}) {
      event {
        ...EventFragment
      }
    }
  }
  ${EventFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteEventGQL extends Apollo.Mutation<DeleteEventMutation, DeleteEventMutationVariables> {
  document = DeleteEventDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FutureEventsByArtistIdDocument = gql`
  query FutureEventsByArtistId($artistId: UUID!, $fromDate: Datetime!, $after: Cursor = null, $pageSize: Int = 20) {
    allEvents(
      condition: {artistId: $artistId}
      orderBy: EVENT_DATE_ASC
      filter: {eventDate: {greaterThan: $fromDate}}
      first: $pageSize
      after: $after
    ) {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          ...EventFragment
        }
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
  ${EventFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class FutureEventsByArtistIdGQL extends Apollo.Query<FutureEventsByArtistIdQuery, FutureEventsByArtistIdQueryVariables> {
  document = FutureEventsByArtistIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PastEventsByArtistIdDocument = gql`
  query PastEventsByArtistId($artistId: UUID!, $toDate: Datetime!, $after: Cursor = null, $pageSize: Int = 20) {
    allEvents(
      condition: {artistId: $artistId}
      orderBy: EVENT_DATE_ASC
      filter: {eventDate: {lessThanOrEqualTo: $toDate}}
      first: $pageSize
      after: $after
    ) {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          ...EventFragment
        }
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
  ${EventFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class PastEventsByArtistIdGQL extends Apollo.Query<PastEventsByArtistIdQuery, PastEventsByArtistIdQueryVariables> {
  document = PastEventsByArtistIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const EventByIdDocument = gql`
  query EventById($eventId: UUID!) {
    eventById(id: $eventId) {
      artistByArtistId {
        displayName
        blarecastUrl
        id
      }
      ...EventFragment
    }
  }
  ${EventFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class EventByIdGQL extends Apollo.Query<EventByIdQuery, EventByIdQueryVariables> {
  document = EventByIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAllGenresDocument = gql`
  query getAllGenres {
    allGenres {
      edges {
        node {
          id
          parentCategory
          categoryName
          img
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAllGenresGQL extends Apollo.Query<GetAllGenresQuery, GetAllGenresQueryVariables> {
  document = GetAllGenresDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetGenreDetailsDocument = gql`
  query getGenreDetails($genreID: UUID!, $after: Cursor = null, $before: Cursor = null, $pageSize: Int = 20) {
    genreById(id: $genreID) {
      tracksByGenre(first: $pageSize, after: $after, before: $before) {
        edges {
          node {
            ...TrackFragment
          }
        }
        pageInfo {
          ...PaginationFragment
        }
      }
      categoryName
      id
      img
    }
  }
  ${TrackFragmentFragmentDoc}
  ${PaginationFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetGenreDetailsGQL extends Apollo.Query<GetGenreDetailsQuery, GetGenreDetailsQueryVariables> {
  document = GetGenreDetailsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ActiveArtistSubscriptionDocument = gql`
  query ActiveArtistSubscription($artistid: UUID!) {
    artistById(id: $artistid) {
      artistSubscriptionsByArtistId(condition: {active: true}) {
        nodes {
          artistSubscriptionTypeBySubscriptionType {
            subscriptionType
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ActiveArtistSubscriptionGQL extends Apollo.Query<ActiveArtistSubscriptionQuery, ActiveArtistSubscriptionQueryVariables> {
  document = ActiveArtistSubscriptionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddArtistMemberDocument = gql`
  mutation AddArtistMember($artistId: UUID!, $userId: UUID!) {
    createArtistMember(input: {artistMember: {artistId: $artistId, userId: $userId}}) {
      clientMutationId
      artistByArtistId {
        id
        artistMembersByArtistId {
          nodes {
            ...ArtistMemberFragment
          }
        }
      }
    }
  }
  ${ArtistMemberFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AddArtistMemberGQL extends Apollo.Mutation<AddArtistMemberMutation, AddArtistMemberMutationVariables> {
  document = AddArtistMemberDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ArtistsByUserIdDocument = gql`
  query ArtistsByUserId($id: UUID!) {
    allArtists(condition: {userId: $id}) {
      nodes {
        ...ArtistFragment
      }
    }
  }
  ${ArtistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ArtistsByUserIdGQL extends Apollo.Query<ArtistsByUserIdQuery, ArtistsByUserIdQueryVariables> {
  document = ArtistsByUserIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllArtistSubscriptionTypesDocument = gql`
  query AllArtistSubscriptionTypes {
    allArtistSubscriptionTypes(orderBy: _MONTHLY_PRICE_ASC) {
      edges {
        node {
          id
          duration
          dynamicPricing
          formats
          isDefault
          minWithdrawalLimit
          moneyBackGuarantee
          percentage
          postCount
          setupFee
          monthlyPrice
          annualPrice
          subscriptionType
          moneyBackGuaranteeDays
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AllArtistSubscriptionTypesGQL extends Apollo.Query<AllArtistSubscriptionTypesQuery, AllArtistSubscriptionTypesQueryVariables> {
  document = AllArtistSubscriptionTypesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateArtistDocument = gql`
  mutation CreateArtist($displayName: String!, $blarecastUrl: String!, $genre: UUID!) {
    createArtist(input: {artist: {displayName: $displayName, blarecastUrl: $blarecastUrl, genre: $genre}}) {
      artist {
        ...ArtistFragment
      }
    }
  }
  ${ArtistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CreateArtistGQL extends Apollo.Mutation<CreateArtistMutation, CreateArtistMutationVariables> {
  document = CreateArtistDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InviteArtistMemberDocument = gql`
  mutation InviteArtistMember($artistId: UUID!, $email: String!) {
    createArtistMemberByEmail(input: {artistId: $artistId, emailAddress: $email}) {
      artistByArtistId {
        id
        artistMembersByArtistId {
          nodes {
            ...ArtistMemberFragment
          }
        }
      }
    }
  }
  ${ArtistMemberFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InviteArtistMemberGQL extends Apollo.Mutation<InviteArtistMemberMutation, InviteArtistMemberMutationVariables> {
  document = InviteArtistMemberDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveArtistMemberDocument = gql`
  mutation RemoveArtistMember($artistId: UUID!, $memberUserId: UUID!) {
    deleteArtistMemberByArtistIdAndUserId(input: {artistId: $artistId, userId: $memberUserId}) {
      clientMutationId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveArtistMemberGQL extends Apollo.Mutation<RemoveArtistMemberMutation, RemoveArtistMemberMutationVariables> {
  document = RemoveArtistMemberDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ResendArtistMemberInvitationDocument = gql`
  mutation ResendArtistMemberInvitation($memberID: ID!) {
    resendArtistMemberInvitation(artistMemberId: $memberID)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ResendArtistMemberInvitationGQL extends Apollo.Mutation<
  ResendArtistMemberInvitationMutation,
  ResendArtistMemberInvitationMutationVariables
> {
  document = ResendArtistMemberInvitationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateArtistDocument = gql`
  mutation UpdateArtist(
    $id: UUID!
    $displayName: String
    $description: String
    $blarecastUrl: String
    $genreId: UUID
    $facebook: String
    $instagram: String
    $twitter: String
  ) {
    updateArtistById(
      input: {
        id: $id
        artistPatch: {
          displayName: $displayName
          description: $description
          blarecastUrl: $blarecastUrl
          genre: $genreId
          facebook: $facebook
          instagram: $instagram
          twitter: $twitter
        }
      }
    ) {
      artist {
        ...ArtistDetailsFragment
      }
    }
  }
  ${ArtistDetailsFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateArtistGQL extends Apollo.Mutation<UpdateArtistMutation, UpdateArtistMutationVariables> {
  document = UpdateArtistDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllRecentlyPlayedsDocument = gql`
  query AllRecentlyPlayeds(
    $after: Cursor = null
    $before: Cursor = null
    $pageSize: Int = 20
    $orderBy: [RecentlyPlayedsOrderBy!] = [CREATED_AT_DESC]
  ) {
    allRecentlyPlayeds(first: $pageSize, after: $after, before: $before, orderBy: $orderBy) {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          id
          artistByArtistId {
            ...ArtistFragment
          }
          playlistByPlaylistId {
            ...PlaylistFragment
          }
          albumByAlbumId {
            ...AlbumFragment
          }
        }
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
  ${ArtistFragmentFragmentDoc}
  ${PlaylistFragmentFragmentDoc}
  ${AlbumFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AllRecentlyPlayedsGQL extends Apollo.Query<AllRecentlyPlayedsQuery, AllRecentlyPlayedsQueryVariables> {
  document = AllRecentlyPlayedsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const NewTracksDocument = gql`
  query NewTracks($limit: Int!) {
    allTracks(orderBy: [CREATED_AT_DESC], first: $limit) {
      nodes {
        ...TrackFragment
      }
    }
  }
  ${TrackFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class NewTracksGQL extends Apollo.Query<NewTracksQuery, NewTracksQueryVariables> {
  document = NewTracksDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const TracksByGenreDocument = gql`
  query TracksByGenre($genre: UUID!, $limit: Int = 20) {
    genreById(id: $genre) {
      ...GenreFragment
      tracksByGenre(orderBy: [TRACK_NUMBER_OF_LIKES_DESC], first: $limit) {
        nodes {
          ...TrackFragment
        }
      }
    }
  }
  ${GenreFragmentFragmentDoc}
  ${TrackFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class TracksByGenreGQL extends Apollo.Query<TracksByGenreQuery, TracksByGenreQueryVariables> {
  document = TracksByGenreDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllAlbumsDocument = gql`
  query AllAlbums($limit: Int = 20, $orderBy: [AlbumsOrderBy!] = [CREATED_AT_DESC]) {
    allAlbums(first: $limit, orderBy: $orderBy) {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          ...AlbumFragment
        }
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
  ${AlbumFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AllAlbumsGQL extends Apollo.Query<AllAlbumsQuery, AllAlbumsQueryVariables> {
  document = AllAlbumsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllAlbumsByArtistIdDocument = gql`
  query AllAlbumsByArtistId($artistId: UUID!, $limit: Int = 20, $orderBy: [AlbumsOrderBy!] = [CREATED_AT_DESC]) {
    allAlbums(first: $limit, orderBy: $orderBy, condition: {artist: $artistId}) {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          ...AlbumFragment
        }
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
  ${AlbumFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AllAlbumsByArtistIdGQL extends Apollo.Query<AllAlbumsByArtistIdQuery, AllAlbumsByArtistIdQueryVariables> {
  document = AllAlbumsByArtistIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllArtistsDocument = gql`
  query AllArtists($limit: Int = 20, $orderBy: [ArtistsOrderBy!] = [CREATED_AT_DESC]) {
    allArtists(first: $limit, orderBy: $orderBy) {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          ...ArtistFragment
        }
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
  ${ArtistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AllArtistsGQL extends Apollo.Query<AllArtistsQuery, AllArtistsQueryVariables> {
  document = AllArtistsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllGenresDocument = gql`
  query AllGenres($parentId: UUID = null, $searchString: String) {
    allGenres(
      condition: {parentCategory: $parentId}
      orderBy: [CATEGORY_NAME_ASC]
      filter: {categoryName: {includesInsensitive: $searchString}}
    ) {
      edges {
        node {
          ...GenreFragment
        }
      }
    }
  }
  ${GenreFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AllGenresGQL extends Apollo.Query<AllGenresQuery, AllGenresQueryVariables> {
  document = AllGenresDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllTracksDocument = gql`
  query AllTracks($limit: Int = 20, $orderBy: [TracksOrderBy!] = [CREATED_AT_DESC]) {
    allTracks(first: $limit, orderBy: $orderBy) {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          ...TrackFragment
        }
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
  ${TrackFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AllTracksGQL extends Apollo.Query<AllTracksQuery, AllTracksQueryVariables> {
  document = AllTracksDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AlbumDetailsDocument = gql`
  query AlbumDetails($id: UUID!) {
    albumById(id: $id) {
      ...AlbumFragment
      likedAsListener
      description
      releaseDate
      tags
      genre
      albumsTracksByAlbumId(orderBy: TRACK_NUMBER_ASC) {
        totalCount
        edges {
          node {
            trackByTrackId {
              ...TrackFragment
              tracksArtistsByTrackId {
                edges {
                  node {
                    artistByArtistId {
                      ...ArtistFragment
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${AlbumFragmentFragmentDoc}
  ${TrackFragmentFragmentDoc}
  ${ArtistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AlbumDetailsGQL extends Apollo.Query<AlbumDetailsQuery, AlbumDetailsQueryVariables> {
  document = AlbumDetailsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AlbumMenuDetailsDocument = gql`
  query AlbumMenuDetails($id: UUID!) {
    albumById(id: $id) {
      ...AlbumFragment
      likedAsListener
      artistByArtist {
        ...ArtistFragment
      }
    }
  }
  ${AlbumFragmentFragmentDoc}
  ${ArtistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AlbumMenuDetailsGQL extends Apollo.Query<AlbumMenuDetailsQuery, AlbumMenuDetailsQueryVariables> {
  document = AlbumMenuDetailsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ArtistDetailsDocument = gql`
  query ArtistDetails($id: UUID!) {
    artistById(id: $id) {
      ...ArtistDetailsFragment
      artistSubscriptionsByArtistId(condition: {active: true}) {
        nodes {
          id
          artistSubscriptionTypeBySubscriptionType {
            subscriptionType
          }
        }
      }
    }
  }
  ${ArtistDetailsFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ArtistDetailsGQL extends Apollo.Query<ArtistDetailsQuery, ArtistDetailsQueryVariables> {
  document = ArtistDetailsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ListenerDetailsDocument = gql`
  query ListenerDetails($id: UUID!) {
    profilePublicByUserId(userId: $id) {
      ...PublicProfileFragment
      userByUserId {
        playlistsByAuthor(condition: {private: false}, after: null, before: null, first: 20) {
          edges {
            node {
              ...PlaylistFragment
            }
          }
        }
        userVerificationByUserId {
          status
        }
      }
    }
  }
  ${PublicProfileFragmentFragmentDoc}
  ${PlaylistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ListenerDetailsGQL extends Apollo.Query<ListenerDetailsQuery, ListenerDetailsQueryVariables> {
  document = ListenerDetailsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PlaylistDetailsDocument = gql`
  query PlaylistDetails($id: UUID!) {
    playlistById(id: $id) {
      ...PlaylistFragment
      likedAsListener
      userByAuthor {
        ...UserFragment
      }
      playlistsTracksByPlaylistId {
        pageInfo {
          ...PaginationFragment
        }
        edges {
          node {
            trackByTrackId {
              ...TrackFragment
              artistByArtist {
                ...ArtistFragment
              }
            }
          }
        }
      }
    }
  }
  ${PlaylistFragmentFragmentDoc}
  ${UserFragmentFragmentDoc}
  ${PaginationFragmentFragmentDoc}
  ${TrackFragmentFragmentDoc}
  ${ArtistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class PlaylistDetailsGQL extends Apollo.Query<PlaylistDetailsQuery, PlaylistDetailsQueryVariables> {
  document = PlaylistDetailsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PlaylistMenuDetailsDocument = gql`
  query PlaylistMenuDetails($id: UUID!) {
    playlistById(id: $id) {
      ...PlaylistFragment
      likedAsListener
      private
      userByAuthor {
        id
      }
    }
  }
  ${PlaylistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class PlaylistMenuDetailsGQL extends Apollo.Query<PlaylistMenuDetailsQuery, PlaylistMenuDetailsQueryVariables> {
  document = PlaylistMenuDetailsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const TrackDetailsDocument = gql`
  query TrackDetails($id: UUID!) {
    trackById(id: $id) {
      ...TrackFragment
    }
  }
  ${TrackFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class TrackDetailsGQL extends Apollo.Query<TrackDetailsQuery, TrackDetailsQueryVariables> {
  document = TrackDetailsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const TrackDetailsEditDocument = gql`
  query TrackDetailsEdit($id: UUID!) {
    trackById(id: $id) {
      id
      img
      presignedImgs {
        ...ImageFragment
      }
      title
      price
      genre
      tags
      releaseDate
      private
      duration
      artist
      explicit
      albumByAlbum {
        id
      }
      price
      pinnedAt
    }
  }
  ${ImageFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class TrackDetailsEditGQL extends Apollo.Query<TrackDetailsEditQuery, TrackDetailsEditQueryVariables> {
  document = TrackDetailsEditDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FavoritesDocument = gql`
  query Favorites($userId: UUID) {
    allLikes(condition: {userId: $userId, actingArtistId: null}) {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          userId
          trackByTrackId {
            ...TrackFragment
          }
          albumByAlbumId {
            ...AlbumFragment
          }
          playlistByPlaylistId {
            ...PlaylistFragment
          }
          artistByArtistId {
            ...ArtistFragment
          }
        }
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
  ${TrackFragmentFragmentDoc}
  ${AlbumFragmentFragmentDoc}
  ${PlaylistFragmentFragmentDoc}
  ${ArtistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class FavoritesGQL extends Apollo.Query<FavoritesQuery, FavoritesQueryVariables> {
  document = FavoritesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const LastPlayedTracksDocument = gql`
  query LastPlayedTracks($limit: Int = 20, $before: Cursor = null, $after: Cursor = null) {
    allLastPlayedTracks(first: $limit, before: $before, after: $after, orderBy: [TRACK_NUMBER_DESC]) {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          id
          trackNumber
          trackByTrackId {
            ...TrackFragment
          }
        }
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
  ${TrackFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class LastPlayedTracksGQL extends Apollo.Query<LastPlayedTracksQuery, LastPlayedTracksQueryVariables> {
  document = LastPlayedTracksDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllLikedAlbumsDocument = gql`
  query AllLikedAlbums($userId: UUID!, $actingArtistId: UUID, $after: Cursor = null, $before: Cursor = null, $pageSize: Int = 20) {
    allLikes(
      condition: {userId: $userId, actingArtistId: $actingArtistId, trackId: null, playlistId: null, artistId: null, likedUserId: null}
      first: $pageSize
      after: $after
      before: $before
    ) {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          albumByAlbumId {
            ...AlbumFragment
          }
        }
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
  ${AlbumFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AllLikedAlbumsGQL extends Apollo.Query<AllLikedAlbumsQuery, AllLikedAlbumsQueryVariables> {
  document = AllLikedAlbumsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllLikedArtistsDocument = gql`
  query AllLikedArtists($userId: UUID!, $actingArtistId: UUID, $after: Cursor = null, $pageSize: Int = 20) {
    allLikes(
      condition: {userId: $userId, actingArtistId: $actingArtistId, trackId: null, playlistId: null, albumId: null, likedUserId: null}
      first: $pageSize
      after: $after
    ) {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          artistByArtistId {
            ...ArtistFragment
          }
        }
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
  ${ArtistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AllLikedArtistsGQL extends Apollo.Query<AllLikedArtistsQuery, AllLikedArtistsQueryVariables> {
  document = AllLikedArtistsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllLikesDocument = gql`
  query AllLikes($userId: UUID!) {
    allLikes(condition: {userId: $userId}) {
      edges {
        node {
          ...LikeFragment
        }
      }
    }
  }
  ${LikeFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AllLikesGQL extends Apollo.Query<AllLikesQuery, AllLikesQueryVariables> {
  document = AllLikesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ArtistFollowersDocument = gql`
  query ArtistFollowers(
    $artistId: UUID
    $after: Cursor = null
    $before: Cursor = null
    $pageSize: Int = 20
    $orderBy: [LikesOrderBy!] = [CREATED_AT_DESC]
  ) {
    allLikes(condition: {artistId: $artistId}, first: $pageSize, after: $after, before: $before, orderBy: $orderBy) {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          id
        }
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ArtistFollowersGQL extends Apollo.Query<ArtistFollowersQuery, ArtistFollowersQueryVariables> {
  document = ArtistFollowersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ArtistLikesDocument = gql`
  query ArtistLikes($userId: UUID!, $artistId: UUID!) {
    artistById(id: $artistId) {
      likesByArtistId(condition: {userId: $userId}) {
        nodes {
          userId
          actingArtistId
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ArtistLikesGQL extends Apollo.Query<ArtistLikesQuery, ArtistLikesQueryVariables> {
  document = ArtistLikesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateLikeDocument = gql`
  mutation CreateLike($trackId: UUID, $albumId: UUID, $playlistId: UUID, $artistId: UUID, $likedUserId: UUID, $actingArtistId: UUID) {
    createLike(
      input: {
        trackId: $trackId
        albumId: $albumId
        playlistId: $playlistId
        artistId: $artistId
        likedUserId: $likedUserId
        actingArtistId: $actingArtistId
      }
    ) {
      like {
        ...LikeFragment
      }
    }
  }
  ${LikeFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CreateLikeGQL extends Apollo.Mutation<CreateLikeMutation, CreateLikeMutationVariables> {
  document = CreateLikeDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteLikeDocument = gql`
  mutation DeleteLike($actingArtistId: UUID, $trackId: UUID, $albumId: UUID, $playlistId: UUID, $artistId: UUID, $userId: UUID) {
    deleteLikes(
      input: {
        actingArtist: $actingArtistId
        albumLike: $albumId
        artistLike: $artistId
        playlistLike: $playlistId
        trackLike: $trackId
        userLike: $userId
      }
    ) {
      likes {
        ...LikeFragment
      }
    }
  }
  ${LikeFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteLikeGQL extends Apollo.Mutation<DeleteLikeMutation, DeleteLikeMutationVariables> {
  document = DeleteLikeDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteLikeByIdDocument = gql`
  mutation DeleteLikeById($likeId: UUID!) {
    deleteLikeById(input: {id: $likeId}) {
      like {
        ...LikeFragment
      }
    }
  }
  ${LikeFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteLikeByIdGQL extends Apollo.Mutation<DeleteLikeByIdMutation, DeleteLikeByIdMutationVariables> {
  document = DeleteLikeByIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ListenerLikesDocument = gql`
  query ListenerLikes($currentUserId: UUID!, $listenerId: UUID!) {
    userById(id: $listenerId) {
      likesByLikedUserId(condition: {userId: $currentUserId}) {
        nodes {
          actingArtistId
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ListenerLikesGQL extends Apollo.Query<ListenerLikesQuery, ListenerLikesQueryVariables> {
  document = ListenerLikesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllNotificationsDocument = gql`
  query AllNotifications($after: Cursor = null, $before: Cursor = null, $pageSize: Int = 20) {
    allNotifications(first: $pageSize, after: $after, before: $before, orderBy: [CREATED_AT_DESC]) {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          id
          createdAt
          title
          subtitle
          notificationType
          targetId
          targetType
          pushSent
          read
          emailSent
        }
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AllNotificationsGQL extends Apollo.Query<AllNotificationsQuery, AllNotificationsQueryVariables> {
  document = AllNotificationsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteAllNotificationsDocument = gql`
  mutation DeleteAllNotifications {
    deleteAllNotifications(input: {}) {
      clientMutationId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteAllNotificationsGQL extends Apollo.Mutation<DeleteAllNotificationsMutation, DeleteAllNotificationsMutationVariables> {
  document = DeleteAllNotificationsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ReadAllNotificationsDocument = gql`
  mutation ReadAllNotifications {
    updateNotificationsAllRead(input: {}) {
      clientMutationId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ReadAllNotificationsGQL extends Apollo.Mutation<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables> {
  document = ReadAllNotificationsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ReadNotificationDocument = gql`
  mutation ReadNotification($notificationId: UUID!) {
    updateNotificationById(input: {notificationPatch: {read: true}, id: $notificationId}) {
      clientMutationId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ReadNotificationGQL extends Apollo.Mutation<ReadNotificationMutation, ReadNotificationMutationVariables> {
  document = ReadNotificationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddToPlaylistDocument = gql`
  mutation AddToPlaylist($playlist: UUID!, $track: UUID!, $trackNumber: Int!) {
    addTrackToPlaylist(input: {playlist: $playlist, track: $track, trackNumber: $trackNumber}) {
      query {
        playlistById(id: $playlist) {
          ...PlaylistFragment
        }
      }
    }
  }
  ${PlaylistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AddToPlaylistGQL extends Apollo.Mutation<AddToPlaylistMutation, AddToPlaylistMutationVariables> {
  document = AddToPlaylistDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllPlaylistsDocument = gql`
  query AllPlaylists(
    $author: UUID!
    $isPrivate: Boolean
    $after: Cursor = null
    $before: Cursor = null
    $pageSize: Int = 20
    $orderBy: [PlaylistsOrderBy!] = [CREATED_AT_DESC]
  ) {
    allPlaylists(condition: {author: $author, private: $isPrivate}, first: $pageSize, after: $after, before: $before, orderBy: $orderBy) {
      pageInfo {
        ...PaginationFragment
      }
      nodes {
        ...PlaylistFragment
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
  ${PlaylistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AllPlaylistsGQL extends Apollo.Query<AllPlaylistsQuery, AllPlaylistsQueryVariables> {
  document = AllPlaylistsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreatePlaylistDocument = gql`
  mutation CreatePlaylist($title: String!, $img: String, $isPrivate: Boolean = false) {
    createPlaylist(input: {title: $title, img: $img, private: $isPrivate}) {
      playlist {
        ...PlaylistFragment
      }
    }
  }
  ${PlaylistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CreatePlaylistGQL extends Apollo.Mutation<CreatePlaylistMutation, CreatePlaylistMutationVariables> {
  document = CreatePlaylistDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeletePlaylistDocument = gql`
  mutation DeletePlaylist($playlistId: UUID!) {
    deletePlaylistById(input: {id: $playlistId}) {
      playlist {
        ...PlaylistFragment
      }
    }
  }
  ${PlaylistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class DeletePlaylistGQL extends Apollo.Mutation<DeletePlaylistMutation, DeletePlaylistMutationVariables> {
  document = DeletePlaylistDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeletePlaylistImageDocument = gql`
  mutation DeletePlaylistImage($playlistId: UUID!) {
    deletePlaylistImage(input: {playlist: $playlistId}) {
      clientMutationId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeletePlaylistImageGQL extends Apollo.Mutation<DeletePlaylistImageMutation, DeletePlaylistImageMutationVariables> {
  document = DeletePlaylistImageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const IsTrackOnPlaylistDocument = gql`
  query IsTrackOnPlaylist($trackId: UUID!, $playlistId: UUID!) {
    allPlaylistsTracks(condition: {trackId: $trackId, playlistId: $playlistId}) {
      totalCount
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IsTrackOnPlaylistGQL extends Apollo.Query<IsTrackOnPlaylistQuery, IsTrackOnPlaylistQueryVariables> {
  document = IsTrackOnPlaylistDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MyPlaylistsDocument = gql`
  query MyPlaylists(
    $author: UUID!
    $after: Cursor = null
    $before: Cursor = null
    $pageSize: Int = 20
    $orderBy: [PlaylistsOrderBy!] = [CREATED_AT_DESC]
  ) {
    allPlaylists(condition: {author: $author}, first: $pageSize, after: $after, before: $before, orderBy: $orderBy) {
      pageInfo {
        ...PaginationFragment
      }
      nodes {
        ...PlaylistFragment
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
  ${PlaylistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class MyPlaylistsGQL extends Apollo.Query<MyPlaylistsQuery, MyPlaylistsQueryVariables> {
  document = MyPlaylistsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveFromPlaylistDocument = gql`
  mutation RemoveFromPlaylist($playlist: UUID!, $track: UUID!) {
    removeTrackFromPlaylist(input: {playlist: $playlist, track: $track}) {
      query {
        playlistById(id: $playlist) {
          ...PlaylistFragment
        }
      }
    }
  }
  ${PlaylistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveFromPlaylistGQL extends Apollo.Mutation<RemoveFromPlaylistMutation, RemoveFromPlaylistMutationVariables> {
  document = RemoveFromPlaylistDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdatePlaylistDocument = gql`
  mutation UpdatePlaylist($playlistId: UUID!, $title: String!, $isPrivate: Boolean) {
    updatePlaylistById(input: {playlistPatch: {private: $isPrivate, title: $title}, id: $playlistId}) {
      playlist {
        ...PlaylistFragment
      }
    }
  }
  ${PlaylistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UpdatePlaylistGQL extends Apollo.Mutation<UpdatePlaylistMutation, UpdatePlaylistMutationVariables> {
  document = UpdatePlaylistDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddToAutoGeneratedQueueDocument = gql`
  mutation AddToAutoGeneratedQueue($queueObjectList: [AutoGeneratedQueueInput!]) {
    mnCreateAutoGeneratedQueue(input: {mnAutoGeneratedQueue: $queueObjectList}) {
      autoGeneratedQueueEdge {
        node {
          ...AutoGeneratedQueueFragment
        }
      }
    }
  }
  ${AutoGeneratedQueueFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AddToAutoGeneratedQueueGQL extends Apollo.Mutation<AddToAutoGeneratedQueueMutation, AddToAutoGeneratedQueueMutationVariables> {
  document = AddToAutoGeneratedQueueDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddToUsersQueueDocument = gql`
  mutation AddToUsersQueue($queueObjectList: [UsersQueueInput!]) {
    mnCreateUsersQueue(input: {mnUsersQueue: $queueObjectList}) {
      usersQueueEdge {
        node {
          ...UsersQueueFragment
        }
      }
    }
  }
  ${UsersQueueFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AddToUsersQueueGQL extends Apollo.Mutation<AddToUsersQueueMutation, AddToUsersQueueMutationVariables> {
  document = AddToUsersQueueDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllAutoGeneratedQueueDocument = gql`
  query AllAutoGeneratedQueue($limit: Int = 20, $orderBy: [AutoGeneratedQueuesOrderBy!] = [CREATED_AT_DESC]) {
    allAutoGeneratedQueues(first: $limit, orderBy: $orderBy) {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          ...AutoGeneratedQueueFragment
        }
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
  ${AutoGeneratedQueueFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AllAutoGeneratedQueueGQL extends Apollo.Query<AllAutoGeneratedQueueQuery, AllAutoGeneratedQueueQueryVariables> {
  document = AllAutoGeneratedQueueDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllUsersQueueDocument = gql`
  query AllUsersQueue($limit: Int = 20) {
    allUsersQueues(condition: {listened: false}, orderBy: [TRACK_NUMBER_ASC], first: $limit) {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          ...UsersQueueFragment
        }
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
  ${UsersQueueFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AllUsersQueueGQL extends Apollo.Query<AllUsersQueueQuery, AllUsersQueueQueryVariables> {
  document = AllUsersQueueDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteFromQueueDocument = gql`
  mutation DeleteFromQueue($trackId: UUID!) {
    deleteUsersQueueById(input: {id: $trackId}) {
      deletedUsersQueueId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteFromQueueGQL extends Apollo.Mutation<DeleteFromQueueMutation, DeleteFromQueueMutationVariables> {
  document = DeleteFromQueueDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteFromUpNextDocument = gql`
  mutation DeleteFromUpNext($id: UUID!) {
    deleteAutoGeneratedQueueById(input: {id: $id}) {
      deletedAutoGeneratedQueueId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteFromUpNextGQL extends Apollo.Mutation<DeleteFromUpNextMutation, DeleteFromUpNextMutationVariables> {
  document = DeleteFromUpNextDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteListFromQueueDocument = gql`
  mutation DeleteListFromQueue($queueIdList: [UsersQueuePatch!]) {
    mnDeleteUsersQueueById(input: {mnUsersQueuePatch: $queueIdList}) {
      deletedUsersQueueId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteListFromQueueGQL extends Apollo.Mutation<DeleteListFromQueueMutation, DeleteListFromQueueMutationVariables> {
  document = DeleteListFromQueueDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const EmptyAutoGeneratedQueueDocument = gql`
  mutation EmptyAutoGeneratedQueue {
    emptyAutoGeneratedQueue(input: {}) {
      clientMutationId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class EmptyAutoGeneratedQueueGQL extends Apollo.Mutation<EmptyAutoGeneratedQueueMutation, EmptyAutoGeneratedQueueMutationVariables> {
  document = EmptyAutoGeneratedQueueDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const EmptyUsersQueueDocument = gql`
  mutation EmptyUsersQueue {
    emptyUsersQueue(input: {}) {
      clientMutationId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class EmptyUsersQueueGQL extends Apollo.Mutation<EmptyUsersQueueMutation, EmptyUsersQueueMutationVariables> {
  document = EmptyUsersQueueDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateAutoGeneratedQueueByIdDocument = gql`
  mutation UpdateAutoGeneratedQueueById($id: UUID!, $listened: Boolean!) {
    updateAutoGeneratedQueueById(input: {id: $id, autoGeneratedQueuePatch: {listened: $listened}}) {
      autoGeneratedQueue {
        ...AutoGeneratedQueueFragment
      }
    }
  }
  ${AutoGeneratedQueueFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateAutoGeneratedQueueByIdGQL extends Apollo.Mutation<
  UpdateAutoGeneratedQueueByIdMutation,
  UpdateAutoGeneratedQueueByIdMutationVariables
> {
  document = UpdateAutoGeneratedQueueByIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateAutoGeneratedQueueListDocument = gql`
  mutation UpdateAutoGeneratedQueueList($patches: [AutoGeneratedQueuePatch!]!) {
    mnUpdateAutoGeneratedQueueById(input: {mnAutoGeneratedQueuePatch: $patches}) {
      clientMutationId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateAutoGeneratedQueueListGQL extends Apollo.Mutation<
  UpdateAutoGeneratedQueueListMutation,
  UpdateAutoGeneratedQueueListMutationVariables
> {
  document = UpdateAutoGeneratedQueueListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateAutogeneratedQueueDocument = gql`
  mutation UpdateAutogeneratedQueue($album: UUID, $playlist: UUID, $startAt: Int!) {
    updateNextUp(input: {album: $album, playlist: $playlist, startAt: $startAt}) {
      query {
        allAutoGeneratedQueues {
          nodes {
            trackByTrackId {
              ...TrackFragment
            }
          }
        }
      }
    }
  }
  ${TrackFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateAutogeneratedQueueGQL extends Apollo.Mutation<
  UpdateAutogeneratedQueueMutation,
  UpdateAutogeneratedQueueMutationVariables
> {
  document = UpdateAutogeneratedQueueDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateNextUpDocument = gql`
  mutation UpdateNextUp($album: UUID, $playlist: UUID, $startAt: Int!, $favourites: Boolean) {
    updateNextUp(input: {album: $album, playlist: $playlist, favourites: $favourites, startAt: $startAt}) {
      autoGeneratedQueues {
        id
        trackNumber
        trackByTrackId {
          ...TrackFragment
        }
      }
    }
  }
  ${TrackFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateNextUpGQL extends Apollo.Mutation<UpdateNextUpMutation, UpdateNextUpMutationVariables> {
  document = UpdateNextUpDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SearchHistoryDocument = gql`
  query SearchHistory {
    allSearchHistories(orderBy: [CREATED_AT_DESC], first: 50) {
      nodes {
        searchValue
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class SearchHistoryGQL extends Apollo.Query<SearchHistoryQuery, SearchHistoryQueryVariables> {
  document = SearchHistoryDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllFollowersDocument = gql`
  query AllFollowers(
    $userId: UUID
    $artistId: UUID
    $searchString: String
    $pageSize: Int = 40
    $after: Cursor = null
    $before: Cursor = null
  ) {
    allFollowers(
      condition: {user: $userId, artist: $artistId}
      filter: {displayName: {includesInsensitive: $searchString}}
      after: $after
      before: $before
      first: $pageSize
    ) {
      edges {
        node {
          id
          type
          displayName
          presignedImgs {
            ...ImageFragment
          }
        }
      }
      pageInfo {
        ...PaginationFragment
      }
    }
  }
  ${ImageFragmentFragmentDoc}
  ${PaginationFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AllFollowersGQL extends Apollo.Query<AllFollowersQuery, AllFollowersQueryVariables> {
  document = AllFollowersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllFollowingsDocument = gql`
  query AllFollowings(
    $userId: UUID
    $artistId: UUID
    $searchString: String
    $pageSize: Int = 40
    $after: Cursor = null
    $before: Cursor = null
  ) {
    allFollowings(
      condition: {user: $userId, artist: $artistId}
      filter: {displayName: {includesInsensitive: $searchString}}
      after: $after
      before: $before
      first: $pageSize
    ) {
      edges {
        node {
          id
          type
          displayName
          presignedImgs {
            ...ImageFragment
          }
        }
      }
      pageInfo {
        ...PaginationFragment
      }
    }
  }
  ${ImageFragmentFragmentDoc}
  ${PaginationFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AllFollowingsGQL extends Apollo.Query<AllFollowingsQuery, AllFollowingsQueryVariables> {
  document = AllFollowingsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteAccountDocument = gql`
  mutation DeleteAccount {
    deleteUser(input: {}) {
      clientMutationId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteAccountGQL extends Apollo.Mutation<DeleteAccountMutation, DeleteAccountMutationVariables> {
  document = DeleteAccountDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetCurrentEmailDocument = gql`
  query getCurrentEmail {
    currentEmail
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetCurrentEmailGQL extends Apollo.Query<GetCurrentEmailQuery, GetCurrentEmailQueryVariables> {
  document = GetCurrentEmailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetShareLinkDocument = gql`
  mutation GetShareLink($id: String!, $itemType: AssetType!) {
    shareContent(input: {id: $id, type: $itemType}) {
      link
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetShareLinkGQL extends Apollo.Mutation<GetShareLinkMutation, GetShareLinkMutationVariables> {
  document = GetShareLinkDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const IsRegisteredWithSocialMediaDocument = gql`
  query IsRegisteredWithSocialMedia {
    isRegisteredWithSocialMedia
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IsRegisteredWithSocialMediaGQL extends Apollo.Query<
  IsRegisteredWithSocialMediaQuery,
  IsRegisteredWithSocialMediaQueryVariables
> {
  document = IsRegisteredWithSocialMediaDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const IsRegistrationCompletedDocument = gql`
  query IsRegistrationCompleted {
    currentUser {
      registrationCompleted
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IsRegistrationCompletedGQL extends Apollo.Query<IsRegistrationCompletedQuery, IsRegistrationCompletedQueryVariables> {
  document = IsRegistrationCompletedDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const IsUserVerifiedDocument = gql`
  query IsUserVerified {
    isUserVerified
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IsUserVerifiedGQL extends Apollo.Query<IsUserVerifiedQuery, IsUserVerifiedQueryVariables> {
  document = IsUserVerifiedDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MyProfileDocument = gql`
  query MyProfile {
    currentUser {
      artistsByUserId {
        nodes {
          ...ArtistDetailsFragment
        }
      }
      playlistsByAuthor(orderBy: [CREATED_AT_DESC]) {
        nodes {
          ...PlaylistFragment
        }
      }
      userVerificationByUserId {
        status
      }
      profilePublicByUserId {
        ...PublicProfileFragment
      }
      profileSettingByUserId {
        ...PrivateProfileFragment
        userByUserId {
          notificationsByUserId(condition: {read: false}) {
            totalCount
          }
        }
        countryByGeolocation {
          currenciesByCountry {
            edges {
              node {
                name
                symbol
              }
            }
          }
        }
      }
      artistMembersByUserId {
        nodes {
          artistByArtistId {
            ...ArtistFragment
          }
        }
      }
    }
  }
  ${ArtistDetailsFragmentFragmentDoc}
  ${PlaylistFragmentFragmentDoc}
  ${PublicProfileFragmentFragmentDoc}
  ${PrivateProfileFragmentFragmentDoc}
  ${ArtistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class MyProfileGQL extends Apollo.Query<MyProfileQuery, MyProfileQueryVariables> {
  document = MyProfileDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProfileDocument = gql`
  query Profile($userId: UUID!) {
    profileSettingByUserId(userId: $userId) {
      ...PrivateProfileFragment
      userByUserId {
        playlistsByAuthor(orderBy: [CREATED_AT_DESC]) {
          edges {
            node {
              ...PlaylistFragment
            }
          }
        }
        userVerificationByUserId {
          status
        }
      }
    }
    profilePublicByUserId(userId: $userId) {
      ...PublicProfileFragment
    }
    allArtistMembers(condition: {userId: $userId}) {
      nodes {
        artistByArtistId {
          ...ArtistFragment
        }
      }
    }
  }
  ${PrivateProfileFragmentFragmentDoc}
  ${PlaylistFragmentFragmentDoc}
  ${PublicProfileFragmentFragmentDoc}
  ${ArtistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ProfileGQL extends Apollo.Query<ProfileQuery, ProfileQueryVariables> {
  document = ProfileDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProfileByUserIdDocument = gql`
  query ProfileByUserId($userId: UUID!) {
    userById(id: $userId) {
      id
      artistsByUserId {
        nodes {
          ...ArtistFragment
          userId
          description
          followingCount
          followerCount
          likedAsListener
          likedAsArtist
          facebook
          instagram
          twitter
          presignedCoverImgs {
            ...ImageFragment
          }
          genreByGenre {
            id
          }
          artistMembersByArtistId {
            nodes {
              ...ArtistMemberFragment
            }
          }
        }
      }
      userVerificationByUserId {
        status
      }
      profileSettingByUserId {
        ...PrivateProfileFragment
      }
      artistMembersByUserId {
        nodes {
          artistByArtistId {
            ...ArtistFragment
          }
        }
      }
    }
    profilePublicByUserId(userId: $userId) {
      ...PublicProfileFragment
    }
  }
  ${ArtistFragmentFragmentDoc}
  ${ImageFragmentFragmentDoc}
  ${ArtistMemberFragmentFragmentDoc}
  ${PrivateProfileFragmentFragmentDoc}
  ${PublicProfileFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ProfileByUserIdGQL extends Apollo.Query<ProfileByUserIdQuery, ProfileByUserIdQueryVariables> {
  document = ProfileByUserIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const NotificationSettingsQueryDocument = gql`
  query NotificationSettingsQuery($id: UUID!) {
    userById(id: $id) {
      id
      usersNotificationByUserId {
        userId
        webRecommendedMusic
        webPlaylistUpdates
        webNewMusic
        webConcertReminder
        webBlareNewsAndOffers
        webArtistUpdate
        emailRecommendedMusic
        emailPlaylistUpdates
        emailNewMusic
        emailConcertReminder
        emailBlareNewsAndOffers
        emailArtistUpdate
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class NotificationSettingsQueryGQL extends Apollo.Query<NotificationSettingsQueryQuery, NotificationSettingsQueryQueryVariables> {
  document = NotificationSettingsQueryDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateNotificationSettingsMutationDocument = gql`
  mutation UpdateNotificationSettingsMutation(
    $userId: UUID!
    $newEmailRecommendedMusic: Boolean!
    $newEmailNewMusic: Boolean!
    $newEmailPlaylistUpdates: Boolean!
    $newEmailArtistUpdate: Boolean!
    $newEmailConcertReminder: Boolean!
    $newEmailBlareNewsAndOffers: Boolean!
    $newWebRecommendedMusic: Boolean!
    $newWebNewMusic: Boolean!
    $newWebPlaylistUpdates: Boolean!
    $newWebArtistUpdate: Boolean!
    $newWebConcertReminder: Boolean!
    $newWebBlareNewsAndOffers: Boolean!
  ) {
    updateUsersNotificationByUserId(
      input: {
        usersNotificationPatch: {
          emailRecommendedMusic: $newEmailRecommendedMusic
          emailNewMusic: $newEmailNewMusic
          emailPlaylistUpdates: $newEmailPlaylistUpdates
          emailArtistUpdate: $newEmailArtistUpdate
          emailConcertReminder: $newEmailConcertReminder
          emailBlareNewsAndOffers: $newEmailBlareNewsAndOffers
          webRecommendedMusic: $newWebRecommendedMusic
          webNewMusic: $newWebNewMusic
          webPlaylistUpdates: $newWebPlaylistUpdates
          webArtistUpdate: $newWebArtistUpdate
          webConcertReminder: $newWebConcertReminder
          webBlareNewsAndOffers: $newWebBlareNewsAndOffers
        }
        userId: $userId
      }
    ) {
      usersNotification {
        userId
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateNotificationSettingsMutationGQL extends Apollo.Mutation<
  UpdateNotificationSettingsMutationMutation,
  UpdateNotificationSettingsMutationMutationVariables
> {
  document = UpdateNotificationSettingsMutationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveProfileCoverDocument = gql`
  mutation RemoveProfileCover {
    deleteCoverImage(input: {}) {
      clientMutationId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveProfileCoverGQL extends Apollo.Mutation<RemoveProfileCoverMutation, RemoveProfileCoverMutationVariables> {
  document = RemoveProfileCoverDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveProfileImageDocument = gql`
  mutation RemoveProfileImage {
    deleteProfileImage(input: {}) {
      clientMutationId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveProfileImageGQL extends Apollo.Mutation<RemoveProfileImageMutation, RemoveProfileImageMutationVariables> {
  document = RemoveProfileImageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const StatusDocument = gql`
  query Status {
    allNotifications(first: 1, condition: {read: false}) {
      totalCount
    }
    currentUser {
      profilePublicByUserId {
        presignedProfileImgs {
          ...ImageFragment
        }
      }
    }
  }
  ${ImageFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class StatusGQL extends Apollo.Query<StatusQuery, StatusQueryVariables> {
  document = StatusDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const TopupTypesDocument = gql`
  query TopupTypes {
    allListenerPackageTypes(orderBy: _PACKAGE_PRICE_ASC) {
      edges {
        node {
          id
          _packagePrice
          packagePrice
          packageType
          streamCharge
          trialDays
          trialTracks
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class TopupTypesGQL extends Apollo.Query<TopupTypesQuery, TopupTypesQueryVariables> {
  document = TopupTypesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdatePrivateProfileDocument = gql`
  mutation UpdatePrivateProfile($firstName: String, $lastName: String, $userId: UUID!) {
    updateProfileSettingByUserId(input: {profileSettingPatch: {firstName: $firstName, lastName: $lastName}, userId: $userId}) {
      profileSetting {
        ...PrivateProfileFragment
      }
    }
  }
  ${PrivateProfileFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UpdatePrivateProfileGQL extends Apollo.Mutation<UpdatePrivateProfileMutation, UpdatePrivateProfileMutationVariables> {
  document = UpdatePrivateProfileDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdatePublicProfileDocument = gql`
  mutation UpdatePublicProfile(
    $displayName: String
    $personalBio: String
    $facebook: String
    $instagram: String
    $twitter: String
    $blarecastUrl: String
    $userId: UUID!
  ) {
    updateProfilePublicByUserId(
      input: {
        profilePublicPatch: {
          displayName: $displayName
          blarecastUrl: $blarecastUrl
          personalBio: $personalBio
          facebook: $facebook
          instagram: $instagram
          twitter: $twitter
        }
        userId: $userId
      }
    ) {
      profilePublic {
        ...PublicProfileFragment
      }
    }
  }
  ${PublicProfileFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UpdatePublicProfileGQL extends Apollo.Mutation<UpdatePublicProfileMutation, UpdatePublicProfileMutationVariables> {
  document = UpdatePublicProfileDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UserByBlarecastUrlDocument = gql`
  query UserByBlarecastUrl($url: String!, $after: Cursor = null, $before: Cursor = null, $pageSize: Int = 20) {
    searchBlarecastUrls(search: $url, searchFilter: LISTENER, first: $pageSize, after: $after, before: $before) {
      edges {
        node {
          userId
          blarecastUrl
          displayName
          presignedImgs {
            ...ImageFragment
          }
        }
      }
      pageInfo {
        ...PaginationFragment
      }
    }
  }
  ${ImageFragmentFragmentDoc}
  ${PaginationFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UserByBlarecastUrlGQL extends Apollo.Query<UserByBlarecastUrlQuery, UserByBlarecastUrlQueryVariables> {
  document = UserByBlarecastUrlDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UserProfilesDocument = gql`
  query UserProfiles($userId: UUID!) {
    userById(id: $userId) {
      profilePublicByUserId {
        displayName
      }
      artistsByUserId {
        nodes {
          id
          displayName
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UserProfilesGQL extends Apollo.Query<UserProfilesQuery, UserProfilesQueryVariables> {
  document = UserProfilesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const IsLikedDocument = gql`
  query isLiked(
    $actingArtistId: UUID = null
    $likedUserId: UUID
    $artistId: UUID
    $trackId: UUID
    $playlistId: UUID
    $albumId: UUID
    $userId: UUID
  ) {
    allLikes(
      condition: {
        actingArtistId: $actingArtistId
        likedUserId: $likedUserId
        artistId: $artistId
        trackId: $trackId
        playlistId: $playlistId
        albumId: $albumId
        userId: $userId
      }
    ) {
      edges {
        node {
          id
          actingArtistId
          artistId
          albumId
          likedUserId
          playlistId
          trackId
          userId
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IsLikedGQL extends Apollo.Query<IsLikedQuery, IsLikedQueryVariables> {
  document = IsLikedDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreatePlaylistWebDocument = gql`
  mutation createPlaylistWeb($title: String, $private: Boolean) {
    createPlaylist(input: {title: $title, private: $private}) {
      playlist {
        id
        private
        title
        presignedImgs {
          size
          url
        }
        likeCount
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreatePlaylistWebGQL extends Apollo.Mutation<CreatePlaylistWebMutation, CreatePlaylistWebMutationVariables> {
  document = CreatePlaylistWebDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdatePlaylistByIdDocument = gql`
  mutation updatePlaylistById($id: UUID!, $title: String, $private: Boolean) {
    updatePlaylistById(input: {id: $id, playlistPatch: {title: $title, private: $private}}) {
      playlist {
        id
        private
        title
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdatePlaylistByIdGQL extends Apollo.Mutation<UpdatePlaylistByIdMutation, UpdatePlaylistByIdMutationVariables> {
  document = UpdatePlaylistByIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemovePlaylistDocument = gql`
  mutation removePlaylist($id: UUID!) {
    deletePlaylistById(input: {id: $id}) {
      deletedPlaylistId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemovePlaylistGQL extends Apollo.Mutation<RemovePlaylistMutation, RemovePlaylistMutationVariables> {
  document = RemovePlaylistDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllPlaylistsQueryDocument = gql`
  query allPlaylistsQuery($userId: UUID) {
    allPlaylists(condition: {author: $userId}) {
      edges {
        node {
          id
          private
          title
          presignedImgs {
            size
            url
          }
          likeCount
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AllPlaylistsQueryGQL extends Apollo.Query<AllPlaylistsQueryQuery, AllPlaylistsQueryQueryVariables> {
  document = AllPlaylistsQueryDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PlaylistByIdDocument = gql`
  query playlistById($id: UUID!) {
    playlistById(id: $id) {
      private
      title
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class PlaylistByIdGQL extends Apollo.Query<PlaylistByIdQuery, PlaylistByIdQueryVariables> {
  document = PlaylistByIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PlaylistDetailsWebDocument = gql`
  query PlaylistDetailsWeb($id: UUID!) {
    playlistById(id: $id) {
      id
      title
      img
      presignedImgs {
        size
        url
      }
      private
      likedAsListener
      userByAuthor {
        ...UserFragment
      }
      likesByPlaylistId {
        totalCount
      }
      playlistsTracksByPlaylistId(orderBy: TRACK_NUMBER_ASC) {
        pageInfo {
          ...PaginationFragment
        }
        edges {
          node {
            trackByTrackId {
              ...TrackFragment
              tracksArtistsByTrackId {
                edges {
                  node {
                    artistByArtistId {
                      ...ArtistFragment
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${UserFragmentFragmentDoc}
  ${PaginationFragmentFragmentDoc}
  ${TrackFragmentFragmentDoc}
  ${ArtistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class PlaylistDetailsWebGQL extends Apollo.Query<PlaylistDetailsWebQuery, PlaylistDetailsWebQueryVariables> {
  document = PlaylistDetailsWebDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddToPlaylistWebDocument = gql`
  mutation AddToPlaylistWeb($playlist: UUID!, $track: UUID!) {
    addTrackToPlaylist(input: {playlist: $playlist, track: $track}) {
      clientMutationId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddToPlaylistWebGQL extends Apollo.Mutation<AddToPlaylistWebMutation, AddToPlaylistWebMutationVariables> {
  document = AddToPlaylistWebDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveFromPlaylistWebDocument = gql`
  mutation RemoveFromPlaylistWeb($playlist: UUID!, $track: UUID!) {
    removeTrackFromPlaylist(input: {playlist: $playlist, track: $track}) {
      clientMutationId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveFromPlaylistWebGQL extends Apollo.Mutation<RemoveFromPlaylistWebMutation, RemoveFromPlaylistWebMutationVariables> {
  document = RemoveFromPlaylistWebDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllLikedPlaylistsDocument = gql`
  query AllLikedPlaylists($userId: UUID!) {
    allLikes(condition: {userId: $userId, albumId: null, trackId: null, artistId: null, likedUserId: null, actingArtistId: null}) {
      edges {
        node {
          id
          playlistByPlaylistId {
            id
            title
            presignedImgs {
              size
              url
            }
            likeCount
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AllLikedPlaylistsGQL extends Apollo.Query<AllLikedPlaylistsQuery, AllLikedPlaylistsQueryVariables> {
  document = AllLikedPlaylistsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MyEarningsDocument = gql`
  query MyEarnings($from: Date!, $to: Date!) {
    myEarnings(from: $from, to: $to) {
      rows {
        amount
        createdAt
      }
      earnings
      totalWithdrawn
      totalEarnings
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class MyEarningsGQL extends Apollo.Query<MyEarningsQuery, MyEarningsQueryVariables> {
  document = MyEarningsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MyStreamingsDocument = gql`
  query MyStreamings($from: Date!, $to: Date!) {
    myStreams(from: $from, to: $to) {
      rows {
        createdAt
        streams
      }
      streams
      maxUploadTime
      avgListeners
      availableUploadTime
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class MyStreamingsGQL extends Apollo.Query<MyStreamingsQuery, MyStreamingsQueryVariables> {
  document = MyStreamingsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AccountBalanceConsumptionDocument = gql`
  query AccountBalanceConsumption($from: Date!, $to: Date!) {
    accountBalanceConsumption(from: $from, to: $to) {
      accountBalance {
        avgMonthConsumption
        balance
      }
      rows {
        consumption
        createdAt
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AccountBalanceConsumptionGQL extends Apollo.Query<AccountBalanceConsumptionQuery, AccountBalanceConsumptionQueryVariables> {
  document = AccountBalanceConsumptionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllEarningsDocument = gql`
  query AllEarnings($userId: UUID, $trackId: UUID, $from: Datetime!, $to: Datetime!) {
    allEarningsHistories(
      condition: {userId: $userId, trackId: $trackId}
      filter: {and: {createdAt: {greaterThanOrEqualTo: $from}}, createdAt: {lessThanOrEqualTo: $to}}
      orderBy: CREATED_AT_ASC
    ) {
      edges {
        node {
          id
          createdAt
          amount
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AllEarningsGQL extends Apollo.Query<AllEarningsQuery, AllEarningsQueryVariables> {
  document = AllEarningsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllStreamsDocument = gql`
  query AllStreams($trackId: UUID, $from: Datetime!, $to: Datetime!) {
    allStreamsHistories(
      condition: {trackId: $trackId}
      filter: {and: {createdAt: {greaterThanOrEqualTo: $from}}, createdAt: {lessThanOrEqualTo: $to}}
      orderBy: CREATED_AT_ASC
    ) {
      edges {
        node {
          id
          createdAt
          streams
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AllStreamsGQL extends Apollo.Query<AllStreamsQuery, AllStreamsQueryVariables> {
  document = AllStreamsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const OwnershipsDocument = gql`
  query Ownerships(
    $myUserId: UUID
    $isArtistMember: Boolean
    $isCeltzer: Boolean
    $fromMyArtist: Boolean
    $after: Cursor = null
    $pageSize: Int = 20
  ) {
    ownerships(isArtistMember: $isArtistMember, isCeltzer: $isCeltzer, isArtist: $fromMyArtist, first: $pageSize, after: $after) {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          id
          trackByTrackId {
            id
            title
            img
            presignedImgs {
              size
              url
            }
            royaltiesByTrackId(condition: {status: CURRENT, userId: $myUserId}) {
              edges {
                node {
                  id
                  percentage
                  userId
                }
              }
            }
            albumByAlbum {
              id
              title
            }
          }
        }
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class OwnershipsGQL extends Apollo.Query<OwnershipsQuery, OwnershipsQueryVariables> {
  document = OwnershipsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AllTracksByArtistIdDocument = gql`
  query AllTracksByArtistId(
    $artistId: UUID
    $pageSize: Int = 20
    $orderBy: [TracksOrderBy!] = [CREATED_AT_DESC]
    $albumId: UUID
    $after: Cursor = null
  ) {
    allTracks(condition: {artist: $artistId, album: $albumId}, first: $pageSize, orderBy: $orderBy, after: $after) {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          ...TrackFragment
          pinnedAt
          private
          albumByAlbum {
            id
          }
        }
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
  ${TrackFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AllTracksByArtistIdGQL extends Apollo.Query<AllTracksByArtistIdQuery, AllTracksByArtistIdQueryVariables> {
  document = AllTracksByArtistIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ToggleTrackPinDocument = gql`
  mutation ToggleTrackPin($trackId: UUID!) {
    toggleTrackPin(input: {trackId: $trackId}) {
      query {
        trackById(id: $trackId) {
          ...TrackFragment
          pinnedAt
        }
      }
      clientMutationId
    }
  }
  ${TrackFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ToggleTrackPinGQL extends Apollo.Mutation<ToggleTrackPinMutation, ToggleTrackPinMutationVariables> {
  document = ToggleTrackPinDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FavoriteTracksDocument = gql`
  query FavoriteTracks($userId: UUID, $after: Cursor = null, $before: Cursor = null, $pageSize: Int = 20) {
    allLikes(
      condition: {albumId: null, playlistId: null, artistId: null, userId: $userId, likedUserId: null}
      first: $pageSize
      after: $after
      before: $before
    ) {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          trackByTrackId {
            ...TrackFragment
          }
        }
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
  ${TrackFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class FavoriteTracksGQL extends Apollo.Query<FavoriteTracksQuery, FavoriteTracksQueryVariables> {
  document = FavoriteTracksDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const TrackTileOptionsDocument = gql`
  query TrackTileOptions($id: UUID!) {
    trackById(id: $id) {
      id
      likedAsListener
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class TrackTileOptionsGQL extends Apollo.Query<TrackTileOptionsQuery, TrackTileOptionsQueryVariables> {
  document = TrackTileOptionsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const TrackTotalEarningsDocument = gql`
  query trackTotalEarnings($trackId: UUID!) {
    trackTotalEarnings(trackId: $trackId)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class TrackTotalEarningsGQL extends Apollo.Query<TrackTotalEarningsQuery, TrackTotalEarningsQueryVariables> {
  document = TrackTotalEarningsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetCurrentArtistDocument = gql`
  query getCurrentArtist($currentUserID: UUID) {
    allArtists(condition: {userId: $currentUserID}) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetCurrentArtistGQL extends Apollo.Query<GetCurrentArtistQuery, GetCurrentArtistQueryVariables> {
  document = GetCurrentArtistDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateEmptyTrackDocument = gql`
  mutation createEmptyTrack($artistID: UUID!, $title: String) {
    createTrack(input: {track: {artist: $artistID, title: $title}}) {
      track {
        id
        title
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateEmptyTrackGQL extends Apollo.Mutation<CreateEmptyTrackMutation, CreateEmptyTrackMutationVariables> {
  document = CreateEmptyTrackDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateTrackDocument = gql`
  mutation updateTrack(
    $trackID: UUID!
    $title: String
    $albumID: UUID
    $tags: String
    $releaseDate: Datetime
    $private: Boolean
    $genreID: UUID
    $explicit: Boolean
    $artistID: UUID
  ) {
    updateTrackById(
      input: {
        trackPatch: {
          title: $title
          album: $albumID
          tags: $tags
          releaseDate: $releaseDate
          private: $private
          genre: $genreID
          explicit: $explicit
          artist: $artistID
        }
        id: $trackID
      }
    ) {
      clientMutationId
      track {
        ...TrackFragment
      }
    }
  }
  ${TrackFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateTrackGQL extends Apollo.Mutation<UpdateTrackMutation, UpdateTrackMutationVariables> {
  document = UpdateTrackDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateTrackPriceDocument = gql`
  mutation createTrackPrice($trackID: UUID!, $amount: Int!) {
    createPrice(input: {price: {amount: $amount, trackId: $trackID}}) {
      clientMutationId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateTrackPriceGQL extends Apollo.Mutation<CreateTrackPriceMutation, CreateTrackPriceMutationVariables> {
  document = CreateTrackPriceDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SearchCollaboratorsDocument = gql`
  query searchCollaborators(
    $searchString: String
    $pageSize: Int = 20
    $afterArtistCursor: Cursor = null
    $afterUserCursor: Cursor = null
  ) {
    allArtists(
      filter: {or: [{blarecastUrl: {includesInsensitive: $searchString}}, {displayName: {includesInsensitive: $searchString}}]}
      first: $pageSize
      after: $afterArtistCursor
    ) {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          id
          displayName
          blarecastUrl
          img
          presignedProfileImgs {
            size
            url
          }
        }
      }
    }
    allProfilePublics(
      filter: {or: [{blarecastUrl: {includesInsensitive: $searchString}}, {displayName: {includesInsensitive: $searchString}}]}
      first: $pageSize
      after: $afterUserCursor
    ) {
      pageInfo {
        ...PaginationFragment
      }
      edges {
        node {
          userId
          displayName
          blarecastUrl
          profileImg
          presignedProfileImgs {
            size
            url
          }
        }
      }
    }
  }
  ${PaginationFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class SearchCollaboratorsGQL extends Apollo.Query<SearchCollaboratorsQuery, SearchCollaboratorsQueryVariables> {
  document = SearchCollaboratorsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateRoyaltiesDocument = gql`
  mutation CreateRoyalties($trackId: UUID!, $percentages: [UserPercentageInput]!) {
    createRoyalties(input: {userPercentages: $percentages, track: $trackId}) {
      clientMutationId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateRoyaltiesGQL extends Apollo.Mutation<CreateRoyaltiesMutation, CreateRoyaltiesMutationVariables> {
  document = CreateRoyaltiesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const TrackRoyaltiesDocument = gql`
  query TrackRoyalties($trackId: UUID!) {
    allRoyalties(condition: {trackId: $trackId, status: CURRENT}) {
      edges {
        node {
          id
          userId
          artistMemberId
          percentage
          celtzer
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class TrackRoyaltiesGQL extends Apollo.Query<TrackRoyaltiesQuery, TrackRoyaltiesQueryVariables> {
  document = TrackRoyaltiesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ArtistMembersDocument = gql`
  query ArtistMembers($artistId: UUID!) {
    allArtistMembers(condition: {artistId: $artistId}) {
      edges {
        node {
          id
          userByUserId {
            id
            profilePublicByUserId {
              userId
              blarecastUrl
              displayName
              profileImg
            }
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ArtistMembersGQL extends Apollo.Query<ArtistMembersQuery, ArtistMembersQueryVariables> {
  document = ArtistMembersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ArtistMemberByIdDocument = gql`
  query ArtistMemberById($artistMemberId: UUID!) {
    artistMemberById(id: $artistMemberId) {
      artistByArtistId {
        blarecastUrl
        img
        presignedProfileImgs {
          size
          url
        }
        id
        displayName
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ArtistMemberByIdGQL extends Apollo.Query<ArtistMemberByIdQuery, ArtistMemberByIdQueryVariables> {
  document = ArtistMemberByIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteTrackDocument = gql`
  mutation deleteTrack($trackID: UUID!) {
    deleteTrackById(input: {id: $trackID}) {
      deletedTrackId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteTrackGQL extends Apollo.Mutation<DeleteTrackMutation, DeleteTrackMutationVariables> {
  document = DeleteTrackDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateAlbumDocument = gql`
  mutation createAlbum($title: String, $tags: String, $releaseDate: Datetime!, $description: String, $genreID: UUID, $artistID: UUID) {
    createAlbum(
      input: {album: {title: $title, tags: $tags, releaseDate: $releaseDate, description: $description, genre: $genreID, artist: $artistID}}
    ) {
      clientMutationId
      album {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateAlbumGQL extends Apollo.Mutation<CreateAlbumMutation, CreateAlbumMutationVariables> {
  document = CreateAlbumDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteAlbumDocument = gql`
  mutation deleteAlbum($albumId: UUID!) {
    deleteAlbumById(input: {id: $albumId}) {
      clientMutationId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteAlbumGQL extends Apollo.Mutation<DeleteAlbumMutation, DeleteAlbumMutationVariables> {
  document = DeleteAlbumDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateAlbumDocument = gql`
  mutation updateAlbum($albumID: UUID!, $title: String, $tags: String, $releaseDate: Datetime!, $description: String, $genreID: UUID) {
    updateAlbumById(
      input: {albumPatch: {title: $title, tags: $tags, releaseDate: $releaseDate, description: $description, genre: $genreID}, id: $albumID}
    ) {
      clientMutationId
      album {
        ...AlbumFragment
      }
    }
  }
  ${AlbumFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateAlbumGQL extends Apollo.Mutation<UpdateAlbumMutation, UpdateAlbumMutationVariables> {
  document = UpdateAlbumDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CurrentUserQueryDocument = gql`
  query CurrentUserQuery {
    currentUser {
      id
      registrationCompleted
      registration {
        requirements {
          genre
          information
          kyc
          payment
        }
        details
      }
      profilePublicByUserId {
        userId
        displayName
        blarecastUrl
        profileImg
        presignedProfileImgs {
          size
          url
        }
      }
      artistsByUserId {
        edges {
          node {
            id
            img
            presignedProfileImgs {
              size
              url
            }
            displayName
            blarecastUrl
            genre
            artistSubscriptionsByArtistId(condition: {active: true}) {
              nodes {
                id
                subscriptionType
                billingInterval
                nextBillingDate
                refund {
                  applicable
                }
                artistSubscriptionByDowngradeTo {
                  id
                  billingInterval
                  nextBillingDate
                  subscriptionType
                }
              }
            }
          }
        }
      }
      userVerificationByUserId {
        status
      }
      listenerPackagesByUserId(condition: {active: true}) {
        edges {
          node {
            id
            active
            hasFreeTrial
            canceledAt
            createdAt
            packageType
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CurrentUserQueryGQL extends Apollo.Query<CurrentUserQueryQuery, CurrentUserQueryQueryVariables> {
  document = CurrentUserQueryDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ActiveListenerPackageDocument = gql`
  query ActiveListenerPackage($userId: UUID!) {
    userById(id: $userId) {
      id
      listenerPackagesByUserId(condition: {active: true}) {
        edges {
          node {
            id
            active
            hasFreeTrial
            canceledAt
            createdAt
            packageType
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ActiveListenerPackageGQL extends Apollo.Query<ActiveListenerPackageQuery, ActiveListenerPackageQueryVariables> {
  document = ActiveListenerPackageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SetBlarecastUrlAndDisplayNameDocument = gql`
  mutation SetBlarecastUrlAndDisplayName($displayName: String, $blarecastUrl: String) {
    setBlarecastData(input: {displayName: $displayName, url: $blarecastUrl}) {
      clientMutationId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class SetBlarecastUrlAndDisplayNameGQL extends Apollo.Mutation<
  SetBlarecastUrlAndDisplayNameMutation,
  SetBlarecastUrlAndDisplayNameMutationVariables
> {
  document = SetBlarecastUrlAndDisplayNameDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PaymentHistoryDocument = gql`
  query PaymentHistory {
    paymentHistory {
      amount
      createdAt
      description
      type
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class PaymentHistoryGQL extends Apollo.Query<PaymentHistoryQuery, PaymentHistoryQueryVariables> {
  document = PaymentHistoryDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
