import {ComponentRef, Injectable, Injector} from '@angular/core';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {MediaObserver} from '@angular/flex-layout';

import {PopoverComponent} from './popover.component';
import {PopoverData} from './popover-config';

export interface PopoverPosition {
  x: number;
  y: number;
}
@Injectable({
  providedIn: 'root',
})
export class PopoverService {
  constructor(private overlay: Overlay, private parentInjector: Injector, private mediaObserver: MediaObserver) {}

  public show<T>(
    position: PopoverPosition | HTMLElement,
    popoverData: PopoverData<T>,
    closeOnScroll = false,
  ): ComponentRef<PopoverComponent> {
    const overlayRef = this.overlay.create(this.getOverlayConfig(position, closeOnScroll));

    const injector = this.getInjector(popoverData, overlayRef, this.parentInjector);
    const popoverPortal = new ComponentPortal(PopoverComponent, null, injector);

    const componentRef = overlayRef.attach(popoverPortal);

    return componentRef;
  }

  private getOverlayConfig(positionOrElement: PopoverPosition | HTMLElement, closeOnScroll: boolean): OverlayConfig {
    let popoverPosition = positionOrElement;
    if ('x' in positionOrElement && 'y' in positionOrElement) {
      popoverPosition = popoverPosition as PopoverPosition;
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      popoverPosition.x -= scrollbarWidth; // not tested with origins of elements
    }

    const isOnMobile = this.mediaObserver.isActive('lt-md');
    // check if opening popover on mobile, change position to bottom
    if (isOnMobile) {
      popoverPosition = {x: 0, y: window.innerHeight};
    }

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(popoverPosition)
      .withPositions([
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
        },
      ]);

    return new OverlayConfig({
      hasBackdrop: isOnMobile,
      positionStrategy,
      scrollStrategy: closeOnScroll ? this.overlay.scrollStrategies.close() : undefined,
    });
  }

  private getInjector(popoverData: PopoverData<unknown>, overlayRef: OverlayRef, parentInjector: Injector): Injector {
    return Injector.create({
      parent: parentInjector,
      providers: [
        {provide: PopoverData, useValue: popoverData},
        {provide: OverlayRef, useValue: overlayRef},
        {provide: PopoverComponent, useValue: this},
      ],
    });
  }
}
