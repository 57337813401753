<div fxLayout="column" class="p-3">
  <a fxFlex fxLayoutAlign="end center" class="link" (click)="followOrUnfollow(true); close()" fxLayout.lt-md="row-reverse">
    <span *ngIf="!userIsFollowing"
      >Follow <ng-container *ngIf="isUserArtist">as {{ userName }}</ng-container>
    </span>
    <span *ngIf="userIsFollowing"
      >Unfollow <ng-container *ngIf="isUserArtist">as {{ userName }}</ng-container>
    </span>
    <fa-icon class="dark-grey" fxFlex="20px" fxLayoutAlign="center center" [icon]="FaIcons.headphones"></fa-icon>
  </a>
  <a
    *ngIf="isUserArtist"
    fxFlex
    fxLayoutAlign="end center"
    class="link"
    (click)="followOrUnfollow(false); close()"
    fxLayout.lt-md="row-reverse"
  >
    <span *ngIf="!artistIsFollowing">Follow as {{ artistName }}</span>
    <span *ngIf="artistIsFollowing">Unfollow as {{ artistName }}</span>
    <fa-icon class="dark-grey" fxFlex="20px" fxLayoutAlign="center center" [icon]="FaIcons.microphone"></fa-icon>
  </a>
  <a fxFlex fxLayoutAlign="end center" class="link" fxLayout.lt-md="row-reverse" *ngIf="popoverData.data?.showShareButton">
    <span>Share artist</span>
    <fa-icon class="dark-grey" fxFlex="20px" fxLayoutAlign="center center" [icon]="FaIcons.shareAlt"></fa-icon>
  </a>
</div>
