import {AfterViewInit, Directive, ElementRef, OnDestroy} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[appPrefixInput]',
})
export class PrefixInputDirective implements AfterViewInit, OnDestroy {
  public resizeObserver: ResizeObserver | undefined;
  public prefixElement: HTMLElement | undefined;

  constructor(private elem: ElementRef) {}

  public ngAfterViewInit(): void {
    this.prefixElement = this.elem.nativeElement.querySelector('.prefix');
    const inputElement = this.elem.nativeElement.querySelector('input');
    if (this.prefixElement) {
      inputElement.setAttribute('style', `padding-left: ${this.prefixElement.offsetWidth + 16}px;`);

      this.resizeObserver = new ResizeObserver(() => {
        if (this.prefixElement) {
          inputElement.setAttribute('style', `padding-left: ${this.prefixElement.offsetWidth + 16}px;`);
        }
      });

      this.resizeObserver.observe(this.prefixElement);
    }
  }

  public ngOnDestroy(): void {
    if (this.prefixElement) {
      this.resizeObserver?.unobserve(this.prefixElement);
    }
  }
}
