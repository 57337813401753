import {Directive, Input, TemplateRef, ViewContainerRef, ChangeDetectorRef} from '@angular/core';
import {MediaObserver} from '@angular/flex-layout';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[appMediaIf]',
})
export class MediaIfDirective {
  private hasView = false;

  /*
   * Accepted are only breakpoint alias (xs, sm, md, lg, xl, lt-sm, lt-md, lt-lg, lt-xl, gt-xs, gt-sm, gt-md, gt-lg).
    More here: https://github.com/angular/flex-layout/wiki/Responsive-API
   * */
  @Input() public set appMediaIf(value: string) {
    this.mediaObserver
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe((changes) => {
        const isBreakPointValid = changes.some((change) => change.mqAlias === value);

        if (isBreakPointValid) {
          this.createView();
        } else {
          this.destroyView();
        }
      });
  }

  constructor(
    private mediaObserver: MediaObserver,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private template: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private changeRef: ChangeDetectorRef,
  ) {}

  private createView() {
    if (!this.hasView) {
      this.viewContainer.createEmbeddedView(this.template);
      this.changeRef.markForCheck();
      this.hasView = true;
    }
  }

  private destroyView() {
    if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
