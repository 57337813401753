import {Injectable} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {combineLatest, fromEvent, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Track} from 'src/generated/graphql';
import {ImgSize} from '../constants/img-size.enum';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class DrawThumbnailFromTracksService {
  public readonly availablePositions = [
    {x: 28, y: 28},
    {x: 76, y: 28},
    {x: 28, y: 76},
    {x: 76, y: 76},
  ];

  public drawThumbnail(tracks: Track[] | undefined): Observable<string> {
    if (tracks) {
      const mainTracks = tracks.slice(0, 4);
      const thumbnailPositions = mainTracks.map((track, index) => this.availablePositions[index]);
      const canvas = document.createElement('canvas');

      canvas.width = 144;
      canvas.height = 144;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        const grad = ctx.createLinearGradient(0, 0, 0, 171);
        grad.addColorStop(0, 'rgba(0, 0, 0, 0.3)');
        grad.addColorStop(1, 'rgba(0, 0, 0, 0)');
        ctx.fillStyle = grad;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        const events: Observable<Event>[] = [];

        for (const [index, imagePos] of thumbnailPositions.entries()) {
          const imgLocal = new Image(144, 144);
          imgLocal.setAttribute('crossorigin', 'anonymous');
          imgLocal.src = tracks[index]?.presignedImgs?.find((x) => x?.size === ImgSize.Small)?.url || '';

          const createImage = (x: number, y: number, img: HTMLImageElement): void => {
            const width = 40;
            const height = 40;
            const radius = 4;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x + radius, y);
            ctx.lineTo(x + width - radius, y);
            ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
            ctx.lineTo(x + width, y + height - radius);
            ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
            ctx.lineTo(x + radius, y + height);
            ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
            ctx.lineTo(x, y + radius);
            ctx.quadraticCurveTo(x, y, x + radius, y);
            ctx.closePath();
            ctx.clip();

            ctx.drawImage(img, x, y, width, height);
            ctx.restore();
          };

          events.push(
            fromEvent<Event>(imgLocal, 'load').pipe(
              untilDestroyed(this),
              tap(() => {
                createImage(imagePos.x, imagePos.y, imgLocal);
              }),
            ),
          );
        }

        return combineLatest(events).pipe(map(() => canvas.toDataURL()));
      }
    }

    return of('');
  }
}
