import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import {FlexLayoutModule} from '@angular/flex-layout';
import {RouterModule} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OverlayModule} from '@angular/cdk/overlay';
import {RoundProgressModule} from 'angular-svg-round-progressbar';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CdkTableModule} from '@angular/cdk/table';

import {HttpClientModule} from '@angular/common/http';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {SwiperModule} from 'swiper/angular';

const sharedModules = [
  FlexLayoutModule,
  RouterModule,
  FontAwesomeModule,
  ReactiveFormsModule,
  NgSelectModule,
  FormsModule,
  OverlayModule,
  RoundProgressModule,
  CdkStepperModule,
  DragDropModule,
  HttpClientModule,
  ScrollingModule,
  CdkTableModule,
  SwiperModule,
];

@NgModule({
  declarations: [],
  imports: [CommonModule, ...sharedModules],
  exports: [...sharedModules],
})
export class CoreModule {}
