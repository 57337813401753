import {ComponentRef, Injectable, Injector} from '@angular/core';
import {Overlay, OverlayRef, PositionStrategy} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';

import {ToastComponent} from './toast.component';
import {ToastData} from './toast-config';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  public lastToastRef: OverlayRef | undefined;

  constructor(private overlay: Overlay, private parentInjector: Injector) {}

  public show(data: ToastData): ComponentRef<ToastComponent> {
    const positionStrategy = this.getPositionStrategy();
    const overlayRef = this.overlay.create({positionStrategy});
    this.lastToastRef = overlayRef;

    const injector = this.getInjector(new ToastData(data), overlayRef, this.parentInjector);
    const toastPortal = new ComponentPortal(ToastComponent, null, injector);

    const componentRef = overlayRef.attach(toastPortal);

    return componentRef;
  }

  private getPositionStrategy(): PositionStrategy {
    // init spacing from top is 10px, spacing between toasts is 4px
    const position = this.lastToastRef?.overlayElement ? this.lastToastRef.overlayElement.getBoundingClientRect().bottom + 4 : 10;
    return this.overlay.position().global().centerHorizontally().top(`${position}px`);
  }

  private getInjector(data: ToastData, overlayRef: OverlayRef, parentInjector: Injector): Injector {
    return Injector.create({
      parent: parentInjector,
      providers: [
        {provide: ToastData, useValue: data},
        {provide: OverlayRef, useValue: overlayRef},
      ],
    });
  }
}
