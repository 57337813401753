import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

interface SdkToken {
  sdkToken: string;
}
interface ApplicantCheckCreated {
  applicantCheckCreated: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class KycService {
  constructor(private http: HttpClient) {}

  public getSdkToken(firstName: string, lastName: string): Observable<SdkToken> {
    return this.http.post<SdkToken>('v1/onfido/get-sdk-token', {firstName, lastName});
  }

  public checkApplicant(): Observable<ApplicantCheckCreated> {
    return this.http.post<ApplicantCheckCreated>('v1/onfido/check-applicant', {});
  }
}
