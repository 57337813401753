import {Component, Input} from '@angular/core';
import {PackageFeature} from '../../interfaces/package-feature.interface';

export interface PackageTopup {
  id: string;
  name: string;
  price1: string;
  price2: string;
  priceClass: string;
  text: string;
  features: PackageFeature[];
  trialDays: number;
  trialTracks: number;
}

@Component({
  selector: 'app-package-topup',
  templateUrl: './package-topup.component.html',
  styleUrls: ['./package-topup.component.scss'],
})
export class PackageTopupComponent {
  @Input() public selected = false;
  @Input() public togglePrice = false;
  @Input() public package: PackageTopup | undefined;
}
