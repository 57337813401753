import {Pipe, PipeTransform} from '@angular/core';
import {Maybe} from 'graphql/jsutils/Maybe';

@Pipe({
  name: 'numbersFormat',
})
export class NumbersFormatPipe implements PipeTransform {
  public transform(number: number | undefined | Maybe<number>): string {
    if (!number) {
      return '0';
    }

    if (number < 1000) {
      return `${number}`;
    }

    if (number < 1000000) {
      return `${this.toDecimal(number / 1000).toFixed(1)}k`;
    }

    return `${this.toDecimal(number / 1000000).toFixed(1)}M`;
  }

  private toDecimal(num: number): number {
    return Math.floor(num * 10) / 10;
  }
}
