import {OverlayRef} from '@angular/cdk/overlay';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {map} from 'rxjs/operators';
import {ModalData} from 'src/app/shared/components/modal/modal-config';
import {ModalComponent} from 'src/app/shared/components/modal/modal.component';
import {ModalService} from 'src/app/shared/components/modal/modal.service';
import {ToastService} from 'src/app/shared/components/toast/toast.service';
import {CacheHelperService} from 'src/app/shared/services/cache-helper.service';
import {AddToPlaylistWebGQL, AllPlaylistsQueryGQL, Playlist} from 'src/generated/graphql';
import {ModalNewPlaylistComponent} from '../modal-new-playlist/modal-new-playlist.component';

@UntilDestroy()
@Component({
  selector: 'app-modal-add-to-playlist',
  templateUrl: './modal-add-to-playlist.component.html',
  styleUrls: ['./modal-add-to-playlist.component.scss'],
})
export class ModalAddToPlaylistComponent implements OnInit {
  public playlistForm: FormGroup;

  public playlists: Playlist[] = [];
  public trackId = '';

  constructor(
    private overlayRef: OverlayRef,
    private modalService: ModalService,
    private modalData: ModalData<string>,
    private modalComponent: ModalComponent,
    private formBuilder: FormBuilder,
    private allPlaylistQueryGQL: AllPlaylistsQueryGQL,
    private cacheHelperService: CacheHelperService,
    private addToPlaylistWebGQL: AddToPlaylistWebGQL,
    private toastService: ToastService,
  ) {
    this.playlistForm = this.formBuilder.group({
      playlist: [undefined, [Validators.required]],
    });

    this.trackId = this.modalData.data || '';
  }

  public get playlistId(): FormControl {
    return this.playlistForm.get('playlist') as FormControl;
  }

  public ngOnInit(): void {
    this.allPlaylistQueryGQL
      .fetch({
        userId: this.cacheHelperService.getCurrentUserCache().id,
      })
      .pipe(
        untilDestroyed(this),
        map((result) => result.data.allPlaylists?.edges),
      )
      .subscribe((data) => {
        this.playlists = data?.map((x) => x.node) as Playlist[];
      });
  }

  public addTrackToPlaylist(): void {
    if (!this.playlistForm.disabled) {
      // TODO if current showing playlist is same that you are adding to it, I am not refetching songs !
      this.playlistForm.disable();
      this.addToPlaylistWebGQL
        .mutate({playlist: this.playlistId.value, track: this.trackId})
        .pipe(untilDestroyed(this))
        .subscribe({
          next: () => {
            this.modalComponent.closeModal();
            this.toastService.show({
              text: 'Song has been added to your playlist',
              type: 'success',
            });
          },
          error: () => {
            this.toastService.show({
              text: 'This song is already on the playlist',
              type: 'info',
            });
            this.playlistForm.enable();
          },
        });
    }
  }

  public createNewPlaylist(): void {
    this.overlayRef.dispose();
    this.modalService.show({content: ModalNewPlaylistComponent, data: this.trackId, position: 'center', padding: '16px'});
  }
}
