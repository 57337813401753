<app-smooth-height [trigger]="currentStep">
  <div *ngIf="currentStep === PaymentFlow.ADDRESS" fxLayoutAlign="start center" fxLayout="column">
    <h4>Billing address</h4>
    <form [formGroup]="billingAddressForm" class="mt-4 w-100">
      <div class="input-field-wrapper">
        <label for="street">Street</label>
        <input id="street" formControlName="street" type="text" placeholder="Oxform Street 14 " appInputErrorClass />
      </div>
      <div class="mt-3 input-field-wrapper">
        <label for="townCity">Town/City</label>
        <input id="townCity" formControlName="townCity" type="text" placeholder="London" appInputErrorClass />
      </div>
      <div class="mt-3 input-field-wrapper">
        <label for="zipCode">Zip Code</label>
        <input
          id="zipCode"
          formControlName="zipCode"
          type="text"
          placeholder="532515"
          appInputErrorClass
          [class.error]="billingAddressForm.controls.zipCode.errors?.pattern"
        />
        <div class="note error" *ngIf="billingAddressForm.controls.zipCode.errors?.pattern" [@fadeInOut]>
          Zip code can only have letters and numbers
        </div>
      </div>
      <div class="mt-3 input-field-wrapper">
        <label for="country">Country</label>
        <ng-select
          labelForId="country"
          class="custom"
          formControlName="country"
          placeholder="Country"
          [items]="countryData"
          bindLabel="name"
          bindValue="alpha2"
        >
        </ng-select>
      </div>
    </form>

    <div class="mt-5" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="16px">
      <button class="big secondary" (click)="backButtonClick.emit()">Back</button>
      <button class="big" [disabled]="billingAddressForm.invalid" (click)="currentStep = PaymentFlow.CARD">Continue</button>
    </div>
  </div>

  <div
    [fxHide]="currentStep !== PaymentFlow.CARD"
    class="stripe-wrapper"
    [class.loader]="loadingStripeElements"
    fxLayoutAlign="start center"
    fxLayout="column"
  >
    <h4 class="mb-4">Payment information</h4>
    <div style="height: 256px" *ngIf="loadingStripeElements"></div>
    <ngx-stripe-card-group
      *ngIf="stripe"
      [stripe]="stripe"
      [formGroup]="stripeForm"
      [elementsOptions]="elementsOptions"
      [fxHide]="loadingStripeElements"
      style="width: 100%"
      appDisableForm
    >
      <div class="input-field-wrapper">
        <label>Card number</label>
        <div
          class="input-wrapper"
          #cardNumber
          [class.disabled]="stripeForm.disabled"
          [class.error]="(cardNumberElementChange$ | async)?.error"
        >
          <ngx-stripe-card-number
            [options]="cardOptions"
            (focus)="cardNumber.classList.add('focus')"
            (blur)="cardNumber.classList.remove('focus')"
            (ready)="elementReady(StripeElementType.CARD)"
          ></ngx-stripe-card-number>
        </div>
      </div>
      <div class="mt-3 input-field-wrapper">
        <label>Expiration date</label>
        <div class="input-wrapper" #date [class.disabled]="stripeForm.disabled" [class.error]="(cardExpiryElementChange$ | async)?.error">
          <ngx-stripe-card-expiry
            [options]="cardOptions"
            (focus)="date.classList.add('focus')"
            (blur)="date.classList.remove('focus')"
            (ready)="elementReady(StripeElementType.DATE)"
          ></ngx-stripe-card-expiry>
        </div>
      </div>

      <div class="mt-3 input-field-wrapper">
        <label>ccv</label>
        <div class="input-wrapper" #ccv [class.disabled]="stripeForm.disabled" [class.error]="(cardCVVElementChange$ | async)?.error">
          <ngx-stripe-card-cvc
            [options]="cardOptions"
            (focus)="ccv.classList.add('focus')"
            (blur)="ccv.classList.remove('focus')"
            (ready)="elementReady(StripeElementType.CVV)"
          ></ngx-stripe-card-cvc>
        </div>
      </div>

      <div class="mt-3 input-field-wrapper">
        <label for="name">Cardholder fullname</label>
        <input
          id="name"
          formControlName="name"
          type="text"
          placeholder="Full name"
          [class.error]="name.touched && name.hasError('required')"
        />
      </div>
    </ngx-stripe-card-group>

    <div class="mt-5" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="16px">
      <button class="big secondary" [disabled]="stripeForm.disabled" (click)="currentStep = PaymentFlow.ADDRESS">Back</button>
      <button
        class="big"
        (click)="manuallyCallAddCardToCustomerFunction ? completed.emit() : automaticAddCardToCustomer()"
        [class.loader]="stripeForm.disabled"
        [disabled]="!stripeFormValid"
      >
        Continue
      </button>
    </div>
  </div>
</app-smooth-height>
