import {OverlayRef} from '@angular/cdk/overlay';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {slideFromTop} from '../../animations/animations';
import {ToastData} from './toast-config';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['toast.component.scss'],
  animations: [slideFromTop],
})
export class ToastComponent implements OnInit, OnDestroy {
  public animationState = 'enter';

  private timeout: number | undefined;

  constructor(public data: ToastData, public overlayRef: OverlayRef) {}
  public ngOnInit(): void {
    console.log(this.data);
    if (this.data.timeout) {
      this.timeout = (setTimeout(() => {
        this.close();
      }, this.data.timeout) as unknown) as number;
    }
  }

  public ngOnDestroy(): void {
    clearTimeout(this.timeout);
  }

  public close(): void {
    this.animationState = 'leave';
  }

  public animationEnd(): void {
    if (this.animationState === 'leave') {
      this.overlayRef.dispose();
    }
  }
}
