<div fxLayoutAlign="start center" fxLayout="column" class="text-center">
  <h4>Remove playlist</h4>
  <fa-icon class="mt-5 light" [icon]="FaIcons.trashAlt" size="2x"></fa-icon>
  <div class="mt-5" fxLayoutAlign="start center" fxLayout="column">
    <span class="light"> This action is irreversable and will delete the playlist permanently. Are you sure you want to continue? </span>
    <div class="mt-5" fxLayout="row">
      <button class="big secondary mr-3" (click)="close()">Cancel</button>
      <button class="big red" [class.loader]="isButtonLoading" (click)="removePlaylist()">Remove</button>
    </div>
  </div>
</div>
