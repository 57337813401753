import {Directive, HostBinding, Input} from '@angular/core';

@Directive({
  selector: '[appRangeSliderBackground]',
})
export class RangeSliderBackgroundDirective {
  @HostBinding('style.background') public background = '';

  private _progressColor = '';
  @Input()
  public set progressColor(val: string) {
    this._progressColor = val;
    this.setBackRangleSliderBacground();
  }
  public get progressColor(): string {
    return this._progressColor;
  }

  private _percentage = 0;
  @Input()
  public set percentage(val: number) {
    this._percentage = val;
    this.setBackRangleSliderBacground();
  }
  public get percentage(): number {
    return this._percentage;
  }

  private setBackRangleSliderBacground(): void {
    this.background = `linear-gradient(to right, ${this.progressColor} 0%, ${this.progressColor} ${this.percentage}%, rgba(193, 194, 197, 0.2) ${this.percentage}%, rgba(193, 194, 197, 0.2) 100%)`;
  }
}
