import {HttpClient, HttpContext, HttpEventType} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {SKIP_INTERCEPTOR_ERROR_HANDLING} from 'src/app/shared/interceptors/error.interceptor';
import {UploadProgress} from '../interfaces/upload.interface';

interface ProfilePicture {
  userId: string;
  isCoverImage: boolean;
}
interface ArtistPicture {
  artistId: string;
  isCoverImage: boolean;
}

interface TrackAudio {
  trackId: string;
}

interface AlbumCoverPicture {
  albumId: string;
}

interface PlaylistCoverArt {
  playlistId: string;
}

export type UploadURLParameters = ProfilePicture | ArtistPicture | TrackAudio | AlbumCoverPicture | PlaylistCoverArt;

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private http: HttpClient) {}

  public uploadFile(urlParameters: UploadURLParameters, file: File, skipInterceptorErrorHandling?: boolean): Observable<UploadProgress> {
    const formData = new FormData();
    formData.append('file', file);

    Object.entries(urlParameters).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return this.http
      .post<UploadProgress>('upload', formData, {
        reportProgress: true,
        observe: 'events',
        context: skipInterceptorErrorHandling ? new HttpContext().set(SKIP_INTERCEPTOR_ERROR_HANDLING, true) : undefined,
      })
      .pipe(
        map((response) => {
          if (response.type === HttpEventType.Sent) {
            return {progress: 0, state: 'PENDING'} as UploadProgress;
          }
          if (response.type === HttpEventType.Response) {
            return {progress: 100, state: 'DONE'} as UploadProgress;
          }
          if (response.type === HttpEventType.UploadProgress && response.total) {
            const progress = Math.round((100 * response.loaded) / response.total);
            if (progress === 100) {
              return {progress, state: 'PROCESSING'} as UploadProgress;
            }
            return {progress, state: 'IN_PROGRESS'} as UploadProgress;
          }
          if (response.type === HttpEventType.DownloadProgress && response.total) {
            return {progress: Math.round((100 * response.loaded) / response.total), state: 'PROCESSING'} as UploadProgress;
          }
          return {progress: 100, state: 'PROCESSING'} as UploadProgress;
        }),
        catchError(() => of({progress: 0, state: 'ERROR'} as UploadProgress)),
      );
  }
}
